import React from 'react'
import {
    Alert,
    Button, Card,
    CardBody, CardHeader, Col, Row,
} from 'reactstrap';
import { connect } from 'react-redux';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { ClipLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router';
import { setSignupSuccess as actionsSetSignupSuccess, corporateSignupWithOrias as actionsSignup } from '../../store/auth/actions';
import Aux from '../../hoc/Aux';
import config from '../../config/config';
import BannerOrange from '../../assets/Pics/Icons/banner_beta_orange.png';
import SignupCorporateHeader from './SignupCorporateHeader';
import { CGUV, PDD } from '../../router/Routes';
import { useState, useEffect } from 'react';
import { Form, Label, Badge, InputGroupAddon, InputGroupText } from 'reactstrap';
import { FormGroup } from 'reactstrap';
import { Input } from 'reactstrap';
import * as REGEX from '../../data/Regex';
import { InputGroup } from 'reactstrap';
import './custom.css';

const SignupCorporateWithOrias2 = (props) => {
    const [signup, setSignup] = useState({
        firstName: '',
        lastName: '',
        mail: '',
        password: '',
        phone: '',
        orias: '',
    })
    const [showRadieError, setShowRadieError] = useState(false)
    const [showOriasUnknown, setShowOriasUnknown] = useState(false)
    const [showUsernameError, setShowUsernameError] = useState(false)
    const [showPhoneError, setShowPhoneError] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    /*const [phoneHas10Numbers, setPhoneHas10Numbers] = useState(false)
    const [phoneStartsWith06or07, setPhoneStartsWith06or07] = useState(false)*/
    const [hasMoreThan6Caracters, setHasMoreThan6Caracters] = useState(false)
    const [hasMoreThan1SpecialCaracter, setHasMoreThan1SpecialCaracter] = useState(false)
    const [hasMoreThan1CapitalLetter, setHasMoreThan1CapitalLetter] = useState(false)

    useEffect(() => {
        if (signup.password.length >= 6) {
            setHasMoreThan6Caracters(true)
        } else {
            setHasMoreThan6Caracters(false)
        }
    }, [signup.password])

    useEffect(() => {
        if (signup.password.toLowerCase() !== signup.password) {
            setHasMoreThan1CapitalLetter(true)
        } else {
            setHasMoreThan1CapitalLetter(false)
        }
    }, [signup.password])

    useEffect(() => {
        if (signup.password.includes(' ')
            || signup.password.includes('!')
            || signup.password.includes('.')
            || signup.password.includes('@')
            || signup.password.includes('&')
            || signup.password.includes('(')
            || signup.password.includes(')')
            || signup.password.includes('*')
            || signup.password.includes('-')
            || signup.password.includes('+')
            || signup.password.includes('/')
            || signup.password.includes('_')) {
            setHasMoreThan1SpecialCaracter(true)
        } else {
            setHasMoreThan1SpecialCaracter(false)
        }
    }, [signup.password])

    const handleKeyPress = (e) => {
        if (e.which === 13 && !e.shiftKey) {
            e.preventDefault();
            handleSubmit(e);
        }
    };

    const handleSubmit = (e) => {
        if (e) e.preventDefault();
        setSubmitted(true)

        let error = 0;
        if (!checkField(REGEX.NAME, signup.firstName)) { error++; }
        if (!checkField(REGEX.NAME, signup.lastName)) { error++; }
        if (!checkField(REGEX.MAIL, signup.mail)) { error++; }
        if (!checkField(REGEX.PHONE, signup.phone)) { error++; }
        if (!checkField(REGEX.ORIAS, signup.orias)) { error++; }
        if (!checkField(REGEX.PHONE, signup.phone)) { error++; }
        if (!checkField(REGEX.PASSWORD, signup.password)) { error++; }

        if (!error) onSubmit();
    };

    const checkField = (regex, value) => {
        if (!regex.test(value)) {
            return false;
        }

        return true;
    };

    const onSubmit = () => {
        setLoading(true)

        if (signup.phone.startsWith('06') || signup.phone.startsWith('+336') || signup.phone.startsWith('07') || signup.phone.startsWith('+337')) {
            const username = (signup.firstName + '-' + signup.lastName).toLowerCase().latinise();
            fetch('https://us-central1-tribu-app.cloudfunctions.net/checkPreProfileForCorporate', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username,
                    mail: signup.mail,
                    password: signup.password,
                    orias: signup.orias,
                    phone: signup.phone,
                })
            }).then(res => res.json()).then((result) => {
                if (result.failure) {
                    setError(true)
                    setLoading(false)
                } else if (result.preProfileCreated) {
                    setSuccess(true)
                    setLoading(false)
                } else if (result.noPreProfile) {
                    fetch('https://us-central1-tribu-app.cloudfunctions.net/checkOrias', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            oriasNumber: signup.orias,
                        })
                    }).then(res => res.json()).then(result => {
                        if (result.radie) {
                            setShowRadieError(true)
                            setLoading(false)
                        } else {
                            fetch('https://us-central1-tribu-app.cloudfunctions.net/checkUsername', {
                                method: 'POST',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    firstName: signup.firstName,
                                    lastName: signup.lastName,
                                })
                            }).then(res => res.json()).then(result => {
                                if (result.success) {
                                    fetch('https://us-central1-tribu-app.cloudfunctions.net/httpOriasSignup', {
                                        method: 'POST',
                                        headers: {
                                            'Accept': 'application/json',
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({
                                            oriasNumber: signup.orias,
                                        })
                                    }).then(res => res.json()).then(result => {
                                        if (result.success) {
                                            props.signup(signup.mail, signup.password, signup.firstName, signup.lastName, signup.phone, signup.orias);
                                            setLoading(false)
                                        } else {
                                            setShowOriasUnknown(true)
                                            setLoading(false)
                                        }
                                    });
                                } else if (result.failure) {
                                    setShowUsernameError(true)
                                    setLoading(false)
                                }
                            }).catch(() => {
                                setError(true)
                                setLoading(false)
                            })
                        }
                    });
                }
            });
        } else {
            setShowPhoneError(true)
            setLoading(false)
        }
    }

    return (
        <Aux>
            <Row className='bg-secondary bg-white m-0'>
                <SignupCorporateHeader />
                <Col xs={12} sm={10} md={6} lg={7} className="my-auto mx-auto" >
                    <Card
                        className='shadow-none border-0 col-xs-12 col-md-8 col-xl-6 mx-auto bg-transparent mw-100 align-items-center mh-100vh'
                    >
                        <CardHeader className="bg-transparent border-0">
                            <Row className="mb-4 d-md-none justify-content-center">
                                <Col className="text-center" xs={6} sm={4}>
                                    <Link to="/">
                                        <img src={BannerOrange} alt="Tribu" className="w-100" />
                                    </Link>
                                </Col>
                            </Row>
                        </CardHeader>
                        <Helmet>
                            <title>{`${config.title} - Inscription corporate`}</title>
                        </Helmet>
                        {
                            props.isSignupAlready && (
                                <ReactBSAlert
                                    warning
                                    style={{ display: 'block' }}
                                    title="Votre compte n'est pas encore activé !"
                                    onConfirm={() => {
                                        props.setSignupSuccess(false);
                                        props.history.push('/');
                                    }}
                                    confirmBtnBsStyle="warning"
                                    confirmBtnText="Retourner à l'accueil"
                                    btnSize=""
                                >
                                    Tribu y travaille.
                                </ReactBSAlert>
                            )
                        }

                        {
                            (props.isSignupSuccess || success) && (
                                <ReactBSAlert
                                    success
                                    style={{ display: 'block' }}
                                    title={`${signup.firstName}, vous venez de créer votre compte !`}
                                    onConfirm={() => {
                                        props.setSignupSuccess(false);
                                        props.history.push('/');
                                    }}
                                    confirmBtnBsStyle="warning"
                                    confirmBtnText="Retourner à l'accueil"
                                    btnSize=""
                                >
                                    Tribu vérifie vos informations et revient vers vous rapidement.
                                </ReactBSAlert>
                            )
                        }

                        {
                            showRadieError && (
                                <ReactBSAlert
                                    danger
                                    style={{ display: 'block' }}
                                    title="Erreur..."
                                    onConfirm={() => setShowRadieError(false)}
                                    confirmBtnBsStyle="danger"
                                    confirmBtnText="OK"
                                    btnSize=""
                                    allowEscape={true}
                                    closeOnClickOutside={true}
                                    onCancel={() => setShowRadieError(false)}
                                >
                                    Le numéro Orias est radié.
                                </ReactBSAlert>
                            )
                        }

                        {
                            showOriasUnknown && (
                                <ReactBSAlert
                                    danger
                                    style={{ display: 'block' }}
                                    title="Erreur..."
                                    onConfirm={() => setShowOriasUnknown(false)}
                                    confirmBtnBsStyle="danger"
                                    confirmBtnText="OK"
                                    btnSize=""
                                    allowEscape={true}
                                    closeOnClickOutside={true}
                                    onCancel={() => setShowOriasUnknown(false)}
                                >
                                    Le numéro Orias indiqué n'existe pas.
                                </ReactBSAlert>
                            )
                        }

                        {
                            showUsernameError && (
                                <ReactBSAlert
                                    danger
                                    style={{ display: 'block' }}
                                    title="Erreur..."
                                    onConfirm={() => setShowUsernameError(false)}
                                    confirmBtnBsStyle="danger"
                                    confirmBtnText="OK"
                                    btnSize=""
                                    allowEscape={true}
                                    closeOnClickOutside={true}
                                    onCancel={() => setShowUsernameError(false)}
                                >
                                    Le nom d'utilisateur existe déjà.
                                </ReactBSAlert>
                            )
                        }

                        {
                            showPhoneError && (
                                <ReactBSAlert
                                    danger
                                    style={{ display: 'block' }}
                                    title="Erreur..."
                                    onConfirm={() => setShowPhoneError(false)}
                                    confirmBtnBsStyle="danger"
                                    confirmBtnText="OK"
                                    btnSize=""
                                    allowEscape={true}
                                    closeOnClickOutside={true}
                                    onCancel={() => setShowPhoneError(false)}
                                >
                                    Le numéro de téléphone n'est pas celui d'un portable.
                                </ReactBSAlert>
                            )
                        }

                        {
                            error && (
                                <ReactBSAlert
                                    danger
                                    style={{ display: 'block' }}
                                    title="Erreur..."
                                    onConfirm={() => setError(false)}
                                    confirmBtnBsStyle="danger"
                                    confirmBtnText="OK"
                                    btnSize=""
                                    allowEscape={true}
                                    closeOnClickOutside={true}
                                    onCancel={() => setError(false)}
                                >
                                    Une erreur s'est produite.
                                </ReactBSAlert>
                            )
                        }

                        <CardBody className="px-0">
                            {
                                !props.isSignupPending && !isLoading ? (
                                    <Aux>
                                        {
                                            props.signupError && (
                                                <Row>
                                                    <Col>
                                                        <Alert color="danger" className="w-100">
                                                            {props.signupError}
                                                        </Alert>
                                                    </Col>
                                                </Row>
                                            )
                                        }

                                        <Form onSubmit={handleSubmit}>
                                            <Row form className='w-100' style={{ maxWidth: '400px' }}>
                                                <Aux>
                                                    <Col
                                                        xs={6}
                                                    >
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for='firstName' className="font-weight-500">
                                                                        Prénom
                                                                        <span className='text-warning'> *</span>
                                                                    </Label>
                                                                    {
                                                                        submitted && !checkField(REGEX.NAME, signup.firstName) && (
                                                                            <Badge color="danger" className="float-right mb-1 badge-error">
                                                                                Invalide
                                                                            </Badge>
                                                                        )
                                                                    }
                                                                    <InputGroup>
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <InputGroupText>
                                                                                <i className="fas fa-user-tie" />
                                                                            </InputGroupText>
                                                                        </InputGroupAddon>
                                                                        <Input
                                                                            type='text'
                                                                            name='firstName'
                                                                            id='firstName'
                                                                            value={signup.firstName}
                                                                            onChange={(e) => setSignup({ ...signup, firstName: e.target.value })}
                                                                        />
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col
                                                        xs={6}
                                                    >
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for='lastName' className="font-weight-500">
                                                                        Nom
                                                                        <span className='text-warning'> *</span>
                                                                    </Label>
                                                                    {
                                                                        submitted && !checkField(REGEX.NAME, signup.lastName) && (
                                                                            <Badge color="danger" className="float-right mb-1 badge-error">
                                                                                Invalide
                                                                            </Badge>
                                                                        )
                                                                    }
                                                                    <InputGroup>
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <InputGroupText>
                                                                                <i className="fas fa-user-tie" />
                                                                            </InputGroupText>
                                                                        </InputGroupAddon>
                                                                        <Input
                                                                            type='text'
                                                                            name='lastName'
                                                                            id='lastName'
                                                                            value={signup.lastName}
                                                                            onChange={(e) => setSignup({ ...signup, lastName: e.target.value })}
                                                                        />
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col
                                                        xs={6}
                                                    >
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for='mail' className="font-weight-500">
                                                                        E-mail
                                                                        <span className='text-warning'> *</span>
                                                                    </Label>
                                                                    {
                                                                        submitted && !checkField(REGEX.MAIL, signup.mail) && (
                                                                            <Badge color="danger" className="float-right mb-1 badge-error">
                                                                                Invalide
                                                                            </Badge>
                                                                        )
                                                                    }
                                                                    <InputGroup>
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <InputGroupText>
                                                                                <i className="ni ni-email-83" />
                                                                            </InputGroupText>
                                                                        </InputGroupAddon>
                                                                        <Input
                                                                            type='email'
                                                                            name='mail'
                                                                            id='mail'
                                                                            value={signup.mail}
                                                                            onChange={(e) => setSignup({ ...signup, mail: e.target.value })}
                                                                        />
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col
                                                        xs={6}
                                                    >
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for='phone' className="font-weight-500">
                                                                        Portable <span className='text-warning'> *</span>
                                                                    </Label>
                                                                    {
                                                                        submitted && !checkField(REGEX.PHONE, signup.phone) && (
                                                                            <Badge color="danger" className="float-right mb-1 badge-error">
                                                                                Invalide
                                                                            </Badge>
                                                                        )
                                                                    }
                                                                    <InputGroup>
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <InputGroupText>
                                                                            <i className="ni ni-mobile-button" />
                                                                            </InputGroupText>
                                                                        </InputGroupAddon>
                                                                        <Input
                                                                            type='text'
                                                                            name='phone'
                                                                            id='phone'
                                                                            value={signup.phone}
                                                                            onChange={(e) => setSignup({ ...signup, phone: e.target.value })}
                                                                        />
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    {/*<Row className="mt--2 mb-4">
                                                            <Col>
                                                                <div className="custom-control custom-checkbox ml-3">
                                                                    <input
                                                                        className="custom-control-input"
                                                                        type="checkbox"
                                                                        checked={phoneHas10Numbers}
                                                                    />
                                                                    <Label className="custom-control-label">
                                                                        10 chiffres
                                                                    </Label>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="custom-control custom-checkbox ml-3">
                                                                    <input
                                                                        className="custom-control-input"
                                                                        type="checkbox"
                                                                        checked={phoneStartsWith06or07}
                                                                    />
                                                                    <Label className="custom-control-label">
                                                                        06 ou 07
                                                                    </Label>
                                                                </div>
                                                            </Col>
                                                        </Row>*/}
                                                    <Col
                                                        xs={12}
                                                    >
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for='orias' className="font-weight-500">
                                                                        Numéro Orias
                                                                        <span className='text-warning'> *</span>
                                                                    </Label>
                                                                    {
                                                                        submitted && !checkField(REGEX.ORIAS, signup.orias) && (
                                                                            <Badge color="danger" className="float-right mb-1 badge-error">
                                                                            Invalide
                                                                            </Badge>
                                                                        )
                                                                    }
                                                                    <InputGroup>
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <InputGroupText>
                                                                                <i className="fas fa-check" />
                                                                            </InputGroupText>
                                                                        </InputGroupAddon>
                                                                        <Input
                                                                            type='text'
                                                                            name='orias'
                                                                            id='orias'
                                                                            value={signup.orias}
                                                                            onChange={(e) => setSignup({ ...signup, orias: e.target.value })}
                                                                        />
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    {/*<div>*/}
                                                    <Col
                                                        xs={12}
                                                    >
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for='password' className="font-weight-500">
                                                                        Mot de passe
                                                                        <span className='text-warning'> *</span>
                                                                    </Label>
                                                                    {
                                                                        submitted && !checkField(REGEX.PASSWORD, signup.password) && (
                                                                            <Badge color="danger" className="float-right mb-1 badge-error">
                                                                              Invalide
                                                                            </Badge>
                                                                        )
                                                                    }
                                                                    <InputGroup>
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <InputGroupText>
                                                                                <i className="ni ni-lock-circle-open" />
                                                                            </InputGroupText>
                                                                        </InputGroupAddon>
                                                                        <Input
                                                                            type='password'
                                                                            name='password'
                                                                            id='password'
                                                                            value={signup.password}
                                                                            onKeyPress={(e) => handleKeyPress(e)}
                                                                            onChange={(e) => setSignup({ ...signup, password: e.target.value })}
                                                                        />
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col
                                                        xs={12}
                                                    >
                                                        <Row className="mt--2 mb-4">
                                                            <Col>
                                                                <div className="custom-control custom-checkbox">
                                                                    <div className={hasMoreThan6Caracters ? 'password-controller-corporate-indicator-enabled' : 'password-controller-corporate-indicator-disabled'} />
                                                                    <Label className='label'>
                                                                        {">"} 6 caractères
                                                                    </Label>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="custom-control custom-checkbox" >
                                                                    <div className={hasMoreThan1SpecialCaracter ? 'password-controller-corporate-indicator-enabled ' : 'password-controller-corporate-indicator-disabled'} />
                                                                    <Label className='label'>
                                                                        1 caractère spécial
                                                                    </Label>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="custom-control custom-checkbox">
                                                                    <div className={hasMoreThan1CapitalLetter ? 'password-controller-corporate-indicator-enabled' : 'password-controller-corporate-indicator-disabled'} />
                                                                    <Label className='label'>
                                                                        1 majuscule
                                                                    </Label>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    {/*<Row className="mt--2 mb-4">
                                                            <Col>
                                                                <div className="custom-control custom-checkbox">
                                                                    <input
                                                                        className="custom-control-input"
                                                                        type="checkbox"
                                                                        checked={passwordHasMoreThan6Caracters}
                                                                    />
                                                                    <Label className="custom-control-label">
                                                    > 6 caractères
                                                                    </Label>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="custom-control custom-checkbox">
                                                                    <input
                                                                        className="custom-control-input"
                                                                        type="checkbox"
                                                                        checked={passwordHasMoreThan1SpecialCaracter}
                                                                    />
                                                                    <Label className="custom-control-label">
                                                                        1 caractère spécial
                                                                    </Label>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="custom-control custom-checkbox">
                                                                    <input
                                                                        className="custom-control-input"
                                                                        type="checkbox"
                                                                        checked={passwordHasMoreThan1CapitalLetter}
                                                                    />
                                                                    <Label className="custom-control-label">
                                                                        1 majuscule
                                                                    </Label>
                                                                </div>
                                                            </Col>
                                                        </Row>*/}
                                                    {/*</div>*/}
                                                </Aux>
                                            </Row>
                                        </Form>

                                        <Row className="d-block text-left" style={{ maxWidth: '420px' }}>
                                            <Col>
                                                <p style={{ fontSize: '9px' }}>
                                                    Tribu s&apos;engage à respecter vos données. En continuant votre
                                                    inscription, vous acceptez
                                                    nos
                                                    <Link to={CGUV} className="text-warning"> Conditions Générales</Link>
                                                    .
                                                </p>
                                                <p style={{ fontSize: '9px' }} className='mt--2'>
                                                    Tribu peut utiliser ces informations pour une communication
                                                    personnalisée relative à des
                                                    évènements à venir, des webinars et la plateforme Tribu. Vous
                                                    pouvez vous désinscrire à tout
                                                    moment.
                                                    <br />
                                                    En savoir plus sur
                                                    <Link to={PDD} className="text-warning"> notre politique de protection des données.</Link>
                                                </p>
                                            </Col>
                                        </Row>

                                        <Row className="justify-content-center mt-3">
                                            <Col>
                                                <Button
                                                    className="w-100"
                                                    color="warning"
                                                    onClick={handleSubmit}
                                                >
                                                    INSCRIPTION
                                                </Button>
                                            </Col>
                                        </Row>

                                        <Row className="justify-content-center mt-3">
                                            <span className="d-md-none">
                                                Déjà un compte ?
                                                <Link to="/signin" className="text-warning"> Connectez-vous </Link>
                                            </span>
                                        </Row>
                                    </Aux>
                                ) : (
                                    <Row className="justify-content-center">
                                        <ClipLoader color="#5e72e4" size={100} />
                                    </Row>
                                )
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Aux>
    )
}

const mapStateToProps = (state) => ({
    isSignupPending: state.Auth.isSignupPending,
    isSignupSuccess: state.Auth.isSignupSuccess,
    isSignupAlready: state.Auth.isSignupAlready,
    signupError: state.Auth.signupError,
});

export default withRouter(connect(mapStateToProps, {
    signup: actionsSignup,
    setSignupSuccess: actionsSetSignupSuccess,
})(SignupCorporateWithOrias2));