import React from 'react'
import {
    Alert,
    Button, Card,
    CardBody, CardHeader, Col, Row,
} from 'reactstrap';
import { connect } from 'react-redux';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { ClipLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router';
import { setSignupSuccess as actionsSetSignupSuccess, signup as actionsSignup } from '../../store/auth/actions';
import Aux from '../../hoc/Aux';
import config from '../../config/config';
import BannerBlue from '../../assets/Pics/Icons/banner_beta_blue.png';
import SignupHeader from './SignupHeader';
import { CGUV, PDD } from '../../router/Routes';
import { useState, useEffect } from 'react';
import { Form, Label, Badge, InputGroupAddon, InputGroupText } from 'reactstrap';
import { FormGroup } from 'reactstrap';
import { Input } from 'reactstrap';
import * as REGEX from '../../data/Regex';
import { InputGroup } from 'reactstrap';
import './custom.css';

const Latinise = {};
Latinise.latin_map = {
  'Á': 'A',
  'Ă': 'A',
  'Ắ': 'A',
  'Ặ': 'A',
  'Ằ': 'A',
  'Ẳ': 'A',
  'Ẵ': 'A',
  'Ǎ': 'A',
  'Â': 'A',
  'Ấ': 'A',
  'Ậ': 'A',
  'Ầ': 'A',
  'Ẩ': 'A',
  'Ẫ': 'A',
  'Ä': 'A',
  'Ǟ': 'A',
  'Ȧ': 'A',
  'Ǡ': 'A',
  'Ạ': 'A',
  'Ȁ': 'A',
  'À': 'A',
  'Ả': 'A',
  'Ȃ': 'A',
  'Ā': 'A',
  'Ą': 'A',
  'Å': 'A',
  'Ǻ': 'A',
  'Ḁ': 'A',
  'Ⱥ': 'A',
  'Ã': 'A',
  'Ꜳ': 'AA',
  'Æ': 'AE',
  'Ǽ': 'AE',
  'Ǣ': 'AE',
  'Ꜵ': 'AO',
  'Ꜷ': 'AU',
  'Ꜹ': 'AV',
  'Ꜻ': 'AV',
  'Ꜽ': 'AY',
  'Ḃ': 'B',
  'Ḅ': 'B',
  'Ɓ': 'B',
  'Ḇ': 'B',
  'Ƀ': 'B',
  'Ƃ': 'B',
  'Ć': 'C',
  'Č': 'C',
  'Ç': 'C',
  'Ḉ': 'C',
  'Ĉ': 'C',
  'Ċ': 'C',
  'Ƈ': 'C',
  'Ȼ': 'C',
  'Ď': 'D',
  'Ḑ': 'D',
  'Ḓ': 'D',
  'Ḋ': 'D',
  'Ḍ': 'D',
  'Ɗ': 'D',
  'Ḏ': 'D',
  'ǲ': 'D',
  'ǅ': 'D',
  'Đ': 'D',
  'Ƌ': 'D',
  'Ǳ': 'DZ',
  'Ǆ': 'DZ',
  'É': 'E',
  'Ĕ': 'E',
  'Ě': 'E',
  'Ȩ': 'E',
  'Ḝ': 'E',
  'Ê': 'E',
  'Ế': 'E',
  'Ệ': 'E',
  'Ề': 'E',
  'Ể': 'E',
  'Ễ': 'E',
  'Ḙ': 'E',
  'Ë': 'E',
  'Ė': 'E',
  'Ẹ': 'E',
  'Ȅ': 'E',
  'È': 'E',
  'Ẻ': 'E',
  'Ȇ': 'E',
  'Ē': 'E',
  'Ḗ': 'E',
  'Ḕ': 'E',
  'Ę': 'E',
  'Ɇ': 'E',
  'Ẽ': 'E',
  'Ḛ': 'E',
  'Ꝫ': 'ET',
  'Ḟ': 'F',
  'Ƒ': 'F',
  'Ǵ': 'G',
  'Ğ': 'G',
  'Ǧ': 'G',
  'Ģ': 'G',
  'Ĝ': 'G',
  'Ġ': 'G',
  'Ɠ': 'G',
  'Ḡ': 'G',
  'Ǥ': 'G',
  'Ḫ': 'H',
  'Ȟ': 'H',
  'Ḩ': 'H',
  'Ĥ': 'H',
  'Ⱨ': 'H',
  'Ḧ': 'H',
  'Ḣ': 'H',
  'Ḥ': 'H',
  'Ħ': 'H',
  'Í': 'I',
  'Ĭ': 'I',
  'Ǐ': 'I',
  'Î': 'I',
  'Ï': 'I',
  'Ḯ': 'I',
  'İ': 'I',
  'Ị': 'I',
  'Ȉ': 'I',
  'Ì': 'I',
  'Ỉ': 'I',
  'Ȋ': 'I',
  'Ī': 'I',
  'Į': 'I',
  'Ɨ': 'I',
  'Ĩ': 'I',
  'Ḭ': 'I',
  'Ꝺ': 'D',
  'Ꝼ': 'F',
  'Ᵹ': 'G',
  'Ꞃ': 'R',
  'Ꞅ': 'S',
  'Ꞇ': 'T',
  'Ꝭ': 'IS',
  'Ĵ': 'J',
  'Ɉ': 'J',
  'Ḱ': 'K',
  'Ǩ': 'K',
  'Ķ': 'K',
  'Ⱪ': 'K',
  'Ꝃ': 'K',
  'Ḳ': 'K',
  'Ƙ': 'K',
  'Ḵ': 'K',
  'Ꝁ': 'K',
  'Ꝅ': 'K',
  'Ĺ': 'L',
  'Ƚ': 'L',
  'Ľ': 'L',
  'Ļ': 'L',
  'Ḽ': 'L',
  'Ḷ': 'L',
  'Ḹ': 'L',
  'Ⱡ': 'L',
  'Ꝉ': 'L',
  'Ḻ': 'L',
  'Ŀ': 'L',
  'Ɫ': 'L',
  'ǈ': 'L',
  'Ł': 'L',
  'Ǉ': 'LJ',
  'Ḿ': 'M',
  'Ṁ': 'M',
  'Ṃ': 'M',
  'Ɱ': 'M',
  'Ń': 'N',
  'Ň': 'N',
  'Ņ': 'N',
  'Ṋ': 'N',
  'Ṅ': 'N',
  'Ṇ': 'N',
  'Ǹ': 'N',
  'Ɲ': 'N',
  'Ṉ': 'N',
  'Ƞ': 'N',
  'ǋ': 'N',
  'Ñ': 'N',
  'Ǌ': 'NJ',
  'Ó': 'O',
  'Ŏ': 'O',
  'Ǒ': 'O',
  'Ô': 'O',
  'Ố': 'O',
  'Ộ': 'O',
  'Ồ': 'O',
  'Ổ': 'O',
  'Ỗ': 'O',
  'Ö': 'O',
  'Ȫ': 'O',
  'Ȯ': 'O',
  'Ȱ': 'O',
  'Ọ': 'O',
  'Ő': 'O',
  'Ȍ': 'O',
  'Ò': 'O',
  'Ỏ': 'O',
  'Ơ': 'O',
  'Ớ': 'O',
  'Ợ': 'O',
  'Ờ': 'O',
  'Ở': 'O',
  'Ỡ': 'O',
  'Ȏ': 'O',
  'Ꝋ': 'O',
  'Ꝍ': 'O',
  'Ō': 'O',
  'Ṓ': 'O',
  'Ṑ': 'O',
  'Ɵ': 'O',
  'Ǫ': 'O',
  'Ǭ': 'O',
  'Ø': 'O',
  'Ǿ': 'O',
  'Õ': 'O',
  'Ṍ': 'O',
  'Ṏ': 'O',
  'Ȭ': 'O',
  'Ƣ': 'OI',
  'Ꝏ': 'OO',
  'Ɛ': 'E',
  'Ɔ': 'O',
  'Ȣ': 'OU',
  'Ṕ': 'P',
  'Ṗ': 'P',
  'Ꝓ': 'P',
  'Ƥ': 'P',
  'Ꝕ': 'P',
  'Ᵽ': 'P',
  'Ꝑ': 'P',
  'Ꝙ': 'Q',
  'Ꝗ': 'Q',
  'Ŕ': 'R',
  'Ř': 'R',
  'Ŗ': 'R',
  'Ṙ': 'R',
  'Ṛ': 'R',
  'Ṝ': 'R',
  'Ȑ': 'R',
  'Ȓ': 'R',
  'Ṟ': 'R',
  'Ɍ': 'R',
  'Ɽ': 'R',
  'Ꜿ': 'C',
  'Ǝ': 'E',
  'Ś': 'S',
  'Ṥ': 'S',
  'Š': 'S',
  'Ṧ': 'S',
  'Ş': 'S',
  'Ŝ': 'S',
  'Ș': 'S',
  'Ṡ': 'S',
  'Ṣ': 'S',
  'Ṩ': 'S',
  'Ť': 'T',
  'Ţ': 'T',
  'Ṱ': 'T',
  'Ț': 'T',
  'Ⱦ': 'T',
  'Ṫ': 'T',
  'Ṭ': 'T',
  'Ƭ': 'T',
  'Ṯ': 'T',
  'Ʈ': 'T',
  'Ŧ': 'T',
  'Ɐ': 'A',
  'Ꞁ': 'L',
  'Ɯ': 'M',
  'Ʌ': 'V',
  'Ꜩ': 'TZ',
  'Ú': 'U',
  'Ŭ': 'U',
  'Ǔ': 'U',
  'Û': 'U',
  'Ṷ': 'U',
  'Ü': 'U',
  'Ǘ': 'U',
  'Ǚ': 'U',
  'Ǜ': 'U',
  'Ǖ': 'U',
  'Ṳ': 'U',
  'Ụ': 'U',
  'Ű': 'U',
  'Ȕ': 'U',
  'Ù': 'U',
  'Ủ': 'U',
  'Ư': 'U',
  'Ứ': 'U',
  'Ự': 'U',
  'Ừ': 'U',
  'Ử': 'U',
  'Ữ': 'U',
  'Ȗ': 'U',
  'Ū': 'U',
  'Ṻ': 'U',
  'Ų': 'U',
  'Ů': 'U',
  'Ũ': 'U',
  'Ṹ': 'U',
  'Ṵ': 'U',
  'Ꝟ': 'V',
  'Ṿ': 'V',
  'Ʋ': 'V',
  'Ṽ': 'V',
  'Ꝡ': 'VY',
  'Ẃ': 'W',
  'Ŵ': 'W',
  'Ẅ': 'W',
  'Ẇ': 'W',
  'Ẉ': 'W',
  'Ẁ': 'W',
  'Ⱳ': 'W',
  'Ẍ': 'X',
  'Ẋ': 'X',
  'Ý': 'Y',
  'Ŷ': 'Y',
  'Ÿ': 'Y',
  'Ẏ': 'Y',
  'Ỵ': 'Y',
  'Ỳ': 'Y',
  'Ƴ': 'Y',
  'Ỷ': 'Y',
  'Ỿ': 'Y',
  'Ȳ': 'Y',
  'Ɏ': 'Y',
  'Ỹ': 'Y',
  'Ź': 'Z',
  'Ž': 'Z',
  'Ẑ': 'Z',
  'Ⱬ': 'Z',
  'Ż': 'Z',
  'Ẓ': 'Z',
  'Ȥ': 'Z',
  'Ẕ': 'Z',
  'Ƶ': 'Z',
  'Ĳ': 'IJ',
  'Œ': 'OE',
  'ᴀ': 'A',
  'ᴁ': 'AE',
  'ʙ': 'B',
  'ᴃ': 'B',
  'ᴄ': 'C',
  'ᴅ': 'D',
  'ᴇ': 'E',
  'ꜰ': 'F',
  'ɢ': 'G',
  'ʛ': 'G',
  'ʜ': 'H',
  'ɪ': 'I',
  'ʁ': 'R',
  'ᴊ': 'J',
  'ᴋ': 'K',
  'ʟ': 'L',
  'ᴌ': 'L',
  'ᴍ': 'M',
  'ɴ': 'N',
  'ᴏ': 'O',
  'ɶ': 'OE',
  'ᴐ': 'O',
  'ᴕ': 'OU',
  'ᴘ': 'P',
  'ʀ': 'R',
  'ᴎ': 'N',
  'ᴙ': 'R',
  'ꜱ': 'S',
  'ᴛ': 'T',
  'ⱻ': 'E',
  'ᴚ': 'R',
  'ᴜ': 'U',
  'ᴠ': 'V',
  'ᴡ': 'W',
  'ʏ': 'Y',
  'ᴢ': 'Z',
  'á': 'a',
  'ă': 'a',
  'ắ': 'a',
  'ặ': 'a',
  'ằ': 'a',
  'ẳ': 'a',
  'ẵ': 'a',
  'ǎ': 'a',
  'â': 'a',
  'ấ': 'a',
  'ậ': 'a',
  'ầ': 'a',
  'ẩ': 'a',
  'ẫ': 'a',
  'ä': 'a',
  'ǟ': 'a',
  'ȧ': 'a',
  'ǡ': 'a',
  'ạ': 'a',
  'ȁ': 'a',
  'à': 'a',
  'ả': 'a',
  'ȃ': 'a',
  'ā': 'a',
  'ą': 'a',
  'ᶏ': 'a',
  'ẚ': 'a',
  'å': 'a',
  'ǻ': 'a',
  'ḁ': 'a',
  'ⱥ': 'a',
  'ã': 'a',
  'ꜳ': 'aa',
  'æ': 'ae',
  'ǽ': 'ae',
  'ǣ': 'ae',
  'ꜵ': 'ao',
  'ꜷ': 'au',
  'ꜹ': 'av',
  'ꜻ': 'av',
  'ꜽ': 'ay',
  'ḃ': 'b',
  'ḅ': 'b',
  'ɓ': 'b',
  'ḇ': 'b',
  'ᵬ': 'b',
  'ᶀ': 'b',
  'ƀ': 'b',
  'ƃ': 'b',
  'ɵ': 'o',
  'ć': 'c',
  'č': 'c',
  'ç': 'c',
  'ḉ': 'c',
  'ĉ': 'c',
  'ɕ': 'c',
  'ċ': 'c',
  'ƈ': 'c',
  'ȼ': 'c',
  'ď': 'd',
  'ḑ': 'd',
  'ḓ': 'd',
  'ȡ': 'd',
  'ḋ': 'd',
  'ḍ': 'd',
  'ɗ': 'd',
  'ᶑ': 'd',
  'ḏ': 'd',
  'ᵭ': 'd',
  'ᶁ': 'd',
  'đ': 'd',
  'ɖ': 'd',
  'ƌ': 'd',
  'ı': 'i',
  'ȷ': 'j',
  'ɟ': 'j',
  'ʄ': 'j',
  'ǳ': 'dz',
  'ǆ': 'dz',
  'é': 'e',
  'ĕ': 'e',
  'ě': 'e',
  'ȩ': 'e',
  'ḝ': 'e',
  'ê': 'e',
  'ế': 'e',
  'ệ': 'e',
  'ề': 'e',
  'ể': 'e',
  'ễ': 'e',
  'ḙ': 'e',
  'ë': 'e',
  'ė': 'e',
  'ẹ': 'e',
  'ȅ': 'e',
  'è': 'e',
  'ẻ': 'e',
  'ȇ': 'e',
  'ē': 'e',
  'ḗ': 'e',
  'ḕ': 'e',
  'ⱸ': 'e',
  'ę': 'e',
  'ᶒ': 'e',
  'ɇ': 'e',
  'ẽ': 'e',
  'ḛ': 'e',
  'ꝫ': 'et',
  'ḟ': 'f',
  'ƒ': 'f',
  'ᵮ': 'f',
  'ᶂ': 'f',
  'ǵ': 'g',
  'ğ': 'g',
  'ǧ': 'g',
  'ģ': 'g',
  'ĝ': 'g',
  'ġ': 'g',
  'ɠ': 'g',
  'ḡ': 'g',
  'ᶃ': 'g',
  'ǥ': 'g',
  'ḫ': 'h',
  'ȟ': 'h',
  'ḩ': 'h',
  'ĥ': 'h',
  'ⱨ': 'h',
  'ḧ': 'h',
  'ḣ': 'h',
  'ḥ': 'h',
  'ɦ': 'h',
  'ẖ': 'h',
  'ħ': 'h',
  'ƕ': 'hv',
  'í': 'i',
  'ĭ': 'i',
  'ǐ': 'i',
  'î': 'i',
  'ï': 'i',
  'ḯ': 'i',
  'ị': 'i',
  'ȉ': 'i',
  'ì': 'i',
  'ỉ': 'i',
  'ȋ': 'i',
  'ī': 'i',
  'į': 'i',
  'ᶖ': 'i',
  'ɨ': 'i',
  'ĩ': 'i',
  'ḭ': 'i',
  'ꝺ': 'd',
  'ꝼ': 'f',
  'ᵹ': 'g',
  'ꞃ': 'r',
  'ꞅ': 's',
  'ꞇ': 't',
  'ꝭ': 'is',
  'ǰ': 'j',
  'ĵ': 'j',
  'ʝ': 'j',
  'ɉ': 'j',
  'ḱ': 'k',
  'ǩ': 'k',
  'ķ': 'k',
  'ⱪ': 'k',
  'ꝃ': 'k',
  'ḳ': 'k',
  'ƙ': 'k',
  'ḵ': 'k',
  'ᶄ': 'k',
  'ꝁ': 'k',
  'ꝅ': 'k',
  'ĺ': 'l',
  'ƚ': 'l',
  'ɬ': 'l',
  'ľ': 'l',
  'ļ': 'l',
  'ḽ': 'l',
  'ȴ': 'l',
  'ḷ': 'l',
  'ḹ': 'l',
  'ⱡ': 'l',
  'ꝉ': 'l',
  'ḻ': 'l',
  'ŀ': 'l',
  'ɫ': 'l',
  'ᶅ': 'l',
  'ɭ': 'l',
  'ł': 'l',
  'ǉ': 'lj',
  'ſ': 's',
  'ẜ': 's',
  'ẛ': 's',
  'ẝ': 's',
  'ḿ': 'm',
  'ṁ': 'm',
  'ṃ': 'm',
  'ɱ': 'm',
  'ᵯ': 'm',
  'ᶆ': 'm',
  'ń': 'n',
  'ň': 'n',
  'ņ': 'n',
  'ṋ': 'n',
  'ȵ': 'n',
  'ṅ': 'n',
  'ṇ': 'n',
  'ǹ': 'n',
  'ɲ': 'n',
  'ṉ': 'n',
  'ƞ': 'n',
  'ᵰ': 'n',
  'ᶇ': 'n',
  'ɳ': 'n',
  'ñ': 'n',
  'ǌ': 'nj',
  'ó': 'o',
  'ŏ': 'o',
  'ǒ': 'o',
  'ô': 'o',
  'ố': 'o',
  'ộ': 'o',
  'ồ': 'o',
  'ổ': 'o',
  'ỗ': 'o',
  'ö': 'o',
  'ȫ': 'o',
  'ȯ': 'o',
  'ȱ': 'o',
  'ọ': 'o',
  'ő': 'o',
  'ȍ': 'o',
  'ò': 'o',
  'ỏ': 'o',
  'ơ': 'o',
  'ớ': 'o',
  'ợ': 'o',
  'ờ': 'o',
  'ở': 'o',
  'ỡ': 'o',
  'ȏ': 'o',
  'ꝋ': 'o',
  'ꝍ': 'o',
  'ⱺ': 'o',
  'ō': 'o',
  'ṓ': 'o',
  'ṑ': 'o',
  'ǫ': 'o',
  'ǭ': 'o',
  'ø': 'o',
  'ǿ': 'o',
  'õ': 'o',
  'ṍ': 'o',
  'ṏ': 'o',
  'ȭ': 'o',
  'ƣ': 'oi',
  'ꝏ': 'oo',
  'ɛ': 'e',
  'ᶓ': 'e',
  'ɔ': 'o',
  'ᶗ': 'o',
  'ȣ': 'ou',
  'ṕ': 'p',
  'ṗ': 'p',
  'ꝓ': 'p',
  'ƥ': 'p',
  'ᵱ': 'p',
  'ᶈ': 'p',
  'ꝕ': 'p',
  'ᵽ': 'p',
  'ꝑ': 'p',
  'ꝙ': 'q',
  'ʠ': 'q',
  'ɋ': 'q',
  'ꝗ': 'q',
  'ŕ': 'r',
  'ř': 'r',
  'ŗ': 'r',
  'ṙ': 'r',
  'ṛ': 'r',
  'ṝ': 'r',
  'ȑ': 'r',
  'ɾ': 'r',
  'ᵳ': 'r',
  'ȓ': 'r',
  'ṟ': 'r',
  'ɼ': 'r',
  'ᵲ': 'r',
  'ᶉ': 'r',
  'ɍ': 'r',
  'ɽ': 'r',
  'ↄ': 'c',
  'ꜿ': 'c',
  'ɘ': 'e',
  'ɿ': 'r',
  'ś': 's',
  'ṥ': 's',
  'š': 's',
  'ṧ': 's',
  'ş': 's',
  'ŝ': 's',
  'ș': 's',
  'ṡ': 's',
  'ṣ': 's',
  'ṩ': 's',
  'ʂ': 's',
  'ᵴ': 's',
  'ᶊ': 's',
  'ȿ': 's',
  'ɡ': 'g',
  'ᴑ': 'o',
  'ᴓ': 'o',
  'ᴝ': 'u',
  'ť': 't',
  'ţ': 't',
  'ṱ': 't',
  'ț': 't',
  'ȶ': 't',
  'ẗ': 't',
  'ⱦ': 't',
  'ṫ': 't',
  'ṭ': 't',
  'ƭ': 't',
  'ṯ': 't',
  'ᵵ': 't',
  'ƫ': 't',
  'ʈ': 't',
  'ŧ': 't',
  'ᵺ': 'th',
  'ɐ': 'a',
  'ᴂ': 'ae',
  'ǝ': 'e',
  'ᵷ': 'g',
  'ɥ': 'h',
  'ʮ': 'h',
  'ʯ': 'h',
  'ᴉ': 'i',
  'ʞ': 'k',
  'ꞁ': 'l',
  'ɯ': 'm',
  'ɰ': 'm',
  'ᴔ': 'oe',
  'ɹ': 'r',
  'ɻ': 'r',
  'ɺ': 'r',
  'ⱹ': 'r',
  'ʇ': 't',
  'ʌ': 'v',
  'ʍ': 'w',
  'ʎ': 'y',
  'ꜩ': 'tz',
  'ú': 'u',
  'ŭ': 'u',
  'ǔ': 'u',
  'û': 'u',
  'ṷ': 'u',
  'ü': 'u',
  'ǘ': 'u',
  'ǚ': 'u',
  'ǜ': 'u',
  'ǖ': 'u',
  'ṳ': 'u',
  'ụ': 'u',
  'ű': 'u',
  'ȕ': 'u',
  'ù': 'u',
  'ủ': 'u',
  'ư': 'u',
  'ứ': 'u',
  'ự': 'u',
  'ừ': 'u',
  'ử': 'u',
  'ữ': 'u',
  'ȗ': 'u',
  'ū': 'u',
  'ṻ': 'u',
  'ų': 'u',
  'ᶙ': 'u',
  'ů': 'u',
  'ũ': 'u',
  'ṹ': 'u',
  'ṵ': 'u',
  'ᵫ': 'ue',
  'ꝸ': 'um',
  'ⱴ': 'v',
  'ꝟ': 'v',
  'ṿ': 'v',
  'ʋ': 'v',
  'ᶌ': 'v',
  'ⱱ': 'v',
  'ṽ': 'v',
  'ꝡ': 'vy',
  'ẃ': 'w',
  'ŵ': 'w',
  'ẅ': 'w',
  'ẇ': 'w',
  'ẉ': 'w',
  'ẁ': 'w',
  'ⱳ': 'w',
  'ẘ': 'w',
  'ẍ': 'x',
  'ẋ': 'x',
  'ᶍ': 'x',
  'ý': 'y',
  'ŷ': 'y',
  'ÿ': 'y',
  'ẏ': 'y',
  'ỵ': 'y',
  'ỳ': 'y',
  'ƴ': 'y',
  'ỷ': 'y',
  'ỿ': 'y',
  'ȳ': 'y',
  'ẙ': 'y',
  'ɏ': 'y',
  'ỹ': 'y',
  'ź': 'z',
  'ž': 'z',
  'ẑ': 'z',
  'ʑ': 'z',
  'ⱬ': 'z',
  'ż': 'z',
  'ẓ': 'z',
  'ȥ': 'z',
  'ẕ': 'z',
  'ᵶ': 'z',
  'ᶎ': 'z',
  'ʐ': 'z',
  'ƶ': 'z',
  'ɀ': 'z',
  'ﬀ': 'ff',
  'ﬃ': 'ffi',
  'ﬄ': 'ffl',
  'ﬁ': 'fi',
  'ﬂ': 'fl',
  'ĳ': 'ij',
  'œ': 'oe',
  'ﬆ': 'st',
  'ₐ': 'a',
  'ₑ': 'e',
  'ᵢ': 'i',
  'ⱼ': 'j',
  'ₒ': 'o',
  'ᵣ': 'r',
  'ᵤ': 'u',
  'ᵥ': 'v',
  'ₓ': 'x',
};
String.prototype.latinise = function() {
  return this.replace(/[^A-Za-z0-9\[\] ]/g, function(a) {
    return Latinise.latin_map[a] || a;
  });
};

const Signup2 = (props) => {
    const [signup, setSignup] = useState({
        firstName: '',
        lastName: '',
        mail: '',
        password: '',
        phone: '',
        orias: '',
    })
    const [showRadieError, setShowRadieError] = useState(false)
    const [showOriasUnknown, setShowOriasUnknown] = useState(false)
    const [showUsernameError, setShowUsernameError] = useState(false)
    const [showPhoneError, setShowPhoneError] = useState(false)
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [submitted, setSubmitted] = useState(false)
    /*const [phoneHas10Numbers, setPhoneHas10Numbers] = useState(false)
    const [phoneStartsWith06or07, setPhoneStartsWith06or07] = useState(false)*/
    const [hasMoreThan6Caracters, setHasMoreThan6Caracters] = useState(false)
    const [hasMoreThan1SpecialCaracter, setHasMoreThan1SpecialCaracter] = useState(false)
    const [hasMoreThan1CapitalLetter, setHasMoreThan1CapitalLetter] = useState(false)

    useEffect(() => {
        if (signup.password.length >= 6) {
            setHasMoreThan6Caracters(true)
        } else {
            setHasMoreThan6Caracters(false)
        }
    }, [signup.password])

    useEffect(() => {
        if (signup.password.toLowerCase() !== signup.password) {
            setHasMoreThan1CapitalLetter(true)
        } else {
            setHasMoreThan1CapitalLetter(false)
        }
    }, [signup.password])

    useEffect(() => {
        if (signup.password.includes(' ')
            || signup.password.includes('!')
            || signup.password.includes('.')
            || signup.password.includes('@')
            || signup.password.includes('&')
            || signup.password.includes('(')
            || signup.password.includes(')')
            || signup.password.includes('*')
            || signup.password.includes('-')
            || signup.password.includes('+')
            || signup.password.includes('/')
            || signup.password.includes('_')) {
            setHasMoreThan1SpecialCaracter(true)
        } else {
            setHasMoreThan1SpecialCaracter(false)
        }
    }, [signup.password])

    const handleKeyPress = (e) => {
        if (e.which === 13 && !e.shiftKey) {
            e.preventDefault();
            handleSubmit(e);
        }
    };

    const handleSubmit = (e) => {
        if (e) e.preventDefault();
        setSubmitted(true)

        let error = 0;
        if (!checkField(REGEX.NAME, signup.firstName)) { error++; }
        if (!checkField(REGEX.NAME, signup.lastName)) { error++; }
        if (!checkField(REGEX.MAIL, signup.mail)) { error++; }
        if (!checkField(REGEX.PHONE, signup.phone)) { error++; }
        if (!checkField(REGEX.ORIAS, signup.orias)) { error++; }
        if (!checkField(REGEX.PHONE, signup.phone)) { error++; }
        if (!checkField(REGEX.PASSWORD, signup.password)) { error++; }

        if (!error) onSubmit();
    };

    const checkField = (regex, value) => {
        if (!regex.test(value)) {
            return false;
        }

        return true;
    };

    const onSubmit = () => {
        setLoading(true)

        if (signup.phone.startsWith('06') || signup.phone.startsWith('+336') || signup.phone.startsWith('07') || signup.phone.startsWith('+337')) {
            const username = (signup.firstName + '-' + signup.lastName).toLowerCase().latinise();
            fetch('https://us-central1-tribu-app.cloudfunctions.net/checkPreProfile', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username,
                    mail: signup.mail,
                    password: signup.password,
                    orias: signup.orias,
                    phone: signup.phone,
                })
            }).then(res => res.json()).then((result) => {
                if (result.failure) {
                    setError(true)
                    setLoading(false)
                } else if (result.preProfileCreated) {
                    setSuccess(true)
                    setLoading(false)
                } else if (result.noPreProfile) {
                    fetch('https://us-central1-tribu-app.cloudfunctions.net/checkOrias', {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            oriasNumber: signup.orias,
                        })
                    }).then(res => res.json()).then(result => {
                        if (result.radie) {
                            setShowRadieError(true)
                            setLoading(false)
                        } else {
                            fetch('https://us-central1-tribu-app.cloudfunctions.net/checkUsername', {
                                method: 'POST',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    firstName: signup.firstName,
                                    lastName: signup.lastName,
                                })
                            }).then(res => res.json()).then(result => {
                                if (result.success) {
                                    fetch('https://us-central1-tribu-app.cloudfunctions.net/httpOriasSignup', {
                                        method: 'POST',
                                        headers: {
                                            'Accept': 'application/json',
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({
                                            oriasNumber: signup.orias,
                                        })
                                    }).then(res => res.json()).then(result => {
                                        if (result.success) {
                                            props.signup(signup.mail, signup.password, signup.firstName, signup.lastName, signup.phone, signup.orias);
                                        } else {
                                            setShowOriasUnknown(true)
                                            setLoading(false)
                                        }
                                    });
                                } else if (result.failure) {
                                    setShowUsernameError(true)
                                    setLoading(false)
                                }
                            }).catch(() => {
                                setError(true)
                                setLoading(false)
                            })
                        }
                    });
                }
            });
        } else {
            setShowPhoneError(true)
            setLoading(false)
        }
    }

    return (
        <Aux>
            <Row className='bg-secondary bg-white m-0'>
                <SignupHeader />
                <Col xs={12} sm={10} md={6} lg={7} className="my-auto mx-auto" >
                    <Card
                        className='shadow-none border-0 col-xs-12 col-md-8 col-xl-6 mx-auto bg-transparent mw-100 align-items-center mh-100vh'
                    >
                        <CardHeader className="bg-transparent border-0">
                            <Row className="mb-4 d-md-none justify-content-center">
                                <Col className="text-center" xs={6} sm={4}>
                                    <Link to="/">
                                        <img src={BannerBlue} alt="Tribu" className="w-100" />
                                    </Link>
                                </Col>
                            </Row>
                        </CardHeader>
                        <Helmet>
                            <title>{`${config.title} - Inscription courtier`}</title>
                        </Helmet>
                        {
                            props.isSignupAlready && (
                                <ReactBSAlert
                                    warning
                                    style={{ display: 'block' }}
                                    title="Votre compte n'est pas encore activé !"
                                    onConfirm={() => {
                                        props.setSignupSuccess(false);
                                        props.history.push('/');
                                    }}
                                    confirmBtnBsStyle="primary"
                                    confirmBtnText="Retourner à l'accueil"
                                    btnSize=""
                                >
                                    Tribu y travaille.
                                </ReactBSAlert>
                            )
                        }

                        {
                            (props.isSignupSuccess || success) && (
                                <ReactBSAlert
                                    success
                                    style={{ display: 'block' }}
                                    title={`${signup.firstName}, vous venez de créer votre compte !`}
                                    onConfirm={() => {
                                        props.setSignupSuccess(false);
                                        props.history.push('/');
                                    }}
                                    confirmBtnBsStyle="primary"
                                    confirmBtnText="Retourner à l'accueil"
                                    btnSize=""
                                >
                                    Tribu vérifie vos informations et revient vers vous rapidement.
                                </ReactBSAlert>
                            )
                        }

                        {
                            showRadieError && (
                                <ReactBSAlert
                                    danger
                                    style={{ display: 'block' }}
                                    title="Erreur..."
                                    onConfirm={() => setShowRadieError(false)}
                                    confirmBtnBsStyle="danger"
                                    confirmBtnText="OK"
                                    btnSize=""
                                    allowEscape={true}
                                    closeOnClickOutside={true}
                                    onCancel={() => setShowRadieError(false)}
                                >
                                    Le numéro Orias est radié.
                                </ReactBSAlert>
                            )
                        }

                        {
                            showOriasUnknown && (
                                <ReactBSAlert
                                    danger
                                    style={{ display: 'block' }}
                                    title="Erreur..."
                                    onConfirm={() => setShowOriasUnknown(false)}
                                    confirmBtnBsStyle="danger"
                                    confirmBtnText="OK"
                                    btnSize=""
                                    allowEscape={true}
                                    closeOnClickOutside={true}
                                    onCancel={() => setShowOriasUnknown(false)}
                                >
                                    Le numéro Orias indiqué n'existe pas.
                                </ReactBSAlert>
                            )
                        }

                        {
                            showUsernameError && (
                                <ReactBSAlert
                                    danger
                                    style={{ display: 'block' }}
                                    title="Erreur..."
                                    onConfirm={() => setShowUsernameError(false)}
                                    confirmBtnBsStyle="danger"
                                    confirmBtnText="OK"
                                    btnSize=""
                                    allowEscape={true}
                                    closeOnClickOutside={true}
                                    onCancel={() => setShowUsernameError(false)}
                                >
                                    Le nom d'utilisateur existe déjà.
                                </ReactBSAlert>
                            )
                        }

                        {
                            showPhoneError && (
                                <ReactBSAlert
                                    danger
                                    style={{ display: 'block' }}
                                    title="Erreur..."
                                    onConfirm={() => setShowPhoneError(false)}
                                    confirmBtnBsStyle="danger"
                                    confirmBtnText="OK"
                                    btnSize=""
                                    allowEscape={true}
                                    closeOnClickOutside={true}
                                    onCancel={() => setShowPhoneError(false)}
                                >
                                    Le numéro de téléphone n'est pas celui d'un portable.
                                </ReactBSAlert>
                            )
                        }

                        {
                            error && (
                                <ReactBSAlert
                                    danger
                                    style={{ display: 'block' }}
                                    title="Erreur..."
                                    onConfirm={() => setError(false)}
                                    confirmBtnBsStyle="danger"
                                    confirmBtnText="OK"
                                    btnSize=""
                                    allowEscape={true}
                                    closeOnClickOutside={true}
                                    onCancel={() => setError(false)}
                                >
                                    Une erreur s'est produite.
                                </ReactBSAlert>
                            )
                        }

                        <CardBody className="px-0">
                            {
                                !props.isSignupPending && !isLoading ? (
                                    <Aux>
                                        {
                                            props.signupError && (
                                                <Row>
                                                    <Col>
                                                        <Alert color="danger" className="w-100">
                                                            {props.signupError}
                                                        </Alert>
                                                    </Col>
                                                </Row>
                                            )
                                        }

                                        <Form onSubmit={handleSubmit}>
                                            <Row form className='w-100' style={{ maxWidth: '400px' }}>
                                                <Aux>
                                                    <Col
                                                        xs={12}
                                                    >
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for='firstName' className="font-weight-500">
                                                                        Prénom
                                                                        <span className='text-primary'> *</span>
                                                                    </Label>
                                                                    {
                                                                        submitted && !checkField(REGEX.NAME, signup.firstName) && (
                                                                            <Badge color="danger" className="float-right mb-1 badge-error">
                                                                                Invalide
                                                                            </Badge>
                                                                        )
                                                                    }
                                                                    <InputGroup>
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <InputGroupText>
                                                                                <i className="fas fa-user-tie" />
                                                                            </InputGroupText>
                                                                        </InputGroupAddon>
                                                                        <Input
                                                                            type='text'
                                                                            name='firstName'
                                                                            id='firstName'
                                                                            value={signup.firstName}
                                                                            onChange={(e) => setSignup({ ...signup, firstName: e.target.value })}
                                                                        />
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col
                                                        xs={12}
                                                    >
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for='lastName' className="font-weight-500">
                                                                        Nom
                                                                        <span className='text-primary'> *</span>
                                                                    </Label>
                                                                    {
                                                                        submitted && !checkField(REGEX.NAME, signup.lastName) && (
                                                                            <Badge color="danger" className="float-right mb-1 badge-error">
                                                                                Invalide
                                                                            </Badge>
                                                                        )
                                                                    }
                                                                    <InputGroup>
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <InputGroupText>
                                                                                <i className="fas fa-user-tie" />
                                                                            </InputGroupText>
                                                                        </InputGroupAddon>
                                                                        <Input
                                                                            type='text'
                                                                            name='lastName'
                                                                            id='lastName'
                                                                            value={signup.lastName}
                                                                            onChange={(e) => setSignup({ ...signup, lastName: e.target.value })}
                                                                        />
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col
                                                        xs={12}
                                                    >
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for='mail' className="font-weight-500">
                                                                        E-mail
                                                                        <span className='text-primary'> *</span>
                                                                    </Label>
                                                                    {
                                                                        submitted && !checkField(REGEX.MAIL, signup.mail) && (
                                                                            <Badge color="danger" className="float-right mb-1 badge-error">
                                                                                Invalide
                                                                            </Badge>
                                                                        )
                                                                    }
                                                                    <InputGroup>
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <InputGroupText>
                                                                                <i className="ni ni-email-83" />
                                                                            </InputGroupText>
                                                                        </InputGroupAddon>
                                                                        <Input
                                                                            type='email'
                                                                            name='mail'
                                                                            id='mail'
                                                                            value={signup.mail}
                                                                            onChange={(e) => setSignup({ ...signup, mail: e.target.value })}
                                                                        />
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col
                                                        xs={12}
                                                    >
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for='phone' className="font-weight-500">
                                                                        Portable <span className='text-primary'> *</span>
                                                                    </Label>
                                                                    {
                                                                        submitted && !checkField(REGEX.PHONE, signup.phone) && (
                                                                            <Badge color="danger" className="float-right mb-1 badge-error">
                                                                                Invalide
                                                                            </Badge>
                                                                        )
                                                                    }
                                                                    <InputGroup>
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <InputGroupText>
                                                                            <i className="ni ni-mobile-button" />
                                                                            </InputGroupText>
                                                                        </InputGroupAddon>
                                                                        <Input
                                                                            type='text'
                                                                            name='phone'
                                                                            id='phone'
                                                                            value={signup.phone}
                                                                            onChange={(e) => setSignup({ ...signup, phone: e.target.value })}
                                                                        />
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    {/*<Row className="mt--2 mb-4">
                                                            <Col>
                                                                <div className="custom-control custom-checkbox ml-3">
                                                                    <input
                                                                        className="custom-control-input"
                                                                        type="checkbox"
                                                                        checked={phoneHas10Numbers}
                                                                    />
                                                                    <Label className="custom-control-label">
                                                                        10 chiffres
                                                                    </Label>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="custom-control custom-checkbox ml-3">
                                                                    <input
                                                                        className="custom-control-input"
                                                                        type="checkbox"
                                                                        checked={phoneStartsWith06or07}
                                                                    />
                                                                    <Label className="custom-control-label">
                                                                        06 ou 07
                                                                    </Label>
                                                                </div>
                                                            </Col>
                                                        </Row>*/}
                                                    <Col
                                                        xs={12}
                                                    >
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for='orias' className="font-weight-500">
                                                                        Numéro Orias
                                                                        <span className='text-primary'> *</span>
                                                                    </Label>
                                                                    {
                                                                        submitted && !checkField(REGEX.ORIAS, signup.orias) && (
                                                                            <Badge color="danger" className="float-right mb-1 badge-error">
                                                                                Invalide
                                                                            </Badge>
                                                                        )
                                                                    }
                                                                    <InputGroup>
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <InputGroupText>
                                                                                <i className="fas fa-check" />
                                                                            </InputGroupText>
                                                                        </InputGroupAddon>
                                                                        <Input
                                                                            type='text'
                                                                            name='orias'
                                                                            id='orias'
                                                                            value={signup.orias}
                                                                            onChange={(e) => setSignup({ ...signup, orias: e.target.value })}
                                                                        />
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    {/*<div>*/}
                                                    <Col
                                                        xs={12}
                                                    >
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for='password' className="font-weight-500">
                                                                        Mot de passe
                                                                        <span className='text-primary'> *</span>
                                                                    </Label>
                                                                    {
                                                                        submitted && !checkField(REGEX.PASSWORD, signup.password) && (
                                                                            <Badge style={{  whiteSpace: "pre-wrap"}} color="danger" className="float-right mb-1 badge-error">
                                                                              Invalide
                                                                            </Badge>
                                                                        )
                                                                    }
                                                                    <InputGroup>
                                                                        <InputGroupAddon addonType="prepend">
                                                                            <InputGroupText>
                                                                                <i className="ni ni-lock-circle-open" />
                                                                            </InputGroupText>
                                                                        </InputGroupAddon>
                                                                        <Input
                                                                            type='password'
                                                                            name='password'
                                                                            id='password'
                                                                            value={signup.password}
                                                                            onKeyPress={(e) => handleKeyPress(e)}
                                                                            onChange={(e) => setSignup({ ...signup, password: e.target.value })}
                                                                        />
                                                                    </InputGroup>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col
                                                        xs={12}
                                                    >
                                                        <Row className="mt--2 mb-4">
                                                            <Col>
                                                                <div className="custom-control custom-checkbox">
                                                                    <div className={hasMoreThan6Caracters ? 'password-controller-indicator-enabled' : 'password-controller-indicator-disabled'} />
                                                                    <Label className='label'>
                                                                        {">"} 6 caractères
                                                                    </Label>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="custom-control custom-checkbox" >
                                                                    <div className={hasMoreThan1SpecialCaracter ? 'password-controller-indicator-enabled' : 'password-controller-indicator-disabled'} />
                                                                    <Label className='label'>
                                                                        1 caractère spécial
                                                                    </Label>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="custom-control custom-checkbox">
                                                                    <div className={hasMoreThan1CapitalLetter ? 'password-controller-indicator-enabled' : 'password-controller-indicator-disabled'} />
                                                                    <Label className='label'>
                                                                        1 majuscule
                                                                    </Label>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    {/*<Row className="mt--2 mb-4">
                                                            <Col>
                                                                <div className="custom-control custom-checkbox">
                                                                    <input
                                                                        className="custom-control-input"
                                                                        type="checkbox"
                                                                        checked={passwordHasMoreThan6Caracters}
                                                                    />
                                                                    <Label className="custom-control-label">
                                                    > 6 caractères
                                                                    </Label>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="custom-control custom-checkbox">
                                                                    <input
                                                                        className="custom-control-input"
                                                                        type="checkbox"
                                                                        checked={passwordHasMoreThan1SpecialCaracter}
                                                                    />
                                                                    <Label className="custom-control-label">
                                                                        1 caractère spécial
                                                                    </Label>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="custom-control custom-checkbox">
                                                                    <input
                                                                        className="custom-control-input"
                                                                        type="checkbox"
                                                                        checked={passwordHasMoreThan1CapitalLetter}
                                                                    />
                                                                    <Label className="custom-control-label">
                                                                        1 majuscule
                                                                    </Label>
                                                                </div>
                                                            </Col>
                                                        </Row>*/}
                                                    {/*</div>*/}
                                                </Aux>
                                            </Row>
                                        </Form>

                                        <Row className="d-block text-left" style={{ maxWidth: '420px' }}>
                                            <Col>
                                                <p style={{ fontSize: '9px' }}>
                                                    Tribu s&apos;engage à respecter vos données. En continuant votre
                                                    inscription, vous acceptez
                                                    nos
                                                    <Link to={CGUV} className="text-primary"> Conditions Générales</Link>
                                                    .
                                                </p>
                                                <p style={{ fontSize: '9px' }} className='mt--2'>
                                                    Tribu peut utiliser ces informations pour une communication
                                                    personnalisée relative à des
                                                    évènements à venir, des webinars et la plateforme Tribu. Vous
                                                    pouvez vous désinscrire à tout
                                                    moment.
                                                    <br />
                                                    En savoir plus sur
                                                    <Link to={PDD}> notre politique de protection des données.</Link>
                                                </p>
                                            </Col>
                                        </Row>

                                        <Row className="justify-content-center mt-3">
                                            <Col>
                                                <Button
                                                    className="w-100"
                                                    color="primary"
                                                    onClick={handleSubmit}
                                                >
                                                    INSCRIPTION
                                                </Button>
                                            </Col>
                                        </Row>

                                        <Row className="justify-content-center mt-3">
                                            <span className="d-md-none">
                                                Déjà un compte ?
                                                <Link to="/signin" className="text-primary"> Connectez-vous </Link>
                                            </span>
                                        </Row>
                                    </Aux>
                                ) : (
                                    <Row className="justify-content-center">
                                        <ClipLoader color="#5e72e4" size={100} />
                                    </Row>
                                )
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Aux>
    )
}

const mapStateToProps = (state) => ({
    isSignupPending: state.Auth.isSignupPending,
    isSignupSuccess: state.Auth.isSignupSuccess,
    isSignupAlready: state.Auth.isSignupAlready,
    signupError: state.Auth.signupError,
});

export default withRouter(connect(mapStateToProps, {
    signup: actionsSignup,
    setSignupSuccess: actionsSetSignupSuccess,
})(Signup2));