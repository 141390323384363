import React, { Component } from 'react';
import Aux from '../../hoc/Aux';
import DefaultFooter from '../Default/DefaultFooter';
import LandingNavbar from './LandingNavbar';

class LandingLayout extends Component {
    render() {
        const {
            children,
        } = this.props;

        document.body.classList.remove('bg-default');

        return (
            <Aux>
                <div className="main-content">
                    <LandingNavbar />
                    {children}
                    <section className="section pt-6 bg-white">
                        <DefaultFooter />
                    </section>
                </div>
            </Aux>
        );
    }
}

export default LandingLayout;
