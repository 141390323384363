// React Cores
import React, { Component } from 'react';

// components
import {
    Navbar,
    Nav,
    Container,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import UserMenu from './DefaultUserMenu';
import WebsiteSearchBar from './DefaultSearchBar6';
import classes from '../../assets/Scss/components/Navbar.scss';
import Aux from '../../hoc/Aux';
import DefaultNotificationsContacts from './DefaultNotificationsContacts';
import DefaultNotificationsChats from './DefaultNotificationsChats';
import DefaultNotificationsVisits from './DefaultNotificationsVisits';
import { Link } from 'react-router-dom';
import Banner from '../../assets/Pics/Icons/banner.png';

class DefaultNavbar2 extends Component {
    render() {
        const {
            opened,
            user,
            allowNotifications,
            fetched,
            relative
        } = this.props;

        return (
            <Aux>
                <Navbar className={`${classes.Navbar} ${opened ? classes.sidebarOpened : null} navbar-top navbar-dark ${user.roles.includes("CORPORATE") ? "bg-gradient-warning" : "bg-gradient-info"} ${relative ? 'position-relative' : 'position-fixed'} d-none d-md-block`} expand="md" id="navbar-main">
                    <Container fluid>
                        <Link to="/" key="breadcrumb_home">
                            <img
                                alt="Tribu"
                                src={Banner}
                                className="mt--2"
                                style={{ maxWidth: '130px', height: 'auto' }}
                            />
                        </Link>
                        <div className="col-10 col-lg-8 d-none d-md-flex" style={{width: '30%', maxWidth: 750}}>
                            <WebsiteSearchBar onHeader />
                        </div>
                        <Nav className="align-items-center d-none d-md-flex" navbar>
                            {
                                fetched ? (
                                    <Nav className="ml-lg-auto">
                                        <DefaultNotificationsContacts />
                                        <DefaultNotificationsChats allowNotifications={allowNotifications} />
                                        <DefaultNotificationsVisits allowNotifications={allowNotifications} />
                                    </Nav>
                                ) : <div></div>
                            }
                        </Nav>
                        <Nav className="align-items-center d-none d-md-flex" navbar>
                            <UserMenu />
                        </Nav>
                    </Container>
                </Navbar>
            </Aux>
        );
    }
}

const mapStateToProps = (state) => ({
    opened: state.Navbar.opened,
    user: state.User.user,
});

export default connect(mapStateToProps)(DefaultNavbar2);

DefaultNavbar2.propTypes = {
    opened: PropTypes.bool,
};

DefaultNavbar2.defaultProps = {
    opened: false,
};