import PropTypes from "prop-types";
import React from "react";
import { ListGroupItem } from "reactstrap";
import Moment from "react-moment";
import { NavLink } from "react-router-dom";
import ChatThreadMenu from "./ChatThreadMenu";
import classes from "../../assets/Scss/components/Chat.scss";
import DefaultProfile from "../../assets/Pics/defaultProfile.jpg";
import { useState, useEffect } from "react";
import { db } from "../../config/firebase";

const GroupedChatRecent = (props) => {
  const { chat } = props;

  return (
    <ListGroupItem
      className={`list-group-item-action flex-column align-items-start p-3 cursor-pointer ${classes.Recent} w-100`}
      style={{ minHeight: 80 }}
      tag={NavLink}
      to={`/chat/grouped/${chat.id}`}
      activeClassName="active-conversation-bg"
    >
      <div className="d-flex w-100 justify-content-between align-items-start">
        <div className="d-flex justify-content-between align-top w-100">
          <div>
            <h5 className="m-0 text-sm font-weight-medium ">
              À :{" "}
              <small className="m-0 text-sm font-weight-medium ">
                {chat.users[0].name} 
              </small> {" "}
              {
                chat.users.length > 1
                &&  `et ${chat.users.length - 1} ${ chat.users.length === 2 ? 'autre' : 'autres'}`
              }
            
            </h5>

            <p
              className="text-sm mb-0 mt-1 last-message"
              style={{ fontWeight: chat.seen ? "initial" : "bold" }}
            >
              {chat.message}
            </p>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
              justifyContent: "space-between",
            }}
          >
            <small className="d-inline-block">
              <Moment locale="fr" toNow ago>
                {chat.date}
              </Moment>
            </small>
          </div>
          <span className={`d-none ml-2 ${classes.dropdown}`}>
            <ChatThreadMenu chat={chat} />
          </span>
        </div>
      </div>
    </ListGroupItem>
  );
};

export default GroupedChatRecent;

GroupedChatRecent.propTypes = {
  chat: PropTypes.object,
};

GroupedChatRecent.defaultProps = {
  chat: {},
};