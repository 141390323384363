import React, { Component } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { withRouter } from "react-router";
import Button from "reactstrap/es/Button";
import Aux from "../../hoc/Aux";
import GenericForm from "../../components/Ui/GenericForm";
import { recoverPass as recoverPassFields } from "./LoginFields";
import { authApp } from "../../config/firebase";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import Brand from "../../assets/Pics/Icons/tribu-white.svg";

class RecoverPass extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAlert: false,
      showError: false,
      mail: "",
    };

    this.formRef = React.createRef();
  }

  handleResetPassword = () => {
    authApp
      .auth()
      .sendPasswordResetEmail(this.state.mail)
      .then(() => {
        // Email sent.
        this.setState({ showAlert: true, showError: false });
      })
      .catch((error) => {
        // An error happened.
        this.setState({ showError: true, showAlert: false });
      });
  };

  formMap = (form, mail) =>
    form.map((field) => {
      const newField = { ...field };
      newField.value = mail;
      newField.handleChange = this.handleChange;
      return newField;
    });

  handleChange = (e) => {
    const { value } = e.target;

    this.setState({ mail: value });
  };

  /*render() {
    const { showAlert, showError, mail } = this.state;

    const { history } = this.props;

    return (
      <Aux>
        {showAlert && (
          <ReactBSAlert
            warning
            title="Mail envoyé !"
            style={{ display: "block" }}
            onConfirm={() => history.push("/")}
            confirmBtnBsStyle="primary"
            confirmBtnText="Retourner à l'accueil"
            btnSize=""
          >
            Vérifie ta boîte mail.
          </ReactBSAlert>
        )}
        {showError && (
          <ReactBSAlert
            danger
            title="Erreur..."
            style={{ display: "block" }}
            onConfirm={() => history.push("/")}
            confirmBtnBsStyle="danger"
            confirmBtnText="Retourner à l'accueil"
            btnSize=""
          >
            Une erreur s'est produite.
          </ReactBSAlert>
        )}
        <Col xs={11} md={9} lg={7}>
          <Card className="card-profile">
            <CardHeader className="bg-transparent">
              <h5 className="h2 mb-0">Mot de passe oublié ?</h5>
              <small>Pas de soucis, vous pouvez le réinitialiser !</small>
            </CardHeader>
            <CardBody>
              <GenericForm
                onSubmit={this.handleResetPassword}
                fields={this.formMap(recoverPassFields, mail)}
                ref={this.formRef}
              />
              <Button
                color="primary"
                className="float-right"
                onClick={() => this.formRef.current.handleSubmit()}
              >
                Réinitialiser
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Aux>
    );
  }*/
  render() {
    const { showAlert, showError, mail } = this.state;

    const { history } = this.props;

    return (
      <Aux>
        <Helmet>
          <title>Réinitialiser son mot de passe Tribu</title>
          <meta
            name="description"
            content="Si vous avez oublié votre mot de passe, vous pouvez le réinitialiser en un clic. Veuillez renseigner votre adresse électronique, un e-mail de réinitialisation de mot de passe vous sera envoyé."
          />
        </Helmet>
        {showAlert && (
          <ReactBSAlert
            warning
            title="E-mail envoyé !"
            style={{ display: "block" }}
            onConfirm={() => history.push("/")}
            confirmBtnBsStyle="primary"
            confirmBtnText="Retourner à l'accueil"
            btnSize=""
          >
            Merci de vérifier votre boîte de réception.
          </ReactBSAlert>
        )}
        {showError && (
          <ReactBSAlert
            danger
            title="Erreur..."
            style={{ display: "block" }}
            onConfirm={() => history.push("/")}
            confirmBtnBsStyle="danger"
            confirmBtnText="Retourner à l'accueil"
            btnSize=""
          >
            Une erreur s'est produite.
          </ReactBSAlert>
        )}
        <div className="bg-white" style={{minHeight: '90vh'}}>
          <div className="header bg-gradient-info py-9">
            <Container>
              <div className="header-body">
                <Row className="justify-content-center">
                  <Col className="col-auto">
                    <Link to="/">
                      <img
                        className="mb-3"
                        alt="Tribu"
                        src={Brand}
                        style={{ maxWidth: "75px" }}
                      />
                    </Link>
                  </Col>
                </Row>
              </div>
            </Container>
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </div>
          <Container className="pt-5 pb-8  d-flex justify-content-center">
            <Col xs={11} md={9} lg={7}>
              <Card className="card-profile" style={{transform: 'translateY(-170px)'}}>
                <CardHeader className="bg-transparent">
                  <h5 className="h2 mb-0">Mot de passe oublié ?</h5>
                  <small>Pas de soucis, vous pouvez le réinitialiser !</small>
                </CardHeader>
                <CardBody>
                  <GenericForm
                    onSubmit={this.handleResetPassword}
                    fields={this.formMap(recoverPassFields, mail)}
                    ref={this.formRef}
                  />
                  <Button
                    color="primary"
                    className="float-right"
                    onClick={() => this.formRef.current.handleSubmit()}
                  >
                    Réinitialiser
                  </Button>
                </CardBody>
              </Card>
            </Col>
          </Container>
        </div>
      </Aux>
    );
  }
}

export default withRouter(RecoverPass);
