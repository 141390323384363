import React from 'react';
import * as REGEX from '../../data/Regex';

export const signin = [
    {
        label: 'E-mail',
        name: 'id',
        type: 'email',
        error: {
            regex: REGEX.MAIL,
            text: 'INVALIDE',
        },
        prepend: <i className="ni ni-email-83" />,
        size: {
            xs: 12,
        },
    },
    {
        label: 'Mot de passe',
        name: 'password',
        type: 'password',
        prepend: <i className="ni ni-lock-circle-open" />,
        submit: true,
        size: {
            xs: 12,
        },
        required: 'primary',
    },
];

export const signup = [
    {
        label: 'Prénom',
        name: 'firstName',
        type: 'text',
        prepend: <i className="fas fa-user-tie" />,
        error: {
            regex: REGEX.NAME,
            text: 'Invalide',
        },
        size: {
            xs: 6,
        },
    },
    {
        label: 'Nom',
        name: 'lastName',
        type: 'text',
        prepend: <i className="fas fa-user-tie" />,
        error: {
            regex: REGEX.NAME,
            text: 'Invalide',
        },
        size: {
            xs: 6,
        },
    },
    {
        label: 'E-mail',
        name: 'mail',
        type: 'email',
        prepend: <i className="ni ni-email-83" />,
        error: {
            regex: REGEX.MAIL,
            text: 'Invalide',
        },
        size: {
            xs: 6,
        },
    },
    {
        label: 'Téléphone',
        name: 'phone',
        type: 'text',
        prepend: <i className="ni ni-mobile-button" />,
        error: {
            regex: REGEX.PHONE,
            text: 'Invalide',
        },
        required: 'primary',
        size: {
            xs: 6,
        },
    },
    {
        label: 'Numéro Orias',
        name: 'orias',
        type: 'text',
        prepend: <i className="fas fa-check" />,
        error: {
            regex: REGEX.ORIAS,
            text: 'INVALIDE',
        },
        submit: true,
        size: {
            xs: 12,
        },
    },
    /*{
        label: 'Numéro Siret',
        name: 'siret',
        type: 'text',
        prepend: <i className="fas fa-check" />,
        error: {
            regex: REGEX.SIRET,
            text: 'Ce numéro n\'est pas correct',
        },
        submit: true,
        size: {
            xs: 12,
        },
    },*/
    {
        label: 'Mot de passe',
        name: 'password',
        type: 'password',
        prepend: <i className="ni ni-lock-circle-open" />,
        error: {
            regex: REGEX.PASSWORD,
            text: 'Invalide',
        },
        submit: true,
        size: {
            xs: 12,
        },
    },
];

export const corporateSignup = [
    {
        label: 'Prénom',
        name: 'firstName',
        type: 'text',
        prepend: <i className="fas fa-user-tie" />,
        required: 'warning',
        error: {
            regex: REGEX.NAME,
            text: 'Invalide',
        },
        size: {
            xs: 6,
        },
    },
    {
        label: 'Nom',
        name: 'lastName',
        type: 'text',
        prepend: <i className="fas fa-user-tie" />,
        required: 'warning',
        error: {
            regex: REGEX.NAME,
            text: 'Invalide',
        },
        size: {
            xs: 6,
        },
    },
    {
        label: 'E-mail',
        name: 'mail',
        type: 'email',
        prepend: <i className="ni ni-email-83" />,
        required: 'warning',
        error: {
            regex: REGEX.MAIL,
            text: 'Invalide',
        },
        size: {
            xs: 6,
        },
    },
    {
        label: 'Téléphone',
        name: 'phone',
        type: 'text',
        prepend: <i className="ni ni-mobile-button" />,
        required: 'warning',
        size: {
            xs: 6,
        },
    },
    {
        label: 'Mot de passe',
        name: 'password',
        type: 'password',
        prepend: <i className="ni ni-lock-circle-open" />,
        required: 'warning',
        error: {
            regex: REGEX.PASSWORD,
            text: 'Invalide',
        },
        submit: true,
        size: {
            xs: 12,
        },
    },
];

export const corporateSignupWithOrias = [
    {
        label: 'Prénom',
        name: 'firstName',
        type: 'text',
        prepend: <i className="fas fa-user-tie" />,
        required: 'warning',
        error: {
            regex: REGEX.NAME,
            text: 'Invalide',
        },
        size: {
            xs: 12,
        },
    },
    {
        label: 'Nom',
        name: 'lastName',
        type: 'text',
        prepend: <i className="fas fa-user-tie" />,
        required: 'warning',
        error: {
            regex: REGEX.NAME,
            text: 'Invalide',
        },
        size: {
            xs: 12,
        },
    },
    {
        label: 'E-mail',
        name: 'mail',
        type: 'email',
        prepend: <i className="ni ni-email-83" />,
        required: 'warning',
        error: {
            regex: REGEX.MAIL,
            text: 'Invalide',
        },
        size: {
            xs: 12,
        },
    },
    {
        label: 'Portable',
        name: 'phone',
        type: 'text',
        prepend: <i className="ni ni-mobile-button" />,
        required: 'warning',
        size: {
            xs: 12,
        },
    },
    {
        label: 'Numéro Orias',
        name: 'orias',
        type: 'text',
        prepend: <i className="fas fa-check" />,
        error: {
            regex: REGEX.ORIAS,
            text: 'Invalide',
        },
        required: 'warning',
        submit: true,
        size: {
            xs: 12,
        },
    },
    {
        label: 'Mot de passe',
        name: 'password',
        type: 'password',
        prepend: <i className="ni ni-lock-circle-open" />,
        required: 'warning',
        error: {
            regex: REGEX.PASSWORD,
            text: 'Invalide',
        },
        submit: true,
        size: {
            xs: 12,
        },
    },
];

export const corporateSignupWithoutOrias = [
    {
        label: 'Prénom',
        name: 'firstName',
        type: 'text',
        prepend: <i className="fas fa-user-tie" />,
        required: 'warning',
        error: {
            regex: REGEX.NAME,
            text: 'Invalide',
        },
        size: {
            xs: 12,
        },
    },
    {
        label: 'Nom',
        name: 'lastName',
        type: 'text',
        prepend: <i className="fas fa-user-tie" />,
        required: 'warning',
        error: {
            regex: REGEX.NAME,
            text: 'Invalide',
        },
        size: {
            xs: 12,
        },
    },
    {
        label: 'E-mail',
        name: 'mail',
        type: 'email',
        prepend: <i className="ni ni-email-83" />,
        required: 'warning',
        error: {
            regex: REGEX.MAIL,
            text: 'Invalide',
        },
        size: {
            xs: 12,
        },
    },
    {
        label: 'Portable',
        name: 'phone',
        type: 'text',
        prepend: <i className="ni ni-mobile-button" />,
        required: 'warning',
        size: {
            xs: 12,
        },
    },
    {
        label: 'Siren',
        name: 'siren',
        type: 'text',
        prepend: <i className="fas fa-check" />,
        error: {
            regex: REGEX.SIREN,
            text: 'Invalide',
        },
        required: 'warning',
        submit: true,
        size: {
            xs: 12,
        },
    },
    {
        label: 'Mot de passe',
        name: 'password',
        type: 'password',
        prepend: <i className="ni ni-lock-circle-open" />,
        required: 'warning',
        error: {
            regex: REGEX.PASSWORD,
            text: 'Invalide',
        },
        submit: true,
        size: {
            xs: 12,
        },
    },
];

export const recoverPass = [
    {
        label: 'Votre adresse e-mail',
        name: 'mail',
        type: 'email',
        prepend: <i className="ni ni-email-83" />,
        required: 'primary',
        submit: true,
        error: {
            regex: REGEX.MAIL,
            text: 'Invalide',
        },
        size: {
            xs: 12,
        },
    },
];
