import * as actionTypes from '../actionConsts';

const initialState = {
    opened: localStorage.getItem('navbar') === 'true' || false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_NAVBAR_OPENED:
            return {
                ...state,
                opened: action.opened,
            };

        default:
            return state;
    }
};

export default reducer;
