// React Core
import React, { Component } from 'react';

// components
import { Container, Row, Col } from 'reactstrap';
import { withRouter } from 'react-router';
import Brand from '../../assets/Pics/Icons/tribu-white.svg';
import LandingNavbar from '../Landing/LandingNavbar';

class AuthLayout extends Component {
    render() {
        const {
            children,
            match,
        } = this.props;

        return (
            <div className="h-100vh bg-default">
                <div className="main-content">
                    <LandingNavbar login={match.path === '/signin'} signup={match.path === '/signup'} />
                    <div className="header bg-gradient-info py-6 py-lg-7">
                        <Container>
                            <div className="header-body text-center mb-7">
                                <Row className="justify-content-center">
                                    <Col lg="5" md="6" className='d-flex justify-content-center'>
                                        <img alt="Brand Icon" src={Brand} style={{ maxWidth: '100px' }} />
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        <div className="separator separator-bottom separator-skew zindex-100">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                                viewBox="0 0 2560 100"
                                x="0"
                                y="0"
                            >
                                <polygon
                                    className="fill-default"
                                    points="2560 0 2560 100 0 100"
                                />
                            </svg>
                        </div>
                    </div>
                    <Container className="mt--8 pb-5">
                        <Row className="justify-content-center">
                            {children}
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

export default withRouter(AuthLayout);
