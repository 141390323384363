import PropTypes from "prop-types";
import React, { Component } from "react";
import { Col, Form, Input, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { dbAddComment as actionsAddComment } from "../../store/posts/actions";

class AddCommentary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      comment: "",
    };
  }

  handleAddComment = (e) => {
    e.preventDefault();
    const { comment } = this.state;
    const { post, addComment, user } = this.props;

    if (comment === "")  return
    
    addComment(post.id, user.id, comment);

    this.setState({ comment: "" });
  };

  render() {
    const { comment } = this.state;

    const { user } = this.props;

    return (
      <Form onSubmit={this.handleAddComment}>
        <Row style={{ display: "flex", alignItems: "center" }}>
          <Col className="col-auto pr-0">
          <Link  target='_blank'  to={`/user/${user.username}/about`}>
              <img
                className="avatar rounded-circle"
                alt=""
                src={user.profilePic}
                style={{
                  maxWidth: "38px",
                  maxHeight: "38px",
                }}
              />
            </Link>
          </Col>
          <Col className="pl-3 p-0">
            <Input
                className="comments"
              type="text"
              placeholder="Commenter..."
              value={comment}
              bsSize="sm"
              onChange={(e) => this.setState({ comment: e.target.value })}
            />
          </Col>
          <Col className="col-auto " role="button">
            <i class="fas fa-paper-plane" style={{cursor: 'pointer'}} onClick={this.handleAddComment}></i>
          </Col>
        </Row>
      </Form>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.User.user,
});

export default connect(mapStateToProps, {
  addComment: actionsAddComment,
})(AddCommentary);

AddCommentary.propTypes = {
  addComment: PropTypes.func,
  post: PropTypes.object,
  user: PropTypes.object,
};

AddCommentary.defaultProps = {
  addComment: () => {},
  post: {},
  user: {},
};
