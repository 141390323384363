export const SET_POST_LOADING = 'SET_POST_LOADING';

export const ADD_POST_LIST = 'ADD_POST_LIST';
export const ADD_POST = 'ADD_POST';
export const DELETE_POST = 'DELETE_POST';
export const RESET_POSTS = 'RESET_POSTS';

export const SET_POST_EDITING_POST = 'SET_POST_EDITING_POST';

export const SET_LAST_POST_DATE = 'SET_LAST_POST_DATE';

export const SET_POST_LIKES_LOADING = 'SET_POST_LIKES_LOADING';
export const SET_LIKES = 'SET_LIKES';
export const SET_POST_USER_LIKES = 'SET_POST_USER_LIKES';

export const SET_POST_COMMENTS = 'SET_POST_COMMENTS';
