import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
    CardHeader, Col, Label, Row,
} from 'reactstrap';
import flipArrow from '../../assets/Scss/components/CollpaseArrow.scss';
import CollapseButton from '../../components/Ui/CollapseButton';
import Aux from '../../hoc/Aux';

class SearchFiltersCategories extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: true,
        };
    }

    render() {
        const {
            opened,
        } = this.state;

        const {
            selectedCategories,
            handleSelectCategory,
        } = this.props;

        return (
            <Aux>
                <CardHeader className={`align-items-center ${flipArrow.FlipArrow}`} onClick={() => this.setState({ opened: !opened })}>
                    <Row>
                        <Col>
                            <h5 className="h4 mb-0">Catégories</h5>
                        </Col>
                        <Col className="col-auto">
                            <CollapseButton collapsed={!opened} />
                        </Col>
                    </Row>
                </CardHeader>
                {
                    opened && (
                        <CardHeader className="pb-1">
                            {
                                [{
                                    "label": "IOBSP",
                                    "name": "IOBSP"
                                },
                                {
                                    "label": "IAS",
                                    "name": "IAS"
                                },
                                {
                                    "label": "CIF",
                                    "name": "CIF"
                                }].map((filter, key) => {
                                    const index = `categories_${key}`;
                                    const checked = selectedCategories.includes(filter.name);

                                    return (
                                        <div className="custom-control custom-checkbox mb-3" key={index}>
                                            <input
                                                className="custom-control-input"
                                                id={index}
                                                type="checkbox"
                                                name={filter.name}
                                                checked={checked}
                                                onChange={handleSelectCategory}
                                            />
                                            <Label className="custom-control-label" htmlFor={index}>
                                                {filter.label}
                                            </Label>
                                        </div>
                                    );
                                })
                            }
                            <div className="custom-control custom-checkbox mb-3">
                                <input
                                    id={33}
                                    className="custom-control-input"
                                    type="checkbox"
                                    checked={this.props.otherServices}
                                />
                                <Label className="custom-control-label" htmlFor={33}>
                                    Autres
                                </Label>
                            </div>
                        </CardHeader>
                    )
                }
            </Aux>

        );
    }
}

export default SearchFiltersCategories;

SearchFiltersCategories.propTypes = {
    handleSelectCategory: PropTypes.func,
    selectedCategories: PropTypes.array,
};

SearchFiltersCategories.defaultProps = {
    handleSelectCategory: () => { },
    selectedCategories: [],
};
