import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Col,
    Modal, ModalBody, ModalHeader, Row,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { dbFetchPostLikes as postsFetchPostLikes } from '../../store/posts/actions';

class LikesModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            maxLikes: 10,
        };
    }

    componentDidUpdate(prevProps) {
        const { fetchPostLikes, postID, isOpen } = this.props;
        const { maxLikes } = this.state;

        if (!prevProps.isOpen && isOpen) {
            fetchPostLikes(postID, maxLikes);
        }
    }

    scrollHandler = () => {
        /* const { maxLikes } = this.state;
        const {
            fetchPostLikes, postID, likesLoading, nbOfLikes,
        } = this.props;
        const { scrollHeight, scrollTop, offsetHeight } = this.likesList;

        if (scrollHeight - (scrollTop + offsetHeight) < 50 && (nbOfLikes - maxLikes > 0) && !likesLoading) { // TO DO Add verification when no more likes
            fetchPostLikes(postID, maxLikes + 20);
            this.setState({ maxLikes: maxLikes + 20 });
        } */
    };

    render() {
        const {
            isOpen,
            toggle,
            likesLoading,
            likes,
        } = this.props;

        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        }

        return (
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}><span style={{ fontSize: '15px' }}>Réactions</span></ModalHeader>
                <ModalBody className="pr-0 pt-0">
                    <ul
                        className="list my--3 list-group list-group-flush"
                        style={{
                            minHeight: '10vh',
                            maxHeight: '50vh',
                            overflowY: 'auto',
                            overflowX: 'hidden',
                        }}
                        onScroll={this.scrollHandler}
                        ref={(div) => {
                            this.likesList = div;
                        }}
                    >
                        {
                            likes.map((like) => (
                                <li key={like.id} className="px-0 list-group-item">
                                    <Row className="align-items-center row">
                                        <Link to={`/user/${like.username}`}>
                                            <Col className="col-auto col">
                                                <img
                                                    alt=''
                                                    className="avatar rounded-circle"
                                                    src={like.profilePic}
                                                />
                                            </Col>
                                        </Link>
                                        <Col className="ml--3">
                                            <Row
                                                className="d-flex justify-content-between align-items-center"
                                            >
                                                <Col>
                                                    <Link to={`/user/${like.username}`}>
                                                        <h4 className="mb-0 text-sm">{`${capitalizeFirstLetter(like.firstName)} ${like.lastName.toUpperCase()}`}</h4>
                                                    </Link>
                                                    <p className="text-sm mb-0">{like.company}</p>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </li>
                            ))
                        }
                        {
                            likesLoading && (
                                <Row className="justify-content-center mt-4">
                                    <ClipLoader color="#1171ef" size={30} />
                                </Row>
                            )
                        }
                    </ul>
                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    ...state,
    likesLoading: state.Posts.likesLoading,
    likes: state.Posts.likes,
});

const mapDispatchToProps = (dispatch) => ({
    fetchPostLikes: (postID, max) => dispatch(postsFetchPostLikes(postID, max)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LikesModal);

LikesModal.propTypes = {
    fetchPostLikes: PropTypes.func.isRequired,
    isOpen: PropTypes.bool,
    likes: PropTypes.array,
    likesLoading: PropTypes.bool,
    postID: PropTypes.string,
    toggle: PropTypes.func,
};

LikesModal.defaultProps = {
    isOpen: false,
    likes: [],
    likesLoading: false,
    postID: null,
    toggle: () => {},
};
