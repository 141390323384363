import { Link } from "react-router-dom";
import Aux from "../../../hoc/Aux";
import PageComponent from "../PageComponent";
import "../custom.css";

function Followers() {
  return (
    <Aux>
      <PageComponent>
        <h1 className="help-item-title">Abonnés et Abonnements</h1>
        <p className="help-item-text">
          Suivre un profil ou une entreprise signifie que vous choisissez de
          vous abonner à ses messages publiés sur Tribu. De cette manière,
          chaque message publié s'affichera directement dans votre fil
          d'actualités.
          <br />
          <br />
          Vous retrouverez l’ensemble de vos abonnements depuis l’onglet
          Abonnements de votre profil.
          <p className="help-item-subtitle">Abonnement à un profil</p>
          Une fois le membre trouvé, vous pouvez cliquer sur le lien "Suivre"
          présent sur le profil (à gauche de son nom).
          <br />
          <br />
          Une fois abonné à ce profil, le bouton "Suivre" est grisé sur sa
          fiche.
          <br />
          <br />
          Vous pouvez vous désabonner à tout moment. Tribu n'envoie aucune
          notification lorsque vous vous désabonnez d'un profil.
          <br />
          <br />
          Votre destinataire peut prendre connaissance de votre abonnement grâce
          à l'email d'alerte envoyé automatiquement. Cependant, si son compte
          n’a pas encore été vérifié, il est nécessaire d'attendre que votre
          correspondant se connecte sur le site. Ceci peut rendre variable la
          durée de notification de votre abonnement.
          <p className="help-item-subtitle">Abonnement à une page entreprise</p>
          Une fois, l’entreprise trouvée, vous pouvez cliquer sur le lien
          "Suivre" présent sur la page (à gauche de son nom).
          <br />
          <br />
          Une fois abonné à cette entreprise, le bouton "Suivre" est grisé sur
          sa fiche.
          <br />
          <br />
          Vous pouvez vous désabonner à tout moment. Tribu n'envoie aucune
          notification lorsque vous vous désabonnez d'une entreprise.
          <p className="help-item-subtitle">Abonnés</p>
          Vous retrouverez l’ensemble des abonnés à votre profil depuis l’onglet
          Abonnés de votre profil.
          <br />
          <br />
          Vous retrouverez l’ensemble des abonnés à votre entreprise depuis
          l’onglet Abonnés de votre page entreprise.
        </p>
      </PageComponent>
    </Aux>
  );
}

export default Followers;
