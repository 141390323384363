import PropTypes from "prop-types";
import React, { Component } from "react";
import { Card, CardHeader, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import CardBody from "reactstrap/es/CardBody";
import { withRouter } from "react-router";
import { actions as actionsSearch } from "../../store/search/actions";
import { actions2 as actionsSearch2 } from "../../store/search/actions";
import { actions3 as actionsSearch3 } from "../../store/search/actions";
import { actions4 as actionsSearch4 } from "../../store/search/actions";
import { actions7 as actionsSearch7 } from "../../store/search/actions";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { db, firebaseApp } from "../../config/firebase";
import Aux from "../../hoc/Aux";
import PremiumModal from "../Premium/PremiumModal";
import ProfileCard from "../Profile/ProfileCard";
import { Helmet } from "react-helmet";

class SearchProfiles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      isCurrentUserPremium: false,
      premiumModal: false,
    };
  }

  categories;

  categories = ["IOBSP", "IAS", "CIF", "ALPSI", "CIP", "IFP"];
  services = [
    "Crédit Immobilier",
    "Rachat de crédits",
    "Rachat de crédits consommation",
    "Rachat de crédits consommation et immobilier",
    "Crédit professionnel",
    "Prêt professionnel",
    "Crédit bail immobilier",
    "Crédit bail mobilier",
    "Location financière",
    "Location longue durée",
    "Affacturage",
    "Assurance crédit",
    "Epargne",
    "Assurance-vie",
    "Contrat de capitalisation",
    "Assurance famille",
    "Garantie des accidents de la vie",
    "Protection juridique",
    "Assurance décès",
    "Assurance scolaire",
    "Assurance emprunteur",
    "Garantie obsèques",
    "Assurance animaux",
    "Assurance dépendance",
    "Assurance rapatriement",
    "Assurance habitation",
    "Assurance locataire",
    "Propriétaire non-occupant",
    "Assurance loisirs",
    "Assurance plaisance",
    "Assurance voyage",
    "Assurance chasse",
    "Assurance sport",
    "Assurance santé",
    "Complémentaire santé",
    "Sur-complémentaire",
    "Assurance auto",
    "Assurance 2 roues",
    "Assurance voiture sans permis",
    "Assurance quad",
    "Assurance camping-car",
    "Assurance malussé",
    "Assurance du chef d'entreprise",
    "Prévoyance pro",
    "Santé pro",
    "Retraite pro",
    "Perte d’emploi",
    "Loi Madelin",
    "Responsabilité civile des mandataires sociaux",
    "Assurance homme-clé",
    "Assurance construction",
    "Dommage-ouvrage",
    "Tous risques chantier",
    "Constructeur non réalisateur",
    "Responsabilité civile décennale",
    "Garantie financière d’achèvement",
    "Responsabilité civile promoteur",
    "Multirisque professionnelle",
    "Bris de machine",
    "Dommage aux biens",
    "Locaux professionnels",
    "Responsabilité civile d’exploitation",
    "Protection juridique pro",
    "Perte d’exploitation",
    "Risques informatiques",
    "Responsabilité civile professionnelle",
    "RC Pro Agriculteur",
    "RC Pro Artisan",
    "RC Pro Association",
    "RC Pro Auto-entrepreneur",
    "RC Pro Commerçant",
    "RC Pro Profession libérale",
    "Assurance des salariés",
    "Prévoyance collective",
    "Epargne salariale",
    "Santé collective",
    "Retraite collective",
    "Assurance des véhicules",
    "Flotte automobile",
    "Transport de marchandises",
    "Transport de voyageurs",
    "Flotte motocycle",
    "Assurance du véhicule",
    "Compte-titres pro",
    "Compte à terme pro",
    "PEE",
    "PERCO",
    "Article 83",
    "Girardin industriel",
    "PEA",
    "Compte-titres",
    "Compte à terme",
    "FIP",
    "FCPI",
    "SCPI",
    "OPCI",
  ];

  departmentsObj = {
    Agen: "Lot-et-Garonne",
    Ajaccio: "Corse-du-Sud",
    Albi: "Tarn",
    Alençon: "Orne",
    Amiens: "Somme",
    Angers: "Maine-et-Loire",
    Angoulême: "Charente",
    Annecy: "Haute-Savoie",
    Arras: "Pas-de-Calais",
    Auch: "Gers",
    Aurillac: "Cantal",
    Auxerre: "Yonne",
    Avignon: "Vaucluse",
    "Bar-le-Duc": "Meuse",
    "Basse-Terre": "Guadeloupe",
    Bastia: "Haute-Corse",
    Beauvais: "Oise",
    Belfort: "Territoire de Belfort",
    Besançon: "Doubs",
    Blois: "Loir-et-Cher",
    Bobigny: "Seine-Saint-Denis",
    Bordeaux: "Gironde",
    "Bourg-en-Bresse": "Ain",
    Bourges: "Cher",
    Caen: "Calvados",
    Cahors: "Lot",
    Carcassonne: "Aude",
    Cayenne: "Guyane",
    "Châlons-en-Champagne": "Marne",
    Chambéry: "Savoie",
    "Charleville-Mézières": "Ardennes",
    Chartres: "Eure-et-Loir",
    Châteauroux: "Indre",
    Chaumont: "Haute-Marne",
    "Clermont-Ferrand": "Puy-de-Dôme",
    Colmar: "Haut-Rhin",
    Créteil: "Val-de-Marne",
    "Digne-les-Bains": "Alpes-de-Haute-Provence",
    Dijon: "Côte-d'Or",
    Epinal: "Vosges",
    Evreux: "Eure",
    Evry: "Essonne",
    Foix: "Ariège",
    "Fort-de-France": "Martinique",
    Gap: "Hautes-Alpes",
    Grenoble: "Isère",
    Guéret: "Creuse",
    "La Rochelle": "Charente-Maritime",
    "La Roche-sur-Yon": "Vendée",
    Laon: "Aisne",
    Laval: "Mayenne",
    "Le Mans": "Sarthe",
    "Le Puy-en-Velay": "Haute-Loire",
    Lille: "Nord",
    Limoges: "Haute-Vienne",
    "Lons-le-Saunier": "Jura",
    Lyon: "Rhône",
    Mâcon: "Saône-et-Loire",
    Marseille: "Bouches-du-Rhône",
    Melun: "Seine-et-Marne",
    Mende: "Lozère",
    Metz: "Moselle",
    "Mont-de-Marsan": "Landes",
    Montauban: "Tarn-et-Garonne",
    Montpellier: "Hérault",
    Moulins: "Allier",
    Nancy: "Meurthe-et-Moselle",
    Nanterre: "Hauts-de-Seine",
    Nantes: "Loire-Atlantique",
    Nevers: "Nièvre",
    Nice: "Alpes-Maritimes",
    Nîmes: "Gard",
    Niort: "Deux-Sèvres",
    Orléans: "Loiret",
    Paris: "Paris",
    Pau: "Pyrénées-Atlantiques",
    Périgueux: "Dordogne",
    Perpignan: "Pyrénées-Orientales",
    Poitiers: "Vienne",
    Pontoise: "Val-d'Oise",
    Privas: "Ardèche",
    Quimper: "Finistère",
    Rennes: "Ille-et-Vilaine",
    Rodez: "Aveyron",
    Rouen: "Seine-Maritime",
    "Saint-Brieuc": "Côtes-d'Armor",
    "Saint-Denis": "Réunion",
    "Saint-Étienne": "Loire",
    "Saint-Lô": "Manche",
    Strasbourg: "Bas-Rhin",
    Tarbes: "Hautes-Pyrénées",
    Toulon: "Var",
    Toulouse: "Haute-Garonne",
    Tours: "Indre-et-Loire",
    Troyes: "Aube",
    Tulle: "Corrèze",
    Valence: "Drôme",
    Vannes: "Morbihan",
    Versailles: "Yvelines",
    Vesoul: "Haute-Saône",
  };

  componentDidMount() {
    this.setState({ loading: true });
    const { search, search2, search3, search4, search7, location } = this.props;
    const params = new URLSearchParams(location.search);

    if (params.get("l") !== null && params.get("d") === null) {
      axios
        .get(
          `https://geo.api.gouv.fr/communes?nom=${params.get(
            "l"
          )}&fields=departement&boost=population&limit=5`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          const department = res.data[0]?.departement.nom;
          
          search(
            "users",
            params.get("q") || "",
            params.get("c") || "",
            params.get("s") || "",
            "",
            department
          );
          this.setState({ loading: false });
        });
    } else {
      if (params.get("e") !== null) {
        search2(params.get("e"));
        this.setState({ loading: false });
      } else if (this.services.includes(params.get("q"))) {
        search3(params.get("q"));
        this.setState({ loading: false });
      } else if (this.categories.includes(params.get("q"))) {
        search4(params.get("q"));
        this.setState({ loading: false });
      } else if (params.get("d") !== null) {
        search7(this.departmentsObj[params.get("d")]);
        this.setState({ loading: false });
      } else {
        search(
          "users",
          params.get("q") || "",
          params.get("c") || "",
          params.get("s") || "",
          params.get("l") || "",
          params.get("l") || "",
          this.departmentsObj[params.get("d")] || ""
        );
        this.setState({ loading: false });
      }
    }
    const userId = firebaseApp.auth().currentUser.uid;
    db.collection("users")
      .doc(userId)
      .get()
      .then((doc) => {
        const premium = doc.get("premium");
        this.setState({ isCurrentUserPremium: premium });
      });
  }

  componentDidUpdate(prevProps) {
    const { location: prevLocation } = prevProps;
    const { search, search2, search3, search4, search7, location } = this.props;

    if (prevLocation.search !== location.search) {
      this.setState({ loading: true });
      const params = new URLSearchParams(location.search);

      if (params.get("l") !== null && params.get("d") === null) {
        axios
          .get(
            `https://geo.api.gouv.fr/communes?nom=${params.get(
              "l"
            )}&fields=departement&boost=population&limit=5`,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            const department = res.data[0]?.departement.nom;
            search(
              "users",
              params.get("q") || "",
              params.get("c") || "",
              params.get("s") || "",
              "",
              department
            );
            this.setState({ loading: false });
          });
      } else {
        if (params.get("e") !== null) {
          search2(params.get("e"));
          this.setState({ loading: false });
        } else if (this.services.includes(params.get("q"))) {
          search3(params.get("q"));
          this.setState({ loading: false });
        } else if (this.categories.includes(params.get("q"))) {
          search4(params.get("q"));
          this.setState({ loading: false });
        } else if (params.get("d") !== null) {
          search7(this.departmentsObj[params.get("d")]);
          this.setState({ loading: false });
        } else {
          search(
            "users",
            params.get("q") || "",
            params.get("c") || "",
            params.get("s") || "",
            params.get("l") || "",
            this.departmentsObj[params.get("d")] || ""
          );
          this.setState({ loading: false });
        }
      }
    }
  }

  render() {
    const { isCurrentUserPremium, premiumModal } = this.state;
    const { results, location } = this.props;

    const params = new URLSearchParams(location.search);

    if (this.state.loading) {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: 50,
          }}
        >
          <CircularProgress />
        </div>
      );
    }

    return (
      <Aux>
        <PremiumModal
          isOpen={premiumModal}
          toggle={() => this.setState({ premiumModal: !premiumModal })}
        />
        <Card className="shadow">
          <CardHeader>
            <Row className="align-items-center">
              <Col>
                {params.get("q") ? (
                  <h5 className="h3 mb-0">
                    {`${results.length} ${
                      results.length > 1 ? "résultats" : "résultat"
                    } ${
                      results.length > 1 ? "correspondent" : "correspond"
                    } à "${params.get("q")}"`}
                  </h5>
                ) : params.get("e") ? (
                  <h5 className="h3 mb-0">
                    {`${results.length} ${
                      results.length > 1 ? "résultats" : "résultat"
                    } ${
                      results.length > 1 ? "correspondent" : "correspond"
                    } à "${params.get("e")}"`}
                  </h5>
                ) : params.get("d") ? (
                  <h5 className="h3 mb-0">
                    {`${results.length} ${
                      results.length > 1 ? "résultats" : "résultat"
                    } ${
                      results.length > 1 ? "correspondent" : "correspond"
                    } à "${params.get("d")}"`}
                  </h5>
                ) : (
                  <h5 className="h3 mb-0">Recherche incomplète</h5>
                )}
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row>
              {params.get("q") || params.get("e") || params.get("d") ? (
                results.map((profile) => {
                  return profile.name === undefined ? (
                    <Col key={profile.objectID} xs={4} className="mb-3">
                      {isCurrentUserPremium ? (
                        <Link
                          target="_blank"
                          className="text-primary w-100"
                          to={`/user/${profile.username}/about`}
                        >
                          <ProfileCard
                            isCurrentUserPremium={isCurrentUserPremium}
                            profile={profile}
                            toggle={() => {
                              this.setState({ premiumModal: true });
                            }}
                          />
                        </Link>
                      ) : (
                        <ProfileCard
                          isCurrentUserPremium={isCurrentUserPremium}
                          profile={profile}
                          toggle={() => {
                            this.setState({ premiumModal: true });
                          }}
                        />
                      )}
                    </Col>
                  ) : (
                    <Col key={profile.objectID} xs={4} className="mb-3">
                      {isCurrentUserPremium ? (
                        <Link
                          target="_blank"
                          className="text-primary w-100"
                          to={`/company/${profile.username}/about`}
                        >
                          <ProfileCard
                            isCurrentUserPremium={isCurrentUserPremium}
                            profile={profile}
                            toggle={() => {
                              this.setState({ premiumModal: true });
                            }}
                          />
                        </Link>
                      ) : (
                        <ProfileCard
                          isCurrentUserPremium={isCurrentUserPremium}
                          profile={profile}
                          toggle={() => {
                            this.setState({ premiumModal: true });
                          }}
                        />
                      )}
                    </Col>
                  );
                })
              ) : (
                <div></div>
              )}
            </Row>
          </CardBody>
        </Card>
      </Aux>
    );
  }
}

const mapDispatchToProps = (state) => ({
  results: state.Search.results,
});

export default withRouter(
  connect(mapDispatchToProps, {
    search: actionsSearch,
    search2: actionsSearch2,
    search3: actionsSearch3,
    search4: actionsSearch4,
    search7: actionsSearch7,
  })(SearchProfiles)
);

SearchProfiles.propTypes = {
  results: PropTypes.array,
  search: PropTypes.func,
  search2: PropTypes.func,
};

SearchProfiles.defaultProps = {
  results: [],
  search: () => {},
  search2: () => {},
};
