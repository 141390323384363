import React, { Component } from "react";
import { Button, Card, Col, Container, Row } from "reactstrap";
import Aux from "../../hoc/Aux";
import SearchFilters2 from "../Search/SearchFilters2";
import SearchProfiles2 from "./SearchProfiles2";
import SignupModal from "../Login/SignupModal";
import DefaultFooter from "../../layouts/Default/DefaultFooter";
import LandingNavbar from "../../layouts/Landing/LandingNavbar";
import LandingHeader2 from "../../layouts/Landing/LandingHeader2";
import "../Search/custom.css";
import { ClipLoader } from "react-spinners";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router";
import CITIES from "../Search/cities";
import SERVICES from "../Search/services";
import CATEGORIES from "../Search/categories";
import SEARCH_METADATAS from "./search_metadatas";

class Search2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loginModal: false,
      showLoader: false,
      width: 768,
      widthForMobile: 576,
      filterMobileMode: false,
      searchCategory: "",
      searchService: "",
      searchCity: "",
      currentCityItem: undefined,
      currentSearchIndexItem: undefined,
      previousFilterURL: "",
    };

    this.setFilterMobileMode = this.setFilterMobileMode.bind(this);
    this.handler = this.handler.bind(this);
  }

  handleResize = () => {
    this.setState({ width: window.innerWidth });
    if (!this.checkIfMobile()) this.setFilterMobileMode(false);
  };

  componentDidMount() {
    this.handleSetCurrentSearchIndexItem();
    const searchParams = new URLSearchParams(this.props.location.search);
    const q = searchParams.get("q");
    const c = searchParams.get("c");
    const d = searchParams.get("d");

    if (q) {
      if (c)
        this.setState({
          searchCategory: q.toString(),
          searchCity: "",
          searchService: "",
        });
      else
        this.setState({
          searchCategory: "",
          searchCity: "",
          searchService: q.toString(),
        });
    }
    if (d)
      this.setState({
        searchCategory: "",
        searchCity: d.toString(),
        searchService: "",
      });

    this.setState({ width: window.innerWidth });

    window.addEventListener("resize", this.handleResize);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search)
      this.handleSetCurrentSearchIndexItem();
  }

  handleSetCurrentSearchIndexItem() {
    const searchParams = new URLSearchParams(this.props.location.search);
    const search = searchParams.get("q") ?? "";
    const category = searchParams.get("c") ?? searchParams.get("q") ?? "";
    const city = searchParams.get("d") ?? searchParams.get("l") ?? "";

    const currentCity =
      CITIES.find((el) => el.city === decodeURI(city))?.city || "";
    const currentService =
      SERVICES.find((el) => el.service === decodeURI(search))?.service || "";
    const currentCategory =
      CATEGORIES.find((el) => el.category === decodeURI(category))?.category ||
      "";
    const currentUrl = decodeURI(
      this.props.location.pathname + this.props.location.search
    );

    var currentSearchIndexItem = {
      ...SEARCH_METADATAS.find((el) => {
        if (el?.url && decodeURI(el.url) === currentUrl) return true;
        else if (
          el?.category &&
          el?.service === undefined &&
          decodeURI(el.category) === currentCategory
        )
          return true;
        else if (
          el?.category &&
          el?.service &&
          decodeURI(el.service) === currentService
        )
          return true;
      }),
    };

    const regexCity = new RegExp("{{city}}", "gi");

    if (
      currentSearchIndexItem &&
      currentSearchIndexItem?.url === undefined &&
      currentSearchIndexItem?.title !== undefined
    ) {
      if (currentCity !== "") {
        currentSearchIndexItem.title = `${currentSearchIndexItem.title} à ${currentCity}`;
        currentSearchIndexItem.description = `${currentSearchIndexItem.description.replace(
          "de France",
          `à ${currentCity}`
        )} `;
        currentSearchIndexItem.keywords = `${currentSearchIndexItem.keywords.replace(
          regexCity,
          ` ${currentCity}`
        )}`;
      } else {
        currentSearchIndexItem.keywords = `${currentSearchIndexItem.keywords.replace(
          regexCity,
          ""
        )}`;
      }
    }

    this.setState({
      currentSearchIndexItem: currentSearchIndexItem,
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handler() {
    this.setState({
      loginModal: true,
    });
  }

  checkIfMobile() {
    return this.state.width <= this.state.widthForMobile;
  }

  setFilterMobileMode(value) {
    this.setState({
      filterMobileMode: value,
      previousFilterURL: window.location.pathname + window.location.search,
    });
  }

  handleFilterMobileGoBack() {
    this.props.history.push(this.state.previousFilterURL);
    this.setState({ filterMobileMode: false });
  }

  // getHelmetData(el) {
  //   if (el === undefined) return { title: "" };
  //   const { currentCityItem } = this.state;
  //   const title = currentCityItem ? `${currentCityItem.ti} ${currentCityItem.city}` : title;

  //   return { title };
  // }

  render() {
    const {
      loginModal,
      filterMobileMode,
      searchCategory,
      searchCity,
      searchService,
      currentCityItem,
      currentSearchIndexItem,
    } = this.state;

    return (
      <Aux>
        {currentSearchIndexItem ? (
          <Helmet>
            {currentSearchIndexItem?.title && (
              <title>{currentSearchIndexItem?.title}</title>
            )}
            {currentSearchIndexItem?.description && (
              <meta
                name="description"
                content={currentSearchIndexItem?.description}
              />
            )}
            {currentSearchIndexItem?.keywords && (
              <meta
                name="keywords"
                content={currentSearchIndexItem?.keywords}
              />
            )}
          </Helmet>
        ) : (
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
        )}

        <div className="main-content">
          <SignupModal
            isOpen={loginModal}
            toggle={() => this.setState({ loginModal: !loginModal })}
          />
          {!filterMobileMode ? (
            <div>
              <LandingNavbar />
              <LandingHeader2 isSearchPage />
              <Aux>
                <div style={{ minHeight: 500 }}>
                  {this.state.showLoader ? (
                    <Container fluid style={{ paddingTop: 100 }}>
                      <Row className="justify-content-center mt-4">
                        <ClipLoader color="#1171ef" />
                      </Row>
                    </Container>
                  ) : (
                    <Container fluid>
                      <Row>
                        {!this.checkIfMobile() && (
                          <Col xl={4} className="column">
                            <Card className="shadow">
                              <SearchFilters2 />
                            </Card>
                          </Col>
                        )}
                        <Col xl={8}>
                          <SearchProfiles2 handler={this.handler} />
                        </Col>
                      </Row>
                      {this.checkIfMobile() && (
                        <Row
                          className="justify-content-center align-items-center d-flex position-fixed w-100 bottom-0"
                          style={{ zIndex: 10 }}
                        >
                          <Col>
                            <Button
                              className="w-100 rounded-pill py-2"
                              style={{
                                marginBottom: 10,
                                borderRadius: 100,
                                height: 50,
                              }}
                              color="primary"
                              onClick={() => this.setFilterMobileMode(true)}
                            >
                              <i
                                class="fas fa-sliders-h"
                                style={{ marginRight: 7, fontSize: 16 }}
                              />
                              Filtres
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </Container>
                  )}
                </div>
                <section
                  className="section pt-6 bg-white"
                  style={{ marginBottom: this.checkIfMobile() ? 50 : 0 }}
                >
                  <DefaultFooter />
                </section>
              </Aux>
            </div>
          ) : (
            <Container>
              <Card
                className="shadow "
                style={{ marginBottom: 70, marginTop: 65 }}
              >
                <SearchFilters2 />
              </Card>
              <Row
                className="justify-content-center position-fixed w-100 bottom-0"
                style={{ zIndex: 10 }}
              >
                <Col>
                  <Button
                    className="w-100 rounded-pill py-2"
                    style={{ marginBottom: 10, borderRadius: 100, height: 50 }}
                    color="primary"
                    onClick={() => this.setFilterMobileMode(false)}
                  >
                    Valider
                  </Button>
                </Col>
              </Row>
              <Row style={{ zIndex: 10 }}>
                <Col
                  className="position-fixed d-flex align-items-center w-100 top-0 shadow-xl"
                  style={{ height: 65, backgroundColor: "var(--secondary)" }}
                >
                  <i
                    onClick={() => this.handleFilterMobileGoBack()}
                    class="fas fa-arrow-left text-lg cursor-pointer"
                  />
                </Col>
              </Row>
              {/* <div
                className="position-fixed d-flex align-items-center w-100 top-0 "
                style={{ height: 50, backgroundColor: "var(--lighter)" }}
              >
                <i
                  onClick={() => this.handleFilterMobileGoBack()}
                  class="fas fa-arrow-left text-lg cursor-pointer"
                />
              </div> */}
            </Container>
          )}
        </div>
      </Aux>
    );
  }
}

export default withRouter(Search2);
