import React, { Component } from 'react';
import {
    Button,
    Card,
    Col, Container, Row,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import CardBody from 'reactstrap/es/CardBody';
import { Helmet } from 'react-helmet';
import Brand from '../../assets/Pics/Icons/tribu-white.svg';
import classes from '../../assets/Scss/components/HowItWorks.scss';
import Aux from '../../hoc/Aux';
import SignupModal from '../Login/SignupModal';
import config from '../../config/config.json';

class HowItWorks extends Component {
    constructor(props) {
        super(props);

        this.state = {
            signupModal: false,
        };
    }

    render() {
        const {
            signupModal,
        } = this.state;

        return (
            <Aux>
                <Helmet>
                    <title>Comment fonctionne Tribu ?</title>
                    <meta
                        name="description"
                        content="Travailler avec des courtiers en crédit et assurance n'a jamais été aussi simple et
                        sécurisé. Collaborez sur Tribu en toute sécurité avec des milliers de courtiers."
                    />
                </Helmet>
                <SignupModal isOpen={signupModal} toggle={() => this.setState({ signupModal: !signupModal })} />
                <div className={`header bg-gradient-info py-9 ${classes.HowItWorks}`}>
                    <Container>
                        <div className="header-body">
                            <Row className="align-items-center">
                                <Col className="my-auto pb-4 pb-md-auto" xs={12} md={4}>
                                    <Row className="justify-content-center">
                                    <Link to="/">
                                            <img
                                                className="mb-3"
                                                alt="Tribu"
                                                src={Brand}
                                                style={{ maxWidth: '75px' }}
                                            />
                                        </Link>
                                    </Row>
                                    <hr className="bg-white w-50 text-left my-2" />
                                    <p className="text-center text-white display-4 font-weight-300">
                                        Comment ça marche ?
                                    </p>
                                </Col>
                                <Col xs={12} md={8}>
                                    <p className="h2 text-white">
                                        L&apos;entraide, le partage et la collaboration sont dans notre ADN.
                                    </p>
                                    <p className="h2 text-white">
                                        Pour atteindre l’excellence au sein de notre communauté, nous recherchons
                                        constamment les meilleurs courtiers.
                                    </p>
                                    <p className="h2 text-white">
                                        Notre combat : que le courtage ne rime plus avec solitude. C&apos;est pourquoi
                                        nous créons chaque jour un maximum d&apos;interactions entre les acteurs du
                                        courtage.
                                    </p>
                                    <Button
                                        color="secondary"
                                        className="mt-5 px-4"
                                        onClick={() => this.setState({ signupModal: !signupModal })}
                                    >
                                        INSCRIPTION
                                        GRATUITE
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                        <div className="separator separator-bottom separator-skew zindex-100">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                                viewBox="0 0 2560 100"
                                x="0"
                                y="0"
                            >
                                <polygon
                                    className="fill-white"
                                    points="2560 0 2560 100 0 100"
                                />
                            </svg>
                        </div>
                    </Container>
                </div>
                <section className="header bg-white">
                    <Container className="py-6 bg-white">
                        <Row className="justify-content-center">
                            <Col xs={12}>
                                <h5 className="display-3 text-center text-primary">
                                    Simple comme bonjour
                                </h5>
                            </Col>
                            <Row className="mt-md--8" style={{marginRight:0}}>
                                <Col className="mt-md-8" xs={12} md={10}>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <Card className="shadow shadow-lg--hover mt-6">
                                                <CardBody>
                                                    <div className="d-flex px-3">
                                                        <div>
                                                            <div
                                                                className="icon icon-shape bg-gradient-info rounded-circle text-white"
                                                            >
                                                                <i className="fas fa-pen" />
                                                            </div>
                                                        </div>
                                                        <div className="pl-4">
                                                            <h5 className="h2 title text-primary">
                                                                1. Inscription
                                                            </h5>
                                                            <h5 className="h4 title text-primary">
                                                                Rapide et gratuite
                                                            </h5>
                                                            <p>
                                                                Inscrivez-vous avec un numéro Orias valide.
                                                                <br />
                                                                Et obtenez votre premier badge de certification !

                                                            </p>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>

                                    <Row className="mt-md--8 justify-content-end">
                                        <Col xs={12} md={6} className="mr-md--8">
                                            <Card className="shadow shadow-lg--hover mt-5">
                                                <CardBody>
                                                    <div className="d-flex px-3">
                                                        <div>
                                                            <div
                                                                className="icon icon-shape bg-gradient-info rounded-circle text-white"
                                                            >
                                                                <i className="fas fa-compass" />
                                                            </div>
                                                        </div>
                                                        <div className="pl-4">
                                                            <h5 className="h2 title text-primary">
                                                                2. Découverte
                                                            </h5>
                                                            <h5 className="h4 title text-primary">
                                                                À la recherche de l’excellence
                                                            </h5>
                                                            <p>
                                                                Devenez con-Tribu-teur en complétant votre profil pré-rempli en quelques étapes !
                                                                Un super profil c’est augmenter ses chances d’être contacté.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>

                                    <Row className="mt-md--8">
                                        <Col xs={12} md={6} className="offset-md-1">
                                            <Card className="shadow shadow-lg--hover mt-5">
                                                <CardBody>
                                                    <div className="d-flex px-3">
                                                        <div>
                                                            <div
                                                                className="icon icon-shape bg-gradient-info rounded-circle text-white"
                                                            >
                                                                <i className="fas fa-equals" />
                                                            </div>
                                                        </div>
                                                        <div className="pl-4">
                                                            <h5 className="h2 title text-primary">
                                                                3. Matching
                                                            </h5>
                                                            <h5 className="h4 title text-primary">
                                                                Coup de foudre professionnel
                                                            </h5>
                                                            <p>
                                                                <span className="font-weight-bold">Courtiers : </span>
                                                                trouvez les confrères qui vous ressemblent.
                                                            </p>
                                                            <p>
                                                                <span className="font-weight-bold">Grands comptes : </span>
                                                                trouvez le bon courtier pour votre organisation.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Row>
                    </Container>
                </section>
                <div className="header bg-gradient-info">
                    <Container className="pt-6 pb-8">
                        <h5 className="display-3 text-center text-white">
                            Pourquoi ça marche ?
                        </h5>
                        <Row className="justify-content-center">
                            <Col md={6}>
                                <p className="mt-2 h2 font-weight-normal text-white text-center">
                                    Nous avons simplifié le courtage en repensant la connexion H2H (human to human).
                                    Vous allez voir, ce n’est pas compliqué.
                                </p>
                            </Col>
                        </Row>
                        <Row className="justify-content-center mt-4">
                            <Button
                                className="btn-secondary text-primary px-5"
                                onClick={() => this.setState({ signupModal: !signupModal })}
                            >
                                INSCRIPTION GRATUITE
                            </Button>
                        </Row>
                    </Container>
                    <div className="separator separator-bottom separator-skew">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="fill-white"
                                points="0 0 2560 100 0 100"
                            />
                        </svg>
                    </div>
                </div>
            </Aux>
        );
    }
}

export default HowItWorks;