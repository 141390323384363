import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Badge, CardBody } from "reactstrap";
import { connect } from "react-redux";
import classes from "../../assets/Scss/components/Post.scss";
import {
  dbLikePost as actionsLikePost,
  dbAddPost,
} from "../../store/posts/actions";
import Commentary from "./Commentary";
import LikesModal from "./LikesModal";
import PostShare from "./PostShare";
import AddCommentary from "./AddCommentary";
import Aux from "../../hoc/Aux";
import { db } from "../../config/firebase";
import Repost from "../../assets/Pics/Icons/repost.svg";
import RepostModal from "./RepostModal";

const PostEngagements = (props) => {
  const { post, likePost, user } = props;
  const [comments, setComments] = useState([]);
  const [nbOfComments, setNbOfComments] = useState(0);
  const [showComments, setShowComments] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [modalRepostOpened, setModalRepostOpened] = useState(false);
  const [modalRepostIsLoading, setModalRepostIsLoading] = useState(false);
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);

  const repostEnabled =
    post.isRepost !== true &&
    user.id !== post.author.userId &&
    (post.images === undefined || post.images.length === 0) &&
    (post.videos === undefined || post.videos.length === 0);

  const { addPost } = props;

  const handleShowComments = () => {
    setShowComments(!showComments);
  };

  useEffect(() => {
    if (showComments) fetchComments();
  }, [showComments]);

  useEffect(() => {
    db.collection("posts")
      .doc(post.id)
      .onSnapshot((doc) => {
        const nbOfComments = doc.data().nbOfComments;
        setNbOfComments(nbOfComments);
      });
  }, []);

  const fetchComments = () => {
    db.collection("comments")
      .orderBy("createdAt", "desc")
      .where("postId", "==", post.id)
      .onSnapshot((snap) => {
        const comments = [];

        snap.forEach((doc) => {
          const data = doc.data();

          const promise = db
            .collection("users")
            .doc(data.userId)
            .get()
            .then((user) => ({
              id: doc.id,
              ...data,
              author: {
                id: user.id,
                ...user.data(),
              },
            }));

          comments.push(promise);
        });

        Promise.all(comments).then((data) => {
          setComments(data);
        });
      });
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const handleRepost = () => {
    setModalRepostIsLoading(true);

    const author = {
      name: `${capitalizeFirstLetter(
        user.firstName
      )} ${user.lastName.toUpperCase()}`,
      profilePic: user.profilePic,
      userId: user.id,
      username: user.username,
    };

    if (user.owners) {
      author.isCompany = true;
    }

    addPost({ isRepost: true, originalPostId: post.id }, author).then(() => {
      setModalRepostIsLoading(false);
      window.location.reload();
    });
  };

  //   const reactions = (post.nbOfLikes > 0 || liked || post.nbOfComments > 0) && (
  //     <CardBody className="py-0 mb-2">
  //       {post.nbOfLikes > 0 ||
  //         (liked && (
  //           <Badge
  //             className="mr-2 cursor-pointer"
  //             onClick={() => setModalOpened(!modalOpened)}
  //           >
  //             <i className="fas fa-thumbs-up mr-1 text-primary" />
  //             <small className="text-primary">
  //               {liked ? post.nbOfLikes + 1 : post.nbOfLikes}
  //             </small>
  //           </Badge>
  //         ))}
  //       {nbOfComments > 0 && (
  //         <Badge
  //           className="mr-2 cursor-pointer"
  //           onClick={() => handleShowComments()}
  //         >
  //           <i className="fas fa-comments mr-1 text-primary" />
  //           <small className="text-primary">{nbOfComments}</small>
  //         </Badge>
  //       )}
  //     </CardBody>
  //   );

  const actions = (
    <CardBody
      className="py-1 border-top px-3"
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <span
          className={`${classes.actions} ${
            (post.userLikes && !disliked) || (!post.userLikes && liked)
              ? "text-primary"
              : ""
          } mr-4 cursor-pointer`}
          onClick={() => {
            if (post.userLikes) setDisliked(!disliked);
            else setLiked(!liked);

            likePost(post.id, user);
          }}
        >
          <i
            className={`${
              (post.userLikes && !disliked) || (!post.userLikes && liked)
                ? "fas"
                : "far"
            } fa-heart mr-2`}
          />
          <small>
            {!post.userLikes
              ? liked
                ? post.nbOfLikes + 1
                : post.nbOfLikes
              : disliked
              ? post.nbOfLikes - 1
              : post.nbOfLikes}
          </small>
        </span>
        <span
          className={`${classes.actions} mr-4 cursor-pointer`}
          onClick={handleShowComments}
        >
          <i class="far fa-comment mr-2"></i>
          <small>{nbOfComments}</small>
        </span>
        {repostEnabled && (
          <div
            className={`${classes.actions} mr-4 cursor-pointer`}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
            onClick={() => {
              repostEnabled && setModalRepostOpened(true);
            }}
          >
            <img
              className=" mr-2"
              alt=""
              src={Repost}
              style={{
                maxHeight: "1.1rem",
                aspectRatio: 1,
              }}
            />
          </div>
        )}
      </div>

      <span className={classes.actions}>
        <PostShare id={post.id.toString()} />
      </span>
    </CardBody>
  );

  const commentsUI = showComments && (
    <CardBody className="border-top p-3">
      <AddCommentary post={post} />
      {comments.map((commentary) => (
        <Commentary key={commentary.id} commentary={commentary} />
      ))}
    </CardBody>
  );

  return (
    <Aux>
      <LikesModal
        isOpen={modalOpened}
        toggle={() => setModalOpened(!modalOpened)}
        postID={post.id}
        nbOfLikes={post.nbOfLikes}
      />
      {/* {reactions} */}
      {actions}
      {commentsUI}
      <RepostModal
        isLoading={modalRepostIsLoading}
        onSubmit={handleRepost}
        isCurrentUserPremium={props.isCurrentUserPremium}
        post={post}
        isOpen={modalRepostOpened}
        toggle={() => setModalRepostOpened(!modalRepostOpened)}
      />
    </Aux>
  );
};

const mapStateToProps = (state) => ({
  user: state.User.user,
  comments: state.Posts.posts,
});

export default connect(mapStateToProps, {
  addPost: dbAddPost,
  likePost: actionsLikePost,
})(PostEngagements);

PostEngagements.propTypes = {
  addPost: PropTypes.func,
  likePost: PropTypes.func,
  post: PropTypes.object,
  user: PropTypes.object,
};

PostEngagements.defaultProps = {
  addPost: () => {},
  likePost: () => {},
  post: {},
  user: {},
};
