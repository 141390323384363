import {
    SET_CHAT, SET_CHAT_ERROR, SET_CHAT_MESSAGES, SET_CHAT_PENDING,
} from './types';

const initialState = {
    isPending: false,
    error: null,
    chat: {},
    messages: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CHAT_PENDING:
            return { ...state, isPending: action.isPending };

        case SET_CHAT_ERROR:
            return { ...state, error: action.error };

        case SET_CHAT:
            return { ...state, chat: action.chat };

        case SET_CHAT_MESSAGES:
            return { ...state, messages: action.messages };

        default:
            return state;
    }
};

export default reducer;
