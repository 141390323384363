import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Col, Input, Row } from "reactstrap";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { connect } from "react-redux";
import messageClasses from "../../assets/Scss/components/Chat.scss";
import CommentaryActions from "./CommentaryActions";
import { db, firebaseApp } from "../../config/firebase";
import firebase from "@firebase/app";

class Commentary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      replyCommentMode: false,
      replySubCommentMode: false,
      currentSubCommentUserIdInReply: "",
      subComment: "",
      subCommentsIsOpen: false,
      subComments: [
        {
          createdAt: 123456789,
          comment: "@georges pas mal",
          id: "0",
          author: {
            id: "E82OjpXeVzdaRIUfuvxbesg2P4z2",
            username: "premium-testtest",
            lastName: "Testtest",
            firstName: "Premium",
            profilePic:
              "https://avatars.dicebear.com/v2/initials/Premium%20Testtest.svg?options[backgroundColors][0]=blue",
          },
          likes: ["123"],
        },
        {
          createdAt: 123456789,
          comment: "Test2",
          id: "1",
          author: {
            id: "E82OjpXeVzdaRIUfuvxbesg2P4z2",
            username: "premium-testtest",
            lastName: "Testtest",
            firstName: "Premium",
            profilePic:
              "https://avatars.dicebear.com/v2/initials/Premium%20Testtest.svg?options[backgroundColors][0]=blue",
          },
          likes: ["123"],
        },
      ],

    };
  }

  render() {
    const { commentary, user } = this.props;
    const { author, likes } = commentary;
    const {
      subComments,
      subCommentsIsOpen,
      subComment,
      replyCommentMode,
      replySubCommentMode,
    } = this.state;
    const uid = firebaseApp.auth().currentUser.uid;

    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    const like = (userId) => {
      db.collection("comments")
        .doc(commentary.id)
        .update({ likes: firebase.firestore.FieldValue.arrayUnion(userId) });
    };

    const dislike = (userId) => {
      db.collection("comments")
        .doc(commentary.id)
        .update({ likes: firebase.firestore.FieldValue.arrayRemove(userId) });
    };

    // todo => listen with onsnapshot sub comments to update automatically this.state.subComments

    const likeSubComment = (subCommentId, userId) => {
      // do something
    };

    const dislikeSubComment = (subCommentId, userId) => {
      // do something
    };

    const handleAddSubComment = (comment) => {
      // do something
      handleSetReplyMode({setReplyCommentMode: false})
    };

    const handleDeleteSubComment = (comment) => {
      // do something
    };

    const highlightUsernames = ({ text, author }) => {
      return text.replace(
        /@([a-zA-Z0-9_]+)/g,
        `<a target="_blank" href='user/$1/about'><span class="highlight">@$1</span></a>`
      );
    };

    const handleSetReplyMode = ({
      setReplyCommentMode,
      setReplySubCommentMode,
      currentSubCommentUserId,
    }) => {
      if (currentSubCommentUserId)
        this.setState({
          currentSubCommentUserIdInReply: currentSubCommentUserId,
          subComment: `@${currentSubCommentUserId} `,
        });
      else this.setState({ subComment: "" });

      if (setReplyCommentMode) {
        this.setState({ replyCommentMode: true, replySubCommentMode: false });
      } else if (setReplySubCommentMode) {
        this.setState({ replyCommentMode: false, replySubCommentMode: true });
      } 
       if (setReplyCommentMode === false)
        this.setState({ replyCommentMode: false });
      else if (setReplySubCommentMode === false)
        this.setState({ replySubCommentMode: false });
    };

    const commentInput = (
      <Row
        style={{ alignItems: "center", marginLeft: 53 }}
        className={`mt-2 mb-0 `}
      >
        <Col className="col-auto pr-0 pl-0">
          <Link target="_blank" to={`/user/${user.username}/about`}>
            <img
              className="avatar rounded-circle"
              alt=""
              src={user.profilePic}
              style={{
                maxWidth: "38px",
                maxHeight: "38px",
              }}
            />
          </Link>
        </Col>
        <Col className="col ">
          <Input
            className="comments"
            type="text"
            placeholder="Commenter..."
            value={subComment}
            bsSize="sm"
            onChange={(e) => this.setState({ subComment: e.target.value })}
          />
        </Col>
        <Col className="col-auto pl-0">
          <i
            onClick={() => handleSetReplyMode({ setReplyCommentMode: false })}
            className="fas fa-times cursor-pointer"
          />
        </Col>
        <Col className="col-auto pl-0">
          <i
            class="fas fa-paper-plane cursor-pointer"
            onClick={() => handleAddSubComment(subComment)}
          ></i>
        </Col>
      </Row>
    );

    const subCommentInput = (
      <Row
        style={{ alignItems: "center", marginLeft: 0 }}
        className={`mt-2 mb-0 `}
      >
        <Col className="col-auto pr-0 pl-0">
          <Link target="_blank" to={`/user/${user.username}/about`}>
            <img
              className="avatar rounded-circle"
              alt=""
              src={user.profilePic}
              style={{
                maxWidth: "38px",
                maxHeight: "38px",
              }}
            />
          </Link>
        </Col>
        <Col className="col ">
          <Input
            className="comments"
            type="text"
            placeholder="Commenter..."
            value={subComment}
            bsSize="sm"
            onChange={(e) => this.setState({ subComment: e.target.value })}
          />
        </Col>
        <Col className="col-auto pl-0">
          <i
            onClick={() =>
              handleSetReplyMode({ setReplySubCommentMode: false })
            }
            className="fas fa-times cursor-pointer"
          />
        </Col>
        <Col className="col-auto pl-0">
          <i
            class="fas fa-paper-plane cursor-pointer"
            onClick={() => handleAddSubComment(subComment)}
          ></i>
        </Col>
      </Row>
    );

    return (
      <>
        <Row
          className={`${messageClasses.Message} justify-content-between pt-4`}
        >
          <Col className="col-auto pr-0">
            <Link target="_blank" to={`/user/${author.username}/about`}>
              <img
                alt=""
                className="avatar rounded-circle"
                style={{
                  maxWidth: "38px",
                  maxHeight: "38px",
                }}
                src={author.profilePic}
              />
            </Link>
          </Col>
          <Col className="pl-0 ">
            <Row>
              <Col className={`${messageClasses.received} col-auto ml-3`}>
                <Link target="_blank" to={`/user/${author.username}/about`}>
                  <span
                    className="text-primary font-weight-bold mr-2"
                    style={{ fontSize: "13px" }}
                  >
                    {`${capitalizeFirstLetter(
                      author.firstName
                    )} ${author.lastName.toUpperCase()}`}
                  </span>
                </Link>
                <br />

                <span style={{ fontSize: "13px" }}>{commentary.comment}</span>
              </Col>
            </Row>
            <Row className="justify-content-start">
              {likes === undefined || likes === null || likes.length === 0 ? (
                <Col className="col-auto">
                  <small
                    className="text-muted ml-3"
                    style={{ fontSize: "12px" }}
                  >
                    0 J'aime
                  </small>
                </Col>
              ) : likes.length === 1 ? (
                <Col className="col-auto">
                  <small
                    className="text-muted ml-3"
                    style={{ fontSize: "12px" }}
                  >
                    {likes.length} J'aime
                  </small>
                </Col>
              ) : (
                <Col className="col-auto">
                  <small
                    className="text-muted ml-3"
                    style={{ fontSize: "12px" }}
                  >
                    {likes.length} J'aimes
                  </small>
                </Col>
              )}
              <Col className="col-auto">
                <small
                  onClick={() =>
                    handleSetReplyMode({
                      setReplyCommentMode: true,
                    })
                  }
                  className="text-muted ml-8"
                  style={{ fontSize: "12px", cursor: "pointer" }}
                >
                  Répondre
                </small>
              </Col>
            </Row>
          </Col>
          <Col className="col-auto pl-2">
            <Row>
              <Col className="d-flex justify-content-end align-items-center">
                <small className="text-muted ml-1" style={{ fontSize: "12px" }}>
                  <Moment locale="fr" fromNow ago>
                    {commentary.createdAt}
                  </Moment>
                </small>
                {user.id === author.id && (
                  <CommentaryActions commentId={commentary.id} />
                )}
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-end align-items-center">
                {likes !== null &&
                likes !== undefined &&
                likes.includes(uid) ? (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => dislike(uid)}
                  >
                    <i className="fas fa-heart text-primary" />
                  </div>
                ) : (
                  <div style={{ cursor: "pointer" }} onClick={() => like(uid)}>
                    <i className="far fa-heart" />
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        {replyCommentMode && commentInput}
        <Row>
          {subComments.length === 0 ? (
            <></>
          ) : (
            <div
              style={{
                marginTop: 10,
                marginLeft: 69,
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Col className="pl-0">
                <span
                  onClick={() => {
                    this.setState({ subCommentsIsOpen: !subCommentsIsOpen });
                  }}
                  style={{
                    cursor: "pointer",
                    fontSize: 12,
                    alignSelf: "center",
                    textDecoration: "underline",
                    marginBottom: subCommentsIsOpen ? 100 : 20,
                  }}
                >
                  {subCommentsIsOpen ? "Masquer" : "Afficher"} les autres
                  réponses
                </span>

                {subCommentsIsOpen && (
                  <Col className="mt-2 px-0">
                    <Col className="px-0">
                      {subComments.map((commentary, index) => {
                        const { author, likes } = commentary;
                        return (
                          <Row ley={commentary.id}>
                            <Col className="col-auto pr-0">
                              <Link
                                target="_blank"
                                to={`/user/${author.username}/about`}
                              >
                                <img
                                  alt=""
                                  className="avatar rounded-circle"
                                  style={{
                                    maxWidth: "38px",
                                    maxHeight: "38px",
                                  }}
                                  src={author.profilePic}
                                />
                              </Link>
                            </Col>
                            <Col className="pl-0 ">
                              <Row>
                                <Col
                                  className={`${messageClasses.received} col-auto ml-3`}
                                >
                                  <Link
                                    target="_blank"
                                    to={`/user/${author.username}/about`}
                                  >
                                    <span
                                      className="text-primary font-weight-bold mr-2"
                                      style={{ fontSize: "13px" }}
                                    >
                                      {`${capitalizeFirstLetter(
                                        author.firstName
                                      )} ${author.lastName.toUpperCase()}`}
                                    </span>
                                  </Link>
                                  <br />

                                  <span
                                    style={{ fontSize: "13px" }}
                                    dangerouslySetInnerHTML={{
                                      __html: highlightUsernames({
                                        text: commentary.comment,
                                        author
                                      }),
                                    }}
                                  ></span>
                                </Col>
                              </Row>
                              <Row className="justify-content-start">
                                {likes === undefined ||
                                likes === null ||
                                likes.length === 0 ? (
                                  <Col className="col-auto">
                                    <small
                                      className="text-muted ml-3"
                                      style={{ fontSize: "12px" }}
                                    >
                                      0 J'aime
                                    </small>
                                  </Col>
                                ) : likes.length === 1 ? (
                                  <Col className="col-auto">
                                    <small
                                      className="text-muted ml-3"
                                      style={{ fontSize: "12px" }}
                                    >
                                      {likes.length} J'aime
                                    </small>
                                  </Col>
                                ) : (
                                  <Col className="col-auto">
                                    <small
                                      className="text-muted ml-3"
                                      style={{ fontSize: "12px" }}
                                    >
                                      {likes.length} J'aimes
                                    </small>
                                  </Col>
                                )}
                                <Col className="col-auto">
                                  <small
                                    onClick={() =>
                                      handleSetReplyMode({
                                        setReplySubCommentMode: true,
                                        currentSubCommentUserId:
                                          author.username,
                                      })
                                    }
                                    className="text-muted ml-8"
                                    style={{
                                      fontSize: "12px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    Répondre
                                  </small>
                                </Col>
                              </Row>
                            </Col>
                            <Col className="col-auto pl-2">
                              <Row>
                                <Col className="d-flex justify-content-end align-items-center">
                                  <small
                                    className="text-muted ml-1"
                                    style={{ fontSize: "12px" }}
                                  >
                                    <Moment locale="fr" fromNow ago>
                                      {commentary.createdAt}
                                    </Moment>
                                  </small>
                                  {user.id === author.id && (
                                    <CommentaryActions
                                      commentId={commentary.id}
                                    />
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                <Col className="d-flex justify-content-end align-items-center">
                                  {likes !== null &&
                                  likes !== undefined &&
                                  likes.includes(uid) ? (
                                    <div
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        dislikeSubComment(commentary.id, uid)
                                      }
                                    >
                                      <i className="fas fa-heart text-primary" />
                                    </div>
                                  ) : (
                                    <div
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        likeSubComment(commentary.id, uid)
                                      }
                                    >
                                      <i className="far fa-heart" />
                                    </div>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        );
                      })}
                    </Col>
                    {replySubCommentMode && subCommentInput}
                  </Col>
                )}
              </Col>
            </div>
          )}
        </Row>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.User.user,
});

export default connect(mapStateToProps)(Commentary);

Commentary.propTypes = {
  commentary: PropTypes.object,
  user: PropTypes.object,
};

Commentary.defaultProps = {
  commentary: {},
  user: {},
};
