const CITIES = [
  { city: "Agen", departement: "Lot-et-Garonne" },
  {
    city: "Ajaccio",
    departement: "Corse-du-Sud",
    description:
      "Retrouvez à Ajaccio (20) dite la cité impériale Corse, tous les courtiers disponibles sur Tribu ! Quel que soit votre projet, trouvez le profil courtier ajaccien qu’il vous faut : courtier en crédit ajaccien, courtier en assurance ajaccien, conseiller en gestion de patrimoine ajaccien… Tous les profils courtiers ajacciens et des environs (Villanova, Poriccio) sont sur Tribu et vous accompagnent tout au long de votre projet.",
  },
  {
    city: "Albi",
    departement: "Tarn",
    description: `Vous êtes à la recherche d’un courtier à Albi (81), chef-lieu du Tarn, et des villes voisines
    (Cambon, Lagrave, Carmaux, Gaillac…) ? Retrouvez sur Tribu tous les courtiers albigeois,
    courtiers en crédit albigeois, courtiers en assurance albigeois, conseillers en gestion de
    patrimoine albigeois et bien d’autres profils de courtiers pour vous accompagner tout au long
    de votre projet.`,
  },
  { city: "Alençon", departement: "Orne", description: "" },
  {
    city: "Amiens",
    departement: "Somme",
    description:
      "La ville d’Amiens (80) est la deuxième ville la plus peuplée de la région Hauts-de-France après Lille, pour trouver rapidement un courtier à proximité rendez-vous sur Tribu ! Tous les profils des indépendants amiénois disponibles sont consultables : courtiers amiénois, courtiers en crédit amiénois, courtiers en assurance amiénois, conseillers en gestion de patrimoine amiénois et bien d’autres profils de courtiers peuvent vous accompagner sur votre projet.",
  },
  {
    city: "Angers",
    departement: "Maine-et-Loire",
    description:
      "Vous êtes à la recherche d’un courtier à Angers (49), au bord de la Maine, pour travailler sur votre projet ? Consultez sur Tribu tous les profils des courtiers angevins disponibles. Courtiers en crédit angevins, courtiers en assurance angevins, conseillers en gestion de patrimoine angevins… trouvez le profil qu’il vous faut pour vous accompagner tout au long de votre projet.",
  },
  {
    city: "Angoulême",
    departement: "Charente",
    description:
      "Retrouvez à Angoulême (16), chef-lieu de la Charente, tous les courtiers disponibles sur Tribu ! Quel que soit votre projet, trouvez le profil courtier angoumoisins qu’il vous faut : courtier en crédit angoumoisins, courtier en assurance angoumoisins, conseiller en gestion de patrimoine angoumoisins… Tous les profils courtiers angoumoisins sont sur Tribu et vous accompagnent tout au long de votre projet.",
  },
  {
    city: "Annecy",
    departement: "Haute-Savoie",
    description:
      "La ville d’Annecy (74) surnommée « la Venise des Alpes » regorge d’une multitude de beaux paysages et de courtiers ! Pour vous accompagner dans tous vos projets, retrouvez sur Tribu votre futur courtier annécien, courtiers en crédit annécien, courtiers en assurance annécien, conseillers en gestion de patrimoine annécien et bien d’autres profils disponibles. Les travailleurs indépendants d’Annecy et de la région de Haute-Savoie (Seynod, Thônes) sont sur Tribu.",
  },
  { city: "Arras", departement: "Pas-de-Calais", description: "" },
  { city: "Auch", departement: "Gers", description: "" },
  { city: "Aurillac", departement: "Cantal", description: "" },
  {
    city: "Auxerre",
    departement: "Yonne",
    description:
      "Trouvez rapidement sur Tribu un Courtier pour votre projet à Auxerre (89), ville située en plein cœur de la Bourgogne-Franche-Comté. Les courtiers Auxerrois peuvent travailler à domicile ou se déplacer chez vous. Courtiers en crédit auxerrois, courtiers en assurance auxerrois, conseillers en gestion de patrimoine auxerrois, tous les profils disponibles des courtiers indépendants d’Auxerre et sa région (Villefargeau, Beine, Monétau…) sont sur Tribu.",
  },
  {
    city: "Avignon",
    departement: "Vaucluse",
    description:
      "La ville d’Avignon (84) surnommée la « cité des Papes » abrite de nombreux courtiers indépendants disponibles. Pour vous aider dans vos différents projets, faites appel aux courtiers avignonnais : courtiers en crédit avignonnais, courtiers en assurance avignonnais, conseillers en gestion de patrimoine avignonnais…et bien d’autres profils encore sont disponibles sur Tribu.",
  },
  { city: "Bar-le-Duc", departement: "Meuse", description: "" },
  { city: "Basse-Terre", departement: "Guadeloupe", description: "" },
  {
    city: "Bastia",
    departement: "Haute-Corse",
    description:
      "La ville de Bastia (20) est la deuxième ville de Corse, si vous recherchez un courtier à proximité, rendez-vous sur Tribu. Tous les courtiers bastiais vous accompagnent sur vos différents projets : courtiers en crédit bastiais, courtiers en assurance bastiais, conseillers en gestion de patrimoine bastiais…Retrouvez sur Tribu tous les profils des courtiers indépendants de Bastia et sa région (Furiani, Biguglia).",
  },
  {
    city: "Beauvais",
    departement: "Oise",
    description:
      "La ville de Beauvais (60), située au nord du bassin parisien abrite de nombreux profils de courtiers, pour trouver rapidement le profil adéquat, rendez-vous sur Tribu. Quel que soit votre besoin, les différents profils des courtiers beauvaisiens (courtiers en crédit beauvaisiens, courtiers en assurance beauvaisiens, conseillers en gestion de patrimoine beauvaisiens) des villes voisines (Compiègne, Cergy, Meaux) sont disponibles pour tous vos projets. ",
  },
  {
    city: "Belfort",
    departement: "Territoire de Belfort",
    description:
      "La ville de Belfort (90) est le chef-lieu du Territoire de Belfort, pour trouver un courtier rapidement dans région, rendez-vous Tribu ! Tous les profils des courtiers de Belfort et des villes voisines (Montreux, Valdoie, Bessancourt) sont disponibles : courtiers en crédit terrifortains, courtiers en assurance terrifortains, conseillers en gestion de patrimoine terrifortains…",
  },
  {
    city: "Besançon",
    departement: "Doubs",
    description:
      "Retrouvez sur Tribu tous les courtiers disponibles sur la ville de Besançon (25) ! En fonction de votre projet, recherchez-le parmi tous les profils courtiers bisontins disponibles : courtiers en crédit bisontins, courtiers en assurance bisontins, conseillers en gestion de patrimoine bisontins … De nombreux profils de courtiers indépendants sont disponibles dans le Grand Besançon, qui regroupe plus de 70 communes.",
  },
  {
    city: "Blois",
    departement: "Loir-et-Cher",
    description:
      "La ville de Blois (41) est la commune la plus peuplée de Loir-et-Cher, pour trouver un courtier à proximité rendez-vous sur Tribu. Tous les courtiers blésois et des villes voisines (Chambord, Cheverny, Amboise) sont disponibles pour vous accompagner. Les courtiers en crédit blésois, courtiers en assurance blésois, conseillers en gestion de patrimoine blésois et bien d’autres profils sont sur Tribu.",
  },
  { city: "Bobigny", departement: "Seine-Saint-Denis", description: "" },
  {
    city: "Bordeaux",
    departement: "Gironde",
    description:
      "La ville de Bordeaux (33), capitale de la Gironde, compte de nombreux courtiers indépendants. Si vous êtes à la recherche d’un courtier pour votre projet, recherchez-le sur Tribu : courtiers en crédit bordelais, courtiers en assurance bordelais, conseillers en gestion de patrimoine bordelais et bien d’autres profils de courtiers sont disponibles à Bordeaux et dans les villes à proximité (Mérignac, Pessac, Talence…).",
  },
  { city: "Bourg-en-Bresse", departement: "Ain", description: "" },
  {
    city: "Bourges",
    departement: "Cher",
    description:
      "Retrouvez sur Tribu tous les courtiers de la ville de Bourges (18), 3ème ville la plus peuplée de la région Centre-Val de Loire. Les courtiers en crédit bourgeois, courtiers en assurance bourgeois, conseillers en gestion de patrimoine bourgeois et bien d’autres profils de courtiers indépendants sont disponibles sur Tribu. ",
  },
  {
    city: "Caen",
    departement: "Calvados",
    description:
      "La ville de Caen (14) est la capitale politique de la Normandie, si vous recherchez un courtier à proximité rendez-vous sur Tribu. Quel que soit votre projet, les courtiers en crédit caennais, courtiers en assurance caennais, conseillers en gestion de patrimoine caennais (et de nombreux profils encore) peuvent vous accompagner sur vos différents projets à Caen et aux alentours : Louvigny, Hérouville-Saint-Clair, Mondeville, Carpiquet…",
  },
  { city: "Cahors", departement: "Lot", description: "" },
  {
    city: "Carcassonne",
    departement: "Aude",
    description:
      "Si vous êtes à la recherche d’un courtier dans la ville de Carcassonne (11) en région Occitanie, retrouvez-le sur Tribu ! Les courtiers indépendants carcassonnais vous accompagnent tout au long de vos différents projets. En fonction de votre besoin, recherchez-le par profil : courtiers en crédit carcassonnais, courtiers en assurance carcassonnais, conseillers en gestion de patrimoine carcassonnais et de nombreux autres profils sont disponibles.",
  },
  { city: "Cayenne", departement: "Guyane", description: "" },
  {
    city: "Châlons-en-Champagne",
    departement: "Marne",
    description:
      "Trouvez sur Tribu un courtier pour votre projet à Châlons-en-Champagne (51) et aux environs (Saint-Dizier, Verdun, Reims), en région Grand Est. Tous les courtiers châlonnais, courtiers en crédit châlonnais, courtiers en assurance châlonnais, conseillers en gestion de patrimoine châlonnais et bien d’autres profils encore sont disponibles pour vous accompagner tout au long de votre projet.",
  },
  {
    city: "Chambéry",
    departement: "Savoie",
    description:
      "Trouvez rapidement le courtier qu’il vous faut, aux pieds des pistes dans la belle ville de Chambéry (73) en Haute-Savoie ! De nombreux profils courtiers sont disponibles sur Tribu pour vos différents projets : courtiers en crédit chambériens, courtiers en assurance chambériens, conseillers en gestion de patrimoine chambériens, la région regorge de différents profils ! Les courtiers de Haute-Savoie (La Motte-Servolex, Sonnaz…) vous accompagnent dans vos projets.",
  },
  {
    city: "Charleville-Mézières",
    departement: "Ardennes",
    description:
      "Vous recherchez un courtier à Charleville-Mézières (08) dans les Ardennes, pour vous aider sur un projet ? Retrouvez sur Tribu tous les courtiers disponibles de la région : courtiers en crédit carolomacériens, courtiers en assurance carolomacériens, conseillers en gestion de patrimoine carolomacériens…Tous les courtiers indépendants de Charleville-Mézières et des villes voisines (Sedan, Bazeilles, Poix-Terron) peuvent vous accompagner sur vos différents projets.",
  },
  {
    city: "Chartres",
    departement: "Eure-et-Loir",
    description:
      "La ville de Chartres (28) et ses environs (Fontaine-le-Guyon, Dourdan, Etampes) regorge de merveilles historiques et de courtiers disponibles pour tous vos projets ! Trouvez sur Tribu le courtier qu’il vous faut parmi de nombreux profils : courtiers en crédit chartrains, courtiers en assurance chartrains, conseillers en gestion de patrimoine chartrains…",
  },
  {
    city: "Châteauroux",
    departement: "Indre",
    description:
      "Si vous êtes à la recherche d’un indépendant à Châteauroux (36), pour travailler sur votre projet trouvez le sur Tribu ! Tous les profils des courtiers castelroussins sont disponibles : courtiers en crédit castelroussins, courtiers en assurance castelroussins, conseillers en gestion de patrimoine castelroussins et bien d’autres courtiers indépendants castelroussins et de la région (Saint Maur, Bourges, Chenonceau) sont sur Tribu.",
  },
  { city: "Chaumont", departement: "Haute-Marne", description: "" },
  {
    city: "Clermont-Ferrand",
    departement: "Puy-de-Dôme",
    description:
      "Retrouvez sur Tribu tous les courtiers disponibles à Clermont-Ferrand (63), capitale historique de l’Auvergne ! Pour vous accompagner sur votre projet, courtiers en crédit clermontois, courtiers en assurance clermontois, conseillers en gestion de patrimoine clermontois et de nombreux autres profils sont disponibles. Les courtiers des villes du Puy-de-Dôme (Riom, Vichy) vous accompagnent dans vos projets.",
  },
  {
    city: "Colmar",
    departement: "Haut-Rhin",
    description:
      "Située dans la plaine d’Alsace, la ville de Colmar (68) possède un riche patrimoine culturel, pour trouver un courtier à proximité, recherchez-le sur Tribu. Tous les profils des courtiers indépendants colmariens sont consultables : courtiers en crédit colmariens, courtiers en assurance colmariens, conseillers en gestion de patrimoine colmariens et bien d’autres profils courtiers encore ! Rentrez en contact avec les différents profils qu’il vous faut pour votre projet à Colmar ou dans la région Grand Est.",
  },
  {
    city: "Créteil",
    departement: "Val-de-Marne",
    description:
      "Retrouvez sur Tribu tous les courtiers disponibles à Créteil (94), préfecture du Val-de-Marne ! Pour vous accompagner sur votre projet, courtiers en crédit cristoliens, courtiers en assurance cristoliens, conseillers en gestion de patrimoine cristoliens et de nombreux autres profils sont disponibles. Les courtiers de Créteil et des villes du Val-de-Marne (Vitry-sur-Seine, Choisy-le-Roi) vous accompagnent dans vos projets.",
  },
  {
    city: "Digne-les-Bains",
    departement: "Alpes-de-Haute-Provence",
    description: "",
  },
  {
    city: "Dijon",
    departement: "Côte-d'Or",
    description:
      "Trouvez rapidement sur (21) Dijon, chef-lieu de la région Bourgogne-France-Comté, un courtier pour votre projet ! Les courtiers indépendants dijonnais sont sur Tribu : courtiers en crédit dijonnais, courtiers en assurance dijonnais, conseillers en gestion de patrimoine dijonnais…Découvrez de nombreux profils courtiers sur Dijon et sa région, ainsi que dans les villes voisines : Chenôve, Quetigny, Chevigny Saint Sauveur, Fontaine lès Dijon.",
  },
  { city: "Epinal", departement: "Vosges", description: "" },
  {
    city: "Evreux",
    departement: "Eure",
    description:
      "La ville d’Évreux (27) en Normandie est située au cœur de la vallée de l’Iton, si vous êtes à la recherche d’un courtier indépendant à proximité retrouvez-le sur Tribu. Courtiers en crédit ébroïciens, courtiers en assurance ébroïciens, conseillers en gestion de patrimoine ébroïciens… quel que soit votre projet, tous les profils des courtiers disponibles d’Évreux et de la région (Gravigny, Claville, Rouen) sont consultables sur Tribu.",
  },
  { city: "Evry", departement: "Essonne", description: "" },
  { city: "Foix", departement: "Ariège", description: "" },
  { city: "Fort-de-France", departement: "Martinique", description: "" },
  { city: "Gap", departement: "Hautes-Alpes", description: "" },
  {
    city: "Grenoble",
    departement: "Isère",
    description:
      "Les courtiers indépendants de la capitale de l’Isère et des villes avoisinantes (Échirolles, Meylan, Voreppe…) sont sur Tribu et disponibles pour vous accompagner tout au long de votre projet. Retrouvez sur Grenoble (38) le courtier qu’il vous faut : Courtiers en crédit grenoblois, courtiers en assurance grenoblois, conseillers en gestion de patrimoine grenoblois et bien d’autres profils de courtage sont sur Tribu. ",
  },
  { city: "Guéret", departement: "Creuse", description: "" },
  {
    city: "La Rochelle",
    departement: "Charente-Maritime",
    description:
      "La ville de La Rochelle (17) est située en bordure de l’océan Atlantique, pour trouver rapidement un courtier à proximité, rendez-vous sur Tribu. Les courtiers indépendants rochelais et des communes voisines (Périgny, Aytré, Lagord…) peuvent vous accompagner dans vos projets. Recherchez sur Tribu le profil qu’il vous faut : courtiers en crédit rochelais, courtiers en assurance rochelais, conseillers en gestion de patrimoine rochelais, consultant marketing…et bien d’autres profils sont disponibles.",
  },
  {
    city: "La Roche-sur-Yon",
    departement: "Vendée",
    description:
      "La ville de La Roche-sur-Yon (85) est située dans la région des Pays de la Loire, pour trouver un courtier rapidement dans la région, rendez-vous sur Tribu. Tous les profils des courtiers yonnais sont disponibles : courtiers en crédit yonnais, courtiers en assurance yonnais, conseillers en gestion de patrimoine yonnais et bien d’autres profils de courtiers peuvent vous accompagner. Trouvez sur Tribu votre courtier indépendant à La Roche-sur-Yon et dans les villes voisines (Montaigu, Cholet, Aubigny…).",
  },
  { city: "Laon", departement: "Aisne", description: "" },
  { city: "Laval", departement: "Mayenne", description: "" },
  {
    city: "Le Mans",
    departement: "Sarthe",
    description:
      "Retrouvez sur Tribu tous les courtiers disponibles au Mans (72), ville historique des Pays de la Loire ! Courtiers en crédit manceaux, courtiers en assurance manceaux, conseillers en gestion de patrimoine manceaux, de nombreux courtiers sont disponibles sur la région. Quel que soit votre projet, les courtiers indépendants du Mans et des villes voisines (Allonnes, La Chapelle-Saint-Aubin…) vous accompagnent.",
  },
  { city: "Le Puy-en-Velay", departement: "Haute-Loire", description: "" },
  {
    city: "Lille",
    departement: "Nord",
    description:
      "Retrouvez sur Tribu tous les courtiers disponibles à Lille (59), chef-lieu de la région Hauts-de-France ! Courtiers en crédit lillois, courtiers en assurance lillois, conseillers en gestion de patrimoine lillois, de nombreux courtiers sont disponibles sur la région. Quel que soit votre projet, les indépendants lillois et des villes aux alentours (Villeneuve-d’Ascq, Armentières, Béthune) vous accompagnent tout au long de votre projet.",
  },
  {
    city: "Limoges",
    departement: "Haute-Vienne",
    description:
      "Capitale historique de l’ancienne province du Limousin, la ville de Limoges (87) est reconnue « ville d’art et d’histoire » depuis 2008. Si vous êtes à la recherche d’un indépendant pour travailler sur votre projet à Limoges, retrouvez-le sur Tribu. De nombreux profils de courtiers sont disponibles : courtiers en crédit limougeauds, courtiers en assurance limougeauds, conseillers en gestion de patrimoine limougeauds, retrouvez l’intégralité des courtiers disponibles à Limoges sur Tribu.",
  },
  { city: "Lons-le-Saunier", departement: "Jura", description: "" },
  {
    city: "Lyon",
    departement: "Rhône",
    description:
      "Retrouvez sur Tribu tous les courtiers disponibles à Lyon (69), ville historique et capitale des gaules ! Courtiers en crédit lyonnais, courtiers en assurance lyonnais, conseillers en gestion de patrimoine lyonnais, de nombreux courtiers sont disponibles sur la région. Quel que soit votre projet, les courtiers indépendants du Grand Lyon et des villes voisines (Saint Etienne, Villeurbanne, Villefranche-sur-Saône…) vous accompagnent.",
  },
  {
    city: "Mâcon",
    departement: "Saône-et-Loire",
    description:
      "Retrouvez sur Tribu tous les courtiers disponibles à Mâcon (71), capitale du Mâconnais et dans les villes voisines (Varennes-lès-Macon, Sancé, Vinzelles). Courtiers en crédit mâconnais, courtiers en assurance mâconnais, conseillers en gestion de patrimoine mâconnais, consultants en communication mâconnais …de nombreux profils de courtiers indépendants mâconnais sont sur Tribu et peuvent vous accompagner dans vos différents projets.",
  },
  {
    city: "Marseille",
    departement: "Bouches-du-Rhône",
    description:
      "Vous êtes à la recherche d’un courtier à Marseille (13), deuxième ville de France ? Retrouvez des courtiers en crédit marseillais, courtiers en assurance marseillais, conseillers en gestion de patrimoine marseillais et bien d’autres courtiers disponibles sur Tribu. Les courtiers indépendants de Marseille et des villes voisines (Aubagne, Marignane, Martigues) vous accompagnent dans tous vos projets.",
  },
  { city: "Melun", departement: "Seine-et-Marne", description: "" },
  { city: "Mende", departement: "Lozère", description: "" },
  {
    city: "Metz",
    departement: "Moselle",
    description:
      "Retrouvez à Metz (57) au cœur de la Lorraine et les villes aux alentours, tous les courtiers indépendants disponibles ! Les courtiers en crédit messins, courtiers en assurance messins, conseillers en gestion de patrimoine messins et bien d’autres profils de courtiers sont sur Tribu et peuvent vous accompagner sur votre projet.",
  },
  { city: "Mont-de-Marsan", departement: "Landes", description: "" },
  {
    city: "Montauban",
    departement: "Tarn-et-Garonne",
    description:
      "Si vous êtes à la recherche d’un courtier à Montauban (82), chef-lieu du département de Tarn-et-Garonne, retrouvez-le sur Tribu. Les courtiers montalbanais sont disponibles pour différentes missions, consultez leur profil en ligne : courtiers en crédit montalbanais, courtiers en assurance montalbanais, conseillers en gestion de patrimoine montalbanais et bien d’autres profils encore sont sur Tribu.",
  },
  {
    city: "Montpellier",
    departement: "Hérault",
    description:
      "Vous êtes à la recherche d’un indépendant pour une mission à Montpellier (34) ? Retrouvez sur Tribu le courtier qu’il vous faut parmi de nombreux profils : courtiers en crédit montpelliérains, courtiers en assurance montpelliérains, conseillers en gestion de patrimoine montpelliérains. Retrouvez l’intégralité des courtiers disponibles à Montpellier et dans les environs (Latte, Lavérune, Saint-Jean de Vedas) sur Tribu.",
  },
  { city: "Moulins", departement: "Allier", description: "" },
  {
    city: "Nancy",
    departement: "Meurthe-et-Moselle",
    description:
      "Vous êtes à la recherche d’un courtier à Nancy (54), au cœur de la Moselle, pour travailler sur votre projet ? Consultez sur Tribu tous les profils des courtiers nancéiens disponibles. Courtiers en crédit nancéiens, courtiers en assurance nancéiens, conseillers en gestion de patrimoine nancéiens… trouvez le profil qu’il vous faut pour vous accompagner tout au long de votre projet.",
  },
  {
    city: "Nanterre",
    departement: "Hauts-de-Seine",
    description:
      "La ville de Nanterre (92) est la deuxième commune des Hauts-de-Seine la plus peuplée, pour trouver un courtier rapidement dans région, rendez-vous Tribu. Tous les profils des courtiers nanterriens sont disponibles : courtiers en crédit nanterriens, courtiers en assurance nanterriens, conseillers en gestion de patrimoine nanterriens et bien d’autres profils courtiers peuvent vous accompagner. Trouvez sur Tribu votre courtier indépendant sur Nanterre et les villes voisines (Courbevoie, Neuilly-sur-Seine, Rueil-Malmaison…)",
  },
  {
    city: "Nantes",
    departement: "Loire-Atlantique",
    description:
      "La ville de Nantes (44), métropole du Grand Ouest compte de nombreux courtiers disponibles pour tous vos projets. Retrouvez sur Tribu les indépendants de la ville de Nantes et des environs (Orvault, Carquefou, Rezé…). Tous les courtiers en crédit nantais, courtiers en assurance nantais, conseillers en gestion de patrimoine nantais… sont disponibles pour différentes missions. Retrouvez l’intégralité des profils des courtiers nantais sur Tribu.",
  },
  {
    city: "Nevers",
    departement: "Nièvre",
    description:
      "La ville de Nevers (58) est située au confluent de la Nièvre et de l’Allier, pour trouver rapidement un courtier à proximité, rendez-vous sur Tribu. Les courtiers indépendants neversois et des communes voisines (Varennes-Vauzelles, Sauvigny-les-bois, Saint Eloi) peuvent vous accompagner dans vos projets. Recherchez sur Tribu le profil qu’il vous faut : courtiers en crédit neversois, courtiers en assurance neversois, conseillers en gestion de patrimoine neversois… et bien d’autres profils sont disponibles.",
  },
  {
    city: "Nice",
    departement: "Alpes-Maritimes",
    description:
      "Trouvez sur Tribu un courtier pour votre projet à Nice (06) en Provence-Alpes-Côte d’Azur, et dans les environs (Cagnes-sur-Mer, Menton, Antibes). Tous les courtiers en crédit niçois, courtiers en assurance niçois, conseillers en gestion de patrimoine niçois et bien d’autres profils encore sont disponibles pour vous accompagner tout au long de votre projet.",
  },
  {
    city: "Nîmes",
    departement: "Gard",
    description:
      "Retrouvez à Nîmes (30) en région Occitanie et des villes aux alentours (Arles, Lunel), tous les courtiers indépendants disponibles ! Les courtiers en crédit nîmois, courtiers en assurance nîmois, conseillers en gestion de patrimoine nîmois et bien d’autres profils de courtiers sont sur Tribu et disponibles pour des missions.",
  },
  {
    city: "Niort",
    departement: "Deux-Sèvres",
    description:
      "Retrouvez sur Tribu tous les courtiers disponibles à Niort (79), chef-lieu des Deux-Sèvres ! Pour vous accompagner sur votre projet, courtiers en crédit niortais, courtiers en assurance niortais, conseillers en gestion de patrimoine niortais et de nombreux autres profils sont disponibles. Les courtiers de Niort et des villes du Niortais (Coulon, Brûlain, Priaires…) vous accompagnent dans vos projets.",
  },
  {
    city: "Orléans",
    departement: "Loiret",
    description:
      "Si vous recherchez un courtier à Orléans (45), capitale historique de l’Orléanais, rendez-vous sur Tribu ! Tous les profils des indépendants orléanais sont consultables sur Tribu : courtiers en crédit orléanais, courtiers en assurance orléanais, conseillers en gestion de patrimoine orléanais… Retrouvez l’intégralité des profils des courtiers orléanais et des villes aux alentours (Saran, Olivet, Chambord) sur Tribu.",
  },
  {
    city: "Paris",
    departement: "Paris",
    description:
      "Vous êtes à la recherche d’un courtier à Paris (75), ville lumière et capitale de la France ? Retrouvez des courtiers en crédit parisiens, courtiers en assurance parisiens, conseillers en gestion de patrimoine parisiens et bien d’autres courtiers disponibles sur Tribu. Les courtiers indépendants de Paris et des villes voisines (Saint-Denis, Créteil, Boulogne-Billancourt, Issy-les-Moulineaux…) vous accompagnent dans tous vos projets.",
  },
  {
    city: "Pau",
    departement: "Pyrénées-Atlantiques",
    description:
      "Vous êtes à la recherche d’un courtier à Pau (64) pour votre projet ? Retrouvez sur Tribu des courtiers en crédit palois, courtiers en assurance palois, conseillers en gestion de patrimoine palois et bien d’autres profils disponibles. Les courtiers indépendants de Pau et des villes voisines (Tarbes, Montardon, Lons, Lourdes, Oloron-Sainte-Marie…) sont sur Tribu.",
  },
  { city: "Périgueux", departement: "Dordogne", description: "" },
  {
    city: "Perpignan",
    departement: "Pyrénées-Orientales",
    description:
      "Retrouvez sur Tribu tous les courtiers de la ville de Perpignan (66), préfecture des Pyrénées-Orientales. Les courtiers en crédit perpignanais, courtiers en assurance perpignanais, conseillers en gestion de patrimoine perpignanais et bien d’autres profils de courtiers indépendants sont disponibles pour des missions.",
  },
  {
    city: "Poitiers",
    departement: "Vienne",
    description:
      "Vous recherchez un courtier à Poitiers (86), ville la plus peuplée de la Vienne ? Trouvez sur Tribu tous les travailleurs indépendants disponibles pour travailler sur votre projet : courtiers en crédit poitevins, courtiers en assurance poitevins, conseillers en gestion de patrimoine poitevins… L’intégralité des profils des courtiers poitevins et à proximité (Châtellerault, Châteauroux, Chauvigny…) sont consultables sur Tribu.",
  },
  { city: "Pontoise", departement: "Val-d'Oise", description: "" },
  { city: "Privas", departement: "Ardèche", description: "" },
  {
    city: "Quimper",
    departement: "Finistère",
    description:
      "Si vous êtes à la recherche d’un courtier à Quimper (29), chef-lieu du Finistère, rendez-vous sur Tribu. Tous les courtiers quimpérois et des villes voisines (Concarneau, Douarnez, Pont l’Abbé, Quimperlé) sont disponibles. Courtiers en crédit quimpérois, courtiers en assurance quimpérois, conseillers en gestion de patrimoine quimpérois… trouvez le courtier indépendant pour votre projet sur Tribu.",
  },
  {
    city: "Rennes",
    departement: "Ille-et-Vilaine",
    description:
      "La ville de Rennes (35) est la première ville de la région Bretagne, retrouvez sur Tribu tous les courtiers du Grand Ouest disponibles. En fonction de votre projet, recherchez votre courtier par profil : courtiers en crédit rennais, courtiers en assurance rennais, conseillers en gestion de patrimoine rennais et bien d’autres profils sont disponibles sur Rennes et dans les villes voisines (Cesson-Sévigné, Saint Grégoire).",
  },
  { city: "Rodez", departement: "Aveyron", description: "" },
  {
    city: "Rouen",
    departement: "Seine-Maritime",
    description:
      "Vous êtes à la recherche d’un courtier à Rouen (76), ville la plus peuplée du Grand Ouest ? Retrouvez sur Tribu tous les courtiers en crédit de Rouen et des villes voisines (Canteleu, Darnétal…), courtiers en assurance rouennais, conseillers en gestion de patrimoine rouennais et bien d’autres profils de courtiers pour vous accompagner tout au long de votre projet.",
  },
  {
    city: "Saint-Brieuc",
    departement: "Côtes-d'Armor",
    description:
      "Retrouvez sur Tribu tous les courtiers disponibles à Saint Brieuc (22), ville la plus peuplée des Côtes-d’Armor ! courtiers en crédit briochins, courtiers en assurance briochins, conseillers en gestion de patrimoine briochins, de nombreux indépendants sont disponibles sur la région. Les courtiers de Saint Brieux et des environs (Loudéac, Lamballe, Guingamp) vous accompagnent tout au long de votre projet.",
  },
  {
    city: "Saint-Denis",
    departement: "Réunion",
    description:
      "Vous êtes à la recherche d’un courtier à Saint-Denis (93) pour votre projet ? Tous les profils des courtiers indépendants dionysiens sont sur Tribu : courtiers en crédit dionysiens, courtiers en assurance dionysiens, conseillers en gestion de patrimoine dionysiens…et bien d’autres profils encore ! Trouvez rapidement un courtier à Saint-Denis et dans la région (Aubervilliers, Saint Ouen, Montreuil, Paris, Aulnay-sous-Bois).",
  },
  {
    city: "Saint-Étienne",
    departement: "Loire",
    description:
      "La ville de Saint Etienne (38) est la deuxième commune d’Auvergne Rhône-Alpes la plus peuplée, pour trouver un courtier rapidement dans région, rendez-vous sur Tribu. Tous les profils des courtiers stéphanois sont disponibles : courtiers en crédit stéphanois, courtiers en assurance stéphanois, conseillers en gestion de patrimoine stéphanois et bien d’autres profils courtiers peuvent vous accompagner. Trouvez sur Tribu votre courtier indépendant sur Saint-Etienne et les villes voisines (Vienne, Saint-Chamond, Annonay…).",
  },
  { city: "Saint-Lô", departement: "Manche", description: "" },
  {
    city: "Strasbourg",
    departement: "Bas-Rhin",
    description:
      "Vous recherchez un courtier à Strasbourg (67) au cœur de l’Alsace pour votre projet ? Tous les courtiers en crédit strasbourgeois, courtiers en assurance strasbourgeois, conseillers en gestion de patrimoine strasbourgeois et bien d’autres profils encore sont disponibles sur Tribu. À Strasbourg et dans le Grand Est les courtiers vous accompagnent durant votre projet.",
  },
  { city: "Tarbes", departement: "Hautes-Pyrénées", description: "" },
  {
    city: "Toulon",
    departement: "Var",
    description:
      "La ville de Toulon (83) est la capitale économique du Var, pour trouver un courtier dans les environs (La-Seyne sur Mer, Sanary-sur-Mer…) rendez-vous sur Tribu. Des courtiers en crédit toulonnais, courtiers en assurance toulonnais, conseillers en gestion de patrimoine toulonnais et bien d’autres profils peuvent travailleur sur votre projet.",
  },
  {
    city: "Toulouse",
    departement: "Haute-Garonne",
    description:
      "Retrouvez sur Tribu tous les courtiers disponibles à Toulouse (31), capitale historique du Languedoc ! Courtiers en crédit toulousains, courtiers en assurance toulousains, conseillers en gestion de patrimoine toulousains, de nombreux courtiers sont disponibles sur la région. Quel que soit votre projet, les courtiers indépendants de Toulouse et des villes voisines (Blagnac, Colomiers, Labège) vous accompagnent.",
  },
  {
    city: "Tours",
    departement: "Indre-et-Loire",
    description:
      "Vous êtes à la recherche d’un courtier à Tours (37) chef-lieu de l’Indre-et-Loire ? Retrouvez sur Tribu tous les courtiers disponibles dans votre région. Les courtiers en crédit tourangeaux, courtiers en assurance tourangeaux, conseillers en gestion de patrimoine tourangeaux et bien d’autres courtiers sont disponibles sur Tours et dans les villes voisines (Chinon, Chambray-lès-Tours, Amboise, Chenonceau) pour vous accompagner sur tous vos projets.",
  },
  {
    city: "Troyes",
    departement: "Aube",
    description:
      "Retrouvez sur Tribu tous les courtiers indépendants à Troyes (10) et ses environs ! Située dans le département de l’Aube, la ville de Troyes compte de nombreux courtiers : courtiers en crédit troyens, courtiers en assurance troyens, conseillers en gestion de patrimoine troyens…Tous les profils des courtiers disponibles et des villes voisines (Saint-André-les-Vergers, Messon) sont sur Tribu et peuvent vous accompagner sur vos différents projets.",
  },
  { city: "Tulle", departement: "Corrèze", description: "" },
  {
    city: "Valence",
    departement: "Drôme",
    description:
      "Retrouvez sur Tribu tous les courtiers disponibles à Valence (26), la ville la plus peuplée de la Drôme et des environs (Romans-sur-Isère, Pierrelatte) ! En fonction de votre besoin, trouvez rapidement le courtier qu’il vous faut : courtiers en crédit valentinois, courtiers en assurance valentinois, conseillers en gestion de patrimoine valentinois et encore bien d’autres profils sont disponibles.",
  },
  {
    city: "Vannes",
    departement: "Morbihan",
    description:
      "Retrouvez sur Tribu tous les courtiers disponibles sur la ville de Vannes (56) ! En fonction de votre projet en Bretagne, recherchez le courtier parmi tous les profils vannetais disponibles : courtiers en crédit ventais, courtiers en assurance ventais, conseillers en gestion de patrimoine ventais et bien d’autres profils encore sur Vannes et sa région. ",
  },
  {
    city: "Versailles",
    departement: "Yvelines",
    description:
      "La ville de Versailles (78) mondialement connue pour son château et ses jardins, abrite de nombreux courtiers indépendants. Pour vous aider dans vos différents projets, faites appel aux courtiers versaillais : courtiers en crédit versaillais, courtiers en assurance versaillais, conseillers en gestion de patrimoine versaillais … et bien d’autres profils encore sont disponibles sur Tribu.",
  },
  { city: "Vesoul", departement: "Haute-Saône", description: "" },
];

export default CITIES;
