import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Col, DropdownItem, Row } from 'reactstrap';
import DefaultProfile from '../../assets/Pics/defaultProfile.jpg';
import { db } from '../../config/firebase';
import { useState } from 'react';

const ContactRequest2 = (props) => {
    const { userId } = props;

    const [name, setName] = useState('');
    const [profilePic, setProfilePic] = useState('');
    const [company, setCompany] = useState('');

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    useEffect(() => {
        db.collection('users')
            .doc(userId)
            .get()
            .then((contact) => {
                const {
                    firstName, lastName, company, profilePic,
                } = contact.data();

                setName(`${capitalizeFirstLetter(firstName)} ${lastName.toUpperCase()}`);
                setProfilePic(profilePic);
                setCompany(company || '');
            })
    }, []);

    return (
        <DropdownItem className="border-top">
            <Row className="align-items-center">
                <Col className="col-auto pr-0">
                    <img alt='' className="avatar rounded-circle" src={profilePic ? profilePic : DefaultProfile} style={{ maxWidth: '40px', maxHeight: '40px' }} />
                </Col>
                <div className="col pl-0">
                    <div className="d-flex justify-content-between align-items-center">
                        <h4 className="mb-0 text-sm">{name}</h4>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <span className="text-muted" style={{ fontSize: '13px' }}>{company}</span>
                    </div>
                </div>
                <Row className="align-items-center">
                    <Col>
                        Invitation acceptée
                    </Col>
                    <Col>
                        <div
                            className="icon icon-shape bg-primary rounded-circle cursor-pointer"
                            style={{ width: '20px', height: '20px' }}
                        >
                            <i className="fas fa-check text-white" style={{ fontSize: '10px' }} />
                        </div>
                    </Col>
                </Row>
            </Row>
        </DropdownItem>
    );
};

export default ContactRequest2;

ContactRequest2.propTypes = {
    request: PropTypes.object,
};

ContactRequest2.defaultProps = {
    request: {},
};