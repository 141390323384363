import PropTypes from "prop-types";
import React from "react";
import { ListGroupItem, Row } from "reactstrap";
import { Link } from "react-router-dom";
import classes from "../../assets/Scss/components/Chat.scss";
import { useState, useEffect } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { db } from "../../config/firebase";
import DefaultProfile from "../../assets/Pics/defaultProfile.jpg";
import moment from "moment";
import "./style.css";

const ChatReceived = (props) => {
  const [profilePic, setProfilePic] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [interlocutor, setInterlocutor] = useState();
  const { message, isWriting, userId } = props;

  useEffect(() => {
    if (message.sender?.userId || isWriting) {
      const userIdToUse = isWriting ? userId : message.sender.userId;
      db.collection("users")
        .doc(userIdToUse)
        .get()
        .then((doc) => {
          if (doc.data()) {
            setProfilePic(doc.data().profilePic);
            setInterlocutor(interlocutor);
          }
        });
    }
  }, []);

  function highlightURLs({ text }) {
    return text?.replace(
      /(https?:\/\/[^\s]+)/g,
      `<a target="_blank" href='$1'><span class="highlight-light">$1</span></a>`
    );
  }

  return (
    <ListGroupItem
      className={`flex-column align-items-end py-2 px-4 border-0 ${classes.Chat}`}
    >
      {isOpen && isWriting !== true && (
        <Lightbox
          mainSrc={message.picture}
          onCloseRequest={() => setIsOpen(false)}
        />
      )}
      <Row className="align-items-end">
        {isWriting ? (
          <>
            <div style={{ width: "48px" }}>
              <Link
                target="_blank"
                to={`/user/${interlocutor?.username}/about`}
              >
                <img
                  alt=""
                  className="avatar rounded-circle"
                  src={profilePic ? profilePic : DefaultProfile}
                />
              </Link>
            </div>
            <div className="d-flex flex-column align-items-end pl-3">
              <div
                className="message-container message-container-received d-flex"
                style={{ backgroundColor: "var(--secondary)" }}
              >
                <p
                  className={`mb-0 ${classes.received} reading-animation-1`}
                  id="message"
                >
                  ●
                </p>
                <p
                  className={`mb-0 ${classes.received} reading-animation-2`}
                  id="message"
                >
                  ●
                </p>
                <p
                  className={`mb-0 ${classes.received} reading-animation-3`}
                  id="message"
                >
                  ●
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div style={{ width: "48px" }}>
              <Link
                target="_blank"
                to={`/user/${message.sender.username}/about`}
              >
                <img
                  alt=""
                  className="avatar rounded-circle"
                  src={profilePic ? profilePic : DefaultProfile}
                />
              </Link>
            </div>
            <div className="d-flex flex-column align-items-end pl-3">
              {/* <h5 className="mb-1 text-primary">{message.sender.name}</h5> */}
              {message.picture !== undefined ? (
                <img
                  id="message"
                  src={message.picture}
                  alt="chat"
                  height="200"
                  onClick={() => setIsOpen(true)}
                  className="chat-media"
                />
              ) : message.video !== undefined ? (
                <video controls height="200" className="chat-media">
                  <source src={message.video} />
                </video>
              ) : message.file !== undefined ? (
                <div
                  className="message-container message-container-received"
                  style={{ backgroundColor: "var(--blue)" }}
                >
                  <div className="d-flex align-items-center">
                    <i class="far fa-file text-white pr-2"></i>
                    {/* with direct download */}
                    {/*
                    <p
                      onClick={() => {
                        const xhr = new XMLHttpRequest();
                        xhr.responseType = "blob";
                        xhr.onload = (event) => {
                          const blob = xhr.response;
                        };
                        xhr.open("GET", message.file.downloadURL);
                        xhr.send();
                      }}
                      style={{ textDecoration: "underline" }}
                      className={`text-white mb-0 ${classes.received} message-text pr-2`}
                      id="message"
                    >
                      {message.file?.detail?.name}
                    </p>
                    */}
                    <a
                      target="_blank"
                      href={message.file.downloadURL}
                      style={{ textDecoration: "underline" }}
                      className={`text-white mb-0 ${classes.received} message-text pr-2`}
                      id="message"
                    >
                      {message.file?.detail?.name}
                    </a>
                    <p
                      className={`text-white mb-0 ${classes.received} message-text-small `}
                    >
                      {message.file?.detail?.size
                        ? `(${(message.file.detail.size / 1000).toFixed(2)}Ko)`
                        : ""}
                    </p>
                  </div>

                  <p
                    className={`message-date-text`}
                    style={{ color: "lightgray" }}
                  >
                    {moment(new Date(message.date)).format("HH:mm")}
                  </p>
                </div>
              ) : (
                // <div
                //   className="message-container message-container-received"
                //   style={{ backgroundColor: "var(--secondary)" }}
                // >
                //   <p
                //     className={`text-sm  mb-0 ${classes.received}`}
                //     id="message"
                //   >
                //     {message.message}
                //   </p>
                //   <p  className={`message-date-text`} style={{ color: "gray" }}>
                //     {moment(new Date(message.date)).format("HH:mm")}
                //   </p>
                // </div>
                <div
                  className="message-container message-container-received"
                  style={{ backgroundColor: "var(--secondary)" }}
                >
                  <p
                    className={`text-sm  mb-0 ${classes.received}`}
                    id="message"
                    dangerouslySetInnerHTML={{
                      __html: highlightURLs({
                        text: message.message,
                      }),
                    }}
                  ></p>
                  <p className={`message-date-text`} style={{ color: "gray" }}>
                    {moment(new Date(message.date)).format("HH:mm")}
                  </p>
                </div>
              )}
            </div>
          </>
        )}
      </Row>
    </ListGroupItem>
  );
};

export default ChatReceived;

ChatReceived.propTypes = {
  message: PropTypes.object,
};

ChatReceived.defaultProps = {
  message: {},
};
