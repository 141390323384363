import React, { Component } from 'react';
import { ClipLoader } from 'react-spinners';
import {
    Alert, Col, Container, Row,
} from 'reactstrap';
import { Route, Switch } from 'react-router';
import Aux from '../../hoc/Aux';
import DefaultNavbar2 from '../../layouts/Default/DefaultNavbar2';
import ProfileHeader2 from './ProfileHeader2';
import { firebaseApp, db } from '../../config/firebase';
import DefaultFooter from '../../layouts/Default/DefaultFooter';
import DocumentTitle from 'react-document-title'

class Profile2 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            profile: {},
            experiences: [],
            formations: [],
            error: null,
            loading: true,
            loading2: true,
            editing: false,
            societyLoading: true,
            societyExists: true,
            informations: {},
            interlocutor: {},
            isCurrentUserPremium: true,
            allowNotifications: false,
            fetched: false,
            editingSection: '',
        };

        this.toggleSocietyEditing = this.toggleSocietyEditing.bind(this)
        this.toggleEditing = this.toggleEditing.bind(this)
    }

    setEditingSection = (x) => this.setState({ editingSection: x })

    currentUserId = firebaseApp.auth().currentUser.uid;

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    componentDidMount() {
        const { match } = this.props;
        const { user } = match.params;
        this.fetchProfile(user)

        db.collection('users').doc(this.currentUserId).get().then((doc) => {
            const premium = doc.get("premium")
            this.setState({ isCurrentUserPremium: premium, loading2: false })
            const allowNotifications = doc.data().allowNotifications
            if (allowNotifications === undefined) {
                this.setState({ allowNotifications: true })
            } else {
                this.setState({ allowNotifications })
            }
            this.setState({ fetched: true })
        })
    }

    componentDidUpdate(prevProps) {
        const { location: prevLocation } = prevProps;
        const { location } = this.props;
        const username = location.pathname.split('/')[2]
        const prevUsername = prevLocation.pathname.split('/')[2]

        if (prevUsername !== username) {
            this.setState({
                profile: {},
                experiences: [],
                formations: [],
                error: null,
                loading: true,
                loading2: true,
                societyLoading: true,
                societyExists: true,
                informations: {},
                interlocutor: {},
                isCurrentUserPremium: true,
                allowNotifications: false,
                fetched: false,
            })
            this.fetchProfile(username);
        }
    }

    fetchProfile(username) {
        db.collection('users').where('username', '==', username).limit(1).get().then((profiles) => {
            if (!profiles.docs.length) {
                this.setState({ error: 'Ce compte n\'a pas été trouvé !' })
                return;
            }

            const userId = profiles.docs[0].id;

            const profile = {
                id: userId,
                ownProfile: userId === this.currentUserId,
                informations: {
                    ...profiles.docs[0].data(),
                },
            };
            this.setState({ profile })

            db.collection('users').doc(userId).collection("experiences").get()
                .then((snapshosts) => {
                    const items = snapshosts.docs.map((snapshot) => ({
                        user_id: userId,
                        id: snapshot.id,
                        ...snapshot.data(),
                    }));

                    this.setState({
                        experiences: items,
                    })

                    db.collection('users').doc(userId).collection("formations").get()
                        .then((snapshosts) => {
                            const items = snapshosts.docs.map((snapshot) => ({
                                user_id: userId,
                                id: snapshot.id,
                                ...snapshot.data(),
                            }));

                            this.setState({
                                formations: items,
                                loading: false,
                            })
                        });
                });

            this.getSociety()
            if (this.currentUserId !== userId) {
                this.addView(userId, this.currentUserId);
            }
        })
    }

    addView(userId, uid) {
        db.collection('users').doc(userId).collection("views").add({
            userId: uid,
            date: Date.now(),
        })
    }

    toggleSocietyEditing() {
        if (this.state.editing) {
            db.collection('usersSociety')
                .doc(this.state.profile.id)
                .update({ informations: this.state.informations, interlocutor: this.state.interlocutor, })
        }
        this.setState({ editing: !this.state.editing, editingSection: '' })
    }

    toggleEditing() {
        this.setState({ editing: !this.state.editing, editingSection: '' })
    }

    getSociety() {
        db.collection("usersSociety").doc(this.state.profile.id).get()
            .then((doc) => {
                if (!doc.exists) {
                    this.setState({
                        societyLoading: false,
                        societyExists: false,
                    })
                    return
                }

                this.setState({
                    societyLoading: false,
                    informations: doc.data().informations,
                    interlocutor: doc.data().interlocutor,
                })
            })
    }

    handleChange = (e, val) => {
        const { name, value } = e.target !== undefined ? e.target : {
            name: e,
            value: val,
        };
        const valueName = name.split('_');
        if (valueName.length === 2) {
            const { ...state } = this.state;
            state[valueName[0]][valueName[1]] = val !== undefined ? val : value;
            this.setState({ [valueName[0]]: state[valueName[0]] });
        } else {
            this.setState({ [name]: val !== undefined ? val : value });
        }
    };

    formMap = (form, values) => form.map((field) => {
        const newField = { ...field };
        newField.value = values[field.name.split('_')[1]] || '';
        newField.handleChange = this.handleChange;
        return newField;
    });

    render() {
        const {
            profile,
            experiences,
            formations,
            error,
            loading,
            loading2,
            editing,
            isCurrentUserPremium,
            /*societyLoading,
            societyExists,
            informations,
            interlocutor,*/
            allowNotifications,
            fetched,
            editingSection,
        } = this.state;

        if (!isCurrentUserPremium && (this.currentUserId !== profile.id) && !loading && !loading2) {
            return (
                <Container fluid>
                    <Row className="mt-4 justify-content-center">
                        <Col>
                            <Alert color="danger">L'accès nécessite un abonnement Tribu Premium</Alert>
                        </Col>
                    </Row>
                </Container>
            );
        }

        if (error) {
            return (
                <Container fluid>
                    <Row className="mt-4 justify-content-center">
                        <Col>
                            <Alert color="danger">{error}</Alert>
                        </Col>
                    </Row>
                </Container>
            );
        }

        return (
            <DocumentTitle title={loading ? 'courtier Tribu' : `${this.capitalizeFirstLetter(profile.informations.firstName)} ${profile.informations.lastName.toUpperCase()}, courtier Tribu`}>
                <div style={{ paddingBottom: '100px' }}>
                    <Aux>
                        <DefaultNavbar2 allowNotifications={allowNotifications} fetched={fetched} />
                        <Container fluid style={{ paddingTop: 150 }}>
                            <Row className="mt-4 justify-content-center">
                                <Col xs={12} lg={8} xl={6}>
                                    <Row>
                                        <Col>
                                            {
                                                loading ? <Container fluid>
                                                    <Row className="justify-content-center mt-4">
                                                        <ClipLoader color="#1171ef" />
                                                    </Row>
                                                </Container> : <ProfileHeader2 profile={profile} editing={editing} toggleSocietyEditing={this.toggleSocietyEditing} toggleEditing={this.toggleEditing} />
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </Aux>
                    <div
                        style={{
                            position: "fixed",
                            left: 0,
                            bottom: 0,
                            right: 0,
                            backgroundColor: "white"
                        }}
                    >
                        <DefaultFooter />
                    </div>
                </div>
            </DocumentTitle>
        );
    }
}

export default Profile2;
