import PropTypes from "prop-types";
import React, { useState } from "react";
import { Col, Form, Input, InputGroup, InputGroupAddon, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import Button from "reactstrap/es/Button";
import Aux from "../../hoc/Aux/index";
import { checkImage, checkFile } from "../../utils/Checkfiles";
import { storage } from "../../config/firebase";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const ChatInput = (props) => {
  const imageType = "image/png, image/gif, image/jpeg";
  const videoType = "video/mp4,video/x-m4v,video/*";
  const fileType = ".pdf, .doc, .docx, .ppt, .pptx, .txt, .xlsx, .xls";

  const imageInputRef = React.createRef();
  const videoInputRef = React.createRef();
  const fileInputRef = React.createRef();
  const [isOpen, setOpen] = useState(false);
  const [isOpen2, setOpen2] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const { onSubmit, onSubmit2, onSubmit3, onSubmit4, chatId } = props;

  function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number }
  ) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    onSubmit(message);
    setMessage("");
  };

  const handleImageUpload = (e) => {
    const errors = checkImage(e.target.files[0], 3);

    if (errors.length === 0) {
      setLoading(true);
      sendPicture(e.target.files[0]);
    } else {
      setOpen2(true)
    }
  };

  const handleVideoUpload = (e) => {
    const errors = checkFile(e.target.files[0]);

    if (errors.length === 0) {
      setLoading(true);
      sendVideo(e.target.files[0]);
    } else {
      setOpen(true)
    }
  };

  const handleFileUpload = (e) => {
    const errors = checkFile(e.target.files[0]);

    if (errors.length === 0) {
      setLoading(true);
      // sendVideo(e.target.files[0]);
      const file = e.target.files[0];
      const typeOfFile = imageType.includes(file.type)
        ? "image"
        : videoType.includes(file.type)
          ? "video"
          : fileType.includes(file.type) ||
            file.type.includes("application/") ||
            file.type.includes("text/")
            ? "file"
            : "";
      if (typeOfFile === "image") {
        sendPicture(file);
      } else if (typeOfFile === "video") {
        sendVideo(file);
      } else if (typeOfFile === "file") {
        sendFile(file);
      }
    } else {
      setOpen(true)
    }
  };

  const sendPicture = (file) => {
    const uploadTask = storage
      .child("chat")
      .child(chatId)
      .child(file.name)
      .put(file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // eslint-disable-next-line no-unused-vars
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (err) => alert(err),
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          onSubmit2(downloadURL);
          setLoading(false);
        });
      }
    );
  };

  const sendVideo = (file) => {
    const uploadTask = storage
      .child("chat")
      .child(chatId)
      .child(file.name)
      .put(file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // eslint-disable-next-line no-unused-vars
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (err) => alert(err),
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          onSubmit3(downloadURL);
          setLoading(false);
        });
      }
    );
  };

  const sendFile = (file) => {
    const uploadTask = storage
      .child("chat")
      .child(chatId)
      .child(file.name)
      .put(file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // eslint-disable-next-line no-unused-vars
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
      },
      (err) => alert(err),
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          onSubmit4({ downloadURL, detail: { name: file.name, size: file.size } });
          setLoading(false);
        });
      }
    );
  };

  if (loading) {
    return <CircularProgressWithLabel value={progress} />;
  }

  return (
    <Aux>
      <Modal size="lg" isOpen={isOpen} centered toggle={() => setOpen(!isOpen)}>
        <ModalHeader toggle={() => setOpen(!isOpen)} />
        <ModalBody className="pt-0 align-items-center">
          <Row className="justify-content-center">
            <h5 className="h1 mb-0 font-weight-light">
              Le fichier est trop grand !
            </h5>
          </Row>
          <Row className="justify-content-center mb-3">
            <p>7 Mo maximum</p>
          </Row>
          <Row className="justify-content-center">
            <Button className="btn-secondary text-primary" onClick={() => setOpen(!isOpen)}>OK</Button>
          </Row>
        </ModalBody>
      </Modal>
      <Modal size="lg" isOpen={isOpen2} centered toggle={() => setOpen2(!isOpen2)}>
        <ModalHeader toggle={() => setOpen2(!isOpen2)} />
        <ModalBody className="pt-0 align-items-center">
          <Row className="justify-content-center">
            <h5 className="h1 mb-0 font-weight-light">
              Le fichier est trop grand !
            </h5>
          </Row>
          <Row className="justify-content-center mb-3">
            <p>3 Mo maximum</p>
          </Row>
          <Row className="justify-content-center">
            <Button className="btn-secondary text-primary" onClick={() => setOpen2(!isOpen2)}>OK</Button>
          </Row>
        </ModalBody>
      </Modal>
      <input
        key={Math.random().toString(36)}
        type="file"
        accept="image/png, image/gif, image/jpeg"
        ref={imageInputRef}
        style={{ display: "none" }}
        onChange={handleImageUpload}
      />
      <input
        key={Math.random().toString(36)}
        type="file"
        accept="video/mp4,video/x-m4v,video/*"
        ref={videoInputRef}
        style={{ display: "none" }}
        onChange={handleVideoUpload}
      />
      <input
        key={Math.random().toString(36)}
        type="file"
        accept={`${fileType}, ${imageType}, ${videoType}`}
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileUpload}
      />

      <Form onSubmit={handleSubmit}>
        <Row className="d-flex align-items-end">
          <Button
            color="link"
            size="sm"
            onClick={() => fileInputRef.current.click()}
          >
            <i className="fas fa-paperclip" style={{ fontSize: "20px" }} />
          </Button>
          {/* <Button color="link" size="sm" onClick={() => imageInputRef.current.click()}>
                        <i className="fas fa-camera" style={{ fontSize: '20px' }} />
                    </Button>
                    <Button color="link" size="sm" onClick={() => videoInputRef.current.click()}>
                        <i className="fas fa-video" style={{ fontSize: '20px' }} />
                    </Button> */}
          <Col>
            <InputGroup>
              <InputGroupAddon addonType="prepend" className="d-none">
                @
              </InputGroupAddon>
              <Input
                type="textarea"
                rows="4"
                placeholder="Écrivez votre message..."
                style={{ borderRadius: 5, minHeight: 41, resize: "none" }}
                value={message}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
            </InputGroup>
          </Col>
          <Col className="col-auto d-flex align-items-center pl-0">
            <Button type="submit" color="link" size="sm">
              <i className="ni ni-send " style={{ fontSize: "20px" }} />
            </Button>
          </Col>
        </Row>
      </Form>
    </Aux>
  );
};

export default ChatInput;

ChatInput.propTypes = {
  onSubmit: PropTypes.func,
};

ChatInput.defaultProps = {
  onSubmit: () => { },
};