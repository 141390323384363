import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Aux from "../../hoc/Aux";
import Brand from "../../assets/Pics/Icons/brand.png";
import DefaultNavbar2 from "../../layouts/Default/DefaultNavbar2";
import DefaultFooter from "../../layouts/Default/DefaultFooter";

class Cguv extends Component {
  render() {
    return (
      <Aux>
        <DefaultNavbar2 />
        <Helmet>
          <title>Conditions générales d’utilisation et de vente</title>
          <meta
            name="description"
            content="Les présentes conditions générales d’utilisation et de vente
            (ci-après dénommées « Conditions Générales») sont conclues entre :"
          />
        </Helmet>
        <div className="bg-white">
          <div className="header bg-gradient-info py-9">
            <Container>
              <div className="header-body">
                <Row className="justify-content-center">
                  <Col className="col-auto">
                    <Link to="/">
                      <img
                        className="mb-3"
                        alt="Tribu"
                        src={Brand}
                        style={{ maxWidth: "75px" }}
                      />
                    </Link>
                  </Col>
                </Row>
                <hr className="bg-white w-50 text-left my-2" />
                <p className="text-white text-center display-4 font-weight-300">
                  Conditions générales d&apos;utilisation et de vente
                </p>
              </div>
            </Container>
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </div>
          <Container className="py-4 col-12 col-md-7">
            <p>
              Les présentes conditions générales d’utilisation et de vente
              (ci-après dénommées « Conditions Générales») sont conclues entre :
            </p>
            <ul className="font-weight-light">
              <li>
                La société K, immatriculée au RCS de Lyon 850 733 262. (ci-après
                : la « Société »)
              </li>
              <br />
              <li>L’Utilisateur du Site https://www.tribu.app</li>
            </ul>
          </Container>

          <div className="header bg-gradient-info py-6">
            <Container className="pb-3 text-white col-md-7">
              <h5 className="h6 display-3 text-white text-center">Préambule</h5>
              <p>
                La Société exploite un site internet accessible à l&apos;adresse
                <a href="https://www.tribu.app" className="text-default">
                  {" "}
                  tribu.app
                </a>
                , proposant une place de marché en ligne dédiée à la mise en
                relation des Grands Comptes et des Courtiers.
              </p>
              <p>
                Les présentes Conditions Générales ont pour objet de régir les
                conditions et modalités d’utilisation et de vente du Site, ainsi
                que de définir les droits et obligations des Utilisateurs mis en
                relation sur le Site.
              </p>
              <p>
                Tout accès et/ou utilisation du Site suppose l’acceptation sans
                réserves et le respect de l’ensemble des termes des présentes
                Conditions Générales.
              </p>
            </Container>
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon className="fill-white" points="0 0 2560 100 0 100" />
              </svg>
            </div>
          </div>

          <Container className="py-4 col-md-7">
            <h5 className="h6 display-3 text-center">I. Définitions</h5>
            <p>
              <span className="font-weight-bold">Grand Compte </span>: désigne
              toute personne morale, utilisant le Site à des fins strictement
              professionnelles, en vue d’entrer en contact ou d’être mis en
              relation avec un ou plusieurs Courtier(s) afin de formaliser un
              partenariat avec lui.
            </p>
            <p>
              <span className="font-weight-bold">Contenu </span>: désigne tous
              propos, messages ou informations de quelque nature que ce soit
              (texte, image, vidéos, photographies, commentaires, marques,
              dénominations sociales, etc.), mis en ligne par un Utilisateur sur
              le Site.
            </p>
            <p>
              <span className="font-weight-bold">Courtier </span>: désigne toute
              personne physique ou morale, disposant d’un numéro unique Orias et
              inscrite au Registre du Commerce et des Sociétés avec un numéro
              SIRET, qui propose ses services aux Grands Comptes/Courtiers à
              travers le Site.
            </p>
            <p>
              <span className="font-weight-bold">Site </span>: désigne le site
              internet dont l’adresse est
              <a href="https://www.tribu.app"> tribu.app</a>
            </p>
            <p>
              <span className="font-weight-bold">Services </span>: désigne tous
              les services fournis par la Société sur le Site et notamment, le
              service de mise en relation entre Courtiers et entre
              Courtiers/Grands Comptes ainsi que des services destinés à
              accompagner le Grand Compte dans la recherche et la sélection de
              Courtiers.
            </p>
            <p>
              <span className="font-weight-bold">Utilisateurs </span>: désigne
              les Courtiers et les Grands Comptes.
            </p>
          </Container>

          <div className="header bg-gradient-info py-6">
            <Container className="pb-3 text-white col-md-7">
              <h5 className="h6 display-3 text-white text-center">
                II. Inscription au Site
              </h5>
              <p>
                <span className="font-weight-bold">2.1 </span>
                Pour pouvoir accéder aux Services, l&apos;Utilisateur doit créer
                un compte en s&apos;inscrivant gratuitement sur le Site.
              </p>
              <p>
                L&apos;Utilisateur doit être âgé d&apos;au moins 18 ans et être
                capable juridiquement de contracter et d&apos;utiliser le Site
                conformément aux présentes Conditions Générales.
                L&apos;Utilisateur est tenu de fournir des informations exactes
                qu&apos;il s&apos;engage à mettre immédiatement à jour en cas de
                modifications.
              </p>

              <p>
                L&apos;accès au compte créé est protégé par un identifiant et un
                mot de passe choisi par l&apos;Utilisateur lors de son
                inscription sur le Site. L&apos;Utilisateur est seul responsable
                de tout usage qui pourrait être fait de son identifiant et mot
                de passe, et seul garant de leur confidentialité, ainsi que de
                toute utilisation de son compte.
              </p>
              <p>
                <span className="font-weight-bold">2.2 </span>
                Pour être référencé comme Courtier sur le Site et être visible
                des Utilisateurs, celui-ci est tenu en outre de renseigner de
                manière exacte et complète sa page profil, ainsi que de la
                mettre à jour.
              </p>
              <p>
                Le Courtier accepte également de télécharger sur le Site, les
                documents requis les concernant aux fins de satisfaire à leurs
                obligations dans le cadre de la lutte contre le travail
                dissimulé, ainsi qu&apos;aux règles de transparence (KYC) aux
                fins de lutte contre la fraude fiscale, le blanchiment
                d&apos;argent et le financement du terrorisme.
              </p>
              <p>
                <span className="font-weight-bold">2.3 </span>
                Dans l&apos;hypothèse où l&apos;Utilisateur fournirait des
                données fausses, inexactes, périmées ou incomplètes, la Société
                sera en droit de suspendre ou de procéder à la fermeture de son
                compte et de lui refuser, à l&apos;avenir, l&apos;accès à tout
                ou partie des Services.
              </p>
            </Container>
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </div>

          <Container className="py-4 col-md-7">
            <h5 className="h6 display-3 text-center">
              III. Description des Services et fonctionnement du Site
            </h5>
            <p>
              <span className="font-weight-bold">3.1 </span>
              Les Services comprennent notamment :
            </p>
            <ul className="font-weight-light">
              <li>
                une mise en relation facilitée entre des Courtiers et des Grands
                Comptes/Courtiers
              </li>
              <li>
                la collecte auprès des Courtiers des documents requis dans le
                cadre de l&apos;obligation de vigilance et leur mise à
                disposition pour les Grands Comptes
              </li>
              <li>
                la mise en place de Services Corporate pour des Grands Comptes
              </li>
            </ul>
            <p>
              <span className="font-weight-bold">3.2 </span>
              Les Services Corporate pour les Grands Comptes comprennent un
              ensemble de services dédiés et notamment :
            </p>
            <ul className="font-weight-light">
              <li>
                une prestation de conseil sur mesure aux fins de recherche de
                Courtiers
              </li>
              <li>
                une interface personnalisée de la plateforme pour le Grand
                Compte, permettant une utilisation collaborative de celle-ci
                entre plusieurs Utilisateurs
              </li>
            </ul>
            <p>
              <span className="font-weight-bold">3.3 </span>
              Le Grand Compte entre en contact avec le Courtier en le contactant
              via le Site. Après échange sur le champ et les modalités de la
              collaboration, le Grand Compte adresse au Courtier grâce aux
              outils mis à sa disposition sur le Site, une proposition de
              partenariat.
            </p>
          </Container>

          <div className="header bg-gradient-info py-6">
            <Container className="pb-3 text-white col-md-7">
              <h5 className="h6 display-3 text-white text-center">
                IV. Accès au Site et aux Services
                <p>
                  L&apos;accès au Site et aux Services est exclusivement réservé
                  aux Utilisateurs inscrits.
                </p>
                <p>
                  Les Courtiers doivent avoir téléchargé sur leur profil les
                  documents requis les concernant aux fins de satisfaire à leurs
                  obligations dans le cadre de la lutte contre le travail
                  dissimulé, ainsi qu&apos;aux règles de transparence (KYC) aux
                  fins de lutte contre la fraude fiscale, le blanchiment
                  d&apos;argent et le financement du terrorisme.
                </p>
                <p>
                  S&apos;agissant des Utilisateurs domiciliés en dehors de
                  France, ceux-ci sont également tenus de respecter toutes les
                  obligations leur incombant en vertu de la législation
                  applicable à leur statut dans leur pays de résidence, ainsi
                  que les obligations découlant de leur utilisation du Site.
                </p>
                <p>
                  Les Utilisateurs font leur affaire personnelle de la mise en
                  place des moyens informatiques et de télécommunications
                  permettant l&apos;accès au Site. Ils conservent à leur charge
                  les frais de télécommunication lors de l&apos;accès à internet
                  et de l&apos;utilisation du Site.
                </p>
                <p>
                  Le Site est accessible 24h/24, 7j/7 pour l&apos;ensemble des
                  Utilisateurs.
                </p>
                <p>
                  La Société se réserve le droit, sans préavis, ni indemnité, de
                  fermer temporairement ou définitivement le Site ou
                  l&apos;accès à un ou plusieurs Services pour effectuer une
                  mise à jour, des modifications ou un changement sur les
                  méthodes opérationnelles, les serveurs et les heures
                  d&apos;accessibilité, sans que cette liste ne soit limitative.
                </p>
                <p>
                  La Société se réserve le droit d&apos;apporter au Site et aux
                  Services toutes les modifications et améliorations
                  qu&apos;elle jugera nécessaires ou utiles dans le cadre du bon
                  fonctionnement du Site et de ses Services.
                </p>
              </h5>
            </Container>
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon className="fill-white" points="0 0 2560 100 0 100" />
              </svg>
            </div>
          </div>

          <Container className="py-4 col-md-7">
            <h5 className="h6 display-3 text-center">V. Engagements</h5>
            <p>
              <span className="font-weight-bold">5.1 </span>
              L&apos;Utilisateur s&apos;engage à accéder et utiliser le Site et
              les Services conformément aux lois en vigueur et aux présentes
              Conditions Générales.
            </p>
            <p>
              A cet égard, l&apos;Utilisateur reconnaît qu&apos;aux fins
              exclusives de vérification du respect par lui des présentes
              Conditions Générales et des lois applicables, la Société peut
              prendre connaissance de tout Contenu publié ou échangé sur le
              Site.
            </p>
            <p>
              De même, il reconnaît que la Société peut intervenir pour modérer
              les Contenus publiés, s&apos;ils ne respectent pas les lois et
              règlements en vigueur ainsi que les obligations des Utilisateurs
              aux termes des présentes Conditions Générales.
            </p>
            <p>
              <span className="font-weight-bold">5.2 </span>
              L&apos;Utilisateur s&apos;engage à réaliser toutes les
              déclarations et formalités nécessaires à son activité, ainsi
              qu&apos;à satisfaire à toutes ses obligations légales, sociales,
              administratives et fiscales et à toutes les obligations
              spécifiques qui lui incombe le cas échéant en application du droit
              français et/ou de la législation étrangère dont il dépend, dans le
              cadre de son activité et de l&apos;utilisation des Services.
            </p>
            <p>
              En cas de demande, l&apos;Utilisateur s&apos;engage à fournir,
              sans délai, à la Société tout justificatif prouvant qu&apos;il
              remplit les conditions énoncées dans le présent article.
            </p>
            <p>
              L&apos;Utilisateur est seul responsable du bon accomplissement des
              formalités précitées qui lui incombent. La responsabilité de la
              Société ne pourra pas être engagée à ce titre.
            </p>
            <p>
              <span className="font-weight-bold">5.3 </span>
              L&apos;Utilisateur s&apos;engage à faire une utilisation loyale du
              Site, notamment à ne pas contourner le Site et ses Services.
            </p>
            <p>
              De même, tout Utilisateur s&apos;interdit de procéder à toute
              extraction du contenu du Site pour une activité similaire ou
              concurrente, ou à des fins de recrutement.
            </p>
            <p>
              <span className="font-weight-bold">5.4 </span>
              Le Grand Compte s&apos;engage à proposer une offre de service
              sérieuse et suffisamment détaillée au(x) Courtier(s) avec
              le(s)quel(s) il rentre en contact par le biais du Site en vue
              d&apos;une collaboration.
            </p>
            <p>
              Le Grand Compte s&apos;interdit également d&apos;utiliser les
              Services et le Site pour faire la promotion de son activité ou de
              celle d&apos;un tiers. A ce titre, il s&apos;engage notamment à ne
              pas envoyer de message publicitaire aux Utilisateurs du Site ou à
              les démarcher.
            </p>
            <p>
              <span className="font-weight-bold">5.6 </span>
              Le Courtier s&apos;engage à garder une stricte confidentialité aux
              échanges qu&apos;il aurait eu avec des Grands Comptes et aux
              informations qui lui auraient été transmises ou dont il aurait
              connaissance à l&apos;occasion d&apos;une mise en contact via le
              Site. Les Utilisateurs s&apos;interdisent notamment de partager
              avec tout tiers, que ce soit en privé ou sur les réseaux sociaux,
              tout échange, information ou extrait de conversation provenant de
              la messagerie du Site.
            </p>
          </Container>

          <div className="header bg-gradient-info py-6">
            <Container className="pb-3 text-white col-md-7">
              <h5 className="h6 display-3 text-white text-center">
                VI. Responsabilité
              </h5>
              <h5 className="h6 display-4 text-white">
                Responsabilité des Utilisateurs, Grands Comptes et Courtiers
              </h5>
              <p>
                <span className="font-weight-bold">6.1 </span>
                L&apos;Utilisateur est seul responsable du préjudice direct ou
                indirect qu&apos;il est susceptible de subir du fait
                d&apos;informations inexactes, incomplètes, et/ ou trompeuses
                qu&apos;il fournirait lors de son inscription ou en
                l&apos;absence de mise à jour de ces informations, ce dont il
                assume seul les conséquences.
              </p>
              <p>
                Il reconnaît et accepte que toute notification aux termes des
                présentes Conditions Générales pourra lui être faite par le
                biais de l&apos;adresse email de contact renseignée lors de la
                création de son profil sur le Site.
              </p>
              <p>
                <span className="font-weight-bold">6.2 </span>
                L&apos;Utilisateur est seul responsable de l&apos;ensemble des
                Contenus qu&apos;il choisit de mettre en ligne sur le Site, la
                Société ne contrôlant pas le Contenu avant la mise en ligne.
              </p>
              <p>
                L&apos;Utilisateur s&apos;interdit expressément de publier tout
                Contenu injurieux, diffamant, dénigrant, calomnieux, raciste,
                xénophobe, contraire à la morale et aux bonnes mœurs,
                contrefaisant, portant atteinte à l&apos;ordre public ou aux
                droits de tiers, susceptible de porter atteinte aux droits, à la
                réputation et à l&apos;image de la Société et plus généralement,
                dont la teneur enfreindrait la loi et/ou les règlements,
                notamment d&apos;ordre pénal.
              </p>
              <p>
                <span className="font-weight-bold">6.3 </span>
                L&apos;Utilisateur est seul responsable de la conclusion et de
                l&apos;exécution des contrats qu&apos;il conclut avec un autre
                Utilisateur par l&apos;intermédiaire du Site, la Société
                n&apos;intervenant que pour les mettre en relation. La
                conclusion et l&apos;exécution de ces contrats, qui
                interviennent directement entre un Courtier et un Grand Compte
                s&apos;opèrent à l&apos;initiative et sous la responsabilité
                exclusive de ces derniers.
              </p>
              <p>
                <span className="font-weight-bold">6.4 </span>
                Le Courtier reconnaît que les outils et moyens techniques mis à
                disposition par la Société ne l&apos;exonèrent pas de sa
                responsabilité quant aux obligations légales lui incombant. En
                particulier, il reconnaît conserver l&apos;entière
                responsabilité de ses obligations légales et fiscales en matière
                de facturation au titre des factures originales initiales et/ou
                rectificatives émises en son nom et pour son compte par la
                Société, notamment en ce qui concerne ses obligations de
                déclaration et de paiement en matière de TVA, et ce conformément
                au Mandat de facturation.
              </p>
              <h5 className="h6 display-4 text-white">
                Responsabilité de la Société
              </h5>
              <p>
                <span className="font-weight-bold">6.5 </span>
                La Société met tous les moyens en œuvre pour assurer
                l&apos;accès et le bon fonctionnement du Site et des Services 24
                heures sur 24, 7 jours sur 7.
              </p>
              <p>
                Néanmoins, compte tenu des limites liées à l&apos;internet, la
                Société ne peut exclure que l&apos;accès et le fonctionnement du
                Site et des Services soient interrompus notamment en cas de
                force majeure, de mauvais fonctionnement des équipements de
                l&apos;Utilisateur, de dysfonctionnements du réseau internet de
                l&apos;Utilisateur, d&apos;opération de maintenance destinées à
                améliorer le Site et les Services.
              </p>
              <p>
                En conséquence, la Société ne saurait être tenue responsable
                d&apos;une interruption des Services, qu&apos;elle soit
                volontaire ou non, étant précisé qu&apos;elle s&apos;engage à
                faire ses meilleurs efforts pour limiter les interruptions qui
                lui seraient imputables.
              </p>
              <p>
                <span className="font-weight-bold">6.6 </span>
                La Société met à la disposition des Courtiers et des Grands
                Comptes des outils et moyens techniques leur permettant
                d&apos;entrer en relation aux fins de conclure un contrat de
                partenariat à travers le Site. Sa responsabilité se limite à la
                fourniture de ces moyens, tels que décrits aux présentes et à la
                mise en relation des Courtiers et des Grands Comptes.
              </p>
              <p>
                La Société ne saurait notamment être tenue pour responsable
                quant à la fréquence de présentation des profils de Courtiers
                sur son Site.
              </p>
              <p>
                La Société et l&apos;Utilisateur sont des parties indépendantes,
                chacun agissant en son nom personnel et pour leur propre compte.
              </p>
              <p>
                La Société ne conclut aucun contrat au nom et/ou pour le compte
                d&apos;un Courtier ou d&apos;un Grand Compte, ces derniers
                contractant directement entre eux par l&apos;intermédiaire du
                Site.
              </p>
              <p>
                En conséquence, la Société ne peut en aucun cas être considérée
                notamment comme employé/employeur ou agent d&apos;un
                Utilisateur.
              </p>
              <p>
                La Société n&apos;étant pas partie à quelque titre que ce soit
                aux contrats conclus entre les Courtiers et les Grands Comptes,
                ces derniers sont seuls responsables des difficultés,
                réclamations et litiges pouvant intervenir lors de la conclusion
                et/ou de l&apos;exécution desdits contrats. En conséquence,
                chaque Utilisateur dégage la Société de toute responsabilité
                quant aux conséquences directes ou indirectes résultant de
                manière directe ou indirecte de la mise en relation, de la
                conclusion et/ou de l&apos;exécution d&apos;un tel contrat entre
                un Courtier et un Grand Compte.
              </p>
              <p>
                <span className="font-weight-bold">6.7 </span>
                La Société fait tout son possible pour s&apos;assurer du contenu
                et de la validité des informations et documents transmis par les
                Courtiers sur le Site dès lors qu&apos;ils sont établis en
                France. Toutefois, la Société ne saurait être tenue pour
                responsable de quelque façon que ce soit, des infractions aux
                obligations leur incombant notamment dans le cadre de la lutte
                contre le travail dissimulé et du respect des règles de
                transparences, ainsi que des dommages pouvant en résulter.
              </p>
              <p>
                Ainsi, la Société ne saurait être tenue pour responsable des
                informations fausses, trompeuses ou non à jour qui lui sont
                communiquées par le Courtier.
              </p>
              <p>
                De même, la Société se réserve le droit de ne pas transmettre
                aux Courtiers les messages de Grands Comptes, lorsque ceux-ci ne
                respectent pas les conditions d&apos;utilisation du Site.
              </p>
            </Container>
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </div>

          <Container className="py-4 col-md-7">
            <h5 className="h6 display-3 text-center">
              VII. Données personnelles
            </h5>
            <p>
              Conformément à la loi dite « Informatique et Libertés » du 6
              janvier 1978 modifiée par la loi du 6 août 2004,
              l&apos;Utilisateur est informé que la Société procède à des
              traitements automatisés des données à caractère personnel de
              l&apos;Utilisateur, notamment lors de sa connexion au Site, de son
              inscription au Site ou lors de l&apos;utilisation du système de
              paiement.
            </p>
            <p>
              La Société est destinataire des données à caractère personnel
              recueillies par l&apos;intermédiaire du Site. Elle s&apos;engage à
              mettre tous les moyens en œuvre pour assurer la sécurité et la
              confidentialité de ces données.
            </p>
            <p>
              Ces données sont destinées à permettre à la Société de rendre les
              Services accessibles à l&apos;Utilisateur et sont également
              utilisées à des fins de statistiques, de prospection commerciale
              directe et d&apos;envoi de newsletters.
            </p>
            <p>
              Les informations et documents collectés ne sont pas revendus à des
              tiers.
            </p>
            <p>
              La Société conserve les données pour une durée de cinq ans à
              compter de la clôture du compte de l&apos;Utilisateur.
            </p>
            <p>
              Conformément aux dispositions de la loi dite « informatique et
              liberté » du 6 janvier 1978 modifiée par la loi du 6 août 2004,
              l&apos;Utilisateur dispose d&apos;un droit d&apos;accès, de
              modification, de rectification et de suppression des données à
              caractère personnel qui le concernent.
            </p>
            <p>
              L&apos;Utilisateur peut également s&apos;opposer à ce traitement
              pour des motifs légitimes.
            </p>
            <p>
              Pour exercer ses droits, il suffit à l&apos;Utilisateur
              d&apos;écrire à l&apos;adresse électronique suivante
              support@tribu.app.
            </p>

            <p>
              L&apos;Utilisateur est également informé que tous les serveurs de
              la Société sur lesquels ces données sont conservées et ceux des
              prestataires utilisés pour échanger et stocker ces données sont
              tous localisés en Europe.
            </p>
          </Container>

          <div className="header bg-gradient-info py-6">
            <Container className="pb-3 text-white col-md-7">
              <h5 className="h6 display-3 text-white text-center">
                VIII. Cookies
              </h5>
              <p>
                Pour le bon fonctionnement du Site et des Services, des cookies
                sont implantés dans l&apos;ordinateur du l&apos;Utilisateur lors
                de sa connexion au Site.
              </p>
              <p>
                Les cookies enregistrent des informations relatives à la
                navigation (pages consultées, date et heure de la consultation,
                etc.) et à l&apos;identification de ses Utilisateurs.
              </p>
              <p>
                La durée de conservation des cookies implantés par la Société
                est de 6 mois.
              </p>
              <p>
                L&apos;Utilisateur dispose toutefois de la possibilité de
                s&apos;opposer à l&apos;utilisation des cookies en configurant
                son logiciel de navigation. Chaque logiciel de navigation étant
                différent, l&apos;Utilisateur est invité à consulter la notice
                de son navigateur pour le paramétrer comme il le souhaite.
              </p>
            </Container>
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon className="fill-white" points="0 0 2560 100 0 100" />
              </svg>
            </div>
          </div>

          <Container className="py-4 col-md-7">
            <h5 className="h6 display-3 text-center">
              IX. Propriété Intellectuelle
            </h5>
            <h5 className="h6 display-4">9.1 Du site</h5>
            <p>
              Le Site et chacun des éléments qui le composent, notamment mais
              non limitativement, les textes, les images, les vidéos, les
              photographies, les marques, les logos, les dénominations sociales,
              les noms de domaine sont la propriété exclusive de la Société ou
              de celle de ses partenaires.
            </p>
            <p>
              Ces éléments sont protégés par les lois relatives à la propriété
              intellectuelle et autres, et notamment le droit d&apos;auteur.
            </p>
            <p>
              Toute reproduction ou représentation, intégrale ou partielle, du
              Site ou de l&apos;un des éléments qui le composent, sans
              l&apos;autorisation de la Société, est interdite et constitue une
              contrefaçon sanctionnée par le Code de la Propriété
              Intellectuelle.
            </p>
            <h5 className="h6 display-4">9.2 Des contenus</h5>
            <p>
              Tout Utilisateur qui publie du Contenu sur le Site garde
              l&apos;entière propriété de tout ce qu&apos;il publie.
            </p>
            <p>
              En créant un profil ou en laissant des recommandations sur les
              profils, l&apos;Utilisateur autorise expressément la Société à
              utiliser, diffuser, héberger, stocker, reproduire, communiquer,
              publier, modifier, adapter, traduire et afficher ces Contenus
              publics sur le Site, les réseaux sociaux, les blogs exploités par
              cette dernière et/ou sur tous autres supports (notamment support
              physique et numérique, dossier de presse, support commercial,
              matériel promotionnel et/ou publicitaire), par tous moyens, à des
              fins d&apos;exploitation, d&apos;amélioration, de promotion, de
              marketing, de publicité des Services et du Site ou pour les
              besoins de la mise en place de partenariats. Cette autorisation
              est valable pour le monde entier et pour toute la durée de
              l&apos;inscription de l&apos;Utilisateur.
            </p>
            <p>
              L&apos;Utilisateur reconnaît que toute utilisation de son Contenu
              effectuée par la Société préalablement à sa désinscription, la
              suppression ou la résiliation de son compte ne pourra pas être
              remise en cause.
            </p>
          </Container>

          <div className="header bg-gradient-info py-6">
            <Container className="pb-3 text-white col-md-7">
              <h5 className="h6 display-3 text-white text-center">
                X. Liens hypertexte
              </h5>
              <p>
                <span className="font-weight-bold">10.1 </span>
                L&apos;Utilisateur est autorisé à créer un ou plusieurs lien(s)
                hypertexte(s) pointant par exemple vers la page d&apos;accueil
                du Site ou vers sa page profil s&apos;il s&apos;agit d&apos;un
                Courtier.
              </p>
              <p>
                Les Utilisateurs s&apos;interdisent de créer tout lien en
                provenance de sites qui ne seraient pas conformes à la
                législation en vigueur ou qui seraient de nature à nuire aux
                intérêts, à la réputation et/ou à l&apos;image du Site et de la
                Société.
              </p>
              <p>
                En tout état de cause, la Société se réserve le droit de mettre
                fin à cette autorisation à tout moment s&apos;il lui paraît que
                le lien établi avec le Site est de nature à porter atteinte à
                ses intérêts, à sa réputation et/ou son image.
              </p>
              <p>
                En aucun cas, l&apos;existence d&apos;un lien hypertexte en
                provenance d&apos;un site tiers vers le Site n&apos;implique une
                coopération et/ou un partenariat entre le Site et ce site tiers.
                La Société n&apos;exerce aucun contrôle sur les sites de tiers
                et n&apos;assume par conséquent, aucune responsabilité quant aux
                contenus et aux produits et/ou services disponibles sur ou à
                partir de ces sites tiers comportant un lien hypertexte vers le
                Site.
              </p>
              <p>
                <span className="font-weight-bold">10.2 </span>
                Le Site peut contenir des liens vers des sites de partenaires de
                la Société ou vers des sites de tiers. La Société n&apos;exerce
                aucun contrôle sur ces sites et n&apos;assume par conséquent,
                aucune responsabilité quant à la disponibilité de ces sites,
                leur contenu et sur les produits et/ou services disponibles sur
                ou à partir de ces sites.
              </p>
              <p>
                La Société ne sera aucunement responsable des dommages directs
                ou indirects pouvant survenir à l&apos;occasion de l&apos;accès
                de l&apos;Utilisateur au site du partenaire et/ou du tiers et de
                l&apos;utilisation des contenus et les produits et/ou services
                de ce site par l&apos;Utilisateur.
              </p>
            </Container>
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </div>

          <Container className="py-4 col-md-7">
            <h5 className="h6 display-3 text-center">
              XI. Durée, résiliation et sanctions
            </h5>
            <p>
              Le présent contrat est conclu pour une durée indéterminée à
              compter de l&apos;acceptation des Conditions Générales par
              l&apos;Utilisateur.
            </p>
            <p>
              Dans le cas où l&apos;Utilisateur ne respecterait pas les
              présentes Conditions Générales, notamment en ne fournissant pas
              les informations requises pour l&apos;utilisation conforme du Site
              et/ou commettrait un quelconque manquement aux lois et règlements
              en vigueur, et cinq jours après en avoir informé
              l&apos;Utilisateur par le biais de la messagerie du Site, la
              Société sera en droit de suspendre ou fermer le compte de
              l&apos;Utilisateur, automatiquement et de plein droit, et de lui
              refuser, à l&apos;avenir, l&apos;accès à tout ou partie des
              Services, sans préjudice de tous dommages et intérêts éventuels
              que la Société serait en droit de réclamer.
            </p>
          </Container>

          <div className="header bg-gradient-info py-6">
            <Container className="pb-3 text-white col-md-7">
              <h5 className="h6 display-3 text-white text-center">
                XII. Service clientèle
              </h5>
              <p>
                Pour toute question ou information concernant le Site et les
                Services, l&apos;Utilisateur peut contacter la Société via la
                rubrique « contact » sur le Site ou en lui adressant un courrier
                électronique à l&apos;adresse suivante : support@tribu.app.
              </p>
            </Container>
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon className="fill-white" points="0 0 2560 100 0 100" />
              </svg>
            </div>
          </div>

          <Container className="py-4 col-md-7">
            <h5 className="h6 display-3 text-center">
              XIII. Nullité – Renonciation
            </h5>
            <p>
              Dans l&apos;hypothèse où l&apos;une des clauses du présent contrat
              serait déclarée nulle et non avenue par un changement de
              législation, de réglementation ou par une décision de justice,
              cela ne saurait en aucun cas affecter la validité et le respect
              des présentes Conditions Générales.
            </p>
            <p>
              Le défaut pour la Société d&apos;exercer les droits qui lui sont
              reconnus en application des présentes ne constitue pas une
              renonciation à faire valoir ses droits.
            </p>
          </Container>

          <div className="header bg-gradient-info py-6">
            <Container className="pb-3 text-white col-md-7">
              <h5 className="h6 display-3 text-white text-center">
                XIV. Modification des Conditions Générales
              </h5>
              <p>
                La Société se réserve la possibilité de modifier tout ou partie
                des présentes Conditions Générales.
              </p>
              <p>
                La Société informera l&apos;Utilisateur des modifications
                apportées aux présentes Conditions Générales dès leur mise en
                ligne sur le Site.
              </p>
              <p>
                A défaut d&apos;adhésion aux nouvelle Conditions Générales,
                l&apos;Utilisateur dispose d&apos;un délai de 24h à compter de
                la date de notification pour en faire part à la Société par
                courrier électronique.
              </p>
              <p>
                Dans l&apos;hypothèse où l&apos;Utilisateur n&apos;aurait pas
                notifié son désaccord dans le délai de prévu ci-dessus, il sera
                réputé avoir accepté les modifications.
              </p>
            </Container>
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </div>

          <Container className="py-4 col-md-7">
            <h5 className="h6 display-3 text-center">
              XV. Loi applicable et juridiction
            </h5>
            <p>
              <meta name="robots" content="noindex" />
              Les présentes Conditions Générales sont soumises au droit
              français. Tout litige relatif à leur formation, conclusion,
              interprétation et/ou exécution relève de la compétence exclusive
              des juridictions dans le ressort de la Cour d&apos;appel de Lyon.
            </p>
          </Container>
        </div>
        <DefaultFooter />
      </Aux>
    );
  }
}

export default Cguv;
