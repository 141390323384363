import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Col,
  Alert,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Row,
} from "reactstrap";
import { connect } from "react-redux";
import classes from "../../assets/Scss/components/PublishModal.scss";
import { checkImage, checkFile } from "../../utils/Checkfiles";
import { dbAddPost } from "../../store/posts/actions";
import Aux from "../../hoc/Aux";
import { ClipLoader } from "react-spinners";

const PublishModal = (props) => {
  const [error, setError] = useState(null);
  const [postText, setPostText] = useState("");
  const [postImages, setPostImages] = useState([]);
  const [postVideos, setPostVideos] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [authorSelected, setAuthorSelected] = useState(false);
  const modalRef = useRef();

  const {
    user,
    companies,
    addPost,
    toggle,
    isOpen,
    setSizeModal,
    setSizeModalVideo,
    customProps,
    postMaxLength,
    scrollToTop,
  } = props;

  const imageInputRef = useRef();
  const videoInputRef = useRef();

  useEffect(() => {
    setAuthorSelected(user);
  }, []);

  useEffect(() => {
    if (customProps?.photo?.element)
      handleImageUpload(customProps.photo.element);
    else if (customProps?.video?.element)
      handleVideoUpload(customProps.video.element);
  }, [customProps]);

  const cancelHandler = () => {
    setPostText("");
    setPostImages([]);
    setPostVideos([]);
  };

  /**
   * Triggered on image upload
   * @param e
   */
  const handleImageUpload = (e) => {
    const errors = checkImage(e.target.files[0], 3);

    if (errors.length === 0) {
      setPostImages([
        ...postImages,
        {
          file: e.target.files[0],
          img: URL.createObjectURL(e.target.files[0]),
        },
      ]);
    } else {
      setSizeModal(true);
    }
  };

  const handleVideoUpload = (e) => {
    const errors = checkFile(e.target.files[0]);

    if (errors.length === 0) {
      const newVideos = [...postVideos];
      newVideos.push({
        file: e.target.files[0],
        video: URL.createObjectURL(e.target.files[0]),
      });
      setPostVideos(newVideos);
    } else {
      setSizeModalVideo(true);
    }
  };

  /**
   * Triggered on image delete
   * @param id
   */
  const handleDeleteImage = (id) => {
    const newImages = [...postImages];
    newImages.splice(id, 1);
    setPostImages(newImages);
  };

  const handleDeleteVideo = (id) => {
    const newVideos = [...postVideos];
    newVideos.splice(id, 1);
    setPostVideos(newVideos);
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  const handleSubmitPost = () => {
    if (
      postText.length === 0 &&
      postImages.length === 0 &&
      postVideos.length === 0
    ) {
      setError("Le contenu de votre publication ne peut être vide !");
      return;
    }

    modalRef.current.scrollTop = 0;

    const author = {
      name: authorSelected.name
        ? authorSelected.name
        : `${capitalizeFirstLetter(
            user.firstName
          )} ${user.lastName.toUpperCase()}`,
      profilePic: authorSelected.profilePic,
      userId: authorSelected.id,
      username: authorSelected.username,
    };

    if (authorSelected.owners) {
      author.isCompany = true;
    }

    setLoading(true);
    if (postVideos.length > 0) {
      addPost({ text: postText, videos: postVideos }, author).then(() => {
        setLoading(false);
        window.location.reload();
      });
    } else {
      addPost({ text: postText, images: postImages }, author).then(() => {
        setLoading(false);
        window.location.reload();
      });
    }
  };

  const authors = [user, ...companies];

  let authorSelector = "";

  if (companies.length) {
    authorSelector = (
      <Row className="justify-content-end mb-3">
        <Col className="col-auto">
          <Dropdown
            isOpen={dropdownOpen}
            toggle={() => setDropdownOpen(!dropdownOpen)}
          >
            <DropdownToggle className="shadow-none m-0" caret>
              {authorSelected.name && (
                <Aux>
                  <img
                    className="avatar rounded-circle"
                    src={authorSelected.profilePic}
                    alt=""
                    style={{
                      maxWidth: "20px",
                      maxHeight: "20px",
                    }}
                  />
                  <span className="ml-2">
                    {authorSelected.name.substring(0, 25)}
                    {authorSelected.name.length > 10 && "..."}
                  </span>
                </Aux>
              )}
            </DropdownToggle>
            <DropdownMenu right>
              {authors.map((author) => (
                <DropdownItem
                  key={author.id}
                  onClick={() => setAuthorSelected(author)}
                >
                  <img
                    className="avatar rounded-circle"
                    src={author.profilePic}
                    alt=""
                    style={{
                      maxWidth: "20px",
                      maxHeight: "20px",
                    }}
                  />
                  <span className="ml-2">{author.name}</span>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </Col>
      </Row>
    );
  }

  return (
    <Modal
      innerRef={modalRef}
      isOpen={isOpen}
      toggle={toggle}
      onClosed={cancelHandler}
      className={classes.PublishModal}
    >
      <ModalHeader className="border-bottom" toggle={toggle}>
        <span className={classes.title}>Écrire une publication</span>
      </ModalHeader>
      <ModalBody className="pb-0">
        {loading && (
          <Col className="col-12 text-center">
            <ClipLoader color="#1171ef" size={30} />
          </Col>
        )}
        {error && <Alert color="danger">{error}</Alert>}
        {authorSelector}

        <Row>
          <Col>
            <Label className="sr-only">Votre message</Label>
            <Input
              maxLength={postMaxLength}
              type="textarea"
              className={classes.input}
              value={postText}
              onChange={(e) => setPostText(e.target.value)}
              placeholder="Votre message..."
            />
          </Col>
        </Row>
        <Row>
          <Col>
            {postMaxLength - postText.length < 10 && (
              <small style={{ color: "red" }}>
                {postMaxLength - postText.length} restant
                {postMaxLength - postText.length > 1 ? "s" : ""}
              </small>
            )}
          </Col>
        </Row>
      </ModalBody>
      {postVideos.length > 0 && (
        <ModalFooter
          className="w-100 overflow-visible"
          style={{ justifyContent: "start" }}
        >
          <div className={classes.PublishModal}>
            <div className={classes.images}>
              {postVideos.map((video, key) => {
                const index = `video_${key}`;

                return (
                  <>
                    <div
                      key={index}
                      className="mt-4"
                      style={{
                        display: "flex",
                        "justify-content": "center",
                        "align-items": "center",
                      }}
                    >
                      <video controls width="350" className="post-media">
                        <source src={video.video} />
                      </video>
                    </div>
                    <div
                      className={classes.discard}
                      onClick={() => handleDeleteVideo(key)}
                    >
                      <i className="fas fa-times" />
                    </div>
                  </>
                );
              })}
              <div
                className={classes.addImage}
                onClick={() => videoInputRef.current.click()}
              >
                <div className={classes.add}>
                  <i className="fas fa-plus-square" />
                </div>
              </div>
            </div>
          </div>
        </ModalFooter>
      )}
      {postImages.length > 0 && (
        <ModalFooter
          className="w-100 overflow-visible"
          style={{ justifyContent: "start" }}
        >
          <div className={classes.PublishModal}>
            <div className={classes.images}>
              {postImages.map((image, key) => {
                const index = `images_${key}`;

                return (
                  <div className={`${classes.previews} mr-2`} key={index}>
                    <img
                      id="message"
                      className="post-media"
                      src={image.img || image}
                      alt="chat"
                      height="200"
                    />
                    <div
                      className={classes.discard}
                      onClick={() => handleDeleteImage(key)}
                    >
                      <i className="fas fa-times" />
                    </div>
                  </div>
                );
              })}
              <div
                className={classes.addImage}
                onClick={() => imageInputRef.current.click()}
              >
                <div className={classes.add}>
                  <i className="fas fa-plus-square" />
                </div>
              </div>
            </div>
          </div>
        </ModalFooter>
      )}
      <ModalFooter className="justify-content-between">
        <input
          key={Math.random().toString(36)}
          type="file"
          ref={imageInputRef}
          style={{ display: "none" }}
          onChange={handleImageUpload}
          accept="image/png, image/gif, image/jpeg"
        />
        <input
          key={Math.random().toString(36)}
          type="file"
          accept="video/mp4,video/x-m4v,video/*"
          ref={videoInputRef}
          style={{ display: "none" }}
          onChange={handleVideoUpload}
        />
        {postImages.length === 0 && postVideos.length === 0 && (
          <Row>
            <Col className="p-0">
              <Button
                className="shadow-none"
                onClick={() => imageInputRef.current.click()}
              >
                <i className="fas fa-camera text-primary" />
              </Button>
            </Col>
            <Col className="p-0">
              <Button
                className="shadow-none"
                onClick={() => videoInputRef.current.click()}
              >
                <i className="fas fa-video text-primary" />
              </Button>
            </Col>
          </Row>
        )}
        <Col className="col-auto p-0 d-block">
          <Button
            color="primary"
            onClick={handleSubmitPost}
            className="mr-1"
            disabled={
              postImages.length === 0 &&
              postVideos.length === 0 &&
              postText.length === 0 &&
              postText.length === postMaxLength
            }
          >
            Publier
          </Button>
          <Button color="secondary" className="shadow-none" onClick={toggle}>
            Annuler
          </Button>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

const mapDispatchToProps = (state) => ({
  user: state.User.user,
  companies: state.User.user.companies,
});

export default connect(mapDispatchToProps, {
  addPost: dbAddPost,
})(PublishModal);

PublishModal.propTypes = {
  companies: PropTypes.array,
  addPost: PropTypes.func,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  user: PropTypes.object,
};

PublishModal.defaultProps = {
  companies: [],
  addPost: () => {},
  isOpen: false,
  toggle: () => {},
  user: {},
};
