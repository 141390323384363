import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalHeader, Row } from "reactstrap";

class IncompleteSearchModal extends Component {
    render() {
        const { isOpen, toggle } = this.props;

        return (
            <Modal size="lg" isOpen={isOpen} centered toggle={toggle}>
                <ModalHeader toggle={toggle} />
                <ModalBody className="pt-0">
                    <Row className="justify-content-center">
                        <h5 className="h1 mb-0 font-weight-light">
                            Indiquer un nom, une entreprise, un service ou une catégorie
                        </h5>
                    </Row>
                    <Row className="justify-content-center">
                        <span>
                            <Button onClick={toggle} style={{ marginLeft: 16 }}>
                                OK
                            </Button>
                        </span>
                    </Row>
                </ModalBody>
            </Modal>
        );
    }
}

export default IncompleteSearchModal;

IncompleteSearchModal.propTypes = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
};

IncompleteSearchModal.defaultProps = {
    isOpen: false,
    toggle: () => { },
};