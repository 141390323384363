import { Link } from "react-router-dom";
import Aux from "../../../hoc/Aux";
import PageComponent from "../PageComponent";
import "../custom.css";

function AddMember() {
  return (
    <Aux>
      <PageComponent>
        <h1 className="help-item-title">Ajouter un membre à mes contacts</h1>
        <p className="help-item-text">
          Sur Tribu, seuls des courtiers et des fournisseurs peuvent être
          invités à rejoindre votre réseau.
          <p className="help-item-subtitle">Depuis la page Contacts</p>
          <ul>
            <li>Rendez-vous sur la page Contacts</li>
            <li>Cliquez sur Import de contacts à droite</li>
            <li>
              Télécharger le modèle de fichier selon le profil de vos contacts
            </li>
            <li>Complétez-le, uploadez et envoyez !</li>
          </ul>
          <p className="help-item-subtitle">Depuis la page Contacts</p>
          Cliquer sur le bouton "Ajouter" présent sur le profil (à droite de son
          nom) pour lui envoyer une invitation.
          <br />
          <br />
          Vous pouvez vérifier si vous avez déjà adressé une invitation à ce
          membre. Si c’est le cas, le bouton "Ajouter" est grisé sur sa fiche et
          il faut attendre qu’il accepte votre invitation.
          <br />
          <br />
          Votre destinataire peut prendre connaissance de votre demande de
          contact grâce à l'email d'alerte envoyé automatiquement. Cependant, si
          son compte n’a pas encore été vérifié, il est nécessaire d'attendre
          que votre correspondant se connecte sur le site. Ceci peut rendre
          variable la durée de réception de votre invitation.
          <br />
          <br />
          Vous retrouverez l’ensemble de vos invitations reçues et envoyées dans
          la page Invitations.
          <br />
          <br />
        </p>
      </PageComponent>
    </Aux>
  );
}

export default AddMember;
