import React, { Component } from "react";
import Aux from "../../hoc/Aux";
import DefaultNavbar2 from "../../layouts/Default/DefaultNavbar2";
import DefaultFooter from "../../layouts/Default/DefaultFooter";
import DashboardHeader from "../../layouts/Default/DashboardHeader";
import { Col, Container, Row } from "reactstrap";
import Publish2 from "../Posts/Publish2";
import Posts2 from "../Posts/Posts2";
import { Route, Switch } from "react-router";
import Post from "../Posts/Post";

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      postMaxLength: 1500,
    };
  }

  render() {
    const { postMaxLength } = this.state;
    return (
      <div style={{ paddingBottom: "100px" }}>
        <Aux>
          <DefaultNavbar2 allowNotifications={true} fetched={true} />
          <DashboardHeader breadcrumb={false} />
          <Container fluid className="posts-container">
            <Row className="">
              <Col xs={12} lg={3} className="d-none d-lg-block position-fixed">
                <Row>
                  <Col xs={12}></Col>
                </Row>
              </Col>
              <Col xs={12} lg={6} className="offset-lg-3">
                <Col>
                  <Publish2 postMaxLength={postMaxLength} />
                  <Posts2 postMaxLength={postMaxLength} />
                  <Switch>
                    <Route path="/post/:postId" component={Post} />
                  </Switch>
                </Col>
              </Col>
              <Col
                xs={12}
                lg={3}
                className="d-none d-lg-block"
                style={{ right: 0 }}
              >
                <Row>
                  <Col xs={12}></Col>
                  <Col
                    xs={12}
                    className={
                      this.props.companies !== undefined &&
                      this.props.companies !== null &&
                      JSON.stringify(this.props.companies) !==
                        JSON.stringify([])
                        ? "mt-3"
                        : "mt-0"
                    }
                  ></Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Aux>
        <div
          style={{
            position: "fixed",
            left: 0,
            bottom: 0,
            right: 0,
            backgroundColor: "white",
          }}
        >
          <DefaultFooter />
        </div>
      </div>
    );
  }
}

export default Home;
