import React, { Component, createRef } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { withRouter } from "react-router";
import Aux from "../../hoc/Aux";
import "./custom.css";
import { createAutocomplete } from "@algolia/autocomplete-core";
import { getAlgoliaResults } from "@algolia/autocomplete-preset-algolia";
import algoliasearch from "algoliasearch/lite";
import PlacesAutocomplete from "react-places-autocomplete";
import IncompleteSearchModal from "../../views/Search/IncompleteSearchModal";
import "@algolia/autocomplete-theme-classic/dist/theme.css";

const searchClient = algoliasearch(
  "DS4P4HIB8H",
  "8cced0de8b8fbfec89bfa7203116ae37"
);

class DefaultSearchBar5 extends Component {
  constructor(props) {
    super(props);
    const { location } = this.props;

    const params = new URLSearchParams(location.search);

    this.inputLeft = createRef();
    this.inputRight = createRef();

    this.state = {
      search: params.get("q") ?? "",
      city: params.get("l") ?? params.get("d") ?? "",
      errorSearch: false,
      errorCity: false,
      autocompleteState: {},
      modal: false,
      isCompany: false,
      inputLeftWidth: 0,
      inputRightWidth: 0,
      width: 768,
      widthForMobile: 576,
      mobileMode: false,
    };

    this.autocomplete = createAutocomplete({
      onStateChange: this.onChange,
      getSources() {
        return [
          {
            sourceId: "hits",
            getItems({ query }) {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: "users",
                    query,
                    params: {
                      hitsPerPage: 5,
                      highlightPreTag: "<mark>",
                      highlightPostTag: "</mark>",
                    },
                  },
                  {
                    indexName: "categories",
                    query,
                    params: {
                      hitsPerPage: 5,
                      highlightPreTag: "<mark>",
                      highlightPostTag: "</mark>",
                    },
                  },
                  {
                    indexName: "services",
                    query,
                    params: {
                      hitsPerPage: 5,
                      highlightPreTag: "<mark>",
                      highlightPostTag: "</mark>",
                    },
                  },
                  {
                    indexName: "companies",
                    query,
                    params: {
                      hitsPerPage: 5,
                      highlightPreTag: "<mark>",
                      highlightPostTag: "</mark>",
                    },
                  },
                ],
              });
            },
            getItemUrl({ item }) {
              return item.url;
            },
          },
        ];
      },
    });
  }

  componentDidMount() {
    this.setState({ width: window.innerWidth });
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }

  componentDidUpdate() {
    if (this.props?.searchCity && this.props?.searchCity !== this.state.city) {
      this.setState({ city: this.props.searchCity });
    }
    if (this.props?.searchLeft && this.props?.searchLeft !== this.state.search)
      this.setState({ search: this.props.searchLeft });
  }

  checkIfMobile() {
    return window.innerWidth <= this.state.widthForMobile;
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      const params = new URLSearchParams(location.search);
      if (params.get("q") !== null) {
        this.setState({ search: params.get("q") });
      }
      if (params.get("l") !== null) {
        this.setState({ city: params.get("l") });
      }
      if (params.get("l") === null) {
        this.setState({ city: "" });
      }
    });
  }

  handleResize = () => {
    this.setState({
      inputLeftWidth: this.inputLeft?.current?.offsetWidth,
      inputRightWidth: this.inputRight?.current?.offsetWidth,
    });
    this.setState({ width: window.innerWidth });

    if (!this.checkIfMobile()) {
      this.setState({ mobileMode: false });
    } else this.setState({ mobileMode: true });
  };

  onChange = ({ state }) => {
    this.setState({ autocompleteState: state, search: state.query });
  };

  handleCityChange = (city) => {
    this.setState({ city });
  };

  handleSearch = (e) => {
    e?.preventDefault();
    const { search, city, isCompany } = this.state;
    const { history, location } = this.props;

    const params = new URLSearchParams(location.search);
    const categories = params.get("c") ?? "";
    const services = params.get("s") ?? "";

    if (search === "") {
      this.setState({ errorSearch: true });
      return;
    }

    history.push(
      `/search${search ? `?q=${search}` : ""}${
        city ? `&l=${city.replace(/, France$/, "")}` : ""
      }${categories ? `&c=${categories}` : ""}${
        services ? `&s=${services}` : ""
      }`
    );

    // if (isCompany) {
    //   history.push(
    //     `/actions?e=${search}${
    //       city ? `&l=${city.replace(/, France$/, "")}` : ""
    //     }${categories ? `&c=${categories}` : ""}${
    //       services ? `&s=${services}` : ""
    //     }`
    //   );
    // } else {
    //   history.push(
    //     `/search${search ? `?q=${search}` : ""}${
    //       city ? `&l=${city.replace(/, France$/, "")}` : ""
    //     }${categories ? `&c=${categories}` : ""}${
    //       services ? `&s=${services}` : ""
    //     }`
    //   );
    // }
  };

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  render() {
    const {
      search,
      city,
      autocompleteState,
      errorSearch,
      errorCity,
      mobileMode,
    } = this.state;

    return (
      <div className="aa-Autocomplete" {...this.autocomplete?.getRootProps({})}>
        <Aux>
          <IncompleteSearchModal
            isOpen={this.state.modal}
            toggle={() => this.setState({ modal: !this.state.modal })}
          />
          <Form
            className={`navbar-search navbar-search-dark form-inline w-100 mx-auto d-flex ${
              this.props.isHomePage
                ? "align-items-start"
                : this.props.isSearchPage
                ? "align-items-start"
                : ""
            }`}
            onSubmit={this.handleSearch}
            style={{ transform: "translateX(0)" }}
          >
            <FormGroup className="input-left-container" style={{}}>
              <InputGroup className="input-group-alternative border-right-0 w-100 input-left">
                <div className="w-100" ref={this.inputLeft}>
                  <Input
                    ref={this.inputLeftRef}
                    style={{ textOverflow: "ellipsis", width: "100%" }}
                    {...this.autocomplete?.getInputProps({
                      placeholder:
                        "Personne, Entreprise, Service, n° Orias, Siren, Mot-clé ...",
                      value: search,
                    })}
                  />
                </div>
              </InputGroup>
              {errorSearch && search === "" && (
                <span
                  className="position-absolute text-danger"
                  style={{
                    transform: `translate(0px, ${mobileMode ? "2" : "32"}px)`,
                    width:
                      this.state.inputLeftWidth ||
                      this.inputLeft.current.offsetWidth,
                    textShadow: "0px 0px 40px rgba(0,0,0,0.4)",
                  }}
                >
                  Indiquez un élément de recherche
                </span>
              )}
              {errorCity && city === "" && (
                <span
                  className="position-absolute text-danger"
                  style={{
                    transform: `translate(0px, ${mobileMode ? "70" : "32"}px)`,
                    marginLeft: mobileMode
                      ? 0
                      : this.state.inputLeftWidth ||
                        this.inputLeft.current.offsetWidth,
                    width:
                      this.state.inputRightWidth ||
                      this.inputRight.current.offsetWidth,
                    textShadow: "0px 0px 40px rgba(0,0,0,0.4)",
                  }}
                >
                  Indiquez une ville
                </span>
              )}
            </FormGroup>
            <FormGroup className="input-right-container ">
              <InputGroup
                className="input-group-alternative border-left-0 w-100 input-right"
                style={{
                  marginBottom: this.props.isSearchPage
                    ? 45
                    : mobileMode &&
                      !this.props.isSearchPage &&
                      !this.props.isHomePage
                    ? 45
                    : 90,
                  marginTop: this.props.isHomePage
                    ? 0
                    : this.props.isSearchPage
                    ? 0
                    : mobileMode
                    ? 0
                    : 90,
                }}
              >
                <PlacesAutocomplete
                  value={this.state.city.replace(/, France$/, "")}
                  onChange={this.handleCityChange}
                  searchOptions={{
                    types: ["(cities)"],
                    region: "FR",
                    componentRestrictions: { country: "fr" },
                  }}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <>
                      <div style={{ flex: 1 }} ref={this.inputRight}>
                        <Input
                          style={{ width: "100%", textOverflow: "ellipsis" }}
                          value={this.state.city.replace(/, France$/, "")}
                          {...getInputProps({
                            placeholder: "Bordeaux, Lyon, Nice ...",
                          })}
                        />
                      </div>

                      {(loading || suggestions.length > 0) && (
                        <div className="autocomplete-right-container cursor">
                          <div className="autocomplete-scroll">
                            {suggestions.map((suggestion) => {
                              return (
                                <div
                                  className="autocomplete-right-item style suggestion"
                                  {...getSuggestionItemProps(suggestion)}
                                >
                                  {suggestion.description.replace(
                                    /, France$/,
                                    ""
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </PlacesAutocomplete>
                {!mobileMode && (
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText
                      style={{ cursor: "pointer" }}
                      onClick={this.handleSearch}
                    >
                      <i className="fas fa-search float-right" />
                    </InputGroupText>
                  </InputGroupAddon>
                )}
              </InputGroup>
              {mobileMode && (
                <Button
                  className="w-100 rounded-pill py-2"
                  style={{
                    transform: `translate(0px, ${
                      this.props.isHomePage ? "-60px" : "-15px"
                    })`,
                    marginBottom: 10,
                    borderRadius: 100,
                    height: 44,
                  }}
                  onClick={this.handleSearch}
                >
                  Rechercher un courtier
                </Button>
              )}
            </FormGroup>
            <input
              type="submit"
              style={{
                position: "absolute",
                left: "-9999px",
                width: 0,
                height: 0,
              }}
              tabIndex="-1"
            />
          </Form>
          {autocompleteState.isOpen == true && (
            <div
              {...this.autocomplete?.getPanelProps({})}
              className={
                this.props.isSearchPage
                  ? "autocomplete-left-container-noblockspace"
                  : "autocomplete-left-container"
              }
            >
              <div className="autocomplete-scroll">
                {autocompleteState.collections.map((collection, index) => {
                  const { source, items } = collection;

                  return (
                    <div key={`source-${index}`} className="bg-white">
                      {items.map((item) => (
                        <div>
                          <div
                            className="aa-ItemWrapper suggestion cursor"
                            style={{ padding: 0 }}
                            {...this.autocomplete?.getItemProps({
                              item,
                              source,
                            })}
                            onClick={() => {
                              /*const { city } = this.state;
                                                            const { history, location } = this.props;
        
                                                            const params = new URLSearchParams(location.search);
                                                            const categories = params.get('c') ?? "";
                                                            const services = params.get('s') ?? "";*/

                              if (item.name !== undefined) {
                                // history.push(`/search?e=${item.name}${city ? `&l=${city.replace(/, France$/, "")}` : ''}${categories ? `&c=${categories}` : ''}${services ? `&s=${services}` : ''}`)
                                this.setState({
                                  search: item.name,
                                  isCompany: true,
                                });
                                this.state.autocompleteState.query = item.name;
                                this.state.autocompleteState.isOpen = false;
                              } else if (
                                item.label !== undefined &&
                                item.category !== undefined
                              ) {
                                // history.push(`/search?q=&c=${item.category}&s=${item.label}`)
                                this.setState({ search: item.label });
                                this.state.autocompleteState.query = item.label;
                                this.state.autocompleteState.isOpen = false;
                              } else if (item.label !== undefined) {
                                // history.push(`/search?q=&c=${item.label}`)
                                this.setState({ search: item.label });
                                this.state.autocompleteState.query = item.label;
                                this.state.autocompleteState.isOpen = false;
                              } else {
                                // history.push(`/search?q=${item.firstName}+${item.lastName}${city ? `&l=${city.replace(/, France$/, "")}` : ''}${categories ? `&c=${categories}` : ''}${services ? `&s=${services}` : ''}`)
                                this.setState({
                                  search: `${item.firstName} ${item.lastName}`,
                                });
                                this.state.autocompleteState.query = `${item.firstName} ${item.lastName}`;
                                this.state.autocompleteState.isOpen = false;
                              }
                            }}
                          >
                            <div className="aa-ItemContent">
                              <div className="aa-ItemIcon aa-ItemIcon--noBorder">
                                <svg
                                  viewBox="0 0 24 24"
                                  width="18"
                                  height="18"
                                  fill="currentColor"
                                >
                                  <path d="M16.041 15.856c-0.034 0.026-0.067 0.055-0.099 0.087s-0.060 0.064-0.087 0.099c-1.258 1.213-2.969 1.958-4.855 1.958-1.933 0-3.682-0.782-4.95-2.050s-2.050-3.017-2.050-4.95 0.782-3.682 2.050-4.95 3.017-2.050 4.95-2.050 3.682 0.782 4.95 2.050 2.050 3.017 2.050 4.95c0 1.886-0.745 3.597-1.959 4.856zM21.707 20.293l-3.675-3.675c1.231-1.54 1.968-3.493 1.968-5.618 0-2.485-1.008-4.736-2.636-6.364s-3.879-2.636-6.364-2.636-4.736 1.008-6.364 2.636-2.636 3.879-2.636 6.364 1.008 4.736 2.636 6.364 3.879 2.636 6.364 2.636c2.125 0 4.078-0.737 5.618-1.968l3.675 3.675c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414z" />
                                </svg>
                              </div>
                              <div className="aa-ItemContentBody">
                                <div className="aa-ItemContentTitle">
                                  {item.name !== undefined &&
                                  item.socialReason !== undefined
                                    ? `${item.socialReason} (${item.name})`
                                    : item.name !== undefined
                                    ? `${item.name}`
                                    : item.label !== undefined
                                    ? `${item.label}`
                                    : `${this.capitalizeFirstLetter(
                                        item.firstName.toLowerCase()
                                      )} ${item.lastName.toUpperCase()}`}
                                </div>
                              </div>
                            </div>

                            <div className="aa-ItemActions">
                              <button
                                className="aa-ItemActionButton"
                                title={`Fill query with "${item.query}"`}
                                onClick={(event) => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                }}
                              >
                                <svg
                                  viewBox="0 0 24 24"
                                  width="18"
                                  height="18"
                                  fill="currentColor"
                                >
                                  <path d="M8 17v-7.586l8.293 8.293c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414l-8.293-8.293h7.586c0.552 0 1-0.448 1-1s-0.448-1-1-1h-10c-0.552 0-1 0.448-1 1v10c0 0.552 0.448 1 1 1s1-0.448 1-1z" />
                                </svg>
                              </button>
                            </div>
                          </div>
                          <div className="aa-ItemWrapper">
                            <div className="aa-ItemContent aa-ItemContent--indented">
                              <div className="aa-ItemContentSubtitle aa-ItemContentSubtitle--standalone">
                                <span className="aa-ItemContentSubtitleIcon" />
                                <span>
                                  dans{" "}
                                  <span className="aa-ItemContentSubtitleCategory">
                                    {item.category !== undefined &&
                                    item.sub_category !== undefined &&
                                    item.sub_sub_category !== undefined &&
                                    item.sub_sub_sub_category !== undefined
                                      ? `${item.sub_sub_sub_category} - ${item.sub_sub_category} - ${item.sub_category} - ${item.category}`
                                      : item.category !== undefined &&
                                        item.sub_category !== undefined &&
                                        item.sub_sub_category !== undefined
                                      ? `${item.sub_sub_category} - ${item.sub_category} - ${item.category}`
                                      : item.category !== undefined &&
                                        item.sub_category !== undefined
                                      ? `${item.sub_category} - ${item.category}`
                                      : item.label !== undefined
                                      ? `Catégorie`
                                      : item.name !== undefined
                                      ? `Entreprise`
                                      : `Personne`}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </Aux>
      </div>
    );
  }

  /*render() {
        const { search, autocompleteState } = this.state;

        return (
            <div className="aa-Autocomplete" {...this.autocomplete?.getRootProps({})}>
                <Aux>
                    <IncompleteSearchModal
                        isOpen={this.state.modal}
                        toggle={() => this.setState({ modal: !this.state.modal })}
                    />
                    <Form className="navbar-search navbar-search-dark form-inline w-100 mx-auto d-flex" onSubmit={this.handleSearch} style={{ transform: 'translateX(0)' }}>
                        <FormGroup className="w-50">
                            <InputGroup className="input-group-alternative border-right-0 w-100" style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0, backgroundColor: 'rgba(0, 0, 0, .2)' }}>
                                <Input {...this.autocomplete?.getInputProps({
                                    placeholder: "Ex : Nom, Entreprise, COBSP, COA ...",
                                    value: search
                                })} />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup className="w-50">
                            <InputGroup className="input-group-alternative border-left-0 w-100" style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, backgroundColor: 'rgba(0, 0, 0, .2)' }}>
                                <PlacesAutocomplete
                                    value={this.state.city.replace(/, France$/, "")}
                                    onChange={this.handleCityChange}
                                    searchOptions={{
                                        types: ['(cities)'],
                                        region: 'FR',
                                        componentRestrictions: { country: "fr" }
                                    }}
                                >
                                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                            <Input
                                                value={this.state.city.replace(/, France$/, "")}
                                                {...getInputProps({
                                                    placeholder: "Ex : Lyon, Nice ...",
                                                    className: 'w-95',
                                                })}
                                            />
                                            <div className="autocomplete-dropdown-container">
                                                {loading && <div>Loading...</div>}
                                                {suggestions.map(suggestion => {
                                                    const className = suggestion.active
                                                        ? 'suggestion-item--active'
                                                        : 'suggestion-item';
                                                    // inline style for demonstration purpose
                                                    const style = suggestion.active
                                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                    return (
                                                        <div
                                                            {...getSuggestionItemProps(suggestion, {
                                                                className,
                                                                style,
                                                            })}
                                                        >
                                                            <span>{suggestion.description.replace(/, France$/, "")}</span>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText style={{ cursor: 'pointer' }} className="w-5" onClick={this.handleSearch}>
                                        <i className="fas fa-search float-right" />
                                    </InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                        <input
                            type="submit"
                            style={{
                                position: 'absolute', left: '-9999px', width: 0, height: 0,
                            }}
                            tabIndex="-1"
                        />
                    </Form>
                </Aux>
                <div {...this.autocomplete?.getPanelProps({})}>
                    {autocompleteState.isOpen &&
                        autocompleteState.collections.map((collection, index) => {
                            const { source, items } = collection;

                            return (
                                <div key={`source-${index}`} className="bg-white" >
                                    {items.map((item) => (
                                        <div>
                                            <div className="aa-ItemWrapper suggestion"
                                                {...this.autocomplete?.getItemProps({
                                                    item,
                                                    source
                                                })}
                                                onClick={() => {
                                                    if (item.name !== undefined) {
                                                        // history.push(`/search?e=${item.name}${city ? `&l=${city.replace(/, France$/, "")}` : ''}${categories ? `&c=${categories}` : ''}${services ? `&s=${services}` : ''}`)
                                                        this.setState({ search: item.name, isCompany: true })
                                                        this.state.autocompleteState.query = item.name
                                                        this.state.autocompleteState.isOpen = false
                                                    }
                                                    else if (item.label !== undefined && item.category !== undefined) {
                                                        // history.push(`/search?q=&c=${item.category}&s=${item.label}`)
                                                        this.setState({ search: item.label })
                                                        this.state.autocompleteState.query = item.label
                                                        this.state.autocompleteState.isOpen = false
                                                    } else if (item.label !== undefined) {
                                                        // history.push(`/search?q=&c=${item.label}`)
                                                        this.setState({ search: item.label })
                                                        this.state.autocompleteState.query = item.label
                                                        this.state.autocompleteState.isOpen = false
                                                    } else {
                                                        // history.push(`/search?q=${item.firstName}+${item.lastName}${city ? `&l=${city.replace(/, France$/, "")}` : ''}${categories ? `&c=${categories}` : ''}${services ? `&s=${services}` : ''}`)
                                                        this.setState({ search: `${item.firstName} ${item.lastName}` })
                                                        this.state.autocompleteState.query = `${item.firstName} ${item.lastName}`
                                                        this.state.autocompleteState.isOpen = false
                                                    }
                                                }}
                                            >
                                                <div className="aa-ItemContent">
                                                    <div className="aa-ItemIcon aa-ItemIcon--noBorder">
                                                        <svg
                                                            viewBox="0 0 24 24"
                                                            width="18"
                                                            height="18"
                                                            fill="currentColor"
                                                        >
                                                            <path d="M16.041 15.856c-0.034 0.026-0.067 0.055-0.099 0.087s-0.060 0.064-0.087 0.099c-1.258 1.213-2.969 1.958-4.855 1.958-1.933 0-3.682-0.782-4.95-2.050s-2.050-3.017-2.050-4.95 0.782-3.682 2.050-4.95 3.017-2.050 4.95-2.050 3.682 0.782 4.95 2.050 2.050 3.017 2.050 4.95c0 1.886-0.745 3.597-1.959 4.856zM21.707 20.293l-3.675-3.675c1.231-1.54 1.968-3.493 1.968-5.618 0-2.485-1.008-4.736-2.636-6.364s-3.879-2.636-6.364-2.636-4.736 1.008-6.364 2.636-2.636 3.879-2.636 6.364 1.008 4.736 2.636 6.364 3.879 2.636 6.364 2.636c2.125 0 4.078-0.737 5.618-1.968l3.675 3.675c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414z" />
                                                        </svg>
                                                    </div>
                                                    <div className="aa-ItemContentBody">
                                                        <div className="aa-ItemContentTitle">
                                                            {
                                                                item.name !== undefined ?
                                                                    `${item.name}` :
                                                                    item.label !== undefined ?
                                                                        `${item.label}`
                                                                        : `${this.capitalizeFirstLetter(item.firstName.toLowerCase())} ${item.lastName.toUpperCase()}`
                                                            }

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="aa-ItemActions">
                                                    <button
                                                        className="aa-ItemActionButton"
                                                        title={`Fill query with "${item.query}"`}
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            event.stopPropagation();
                                                        }}
                                                    >
                                                        <svg
                                                            viewBox="0 0 24 24"
                                                            width="18"
                                                            height="18"
                                                            fill="currentColor"
                                                        >
                                                            <path d="M8 17v-7.586l8.293 8.293c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414l-8.293-8.293h7.586c0.552 0 1-0.448 1-1s-0.448-1-1-1h-10c-0.552 0-1 0.448-1 1v10c0 0.552 0.448 1 1 1s1-0.448 1-1z" />
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="aa-ItemWrapper">
                                                <div className="aa-ItemContent aa-ItemContent--indented">
                                                    <div className="aa-ItemContentSubtitle aa-ItemContentSubtitle--standalone">
                                                        <span className="aa-ItemContentSubtitleIcon" />
                                                        <span>
                                                            dans{' '}
                                                            <span className="aa-ItemContentSubtitleCategory">
                                                                {
                                                                    item.category !== undefined && item.sub_category !== undefined && item.sub_sub_category !== undefined && item.sub_sub_sub_category !== undefined ?
                                                                        `${item.sub_sub_sub_category} - ${item.sub_sub_category} - ${item.sub_category} - ${item.category}`
                                                                        : item.category !== undefined && item.sub_category !== undefined && item.sub_sub_category !== undefined ?
                                                                            `${item.sub_sub_category} - ${item.sub_category} - ${item.category}`
                                                                            : item.category !== undefined && item.sub_category !== undefined ?
                                                                                `${item.sub_category} - ${item.category}`
                                                                                : item.label !== undefined ?
                                                                                    `Catégorie`
                                                                                    : item.name !== undefined ?
                                                                                        `Entreprise`
                                                                                        : `Personne`
                                                                }
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            );
                        })}
                </div>
            </div>
        );
    }*/
}

export default withRouter(DefaultSearchBar5);
