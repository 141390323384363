import { firebaseApp, db } from "../../config/firebase";
import {
  SET_RECENTS_CHATS,
  SET_RECENTS_CHATS_2,
  SET_SELECTED_TABS,
  SET_RECENTS_GROUPED_CHATS,
} from "./types";

export const setChatSelectedTabb = (selectedTab) => ({
  type: SET_SELECTED_TABS,
  selectedTab,
});

const setChats = (chats) => ({
  type: SET_RECENTS_CHATS,
  chats,
});

const setGroupedChats = (groupedChats) => ({
  type: SET_RECENTS_GROUPED_CHATS,
  groupedChats,
});

const setChats2 = (chats) => ({
  type: SET_RECENTS_CHATS_2,
  chats,
});


export const setChatSelectedTab = (selectedTab) => (dispatch) => {
  dispatch(setChatSelectedTabb(selectedTab));
};

export const dbFetchChats = () => (dispatch) => {
  const userId = firebaseApp.auth().currentUser.uid;

  //  get only chats without grouped TODO

  db.collection("users")
    .doc(userId)
    .collection("chats")
    .onSnapshot((snapshot) => {
      const chats = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      dispatch(setChats(chats));
    });
};

export const dbFetchGroupedChats = () => (dispatch) => {
  const userId = firebaseApp.auth().currentUser.uid;

  //   db.collection("users")
  //     .doc(userId)
  //     .collection("chats") or groupedChats
  //     .where("is_grouped", "==", true) or other logic
  //     .onSnapshot((snapshot) => {
  //       const chats = snapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //       }));

  //       dispatch(setChats(chats));
  //     });

  const groupedChats = [
    {
      id: 1,
      users: [
        {
          name: "Gilles Bedel",
        },
        {
          name: "Avenir Courtage",
        },
        {
          name: "Paul Lemarchand",
        },
        {
          name: "Robert Dupont",
        },
      ],
      created_at: 1686010239122,
      message:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
      
    },
  ];

  dispatch(setGroupedChats(groupedChats));
};

export const dbFetchChats2 = () => (dispatch) => {
  const userId = firebaseApp.auth().currentUser.uid;

  //  get only chats without grouped TODO

  db.collection("users")
    .doc(userId)
    .collection("chats")
    .where("seen", "==", false)
    .onSnapshot((snapshot) => {
      const chats = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      dispatch(setChats2(chats));
    });
};
