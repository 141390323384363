import { Link } from "react-router-dom";
import Aux from "../../../hoc/Aux";
import PageComponent from "../PageComponent";
import "../custom.css";

function AccountActivation() {
  return (
    <Aux>
      <PageComponent>
        <h1 className="help-item-title">Activation du compte</h1>
        <p className="help-item-text">
          Afin de garantir les mises en relation entre ses membres, Tribu a mis
          en place un système "d’activation" des comptes.
          <br />
          <br />
          Il est donc essentiel d’attendre la vérification de votre compte pour
          utiliser Tribu. 
          <p className="help-item-subtitle">
            Comment savoir si votre compte est activé ou pas ? 
          </p>
          Au moment de votre inscription à Tribu, vous avez reçu un e-mail de
          confirmation. Tribu vérifie votre compte sous 48h.
          <br />
          <br />À la vérification de votre compte, vous recevez immédiatement un
          e-mail vous confirmant la validation de votre compte et vous invitant
          à vous connecter à Tribu.
          <p className="help-item-subtitle">
            Vous ne retrouvez pas cet e-mail ?
          </p>
          Peut-être vous êtes-vous trompé lorsque vous avez saisi votre adresse
          e-mail au moment de votre inscription. Il vous suffit de nous écrire
          un message en utilisant <Link to="/nous-contacter">ce formulaire</Link>.
        </p>
      </PageComponent>
    </Aux>
  );
}

export default AccountActivation;
