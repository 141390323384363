import React, { Component } from 'react';
import {
    Col, Container, Row,
} from 'reactstrap';
import { Helmet } from 'react-helmet';
import Aux from '../../hoc/Aux';
import config from '../../config/config';
import GetInTouchForm from './GetInTouchForm';

class GetInTouch extends Component {
    render() {
        return (
            <Aux>
                <Helmet>
                    <title>{`${config.title} - Contactez-nous`}</title>
                </Helmet>
                <div className="header bg-gradient-info py-9">
                    <Container>
                        <div className="header-body">
                            <Row className="align-items-center">
                                <Col className="my-auto pb-4 pb-md-auto" xs={12} md={6}>
                                    <h5 className="h2 display-3 mb-0 text-white font-weight-300">Une question ?</h5>
                                    <h5 className="h1 display-2 my-0 text-white">
                                        Envoyez
                                        <span className="d-inline"> un message !</span>
                                    </h5>
                                </Col>
                                <Col xs={12} md={6}>
                                    <GetInTouchForm />
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    <div className="separator separator-bottom separator-skew">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="fill-white"
                                points="2560 0 2560 100 0 100"
                            />
                        </svg>
                    </div>
                </div>
            </Aux>
        );
    }
}

export default GetInTouch;
