import React, { useState } from 'react';
import {
    Alert,
    Button,
    Card,
    Col, Container, Row,
} from 'reactstrap';
import CardBody from 'reactstrap/es/CardBody';
import { Link } from 'react-router-dom';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { Helmet } from 'react-helmet';
import { ClipLoader } from 'react-spinners';
import Aux from '../../hoc/Aux';
import GenericForm from '../../components/Ui/GenericForm';
import CorporateFields from './CorporateFields';
import { PDD } from '../../router/Routes';
import { firebaseApp } from '../../config/firebase';

const Corporate = () => {
    const [isPending, setIsPending] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [corporate, setCorporate] = useState({
        lastName: '',
        firstName: '',
        mail: '',
        phone: '',
        company: '',
    });

    const formRef = React.createRef();

    const handleChange = (e) => {
        const { name, value } = e.target;

        setCorporate({
            ...corporate,
            [name]: value,
        });
    };

    const handleSubmit = () => {
        setIsPending(true);

        const mailCorporateGetInTouch = firebaseApp.functions().httpsCallable('mailCorporateGetInTouch');
        mailCorporateGetInTouch({ mail: corporate }).then((result) => {
            setIsPending(false);

            if (result.data && result.data.success) {
                setIsSuccess(true);
            } else if (result.data && result.data.error) {
                setError(result.data.error);
            } else {
                setError('Une erreur s\'est produite');
            }
        });
    };

    const formMap = (form, values) => form.map((field) => {
        const newField = { ...field };
        newField.value = values[field.name] || '';
        newField.handleChange = handleChange;
        return newField;
    });

    /*return (
        <Aux>
            <Helmet>
                <title>{`${config.title} - La plateforme de courtage crédit et assurance leader en France`}</title>
                <meta
                    name="description"
                    content="Créez votre meilleur profil banque ou compagnie d’assurance sur Tribu."
                />
            </Helmet>
            {
                isSuccess && (
                    <ReactBSAlert
                        success
                        title=""
                        style={{ display: 'block' }}
                        onConfirm={() => setIsSuccess(false)}
                        confirmBtnBsStyle="success"
                        confirmBtnText="OK"
                        btnSize=""
                    >
                            Nous avons bien reçu votre demande !
                    </ReactBSAlert>
                )
            }
            <div className="header bg-gradient-warning py-9">
                <Container>
                    <div className="header-body">
                        <Row className="align-items-center">
                            <Col className="my-auto pb-4 pb-md-auto" xs={12} md={6}>
                                <h5 className="h2 display-3 mb-0 text-white font-weight-300">Corporate vous intéresse ?</h5>
                                <h5 className="h1 display-2 my-0 text-white">
                                            Demandez
                                    <span className="d-inline"> une démo !</span>
                                </h5>
                                <hr className="bg-white w-50 text-left my-2" />
                                <p className="text-white display-4 font-weight-300">
                                        Tribu est la solution pour trouver et gérer simplement les meilleurs courtiers.
                                </p>
                            </Col>
                            <Col xs={12} md={6}>
                                <Card>
                                    <CardBody>
                                        {
                                            error && (
                                                <Alert color="danger">
                                                    {error}
                                                </Alert>
                                            )
                                        }
                                        {
                                            !isPending ? (
                                                <Aux>
                                                    <GenericForm ref={formRef} fields={formMap(CorporateFields, corporate)} onSubmit={handleSubmit} />
                                                    <Row className="d-block">
                                                        <Col>
                                                            <small>
                                                                    Tribu peut utiliser ces informations pour une communication personnalisée relative à des évènements à venir, des webinars et la plateforme Tribu. Vous pouvez vous désinscrire à tout moment.
                                                                <br />
                                                                    En savoir plus sur
                                                                <Link to={PDD} className="text-warning"> notre politique de protection des données.</Link>
                                                            </small>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-3 justify-content-end">
                                                        <Col className="col-auto">
                                                            <Button color="warning" onClick={() => formRef.current.handleSubmit()}>ENVOYER</Button>
                                                        </Col>
                                                    </Row>
                                                </Aux>
                                            ) : (
                                                <Row className="py-8 justify-content-center">
                                                    <ClipLoader size={100} color="#fb6340" />
                                                </Row>
                                            )
                                        }

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <div className="separator separator-bottom separator-skew">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="fill-white"
                            points="2560 0 2560 100 0 100"
                        />
                    </svg>
                </div>
            </div>
            <Container className="pb-4">
                <Row className="justify-content-center">
                    <Col lg="12">
                        <Row>
                            <Col className="mt-4 mt-lg-5" xs={12} lg="6">
                                <Card className="card-lift--hover shadow border-0">
                                    <CardBody className="py-5">
                                        <Row>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-gradient-warning text-white rounded-circle mb-4">
                                                    <i className="ni ni-single-02" />
                                                </div>
                                            </Col>
                                            <Col>
                                                <h4 className="h3 text-warning">
                                                        Des indépendants au service du courtage
                                                </h4>
                                                <p className="description" style={{ fontSize: '14px' }}>
                                                        Crédit immobilier, Rachat de crédit, Crédit pro, Assurances, Gestion de Patrimoine, Conseil en investissements financiers, Crowdfunding, etc.
                                                </p>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="mt-5" xs={12} lg="6">
                                <Card className="card-lift--hover shadow border-0">
                                    <CardBody className="py-5">
                                        <Row>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-gradient-warning text-white rounded-circle mb-4">
                                                    <i className="ni ni-atom" />
                                                </div>
                                            </Col>
                                            <Col>
                                                <h4 className="h3 text-warning">
                                                        De plus en plus de courtiers référencés
                                                </h4>
                                                <p className="description" style={{ fontSize: '14px' }}>
                                                        Quels que soient vos objectifs, il y a forcément des courtiers pour vous aider à les réaliser. Tous les métiers du courtage sont représentés sur Tribu.
                                                </p>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="mt-5" xs={12} lg="6">
                                <Card className="card-lift--hover shadow border-0">
                                    <CardBody className="py-5">
                                        <Row>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-gradient-warning text-white rounded-circle mb-4">
                                                    <i className="fas fa-briefcase" />
                                                </div>
                                            </Col>
                                            <Col>
                                                <h4 className="h3 text-warning">
                                                        La façon la plus sûre de travailler avec des courtiers
                                                </h4>
                                                <ul className="pl-4 mb-0">
                                                    <li className="font-weight-300" style={{ fontSize: '14px' }}>
                                                            Un moteur de recherche intelligent
                                                    </li>
                                                    <li className="font-weight-300" style={{ fontSize: '14px' }}>
                                                            Des profils avec documents légaux vérifiés
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="mt-5" xs={12} lg="6">
                                <Card className="card-lift--hover shadow border-0">
                                    <CardBody className="py-5">
                                        <Row>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-gradient-warning text-white rounded-circle mb-4">
                                                    <i className="ni ni-app" />
                                                </div>
                                            </Col>
                                            <Col>
                                                <h4 className="h3 text-warning">
                                                        Une solution globale de gestion des talents
                                                </h4>
                                                <ul className="pl-4 mb-0 description">
                                                    <li className="font-weight-300" style={{ fontSize: '14px' }}>
                                                                Une vue complète sur vos apporteurs
                                                    </li>
                                                    <li className="font-weight-300" style={{ fontSize: '14px' }}>
                                                                Un modèle direct de communication et de collaboration
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Aux>
    );*/
    return (
        <Aux>
            <Helmet>
                <title>Tribu, la façon la plus sûre de travailler avec des courtiers</title>
                <meta
                    name="description"
                    content="Tribu vous donne accès à la plus grande communauté de courtiers, spécialisés
                    dans le crédit, l'assurance et le conseil financier. Créez votre profil rapidement."
                />
                <meta name="keywords" content="Acpr, Orias, banque, assureur, compagnie d’assurance, courtier grossiste, grossiste,
mutuelle, institution prevoyance, packageur credit, societe de gestion, groupement gestion
patrimoine, groupement cgp, reseau de franchise, reseau franchise credit, reseau franchise
assurance, franchiseur, franchiseur credit, franchiseur assurance, association agreee courtier,
association agreee ACPR, editeur logiciel credit, editeur logiciel assurance, organisme de formation
courtage, organisme de formation courtier, patrimonia, salon du courtage, les journees du courtage,
les rendez-vous du courtage, rdv du courtage, salon du credit"/>
            </Helmet>
            {
                isSuccess && (
                    <ReactBSAlert
                        success
                        title=""
                        style={{ display: 'block' }}
                        onConfirm={() => setIsSuccess(false)}
                        confirmBtnBsStyle="success"
                        confirmBtnText="OK"
                        btnSize=""
                    >
                        Nous avons bien reçu votre demande !
                    </ReactBSAlert>
                )
            }
            <div className="header bg-gradient-warning py-9">
                <Container>
                    <div className="header-body">
                        <Row className="align-items-center">
                            <Col className="my-auto pb-4 pb-md-auto" xs={12} md={6}>
                                <h5 className="h2 display-3 mb-0 text-white font-weight-300">Corporate vous intéresse ?</h5>
                                <h5 className="h1 display-2 my-0 text-white">
                                    Demandez
                                    <span className="d-inline"> une démo !</span>
                                </h5>
                                <hr className="bg-white w-50 text-left my-2" />
                                <p className="text-white display-4 font-weight-300">
                                    Tribu est la solution pour trouver et gérer simplement les meilleurs courtiers.
                                </p>
                            </Col>
                            <Col xs={12} md={6}>
                                <Card>
                                    <CardBody>
                                        {
                                            error && (
                                                <Alert color="danger">
                                                    {error}
                                                </Alert>
                                            )
                                        }
                                        {
                                            !isPending ? (
                                                <Aux>
                                                    <GenericForm ref={formRef} fields={formMap(CorporateFields, corporate)} onSubmit={handleSubmit} />
                                                    <Row className="d-block">
                                                        <Col>
                                                            <small>
                                                                Tribu peut utiliser ces informations pour une communication personnalisée relative à des évènements à venir, des webinars et la plateforme Tribu. Vous pouvez vous désinscrire à tout moment.
                                                                <br />
                                                                En savoir plus sur
                                                                <Link to={PDD} className="text-warning"> notre politique de protection des données.</Link>
                                                            </small>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-3 justify-content-end">
                                                        <Col className="col-auto">
                                                            <Button color="warning" onClick={() => formRef.current.handleSubmit()}>ENVOYER</Button>
                                                        </Col>
                                                    </Row>
                                                </Aux>
                                            ) : (
                                                <Row className="py-8 justify-content-center">
                                                    <ClipLoader size={100} color="#fb6340" />
                                                </Row>
                                            )
                                        }

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <div className="separator separator-bottom separator-skew">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="fill-white"
                            points="2560 0 2560 100 0 100"
                        />
                    </svg>
                </div>
            </div>
            <Container className="pb-4">
                <Row className="justify-content-center">
                    <Col lg="12">
                        <Row>
                            <Col className="mt-4 mt-lg-5" xs={12} lg="6">
                                <Card className="card-lift--hover shadow border-0">
                                    <CardBody className="py-5">
                                        <Row>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-gradient-warning text-white rounded-circle mb-4">
                                                    <i className="ni ni-single-02" />
                                                </div>
                                            </Col>
                                            <Col>
                                                <h4 className="h3 text-warning">
                                                    Des indépendants au service du courtage
                                                </h4>
                                                <p className="description" style={{ fontSize: '14px' }}>
                                                    Crédit immobilier, Rachat de crédit, Crédit pro, Assurances, Gestion de Patrimoine, Conseil en investissements financiers, Crowdfunding, etc.
                                                </p>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="mt-5" xs={12} lg="6">
                                <Card className="card-lift--hover shadow border-0">
                                    <CardBody className="py-5">
                                        <Row>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-gradient-warning text-white rounded-circle mb-4">
                                                    <i className="ni ni-atom" />
                                                </div>
                                            </Col>
                                            <Col>
                                                <h4 className="h3 text-warning">
                                                    De plus en plus de courtiers référencés
                                                </h4>
                                                <p className="description" style={{ fontSize: '14px' }}>
                                                    Quels que soient vos objectifs, il y a forcément des courtiers pour vous aider à les réaliser. Tous les métiers du courtage sont représentés sur Tribu.
                                                </p>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="mt-5" xs={12} lg="6">
                                <Card className="card-lift--hover shadow border-0">
                                    <CardBody className="py-5">
                                        <Row>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-gradient-warning text-white rounded-circle mb-4">
                                                    <i className="fas fa-briefcase" />
                                                </div>
                                            </Col>
                                            <Col>
                                                <h4 className="h3 text-warning">
                                                    La façon la plus sûre de travailler avec des courtiers
                                                </h4>
                                                <ul className="pl-4 mb-0">
                                                    <li className="font-weight-300" style={{ fontSize: '14px' }}>
                                                        Un moteur de recherche intelligent
                                                    </li>
                                                    <li className="font-weight-300" style={{ fontSize: '14px' }}>
                                                        Des profils avec documents légaux vérifiés
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col className="mt-5" xs={12} lg="6">
                                <Card className="card-lift--hover shadow border-0">
                                    <CardBody className="py-5">
                                        <Row>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-gradient-warning text-white rounded-circle mb-4">
                                                    <i className="ni ni-app" />
                                                </div>
                                            </Col>
                                            <Col>
                                                <h4 className="h3 text-warning">
                                                    Une solution globale de gestion des talents
                                                </h4>
                                                <ul className="pl-4 mb-0 description">
                                                    <li className="font-weight-300" style={{ fontSize: '14px' }}>
                                                        Une vue complète sur vos apporteurs
                                                    </li>
                                                    <li className="font-weight-300" style={{ fontSize: '14px' }}>
                                                        Un modèle direct de communication et de collaboration
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Aux>
    );
};

export default Corporate;
