import PropTypes from "prop-types";
import React, { useState } from "react";
import { Card, Row } from "reactstrap";
import CardHeader from "reactstrap/es/CardHeader";
import CardFooter from "reactstrap/es/CardFooter";
import CardBody from "reactstrap/es/CardBody";
import { connect } from "react-redux";
import ChatInput from "./ChatInput";
import { dbCreateChat } from "../../store/chat/actions";
import ChatComposeContact2 from "./ChatComposeContact2";
import { Link } from "react-router-dom";

const ChatCompose = (props) => {
  const [selectedContacts, setSelectedContacts] = useState([]);

  const { history, createChat } = props;

  const handleSubmit = (message) => {
    createChat(selectedContacts, message, history);
  };

  return (
    <Card className="shadow h-100">
      <CardHeader>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Link to="/chat" style={{ color: "inherit" }}>
            <i
              class="fas fa-arrow-left mr-3 cursor-pointer"
              style={{ fontSize: 20 }}
            ></i>
          </Link>

          <h5 className="h2 mb-0 font-weight-normal">Composer un message</h5>
        </div>
      </CardHeader>
      <CardHeader className="">
        <ChatComposeContact2
          selectedContacts={selectedContacts}
          setSelectedContacts={setSelectedContacts}
        />
      </CardHeader>
      <CardBody></CardBody>
      <CardFooter>
        <ChatInput onSubmit={handleSubmit} />
      </CardFooter>
    </Card>
  );
};

export default connect(null, {
  createChat: dbCreateChat,
})(ChatCompose);

ChatCompose.propTypes = {
  createChat: PropTypes.func,
};

ChatCompose.defaultProps = {
  createChat: () => {},
};
