import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  Card,
  Col,
  Row,
  CardBody,
  Button,
  ModalHeader,
  Modal,
  ModalBody,
} from "reactstrap";
import classes from "../../assets/Scss/components/Publish.scss";
import PublishModal from "./PublishModal";
import { checkImage, checkFile } from "../../utils/Checkfiles";
import { connect } from "react-redux";
import { dbAddPost } from "../../store/posts/actions";
import { ClipLoader } from "react-spinners";
import Aux from "../../hoc/Aux";

class Publish2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      loading: false,
      post: {},
      sizeModal: false,
      sizeModalVideo: false,
      customModalProps: {},
      
    };
  }

  imageInputRef = React.createRef();
  videoInputRef = React.createRef();

  /**
   * Triggered on image upload
   * @param e
   */

  handleImageUpload = (e) => {
    const errors = checkFile(e.target.files[0]);
    if (errors.length > 0) {
        this.setState({ sizeModal: true });
        return;
      }
    this.setState({
      isOpen: true,
      customModalProps: { photo: { element: e } },
    });
   
  };

  handleVideoUpload = (e) => {
    const errors = checkFile(e.target.files[0]);
    if (errors.length > 0) {
      this.setState({ sizeModalVideo: true });
      return;
    }
    this.setState({
      isOpen: true,
      customModalProps: { video: { element: e } },
    });
  };

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }



  handleSubmitPost = () => {
    this.setState({
      loading: true,
    });
    const author = {
      name: `${this.capitalizeFirstLetter(
        this.props.user.firstName
      )} ${this.props.user.lastName.toUpperCase()}`,
      profilePic: this.props.user.profilePic,
      userId: this.props.user.id,
      username: this.props.user.username,
    };

    if (this.props.user.owners) {
      author.isCompany = true;
    }

    this.props.addPost(this.state.post, author).then(() => {
      this.setState({
        loading: false,
      });
      window.location.reload();
    });
  };

  render() {


    const { isOpen, loading, sizeModal, sizeModalVideo, customModalProps } =
      this.state;

      const { postMaxLength} = this.props

    if (loading)
      return (
        <Col className="col-12 text-center">
          <ClipLoader color="#1171ef" size={30} />
        </Col>
      );

    return (
      <Aux>
        <Modal
          size="lg"
          isOpen={sizeModal}
          centered
          toggle={() => this.setState({ sizeModal: !sizeModal })}
        >
          <ModalHeader
            toggle={() => this.setState({ sizeModal: !sizeModal })}
          />
          <ModalBody className="pt-0 align-items-center">
            <Row className="justify-content-center">
              <h5 className="h1 mb-0 font-weight-light">
                Le fichier est trop grand !
              </h5>
            </Row>
            <Row className="justify-content-center mb-3">
              <p>3 Mo maximum</p>
            </Row>
            <Row className="justify-content-center">
              <Button
                className="btn-secondary text-primary"
                onClick={() => this.setState({ sizeModal: !sizeModal })}
              >
                OK
              </Button>
            </Row>
          </ModalBody>
        </Modal>
        <Modal
          size="lg"
          isOpen={sizeModalVideo}
          centered
          toggle={() => this.setState({ sizeModalVideo: !sizeModalVideo })}
        >
          <ModalHeader
            toggle={() => this.setState({ sizeModalVideo: !sizeModalVideo })}
          />
          <ModalBody className="pt-0 align-items-center">
            <Row className="justify-content-center">
              <h5 className="h1 mb-0 font-weight-light">
                Le fichier est trop grand !
              </h5>
            </Row>
            <Row className="justify-content-center mb-3">
              <p>7 Mo maximum</p>
            </Row>
            <Row className="justify-content-center">
              <Button
                className="btn-secondary text-primary"
                onClick={() =>
                  this.setState({ sizeModalVideo: !sizeModalVideo })
                }
              >
                OK
              </Button>
            </Row>
          </ModalBody>
        </Modal>
        <Row className={`${classes.Publish} mb-3`}>
          <input
            key={Math.random().toString(36)}
            type="file"
            accept="image/png, image/gif, image/jpeg, image/jpg"
            ref={this.imageInputRef}
            style={{ display: "none" }}
            onChange={this.handleImageUpload}
          />
          <input
            key={Math.random().toString(36)}
            type="file"
            accept="video/mp4, video/x-m4v, video/*"
            ref={this.videoInputRef}
            style={{ display: "none" }}
            onChange={this.handleVideoUpload}
          />
          <PublishModal 
            isOpen={isOpen}
            toggle={() => this.setState({ isOpen: !isOpen })}
            setSizeModal={(bool) => this.setState({ sizeModal: bool })}
            setSizeModalVideo={(bool) =>
              this.setState({ sizeModalVideo: bool })
            }
            customProps={customModalProps}
            postMaxLength={postMaxLength}
          />
          <Col xs="12">
            <Card className="shadow mb-0">
              <CardBody className="p-0">
                <Row className="py-2 px-4 align-items-center">
                  <Col>
                    <Button
                      onClick={() => this.setState({ isOpen: true })}
                      style={{
                        "background-color": "transparent",
                        "box-shadow": "none",
                      }}
                    >
                      <h4 className="mt-2 text-sm text-gray">
                        <i className="fas fa-edit text-primary mr-3" />
                        Écrire une publication
                      </h4>
                    </Button>
                  </Col>
                  <Col className={`${classes.attachments} col-auto`}>
                    <Button
                      onClick={() => this.imageInputRef.current.click()}
                      style={{
                        "background-color": "transparent",
                        "box-shadow": "none",
                      }}
                    >
                      <i
                        className="fas fa-camera text-primary p-0"
                        style={{ fontSize: "20px" }}
                      />
                    </Button>
                    <Button
                      color="link"
                      size="sm"
                      onClick={() => this.videoInputRef.current.click()}
                    >
                      <i
                        className="fas fa-video"
                        style={{ fontSize: "20px" }}
                      />
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Aux>
    );
  }
}

const mapDispatchToProps = (state) => ({
  user: state.User.user,
});

export default connect(mapDispatchToProps, {
  addPost: dbAddPost,
})(Publish2);

Publish2.propTypes = {
  addPost: PropTypes.func,
  user: PropTypes.object,
  postMaxLength: PropTypes.number
};

Publish2.defaultProps = {
  addPost: () => {},
  user: {},
  postMaxLength: 0
};
