import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import Aux from "../../hoc/Aux";
import DefaultSearchBar5 from "../Default/DefaultSearchBar5";
import { Helmet } from "react-helmet";
import "./custom.css";
class LandingHeader extends Component {
  render() {
    return (
      <Aux>
        <Helmet>
          <title>
            Avec Tribu, accélérer vos projets grâces aux meilleurs courtiers
          </title>
          <meta
            name="description"
            content="Recherchez et travaillez avec les meilleurs courtiers. La plateforme Tribu facilite la mise en relation et la collaboration entre fournisseurs et distributeurs."
          />
          <meta
            name="keywords"
            content="acpr, orias, iobsp, ias, cif, cgp, cgpi, courtier, courtier credit, courtier assurance,
courtier rachat crédits, conseil en gestion de patrimoine, conseiller en gestion de patrimoine,
gestionnaire de patrimoine, agent d’assurance, agent general d’assurance, franchise courtier,
franchise courtier credit, franchise courtier assurance, franchise courtage, franchise courtage credit,
franchise courtage assurance, franchise gestion de patrimoine, groupement gestion de patrimoine,
logiciel credit, logiciel credit immobilier, logiciel rachat credits, logiciel credit professionnel , logiciel
assurance, logiciel courtier, logiciel courtier credit, logiciel courtier credit immobilier, logiciel courtier
rachat crédits, logiciel courtier credit professionnel, logiciel courtier assurance, logiciel courtage,
logiciel courtage credit, logiciel courtage credit immobilier, logiciel courtage rachat credits, logiciel
courtage credit professionnel, logiciel courtage assurance, formation orias, formation courtier credit,
formation courtage credit, formation courtier iobsp, formation courtage iobsp, formation iob,
formation dci, formation courtier assurance, formation courtage assurance, formation ias, formation
dda, formation cif, formation gestion de patrimoine, formation cgp, formation loi alur, reseau social
courtage, reseau social courtier"
          />
        </Helmet>
        <div className="header bg-gradient-info pb-7">
          <Container className="justify-content-center pt-9">
            <div>
              <h1 className="text-white font-weight-bold mb-0 title-text-center-mobile">
                La communauté des courtiers connectés
                {/* <br /> 
                                du courtage connecté       
                                  <br /> */}
              </h1>
            </div>
          </Container>
          <Container>
            <div className="header-body">
              <Row className="justify-content-center">
                <Col lg="8">
                  <div>
                    <div className="mt-5">
                      <DefaultSearchBar5 isHomePage={true} />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
      </Aux>
    );
  }
}

export default LandingHeader;
