import PropTypes from "prop-types";
import React, { Component } from "react";
import { Card, CardHeader, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import CardBody from "reactstrap/es/CardBody";
import { withRouter } from "react-router";
import { actions as actionsSearch } from "../../store/search/actions";
import { actions2 as actionsSearch2 } from "../../store/search/actions";
import { actions5 as actionsSearch5 } from "../../store/search/actions";
import { actions6 as actionsSearch6 } from "../../store/search/actions";
import { actions8 as actionsSearch8 } from "../../store/search/actions";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import ProfileCard2 from "../Profile/ProfileCard2";

class SearchProfiles2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  categories = ["IOBSP", "IAS", "CIF"];
  services = [
    "Crédit Immobilier",
    "Rachat de crédits",
    "Rachat de crédits consommation",
    "Rachat de crédits consommation et immobilier",
    "Crédit professionnel",
    "Prêt professionnel",
    "Crédit bail immobilier",
    "Crédit bail mobilier",
    "Location financière",
    "Location longue durée",
    "Affacturage",
    "Assurance crédit",
    "Epargne",
    "Assurance-vie",
    "Contrat de capitalisation",
    "Assurance famille",
    "Garantie des accidents de la vie",
    "Protection juridique",
    "Assurance décès",
    "Assurance scolaire",
    "Assurance emprunteur",
    "Garantie obsèques",
    "Assurance animaux",
    "Assurance dépendance",
    "Assurance rapatriement",
    "Assurance habitation",
    "Assurance locataire",
    "Propriétaire non-occupant",
    "Assurance loisirs",
    "Assurance plaisance",
    "Assurance voyage",
    "Assurance chasse",
    "Assurance sport",
    "Assurance santé",
    "Complémentaire santé",
    "Sur-complémentaire",
    "Assurance auto",
    "Assurance 2 roues",
    "Assurance voiture sans permis",
    "Assurance quad",
    "Assurance camping-car",
    "Assurance malussé",
    "Assurance du chef d'entreprise",
    "Prévoyance pro",
    "Santé pro",
    "Retraite pro",
    "Perte d’emploi",
    "Loi Madelin",
    "Responsabilité civile des mandataires sociaux",
    "Assurance homme-clé",
    "Assurance construction",
    "Dommage-ouvrage",
    "Tous risques chantier",
    "Constructeur non réalisateur",
    "Responsabilité civile décennale",
    "Garantie financière d’achèvement",
    "Responsabilité civile promoteur",
    "Multirisque professionnelle",
    "Bris de machine",
    "Dommage aux biens",
    "Locaux professionnels",
    "Responsabilité civile d’exploitation",
    "Protection juridique pro",
    "Perte d’exploitation",
    "Risques informatiques",
    "Responsabilité civile professionnelle",
    "RC Pro Agriculteur",
    "RC Pro Artisan",
    "RC Pro Association",
    "RC Pro Auto-entrepreneur",
    "RC Pro Commerçant",
    "RC Pro Profession libérale",
    "Assurance des salariés",
    "Prévoyance collective",
    "Epargne salariale",
    "Santé collective",
    "Retraite collective",
    "Assurance des véhicules",
    "Flotte automobile",
    "Transport de marchandises",
    "Transport de voyageurs",
    "Flotte motocycle",
    "Assurance du véhicule",
    "Compte-titres pro",
    "Compte à terme pro",
    "PEE",
    "PERCO",
    "Article 83",
    "Girardin industriel",
    "PEA",
    "Compte-titres",
    "Compte à terme",
    "FIP",
    "FCPI",
    "SCPI",
    "OPCI",
  ];

  departmentsObj = {
    Agen: "Lot-et-Garonne",
    Ajaccio: "Corse-du-Sud",
    Albi: "Tarn",
    Alençon: "Orne",
    Amiens: "Somme",
    Angers: "Maine-et-Loire",
    Angoulême: "Charente",
    Annecy: "Haute-Savoie",
    Arras: "Pas-de-Calais",
    Auch: "Gers",
    Aurillac: "Cantal",
    Auxerre: "Yonne",
    Avignon: "Vaucluse",
    "Bar-le-Duc": "Meuse",
    "Basse-Terre": "Guadeloupe",
    Bastia: "Haute-Corse",
    Beauvais: "Oise",
    Belfort: "Territoire de Belfort",
    Besançon: "Doubs",
    Blois: "Loir-et-Cher",
    Bobigny: "Seine-Saint-Denis",
    Bordeaux: "Gironde",
    "Bourg-en-Bresse": "Ain",
    Bourges: "Cher",
    Caen: "Calvados",
    Cahors: "Lot",
    Carcassonne: "Aude",
    Cayenne: "Guyane",
    "Châlons-en-Champagne": "Marne",
    Chambéry: "Savoie",
    "Charleville-Mézières": "Ardennes",
    Chartres: "Eure-et-Loir",
    Châteauroux: "Indre",
    Chaumont: "Haute-Marne",
    "Clermont-Ferrand": "Puy-de-Dôme",
    Colmar: "Haut-Rhin",
    Créteil: "Val-de-Marne",
    "Digne-les-Bains": "Alpes-de-Haute-Provence",
    Dijon: "Côte-d'Or",
    Epinal: "Vosges",
    Evreux: "Eure",
    Evry: "Essonne",
    Foix: "Ariège",
    "Fort-de-France": "Martinique",
    Gap: "Hautes-Alpes",
    Grenoble: "Isère",
    Guéret: "Creuse",
    "La Rochelle": "Charente-Maritime",
    "La Roche-sur-Yon": "Vendée",
    Laon: "Aisne",
    Laval: "Mayenne",
    "Le Mans": "Sarthe",
    "Le Puy-en-Velay": "Haute-Loire",
    Lille: "Nord",
    Limoges: "Haute-Vienne",
    "Lons-le-Saunier": "Jura",
    Lyon: "Rhône",
    Mâcon: "Saône-et-Loire",
    Marseille: "Bouches-du-Rhône",
    Melun: "Seine-et-Marne",
    Mende: "Lozère",
    Metz: "Moselle",
    "Mont-de-Marsan": "Landes",
    Montauban: "Tarn-et-Garonne",
    Montpellier: "Hérault",
    Moulins: "Allier",
    Nancy: "Meurthe-et-Moselle",
    Nanterre: "Hauts-de-Seine",
    Nantes: "Loire-Atlantique",
    Nevers: "Nièvre",
    Nice: "Alpes-Maritimes",
    Nîmes: "Gard",
    Niort: "Deux-Sèvres",
    Orléans: "Loiret",
    Paris: "Paris",
    Pau: "Pyrénées-Atlantiques",
    Périgueux: "Dordogne",
    Perpignan: "Pyrénées-Orientales",
    Poitiers: "Vienne",
    Pontoise: "Val-d'Oise",
    Privas: "Ardèche",
    Quimper: "Finistère",
    Rennes: "Ille-et-Vilaine",
    Rodez: "Aveyron",
    Rouen: "Seine-Maritime",
    "Saint-Brieuc": "Côtes-d'Armor",
    "Saint-Denis": "Réunion",
    "Saint-Étienne": "Loire",
    "Saint-Lô": "Manche",
    Strasbourg: "Bas-Rhin",
    Tarbes: "Hautes-Pyrénées",
    Toulon: "Var",
    Toulouse: "Haute-Garonne",
    Tours: "Indre-et-Loire",
    Troyes: "Aube",
    Tulle: "Corrèze",
    Valence: "Drôme",
    Vannes: "Morbihan",
    Versailles: "Yvelines",
    Vesoul: "Haute-Saône",
  };

  handleResize = () => {
    this.setState({ width: window.innerWidth });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  componentDidMount() {
    this.setState({ width: window.innerWidth });
    window.addEventListener("resize", this.handleResize);

    this.setState({ loading: true });
    const { search, search2, search5, search6, search8, location } = this.props;
    const params = new URLSearchParams(location.search);

    if (params.get("l") !== null && params.get("d") === null) {
      axios
        .get(
          `https://geo.api.gouv.fr/communes?nom=${params.get(
            "l"
          )}&fields=departement&boost=population&limit=5`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          const department = res.data[0]?.departement.nom;
          search(
            "users",
            params.get("q") || "",
            params.get("c")  || "",
            params.get("s") || "",
             params.get("l") || "asdsa",
            department
          );
          this.setState({ loading: false });
        });
    } else {
      if (params.get("e") !== null) {
        search2(params.get("e"));
        this.setState({ loading: false });
      } else if (this.services.includes(params.get("q"))) {
        search5(params.get("q"));
        this.setState({ loading: false });
      } else if (this.categories.includes(params.get("q"))) {
        search6(params.get("q"));
        this.setState({ loading: false });
      } else if (params.get("d") !== null) {
        search8(this.departmentsObj[params.get("d")]);
        this.setState({ loading: false });
      } else {
        search(
          "users",
          params.get("q") || "",
          params.get("c")  || "",
          params.get("s") || "",
          params.get("l") || "",
          params.get("l") || "",
          this.departmentsObj[params.get("d")] || ""
        );
        this.setState({ loading: false });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { location: prevLocation } = prevProps;
    const { search, search2, search5, search6, search8, location } = this.props;

    if (prevLocation.search !== location.search) {
      this.setState({ loading: true });
      const params = new URLSearchParams(location.search);
      

      if (params.get("l") !== null && params.get("d") === null) {
        axios
          .get(
            `https://geo.api.gouv.fr/communes?nom=${params.get(
              "l"
            )}&fields=departement&boost=population&limit=5`,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            const department = res.data[0]?.departement.nom;
            search(
              "users",
              params.get("q") || "",
              params.get("c") || params.get("l") || "",
              params.get("s") || "",
              params.get("l") || "",
              department
            );
            this.setState({ loading: false });
          });
      } else {
        if (params.get("e") !== null) {
          search2(params.get("e"));
          this.setState({ loading: false });
        } else if (this.services.includes(params.get("q"))) {
          search5(params.get("q"));
          this.setState({ loading: false });
        } else if (this.categories.includes(params.get("q"))) {
          search6(params.get("q"));
          this.setState({ loading: false });
        } else if (params.get("d") !== null) {
          search8(this.departmentsObj[params.get("d")]);
          this.setState({ loading: false });
        } else {
          search(
            "users",
            params.get("q") || "",
            params.get("c"),
            params.get("s") || "",
            params.get("l") || "",
            this.departmentsObj[params.get("d")] || ""
          );
          this.setState({ loading: false });
        }
      }
    }
  }

  render() {
    const { width } = this.state;

    const { results, location, handler, isPending } = this.props;

    const params = new URLSearchParams(location.search);

    if (this.state.loading || isPending) {
      return (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: 50,
          }}
        >
          <CircularProgress />
        </div>
      );
    }

    return (
      <Card className="shadow">
        <CardHeader>
          <Row className="align-items-center">
            <Col>
              {params.get("q") ? (
                <h5 className="h3 mb-0">
                  {`${results.length} ${
                    results.length > 1 ? "résultats" : "résultat"
                  } ${
                    results.length > 1 ? "correspondent" : "correspond"
                  } à "${params.get("q")}"`}
                </h5>
              ) : params.get("e") ? (
                <h5 className="h3 mb-0">
                  {`${results.length} ${
                    results.length > 1 ? "résultats" : "résultat"
                  } ${
                    results.length > 1 ? "correspondent" : "correspond"
                  } à "${params.get("e")}"`}
                </h5>
              ) : params.get("d") ? (
                <h5 className="h3 mb-0">
                  {`${results.length} ${
                    results.length > 1 ? "résultats" : "résultat"
                  } ${
                    results.length > 1 ? "correspondent" : "correspond"
                  } à "${params.get("d")}"`}
                </h5>
              ) : (
                <h5 className="h3 mb-0">Recherche incomplète</h5>
              )}
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            {params.get("q") || params.get("e") || params.get("d") ? (
              results.map((profile) => (
                <Col
                  key={profile.objectID}
                  xs={width > 768 ? 4 : 0}
                  className="mb-4"
                  style={{
                    width: "100%",
                    flex:
                      width > 768 ? "" : width > 500 ? "0 0 50%" : "0 0 100%",
                  }}
                >
                  <div onClick={handler}>
                    <ProfileCard2 mobileSize={width <= 768} profile={profile} />
                  </div>
                </Col>
              ))
            ) : (
              <div></div>
            )}
          </Row>
        </CardBody>
      </Card>
    );
  }

  /*render() {
        const {
            results,
            location,
            handler,
            isPending,
        } = this.props;

        const params = new URLSearchParams(location.search);

        if (this.state.loading || isPending) {
            return <CircularProgress />
        }

        return (
            <Card className="shadow">
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            {
                                params.get('q') ? (
                                    <h5 className="h3 mb-0">
                                        {`${results.length} ${results.length > 1 ? 'résultats' : 'résultat'} ${results.length > 1 ? 'correspondent' : 'correspond'} à "${params.get('q')}"`}
                                    </h5>
                                ) : params.get('e') ? (
                                    <h5 className="h3 mb-0">
                                        {`${results.length} ${results.length > 1 ? 'résultats' : 'résultat'} ${results.length > 1 ? 'correspondent' : 'correspond'} à "${params.get('e')}"`}
                                    </h5>
                                ) : params.get('d') ? (
                                    <h5 className="h3 mb-0">
                                        {`${results.length} ${results.length > 1 ? 'résultats' : 'résultat'} ${results.length > 1 ? 'correspondent' : 'correspond'} à "${params.get('d')}"`}
                                    </h5>
                                ) : (
                                    <h5 className="h3 mb-0">
                                        Recherche incomplète
                                    </h5>
                                )
                            }
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Row>
                        {
                            params.get('q') || params.get('e') || params.get('d') ? results.map((profile) => (
                                <Col key={profile.objectID} xs={4} className="mb-3">
                                    <Button style={{ height: '100%' }} className="text-center" onClick={(handler)}>
                                        <ProfileCard2 profile={profile} />
                                    </Button>
                                </Col>
                            )) : <div></div>
                        }
                    </Row>

                </CardBody>
            </Card>
        );
    }*/
}

const mapDispatchToProps = (state) => ({
  results: state.Search.results,
  isPending: state.Search.isPending,
});

export default withRouter(
  connect(mapDispatchToProps, {
    search: actionsSearch,
    search2: actionsSearch2,
    search5: actionsSearch5,
    search6: actionsSearch6,
    search8: actionsSearch8,
  })(SearchProfiles2)
);

SearchProfiles2.propTypes = {
  results: PropTypes.array,
  search: PropTypes.func,
  search2: PropTypes.func,
};

SearchProfiles2.defaultProps = {
  results: [],
  search: () => {},
  search2: () => {},
};
