import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { Helmet } from 'react-helmet';
import rootReducer from './store';
import App from './App';

import './assets/Vendor/nucleo/css/nucleo.css';
import './assets/Vendor/@fortawesome/fontawesome-free/css/all.min.css';
import './assets/Css/argon.min.css';
import './assets/Css/app.min.css';
import config from './config/config';

import './assets/Pics/Favicons/android-chrome-192x192.png';
import './assets/Pics/Favicons/android-chrome-256x256.png';
import './assets/Pics/Favicons/apple-touch-icon.png';
import './assets/Pics/Favicons/browserconfig.xml';
import './assets/Pics/Favicons/favicon.ico';
import './assets/Pics/Favicons/favicon-16x16.png';
import './assets/Pics/Favicons/favicon-32x32.png';
import './assets/Pics/Favicons/mstile-150x150.png';
import './assets/Pics/Favicons/safari-pinned-tab.svg';
import './assets/Pics/Favicons/site.webmanifest';

const store = createStore(rootReducer, compose(applyMiddleware(thunk)));

const app = (
    <BrowserRouter>
        <Helmet>
            <title>{`${config.title} - Accélérer vos projets grâce aux meilleurs courtiers`}</title>
        </Helmet>
        <Provider store={store}>
            <App />
        </Provider>
    </BrowserRouter>
);

ReactDOM.render(app, document.getElementById('root'));
