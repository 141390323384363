import React, { Component } from "react";
import { Col, Container, Row, Badge, CardBody, Card } from "reactstrap";
import Aux from "../../hoc/Aux";
import DefaultSearchBar5 from "../Default/DefaultSearchBar5";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Img from "../../assets/Pics/tribu_orias.png";
import "../../App.css";
import { withRouter } from "react-router-dom";
import "./custom.css";

const CATEGORIES = [
  {
    category: "IOBSP",
    title: "Les courtiers IOBSP | Tribu",
    description:
      "Besoin d'un courtier IOBSP ? Trouvez facilement les meilleurs profils pour votre projet : Expertise, Professionnalisme et Disponibilité.",
    keywords:
      "Courtier, Courtier en credit, Courtier IOBSP, IOBSP, Courtier ORIAS, ORIAS, ACPR, AMF",
    content: (
      <>
        <p className="intermediary-text">
          <p className="intermediary-title">
            Tout savoir sur le métier d’intermédiaire en opérations de banque et
            service de paiement (IOBSP)
          </p>
          Deux critères conditionnent la qualification d’IOBSP :
          <ul>
            <li>
              L’exercice de la profession à titre habituel, soit à titre
              principal soit à titre accessoire d’une autre activité
              professionnelle principale (agent général d’assurance ou agent
              immobilier par exemple).
            </li>
            <li>
              L’exercice de la profession à titre habituel, soit à titre
              principal soit à titre accessoire d’une autre activité
              professionnelle principale (agent général d’assurance ou agent
              immobilier par exemple).
            </li>
          </ul>
          <p className="intermediary-subtitle">
            Les différents statuts des IOBSP
          </p>
          L’article 519-4 du Code monétaire et financier, qui trouve son origine
          dans le décret du 26 janvier 2012 répartit les IOBSP en quatre grandes
          catégories :
          <br />
          <br />
          Dès lors, dans le cadre de son immatriculation (obligatoire) auprès de
          l’Orias (registre unique des intermédiaires en assurance, banque et
          finance), chaque nouvel IOBSP doit être ainsi inscrit dans l’une des
          catégories suivantes :
          <p className="intermediary-subtitle2">Les courtiers (COBSP)</p>
          Le courtier est un intermédiaire qui, sur mandat de son client,
          recherche pour lui une solution de financement.
          <br />
          <br />
          Il doit être libre de tout engagement vis à vis d’établissement de
          crédit, afin de satisfaire au mandat que lui a donné son client.
          <p className="intermediary-subtitle2">
            Les mandataires exclusifs (MOBSPL)
          </p>
          Le mandataire exclusif est mandaté par un établissement de crédit pour
          commercialiser les produits de cet établissement.
          <br />
          <br />
          Face à un client qui recherche une solution de financement, il a
          l’obligation de ne proposer en exclusivité que les solutions de cet
          établissement.
          <p className="intermediary-subtitle2">
            Les mandataires d’intermédiaires (MIOBSP)
          </p>
          Le mandataire d’intermédiaire, exerce son activité en vertu d’un ou
          plusieurs mandats reçus d’un professionnel appartenant à l’une des
          trois autres catégories.
          <br />
          <br />
          Il a donc dans la recherche des solutions pour le client la liberté
          plus ou moins grande dont dispose son propre mandant.
          <br />
          <br />
          <p className="intermediary-subtitle">
            Comment s'assurer du sérieux d'un intermédiaire en opérations de
            banque et en services de paiement ?
          </p>
          Chaque intermédiaire en opérations de banque et en services de
          paiement (IOBSP) doit être immatriculé à l’ORIAS, le registre unique
          des intermédiaires en assurance, banque et finance.
          <br />
          <br />
          Trouver sur <b>Tribu</b> tous les intermédiaires en opérations de
          banque et en services de paiement respectant la réglementation.
        </p>
      </>
    ),
  },
  {
    category: "IAS",
    title: "Les courtiers IAS | Tribu",
    description:
      "Besoin d'un intermédiaire en assurance ? Trouvez facilement les meilleurs profils pour votre projet : Expertise, Professionnalisme et Disponibilité.",
    keywords:
      "Courtier, Courtier en assurance, Courtier IAS, IAS, Courtier ORIAS, ORIAS, ACPR, AMF",
    content: (
      <>
        <p className="intermediary-text">
          <p className="intermediary-title">
            Tout savoir sur le métier d’intermédiaire en assurance (IAS)
          </p>
          De nombreux <b>intermédiaires en assurances</b> ont la possibilité de
          commercialiser des contrats d’assurance. Ces professionnels, qu’ils
          soient mandataires, courtiers ou agents généraux, doivent toutefois
          respecter des <b>obligations de conseil et d’information</b> pour
          chacun des clients.
          <p className="intermediary-subtitle">
            Les différents statuts des intermédiaires en assurances
          </p>
          L’intermédiaire d’assurances se décline en 4 métiers bien distincts,
          chacun soumis aux obligations d’information et de conseil. Par
          ailleurs, tous les <b>intermédiaires en assurances</b> doivent
          impérativement être immatriculés et inscrits sur registre pour pouvoir
          exercer.
          <p className="intermediary-subtitle2">L’agent général d’assurances</p>
          L’<b>agent général d’assurances</b> exerce en tant que libéral, et
          doit signer un mandat exclusif avec une ou plusieurs sociétés
          d’assurance. Il est alors chargé, en tant que personne morale ou
          physique, de représenter l’entreprise d’assurances dont il engage la
          responsabilité.
          <br />
          <br />
          L’agent général d’assurances peut parfois être amené à commercialiser
          des <b>contrats d’assurances</b> au nom d’autres entreprises que celle
          pour lequel il est mandaté. Il prend ainsi la décision d’engager sa
          propre responsabilité, et d’intervenir en dehors de son mandat
          d’agent.
          <p className="intermediary-subtitle2">
            Le courtier d’assurances ou de réassurances
          </p>
          Le <b>courtier en assurances</b> est une personne physique ou morale
          qui exerce en tant que commerçant. Aucun mandat exclusif ne le lie à
          une entreprise, mais en cas de faute, cela engage sa propre
          responsabilité professionnelle. En ce sens, un{" "}
          <b>courtier en assurances</b> a l’obligation de souscrire à une
          assurance de responsabilité civile professionnelle, mais également de
          justifier d’une garantie financière, pour pouvoir exercer son métier,
          et vendre des contrats d’assurances.
          <p className="intermediary-subtitle2">Le mandataire d’assurances</p>
          Le <b>mandataire en assurance</b> peut être une personne morale, ou
          une personne physique non salariée. Tout comme l’agent général
          d’assurance, une ou plusieurs entreprises d’assurances mandate(ent) le
          mandataire d’assurance. Mais l’exclusivité n’est pas une clause
          obligatoire.
          <p className="intermediary-subtitle2">
            Le mandataire d’intermédiaire d’assurance
          </p>
          Par définition, le <b>mandataire d’intermédiaire d’assurances</b> est
          un professionnel qu’un agent général, un courtier, ou un mandataire
          d’assurance viennent mandater. En faisant appel à ce professionnel,
          l’intermédiaire s’engage à prendre la responsabilité des éventuelles
          fautes que celui-ci pourrait commettre lors de son mandat.
          <br />
          <br />
          Les missions du mandataire d’intermédiaire et du mandataire
          d’assurances sont limitées, et ne concernent pas la gestion des{" "}
          <b>contrats d’assurance</b> et le règlement des sinistres.
          <br />
          <br />
          <p className="intermediary-subtitle">
            Comment s'assurer du sérieux d'un intermédiaire en assurance ?
          </p>
          Chaque intermédiaire en assurance (IAS) doit être immatriculé à
          l’ORIAS, le registre unique des intermédiaires en assurance, banque et
          finance.
          <br />
          <br />
          Trouver sur <b>Tribu</b> tous les intermédiaires en assurance
          respectant la réglementation.
        </p>
      </>
    ),
  },
  {
    category: "CIF",
    title: "Les courtiers CIF | Tribu",
    description:
      "Besoin d'un conseiller en investissements financiers ? Trouvez facilement les meilleurs profils pour votre projet : Expertise, Professionnalisme et Disponibilité.",
    keywords: "Courtier, Courtier CIF, CIF, Courtier ORIAS, ORIAS, ACPR, AMF",
    content: (
      <>
        <p className="intermediary-text">
          <p className="intermediary-title">
            Tout savoir sur le conseiller en investissements financiers (CIF)
          </p>
          Les conseillers en investissements financiers (CIF) sont des personnes
          qui exercent à titre habituel une activité de conseil portant sur :
          <br />
          <br />
          - la fourniture de services d&#39;investissement
          (réception-transmission et exécution d&#39;ordres pour le compte de
          tiers, négociation pour compte propre, gestion de portefeuille pour le
          compte de tiers, conseil en investissement, placement, etc.),
          <br />
          <br />
          - la réalisation d'opérations sur biens divers (souscription de rente
          viagère, acquisition de droits sur biens mobiliers ou immobiliers,
          etc.).
          <br />
          <br />
          <b>Précisions :</b>
          <br />- les CIF peuvent également fournir le service de réception et
          de transmission d&#39;ordres pour le compte de clients auxquels ils
          ont fourni une prestation de conseil, dans les conditions et limites
          fixées par le règlement général de l&#39;Autorité des marchés
          financiers et exercer d&#39;autres activités de conseil en gestion de
          patrimoine,
          <br />- ils ne peuvent, à titre habituel et rémunéré, donner des
          consultations juridiques ou rédiger des actes sous seing privé pour
          autrui que dans les conditions et limites des articles 54, 55 et 60 de
          la loi n° 71-1130 du 31 décembre 1971 portant réforme de certaines
          professions judiciaires et juridiques.
          <br />
          <br />
          <p className="intermediary-subtitle">
            Comment s'assurer du sérieux d'un conseiller en investissements
            financiers ?
          </p>
          Chaque conseiller en investissements financiers (CIF) doit être
          immatriculé à l’ORIAS, le registre unique des intermédiaires en
          assurance, banque et finance.
          <br />
          <br />
          Trouver sur <b>Tribu</b> tous les conseillers en investissements
          financiers respectant la réglementation.
        </p>
      </>
    ),
  },
  {
    category: "ALPSI",
    title: "Les courtiers ALPSI | Tribu",
    description:
      "Besoin d'un agent lié de PSI ? Trouvez facilement les meilleurs profils pour votre projet : Expertise, Professionnalisme et Disponibilité.",
    keywords:
      "Courtier, Courtier ALPSI, ALPSI, Courtier ORIAS, ORIAS, ACPR, AMF",
    content: (
      <>
        <p className="intermediary-text">
          <p className="intermediary-title">
            Tout savoir sur l’agent lié de prestataires de services
            d'investissement (ALPSI)
          </p>
          Un Prestataire de Services d&#39;Investissement (PSI) est une
          entreprise d&#39;investissement agréée par l&#39;Autorité de Contrôle
          Prudentiel et de Résolution (ACPR) dont le statut est défini par
          l&#39;article L. 531-1 du Code monétaire et financier.
          <br />
          <br />
          Conformément à l&#39;article L. 545-1 du Code monétaire et financier,
          &quot;un prestataire de services d&#39;investissement peut recourir
          aux services d&#39;agents liés&quot;. Les agents liés sont définis
          comme toute personne physique ou morale qui, sous la responsabilité
          entière et inconditionnelle d&#39;une seule et unique entreprise
          d&#39;investissement pour le compte de laquelle elle agit, fait la
          promotion auprès de clients ou de clients potentiels de services
          d&#39;investissement et/ou de services auxiliaires, reçoit et transmet
          les instructions ou les ordres de clients concernant des instruments
          financiers ou des services d&#39;investissements, place des
          instruments financiers et/ou fournit à des clients et/ou à des clients
          potentiels des conseils sur ces instruments ou services.
          <br />
          <br />
          <p className="intermediary-subtitle">
            Comment s&#39;assurer du sérieux d&#39;un agent lié de prestataires
            de services d&#39;investissement ?
          </p>
          Chaque agent lié de prestataires de services d&#39;investissement doit
          être immatriculé à l’ORIAS, le registre unique des intermédiaires en
          assurance, banque et finance. Une liste des agents liés de
          prestataires de services d&#39;investissements figure sur le site de
          l&#39;Autorité de contrôle Prudentiel et de Résolution.
          <br />
          <br />
          Trouver sur <b>Tribu</b> tous les agents liés de prestataires de
          services d'investissement respectant la réglementation.
        </p>
      </>
    ),
  },
  {
    category: "CIP",
    title: "Les courtiers CIP | Tribu",
    description:
      "Besoin d'un conseiller en investissements participatifs ? Trouvez facilement les meilleurs profils pour votre projet : Expertise, Professionnalisme et Disponibilité.",
    keywords: "Courtier, Courtier CIP, CIP, Courtier ORIAS, ORIAS, ACPR, AMF",
    content: (
      <>
        <p className="intermediary-text">
          <p className="intermediary-title">
            Tout savoir sur le métier de conseiller en investissements
            participatifs (CIP)
          </p>
          Le Conseiller en Investissements Participatifs (CIP) est un statut qui
          s’applique aux plateformes de crowdfunding pratiquant le financement
          participatif sous forme de titres financiers (commercialisation, via
          un site internet, d’actions et d’obligations émises par des sociétés
          non cotées). Cette activité est exercée sous le contrôle de l’Autorité
          des marchés financiers (AMF).
          <p className="intermediary-subtitle">
            Le statut de Conseiller en Investissement Participatif
          </p>
          Le Conseiller en Investissement Participatif est régulé par l’Autorité
          des Marchés Financiers, qui a vérifié notamment ses capacités
          professionnelles, ainsi que celles de sa plateforme de financement
          participatif, à respecter des règles de bonne conduite qu’elle a
          édictées dont certaines relèvent du Code Monétaire et Financier. Parmi
          elles, on peut retenir l’obligation de faire mention des risques liés
          à tout investissement, l’importance de fournir une information
          équilibrée sur chaque projet, la nécessité de vérifier l’adéquation
          entre l’investissement visé et le profil de l’investisseur…
          <br />
          <br />
          Le Conseiller en Investissement Participatif a un rôle de conseil, il
          doit ainsi respecter des règles de bonne conduite relatives à la
          gestion des conflits d’intérêts, à la réalisation d’un test
          d’adéquation de l’offre au profil de l’investisseur, à la transparence
          sur les prestations fournies aux émetteurs et les frais perçus dans la
          délivrance des conseils aux clients.
          <br />
          <br />
          Les plateformes CIP doivent être immatriculées auprès de l’ORIAS,
          adhérer à une association agréée par l’AMF et souscrire à une
          assurance responsabilité civile professionnelle. Quant aux gérants,
          ils doivent également remplir des conditions d’âge, d’honorabilité et
          de compétences (diplômes, expériences, formation…).
          <br />
          <br />
          <p className="intermediary-subtitle">
            Comment s'assurer du sérieux d'un conseiller en investissement
            participatif ?
          </p>
          Chaque conseiller en investissement participatif (CIP) doit être
          immatriculé à l’ORIAS, le registre unique des intermédiaires en
          assurance, banque et finance.
          <br />
          <br />
          Trouver sur <b>Tribu</b> tous les conseillers en investissements
          participatifs respectant la réglementation.
        </p>
      </>
    ),
  },
  {
    category: "IFP",
    title: "Les courtiers IFP | Tribu",
    description:
      "Besoin d'un intermédiaire en financement participatif ? Trouvez facilement les meilleurs profils pour votre projet : Expertise, Professionnalisme et Disponibilité.",
    keywords: "Courtier, Courtier IFP, IFP, Courtier ORIAS, ORIAS, ACPR, AMF",
    content: (
      <>
        <p className="intermediary-text">
          <p className="intermediary-title">
            Tout savoir sur le métier d’intermédiaire en financement
            participatif (IFP)
          </p>
          Un intermédiaire en financement participatif est une plateforme qui
          relie porteurs de projets et investisseurs. Les investissements
          proposés peuvent prendre la forme de prêts, dons ou royalties.
          <br />
          <br />
          L’intermédiaire est garant de la transparence des informations
          communiquées aux investisseurs et de la gestion des flux financiers.
          Il est obligatoirement inscrit au registre de l’Orias et contrôlé par
          la Banque de France via l’ACPR (Autorité de Contrôle Prudentiel et de
          Résolution) afin de protéger les investisseurs.
          <br />
          <br />
          Pour obtenir l’agrément, l’intermédiaire doit prouver sa compétence et
          souscrire au plus haut niveau d’assurance responsabilité civile.
          L’agrément est à renouveler chaque année et permet l’utilisation du
          label « Plate-forme de financement participatif régulée par les
          autorités françaises ».
          <br />
          <br />
          <p className="intermediary-subtitle">
            Comment s&#39;assurer du sérieux d&#39;un intermédiaire en
            financement participatif ?
          </p>
          Chaque intermédiaire en financement participatif (IFP) doit être
          immatriculé à l’ORIAS, le registre unique des intermédiaires en
          assurance, banque et finance.
          <br />
          <br />
          Trouver sur <b>Tribu</b> tous les intermédiaires en financement
          participatif respectant la réglementation.
        </p>
      </>
    ),
  },
];

class LandingHeader4 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentCategoryItem: undefined,
    };
  }

  componentDidMount() {
    this.handleSetCurrentCategoryItem();
  }

  componentDidUpdate() {
    const urlCategory = this.props.match.params.category;
    if (urlCategory !== this.state.currentCategoryItem?.category)
      this.handleSetCurrentCategoryItem();
  }

  handleSetCurrentCategoryItem() {
    const urlCategory = this.props.match.params.category;
    const currentCategory = CATEGORIES.find(
      (el) => el.category === urlCategory
    );
    if (currentCategory)
      this.setState({ currentCategoryItem: currentCategory });
    // else if (urlCategory !== undefined) this.props.history.push("/404");
  }

  render() {
    const { currentCategoryItem } = this.state;
    return (
      <Aux>
        {currentCategoryItem ? (
          <Helmet>
            <title>{currentCategoryItem.title}</title>
            <meta
              name="description"
              content={currentCategoryItem.description}
            />
            <meta name="keywords" content={currentCategoryItem.keywords} />
          </Helmet>
        ) : (
          <Helmet>
            <title>
              Découvrez les différentes catégories Orias de nos courtiers Tribu
            </title>
            <meta
              name="description"
              content="Toutes les catégories Orias du registre unique des intermédiaires en assurance,
                        banque et finance sont représentées sur Tribu : IOBSP, IAS, CIF.."
            />
            <meta
              name="keywords"
              content="Courtier Orias, Orias, Courtier IOBSP, IOBSP, Intermediaire en operations de banque
et en services de paiements, Courtier IAS, IAS, Intermediaire en assurances, Courtier CIF, CIF,
Conseiller en investissements financiers, Courtier ALPSI, ALPSI, Agent lie de prestataires de services
d'investissement, Courtier CIP, CIP, Conseiller en investissements participatifs , Courtier IFP, IFP,
Intermediaire en financement participatif, Conseil en gestion de patrimoine independant, Conseiller
en gestion de patrimoine, Gestionnaire de patrimoine, Cgpi, Cgp"
            />
          </Helmet>
        )}

        <div className="header bg-gradient-info pb-7 ">
          <Container>
            <div className="header-body">
              <Row className="justify-content-center">
                <img
                  style={{ transform: "translate(0, 220px)", marginBottom: 50 }}
                  className="mb-3 bg-title-image"
                  alt="trouver un courtier"
                  src={Img}
                />
              </Row>
              <Row className="justify-content-center align-items-center h-100 m-0">
                <Col className="text-white text-center">
                  <h3
                    className="display-2 text-white mt-5 font-weight-light"
                    style={{ lineHeight: "1.3", paddingTop: 100 }}
                  >
                    {currentCategoryItem ? "Courtiers" : "Courtiers par"}
                    <br />
                    <span className="font-weight-bold">
                      {currentCategoryItem
                        ? currentCategoryItem.category
                        : "catégories"}
                    </span>
                  </h3>
                  <h3
                    className="display-4 text-white mt-5 font-weight-light"
                    style={{ lineHeight: "1.3" }}
                  >
                    {currentCategoryItem
                      ? `Travaillez en toute sérénité avec les meilleurs courtiers ${currentCategoryItem.category}`
                      : "Travaillez en toute sérénité avec les meilleurs courtiers partout en France"}
                  </h3>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg="8">
                  <div>
                    <div className="mt-5">
                      <DefaultSearchBar5
                        searchLeft={currentCategoryItem?.category}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className="text-white text-center">
                  <h3
                    className="display-4 text-white mt-5 font-weight-light"
                    style={{ lineHeight: "1.3" }}
                  >
                    Les professionnels du courtage sont sur Tribu
                  </h3>
                </Col>
              </Row>
              {currentCategoryItem ? (
                currentCategoryItem.content && (
                  <Row className="mt-5 d-flex justify-content-center">
                    <Col lg={8}>
                      <Card style={{ backgroundColor: "var(--lighter)" }}>
                        <CardBody>{currentCategoryItem.content}</CardBody>
                      </Card>
                    </Col>
                  </Row>
                )
              ) : (
                <>
                  <Row className="mt-5">
                    <Col className="text-center">
                      <Link to="/search/categories/IOBSP">
                        <Badge style={{ fontSize: 20 }} color="primary">
                          IOBSP
                        </Badge>
                      </Link>
                    </Col>
                    <Col className="text-center">
                      <Link to="/search/categories/IAS">
                        <Badge style={{ fontSize: 20 }} color="primary">
                          IAS
                        </Badge>
                      </Link>
                    </Col>
                    <Col className="text-center">
                      <Link to="/search/categories/CIF">
                        <Badge style={{ fontSize: 20 }} color="primary">
                          CIF
                        </Badge>
                      </Link>
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <Col className="text-center">
                      <Link to="/search/categories/ALPSI">
                        <Badge style={{ fontSize: 20 }} color="primary">
                          ALPSI
                        </Badge>
                      </Link>
                    </Col>
                    <Col className="text-center">
                      <Link to="/search/categories/CIP">
                        <Badge style={{ fontSize: 20 }} color="primary">
                          CIP
                        </Badge>
                      </Link>
                    </Col>
                    <Col className="text-center">
                      <Link to="/search/categories/IFP">
                        <Badge style={{ fontSize: 20 }} color="primary">
                          IFP
                        </Badge>
                      </Link>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </Container>
        </div>
      </Aux>
    );
  }
}

export default withRouter(LandingHeader4);
