import FirstStep from "./FirstStep";
import AccountActivation from "./AccountActivation";
import SignInProblem from "./SignInProblem";
import AccountSettings from "./AccountSettings";
import TribuPremium from "./TribuPremium";
import EditProfile from "./EditProfile";
import EditCompany from "./EditCompany";
import UserData from "./UserData";
import Dashboard from "./Dashboard";
import AddMember from "./AddMember";
import RemoveContact from "./RemoveContact";
import WriteToContact from "./WriteToContact";
import Followers from "./Followers";
import Posts from "./Posts";

export default [
  {
    component: FirstStep,
    name: "Premiers pas sur Tribu",
    url: "/premiers-pas-sur-tribu",
  },
  {
    component: AccountActivation,
    name: "Activation du compte",
    url: "/activation-du-compte",
  },
  {
    component: SignInProblem,
    name: "Problème de connexion",
    url: "/probleme-de-connexion",
  },
  {
    component: AccountSettings,
    name: "Paramètres du compte",
    url: "/parametres-du-compte",
  },
  {
    component: TribuPremium,
    name: "Tribu Premium",
    url: "/tribu-premium",
  },
  {
    component: EditProfile,
    name: "Modifier son profil",
    url: "/modifier-son-profil",
  },
  {
    component: EditCompany,
    name: "Modifier sa page entreprise",
    url: "/modifier-sa-page-entreprise",
  },
  {
    component: UserData,
    name: "Données personnelles",
    url: "/donnees-personnelles",
  },
  {
    component: Dashboard,
    name: "Tableau de bord",
    url: "/tableau-de-bord",
  },
  {
    component: AddMember,
    name: "Ajouter un membre à mes contacts",
    url: "/ajouter-un-membre",
  },
  {
    component: RemoveContact,
    name: "Supprimer un contact",
    url: "/supprimer-un-contact",
  },
  {
    component: WriteToContact,
    name: "Êcrire à un contact",
    url: "/ecrire-a-un-contact",
  },
  {
    component: Followers,
    name: "Abonnés et Abonnements",
    url: "/abonnes-et-abonnements",
  },
  {
    component: Posts,
    name: "Publications",
    url: "/publications",
  },
];
