// React Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

// components
import { NavLink, NavItem } from 'reactstrap';
import { NavLink as NavLinkRRD } from 'react-router-dom';
import classes from '../../../assets/Scss/components/Dropdown.scss';

import sideBarClasses from '../../../assets/Scss/components/Sidebar.scss';

class Dropdown extends Component {
    render() {
        const {
            icon,
            title,
            items,
            setOpened,
            sectionPath,
        } = this.props;

        const active = (routename) => {
            const { location } = this.props;
            return location.pathname.indexOf(routename) > -1 ? 'active' : '';
        };

        return (
            <NavItem className={`${classes.Dropdown} ${sideBarClasses.Sidebar} mb-md--2 text-center`}>
                <NavLink style={{ cursor: 'pointer' }} className={active(sectionPath)}>
                    {
                        icon !== null && (
                            <i className={`${icon} ${sideBarClasses.icon} d-block`} />
                        )
                    }
                    <span className={`${sideBarClasses.itemName} d-block`} style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                        {title}
                        <i className={`${classes.dropdownIcon} ${sideBarClasses.itemName} fas fa-sort-up text-primary`} />
                    </span>
                </NavLink>
                <div className={classes.dropdownContent}>
                    {
                        items.map((prop, key) => {
                            const keyIndex = `dropdown_${key}`;
                            return (
                                <NavLink
                                    key={keyIndex}
                                    to={prop.path}
                                    tag={NavLinkRRD}
                                    onClick={setOpened}
                                    activeClassName="active"
                                >
                                    {prop.title}
                                </NavLink>
                            );
                        })
                    }
                </div>
            </NavItem>
        );
    }
}

Dropdown.propTypes = {
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    sectionPath: PropTypes.string.isRequired,
    setOpened: PropTypes.func.isRequired,
    items: PropTypes.array,
};

Dropdown.defaultProps = {
    items: [],
};

export default withRouter(Dropdown);
