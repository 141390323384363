import firebase from '@firebase/app';
import { db, firebaseApp } from '../../config/firebase';

import {
    SET_CONTACT_RELATIONSHIP, SET_CONTACTS, SET_CONTACTS_REQUESTS, SET_CONTACTS_FETCHING,
} from './types';

import {
    CONTACT, RECEIVED, REQUEST, SENT,
} from '../../data/ContactConsts';

const setContacts = (contacts) => ({
    type: SET_CONTACTS,
    contacts,
});

const setRequests = (requests) => ({
    type: SET_CONTACTS_REQUESTS,
    requests,
});

const setRelationship = (relationship) => ({
    type: SET_CONTACT_RELATIONSHIP,
    relationship,
});

const setIsFetching = (isFetching) => ({
    type: SET_CONTACTS_FETCHING,
    isFetching,
});

/**
 * Call API request and dispatch DATA or errors.
 * @param relationships
 * @returns {Function}
 */
export const dbFetchContacts = (relationships = [CONTACT]) => (dispatch) => {
    dispatch(setIsFetching(true));
    const userId = firebaseApp.auth().currentUser.uid;

    let contactsRef = db.collection('users').doc(userId).collection('contacts');

    relationships.forEach((relationship) => {
        contactsRef = contactsRef.where('status', '==', relationship);
    });

    contactsRef
        .onSnapshot(async (snapshots) => {
            const contactsPromises = [];

            snapshots.forEach((doc) => {
                contactsPromises.push(
                    db.collection('users')
                        .doc(doc.data().userId)
                        .get()
                        .then((contact) => {
                            if (!contact.data()) return null;

                            return ({
                                userId: contact.id,
                                ...contact.data(),
                            });
                        }),
                );
            });

            const contacts = await Promise.all(contactsPromises);

            dispatch(setContacts(await contacts.filter((contact) => contact !== null)));
            dispatch(setIsFetching(false));
        });
};

export const dbFetchUserContacts = (userId, relationships = [CONTACT]) => (dispatch) => {
    dispatch(setIsFetching(true));

    let contactsRef = db.collection('users').doc(userId).collection('contacts');

    relationships.forEach((relationship) => {
        contactsRef = contactsRef.where('status', '==', relationship);
    });

    contactsRef
        .onSnapshot(async (snapshots) => {
            const contactsPromises = [];

            snapshots.forEach((doc) => {
                contactsPromises.push(
                    db.collection('users')
                        .doc(doc.data().userId)
                        .get()
                        .then((contact) => {
                            if (!contact.data()) return null;

                            return ({
                                userId: contact.id,
                                ...contact.data(),
                            });
                        }),
                );
            });

            const contacts = await Promise.all(contactsPromises);

            dispatch(setContacts(await contacts.filter((contact) => contact !== null)));
            dispatch(setIsFetching(false));
        });
};

export const dbFetchRequests = () => (dispatch) => {
    const userId = firebaseApp.auth().currentUser.uid;

    db.collection('users')
        .doc(userId)
        .collection('contacts')
        .where('status', '==', REQUEST)
        .where('nature', '==', RECEIVED)
        .onSnapshot(async (snapshots) => {
            const contacts = snapshots.docs
                .map(async (doc) => db.collection('users')
                    .doc(doc.data().userId)
                    .get()
                    .then((contact) => {
                        const {
                            firstName, lastName, company, profilePic, username,
                        } = contact.data();

                        return {
                            author: {
                                id: contact.id,
                                name: `${firstName} ${lastName}`,
                                profilePic,
                                company: company || '',
                                username,
                            },
                            createdAt: new Date(),
                        };
                    }));

            dispatch(setRequests(await Promise.all(contacts)));
        });
};

export const dbFetchRelationship = (userId) => (dispatch) => {
    const { uid } = firebaseApp.auth().currentUser;

    db.collection('users')
        .doc(uid)
        .collection('contacts')
        .where('userId', '==', userId)
        .onSnapshot((snapshot) => {
            const snapshotArray = [];
            snapshot.forEach((data) => snapshotArray.push(data.data()));

            const relationship = snapshotArray[0] ? snapshotArray[0] : {};

            dispatch(setRelationship(relationship));
        });
};

/**
 * Add a contact.
 * @param userId
 * @returns {Function}
 */
// eslint-disable-next-line no-unused-vars
export const dbAddContact = (userId) => (dispatch) => {
    const { uid } = firebaseApp.auth().currentUser;

    const data = {
        userId,
        status: SENT,
        nature: REQUEST,
    };

    const batch = db.batch();

    const senderRef = db.collection('users').doc(uid).collection('contacts').doc(userId);
    batch.set(senderRef, data);

    data.nature = RECEIVED;
    data.userId = uid;

    const receiverRef = db.collection('users').doc(userId).collection('contacts').doc(uid);
    batch.set(receiverRef, data);

    batch.commit();
};

/**
 * Confirm contact on both accounts and increment their nbOfContacts.
 * @param userId
 * @returns {Function}
 */
// eslint-disable-next-line no-unused-vars
export const dbConfirmContact = (userId) => (dispatch) => {
    const { uid } = firebaseApp.auth().currentUser;

    const data = {
        userId,
        status: 1,
    };

    const batch = db.batch();

    const senderRef = db.collection('users').doc(uid);
    batch.set(senderRef.collection('contacts').doc(userId), data);
    batch.update(senderRef, { nbOfContacts: firebase.firestore.FieldValue.increment(1) });

    data.userId = uid;

    const receiverRef = db.collection('users').doc(userId);
    batch.set(receiverRef.collection('contacts').doc(uid), data);
    batch.update(receiverRef, { nbOfContacts: firebase.firestore.FieldValue.increment(1) });

    batch.commit();
};

/**
 * Decline a contact
 * @param userId
 * @returns {Function}
 */
// eslint-disable-next-line no-unused-vars
export const dbDeclineContact = (userId) => (dispatch) => {
    const { uid } = firebaseApp.auth().currentUser;

    db.collection('users')
        .doc(uid)
        .collection('contacts')
        .doc(userId)
        .delete();
};

/**
 * Remove a contact
 * @param userId
 * @returns {Function}
 */
// eslint-disable-next-line no-unused-vars
export const dbDeleteContact = (userId) => (dispatch) => {
    const { uid } = firebaseApp.auth().currentUser;

    db.collection('users').doc(uid).collection('contacts').doc(userId)
        .delete()
        .then(() => {
            db.collection('users').doc(userId).collection('contacts').doc(uid)
                .delete()
        })
};
