import * as moment from 'moment';
import { firebaseApp, db } from '../../config/firebase';
import {
    SET_CHAT, SET_CHAT_ERROR, SET_CHAT_MESSAGES, SET_CHAT_PENDING,
} from './types';

const setIsPending = (isPending) => ({
    type: SET_CHAT_PENDING,
    isPending,
});

// eslint-disable-next-line no-unused-vars
const setError = (error) => ({
    type: SET_CHAT_ERROR,
    error,
});

const setChat = (chat) => ({
    type: SET_CHAT,
    chat,
});

const setChatMessages = (messages) => ({
    type: SET_CHAT_MESSAGES,
    messages,
});

export const dbFetchChat = (chatId) => (dispatch) => {
    const userId = firebaseApp.auth().currentUser.uid;

    dispatch(setChatMessages([]));
    dispatch(setIsPending(true));

    db.collection('users')
        .doc(userId)
        .collection('chats')
        .doc(chatId)
        .get()
        .then((doc) => ({
            id: doc.id,
            ...doc.data(),
        }))
        .then((chat) => {
            db.collection('chats')
                .doc(chatId)
                .get()
                .then((snapshot) => {
                    dispatch(setChat({
                        ...snapshot.data(),
                        ...chat,
                    }));
                });
        })
        .then(() => {
            db.collection('chats')
                .doc(chatId)
                .collection('messages')
                .orderBy('date', 'asc')
                .onSnapshot((snapshots) => {
                    const messages = snapshots.docs.map((snapshot) => ({
                        id: snapshot.id,
                        ...snapshot.data(),
                    }));

                    dispatch(setChatMessages(messages));
                    dispatch(setIsPending(false));
                });
        });
};

export const dbAddmessage = (chat, message) => (dispatch, getState) => {
    const { user: userFromReducer } = getState().User;

    const sender = {
        userId: firebaseApp.auth().currentUser.uid,
        profilePic: userFromReducer.profilePic || null,
        name: `${userFromReducer.firstName} ${userFromReducer.lastName}`,
        username: userFromReducer.username,
    };

    db.collection('chats')
        .doc(chat.id)
        .collection('messages')
        .add({
            date: moment.now(),
            message,
            sender,
        })
        .then(() => {
            if (chat.users !== undefined) {
                const batch = db.batch();

                chat.users.forEach((user, key) => {
                    if (chat.users.length === 2) {
                        chat.name = chat.users[0].userId === user.userId ? chat.users[1].name : chat.users[0].name;
                    }

                    const ref = db.collection('users')
                        .doc(user.userId)
                        .collection('chats')
                        .doc(chat.id);

                    batch.set(ref, {
                        profilePic: chat.profilePic || chat.users.length === 2 ? chat.users[key % 2 ? 0 : 1].profilePic : null,
                        name: chat.name,
                        userId: chat.users[user.userId === sender.userId ? 1 : 0].userId,
                        id: chat.id,
                        date: moment.now(),
                        seen: sender.userId === user.userId,
                        lastMessage: {
                            sender,
                            date: moment.now(),
                            message,
                        },
                    });
                });

                return batch.commit();
            } else {
                db.collection('chats')
                    .doc(chat.id)
                    .get()
                    .then((doc) => {
                        const chatUsers = doc.data().users
                        const chatProfilePic = doc.data().profilePic

                        const batch = db.batch();

                        chatUsers.forEach((user, key) => {
                            if (chatUsers.length === 2) {
                                chat.name = chatUsers[0].userId === user.userId ? chatUsers[1].name : chatUsers[0].name;
                            }

                            const ref = db.collection('users')
                                .doc(user.userId)
                                .collection('chats')
                                .doc(chat.id);

                            batch.set(ref, {
                                profilePic: chatProfilePic || chatUsers.length === 2 ? chatUsers[key % 2 ? 0 : 1].profilePic : null,
                                name: chat.name,
                                userId: chatUsers[user.userId === sender.userId ? 1 : 0].userId,
                                id: chat.id,
                                date: moment.now(),
                                seen: sender.userId === user.userId,
                                lastMessage: {
                                    sender,
                                    date: moment.now(),
                                    message,
                                },
                            });
                        });

                        return batch.commit();
                    })
            }
        });
};

export const dbAddpicture = (chat, picture) => (dispatch, getState) => {
    const { user: userFromReducer } = getState().User;

    const sender = {
        userId: firebaseApp.auth().currentUser.uid,
        profilePic: userFromReducer.profilePic || null,
        name: `${userFromReducer.firstName} ${userFromReducer.lastName}`,
        username: userFromReducer.username,
    };

    db.collection('chats')
        .doc(chat.id)
        .collection('messages')
        .add({
            date: moment.now(),
            picture,
            sender,
        })
        .then(() => {
            const batch = db.batch();

            chat.users.forEach((user, key) => {
                if (chat.users.length === 2) {
                    chat.name = chat.users[0].userId === user.userId ? chat.users[1].name : chat.users[0].name;
                }

                const ref = db.collection('users')
                    .doc(user.userId)
                    .collection('chats')
                    .doc(chat.id);

                batch.set(ref, {
                    profilePic: chat.profilePic || chat.users.length === 2 ? chat.users[key % 2 ? 0 : 1].profilePic : null,
                    name: chat.name,
                    // id: chat.users[user.userId === sender.userId ? 0 : 1].userId,
                    id: chat.id,
                    date: moment.now(),
                    seen: sender.userId === user.userId,
                    lastMessage: {
                        sender,
                        date: moment.now(),
                        picture,
                    },
                });
            });

            return batch.commit();
        });
};

export const dbAddvideo = (chat, video) => (dispatch, getState) => {
    const { user: userFromReducer } = getState().User;

    const sender = {
        userId: firebaseApp.auth().currentUser.uid,
        profilePic: userFromReducer.profilePic || null,
        name: `${userFromReducer.firstName} ${userFromReducer.lastName}`,
        username: userFromReducer.username,
    };

    db.collection('chats')
        .doc(chat.id)
        .collection('messages')
        .add({
            date: moment.now(),
            video,
            sender,
        })
        .then(() => {
            const batch = db.batch();

            chat.users.forEach((user, key) => {
                if (chat.users.length === 2) {
                    chat.name = chat.users[0].userId === user.userId ? chat.users[1].name : chat.users[0].name;
                }

                const ref = db.collection('users')
                    .doc(user.userId)
                    .collection('chats')
                    .doc(chat.id);

                batch.set(ref, {
                    profilePic: chat.profilePic || chat.users.length === 2 ? chat.users[key % 2 ? 0 : 1].profilePic : null,
                    name: chat.name,
                    // id: chat.users[user.userId === sender.userId ? 0 : 1].userId,
                    id: chat.id,
                    date: moment.now(),
                    seen: sender.userId === user.userId,
                    lastMessage: {
                        sender,
                        date: moment.now(),
                        video,
                    },
                });
            });

            return batch.commit();
        });
};

export const dbAddfile = (chat, file) => (dispatch, getState) => {
    const { user: userFromReducer } = getState().User;

    const sender = {
        userId: firebaseApp.auth().currentUser.uid,
        profilePic: userFromReducer.profilePic || null,
        name: `${userFromReducer.firstName} ${userFromReducer.lastName}`,
        username: userFromReducer.username,
    };

    db.collection('chats')
        .doc(chat.id)
        .collection('messages')
        .add({
            date: moment.now(),
            file,
            sender,
        })
        .then(() => {
            const batch = db.batch();

            chat.users.forEach((user, key) => {
                if (chat.users.length === 2) {
                    chat.name = chat.users[0].userId === user.userId ? chat.users[1].name : chat.users[0].name;
                }

                const ref = db.collection('users')
                    .doc(user.userId)
                    .collection('chats')
                    .doc(chat.id);

                batch.set(ref, {
                    profilePic: chat.profilePic || chat.users.length === 2 ? chat.users[key % 2 ? 0 : 1].profilePic : null,
                    name: chat.name,
                    // id: chat.users[user.userId === sender.userId ? 0 : 1].userId,
                    id: chat.id,
                    date: moment.now(),
                    seen: sender.userId === user.userId,
                    lastMessage: {
                        sender,
                        date: moment.now(),
                        file,
                    },
                });
            });

            return batch.commit();
        });
};

export const dbDeleteMessage = (chat, messageId) => (dispatch, getState) => {
    const { user: userFromReducer } = getState().User;

    const sender = {
        userId: firebaseApp.auth().currentUser.uid,
        profilePic: userFromReducer.profilePic || null,
        name: `${userFromReducer.firstName} ${userFromReducer.lastName}`,
        username: userFromReducer.username,
    };

    db.collection('chats')
        .doc(chat.id)
        .collection('messages')
        .doc(messageId)
        .update({
            message: 'Ce message a été supprimé.',
        })
        .then(() => {
            const batch = db.batch();

            chat.users.forEach((user, key) => {
                if (chat.users.length === 2) {
                    chat.name = chat.users[user.userId === sender.userId ? 0 : 1].name;
                }

                const ref = db.collection('users')
                    .doc(user.userId)
                    .collection('chats')
                    .doc(chat.id);

                batch.set(ref, {
                    profilePic: chat.profilePic || chat.users.length === 2 ? chat.users[key % 2 ? 0 : 1].profilePic : null,
                    name: chat.name,
                    date: moment.now(),
                    seen: sender.userId === user.userId,
                    lastMessage: {
                        sender,
                        date: moment.now(),
                        message: 'Ce message a été supprimé',
                    },
                });
            });

            return batch.commit();
        });
};

export const dbCreateChat = (users, message, history) => async (dispatch, getState) => {
    const { user: userFromReducer } = getState().User;
    let chat = null;

    users = users.map((user) => ({
        userId: user.objectID,
        profilePic: user.profilePic || null,
        name: `${user.firstName} ${user.lastName}`,
        username: user.username,
    }));

    const sender = {
        userId: firebaseApp.auth().currentUser.uid,
        profilePic: userFromReducer.profilePic || null,
        name: `${userFromReducer.firstName} ${userFromReducer.lastName}`,
        username: userFromReducer.username,
    };

    users.push(sender);

    if (users.length === 2) {
        let ref = db.collection('chats').where('nbOfUsers', '==', 2);

        users.forEach((user) => {
            ref = ref.where(`userIds.${user.userId}`, '==', true);
        });

        chat = await ref.get()
            .then((snapshot) => {
                if (snapshot.docs.length) {
                    return {
                        id: snapshot.docs[0].id,
                        ...snapshot.docs[0].data(),
                    };
                }
                return null;
            });
    }

    if (!chat && users.length === 2) {
        const userIds = {};
        users.forEach((user) => { userIds[user.userId] = true; });
        const chatId = users[0].userId > users[1].userId ? users[0].userId + users[1].userId : users[1].userId + users[0].userId;
        chat = await db.collection('chats')
            .doc(chatId)
            .set({
                date: moment.now(),
                nbOfUsers: users.length,
                userIds,
                users,
            }).then(() => {
                return {
                    id: chatId,
                    users,
                };
            });
    }

    if (!chat && users.length > 2) {
        const userIds = {};
        const names = [];
        users.forEach((user, i) => {
            if (i > 2) return;
            names.push(user.name);
        });
        users.forEach((user) => { userIds[user.userId] = true; });
        chat = await db.collection('chats')
            .add({
                date: moment.now(),
                nbOfUsers: users.length,
                userIds,
                users,
                name: names.join(', '),
            }).then((snapshot) => {
                if (snapshot.id) {
                    return {
                        id: snapshot.id,
                        users,
                    };
                }
                return null;
            });
    }

    dispatch(dbAddmessage(chat, message));
    history.push(`/chat/${chat.id}`);
};

export const dbCreateChatWithoutMessage = (userId, chatId) => async (dispatch, getState) => {
    const users = []
    const { user: userFromReducer } = getState().User;

    const sender = {
        userId: firebaseApp.auth().currentUser.uid,
        profilePic: userFromReducer.profilePic || null,
        name: `${userFromReducer.firstName} ${userFromReducer.lastName}`,
        username: userFromReducer.username,
    };

    users.push(sender);

    db.collection('users').doc(userId).get()
        .then(async (doc) => {
            users.push({
                userId,
                profilePic: doc.data().profilePic || null,
                name: `${doc.data().firstName} ${doc.data().lastName}`,
                username: doc.data().username,
            })

            const userIds = {};
            users.forEach((user) => { userIds[user.userId] = true; });

            const chat = {
                date: moment.now(),
                nbOfUsers: users.length,
                userIds,
                users,
            }

            await db.collection('chats')
                .doc(chatId)
                .set(chat)
                .then(() => {
                    const batch = db.batch();

                    users.forEach((user, key) => {
                        chat.name = users[0].userId === user.userId ? users[1].name : users[0].name;

                        const ref = db.collection('users')
                            .doc(user.userId)
                            .collection('chats')
                            .doc(chatId);

                        batch.set(ref, {
                            profilePic: chat.profilePic || chat.users.length === 2 ? chat.users[key % 2 ? 0 : 1].profilePic : null,
                            name: chat.name,
                            userId: chat.users[user.userId === sender.userId ? 1 : 0].userId,
                            id: chatId,
                        });
                    });

                    return batch.commit();
                });
        })
};

// eslint-disable-next-line no-unused-vars
export const dbDeleteChat = (chatId) => (dispatch) => {
    const userId = firebaseApp.auth().currentUser.uid;

    db.collection('users')
        .doc(userId)
        .collection('chats')
        .doc(chatId)
        .delete()
        .then(() => {
            db.collection('users')
                .doc(chatId.replace(userId, ''))
                .collection('chats')
                .doc(chatId)
                .delete()
                .then(() => {
                    db.collection('chats')
                        .doc(chatId)
                        .collection('messages')
                        .get()
                        .then((snap) => {
                            snap.docs.forEach((doc) => {
                                const id = doc.id
                                db.collection('chats')
                                    .doc(chatId)
                                    .collection('messages')
                                    .doc(id)
                                    .delete()

                            })
                            db.collection('chats')
                                .doc(chatId)
                                .delete()
                        })
                })
        });
};
