import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
    Badge, Card, CardBody, Col, Row
} from 'reactstrap';
import DefaultProfile from '../../assets/Pics/defaultProfile.jpg';
import { db } from '../../config/firebase';
import './custom.css';

class ProfileCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            society: '',
            company: '',
        };
    }

    componentDidMount() {
        db.collection('usersSociety')
            .doc(this.props.profile.objectID)
            .get()
            .then((society) => {
                const {
                    informations
                } = society.data();
                this.setState({ society: informations.denomination })
            })
            .catch(() => { })

        db.collection("users").doc(this.props.profile.objectID).get()
            .then((doc) => {
                const initialCompany = doc.get("initialCompany")
                if (initialCompany !== undefined && initialCompany !== null && initialCompany !== '') {
                    db.collection('companies').doc(initialCompany).get().then((doc) => {
                        const name = doc.data().name
                        this.setState({ company: name })
                    }).catch(() => { });
                }
            });
    }

    render() {
        const {
            profile,
            cornerInfo,
            isCurrentUserPremium,
        } = this.props;

        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        }

        return (
            <Card className="bg-white" style={{ height: '100%', cursor: "pointer" }} onClick={!isCurrentUserPremium ? () => this.props.toggle() : () => { }}>
                <CardBody >
                    {
                        cornerInfo !== null && (
                            <Row>
                                <Col className="text-right">
                                    {cornerInfo}
                                </Col>
                            </Row>
                        )
                    }
                    <Row className="align-items-center">
                        <Col className="text-center" xs={12}>
                            <img
                                className="avatar rounded-circle mb-2"
                                alt=''
                                src={profile.profilePic ? profile.profilePic : DefaultProfile}
                                style={{ width: '75px', height: '75px' }}
                            />
                        </Col>
                        <Col className="text-center" xs={12}>
                            {
                                (profile.name === undefined) ?
                                    <h3>
                                        {`${capitalizeFirstLetter(profile.firstName)} ${profile.lastName.toUpperCase()}`}
                                    </h3>
                                    :
                                    <h3>
                                        {`${profile.name}`}
                                    </h3>
                            }
                            <p className="mb-0">
                                {profile.function}
                                {' '}
                                {profile.company && profile.function && (' - ')}
                                {' '}
                                {profile.company}
                            </p>
                            {
                                this.state.society ? (
                                    <p className="mb--1">
                                        {capitalizeFirstLetter(this.state.society)}
                                    </p>
                                ) : this.state.company ? (
                                    <p className="mb--1">
                                        {capitalizeFirstLetter(this.state.company)}
                                    </p>
                                ) : <div></div>
                            }
                            <p>
                                {
                                    profile.categories && profile.categories.map((category, key) => {
                                        const index = `categories_${key}`;

                                        return (
                                            <Badge key={index} color="primary" className="mr-2">{category}</Badge>
                                        );
                                    })
                                }
                            </p>
                            {
                                profile.city && (
                                    <p>
                                        <span role="img" aria-label="pin">📍</span>
                                        {' '}
                                        {capitalizeFirstLetter(profile.city.toLowerCase())}
                                    </p>
                                )
                            }
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }

    /*render() {
        const {
            profile,
            cornerInfo,
        } = this.props;

        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        }

        return (
            <Card className="shadow" style={{ height: '100%' }}>
                <CardBody>
                    {
                        cornerInfo !== null && (
                            <Row>
                                <Col className="text-right">
                                    {cornerInfo}
                                </Col>
                            </Row>
                        )
                    }
                    <Row className="align-items-center">
                        <Col className="text-center" xs={12}>
                            <img
                                className="avatar rounded-circle mb-2"
                                alt=''
                                src={profile.profilePic ? profile.profilePic : DefaultProfile}
                                style={{ width: '75px', height: '75px' }}
                            />
                        </Col>
                        <Col className="text-center" xs={12}>
                            {
                                profile.name === undefined ?
                                    <h3>
                                        <Link target="_blank" to={`/user/${profile.username}/about`}>{`${capitalizeFirstLetter(profile.firstName)} ${profile.lastName.toUpperCase()}`}</Link>
                                    </h3> :
                                    <h3>
                                        <Link target="_blank" to={`/company/${profile.username}/about`}>{`${profile.name}`}</Link>
                                    </h3>
                            }
                            <p className="mb-0">
                                {profile.function}
                                {' '}
                                {profile.company && profile.function && (' - ')}
                                {' '}
                                {profile.company}
                            </p>
                            {
                                this.state.society && (
                                    <p className="mb--1">
                                        {capitalizeFirstLetter(this.state.society)}
                                    </p>
                                )
                            }
                            <p>
                                {
                                    profile.categories && profile.categories.map((category, key) => {
                                        const index = `categories_${key}`;

                                        return (
                                            <Badge key={index} color="primary" className="mr-2">{category}</Badge>
                                        );
                                    })
                                }
                            </p>
                            {
                                profile.city && (
                                    <p>
                                        <span role="img" aria-label="pin">📍</span>
                                        {' '}
                                        {capitalizeFirstLetter(profile.city.toLowerCase())}
                                    </p>
                                )
                            }
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }*/
}

export default ProfileCard;

ProfileCard.propTypes = {
    action: PropTypes.object,
    cornerInfo: PropTypes.string,
    profile: PropTypes.object.isRequired,
};

ProfileCard.defaultProps = {
    action: null,
    cornerInfo: null,
};