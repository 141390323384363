import React, { Component } from "react";
import Scrollspy from "react-scrollspy";
import { Button, Col, Row, Container } from "reactstrap";
import { scroller } from "react-scroll";
import { Helmet } from "react-helmet";
import classes from "../../assets/Scss/components/BecomeBroker.scss";
import config from "../../config/config";
import { Link } from 'react-router-dom';
import Img from '../../assets/Pics/tribu-je-suis-courtier.svg';


class ImBroker extends Component {
    myRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            currentSection: "section-1",
        };
    }

    handleNewSection = (data) => {
        if (data) {
            this.setState({ currentSection: data.id });
        }
    };

    scrollToHandler = (e) => {
        e.preventDefault();
        const { currentSection } = this.state;
        const newSection = currentSection.split("-");
        newSection[1] = parseInt(newSection[1], 10) + 1;

        scroller.scrollTo(newSection.join("-"), {
            duration: 500,
            delay: 100,
            smooth: true,
        });

        this.setState({ currentSection: newSection.join("-") });
    };

    render() {
        const { currentSection } = this.state;

        return (
            <div className={classes.BecomeBroker} style={{}}>
                <Helmet>
                    <title>Rejoindre Tribu la communauté des courtiers crédit et assurance</title>
                    <meta
                        name="description"
                        content="Il suffit de quelques minutes sur Tribu pour créer un profil qui attirera l'attention des fournisseurs et de milliers de courtiers en financement et en assurance."
                    />
                    <meta
                        name="keywords"
                        content="franchise courtier, franchise courtier credit, franchise courtier assurance, franchise
                        courtier rachat crédit, franchise courtage, franchise courtage credit, franchise courtage assurance,
                        franchise courtage rachat de crédit, groupement gestion de patrimoine, groupement cgp, logiciel
                        credit, logiciel credit immobilier, logiciel rachat credits, logiciel credit professionnel, logiciel
                        assurance, logiciel courtier, logiciel courtier credit, logiciel courtier credit immobilier, logiciel courtier
                        rachat crédits, logiciel courtier credit professionnel, logiciel courtier assurance, logiciel courtage,
                        logiciel courtage credit, logiciel courtage credit immobilier, logiciel courtage rachat credits, logiciel
                        courtage credit professionnel, logiciel courtage assurance, formation continue orias, formation
                        continue courtier credit, formation continue courtage credit, formation continue courtier iobsp,
                        formation continue courtage iobsp, formation continue iob, formation dci, formation continue
                        courtier assurance, formation continue courtage assurance, formation continue courtier ias,
                        formation continue courtage ias, formation continue ias, formation dda, formation continue cif,
                        formation loi alur"
                    />
                </Helmet>
                <Scrollspy
                    className="d-none"
                    items={[
                        "section-1",
                        "section-2",
                        "section-3",
                        "section-4",
                        "section-5",
                        "section-6",
                    ]}
                    onUpdate={this.handleNewSection}
                />
                <section className={`header bg-gradient-info py-9 ${classes.About}`}>
                    <Container>

                        <Row className="justify-content-center">

                            <img
                                style={{ transform: 'translate(0, -60px)' }}
                                className="mb-3 bg-title-image"
                                alt="je suis courtier"
                                src={Img}
                            />

                        </Row>


                        <Row className="justify-content-center" style={{ paddingBottom: 30 }}>

                            <Col xs={12}>
                                <h5 className="display-3 text-center text-white"
                                    style={{ textShadow: '0px 0px 20px rgba(0,0,0,0.4)' }}
                                >
                                    Rejoignez et intégrez la plus grande
                                    communauté de courtiers
                                </h5>
                            </Col>
                            {/* <Col className="mt-3" md={6}>
                                <p className="h2 text-center text-white font-weight-normal"
                               style={{textShadow:  '0px 0px 20px rgba(0,0,0,0.4)'}} 
                                >
                                    Seul ou en équipe, trouvez les partenaires qui vous correspondent et travaillez simplement.
                                </p>
                            </Col> */}
                        </Row>
                        <div className="separator separator-bottom separator-skew zindex-100">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                                viewBox="0 0 2560 100"
                                x="0"
                                y="0"
                            >
                                <polygon
                                    className="fill-white"
                                    points="2560 0 2560 100 0 100"
                                />
                            </svg>
                        </div>
                    </Container>
                </section>
                <section className="bg-white" style={{
                    zIndex: 2,
                    position: "relative",
                }}>
                    <Container className="py-6">
                        <Row className="justify-content-center">
                            <Col xs={12}>
                                <h5 className="display-3 text-center">
                                    Créez un profil qui vous ressemble
                                </h5>
                            </Col>
                            <Col className="mt-3" md={6}>
                                <p className="h2 text-center font-weight-normal" >
                                    Prenez quelques minutes pour compléter vos profils personnel et professionnel : expériences, compétences, services, pour une mise en relation réussie. Notre équipe vous permet d’optimiser votre profil.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="header bg-gradient-info">
                    <Container className="pt-6 pb-8">
                        <Row className="justify-content-center">
                            <Col xs={12}>
                                <h5 className="display-3 text-center text-white">
                                    Composez votre propre équipe
                                </h5>
                            </Col>
                            <Col className="mt-3" md={6}>
                                <p className="h2 text-center text-white font-weight-normal" >
                                    Invitez des courtiers de votre réseau et construisez de nouveaux projets grâce à votre équipe.

                                </p>
                            </Col>
                        </Row>
                        {/*             
                    <h5 className="display-3 font-weight-normal text-center text-white">
                      Prenez part à l&apos;aventure,
                      <span className="font-weight-bold d-inline"> rejoignez la team Tribu !</span>
                    </h5>
                    <Row className="justify-content-center mt-4">
                      <Button className="btn-secondary text-primary px-5" onClick={() => this.setState({ signupModal: !signupModal })}>INSCRIVEZ-VOUS</Button>
                    </Row> */}



                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                                viewBox="0 0 2560 100"
                                x="0"
                                y="0"
                            >
                                <polygon
                                    className="fill-white"
                                    points="0 0 2560 100 0 100"
                                />
                            </svg>
                        </div>
                    </Container>
                </section>
                <section className="bg-white">
                    <Container className="py-6">
                        <Row className="justify-content-center">
                            <Col xs={12}>
                                <h5 className="display-3 text-center">
                                    Rejoignez la communauté Tribu,
                                    <br />
                                    ensemble nous sommes plus forts
                                </h5>
                            </Col>
                            <Col className="mt-3" md={6}>
                                <Row className="justify-content-center mt-4">
                                    <Link to="/signup">
                                        <Button className="btn-secondary text-primary px-4 mw-100">JE M'INSCRIS GRATUITEMENT</Button>
                                    </Link>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div >
        );
    }
}

export default ImBroker;