import PropTypes from 'prop-types';
import React from 'react';
import { Col, DropdownItem, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { dbConfirmContact, dbDeclineContact } from '../../store/contacts/actions';
import DefaultProfile from '../../assets/Pics/defaultProfile.jpg';
import { Link } from 'react-router-dom';

const ContactRequest = (props) => {
    const { request, confirmContact, declineContact, isCurrentUserPremium } = props;

    return (
        <DropdownItem className="border-top">
            <Row className="align-items-center">
                <Col className="col-auto pr-0">
                    {
                        isCurrentUserPremium ?
                            <Link target="_blank" className="text-primary w-100" to={`/user/${request.author.username}/about`}>
                                <img alt='' className="avatar rounded-circle" src={request.author.profilePic ? request.author.profilePic : DefaultProfile} style={{ maxWidth: '40px', maxHeight: '40px' }} />
                            </Link>
                            :
                            <div className="text-primary w-100" style={{ cursor: "pointer" }} onClick={!isCurrentUserPremium ? () => props.toggle() : () => { }}>
                                <img alt='' className="avatar rounded-circle" src={request.author.profilePic ? request.author.profilePic : DefaultProfile} style={{ maxWidth: '40px', maxHeight: '40px' }} />
                            </div>
                    }
                </Col>
                <div className="col pl-0">
                    <div className="d-flex justify-content-between align-items-center">
                        {
                            isCurrentUserPremium ?
                                <Link target="_blank" className="text-primary w-100" to={`/user/${request.author.username}/about`}>
                                    <h4 className="mb-0 text-sm">{request.author.name}</h4>
                                </Link>
                                :
                                <div className="text-primary w-100" style={{ cursor: "pointer" }} onClick={!isCurrentUserPremium ? () => props.toggle() : () => { }}>
                                    <h4 className="mb-0 text-sm">{request.author.name}</h4>
                                </div>
                        }
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <span className="text-muted" style={{ fontSize: '13px' }}>{request.author.company}</span>
                    </div>
                </div>
                <Col className="col-auto">
                    <div
                        className="icon icon-shape bg-transparent border rounded-circle cursor-pointer mr-1"
                        style={{ width: '30px', height: '30px' }}
                        onClick={!isCurrentUserPremium ? () => props.toggle() : declineContact(request.author.id)}
                    >
                        <i className="fas fa-times text-light" style={{ fontSize: '15px' }} />
                    </div>
                    <div
                        className="icon icon-shape bg-primary rounded-circle cursor-pointer"
                        style={{ width: '30px', height: '30px' }}
                        onClick={!isCurrentUserPremium ? () => props.toggle() : confirmContact(request.author.id)}
                    >
                        <i className="fas fa-check text-white" style={{ fontSize: '15px' }} />
                    </div>
                </Col>
            </Row>
        </DropdownItem>
    );
};

export default connect(null, {
    confirmContact: dbConfirmContact,
    declineContact: dbDeclineContact,
})(ContactRequest);

ContactRequest.propTypes = {
    confirmContact: PropTypes.func,
    declineContact: PropTypes.func,
    request: PropTypes.object,
};

ContactRequest.defaultProps = {
    confirmContact: () => { },
    declineContact: () => { },
    request: {},
};
