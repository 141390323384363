import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Aux from "../../hoc/Aux";
import Brand from "../../assets/Pics/Icons/tribu-white.svg";
import { CGUV, PDD } from "../../router/Routes";

class Pdd extends Component {
  render() {
    return (
      <Aux>
        <Helmet>
          <title>Centre de confidentialité</title>
          <meta
            name="description"
            content="La protection de vos données personnelles est importante pour
            nous. Nous nous engageons à ne collecter que les données dont nous
            avons besoin pour vous assurer un service optimal, à en assurer la
            confidentialité et la sécurité, y compris lorsque nous faisons
            appel à des prestataires et à faciliter l'exercice de vos
            droits sur vos données."
          />
        </Helmet>
        <div className="bg-white">
          <div className="header bg-gradient-info py-9">
            <Container>
              <div className="header-body">
                <Row className="justify-content-center">
                  <Col className="col-auto">
                    <Link to="/">
                      <img
                        className="mb-3"
                        alt="Tribu"
                        src={Brand}
                        style={{ maxWidth: "75px" }}
                      />
                    </Link>
                  </Col>
                </Row>
                <hr className="bg-white w-50 text-left my-2" />
                <p className="text-white text-center display-4 font-weight-300">
                  Protection des données
                </p>
              </div>
            </Container>
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </div>
          <Container className="py-4 col-md-7">
            <h5 className="h6 display-3 text-center">Introduction</h5>
            <p>
           
            </p>
            <p>
              Notre premier engagement est le respect de la législation
              applicable en matière de protection des données et notamment du
              Règlement général relatif à la protection des données personnelles
              (également appelé RGPD) et de la loi dite “Informatique et
              Libertés” du 6 janvier 1978 modifiée.
            </p>
            <p>
              La présente politique décrit les données personnelles que nous
              recueillons, comment elles sont utilisées et partagées, et vos
              droits à cet égard. Elle s&apos;applique à tout utilisateur des
              services Tribu. Nous vous conseillons de la lire attentivement.
              Nous avons fait le maximum pour qu&apos;elle soit claire,
              facilement compréhensible et accessible à tout moment de votre
              navigation.
            </p>
            <p>
              Si vous souhaitez obtenir une clarification ou si vous avez une
              question à nous poser la concernant, vous pouvez contacter notre
              DPO à l&apos;adresse suivante: dpo@tribu.app.
            </p>
            <p>
              Nous nous réservons le droit de modifier la présente Politique de
              confidentialité à tout moment. La version la plus actuelle de la
              présente politique régit notre utilisation de vos informations et
              sera toujours disponible à<Link to={PDD}> tribu.app/pdd</Link>.
            </p>
            <p>
              Si nous devions apporter une modification substantielle à cette
              politique de confidentialité, nous nous engageons à vous la
              notifier via l&apos;adresse email que vous avez renseignée sur le
              site.
            </p>
          </Container>

          <div className="header bg-gradient-info py-6">
            <Container className="pb-3 text-white col-md-7">
              <h5 className="h6 display-3 text-white text-center">
                Responsable du traitement des données : Tribu
              </h5>
              <p>
                La société K, société par actions simplifée, immatriculée au RCS
                de Lyon sous le n° 850 733 262
              </p>
            </Container>
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon className="fill-white" points="0 0 2560 100 0 100" />
              </svg>
            </div>
          </div>

          <Container className="py-4 col-md-7">
            <h5 className="h6 display-3 text-center">
              Coordonnées de notre DPO
            </h5>
            <p>
              Vous pouvez adresser toute question relative à la protection des
              données personnelles chez Tribu ou adresser une demande
              d&apos;exercice de vos droits d&apos;accès, rectification,
              opposition, effacement, limitation, portabilité à notre délégué à
              la protection des données :
            </p>
            <ul className="font-weight-light">
              <li>par mail: dpo@tribu.app</li>
            </ul>
          </Container>

          <div className="header bg-gradient-info py-6">
            <Container className="pb-3 text-white col-md-7">
              <h5 className="h6 display-3 text-white text-center">
                Données que nous collectons
              </h5>
              <h5 className="h6 display-4 text-white">Résumé</h5>
              <p>
                Dans le cadre de votre utilisation de nos services et de votre
                navigation sur notre site internet, Tribu collecte plusieurs
                catégories de données dont vous trouverez le détail ci-dessous.
                Ces données proviennent :
              </p>
              <ul className="font-weight-light">
                <li>
                  Des informations que vous nous communiquez. Lorsque vous créez
                  votre compte, que vous mettez à jour votre profil, ou lorsque
                  vous échangez avec notre équipe. Ces informations personnelles
                  sont par exemple des données permettant votre identification,
                  votre adresse e-mail, votre photo, vos coordonnées bancaires.
                </li>
                <li>
                  Des informations résultant de votre utilisation de nos
                  services. Nous collectons des informations relatives aux
                  services que vous utilisez et à l&apos;usage que vous en
                  faites. Par exemple, l&apos;utilisation de la messagerie, les
                  données relatives à votre navigation sur le site, les pages
                  que vous avez consultées, votre activité sur la plateforme, et
                  les informations relatives aux transactions.
                </li>
              </ul>
              <p>
                Nous ne traitons pas de catégories particulières de données
                (données sensibles) telles que les données qui révèlent
                l&apos;origine raciale ou ethnique, les opinions politiques, les
                convictions religieuses ou philosophiques ou l&apos;appartenance
                syndicale, des données génétiques, des données biométriques, des
                données concernant la santé ou des données concernant la vie
                sexuelle ou l&apos;orientation sexuelle des personnes.
              </p>
              <h5 className="h6 display-4 text-white">
                Données relatives aux Courtiers
              </h5>
              <p>
                Nous informons au moment de la collecte du caractère obligatoire
                ou non de la donnée.
              </p>
              <ul className="font-weight-light">
                <li>
                  Informations personnelles
                  <ul className="font-weight-light">
                    <li>Nom</li>
                    <li>Prénom</li>
                    <li>Email</li>
                    <li>Photo de profil</li>
                  </ul>
                  <li>
                    Informations relatives aux échanges avec nos équipes
                    <ul className="font-weight-light">
                      <li>Emails</li>
                      <li>Dates et durées des appels téléphoniques</li>
                    </ul>
                  </li>
                  <li>
                    Informations professionnelles
                    <ul className="font-weight-light">
                      <li>Expériences professionnelles</li>
                      <li>Compétences</li>
                      <li>Portfolio</li>
                    </ul>
                  </li>
                  <li>
                    Structure juridique
                    <ul className="font-weight-light">
                      <li>Nom de l&apos;entreprise</li>
                      <li>Forme juridique</li>
                      <li>
                        Identité du représentant légal de l&apos;entreprise
                      </li>
                      <li>Documents légaux</li>
                    </ul>
                  </li>
                  <li>
                    Information bancaire
                    <ul className="font-weight-light">
                      <li>Relevé d&apos;Identité Bancaire</li>
                    </ul>
                  </li>
                  <li>
                    Compléments d&apos;informations sur votre profil
                    <ul className="font-weight-light">
                      <li>Intégration de vos réseaux sociaux</li>
                      <li>Certifications et formations</li>
                      <li>Langues</li>
                    </ul>
                  </li>
                </li>
              </ul>
              <h5 className="h6 display-4 text-white">
                Générées par votre utilisation de la plateforme
              </h5>
              <ul className="font-weight-light">
                <li>
                  Données de navigation
                  <ul className="font-weight-light">
                    <li>Cookies et adresse IP</li>
                  </ul>
                </li>
                <li>
                  Informations relatives aux transactions
                  <ul className="font-weight-light">
                    <li>Détails des transactions</li>
                  </ul>
                </li>
                <li>
                  Recommandations
                  <ul className="font-weight-light">
                    <li>
                      Recommandations externes faisant suite à vos sollicitation
                    </li>
                  </ul>
                </li>
                <li>
                  Données relatives à l&apos;utilisation de la messagerie
                  sécurisée
                  <ul className="font-weight-light">
                    <li>Identifiants des utilisateurs</li>
                    <li>Date et heure d&apos;envoi</li>
                    <li>Contenu</li>
                  </ul>
                </li>
              </ul>
              <h5 className="h6 display-4 text-white">
                Données relatives aux Grands Comptes
              </h5>
              <p>
                Nous informons au moment de la collecte du caractère obligatoire
                ou non de la donnée.
              </p>
              <h5 className="h6 display-4 text-white">
                Communiquées par l&apos;utilisateur
              </h5>
              <ul className="font-weight-light">
                <li>
                  Informations personnelles&nbsp;
                  <ul className="font-weight-light">
                    <li>Nom</li>
                    <li>Pr&eacute;nom</li>
                    <li>Adresse email&nbsp;</li>
                    <li>Photo de profil&nbsp;</li>
                  </ul>
                </li>
                <li>
                  Informations g&eacute;n&eacute;rales sur
                  l&apos;entreprise&nbsp;
                  <ul className="font-weight-light">
                    <li>Secteur d&apos;activit&eacute;&nbsp;</li>
                    <li>Nombre d&apos;employ&eacute;s&nbsp;</li>
                    <li>Logo de l&apos;entreprise&nbsp;</li>
                    <li>Structure juridique&nbsp;</li>
                    <li>Coordonn&eacute;es de facturation&nbsp;</li>
                    <li>Contact de comptabilit&eacute;&nbsp;</li>
                  </ul>
                </li>
                <li>
                  Informations relatives aux &eacute;changes avec nos
                  &eacute;quipes&nbsp;
                  <ul className="font-weight-light">
                    <li>Emails</li>
                    <li>
                      Dates et dur&eacute;es des appels
                      t&eacute;l&eacute;phoniques&nbsp;
                    </li>
                  </ul>
                </li>
                <li>
                  G&eacute;n&eacute;r&eacute;es par votre utilisation de la
                  plateforme&nbsp;
                  <ul className="font-weight-light">
                    <li>Donn&eacute;es de navigation&nbsp;</li>
                    <li>Cookies et adresse IP&nbsp;</li>
                  </ul>
                </li>
                <li>
                  Informations relatives aux transactions&nbsp;
                  <ul className="font-weight-light">
                    <li>D&eacute;tails des transactions&nbsp;</li>
                  </ul>
                </li>
                <li>
                  Donn&eacute;es relatives &agrave; l&apos;utilisation de la
                  messagerie s&eacute;curis&eacute;e&nbsp;
                  <ul className="font-weight-light">
                    <li>Identifiants des utilisateurs&nbsp;</li>
                    <li>Date et heure d&apos;envoi&nbsp;</li>
                    <li>Contenu</li>
                  </ul>
                </li>
              </ul>
              <h5 className="h6 display-4 text-white">
                Informations statistiques relatives à votre utilisation de nos
                services
              </h5>
              <p>
                Nous recueillons par exemple des statistiques d&apos;utilisation
                de la messagerie sécurisée que nous proposons (pourcentage de
                messages répondus, temps de réponse); données de connexion et de
                navigation des utilisateurs, données statistiques sur
                l&apos;utilisation du moteur de recherche et du formulaire de
                contact; statistiques d&apos;utilisation des différents modules;
                les fonctionnalités mises à disposition des grands comptes.
              </p>
              <h5 className="h6 display-4 text-white">Cookies</h5>
              <p>
                Pour le bon fonctionnement du Site et des Services, des cookies
                sont implantés dans votre ordinateur lors de votre connexion au
                Site. Les cookies sont de petits fichiers textes qui sont
                stockés dans votre navigateur ou votre appareil par les sites
                Internet, les applications, les médias en ligne et les
                publicités.
              </p>
              <p>
                Nous utilisons des cookies d&apos;authentification, cookies de
                sécurité et cookies relatifs à l&apos;intégrité du site, cookies
                de localisation, cookies liés à la fonctionnalité du site et
                services, des cookies d&apos;analyses et de recherche et des
                cookies pour diffuser des messages publicitaires.
              </p>
              <p>
                La durée de conservation des cookies implantés par Tribu va de
                deux semaines (cookie d&apos;identification) à 6 mois (Analyses
                et recherche) et 13 mois (Pour Google analytics).
              </p>
              <p>
                Vous disposez toutefois de la possibilité de vous opposer à
                l&apos;utilisation des cookies en configurant votre logiciel de
                navigation. Chaque logiciel de navigation étant différent, vous
                êtes invité à consulter la notice de votre navigateur pour le
                paramétrer comme il le souhaite.
              </p>
              <p>
                Pour de plus amples informations sur les cookies, y compris pour
                voir quels cookies ont été installés sur votre appareil et
                comment les gérer et les supprimer, vous pouvez visiter le site
                <a
                  href="https://www.allaboutcookies.org/"
                  className="text-default"
                >
                  {" "}
                  allaboutcookies.org{" "}
                </a>
                et
                <a
                  href="https://www.youronlinechoices.eu/"
                  className="text-default"
                >
                  {" "}
                  youronlinechoices.eu
                </a>
                .
              </p>
            </Container>
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </div>

          <Container className="py-4 col-md-7">
            <h5 className="h6 display-3 text-center">Finalités</h5>
            <h5 className="h6 display-4">Résumé</h5>
            <p>
              Tribu procède au traitement de vos données à caractère personnel,
              notamment lors de votre connexion au site, de votre inscription au
              site, de votre activité sur le site ou lors de l&apos;utilisation
              du système de paiement.
            </p>
            <p>
              Les données que nous collectons nous permettent de fournir, gérer,
              protéger et améliorer nos services, d&apos;en développer de
              nouveaux, et de protéger aussi bien nos utilisateurs que
              nous-mêmes.
            </p>
            <h5 className="h6 display-4">Détails</h5>
            <p>En particulier, ces données sont destinées à</p>
            <ul className="font-weight-light">
              <li>La création et la gestion de votre compte;</li>
              <li>
                L&apos;utilisation des services du site;
                <ul className="font-weight-light">
                  <li>
                    Pour les courtiers, l&apos;optimisation de votre
                    r&eacute;f&eacute;rencement dans le moteur de recherche;
                    pour les grands comptes la proposition de r&eacute;sultats
                    de recherche les plus pertinents;
                  </li>
                  <li>
                    Prot&eacute;ger nos utilisateurs contre le spam et garantir
                    la qualit&eacute; de notre service (notamment gr&acirc;ce
                    aux statistiques);
                  </li>
                  <li>
                    Permettre la communication entre utilisateurs par la mise
                    &agrave; disposition d&apos;une messagerie
                    instantan&eacute;e s&eacute;curis&eacute;e;
                  </li>
                  <li>
                    Mettre &agrave; votre disposition vos documents (factures,
                    contrats&hellip;);
                  </li>
                  <li>
                    La v&eacute;rification de votre soci&eacute;t&eacute; sur la
                    base de vos documents l&eacute;gaux;
                  </li>
                </ul>
              </li>
              <li>
                Effectuer un suivi personnalis&eacute; pour ne vous adresser que
                des communications pertinentes, adapt&eacute;es &agrave; vos
                besoins et directement en lien avec les services du site (par
                exemple: vous inviter &agrave; mettre &agrave;
                jour/compl&eacute;ter votre profil afin de maximiser vos chances
                de d&eacute;crocher un partenariat&hellip;);&nbsp;
              </li>
              <li>
                S&eacute;curiser le site <Link to="/">tribu.app</Link>, ainsi
                que les donn&eacute;es et &eacute;changes intervenant sur le
                site;&nbsp;
              </li>
              <li>
                La gestion des flux financiers intervenant via la plateforme
                ainsi que, conform&eacute;ment &agrave; la loi, la lutte contre
                le terrorisme et le blanchiment d&apos;argent;
              </li>
              <li>
                Faire &eacute;voluer nos services pour vous offrir de nouvelles
                fonctionnalit&eacute;s et nous adapter &agrave; vos besoins:
                &agrave; cette fin, nous r&eacute;alisons des statistiques de
                suivi de l&apos;audience des pages de notre site internet et de
                suivi des activit&eacute;s commerciales et du comportement des
                utilisateurs sur la plateforme. Ces statistiques sont
                anonymis&eacute;es aussi souvent que possible;&nbsp;
              </li>
              <li>
                Garantir la qualit&eacute; des profils, lutter contre la fraude,
                v&eacute;rifier les informations des profils (par ex.
                localisation);&nbsp;
              </li>
              <li>
                Vous adresser des notifications li&eacute;es &agrave; votre
                activit&eacute; sur le site comme, par exemple, lorsque vous
                avez re&ccedil;u un message;&nbsp;
              </li>
              <li>
                Communiquer avec vous: Tribu peut utiliser les informations
                recueillies pour communiquer avec vous au sujet des produits,
                des services, des promotions, des &eacute;tudes, des
                enqu&ecirc;tes, des actualit&eacute;s, des mises &agrave; jour
                et des &eacute;v&eacute;nements.&nbsp;
              </li>
            </ul>
            <h5 className="h6 display-4">Base juridique des traitements</h5>
            <p>
              Tribu traite la plupart de vos données personnelles dans le cadre
              de votre inscription sur le site via l&apos;acceptation de nos
              conditions générales d&apos;utilisation (si vous souhaitez
              consulter de nouveau nos conditions générales d&apos;utilisation,
              vous les trouverez <Link to={CGUV}>tribu.app/cguv</Link>
              ). Toutefois, nous sommes susceptibles de traiter certaines
              données vous concernant sur la base de votre consentement, en
              raison d&apos;obligations légales ou pour répondre à notre intérêt
              légitime à les traiter.
            </p>
            <h5 className="h6 display-4">Détails</h5>
            <ul className="font-weight-light">
              <li>
                <span className="font-weight-bold">Inscription: </span>
                Tribu traite la plupart de vos données personnelles dans le
                cadre de votre inscription sur le site via l&apos;acceptation de
                nos conditions générales d&apos;utilisation (si vous souhaitez
                consulter de nouveau nos conditions générales
                d&apos;utilisation, vous les trouverez à
                <Link to={CGUV}> tribu.app/cguv</Link>
                ). Ainsi, toutes les données que vous nous communiquez lors de
                la création et la gestion de votre profil ainsi que les données
                nécessaires à la création, les échanges que vous pouvez avoir
                avec notre service support ou dans le cadre de la messagerie
                sécurisée et les communications que nous vous adressons, en lien
                avec nos services ou au suivi de votre compte sont traités dans
                ce cadre légal.
              </li>
              <li>
                <span className="font-weight-bold">Intérêt légitime: </span>
                par ailleurs, nous traitons également certaines données sur la
                base de notre intérêt légitime à des fins de gestion et
                sécurisation des accès à notre site internet, de lutte contre la
                fraude et de justification de notre comptabilité.
              </li>
              <li>
                <span className="font-weight-bold">Consentement: </span>
                nous sommes également susceptibles de recueillir votre
                consentement préalable au traitement de certaines données, par
                exemple pour la réutilisation de votre photo de profil, réalisée
                par nos services, sur des supports commerciaux ou lorsque vous
                n&apos;avez pas créé de compte sur la plateforme mais souhaitez
                recevoir nos offres commerciales/notre newsletter.
              </li>
              <li>
                <span className="font-weight-bold">Obligations légales: </span>
                enfin, nous traitons certaines données en raison des obligations
                légales qui nous sont imposées dans le cadre de la gestion des
                paiements réalisés via la plateforme et notamment de la lutte
                contre le terrorisme et le blanchiment d&apos;argent.
              </li>
            </ul>
            <h5 className="h6 display-4">
              Destinataires ou catégorie de destinataires
            </h5>
            <h5 className="h6 display-4">Résumé</h5>
            <p>
              Tribu est destinataire des données à caractère personnel
              recueillies par l&apos;intermédiaire du site.
            </p>
            <p>
              Certains des services et fonctionnalités mis à votre disposition
              par Tribu requièrent que nous partagions des informations avec
              d&apos;autres utilisateurs ou avec nos prestataires. Nous sommes
              également susceptibles de partager ces informations pour des
              raisons juridiques ou en cas de litige.
            </p>
            <h5 className="h6 display-4">Détails</h5>
            <p>
              Tribu est destinataire des données à caractère personnel
              recueillies par l&apos;intermédiaire du site.
            </p>
            <p>
              Nous ne communiquons vos données personnelles à des entreprises,
              des organisations ou des personnes tierces que dans les
              circonstances suivantes :
            </p>
            <ul className="font-weight-light">
              <li>
                Partage avec des grands comptes des informations
                d&apos;identification de votre entreprise lorsque vous leur
                envoyez une proposition commerciale et des documents
                l&eacute;gaux relatifs &agrave; votre soci&eacute;t&eacute;
                lorsqu&apos;un partenariat d&eacute;marre;&nbsp;
              </li>
              <li>
                Lorsque cela est n&eacute;cessaire pour des besoins de
                traitement externe, et uniquement dans ce cas, nous transmettons
                ces donn&eacute;es &agrave; nos prestataires de confiance qui
                les traitent pour notre compte, selon nos instructions ou selon
                un accord contractuel, conform&eacute;ment &agrave; la
                pr&eacute;sente Charte et dans le respect de toute autre mesure
                appropri&eacute;e de s&eacute;curit&eacute; et de
                confidentialit&eacute;. Nous sommes susceptibles de leur
                transmettre vos donn&eacute;es personnelles uniquement pour les
                finalit&eacute;s &eacute;nonc&eacute;es ci-dessous.
              </li>
              <li>
                Lorsque cela est n&eacute;cessaire &agrave; la satisfaction
                d&apos;obligations l&eacute;gales, r&eacute;glementaires,
                &agrave; des proc&eacute;dures judiciaires. Nous conservons ou
                divulguons vos informations si nous estimons que cela est
                raisonnablement n&eacute;cessaire pour satisfaire &agrave; toute
                obligation l&eacute;gale ou r&eacute;glementaire, toute
                proc&eacute;dure juridique ou demande administrative, pour
                prot&eacute;ger la s&eacute;curit&eacute; d&apos;une personne,
                pour traiter tout probl&egrave;me de nature frauduleuse,
                s&eacute;curitaire ou technique, ou pour prot&eacute;ger les
                droits ou les biens de nos utilisateurs.&nbsp;
              </li>
            </ul>
            <p className="font-weight-bold">
              Important: Tribu ne revend à aucun tiers les données, informations
              ou documents que vous nous confiez et ne divulgue pas vos données
              personnelles en dehors des situations décrites dans la présente
              Charte de protection des données personnelles.
            </p>
            <h5 className="h6 display-4">
              Durée de conservation ou critères déterminant ces durées et
              suppression des informations vous concernant
            </h5>
            <h5 className="h6 display-4">Résumé</h5>
            <p>
              Tribu conserve vos informations tant que votre compte reste actif,
              à moins que vous ne demandiez leur suppression ou celle de votre
              compte. Dans certains cas, nous pouvons conserver des informations
              vous concernant en raison de la loi ou à d&apos;autres fins, comme
              décrit dans cette partie, même si vous supprimez votre compte.
            </p>
            <h5 className="h6 display-4">Détail</h5>
            <p>
              Tribu conserve vos informations tant que votre compte reste actif,
              à moins que vous ne demandiez leur suppression ou celle de votre
              compte. Sous réserve des exceptions indiquées ci-dessous, Tribu
              supprime vos informations ou les rend anonymes sur demande.
            </p>
            <p>
              Nous ne conservons vos informations que dans les circonstances
              suivantes :
            </p>
            <ul className="font-weight-light">
              <li>
                Si vous avez &eacute;t&eacute; abonn&eacute;, Tribu conserve vos
                coordonn&eacute;es de facturation pour une dur&eacute;e de 10
                ans &agrave; compter de la cl&ocirc;ture/suppression de votre
                compte utilisateur afin de respecter nos obligations comptables,
                de paiement des charges sociales ainsi que nos obligations
                fiscales.&nbsp;
              </li>
              <li>
                Pour les courtiers uniquement, Tribu conserve &eacute;galement,
                pour une dur&eacute;e de 10 ans, la documentation l&eacute;gale
                permettant la v&eacute;rification de votre entreprise afin de
                r&eacute;pondre aux obligations l&eacute;gales des grands
                comptes.&nbsp;
              </li>
            </ul>
            <h5 className="h6 display-4">Sécurité des données</h5>
            <p>
              Nous mettons en œuvre les mesures techniques et organisationnelles
              appropriées afin de garantir la sécurité, la confidentialité,
              l&apos;intégrité, la disponibilité et la résilience constantes de
              notre système d&apos;information et de vos données et protéger les
              données à caractère personnelle contre la destruction, la perte,
              l&apos;altération, la divulgation non autorisée ou l&apos;accès
              non autorisé.
            </p>
            <p>
              Nous nous engageons à mettre en œuvre tous les moyens disponibles
              pour assurer la sécurité et la confidentialité de ces données, en
              particulier :
            </p>
            <ul className="font-weight-light">
              <li>
                Nous chiffrons la plupart de nos services &agrave; l&apos;aide
                de la technologie SSL;&nbsp;
              </li>
              <li>
                Nous v&eacute;rifions votre adresse email lors de la
                cr&eacute;ation de votre compte;&nbsp;
              </li>
              <li>
                Nous menons des audits internes sur la collecte, le stockage et
                le traitement des donn&eacute;es, y compris les mesures de
                s&eacute;curit&eacute; physique, afin d&apos;emp&ecirc;cher tout
                acc&egrave;s non autoris&eacute; &agrave; nos
                syst&egrave;mes;&nbsp;
              </li>
              <li>
                L&apos;acc&egrave;s aux donn&eacute;es personnelles est
                strictement r&eacute;serv&eacute; aux salari&eacute;s et
                prestataires de Tribu ayant besoin d&apos;en connaitre. Ces
                personnes sont soumises &agrave; de strictes obligations de
                confidentialit&eacute; et sont susceptibles de faire
                l&apos;objet de sanctions disciplinaires pouvant aller
                jusqu&apos;au licenciement en cas de manquement &agrave; ces
                obligations;&nbsp;
              </li>
            </ul>
            <h5 className="h6 display-4">
              Transfert de données vers un pays tiers
            </h5>
            <p>
              Tous nos serveurs sur lesquels vos données sont conservées et ceux
              des prestataires utilisés pour échanger et stocker ces données
              sont localisés en Europe. Très exceptionnellement, certains de nos
              prestataires dont l&apos;établissement principal se situe dans un
              État tiers à l&apos;Union européenne peuvent devoir transférer vos
              données vers cet État. Dans ce cas, nous vérifions préalablement
              qu&apos;ils respectent leurs obligations en matière de protection
              des données.
            </p>
            <p>Ainsi, </p>
            <ul className="font-weight-light">
              <li>
                <a href="https://policies.google.com/privacy/frameworks?hl=en">
                  Google
                </a>
                , adhère au Privacy Shield, un ensemble de règles de protection
                des données équivalentes à celles prévues au sein de l&apos;UE,
              </li>
            </ul>
          </Container>

          <div className="header bg-gradient-info py-6">
            <Container className="pb-3 text-white col-md-7">
              <h5 className="h6 display-3 text-white text-center">Droits</h5>
              <p>
                Conformément aux dispositions de la loi dite « informatique et
                libertés » du 6 janvier 1978 modifiée et au règlement général
                relatif à la protection des données, vous disposez d&apos;un
                droit d&apos;accès, de rectification, d&apos;effacement, de
                limitation, d&apos;opposition et à la portabilité des données à
                caractère personnel vous concernant.
              </p>
              <p>
                Vous pouvez retrouver les descriptifs de chacun de vos droits
                dans le centre de protection des données que nous mettons à
                votre disposition à l&apos;adresse
                <Link to={PDD}> tribu.app/pdd</Link>. Vous y trouverez également
                les moyens de les exercer.
              </p>
              <h5 className="h6 display-4 text-white">Droit d&apos;accès</h5>
              <p>
                Vous disposez d&apos;un droit d&apos;accès aux données vous
                concernant traitées par Tribu. Il vous permet de prendre
                connaissance des données dont nous disposons sur vous et, si
                vous le souhaitez, d&apos;en demander une copie.
              </p>
              <h5 className="h6 display-4 text-white">
                Droit à la rectification
              </h5>
              <p>
                Aux termes de la réglementation applicable en matière de
                protection des données personnelles, vous pouvez solliciter de
                notre part la rectification des données à caractère personnel
                vous concernant qui sont inexactes. Vous pouvez également
                demander à ce que les données à caractère personnel incomplètes
                vous concernant soient complétées, y compris en fournissant, à
                l&apos;appui, une déclaration complémentaire.
              </p>
              <p>
                Afin de vous permettre d&apos;exercer le plus facilement
                possible ce droit, nous vous invitons à procéder directement à
                ces modifications et compléments sur votre profil. Si vous
                estimez que d&apos;autres données vous concernant doivent être
                modifiées ou complétées et que vous ne parvenez pas à effectuer
                ce changement par vous-même, nous vous invitons à en faire la
                demande directement dans la section &quot;vos droits&quot; de
                notre centre de protection des données.
              </p>
              <h5 className="h6 display-4 text-white">
                Droit à l&apos;effacement
              </h5>
              <p>
                Vous disposez du droit d&apos;obtenir de notre part
                l&apos;effacement, dans les meilleurs délais, de données à
                caractère personnel vous concernant sous réserve de notre
                intérêt légitime ou de toute obligation légale nous en imposant
                la conservation.
              </p>
              <p>
                Pour des raisons de sécurité et pour éviter une suppression non
                souhaitée du compte, nous vous invitons à effectuer cette
                démarche par vous-même une fois connecté à votre compte en
                suivant ce lien :
                <Link to="/settings" className="text-default">
                  {" "}
                  tribu.app/user/settings
                </Link>
              </p>
              <p>
                S&apos;agissant des données de votre profil, vous devez garder à
                l&apos;esprit que les moteurs de recherche et autres tierces
                parties peuvent encore conserver pour un certain temps des
                copies de vos informations de profil publiques lorsque vous en
                aviez accepté le référencement, même après avoir supprimé les
                informations du site Tribu ou désactivé votre compte. Si vous le
                souhaitez, vous pouvez exercer votre droit au déréférencement
                directement auprès de ces moteurs de recherche. Par exemple,
                pour effectuer cette demande auprès de Google, vous pouvez
                utiliser l&apos;outil mis à disposition par le moteur de
                recherche
                <a
                  href="https://www.google.com/webmasters/tools/removals"
                  className="text-default"
                >
                  {" "}
                  ici
                </a>
                .
              </p>
              <h5 className="h6 display-4 text-white">
                Droit à la limitation du traitement
              </h5>
              <p>
                Vous pouvez solliciter de Tribu la limitation du traitement de
                vos données (par exemple, le fait de rendre temporairement votre
                profil indisponible sur le site), lorsque l&apos;un des éléments
                suivants s&apos;applique:
              </p>
              <p>
                a) vous contestez l&apos;exactitude des données à caractère
                personnel vous concernant. Dans ce cas, nous masquerons les
                données vous concernant, pendant une durée nous permettant
                d&apos;en vérifier l&apos;exactitude;
              </p>
              <p>
                b) vous estimez que le traitement de vos données est mis en
                oeuvre de manière illicite et vous opposez à leur effacement et
                exigez à la place la limitation de leur utilisation;
              </p>
              <p>
                c) nous n&apos;avons plus besoin des données à caractère
                personnel vous concernant aux fins du traitement mais celles-ci
                sont encore nécessaires pour vous permettre de faire constater,
                d&apos;exercer ou de défendre un droit en justice;
              </p>
              <p>
                d) vous avez exercé votre droit d&apos;opposition en vertu de
                l&apos;article 21, paragraphe 1 du règlement général relatif à
                la protection des données. Nous procéderons à la limitation du
                traitement de vos données, pendant la vérification portant sur
                le point de savoir si les motifs légitimes que nous poursuivons
                prévalent sur votre droit.
              </p>
              <p>
                Si nous décidons de lever la limitation du traitement de vos
                données personnelles, nous vous en tiendrons informé.
              </p>
              <h5 className="h6 display-4 text-white">
                Droit à l&apos;opposition
              </h5>
              <p>
                Vous pouvez vous opposer à l&apos;envoi de communications,
                notamment commerciales, par Tribu. Nous mettons à cette fin à
                votre disposition un lien de désinscription dans tous les
                courriels que nous vous adressons et vous pouvez gérer vos
                préférences de notification directement dans notre centre de
                protection des données
                <Link to={PDD} className="text-default">
                  {" "}
                  tribu.app/pdd
                </Link>
                .
              </p>
              <p>
                Vous pouvez également vous opposer, pour des motifs légitimes,
                au traitement de vos données, sauf si celui-ci répond à une
                obligation légale s&apos;imposant à Tribu (par exemple,
                déclarations fiscales, déclarations de charges sociales…).
              </p>
              <h5 className="h6 display-4 text-white">
                Droit à la portabilité
              </h5>
              <p>
                Vous pouvez à tout moment demander la portabilité de vos données
                Tribu. Nous nous engageons à vous transmettre dans un délai
                raisonnable et dans un format lisible par machine les données
                que vous nous aurez fournies qu&apos;elles aient été déclarées
                par vous-même ou générées par votre activité sur la plateforme.
              </p>
              <h5 className="h6 display-4 text-white">
                Droit de retrait du consentement
              </h5>
              <p>
                Vous avez le droit de refuser à tout moment le traitement de vos
                données personnelles pour des raisons personnelles ainsi que le
                droit de refuser le traitement de vos données personnelles à des
                fins de marketing direct.
              </p>
              <h5 className="h6 display-4 text-white">
                Droit d&apos;introduire une réclamation auprès d&apos;une
                autorité de contrôle :
              </h5>
              <p>
                L&apos;autorité de contrôle compétente pour connaître de toute
                demande nous concernant, y compris, le cas échéant, de la
                plainte d&apos;un utilisateur, est la Commission Nationale de
                l&apos;Informatique et des Libertés (CNIL).
              </p>
            </Container>
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon className="fill-white" points="0 0 2560 100 0 100" />
              </svg>
            </div>
          </div>

          <Container className="py-4 col-md-7">
            <h5 className="h6 display-3 text-center">En savoir plus</h5>
            <p>
              Vous trouverez d&apos;autres ressources utiles liées à la
              confidentialité et à la protection de vos données dans notre
              Centre de Protection des données :
            </p>
            <ul className="font-weight-light">
              <li>
                notre parti pris et les actions que nous menons afin de
                prot&eacute;ger au mieux vos donn&eacute;es;&nbsp;
              </li>
              <li>
                des informations sur la mani&egrave;re dont nous utilisons les
                cookies et autres traceurs;&nbsp;
              </li>
              <li>
                des informations sur les modalit&eacute;s d&apos;exercice de vos
                droits;&nbsp;
              </li>
            </ul>
            <p>
              Pour toute question concernant la protection de vos données
              personnelles traitées par Tribu ou pour exercer vos droits
              d&apos;accès, rectification, effacement, limitation ou portabilité
              de vos données, vous pouvez vous adresser à notre Délégué à la
              Protection des Données :
            </p>
            <ul className="font-weight-light">
              <meta name="robots" content="noindex" />
              <li>par e-mail : dpo@tribu.app</li>
            </ul>
          </Container>
        </div>
      </Aux>
    );
  }
}

export default Pdd;
