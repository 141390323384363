import PropTypes from 'prop-types';
import React from 'react';
import classes from '../../assets/Scss/components/CollpaseArrow.scss';

const collapseButton = (props) => {
    const {
        collapsed,
        collapse,
    } = props;

    return (
        <div className={classes.Collapse}>
            <i className={`ni ni-bold-down text-primary ${collapsed ? null : classes.opened}`} onClick={collapse} />
        </div>
    );
};


export default collapseButton;

collapseButton.propTypes = {
    collapse: PropTypes.func,
    collapsed: PropTypes.bool,
};

collapseButton.defaultProps = {
    collapse: () => {},
    collapsed: false,
};
