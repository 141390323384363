import * as actionTypes from '../actionConsts';
import { DISCONNECTED } from '../../router/Roles';

const initialState = {
    error: null,
    isFetching: false,
    fetched: false,
    user: {
        id: null,
        profilePic: '',
        firstName: '',
        lastName: '',
        mail: '',
        roles: [DISCONNECTED],
        followings: [],
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_USER_ERROR:
            return { ...state, error: action.error };

        case actionTypes.SET_USER_FETCHING:
            return {
                ...state,
                isFetching: action.isFetching,
            };

        case actionTypes.SET_USER_FETCHED:
            return {
                ...state,
                isFetched: action.isFetched,
            };

        case actionTypes.SET_USER:
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.user,
                },
            };


        default:
            return state;
    }
};

export default reducer;
