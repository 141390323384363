import { Link } from "react-router-dom";
import Aux from "../../../hoc/Aux";
import PageComponent from "../PageComponent";
import "../custom.css";

function Posts() {
  return (
    <Aux>
      <PageComponent>
        <h1 className="help-item-title">Publications</h1>
        <p className="help-item-text">
          Tribu permet aux courtiers et à leurs fournisseurs d'échanger grâce à
          des publications régulières. Ces messages sont publiés sur votre fil
          d’actualités et sont visibles par vos abonnés.
          <p className="help-item-subtitle">Comment publier des posts ?</p>
          Un post est un message public sur Tribu, qui peut contenir des photos,
          des vidéos, des liens et du texte. Cliquez sur le bouton "Écrire une
          publication" pour publier un message sur votre fil d’actualités. Notez
          qu’il est possible de modifier un post après sa publication.
          <br />
          <br />
          Vous pouvez poster en tant que profil et retrouverez l’ensemble de vos
          posts depuis l’onglet Publications de votre profil. Vous pouvez
          également poster en tant qu’entreprise et retrouverez l’ensemble de
          vos posts depuis l’onglet Publications de votre page entreprise.
          <p className="help-item-subtitle">Comment aimer un post ?</p>
          Les J'aime sont représentés par un petit cœur et servent à indiquer
          qu'un utilisateur aime un post.
          <p className="help-item-subtitle">Qu'est-ce qu'un repost ?</p>
          Un repost consiste à republier un post. La fonctionnalité "Reposter"
          de Tribu permet aux utilisateurs de partager rapidement une
          publication avec tous leurs abonnés. Vous pouvez reposter vos propres
          publications ou celles des autres. Vous pouvez voir les posts repostés
          par des utilisateurs que vous suivez dans votre fil d'actualités.
          <p className="help-item-subtitle">Qu'est-ce qu'une réponse ?</p>
          Une réponse est une réaction à un post d'une autre personne. Cliquez
          sur l'icône "Répondre" figurant dans la publication d'une autre
          personne pour y répondre. Une réponse est un message public, visible
          aussi bien par vos abonnés que par des personnes qui ne vous suivent
          pas.
          <p className="help-item-subtitle">Comment supprimer des posts ?</p>
          Vous pouvez supprimer vos propres posts de Tribu à tout moment. Vous
          ne pouvez pas supprimer des posts publiés par d'autres comptes. En
          revanche, pour ne plus recevoir les publications d'un compte, vous
          pouvez vous désabonner de celui-ci.
          <p className="help-item-subtitle">Comment signaler du contenu ?</p>
          Faire de Tribu un endroit sûr pour notre communauté est une priorité
          absolue.
          <br />
          <br />
          Si vous rencontrez du contenu qui, selon vous, enfreint les règles de
          la communauté, nous vous encourageons à signaler sa diffusion en
          sélectionnant l'icône "..." dans le post. Cliquez sur "Signaler le
          post". Une boîte de dialogue s’ouvre, complétez et cliquer sur
          "Signaler" pour confirmer votre signalement.
        </p>
      </PageComponent>
    </Aux>
  );
}

export default Posts;
