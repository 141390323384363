import { SET_SEARCH_PENDING, SET_SEARCH_RESULTS } from '../actionConsts';

const initialState = {
    isPending: false,
    results: [],
};

const searchReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SEARCH_PENDING:
            return { ...state, isPending: action.isPending };

        case SET_SEARCH_RESULTS:
            return { ...state, results: action.results };

        default:
            return state;
    }
};

export default searchReducer;
