import {
  SET_RECENTS_CHATS,
  SET_RECENTS_CHATS_2,
  SET_SELECTED_TABS,
  SET_RECENTS_GROUPED_CHATS
} from "./types";

const initialState = {
  isPending: false,
  error: null,
  chats: [],
  groupedChats: [],
  chats2: [],
  chatTabs: ["Messages", "Envoyés en groupe"],
  selectedChatTab: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_RECENTS_CHATS:
      return { ...state, chats: action.chats };
    case SET_RECENTS_GROUPED_CHATS:
      return { ...state, groupedChats: action.groupedChats };
    case SET_RECENTS_CHATS_2:
      return { ...state, chats2: action.chats };
    case SET_SELECTED_TABS:
      return { ...state, selectedChatTab: action.selectedTab };
    default:
      return state;
  }
};

export default reducer;
