import { storage } from '../../config/firebase';

export const uploadImage = (ref, file) => {
    const uploadTask = storage.child(`${ref}/${file.name}`).put(file);

    return new Promise((resolve, reject) => {
        uploadTask.on('state_changed', (snapshot) => {
            // eslint-disable-next-line no-unused-vars
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (err) => reject(err),
        () => uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => resolve(downloadURL)));
    })
        .then((res) => res);
};
