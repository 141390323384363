import {
    SET_COMPANY,
    SET_COMPANY_EDITING_SECTION,
    SET_COMPANY_ERROR,
    SET_COMPANY_PENDING,
} from './types';

const initialState = {
    company: {
        informations: {},
    },
    isPending: true,
    isSuccess: false,
    error: null,

    editingSection: '',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COMPANY_PENDING:
            return { ...state, isPending: action.isPending };

        case SET_COMPANY:
            return {
                ...state,
                company: { ...state.company, ...action.company },
            };

        case SET_COMPANY_ERROR:
            return { ...state, error: action.error };

        case SET_COMPANY_EDITING_SECTION:
            return { ...state, editingSection: action.editingSection === state.editingSection ? '' : action.editingSection };

        default:
            return state;
    }
};

export default reducer;
