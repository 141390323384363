import React from 'react';
import {
    Badge,
    DropdownMenu, DropdownToggle, ListGroup, ListGroupItem, Row, UncontrolledDropdown,
} from 'reactstrap';
import classes from '../../assets/Scss/components/Notifications.scss';

const DefaultNotifications = () => {
    let body = '';

    if (!body.length) {
        body = (
            <ListGroupItem className="list-group-item-action">
                <Row className="align-items-center justify-content-center">
                    <p className="mb-0 font-weight-500">
                        Aucune notification.
                    </p>
                </Row>
            </ListGroupItem>
        );
    }

    const badge = [].length > 0 && <Badge color="primary" className={`${classes.notify} badge-md badge-circle badge-floating border-white`}>0</Badge>;

    return (
        <UncontrolledDropdown nav className={classes.Notifications}>
            <DropdownToggle className="nav-link cursor-pointer" color="" tag="a">
                <span>
                    <i className="ni ni-bell-55" />
                    {badge}
                </span>
            </DropdownToggle>
            <DropdownMenu
                className="dropdown-menu-xl py-0 overflow-hidden"
            >
                <div className="px-3 py-3">
                    <h6 className="text-sm text-muted m-0">Notifications</h6>
                </div>
                <ListGroup flush>
                    {body}
                </ListGroup>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};

export default DefaultNotifications;
