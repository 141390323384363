const CATEGORIES = [
  { category: "IOBSP" },
  { category: "IAS" },
  { category: "CIF" },
  { category: "ALPSI" },
  { category: "CIP" },
  { category: "IFP" },
];

export default CATEGORIES;
