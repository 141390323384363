// Login
export const SET_SIGNIN_PENDING = 'SET_SIGNIN_PENDING';
export const SET_SIGNIN_SUCCESS = 'SET_SIGNIN_SUCCESS';
export const SET_SIGNIN_ERROR = 'SET_SIGNIN_ERROR';

export const SET_SIGNUP_PENDING = 'SET_SIGNUP_PENDING';
export const SET_SIGNUP_SUCCESS = 'SET_SIGNUP_SUCCESS';
export const SET_SIGNUP_ALREADY = 'SET_SIGNUP_ALREADY';
export const SET_SIGNUP_ERROR = 'SET_SIGNUP_ERROR';
export const LOGOUT = 'LOGOUT';

// user
export const SET_USER_ERROR = 'SET_USER_ERROR';
export const SET_USER_FETCHING = 'SET_USER_FETCHING';
export const SET_USER_FETCHED = 'SET_USER_FETCHED';
export const SET_USER = 'SET_USER';

// search
export const SET_SEARCH_PENDING = 'SET_SEARCH_PENDING';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';

// society
export const SET_ORIAS_PENDING = 'SET_ORIAS_PENDING';
export const SET_ORIAS_SUCCESS = 'SET_ORIAS_SUCCESS';
export const SET_ORIAS_ERROR = 'SET_ORIAS_ERROR';

export const SET_SOCIETY_PENDING = 'SET_SOCIETY_PENDING';
export const SET_SOCIETY = 'SET_SOCIETY';
export const SET_SOCIETY_ERROR = 'SET_SOCIETY_ERROR';

// visits
export const SET_NB_VISITS = 'SET_NB_VISITS';
export const SET_VISITS = 'SET_VISITS';
export const SET_VISITS_FETCHING = 'SET_VISITS_FETCHING';
export const SET_VISITS_STATS_FETCHING = 'SET_VISITS_STATS_FETCHING';
export const SET_VISITS_STATS = 'SET_VISITS_STATS';

// invitations
export const SET_INVITATIONS_FETCHING = 'SET_INVITATIONS_FETCHING';
export const SET_NB_INVITATIONS = 'SET_NB_INVITATIONS';
export const SET_INVITATIONS_SENT = 'SET_INVITATIONS_SENT';
export const SET_INVITATIONS_RECEIVED = 'SET_INVITATIONS_RECEIVED';

// navbar
export const SET_NAVBAR_OPENED = 'SET_NAVBAR_OPENED';

// POPOVER
export const SET_POPOVER_LOADING = 'SET_POPOVER_LOADING';
export const SET_POPOVER_INFO = 'SET_POPOVER_INFO';


// TO DO List
export const FETCH_TASKS = 'FETCH_TASKS';

// calendar
export const SET_CALENDAR_FETCHING = 'SET_CALENDAR_FETCHING';
export const SET_CALENDAR_INFO_FETCHING = 'SET_CALENDAR_INFO_FETCHING';
export const ADD_CALENDAR_EVENTS = 'ADD_CALENDAR_EVENTS';
export const DELETE_CALENDAR_EVENT = 'DELETE_CALENDAR_EVENT';
