// React Cores
import PropTypes from 'prop-types';
import React, { Component } from 'react';
// components
import {
    Container,
    Col,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

class DashboardHeader extends Component {
    renderBreadcrumb(links) {
        const breadcrumb = [];
        breadcrumb.push(
            <Link to="/" key="breadcrumb_home">
                <i className="fas fa-home text-white" />
            </Link>,
        );

        links.forEach((link, key) => {
            const index = `breadcrumbLink_${key}`;

            breadcrumb.push(
                link.link ?
                    (
                        <Link key={index} to={link.link} className={`text-white ${links.length === key + 1 ? 'font-weight-600' : null}`}>
                            {` > ${link.name.charAt(0).toUpperCase() + link.name.slice(1)} `}
                        </Link>
                    ) : (
                        <span key={index} className={`text-white ${links.length === key + 1 ? 'font-weight-600' : null}`}>
                            {` > ${link.name.charAt(0).toUpperCase() + link.name.slice(1)} `}
                        </span>
                    ),
            );
        });

        if (links.length === 0) {
            const { location } = this.props;
            const pathList = location.pathname.split('/').slice(1);

            pathList.forEach((link, key) => {
                const index = `breadcrumbLink_${key}`;

                breadcrumb.push(
                    <span key={index} className={`text-white ${pathList.length === key + 1 ? 'font-weight-600' : null}`}>
                        {` > ${link.charAt(0).toUpperCase() + link.slice(1)} `}
                    </span>,
                );
            });
        }

        return breadcrumb;
    }

    render() {
        const {
            links,
            breadcrumb,
            user,
        } = this.props;

        return (
            <div className={`header ${user.roles.includes("CORPORATE") ? "bg-gradient-warning" : "bg-gradient-info"} pb-4 pt-3 pt-3`}>
                <Container fluid>
                    <div className="header-body">
                        <Col>
                            {
                                breadcrumb ? this.renderBreadcrumb(links) : <span>&nbsp;</span>
                            }
                        </Col>
                    </div>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.User.user,
});

export default connect(mapStateToProps)(withRouter(DashboardHeader));

DashboardHeader.propTypes = {
    breadcrumb: PropTypes.bool,
    links: PropTypes.array,
};

DashboardHeader.defaultProps = {
    breadcrumb: true,
    links: [],
};
