import React, { useState } from "react";
import { Card, CardBody, Col, Collapse, Container, Row } from "reactstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Aux from "../../hoc/Aux";
import Brand from "../../assets/Pics/Icons/tribu-white.svg";
import { CGUV, PDD } from "../../router/Routes";
import "./custom.css";
import GoldBadge from "../../assets/Pics/goldBadge.svg";
import BlueBadge from "../../assets/Pics/blueBadge.svg";
import PageComponent from "./PageComponent";

const FAQS = [
  {
    question: "Inscription et Paramètres",
    answers: [
      {
        url: "/centre-assistance/premiers-pas-sur-tribu",
        icon: () => (
          <i class="fas fa-exclamation-circle text-primary help-answer-icon"></i>
        ),
        title: "Premiers pas sur Tribu",
      },
      {
        url: "/centre-assistance/activation-du-compte",
        icon: () => (
          <img
            src={BlueBadge}
            className="help-answer-icon help-answer-icon-svg"
          />
        ),
        title: "Activation du compte",
      },
      {
        url: "/centre-assistance/probleme-de-connexion",
        icon: () => (
          <i class="fas fa-sign-in-alt text-primary help-answer-icon"></i>
        ),
        title: "Problème de connexion",
      },
      {
        url: "/centre-assistance/parametres-du-compte",
        icon: () => <i class="fas fa-cog text-primary help-answer-icon"></i>,
        title: "Paramètres du compte",
      },
      {
        url: "/centre-assistance/tribu-premium",
        icon: () => (
          <img
            src={GoldBadge}
            className="help-answer-icon help-answer-icon-svg"
          />
        ),
        title: "Tribu Premium",
      },
    ],
  },
  {
    question: "Profil et Entreprise",
    answers: [
      {
        url: "/centre-assistance/modifier-son-profil",
        icon: () => <i class="fas fa-pen text-primary help-answer-icon"></i>,
        title: "Modifier son profil",
      },
      {
        url: "/centre-assistance/modifier-sa-page-entreprise",
        icon: () => (
          <i class="fas fa-building text-primary help-answer-icon"></i>
        ),
        title: "Modifier sa page entreprise",
      },
      {
        url: "/centre-assistance/donnees-personnelles",
        icon: () => (
          <i class="fas fa-user-shield text-primary help-answer-icon"></i>
        ),
        title: "Données personnelles",
      },
    ],
  },
  {
    question: "Mon Réseau",
    answers: [
      {
        url: "/centre-assistance/tableau-de-bord",
        icon: () => (
          <i class="fas fa-th-list text-primary help-answer-icon"></i>
        ),
        title: "Tableau de bord",
      },
      {
        url: "/centre-assistance/ajouter-un-membre",
        icon: () => (
          <i class="fas fa-user-plus text-primary help-answer-icon"></i>
        ),
        title: "Ajouter un membre",
      },
      {
        url: "/centre-assistance/supprimer-un-contact",
        icon: () => (
          <i class="fas fa-user-slash text-primary help-answer-icon"></i>
        ),
        title: "Supprimer un contact",
      },
      {
        url: "/centre-assistance/ecrire-a-un-contact",
        icon: () => (
          <i class="fas fa-comment-dots text-primary help-answer-icon"></i>
        ),
        title: "Ecrire à un contact",
      },
      {
        url: "/centre-assistance/abonnes-et-abonnements",
        icon: () => <i class="fas fa-users text-primary help-answer-icon"></i>,
        title: "Abonnés et Abonnements",
      },
      {
        url: "/centre-assistance/publications",
        icon: () => <i class="fas fa-heart text-primary help-answer-icon"></i>,
        title: "Publications",
      },
    ],
  },
];

function Faq() {
  function faqsToUse(option) {
    return option === "first"
      ? FAQS.slice(0, Math.floor(FAQS.length / 2) + 1)
      : FAQS.slice(Math.floor(FAQS.length / 2) + 1, FAQS.length);
  }

  return (
    <Aux>
      <Helmet>
        <title>Centre d'assistance Tribu</title>
      </Helmet>
      <PageComponent breadcrumbDisable={true}>
        <Row className="pt-5">
          {FAQS.map((faq, index) => (
            <Col md="4" sm="12" key={faq.text}>
              <Card className="help-card mb-4" key={index}>
                <CardBody>
                  <div className="help-card-title-container">
                    <h4 className="help-card-title">{faq.question}</h4>
                  </div>
                  <hr className="bg-black w-100 my-4" />
                  {faq.answers.map((answer, index) => (
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      className={`${
                        index < faq.answers.length - 1 ? "mb-4" : ""
                      }`}
                    >
                      {answer.icon()}
                      <Link to={answer.url} className="ml-2">
                        <p className={`help-card-text`}>{answer.title}</p>
                      </Link>
                    </div>
                  ))}
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>

        {/* <div className="faq-cards-container">
            <div className="faq-card-left">
              {faqsToUse("first").map((faq, index) => (
                <Card className="faq-card mb-4" key={index}>
                  <CardBody>
                    <div
                      className="faq-card-title-container"
                      onClick={() => handleSetFaqsOpened(index)}
                    >
                      <h4 className="faq-card-title">{faq.question}</h4>
                      <i
                        className={`fas ml-1 ${
                          faqsOpened[index]
                            ? "fa-chevron-up"
                            : "fa-chevron-down"
                        }`}
                      ></i>
                    </div>
                    <Collapse isOpen={faqsOpened[index]}>
                      <hr className="bg-black w-100 my-4" />
                      {faq.answers.map((answer, index) => (
                        <Row>
                          <Col className="col">{faq?.icon}</Col>
                          <Col>
                            <Link to={answer.url}>
                              <p
                                className={`faq-card-text ${
                                  index < faq.answers.length - 1 ? "mb-2" : ""
                                }`}
                              >
                                {answer.text}
                              </p>
                            </Link>
                          </Col>
                        </Row>
                      ))}
                    </Collapse>
                  </CardBody>
                </Card>
              ))}
            </div>
            <div className="faq-card-right">
              {faqsToUse("second").map((faq, index) => (
                <Card className="faq-card mb-4" key={index}>
                  <CardBody>
                    <div
                      className="faq-card-title-container"
                      onClick={() =>
                        handleSetFaqsOpened(index + faqsToUse("first").length)
                      }
                    >
                      <h4 className="faq-card-title">{faq.question}</h4>
                      <i
                        className={`fas ml-1 ${
                          faqsOpened[index + faqsToUse("first").length]
                            ? "fa-chevron-up"
                            : "fa-chevron-down"
                        }`}
                      ></i>
                    </div>
                    <Collapse
                      isOpen={faqsOpened[index + faqsToUse("first").length]}
                    >
                      <hr className="bg-black w-100 my-4" />
                      {faq.answers.map((answer, index) => (
                        <Link to={answer.url}>
                          <p
                            className={`faq-card-text ${
                              index < faq.answers.length - 1 ? "mb-2" : ""
                            }`}
                          >
                            {answer.text}
                          </p>
                        </Link>
                      ))}
                    </Collapse>
                  </CardBody>
                </Card>
              ))}
            </div>
          </div> */}
      </PageComponent>
    </Aux>
  );
}

export default Faq;
