import React, { Component } from "react";
import Scrollspy from "react-scrollspy";
import { Col, Container, Row } from "reactstrap";
import { scroller } from "react-scroll";
import { Helmet } from "react-helmet";
import classes from "../../assets/Scss/components/BecomeBroker.scss";
import GetInTouchForm from "../GetInTouch/GetInTouchForm";
import config from "../../config/config";
import Img from '../../assets/Pics/tribu-devenir-courtier.svg';
import '../../App.css' 

class BecomeBroker extends Component {
  myRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      currentSection: "section-1",
    };
  }

  handleNewSection = (data) => {
    if (data) {
      this.setState({ currentSection: data.id });
    }
  };

  scrollToHandler = (e) => {
    e.preventDefault();
    const { currentSection } = this.state;
    const newSection = currentSection.split("-");
    newSection[1] = parseInt(newSection[1], 10) + 1;

    scroller.scrollTo(newSection.join("-"), {
      duration: 500,
      delay: 100,
      smooth: true,
    });

    this.setState({ currentSection: newSection.join("-") });
  };

  render() {
    const { currentSection } = this.state;

    return (
      <div className={classes.BecomeBroker}>
        <Helmet>
          <title>Devenir courtier en crédit immobilier et assurances avec Tribu</title>
          <meta
            name="description"
            content="De la création de votre société, en passant par la formation à l’Orias, Tribu
accompagne les entrepreneurs qui souhaitent devenir courtier en crédit et assurances."
          />
          <meta
            name="keywords"
            content="devenir courtier, devenir courtier credit, devenir courtier assurance, devenir courtier
            rachat credits, devenir conseil en gestion de patrimoine, devenir conseiller en gestion de patrimoine,
            devenir gestionnaire de patrimoine, devenir CGP, franchise courtier, franchise courtier credit,
            franchise courtier assurance, franchise courtier rachat credits, franchise courtage, franchise courtage
            credit, franchise courtage assurance, franchise courtage rachat credits, franchise gestion de
            patrimoine, formation orias, formation courtier credit, formation courtage credit, formation courtier
            iobsp, formation courtage iobsp, formation iob, formation courtier assurance, formation courtage
            assurance, formation courtier ias, formation courtage ias, formation ias, formation cif, formation
            gestion de patrimoine, formation cgp"
          />
        </Helmet>
        <Scrollspy
          className="d-none"
          items={[
            "section-1",
            "section-2",
            "section-3",
            "section-4",
            "section-5",
            "section-6",
          ]}
          onUpdate={this.handleNewSection}
        />
        {/* <section
          className={`bg-gradient-info ${classes.section} ${classes.section1}`}
          id="section-1"
        >
          <Row className="justify-content-center align-items-center h-100 m-0">
            <Col className="text-white text-center">
              <h3
                className="display-2 text-white mt-5 font-weight-light"
                style={{ lineHeight: "1.3", paddingTop: 50 }}
              >
                Pourquoi devenir
                <br />
                <span className="font-weight-bold">Courtier ?</span>
              </h3>
            </Col>
          </Row>
        </section> */}
        <section className={`header bg-gradient-info py-9 ${classes.About}`}>
          <Container>
            <Row className="justify-content-center">

              

              <img
               style={{ transform: 'translate(0, -60px)' }}
                className="mb-3 bg-title-image"
                alt="tribu devenir courtier"
                src={Img}
              />
            </Row>
            <Row className="justify-content-center" style={{ paddingBottom: 30 }}>

              <Col xs={12}>
                <h5 className="display-3 text-center text-white"
                  style={{ textShadow: '0px 0px 20px rgba(0,0,0,0.4)' }}
                >
                  Pourquoi devenir Courtier ?
                </h5>
              </Col>
              {/* <Col className="mt-3" md={6}>
                <p className="h2 text-center text-white font-weight-normal" >
                  En 10 ans, le nombre de courtiers a doublé.
                  <br />
                  Notre plateforme permet de collaborer en toute sécurité, de la première prise de contact jusqu&#39;au
                  partenariat.
                  <br />
                  Fin 2021 l’ORIAS, le registre unique des intermédiaires en assurance, banque et finance,
                  comptait plus de 119.000 inscriptions (toutes catégories confondues).
                </p>
              </Col> */}
            </Row>
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </Container>
        </section>
        <section className="bg-white" style={{
          zIndex: 2,
          position: "relative",
        }}>
          <Container className="py-6">
            <Row className="justify-content-center">
              <Col xs={12}>
                <h5 className="display-3 text-center">
                  La tendance est à l&#39;indépendance.
                </h5>
              </Col>
              <Col className="mt-3" md={8}>
                <p className="h2 text-center font-weight-normal" >

                  Et à la féminisation de la profession, avec plus de 30% de femmes.
                  <br />
                  En 10 ans, le nombre de courtiers a doublé.
                  <br />   
                  Fin 2021 l’ORIAS, le registre unique des intermédiaires en assurance, banque et finance,
                  comptait plus de 119.000 inscriptions (toutes catégories confondues).
                 
                 
                </p>
              </Col>
            </Row>
            <Row className="justify-content-center">
            <Col  md={6}>
              
                <p className="h2 text-center font-weight-normal" >

               
                  Notre plateforme permet de collaborer en toute sécurité, de la première prise de contact jusqu&#39;au
                  partenariat.
                 
                </p>
              </Col>
            </Row>
          </Container>
        </section>
       
        <section className="header bg-gradient-info">
          <Container className="pt-6 pb-8">
            <Row className="justify-content-center">
              <Col xs={12}>
                <h5 className="display-3 text-center text-white">
                  Courtier indépendant ne veut plus dire seul.
                </h5>
              </Col>
              <Col className="mt-3" md={6}>
                <p className="h2 text-center text-white font-weight-normal" >
                  Nous sommes là pour vous aider à faire grandir votre activité.

                </p>
              </Col>
            </Row>
            {/*             
              <h5 className="display-3 font-weight-normal text-center text-white">
                Prenez part à l&apos;aventure,
                <span className="font-weight-bold d-inline"> rejoignez la team Tribu !</span>
              </h5>
              <Row className="justify-content-center mt-4">
                <Button className="btn-secondary text-primary px-5" onClick={() => this.setState({ signupModal: !signupModal })}>INSCRIVEZ-VOUS</Button>
              </Row> */}

            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="0 0 2560 100 0 100"
                />
              </svg>
            </div>
          </Container>
        </section>
        <section className="bg-white">
          <Container className="py-6">
            <Row className="justify-content-center">
              <Col xs={12}>
                <h5 className="display-3 text-center">
                  Et vous ?
                </h5>
              </Col>
              <Col className="mt-3" md={6}>
                <p className="h2 text-center font-weight-normal" >
                  Prêts à sauter le pas pour rejoindre la grande communauté des courtiers avec Tribu ?

                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section
          className="section py-3 section-lg bg-white header"
          id="section-6"
        >
          <Container>
            <Row className="justify-content-center">
              <Col className="my-auto pb-4 pb-md-auto" xs={12} md={6}>
                <h5 className="h1 display-2 my-0">
                  3 mois pour devenir courtier ?
                </h5>
                <hr className="bg-white w-50 text-left my-2" />
                <p className="display-4 font-weight-300">
                  <span className="font-weight-400">
                    C’est possible avec Tribu !
                  </span>
                  <span className="mt-1">
                    Indépendant avec le soutien d&apos;une grande famille.
                  </span>
                </p>
              </Col>
              <Col lg="6">
                <GetInTouchForm becomeBroker />
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    );
  }
}

export default BecomeBroker;