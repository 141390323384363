import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classes from '../../assets/Scss/components/FileInput.scss';
import { Button, Label } from 'reactstrap';
import { Row } from 'reactstrap';
import { Col } from 'reactstrap';

class FileInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inputKey: Math.random().toString(36),
        };
    }

    hiddenFileInput = React.createRef();

    handleClick = event => {
        this.hiddenFileInput.current.click();
    };

    render() {
        const {
            inputKey,
        } = this.state;

        const {
            name,
            handleChange,
            placeholder,
        } = this.props;

        return (
            <div className={classes.FileInput}>
                <Row className='align-items-center'>
                    <Col>
                        <Button onClick={this.handleClick}>
                            Parcourir
                        </Button>
                    </Col>
                    <Label className='mt-3'>{placeholder}</Label>
                </Row>
                <input
                    disabled={!this.props.editing}
                    key={inputKey || ''}
                    type="file"
                    name={name}
                    id={name}
                    onChange={(e) => {
                        handleChange(e);
                        this.setState({ inputKey: Math.random().toString(36) });
                    }}
                    style={{ display: 'none' }}
                    ref={this.hiddenFileInput}
                />
                {/*<Label htmlFor={name}>{placeholder}</Label>*/}
            </div>
        );
    }
}

FileInput.propTypes = {
    handleChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
};

export default FileInput;

FileInput.defaultProps = {
    placeholder: '',
};
