import React, { Component } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import {
    Card,
    CardHeader,
    Col, Row,
} from 'reactstrap';
import { connect } from 'react-redux';
import Signin from './Signin';
import Signup2 from './Signup2';
import Aux from '../../hoc/Aux';
import BannerBlue from '../../assets/Pics/Icons/banner_beta_blue.png';
import SigninHeader from './SigninHeader';
import SignupHeader from './SignupHeader';
import SignupCorporate from './SignupCorporate';
import SignupCorporateHeader from './SignupCorporateHeader';

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            width: window.innerWidth,
        };

        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({ width: window.innerWidth });
    };

    render() {
        const {
            width,
        } = this.state;

        return (
            <Aux>
                <Row className={`bg-secondary h-100vh ${width < 768 ? 'bg-info' : 'bg-white'} m-0`}>
                    <Switch>
                        <Route path="/signin" component={SigninHeader} />
                        <Route path="/signup/corporate" component={SignupCorporateHeader} />
                        <Route path="/signup" component={SignupHeader} />
                    </Switch>
                    <Col xs={12} sm={10} md={6} lg={7} className="my-auto mx-auto">
                        <Card className={`shadow-none border-0 col-xs-12 col-md-8 col-xl-6 mx-auto ${width < 768 ? 'bg-white' : 'bg-transparent'}`}>
                            <CardHeader className="bg-transparent border-0">
                                <Row className="mb-4 d-md-none justify-content-center">
                                    <Col className="text-center" xs={6} sm={4}>
                                        <Link to="/">
                                            <img src={BannerBlue} alt="Tribu" className="w-100" />
                                        </Link>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <Switch>
                                <Route path="/signin" component={Signin} />
                                <Route path="/signup/corporate" component={SignupCorporate} />
                                <Route path="/signup" component={Signup2} />
                            </Switch>
                        </Card>
                    </Col>
                </Row>
            </Aux>
        );
    }
}
const mapStateToProps = (state) => ({
    state,
});

export default connect(mapStateToProps)(Login);
