import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { connect } from "react-redux";
import messageClasses from "../../assets/Scss/components/Chat.scss";
import Aux from "../../hoc/Aux";
import DeleteModal from "./DeleteModal";
import {
  dbReportPost,
  setEditingPost as actionsSetEditingPost,
} from "../../store/posts/actions";
import ReportModal from "../../components/ReportModal";

const PostDropdown = (props) => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isReportOpen, setIsReportOpen] = useState(false);

  const { post, user, setEditingPost, reportPost } = props;

  const { author } = post;
  const { companies } = user;

  const authorOptions = (author.userId === user.id ||
    companies.findIndex((company) => company.id === author.userId) > -1) && (
    <Aux>
      {post.isRepost !== true && (
        <DropdownItem onClick={() => setEditingPost(post.id)}>
          Éditer le post
        </DropdownItem>
      )}
      <DropdownItem onClick={() => setIsDeleteOpen(true)}>
        Supprimer le post
      </DropdownItem>
    </Aux>
  );

  return (
    <Aux>
      <ReportModal
        isOpen={isReportOpen}
        toggle={() => setIsReportOpen(!isReportOpen)}
        onSubmit={(text) => reportPost(post.id, text)}
        title="Signaler une publication"
      />
      <DeleteModal
        isOpen={isDeleteOpen}
        toggle={() => setIsDeleteOpen(!isDeleteOpen)}
        postId={post.id}
        isCompany={author.isCompany}
        authorId={author.userId}
      />
      <Dropdown isOpen={isMenuOpen} toggle={() => setIsMenuOpen(!isMenuOpen)}>
        <DropdownToggle
          tag="span"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          data-toggle="dropdown"
          className={`${messageClasses.dropdown} ml-2 btn-sm`}
          style={{ cursor: "pointer" }}
        >
          <i
            className="fas fa-ellipsis-h text-gray"
            style={{ fontSize: "15px" }}
          />
        </DropdownToggle>
        <DropdownMenu right>
          {authorOptions}
          <DropdownItem onClick={() => setIsReportOpen(true)}>
            Signaler le post
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </Aux>
  );
};

const mapStateToProps = (state) => ({
  user: state.User.user,
});

export default connect(mapStateToProps, {
  setEditingPost: actionsSetEditingPost,
  reportPost: dbReportPost,
})(PostDropdown);

PostDropdown.propTypes = {
  post: PropTypes.object,
  reportPost: PropTypes.func,
  setEditingPost: PropTypes.func,
  user: PropTypes.object,
};

PostDropdown.defaultProps = {
  post: {},
  reportPost: () => {},
  setEditingPost: () => {},
  user: {},
};
