import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
    Badge,
    Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row,
} from 'reactstrap';
import ReactDatetime from 'react-datetime';
import FileInput from './FileInput';
import Aux from '../../hoc/Aux';
import { saveAs } from "file-saver";

class GenericForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
        };
    }

    saveFile(file, name) {
        saveAs(
            file,
            name
        );
    };

    handleKeyPress = (e) => {
        if (e.which === 13 && !e.shiftKey) {
            e.preventDefault();
            this.handleSubmit(e);
        }
    };

    handleSubmit = (e) => {
        if (e) e.preventDefault();
        this.setState({ submitted: true });
        const { onSubmit, fields } = this.props;

        let error = 0;
        fields.forEach((field) => {
            if (!this.checkField(field)) { error++; }
        });

        if (!error) onSubmit();
    };

    checkField = (field) => {
        if ((field.error && !field.error.regex.test(field.value))) {
            return false;
        }

        if (field.required && !field.value.length) {
            return false;
        }

        if (!field.required && !field.error) {
            return true;
        }

        return true;
    };

    render() {
        const {
            submitted,
        } = this.state;

        const {
            editing,
            disabled,
            fields,
        } = this.props;

        return (
            <Form onSubmit={this.handleSubmit}>
                <Row form>
                    {
                        fields.map((field) => {
                            let fieldElement = ('');

                            if (!field.type || ['text', 'textarea', 'email', 'number', 'password'].includes(field.type)) {
                                fieldElement = (
                                    <Input
                                        type={field.type ? field.type : 'text'}
                                        name={field.name}
                                        id={field.name}
                                        value={field.value}
                                        onChange={field.handleChange}
                                        onKeyPress={(e) => { if (field.submit) { this.handleKeyPress(e); } }}
                                        disabled={field.disabled ? field.disabled : disabled}
                                    />
                                );
                            }

                            if (field.type && field.type === 'select') {
                                fieldElement = (
                                    <Input
                                        type="select"
                                        name={field.name}
                                        id={field.name}
                                        value={field.value}
                                        onChange={field.handleChange}
                                        disabled={field.disabled ? field.disabled : disabled}
                                    >
                                        {
                                            field.options && field.options.map((option, key) => {
                                                const index = `options_${key}`;

                                                return (
                                                    <option key={index} value={option.value}>{option.label}</option>
                                                );
                                            })
                                        }
                                    </Input>
                                );
                            }

                            if (field.type && field.type === 'checkbox') {
                                fieldElement = (
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            className="custom-control-input"
                                            type="checkbox"
                                            name={field.name}
                                            id={field.name}
                                            checked={field.value}
                                            onChange={(e) => field.handleChange(e, !field.value)}
                                            disabled={field.disabled ? field.disabled : disabled}
                                        />
                                        <Label className="custom-control-label" htmlFor={field.name}>
                                            {field.label}
                                        </Label>
                                    </div>
                                );
                            }

                            if (field.type && field.type === 'date') {
                                fieldElement = (
                                    <InputGroup className="input-group-alternative shadow-none" disabled>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ni ni-calendar-grid-58" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <ReactDatetime
                                            name={field.name}
                                            value={field.value}
                                            /* eslint-disable-next-line no-underscore-dangle */
                                            onChange={(e) => field.handleChange(field.name, e._d)}
                                            inputProps={{
                                                placeholder: field.placeholder,
                                                disabled: field.disabled ? field.disabled : disabled,
                                            }}
                                            timeFormat={false}
                                            locale="fr"
                                        />
                                    </InputGroup>
                                );
                            }

                            if (field.type && field.type === 'file') {
                                fieldElement = (
                                    <InputGroup>
                                        <FileInput
                                            editing={this.props.editing}
                                            file={field.file}
                                            name={field.name}
                                            id={field.name}
                                            handleChange={field.handleChange}
                                            placeholder={field.placeHolder ? field.placeHolder : 'Sélectionner un fichier'}
                                        />
                                    </InputGroup>
                                );
                            }

                            if (!editing || (field.editing !== undefined && !field.editing)) {
                                fieldElement = (
                                    <p>
                                        {field.toUpperCase && (field.value.toUpperCase())}
                                        {!field.toUpperCase && !field.toLowerCase && (field.value)}
                                    </p>
                                );
                            }

                            if ((field.prepend || field.append) && (editing && (field.editing === undefined || field.editing))) {
                                fieldElement = (
                                    <InputGroup>
                                        {
                                            field.prepend && (
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        {field.prepend}
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                            )
                                        }
                                        {fieldElement}
                                        {
                                            field.append && (
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText>
                                                        {field.append}
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                            )
                                        }
                                    </InputGroup>
                                );
                            }

                            return (
                                <Aux key={field.name}>
                                    {
                                        ((!editing && !field.displayHide) || editing) && !field.space && (
                                            <Col
                                                xs={field.size && field.size.xs ? field.size.xs : null}
                                                sm={field.size && field.size.sm ? field.size.sm : null}
                                                md={field.size && field.size.md ? field.size.md : null}
                                                lg={field.size && field.size.lg ? field.size.lg : null}
                                                xl={field.size && field.size.xl ? field.size.xl : null}
                                            >
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <Label for={field.name} className="font-weight-500">
                                                                {
                                                                    (!field.type || !['checkbox'].includes(field.type)) && (
                                                                        field.label
                                                                    )
                                                                }
                                                                {
                                                                    ((field.required && typeof field.required === 'string') || field.error) && (
                                                                        <span className={`text-${field.required || 'primary'}`}> *</span>
                                                                    )
                                                                }
                                                            </Label>
                                                            {
                                                                field.info !== undefined && (
                                                                    <Badge color={field.info.color || 'primary'} className="float-right mb-1 badge-error">
                                                                        {field.info.text}
                                                                    </Badge>
                                                                )
                                                            }
                                                            {
                                                                submitted && !this.checkField(field) && (
                                                                    <Badge color="danger" className="float-right mb-1 badge-error">
                                                                        {field.error ? field.error.text : 'Invalide'}
                                                                    </Badge>
                                                                )
                                                            }
                                                            {fieldElement}
                                                            {
                                                                field.small && <small>{field.small}</small>
                                                            }
                                                        </FormGroup>
                                                    </Col>
                                                    {
                                                        field.file && <div>
                                                                <button onClick={() => this.saveFile(field.file, field.name)}>Télécharger</button>
                                                            </div>
                                                    }
                                                    {
                                                        field.actions && (
                                                            <Col className="col-auto my-auto pl-0">
                                                                {field.actions}
                                                            </Col>
                                                        )
                                                    }
                                                </Row>
                                            </Col>
                                        )
                                    }
                                    {
                                        field.space && (
                                            <div className="w-100" key={field.name} style={{ marginTop: field.space }} />
                                        )
                                    }
                                </Aux>
                            );
                        })
                    }
                </Row>
            </Form>
        );
    }
}

export default GenericForm;

GenericForm.propTypes = {
    disabled: PropTypes.bool,
    editing: PropTypes.bool,
    fields: PropTypes.array.isRequired,
    onSubmit: PropTypes.func,
};

GenericForm.defaultProps = {
    disabled: false,
    editing: true,
    onSubmit: () => { },
};
