import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Link } from "react-router-dom";

class PremiumModal extends Component {
  render() {
    const { isOpen, toggle } = this.props;

    return (
      <Modal size="lg" isOpen={isOpen} centered toggle={toggle}>
        <ModalHeader toggle={toggle} />
        <ModalBody className="pt-0 align-items-center">
          <Row className="justify-content-center">
            <h5 className="h1 mb-0 font-weight-light">
              Augmenter votre visibilité en devenant Premium
            </h5>
          </Row>
          <Row className="justify-content-center mb-3">
            <p>Invitez des courtiers, discutez directement avec eux et construisez votre réseau</p>
          </Row>
          <Row className="justify-content-center">
              <Link to="/user/premium"><Button className="btn-secondary text-primary">Je booste mon réseau</Button></Link>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

export default PremiumModal;

PremiumModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

PremiumModal.defaultProps = {
  isOpen: false,
  toggle: () => { },
};