import { Link } from "react-router-dom";
import Aux from "../../../hoc/Aux";
import PageComponent from "../PageComponent";
import "../custom.css";

function UserData() {
  return (
    <Aux>
      <PageComponent>
        <h1 className="help-item-title">Données personelles</h1>
        <p className="help-item-text">
          Tribu propose un annuaire complet des courtiers en France avec des
          informations au service de ses utilisateurs.
          <br />
          <br />
          Nous sommes connectés aux organismes publiant les données légales des
          entreprises françaises. Nos données sont actualisées depuis tous les
          répertoires auxquels nous sommes connectés.
          <br />
          <br />
          Ces informations sont publiques et par conséquent leur communication
          est librement autorisée.
          <br />
          <br />
          Les données de contact (adresse e-mail et numéro de téléphone) ne sont
          pas disponibles publiquement.
          <br />
          <br />
          Nous procédons à la suppression uniquement des entreprises qui sont
          radiées du RCS et de l’Orias.
          <br />
          <br />
          Une page entreprise n’a qu’un administrateur. Pour modifier le contenu
          d’une page, veuillez en faire la demande auprès de son administrateur.
        </p>
      </PageComponent>
    </Aux>
  );
}

export default UserData;
