import React from 'react';
import * as REGEX from '../../data/Regex';

const fields = [
    {
        label: 'Nom',
        name: 'lastName',
        type: 'text',
        prepend: <i className="fas fa-user-tie" />,
        error: {
            regex: REGEX.NAME,
            text: 'INVALIDE',
        },
        required: 'warning',
        size: {
            xs: 12,
        },
    },
    {
        label: 'Prénom',
        name: 'firstName',
        type: 'text',
        prepend: <i className="fas fa-user-tie" />,
        error: {
            regex: REGEX.NAME,
            text: 'INVALIDE',
        },
        required: 'warning',
        size: {
            xs: 12,
        },
    },
    {
        label: 'E-mail',
        name: 'mail',
        type: 'email',
        prepend: <i className="ni ni-email-83" />,
        error: {
            regex: REGEX.MAIL,
            text: 'INVALIDE',
        },
        required: 'warning',
        size: {
            xs: 12,
        },
    },
    {
        label: 'Téléphone',
        name: 'phone',
        type: 'text',
        prepend: <i className="ni ni-mobile-button" />,
        required: 'warning',
        size: {
            xs: 12,
        },
    },
    {
        label: 'Entreprise',
        name: 'company',
        type: 'text',
        required: 'warning',
        size: {
            xs: 12,
        },
    },
];

export default fields;
