import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { Route, Switch } from "react-router";
import { connect } from "react-redux";
import ChatRecents from "./ChatRecents";
import ChatThread from "./ChatThread";
import ChatCompose from "./ChatCompose";
import { dbFetchContacts } from "../../store/contacts/actions";
import { dbCreateChatWithoutMessage } from "../../store/chat/actions";
import { CONTACT } from "../../data/ContactConsts";
import DefaultNavbar2 from "../../layouts/Default/DefaultNavbar2";
import DefaultFooter from "../../layouts/Default/DefaultFooter";
import ChatProfile from "./ChatProfile";
import { dbFetchChat } from "../../store/chat/actions";
import GroupedChatThread from "./GroupedChatThread.js";
import { setChatSelectedTab } from "../../store/chatRecents/actions";
import { db, firebaseApp } from "../../config/firebase";

const Chat = (props) => {
  const {
    fetchContacts,
    match,
    chats,
    history,
    fetchChat,
    groupedChats,
    selectedChatTab,
    setChatSelectedTabb,
  } = props;
  const [firstLoadChats, setFirstLoadChats] = useState(true);
  const [firstLoadGroupedChats, setFirstLoadGroupedChats] = useState(true);
  const [allowNotifications, setAllowNotifications] = useState(false)
  const [fetched, setFetched] = useState(false)

  useEffect(() => {
    fetchContacts([CONTACT], true);
  }, []);

  useEffect(() => {
    if (
      chats[0] &&
      firstLoadChats &&
      !window.location.pathname.includes("/chat/compose") &&
      !window.location.pathname.includes("/chat/grouped")
    ) {
      setFirstLoadChats(false);
      history.push(
        `/chat/${chats.sort((a, b) => b.lastMessage.date - a.lastMessage.date)[0].id
        }`
      );
    }
  }, [chats]);

  useEffect(() => {
    if (
      groupedChats[0] &&
      firstLoadGroupedChats &&
      !window.location.pathname.includes("/chat/compose") &&
      window.location.pathname.includes("/chat/grouped")
    ) {
      setFirstLoadGroupedChats(false);
      history.push(
        `/chat/grouped/${groupedChats.sort(
          (a, b) => b.lastMessage.date - a.lastMessage.date
        )[0].id
        }`
      );
    }
  }, [groupedChats]);

  useEffect(() => {
    if (window.location.pathname.includes("/chat/grouped"))
      setChatSelectedTabb(1);
    else setChatSelectedTabb(0);
  }, [window.location.pathname]);

  const { chatId } = match.params;

  useEffect(() => {
  }, [groupedChats]);

  const uid = firebaseApp.auth().currentUser.uid;

  useEffect(() => {
    db.collection('users').doc(uid).get().then((doc) => {
        const allowNotifications = doc.data().allowNotifications
        if (allowNotifications === undefined) {
            setAllowNotifications(true)
        } else {
            setAllowNotifications(allowNotifications)
        }
        setFetched(true)
    })
}, []);

  //useEffect(() => fetchChat(chatId), [chatId]);

  return (
    <div style={{ maxHeight: "100vh" }} className="h-100vh d-flex flex-column">
      <DefaultNavbar2 relative allowNotifications={allowNotifications} fetched={fetched} />
      <section style={{ flex: 1 }} className=" d-flex flex-column">
        {/* <div style={{ height: 75 }} /> */}

        <div style={{ flex: 1 }} className=" d-flex flex-row ">
          <div className="pl-2 py-2" style={{ width: 400 }}>
            <ChatRecents />
          </div>

          <div className="px-0 p-2" style={{ flex: 1, zIndex: 1 }}>
            <Switch>
              <Route
                path="/chat/grouped/:chatId"
                component={GroupedChatThread}
              />
              <Route path="/chat/compose" exact component={ChatCompose} />
              <Route path="/chat/:chatId" component={ChatThread} />

              <Route
                path="/chat"
                component={() => (
                  <Card className="shadow h-100">
                    <CardBody className="d-flex flex-column align-items-center justify-content-center">
                      <i
                        className="ni ni-chat-round primary mb-3"
                        style={{ fontSize: 200, color: "var(--blue)" }}
                      />
                      <span className="text-center text-lg">
                        Créez une conversation pour discuter avec un courtier
                      </span>
                    </CardBody>
                  </Card>
                )}
              />
            </Switch>
          </div>
          {chats.length > 0 && !window.location.pathname.includes('chat/grouped') && (
            <div className="pr-2 py-2" style={{ width: 400, zIndex: 0 }}>
              <ChatProfile chatId={chatId} />
            </div>
          )}
        </div>
      </section>

      <DefaultFooter />
    </div>
  );
};

const mapStateToProps = (state) => ({
  chats: state.ChatRecents.chats,
  groupedChats: state.ChatRecents.groupedChats,
  selectedChatTab: state.ChatRecents.selectedChatTab,
});

export default connect(mapStateToProps, {
  fetchChat: dbFetchChat,
  fetchContacts: dbFetchContacts,
  createChat: dbCreateChatWithoutMessage,
  setChatSelectedTabb: setChatSelectedTab,
})(Chat);

Chat.propTypes = {
  chats: PropTypes.array,
  groupedChats: PropTypes.array,
  fetchContacts: PropTypes.func,
  setChatSelectedTabb: PropTypes.func,
};

Chat.defaultProps = {
  chats: [],
  groupedChats: [],
  selectedChatTab: "",
  fetchContacts: () => { },
  setChatSelectedTabb: () => { },
};