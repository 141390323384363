import PropTypes from "prop-types";
import React from "react";
import { ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";
import "./style.css";
import classes from "../../assets/Scss/components/Chat.scss";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useState, useEffect } from "react";
import { db } from "../../config/firebase";
import moment from "moment";

const ChatSent = (props) => {
  const [profilePic, setProfilePic] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const { message, isLast } = props;

  const { sender } = message;

  function highlightURLs({ text }) {
    return text?.replace(
      /(https?:\/\/[^\s]+)/g,
      `<a target="_blank" href='$1'><span class="highlight-light">$1</span></a>`
    );
  }

  useEffect(() => {
    if (sender.userId) {
      db.collection("users")
        .doc(sender.userId)
        .get()
        .then((doc) => {
          if (doc.data()) setProfilePic(doc.data().profilePic);
        });
    }

    db.collection("users")
      .doc(sender.userId)
      .get()
      .then((doc) => {
        if (doc.data()) setProfilePic(doc.data().profilePic);
      });
  }, []);

  const fakeMessage = {
    date: 1686010239122,
    is_grouped: true,
    grouped_chat_id: 1,
    sender: {},
    message:
      "Bonjour,\n\nNous voulons vous informer de nos nouvelles offres pour votre assurance automobile.Nous voulons vous informer de nos nouvelles offres pour votre assurance automobile.Nous voulons vous informer de nos nouvelles offres pour votre assurance automobile.\n\nCordialement.Bonjour,\n\nNous voulons vous informer de nos nouvelles offres pour votre assurance automobile.\n\nCordialement.Bonjour,\n\nNous voulons vous informer de nos nouvelles offres pour votre assurance automobile.\n\nCordialement.Bonjour,\n\nNous voulons vous informer de nos nouvelles offres pour votre assurance automobile.\n\nCordialement",
    files: [
      {
        name: "Explication.mp4",
        type: "video",
        size: 100,
        downloadURL:
          "https://firebasestorage.googleapis.com/v0/b/tribu-app.appspot.com/o/chat%2Fu2j9W8zlZsOO1hiqMq3Aj8sOvv03pBigUxpaNtQ0cgIFCCYbxsswj672%2FMAATHENA-Appli-Livreur-CHARGEMENT%2002.mp4?alt=media&token=9cc14a57-be71-4631-89ac-c1e1d4139250",
      },
      {
        name: "Liste de.txt",
        type: "file",
        size: 100,
        downloadURL:
          "https://firebasestorage.googleapis.com/v0/b/tribu-app.appspot.com/o/chat%2Fu2j9W8zlZsOO1hiqMq3Aj8sOvv03pBigUxpaNtQ0cgIFCCYbxsswj672%2FDocument%20sans%20titre.txt?alt=media&token=a539d02a-170b-474d-b432-0345793bc89c",
      },
    ],
  };

  return (
    <ListGroupItem
      className={`flex-column align-items-end py-2 px-4 border-0 ${classes.Chat} `}
    >
      {isOpen && (
        <Lightbox
          mainSrc={message.picture}
          onCloseRequest={() => setIsOpen(false)}
        />
      )}
      <div className="d-flex flex-column align-items-end">
        {message.is_grouped ? (
          <div
            className="message-container message-container-sent"
            style={{ backgroundColor: "var(--blue)" }}
          >
            <p
              className={`text-white mb-1 ${classes.sent} message-text message-grouped-text`}
              id="message"
            >
              {fakeMessage.message}
            </p>
            <Link
              to={`/chat/grouped/${fakeMessage.id}`}
              className={`text-white mb-0 text-underline`}
            >
              Voir le message complet
            </Link>

            <p className={`message-date-text`} style={{ color: "lightgray" }}>
              Message envoyé à plusieurs contacts -{" "}
              {moment(new Date(fakeMessage.date)).format("HH:mm")}
            </p>
          </div>
        ) : message.picture !== undefined ? (
          <img
            id="message"
            src={message.picture}
            alt="chat"
            height="200"
            className="chat-media"
            onClick={() => setIsOpen(true)}
          />
        ) : message.video !== undefined ? (
          <video controls height="200"  className="chat-media">
            <source src={message.video} />
          </video>
        ) : message.file !== undefined ? (
          <div
            className="message-container message-container-sent"
            style={{ backgroundColor: "var(--blue)" }}
          >
            <div className="d-flex align-items-center">
              <i class="far fa-file text-white pr-2"></i>
              {/* with direct download */}
              {/*
              <button onClick={() => {
                const xhr = new XMLHttpRequest();
                xhr.responseType = "blob";
                xhr.onload = (event) => {
                  const blob = xhr.response;
                };
                xhr.open("GET", message.file.downloadURL);
                xhr.send();
              }}>
                <p
                  style={{ textDecoration: "underline" }}
                  className={`text-white mb-0 ${classes.sent} message-text pr-2`}
                  id="message"
                >
                  {message.file?.detail?.name}
                </p>
              </button>
              <p
                onClick={() => {
                  const xhr = new XMLHttpRequest();
                  xhr.responseType = "blob";
                  xhr.onload = (event) => {
                    const blob = xhr.response;
                  };
                  xhr.open("GET", message.file.downloadURL);
                  xhr.send();
                }}
                style={{ textDecoration: "underline" }}
                className={`text-white mb-0 ${classes.sent} message-text pr-2`}
                id="message"
              >
                {message.file?.detail?.name}
              </p>
              */}
              <div
                style={{ textDecoration: "underline" }}
                className={`text-white mb-0 ${classes.sent} message-text pr-2 cursor-pointer`}
                id="message"
                onClick={() => {
                  fetch(message.file.downloadURL)
                    .then((response) => response.blob())
                    .then((blob) => {
                      const urlBlob = window.URL.createObjectURL(blob);
                      const a = document.createElement("a");
                      a.href = urlBlob;
                      a.target = "_blank";
                      a.download = message.file?.detail?.name
                      document.body.appendChild(a);
                      a.click();
                      a.remove();
                    })
                    .catch((error) => console.error("Error:", error));
                }}
              >
                {message.file?.detail?.name}
              </div>
              <p
                className={`text-white mb-0 ${classes.sent} message-text-small `}
              >
                {message.file?.detail?.size
                  ? `(${(message.file.detail.size / 1000).toFixed(2)}Ko)`
                  : ""}
              </p>
            </div>

            <p className={`message-date-text`} style={{ color: "lightgray" }}>
              {moment(new Date(message.date)).format("HH:mm")}
            </p>
          </div>
        ) : (
          <div
            className="message-container message-container-sent"
            style={{ backgroundColor: "var(--blue)" }}
          >
            <p
              className={`text-white mb-0 ${classes.sent} message-text`}
              id="message"
              dangerouslySetInnerHTML={{
                __html: highlightURLs({
                  text: message.message,
                }),
              }}
            ></p>
            <p className={`message-date-text`} style={{ color: "lightgray" }}>
              {moment(new Date(message.date)).format("HH:mm")}
            </p>
          </div>
        )}
        {/* <UncontrolledTooltip delay={0} placement="top" target="message">
          <Moment locale="fr" format="lll">
            {message.date}
          </Moment>
        </UncontrolledTooltip> */}
        {isLast && message.seen && (
          <p className={`message-read mb-0 ${classes.sent} `}>Lu</p>
        )}
      </div>
    </ListGroupItem>
  );
};

export default ChatSent;

ChatSent.propTypes = {
  chat: PropTypes.object,
  message: PropTypes.object,
};

ChatSent.defaultProps = {
  chat: {},
  message: {},
};
