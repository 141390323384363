import Error404 from "./Error404";
import Login from "./Login/Login";
import RecoverPass from "./Login/RecoverPass";
import Home from "./Home/Home";
import Landing from "./Landing/Landing";
import Chat from "./Chat/Chat";
import Search from "./Search/Search";
import Corporate from "./Corporate/Corporate";
import GetInTouch from "./GetInTouch/GetInTouch";
import GetInTouch2 from "./GetInTouch/GetInTouch2";
import About from "./About/About";
import Cguv from "./Cguv/Cguv";
import Cguv2 from "./Cguv/Cguv2";
import Pdd from "./Pdd/Pdd";
import HelpCenter from "./HelpCenter/Center";
import HelpFirstSteps from "./HelpCenter/Pages/FirstStep";
import HelpAccountActivation from "./HelpCenter/Pages/AccountActivation";
import HelpSignInProblem from "./HelpCenter/Pages/SignInProblem";
import HelpAccountSettings from "./HelpCenter/Pages/AccountSettings";
import HelpTribuPremium from "./HelpCenter/Pages/TribuPremium";
import HelpEditProfile from "./HelpCenter/Pages/EditProfile";
import HelpEditCompany from "./HelpCenter/Pages/EditCompany";
import HelpUserData from "./HelpCenter/Pages/UserData";
import HelpDashboard from "./HelpCenter/Pages/Dashboard";
import HelpAddMember from "./HelpCenter/Pages/AddMember";
import HelpRemoveContact from "./HelpCenter/Pages/RemoveContact";
import HelpWriteToContact from "./HelpCenter/Pages/WriteToContact";
import HelpFollowers from "./HelpCenter/Pages/Followers";
import HelpPosts from "./HelpCenter/Pages/Posts";
import HowItWorks from "./HowItWorks/HowItWorks";
import Sitemap from "./Sitemap/Sitemap";
import Sitemap2 from "./Sitemap/Sitemap2";
import BecomeBroker from "./BecomeBroker/BecomeBroker";
import Signin from "./Login/Signin";
import Signup2 from "./Login/Signup2";
import SignupCorporate from "./Login/SignupCorporate";
import Search2 from "./Search2/Search2";
import ImBroker from "./ImBroker/ImBroker";
import ImCorporate from "./ImCorporate/ImCorporate";
import SearchWithServices from "./Search/SearchWithServices";
import SearchWithCategories from "./Search/SearchWithCategories";
import SearchWithCities from "./Search/SearchWithCities";
import SignupCorporateWithOrias from "./Login/SignupCorporateWithOrias";
import SignupCorporateWithoutOrias from "./Login/SignupCorporateWithoutOrias";
import SignupCorporateWithOrias2 from "./Login/SignupCorporateWithOrias2";
import SignupCorporateWithoutOrias2 from "./Login/SignupCorporateWithoutOrias2";
import SignupCorporate2 from "./Login/SignupCorporate2";
import Profile2 from "./Profile/Profile2";
import Post from "./Posts/Post";

export default {
  Error404,
  Login,
  RecoverPass,
  Chat,
  Search,
  Search2,
  Home,
  Landing,
  Corporate,
  GetInTouch,
  About,
  Cguv,
  HelpCenter,
  HelpFirstSteps,
  HelpAccountActivation,
  HelpSignInProblem,
  HelpAccountSettings,
  HelpTribuPremium,
  HelpEditProfile,
  HelpEditCompany,
  HelpUserData,
  HelpDashboard,
  HelpAddMember,
  HelpRemoveContact,
  HelpWriteToContact,
  HelpFollowers,
  HelpPosts,
  Pdd,
  HowItWorks,
  Sitemap,
  BecomeBroker,
  Signin,
  Signup2,
  SignupCorporate,
  ImBroker,
  ImCorporate,
  SearchWithServices,
  SearchWithCategories,
  SearchWithCities,
  GetInTouch2,
  Cguv2,
  Sitemap2,
  SignupCorporateWithOrias,
  SignupCorporateWithoutOrias,
  SignupCorporateWithOrias2,
  SignupCorporateWithoutOrias2,
  SignupCorporate2,
  Profile2,
  Post,
};
