import React, { Component } from "react";
import { Col, Container, Row, Badge, Card, CardBody } from "reactstrap";
import Aux from "../../hoc/Aux";
import DefaultSearchBar5 from "../Default/DefaultSearchBar5";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Img from "../../assets/Pics/tribu-trouver-un-courtier.svg";
import "../../App.css";
import SERVICES from "../../views/Search/services";
import { withRouter } from "react-router-dom";

class LandingHeader3 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentServiceItem: undefined,
      currentChildrenServices: undefined,
    };
  }

  map = {
    IOBSP: [
      "Crédit Immobilier",
      "Rachat de crédits",
      "Rachat de crédits consommation",
      "Rachat de crédits consommation et immobilier",
      "Crédit professionnel",
      "Prêt professionnel",
      "Crédit bail immobilier",
      "Crédit bail mobilier",
      "Location financière",
      "Location longue durée",
      "Affacturage",
      "Assurance crédit",
    ],
    IAS: [
      "Epargne",
      "Assurance-vie",
      "Contrat de capitalisation",
      "Assurance famille",
      "Garantie des accidents de la vie",
      "Protection juridique",
      "Assurance décès",
      "Assurance scolaire",
      "Assurance emprunteur",
      "Garantie obsèques",
      "Assurance animaux",
      "Assurance dépendance",
      "Assurance rapatriement",
      "Assurance habitation",
      "Assurance locataire",
      "Propriétaire non-occupant",
      "Assurance loisirs",
      "Assurance plaisance",
      "Assurance voyage",
      "Assurance chasse",
      "Assurance sport",
      "Assurance santé",
      "Complémentaire santé",
      "Sur-complémentaire",
      "Assurance auto",
      "Assurance 2 roues",
      "Assurance voiture sans permis",
      "Assurance quad",
      "Assurance camping-car",
      "Assurance malussé",
      "Assurance du chef d'entreprise",
      "Prévoyance pro",
      "Santé pro",
      "Retraite pro",
      "Perte d’emploi",
      "Loi Madelin",
      "Responsabilité civile des mandataires sociaux",
      "Assurance homme-clé",
      "Assurance construction",
      "Dommage-ouvrage",
      "Tous risques chantier",
      "Constructeur non réalisateur",
      "Responsabilité civile décennale",
      "Garantie financière d’achèvement",
      "Responsabilité civile promoteur",
      "Multirisque professionnelle",
      "Bris de machine",
      "Dommage aux biens",
      "Locaux professionnels",
      "Responsabilité civile d’exploitation",
      "Protection juridique pro",
      "Perte d’exploitation",
      "Risques informatiques",
      "Responsabilité civile professionnelle",
      "RC Pro Agriculteur",
      "RC Pro Artisan",
      "RC Pro Association",
      "RC Pro Auto-entrepreneur",
      "RC Pro Commerçant",
      "RC Pro Profession libérale",
      "Assurance des salariés",
      "Prévoyance collective",
      "Epargne salariale",
      "Santé collective",
      "Retraite collective",
      "Assurance des véhicules",
      "Flotte automobile",
      "Transport de marchandises",
      "Transport de voyageurs",
      "Flotte motocycle",
      "Assurance du véhicule",
    ],
    CIF: [
      "Compte-titres pro",
      "Compte à terme pro",
      "PEE",
      "PERCO",
      "Article 83",
      "Girardin industriel",
      "PEA",
      "Compte-titres",
      "Compte à terme",
      "FIP",
      "FCPI",
      "SCPI",
      "OPCI",
    ],
  };
  map2 = {
    IOBSP: [
      "assurance-vie",
      "contrat_de_capitalisation",
      "consommation",
      "consommation_et_immobilier",
      "credit_professionnel",
      "credit_bail_immobilier",
      "credit_bail_mobilier",
      "location_financiere",
      "location_longue_duree",
      "affacturage",
      "assurance_credit",
    ],
    IAS: [
      "garantie_des_accidents_de_la_vie",
      "protection_juridique",
      "deces",
      "scolaire",
      "emprunteur",
      "garantie_obseques",
      "animaux_de_compagnie",
      "dependance",
      "rapatriement",
      "locataire",
      "propriétaire_non-occupant",
      "plaisance",
      "voyage",
      "chasse",
      "sport",
      "complementaire_sante",
      "sur-complementaire",
      "auto",
      "2_roues",
      "voiture_sans_permis",
      "quad",
      "camping-car",
      "malusse",
      "prevoyance",
      "sante",
      "retraite",
      "perte_d_emploi",
      "loi_madelin",
      "responsabilite_civile_des_mandataires_sociaux",
      "homme-cle",
      "dommage-ouvrage",
      "tous_risques_chantier",
      "constructeur_non_realisateur",
      "responsabilite_civile_decennale",
      "garantie_financiere_d_achevement",
      "responsabilite_civile_promoteur",
      "bris_de_machine",
      "dommage_aux_biens",
      "locaux_professionnels",
      "responsabilite_civile_d_exploitation",
      "protection_juridique",
      "perte_d_exploitation",
      "risques_informatiques",
      "agriculteur",
      "artisan",
      "association",
      "auto-entrepreneur",
      "commercant",
      "profession_liberale",
      "prevoyance",
      "epargne_salariale",
      "sante",
      "retraite",
      "dependance",
      "flotte_de_vehicules",
      "transport_de_marchandises",
      "transport_de_voyageurs",
      "flotte_motocycle",
      "dommage-ouvrage",
      "tous_risques_chantier",
      "constructeur_non_realisateur",
      "responsabilite_civile_decennale",
      "garantie_financiere_d_achevement",
      "responsabilite_civile_promoteur",
      "bris_de_machine",
      "dommage_aux_biens",
      "locaux_professionnels",
      "responsabilite_civile_d_exploitation",
      "protection_juridique",
      "perte_d_exploitation",
      "risques_informatiques",
      "agriculteur",
      "artisan",
      "association",
      "auto-entrepreneur",
      "commercant",
      "profession_liberale",
    ],
    CIF: [
      "compte-titres",
      "compte_a_terme",
      "pee",
      "perco",
      "article_83",
      "girardin_industriel",
      "pea",
      "fip",
      "fcpi",
      "scpi",
      "opci",
    ],
  };
  mainServices = [
    "Crédit Immobilier",
    "Rachat de crédits",
    "Crédit professionnel",
    "Epargne",
    "Assurance famille",
    "Assurance habitation",
    "Assurance loisirs",
    "Assurance santé",
    "Assurance des véhicules",
    "Assurance du chef d'entreprise",
    "Assurance construction",
    "Multirisque professionnelle",
    "Responsabilité civile professionnelle",
    "Assurance des salariés",
    "Assurance du véhicule",
  ];

  iobspObj = [
    {
      id: "rachat_de_credits_consommation",
      text: "Rachat de crédits consommation",
    },
    {
      id: "rachat_de_credits_consommation_et_immobilier",
      text: "Rachat de crédits consommation et immobilier",
    },
    {
      id: "credit_professionnel",
      text: "Crédit professionnel",
    },
    {
      id: "credit_bail_immobilier",
      text: "Crédit bail immobilier",
    },
    {
      id: "credit_bail_mobilier",
      text: "Crédit bail mobilier",
    },
    {
      id: "location_financiere",
      text: "Location financière",
    },
    {
      id: "location_longue_duree",
      text: "Location longue durée",
    },
    {
      id: "affacturage",
      text: "Affacturage",
    },
    {
      id: "assurance_credit",
      text: "Assurance crédit",
    },
    {
      id: "credit_immobilier",
      text: "Crédit Immobilier",
    },
    {
      id: "rachat_de_credits",
      text: "Rachat de crédits",
    },
    {
      id: "pret_professionnel",
      text: "Prêt professionnel",
    },
  ];
  cifObj = [
    {
      id: "compte-titres",
      text: "Compte-titres",
    },
    {
      id: "compte_a_terme",
      text: "Compte à terme",
    },
    {
      id: "compte-titres_pro",
      text: "Compte-titres pro",
    },
    {
      id: "compte_a_terme_pro",
      text: "Compte à terme pro",
    },
    {
      id: "pee",
      text: "PEE",
    },
    {
      id: "perco",
      text: "PERCO",
    },
    {
      id: "article_83",
      text: "Article 83",
    },
    {
      id: "girardin_industriel",
      text: "Girardin industriel",
    },
    {
      id: "pea",
      text: "PEA",
    },
    {
      id: "fip",
      text: "FIP",
    },
    {
      id: "fcpi",
      text: "FCPI",
    },
    {
      id: "scpi",
      text: "SCPI",
    },
    {
      id: "opci",
      text: "OPCI",
    },
  ];
  iasObj = [
    {
      id: "assurance-vie",
      text: "Assurance-vie",
    },
    {
      id: "contrat_de_capitalisation",
      text: "Contrat de capitalisation",
    },
    {
      id: "garantie_des_accidents_de_la_vie",
      text: "Garantie des accidents de la vie",
    },
    {
      id: "protection_juridique",
      text: "Protection juridique",
    },
    {
      id: "assurance_deces",
      text: "Assurance décès",
    },
    {
      id: "assurance_scolaire",
      text: "Assurance scolaire",
    },
    {
      id: "assurance_emprunteur",
      text: "Assurance emprunteur",
    },
    {
      id: "garantie_obseques",
      text: "Garantie obsèques",
    },
    {
      id: "assurance_animaux",
      text: "Assurance animaux",
    },
    {
      id: "assurance_dependance",
      text: "Assurance dépendance",
    },
    {
      id: "assurance_rapatriement",
      text: "Assurance rapatriement",
    },
    {
      id: "assurance_locataire",
      text: "Assurance locataire",
    },
    {
      id: "propriétaire_non-occupant",
      text: "Propriétaire non-occupant",
    },
    {
      id: "assurance_plaisance",
      text: "Assurance plaisance",
    },
    {
      id: "assurance_voyage",
      text: "Assurance voyage",
    },
    {
      id: "assurance_chasse",
      text: "Assurance chasse",
    },
    {
      id: "assurance_sport",
      text: "Assurance Sport",
    },
    {
      id: "complementaire_sante",
      text: "Complémentaire santé",
    },
    {
      id: "sur-complementaire",
      text: "Sur-complémentaire",
    },
    {
      id: "assurance_auto",
      text: "Assurance auto",
    },
    {
      id: "assurance_2_roues",
      text: "Assurance 2 roues",
    },
    {
      id: "assurance_voiture_sans_permis",
      text: "Assurance voiture sans permis",
    },
    {
      id: "assurance_quad",
      text: "Assurance quad",
    },
    {
      id: "assurance_camping-car",
      text: "Assurance camping-car",
    },
    {
      id: "assurance_malusse",
      text: "Assurance malussé",
    },
    {
      id: "assurance_du_chef_d_entreprise",
      text: "Assurance du chef d'entreprise",
    },
    {
      id: "prevoyance_pro",
      text: "Prévoyance pro",
    },
    {
      id: "sante_pro",
      text: "Santé pro",
    },
    {
      id: "retraite_pro",
      text: "Retraite pro",
    },
    {
      id: "perte_d_emploi",
      text: "Perte d’emploi",
    },
    {
      id: "loi_madelin",
      text: "Loi Madelin",
    },
    {
      id: "responsabilite_civile_des_mandataires_sociaux",
      text: "Responsabilité civile des mandataires sociaux",
    },
    {
      id: "assurance_homme-cle",
      text: "Assurance homme-clé",
    },
    {
      id: "assurance_construction",
      text: "Assurance construction",
    },
    {
      id: "dommage-ouvrage",
      text: "Dommage-ouvrage",
    },
    {
      id: "tous_risques_chantier",
      text: "Tous risques chantier",
    },
    {
      id: "constructeur_non_realisateur",
      text: "Constructeur non réalisateur",
    },
    {
      id: "responsabilite_civile_decennale",
      text: "Responsabilité civile décennale",
    },
    {
      id: "garantie_financiere_d_achevement",
      text: "Garantie financière d’achèvement",
    },
    {
      id: "responsabilite_civile_promoteur",
      text: "Responsabilité civile promoteur",
    },
    {
      id: "multirisque_professionnelle",
      text: "Multirisque professionnelle",
    },
    {
      id: "bris_de_machine",
      text: "Bris de machine",
    },
    {
      id: "dommage_aux_biens",
      text: "Dommage aux biens",
    },
    {
      id: "locaux_professionnels",
      text: "Locaux professionnels",
    },
    {
      id: "responsabilite_civile_d_exploitation",
      text: "Responsabilité civile d’exploitation",
    },
    {
      id: "protection_juridique_pro",
      text: "Protection juridique pro",
    },
    {
      id: "perte_d_exploitation",
      text: "Perte d’exploitation",
    },
    {
      id: "risques_informatiques",
      text: "Risques informatiques",
    },
    {
      id: "responsabilite_civile_professionnelle",
      text: "Responsabilité civile professionnelle",
    },
    {
      id: "rc_pro_agriculteur",
      text: "RC Pro Agriculteur",
    },
    {
      id: "rc_pro_artisan",
      text: "RC Pro Artisan",
    },
    {
      id: "rc_pro_association",
      text: "RC Pro Association",
    },
    {
      id: "rc_pro_auto-entrepreneur",
      text: "RC Pro Auto-entrepreneur",
    },
    {
      id: "rc_pro_commercant",
      text: "RC Pro Commerçant",
    },
    {
      id: "rc_pro_profession_liberale",
      text: "RC Pro Profession libérale",
    },
    {
      id: "assurance_des_salaries",
      text: "Assurance des salariés",
    },
    {
      id: "prevoyance_collective",
      text: "Prévoyance collective",
    },
    {
      id: "epargne_salariale",
      text: "Epargne salariale",
    },
    {
      id: "sante_collective",
      text: "Santé collective",
    },
    {
      id: "retraite_collective",
      text: "Retraite collective",
    },
    {
      id: "flotte_automobile",
      text: "Flotte automobile",
    },
    {
      id: "transport_de_marchandises",
      text: "Transport de marchandises",
    },
    {
      id: "transport_de_voyageurs",
      text: "Transport de voyageurs",
    },
    {
      id: "flotte_motocycle",
      text: "Flotte motocycle",
    },
    {
      id: "epargne",
      text: "Epargne",
    },
    {
      id: "assurance_famille",
      text: "Assurance famille",
    },
    {
      id: "assurance_habitation",
      text: "Assurance habitation",
    },
    {
      id: "assurance_loisirs",
      text: "Assurance loisirs",
    },
    {
      id: "assurance_sante",
      text: "Assurance santé",
    },
    {
      id: "assurance_du_vehicule",
      text: "Assurance du véhicule",
    },
  ];

  componentDidMount() {
    this.handleSetCurrentServiceItem();
  }

  componentDidUpdate() {
    const urlService = this.props.match.params.service;
    if (urlService !== this.state.currentServiceItem?.name)
      this.handleSetCurrentServiceItem();
  }

  handleSetCurrentServiceItem() {
    const urlService = this.props.match.params.service;
    const currentService = SERVICES.find(
      (el) => el?.name !== undefined && el.name === urlService
    );

    if (currentService) {
      var childrenServices = [];

      const parentService = SERVICES.find(
        (el) => el.name === currentService.parent
      );
      // console.log(parentService, 'parentService')
      SERVICES.map((service) => {
        if (
          (parentService && parentService?.name === service.parent) ||
          parentService?.name === service.name
        )
          childrenServices.push(service);
        else if (
          currentService.name === service.name ||
          currentService.name === service.parent
        ) {
          childrenServices.push(service);
        }
      });

      this.setState({
        currentServiceItem: currentService,
        currentChildrenServices: childrenServices,
      });
    }
    // else if (urlService !== undefined) this.props.history.push("/404");
  }

  lowerCaseService(s) {
    var isFullUpperCase = true;
    for (var i = 0; i < s.length; i++) {
      if (s.charAt(i) !== s.charAt(i).toUpperCase()) isFullUpperCase = false;
    }
    return isFullUpperCase ? s : s.toLowerCase();
  }

  render() {
    const { currentServiceItem, currentChildrenServices } = this.state;
    const TextComponent = ({ text }) => {
      const lines = text.split("\n").map((line, index) => (
        <span key={index}>
          {line}
          <br />
        </span>
      ));

      return <div>{lines}</div>;
    };

    const ServiceBadge = ({ service }) => {
      return (
        <Link style={{pointerEvents: service?.disable ? 'none' : 'auto'}}  to={`/search/services/${service.name}`}>
          <Badge
            className="mr-2 mb-2  text-truncate mw-100"
            color={service.isParent ? "danger" : "primary"}
          >
            {service.service}
          </Badge>
        </Link>
      );
    };
    return (
      <Aux>
        {currentServiceItem ? (
          <Helmet>
            <title>{currentServiceItem.title}</title>
            <meta name="description" content={currentServiceItem.description} />
            <meta name="keywords" content={currentServiceItem.keywords} />
          </Helmet>
        ) : (
          <Helmet>
            <title>
              Tous les services banque et assurance de nos courtiers sur Tribu
            </title>
            <meta
              name="description"
              content="Vous recherchez un courtier avec une offre de services identifiés ? La plateforme
                        Tribu permet de trouver les courtiers parmi plus de 80 spécialités référencées."
            />
            <meta
              name="keywords"
              content="assurance 2 roues, Affacturage, assurance Agriculteur, assurance Animaux de
compagnie, assurance Article 83, assurance Artisan, assurance Association, Assurance credit,
Assurance-vie, assurance Auto, assurance Auto-entrepreneur, assurance Bris de machine, assurance
Camping-car, assurance Chasse, assurance Commerçant, Complementaire sante, Compte a terme,
Compte-titres, Credit Consommation, Credit Consommation et Immobilier, assurance Constructeur
non realisateur, Contrat de capitalisation, Credit bail immobilier, Credit bail mobilier, Credit
professionnel, assurance Dommage aux biens, assurance Dommage-ouvrage, assurance Deces,
assurance Dependance, assurance Emprunteur, Epargne salariale, FCPI, FIP, assurance Flotte de
vehicules, assurance Flotte motocycle, assurance Garantie des accidents de la vie, assurance Garantie
financiere d’achevement, assurance Garantie obseques, Girardin industriel, assurance Homme-cle,
assurance Locataire, Location financiere, Location longue duree, assurance Locaux professionnels,
assurance Loi Madelin, assurance Malusse, OPCI, PEA, PEE, PERCO, assurance Perte d’emploi,
assurance Perte d’exploitation, assurance Plaisance, assurance Profession liberale, assurance
Proprietaire non-occupant, assurance Protection juridique, assurance Prevoyance, assurance Quad,
assurance Rapatriement, assurance Responsabilite civile des mandataires sociaux, assurance
Responsabilite civile decennale, assurance Responsabilite civile d’exploitation, assurance
Responsabilite civile promoteur, assurance Retraite, assurance Risques informatiques, SCPI,
assurance Sante, assurance Scolaire, assurance Sport, assurance Sur-complementaire, assurance
Tous risques chantier, assurance Transport de marchandises, assurance Transport de voyageurs,
assurance Voiture sans permis, assurance Voyage"
            />
          </Helmet>
        )}
        <div className="header bg-gradient-info pb-7">
          <Container>
            <div className="header-body">
              <Row className="justify-content-center">
                <img
                  style={{ transform: "translate(0, 60px)" }}
                  className="mb-3 bg-title-image"
                  alt="trouver un courtier"
                  src={Img}
                />
              </Row>
              <Row className="justify-content-center align-items-center h-100 m-0">
                <Col className="text-white text-center">
                  <h3
                    className="display-2 text-white mt-5 font-weight-light"
                    style={{
                      lineHeight: "1.3",
                      paddingTop: 100,
                      textShadow: "0px 0px 20px rgba(0,0,0,0.4)",
                    }}
                  >
                    {currentServiceItem ? (
                      <>
                        Courtiers en
                        <br />
                        <span className="font-weight-bold">
                          {currentServiceItem.service_alias}
                        </span>
                      </>
                    ) : (
                      <>
                        Courtiers par
                        <br />
                        <span className="font-weight-bold">services</span>
                      </>
                    )}
                  </h3>
                  <h3
                    className="display-4 text-white mt-5 font-weight-light"
                    style={{
                      lineHeight: "1.3",
                      textShadow: "0px 0px 20px rgba(0,0,0,0.4)",
                    }}
                  >
                    {currentServiceItem
                      ? `Travaillez en toute sérénité avec les meilleurs courtiers
                       en ${this.lowerCaseService(currentServiceItem.service_alias)}`
                      : `Travaillez en toute sérénité avec les meilleurs courtiers
                      partout en France`}
                  </h3>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg="8">
                  <div>
                    <div className="mt-5">
                      <DefaultSearchBar5
                        searchLeft={currentServiceItem?.service}
                        category={currentServiceItem?.category}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className="text-white text-center">
                  <h3
                    className="display-4 text-white mt-5 font-weight-light"
                    style={{
                      lineHeight: "1.3",
                      textShadow: "0px 0px 20px rgba(0,0,0,0.4)",
                    }}
                  >
                    Les professionnels du courtage sont sur Tribu
                  </h3>
                </Col>
              </Row>

              <Row className="mt-5 d-flex justify-content-center">
                <Col lg={currentServiceItem ? 8 : 0}>
                  {currentServiceItem
                    ? currentServiceItem.text && (
                        <Card style={{ backgroundColor: "var(--lighter)" }}>
                          <CardBody>
                            <span
                              style={{
                                fontSize: 28,
                                marginBlockEnd: 10,
                                display: "flex",
                                alignSelf: "center",
                              }}
                            >
                              {currentServiceItem.service_alias}
                            </span>
                            <TextComponent text={currentServiceItem.text} />
                          </CardBody>
                        </Card>
                      )
                    : SERVICES.map((service) => {
                      return <ServiceBadge service={service} />;
                      })}
                </Col>
              </Row>
              {currentChildrenServices && (
                <Row className="mt-5 d-flex justify-content-center">
                  <Col lg={currentServiceItem ? 8 : 0}>
                    {currentChildrenServices.map((service) => {
                      return <ServiceBadge service={service} />;
                    })}
                  </Col>
                </Row>
              )}
            </div>
          </Container>
        </div>
      </Aux>
    );
  }
}

export default withRouter(LandingHeader3);
