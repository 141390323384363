// React Core
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// components
import { connect } from 'react-redux';
import WebsiteNavbar from './DefaultNavbar';
import Sidebar from './DefaultSidebar';
import classes from '../../assets/Scss/Layouts/DefaultLayout.scss';
import DefaultFooter from './DefaultFooter';

class DefaultLayout extends Component {
    render() {
        const { children, opened, isFooter } = this.props;
        document.body.classList.remove('bg-default');

        return (
            <div className={`${classes.DefaultLayout}`}>
                <Sidebar
                    {...this.props}
                />
                <div className={`main-content ${opened ? classes.navbarOpened : classes.navbarClosed}`} style={{ minHeight: 'calc(100vh - 100px)', paddingBottom: isFooter ? '100px' : 0 }}>
                    <WebsiteNavbar {...this.props} />
                    {children}
                    {isFooter && <DefaultFooter />}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    opened: state.Navbar.opened,
});

export default connect(mapStateToProps)(DefaultLayout);

DefaultLayout.propTypes = {
    isFooter: PropTypes.bool,
    opened: PropTypes.bool,
};

DefaultLayout.defaultProps = {
    isFooter: true,
    opened: false,
};
