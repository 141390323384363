import PropTypes from "prop-types";
import React, { Component, createRef } from "react";
import { CardBody, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import Aux from "../../hoc/Aux";
import PostEdit from "./PostEdit";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import "./custom.css";
import axios from "axios";
import * as cheerio from "cheerio";

class PostContent extends Component {
  constructor(props) {
    super(props);

    this.textContainerRef = createRef();

    this.state = {
      imagePreviewOpened: false,
      imagePreviewItem: 0,
      textContainerHeight: 0,
      textSeeMore: false,
      textContainerMaxHeight: 170,
      text: this.props.post.isRepost
        ? this.props.post.post.text
        : this.props.post.text,
      dataFromUrl: {
        // image:
        //   "https://img.lemde.fr/2023/09/12/520/0/6240/3120/1600/800/60/0/6c5a251_1694477715442-pl-seisme-maroc-03.jpg",
        // title:
        //   "Séisme au Maroc, en direct : le bilan provisoire passe à 2 901 morts et 5 530 blessés, selon le ministère de l’intérieur marocain",
        // url: new URL("https://news.google.com/articles/CBMiwQFodHRwczovL3d3dy5sZW1vbmRlLmZyL2FmcmlxdWUvbGl2ZS8yMDIzLzA5LzEwL3NlaXNtZS1hdS1tYXJvYy1lbi1kaXJlY3QtbGEtZnJhbmNlLWVzdC1wcmV0ZS1hLWFwcG9ydGVyLXVuZS1haWRlLXN1ci1sZS10ZXJyYWluLW91LWxlLWJpbGFuLXMtYWxvdXJkaXQtYXZlYy1wbHVzLWRlLTItMDAwLW1vcnRzXzYxODg1NzhfMzIxMi5odG1s0gEA?hl=fr&gl=FR&ceid=FR%3Afr").hostname,
      },
    };
  }

  componentDidMount() {
    this.setState({ textContainerHeight: this.divElement.clientHeight });
    this.handleSetImageInURL();
  }

  async handleSetImageInURL() {
    // CLOUD FUNCTIONS ->
    // const express = require('express');
    // const axios = require('axios');
    // const cors = require('cors');

    // const app = express();

    // app.use(cors());

    // app.get('/fetch-external-image', async (req, res) => {
    //   const { url } = req.query;

    // try {
    // const response = await axios.get(url);
    // const $ = cheerio.load(response.data);
    // const imgTags = $("img");
    // if (imgTags.length > 0)
    //    res.json({ image: imgTags.eq(0), title: $('title').text() });
    // else throw error
    //   } catch (error) {
    //     res.status(500).json({ error: 'Erreur' });
    //   }
    // });

    // exports.api = functions.https.onRequest(app); ;

    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const links = this.state.text.match(urlRegex);
    try {
      const response = await axios.get(
        "https://<region><project-id>.cloudfunctions.net/api/fetch-external-image", // set endpoint
        {
          params: { url: links[0] },
        }
      );
      this.setState({
        text: this.state.text.replace(links[0], " "),
        dataFromUrl: {
          image: response.data.image,
          title: response.data.title,
          url: new URL(links[0]).hostname,
        },
      });
    } catch (error) {}
  }

  render() {
    const {
      imagePreviewOpened,
      imagePreviewItem,
      textContainerHeight,
      textContainerMaxHeight,
      textSeeMore,
      text,
      dataFromUrl,
    } = this.state;
    const { post, editingPost, postMaxLength } = this.props;

    if (editingPost === post.id) {
      return <PostEdit post={post} postMaxLength={postMaxLength} />;
    }
    {
      this.textContainerRef.current?.clientHeight;
    }
    return (
      <Aux>
        <CardBody
          className={`py-2 px-3 ${post.isRepost ? "cursor-pointer" : ""}`}
          onClick={() => {
            if (post.isRepost) window.open(`post/${post.post.id}`);
          }}
        >
          <Row>
            <Col
              style={{
                maxHeight: !textSeeMore ? textContainerMaxHeight : 2500,
                overflow: "hidden",
              }}
            >
              <p
                className="p-0 m-0  post-text"
                ref={(divElement) => {
                  this.divElement = divElement;
                }}
              >
                {text}
              </p>
            </Col>
          </Row>
          {textContainerHeight >= textContainerMaxHeight && (
            <Row>
              <Col
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <span
                  onClick={() => {
                    this.setState({ textSeeMore: !textSeeMore });
                  }}
                  style={{
                    color: "var(--primary)",
                    cursor: "pointer",
                    fontSize: 14,
                  }}
                >
                  Voir {!textSeeMore ? "plus" : "moins"}
                </span>
              </Col>
            </Row>
          )}

          {dataFromUrl?.image && (
            <Col
              xs={12}
              className="p-0 mb-2 post-img-container cursor-pointer "
              onClick={() => window.open(dataFromUrl.url, "_blank")}
            >
              <img
                id="message "
                className="post-media post-image"
                src={dataFromUrl.image}
                alt="post"
                width="550"
              />
              <div
                style={{
                  width: "100%",
                  position: "absolute",
                  bottom: 0,
                  padding: 10,
                  backgroundColor: "rgba(0, 0, 0, 0.4)",
                }}
              >
                <span className="post-media-url-title">
                  {dataFromUrl.title}
                </span>
                <small className="post-media-url">{dataFromUrl.url}</small>
              </div>
              <div className="post-media-bottom-shadow" />
            </Col>
          )}
          {post.images && post.images.length > 1 && (
            <Row className="mt-3 mb-3">
              <Aux>
                {imagePreviewOpened && (
                  <Lightbox
                    mainSrc={post.images[imagePreviewItem]}
                    onCloseRequest={() =>
                      this.setState({ imagePreviewOpened: false })
                    }
                  />
                )}

                <Carousel showThumbs={false}>
                  {post.images.map((image, key) => {
                    const index = `images_${key}`;
                    return (
                      <Col
                        key={index}
                        xs={12}
                        className="p-0 mb-2 px-3 post-img-container"
                      >
                        <img
                          id="message "
                          className="post-media post-image"
                          src={image}
                          alt="post"
                          width="550"
                          onClick={() =>
                            this.setState({
                              imagePreviewItem: key,
                              imagePreviewOpened: true,
                            })
                          }
                        />
                        <div className="post-media-bottom-shadow" />
                      </Col>
                    );
                  })}
                </Carousel>
              </Aux>
            </Row>
          )}
          {post.images && post.images.length === 1 && (
            <Row className="mt-3 mb-3">
              <Aux>
                {imagePreviewOpened && (
                  <Lightbox
                    mainSrc={post.images[imagePreviewItem]}
                    onCloseRequest={() =>
                      this.setState({ imagePreviewOpened: false })
                    }
                  />
                )}
                <Col xs={12} className="p-0  px-3 mb-2 post-img-container">
                  <img
                    id="message"
                    className="post-media post-image"
                    src={post.images[0]}
                    alt="post"
                    width="550"
                    onClick={() =>
                      this.setState({
                        imagePreviewItem: 0,
                        imagePreviewOpened: true,
                      })
                    }
                  />
                </Col>
              </Aux>
            </Row>
          )}
          {post.videos && post.videos.length === 1 && (
            <div className="post-video-container">
              <video controls width="550" className="post-media post-video">
                <source src={post.videos[0]} />
              </video>
            </div>
          )}
          {post.videos && post.videos.length > 1 && (
            <Carousel showThumbs={false}>
              {post.videos.map((video, key) => {
                const index = `video_${key}`;

                return (
                  <div key={index} className="post-video-container">
                    <video
                      controls
                      width="550"
                      className="post-media post-video"
                    >
                      <source src={video} />
                    </video>
                    <div className="post-media-bottom-shadow" />
                  </div>
                );
              })}
            </Carousel>
          )}
        </CardBody>
      </Aux>
    );
  }
}

const mapStateToProps = (state) => ({
  editingPost: state.Posts.editingPostId,
});

export default connect(mapStateToProps)(PostContent);

PostContent.propTypes = {
  editingPost: PropTypes.string,
  post: PropTypes.object.isRequired,
  postMaxLength: PropTypes.number,
};

PostContent.defaultProps = {
  editingPost: "",
  postMaxLength: 0,
};
