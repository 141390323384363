import firebase from '@firebase/app';
import { db, firebaseApp } from '../../config/firebase';
import {
    SET_COMPANY, SET_COMPANY_EDITING_SECTION, SET_COMPANY_ERROR, SET_COMPANY_PENDING,
} from './types';
import * as moment from 'moment';
import axios from "axios";

const setIsPending = (isPending) => ({
    type: SET_COMPANY_PENDING,
    isPending,
});

const setCompany = (company) => ({
    type: SET_COMPANY,
    company,
});

const setError = (error) => ({
    type: SET_COMPANY_ERROR,
    error,
});

export const setEditingSection = (editingSection) => ({
    type: SET_COMPANY_EDITING_SECTION,
    editingSection,
});

/**
 *
 * @param username
 * @returns {Function}
 */
export const dbFetchCompany = (username) => async (dispatch) => {
    const userId = firebaseApp.auth().currentUser.uid;

    axios
        .post(
            "https://us-central1-tribu-app.cloudfunctions.net/getCompanyWithUsername",
            { username },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization:
                        "key=AAAAh3iPUF4:APA91bFfpDPkwEXRs7VZVOct9eQ0fmaDNnjQoHBKxYpcCwmxPL9koev3SggjQ5d7mWSTlzpgOs1tTlalSKebQ0_fFy0JPOkFGv4oLe9eAeSkCsGaldYlPqM89vvMT3wbI2du1BdjbZ4D",
                },
            }
        )
        .then((res) => {
            if (res.status === 200) {
                const companies = res.data.companies

                if (!companies.docs.length) {
                    dispatch(setError('Ce compte n\'a pas été trouvé !'));
                    return;
                }
    
                const company = {
                    id: companies.docs[0].id,
                    ownCompany: companies.docs[0].data().owners && companies.docs[0].data().owners[userId],
                    informations: {
                        ...companies.docs[0].data(),
                    },
                };
    
                dispatch(setCompany(company));
                dispatch(setIsPending(false));
            }
        });
    /*db.collection('companies')
        .where('username', '==', username)
        .limit(1)
        .get()
        .then((companies) => {
            if (!companies.docs.length) {
                dispatch(setError('Ce compte n\'a pas été trouvé !'));
                return;
            }

            const company = {
                id: companies.docs[0].id,
                ownCompany: companies.docs[0].data().owners && companies.docs[0].data().owners[userId],
                informations: {
                    ...companies.docs[0].data(),
                },
            };

            dispatch(setCompany(company));
            dispatch(setIsPending(false));
        });*/
};

// eslint-disable-next-line no-unused-vars
export const dbFollowCompany = (companyId) => (dispatch) => {
    const userId = firebaseApp.auth().currentUser.uid;

    const batch = db.batch();

    const companyRef = db.collection('companies').doc(companyId);
    batch.update(companyRef, { nbOfFollowers: firebase.firestore.FieldValue.increment(1), followers: firebase.firestore.FieldValue.arrayUnion(userId) });

    const companyFollowersRef = db.collection('companies').doc(companyId).collection('followers').doc(userId);
    batch.set(companyFollowersRef, { following: true, date: moment.now() });

    const userRef = db.collection('users').doc(userId);
    batch.update(userRef, { nbOfFollowing: firebase.firestore.FieldValue.increment(1), following: firebase.firestore.FieldValue.arrayUnion(companyId) });

    const userFollowingRef = db.collection('users').doc(userId).collection('following').doc(companyId);
    batch.set(userFollowingRef, { following: true, type: 'company', date: moment.now() });

    batch.commit();
};

// eslint-disable-next-line no-unused-vars
export const dbUnfollowCompany = (companyId) => (dispatch) => {
    const userId = firebaseApp.auth().currentUser.uid;

    const batch = db.batch();

    const companyRef = db.collection('companies').doc(companyId);
    batch.update(companyRef, { nbOfFollowers: firebase.firestore.FieldValue.increment(-1), followers: firebase.firestore.FieldValue.arrayRemove(userId) });

    const companyFollowersRef = db.collection('companies').doc(companyId).collection('followers').doc(userId);
    batch.delete(companyFollowersRef);

    const userRef = db.collection('users').doc(userId);
    batch.update(userRef, { nbOfFollowing: firebase.firestore.FieldValue.increment(-1), following: firebase.firestore.FieldValue.arrayRemove(companyId) });

    const userFollowingRef = db.collection('users').doc(userId).collection('following').doc(companyId);
    batch.delete(userFollowingRef);

    batch.commit();
};

// eslint-disable-next-line no-unused-vars
export const dbFollowUser = (userId) => (dispatch) => {
    const currentUserId = firebaseApp.auth().currentUser.uid;

    const batch = db.batch();

    const userRef = db.collection('users').doc(userId);
    batch.update(userRef, { nbOfFollowers: firebase.firestore.FieldValue.increment(1), followers: firebase.firestore.FieldValue.arrayUnion(currentUserId) });

    const userFollowersRef = db.collection('users').doc(userId).collection('followers').doc(currentUserId);
    batch.set(userFollowersRef, { following: true });

    const currentUserRef = db.collection('users').doc(currentUserId);
    batch.update(currentUserRef, { nbOfFollowing: firebase.firestore.FieldValue.increment(1), following: firebase.firestore.FieldValue.arrayUnion(userId) });

    const currentUserFollowingRef = db.collection('users').doc(currentUserId).collection('following').doc(userId);
    batch.set(currentUserFollowingRef, { following: true, type: 'user' });

    batch.commit();
};

// eslint-disable-next-line no-unused-vars
export const dbUnfollowUser = (userId) => (dispatch) => {
    const currentUserId = firebaseApp.auth().currentUser.uid;

    const batch = db.batch();

    const userRef = db.collection('users').doc(userId);
    batch.update(userRef, { nbOfFollowers: firebase.firestore.FieldValue.increment(-1), followers: firebase.firestore.FieldValue.arrayRemove(currentUserId) });

    const userFollowersRef = db.collection('users').doc(userId).collection('followers').doc(currentUserId);
    batch.delete(userFollowersRef);

    const currentUserRef = db.collection('users').doc(currentUserId);
    batch.update(currentUserRef, { nbOfFollowing: firebase.firestore.FieldValue.increment(-1), following: firebase.firestore.FieldValue.arrayRemove(userId) });

    const currentUserFollowingRef = db.collection('users').doc(currentUserId).collection('following').doc(userId);
    batch.delete(currentUserFollowingRef);

    batch.commit();
};