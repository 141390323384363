import { Link } from "react-router-dom";
import Aux from "../../../hoc/Aux";
import PageComponent from "../PageComponent";
import "../custom.css";

function EditCompany() {
  return (
    <Aux>
      <PageComponent>
        <h1 className="help-item-title">Modifier sa page entreprise</h1>
        <p className="help-item-text">
          Nous vous donnons ici tous les conseils pour que vous puissiez
          facilement compléter et/ou modifier votre page entreprise Tribu.
          <p className="help-item-subtitle">Personnes</p>
          Tous les dirigeants de l’entreprise et collaborateurs salariés ayant
          créé un compte Tribu.
          <p className="help-item-subtitle">Orias</p>
          Lors de la création de votre profil Tribu, votre page entreprise
          indiquera votre numéro Orias ainsi que les catégories (
          <b>
            <Link to="/search?q=IOBSP">IOBSP</Link> –{" "}
            <Link to="/search?q=IAS">IAS</Link> –{" "}
            <Link to="/search?q=CIF">CIF</Link> –{" "}
            <Link to="/search?q=ALPSI">ALPSI</Link> –{" "}
            <Link to="/search?q=CIP">CIP</Link> –{" "}
            <Link to="/search?q=IFP">IFP</Link>
          </b>
          ) dans lesquelles vous êtes enregistré auprès du registre unique des
          intermédiaires en assurance, banque et finance.
          <p className="help-item-subtitle">Services</p>
          Cette section vous permet de mettre en valeur votre expertise auprès
          des membres de la plateforme.
          <br />
          <br />
          En complétant la section "Services", vos compétences seront liées aux
          catégories communiquées par l’Orias.
          <br />
          <br />
          Enregistrez vos principales compétences parmi les groupes de services
          suivants et que vous proposez le plus fréquemment :
          <br />
          <br />
          <b>
            Crédit immobilier - Rachat de crédits - Crédit Professionnel –
            Epargne - Assurance famille - Assurance habitation - Assurance
            loisirs - Assurance santé - Assurance du véhicule - Assurance du
            chef d’entreprise - Assurance construction - Multirisque
            professionnelle - Responsabilité civile professionnelle - Assurance
            des salariés - Assurance des véhicules - Services de conseil en
            investissements financiers
          </b>
          <br />
          <br />
          Notez que des services sont renseignés par défaut lors de la création
          de votre profil afin que vous puissiez apparaître dans le moteur de
          recherche et dans les suggestions de profils aux membres.
          <br />
          <br />
          Lors de votre première connexion, vous pourrez affiner l’offre de
          services proposés.
          <br />
          <br />
          Se positionner en spécialiste est souvent plus efficace qu'un
          positionnement en généraliste (s'indexer dans tous les services que
          l'on connaît/peut exercer).
          <br />
          <br />
          En tant que généraliste, votre visibilité globale pourrait augmenter,
          mais cela se fera au détriment de la qualité perçue.
          <br />
          <br />
          Les services doivent correspondre à votre positionnement principal
          dans Tribu, celui que vous souhaitez le plus mettre en avant.
        </p>
      </PageComponent>
    </Aux>
  );
}

export default EditCompany;
