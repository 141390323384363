import React, { Component } from 'react';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import Banner from '../../assets/Pics/Icons/banner.png';

class SignupCorporateHeader extends Component {
    render() {
        return (
            <Col className="bg-gradient-warning order-2 align-items-center d-none d-md-flex" style={{minHeight:'100vh'}}>
                <div className="mx-auto text-center">
                    <Link to="/">
                        <img
                            className="mb-3 position-absolute top-3 right-6"
                            alt="Tribu"
                            src={Banner}
                            style={{ maxWidth: '130px' }}
                        />
                    </Link>
                    <h2 className="display-4 text-white">
                        Bienvenue parmi nous !
                    </h2>
                    <small className="text-white mt-4">
                        Déjà un compte ?
                    </small>
                    <br />
                    <Link to="/signin" className="btn btn-secondary mt-2 text-warning">
                        CONNECTEZ-VOUS
                    </Link>
                </div>
            </Col>
        );
    }
}

export default SignupCorporateHeader;
