import React, { Component } from 'react';
import {
    Button, Card, CardBody, CardHeader, Col, Row,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Aux from '../../hoc/Aux';
import DefaultProfile from '../../assets/Pics/defaultProfile.jpg';
import goldBadge from '../../assets/Pics/goldBadge.svg';
import blueBadge from '../../assets/Pics/blueBadge.svg';
import firebase from '@firebase/app';
import { db, firebaseApp } from '../../config/firebase';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import * as moment from 'moment';

class ProfileHeader2 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            profilePicOpened: false,
            profilePic: '',
            showBadge: false,
            showGoldBadge: false,
        };
    }

    currentUserId = firebaseApp.auth().currentUser.uid;

    componentDidMount() {
        db.collection('users')
            .doc(this.props.profile.id)
            .onSnapshot((documentSnapshot) => {
                this.setState({ profilePic: documentSnapshot.get('profilePic') })
                const email = documentSnapshot.get('email');
                const verified = documentSnapshot.get('verified');
                const premium = documentSnapshot.get('premium');
                if (email !== undefined && email !== null && email !== '' && verified === true) {
                    this.setState({ showBadge: true });
                }
                if (premium === true) {
                    this.setState({ showGoldBadge: true });
                }
            })
    }

    followUser = (userId) => {
        const currentUserId = firebaseApp.auth().currentUser.uid;

        const batch = db.batch();

        const userRef = db.collection('users').doc(userId);
        batch.update(userRef, { nbOfFollowers: firebase.firestore.FieldValue.increment(1), followers: firebase.firestore.FieldValue.arrayUnion(currentUserId) });

        const userFollowersRef = db.collection('users').doc(userId).collection('followers').doc(currentUserId);
        batch.set(userFollowersRef, { following: true, date: moment.now() });

        const currentUserRef = db.collection('users').doc(currentUserId);
        batch.update(currentUserRef, { nbOfFollowing: firebase.firestore.FieldValue.increment(1), following: firebase.firestore.FieldValue.arrayUnion(userId) });

        const currentUserFollowingRef = db.collection('users').doc(currentUserId).collection('following').doc(userId);
        batch.set(currentUserFollowingRef, { following: true, type: 'user', date: moment.now() });

        batch.commit();
    };

    unfollowUser = (userId) => {
        const currentUserId = firebaseApp.auth().currentUser.uid;

        const batch = db.batch();

        const userRef = db.collection('users').doc(userId);
        batch.update(userRef, { nbOfFollowers: firebase.firestore.FieldValue.increment(-1), followers: firebase.firestore.FieldValue.arrayRemove(currentUserId) });

        const userFollowersRef = db.collection('users').doc(userId).collection('followers').doc(currentUserId);
        batch.delete(userFollowersRef);

        const currentUserRef = db.collection('users').doc(currentUserId);
        batch.update(currentUserRef, { nbOfFollowing: firebase.firestore.FieldValue.increment(-1) });

        const currentUserFollowingRef = db.collection('users').doc(currentUserId).collection('following').doc(userId);
        batch.delete(currentUserFollowingRef);

        batch.commit();
    };

    render() {
        const {
            profilePicOpened,
        } = this.state;

        const {
            match,
            profile,
            followings,
            editing,
            toggleSocietyEditing,
            toggleEditing,
        } = this.props;

        const { informations } = profile;

        const { view } = match.params;
        const isSocietyNavActive = view === 'society';

        const isFollowing = followings.includes(profile.id);
        const userId = firebaseApp.auth().currentUser.uid;

        const toggle = () => {
            if (isSocietyNavActive) {
                toggleSocietyEditing()
            }
            else {
                toggleEditing()
            }
        }

        function capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        }

        return (
            <Aux>
                <Card className="card-profile shadow">
                    <Aux>
                        <Row className="justify-content-center">
                            <Col className="order-lg-2" lg="3">
                                <div
                                    className="card-profile-image"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => this.setState({ profilePicOpened: !profilePicOpened })}
                                >
                                    <img
                                        alt=''
                                        className="avatar rounded-circle"
                                        src={this.state.profilePic !== '' && this.state.profilePic !== undefined && this.state.profilePic !== null ? this.state.profilePic : DefaultProfile}
                                        style={{ width: '130px', height: '130px' }}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                            <Col className="col-auto float-left">
                                {
                                    !profile.ownProfile ? <div className="h5 font-weight-300">
                                        {isFollowing ? <Button size="sm" onClick={() => this.unfollowUser(profile.id)}>
                                            Ne plus suivre
                                        </Button> : <Button color="primary" size="sm" onClick={() => this.followUser(profile.id)}>
                                            Suivre
                                        </Button>}
                                    </div> : (
                                        <div className="d-flex justify-content-between">
                                            <Button
                                                className="float-right"
                                                size="sm"
                                                onClick={toggle}
                                            >
                                                {editing && isSocietyNavActive ? 'Enregistrer' : editing ? 'Visualiser le profil' : 'Editer'}
                                            </Button>
                                        </div>
                                    )
                                }
                            </Col>
                            <Col className="col-auto float-right">

                            </Col>
                        </CardHeader>
                        <CardBody className="pt-0 pt-md-4 mt-3">
                            <div className="text-center">
                                <h3>
                                    {`${capitalizeFirstLetter(informations.firstName)} ${informations.lastName.toUpperCase()}`}
                                    {
                                        this.state.showGoldBadge ?
                                        <>
                                           
                                                 <img
                                                    alt=''
                                                    className="avatar"
                                                    src={goldBadge}
                                                    style={{ aspectRatio: 1, width: '18px', height: '18px', marginBottom: '4px', backgroundColor: 'transparent', marginInline: 4 }}
                                                /> 
                                      
                                            </>
                                            :
                                            this.state.showBadge ?
                                            <img
                                            alt=''
                                            className="avatar"
                                            src={blueBadge}
                                            style={{ aspectRatio: 1, width: '18px', height: '18px', marginBottom: '4px', backgroundColor: 'transparent' ,  marginInline: 4}}
                                        />  : ' '
                                    }
                                    {
                                        profile.id === this.currentUserId ? <Link to={`/user/${profile.id}/contacts`}>
                                            <small>{`• ${informations.nbOfContacts || 0} ${informations.nbOfContacts === 1 || informations.nbOfContacts === 0 || !informations.nbOfContacts ? "contact" : "contacts"}`}</small>
                                        </Link> : <small>{`• ${informations.nbOfContacts || 0} ${informations.nbOfContacts === 1 || informations.nbOfContacts === 0 || !informations.nbOfContacts ? "contact" : "contacts"}`}</small>
                                    }
                                </h3>
                                <div className="h5">
                                    {informations.function}
                                </div>
                            </div>
                        </CardBody>
                    </Aux>
                </Card>
            </Aux>
        );
    }
}

const mapDispatchToProps = (state) => ({
    followings: state.User.user.followings,
    user: state.User.user,
});

export default withRouter(connect(mapDispatchToProps)(ProfileHeader2));
