import { combineReducers } from 'redux';
import * as actionTypes from './actionConsts';

import Auth from './auth/reducer';
import User from './user/reducer';
import Chat from './chat/reducer';
import ChatRecents from './chatRecents/reducer';
import Contacts from './contacts/reducer';
import Navbar from './navbar/reducer';
import Search from './search/reducer';
import Posts from './posts/reducer';
import Company from './company/reducer';

const appReducer = combineReducers({
    Auth,
    User,
    Chat,
    ChatRecents,
    Contacts,
    Navbar,
    Search,
    Posts,
    Company,
});

const rootReducer = (state, action) => {
    if (action.type === actionTypes.LOGOUT) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
