// React Core
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// components
import { NavLink as NavLinkRRD } from 'react-router-dom';
import { NavLink, NavItem } from 'reactstrap';

import sideBarClasses from '../../../assets/Scss/components/Sidebar.scss';

class Item extends Component {
    render() {
        const {
            path,
            setOpened,
            icon,
            title,
            exact,
        } = this.props;
        return (
            <NavItem className={`${sideBarClasses.Sidebar} mb-md--2 text-center`}>
                <NavLink
                    exact={exact}
                    to={path}
                    tag={NavLinkRRD}
                    onClick={setOpened}
                    activeClassName="active"
                >
                    <i className={`${icon} ${sideBarClasses.icon} d-block`} />
                    <span className={`${sideBarClasses.itemName} d-block`} style={{ color: 'rgba(0, 0, 0, 0.6)' }}>{title}</span>
                </NavLink>
            </NavItem>
        );
    }
}

Item.propTypes = {
    exact: PropTypes.bool,
    icon: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    setOpened: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};

export default Item;

Item.defaultProps = {
    exact: false,
};
