import React, { useEffect, useState } from 'react';
import {
    Badge,
    DropdownItem, DropdownMenu, DropdownToggle, ListGroup, ListGroupItem, NavItem, Row, Dropdown,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import classes from '../../assets/Scss/components/Notifications.scss';
import ContactRequest from '../../components/Notifications/ContactRequest';
import ContactRequest2 from '../../components/Notifications/ContactRequest2';
import { db, firebaseApp } from '../../config/firebase';
import Aux from '../../hoc/Aux';
import PremiumModal from '../../views/Premium/PremiumModal';

const DefaultNotificationsContacts = (props) => {
    const uid = firebaseApp.auth().currentUser.uid;
    const [requests, setRequests] = useState([]);
    const [requestsAccepted, setRequestsAccepted] = useState([]);
    const [isOpen, setOpen] = useState(false);
    const [hasOpened, setHasOpened] = useState(false);
    const [isCurrentUserPremium, setIsCurrentUserPremium] = useState(false);
    const [premiumModal, setPremiumModal] = useState(false);

    const fetchRequests = () => {
        db.collection('users')
            .doc(uid)
            .collection('contacts')
            .where('status', '==', 0)
            .where('nature', '==', 1)
            .onSnapshot(async (snapshots) => {
                const contacts = snapshots.docs
                    .map(async (doc) => db.collection('users')
                        .doc(doc.data().userId)
                        .get()
                        .then((contact) => {
                            const {
                                firstName, lastName, company, profilePic, username,
                            } = contact.data();

                            return {
                                author: {
                                    id: contact.id,
                                    name: `${firstName} ${lastName}`,
                                    profilePic,
                                    company: company || '',
                                    username,
                                },
                                createdAt: new Date(),
                            };
                        }));
                setRequests(await Promise.all(contacts))
            });
    };

    useEffect(() => {
        if (isOpen) {
            if (!hasOpened) setHasOpened(true)
        }
    }, [isOpen]);

    useEffect(() => {
        if (hasOpened) {
            db.collection("users").doc(uid).update({
                lastTimeContactNotificationOpened: Date.now()
            })
        }
    }, [hasOpened]);

    useEffect(() => {
        fetchRequests();
    }, []);

    useEffect(() => {
        fetchContactRequestsAccepted();
    }, []);

    useEffect(() => {
        db.collection('users').doc(uid).get().then((doc) => {
            const premium = doc.get("premium")
            setIsCurrentUserPremium(premium)
        })
    }, []);

    const fetchContactRequestsAccepted = () => {
        db.collection("users").doc(uid).get()
            .then((documentSnapshot) => {
                const lastTimeContactNotificationOpened = documentSnapshot.get("lastTimeContactNotificationOpened") ?? 0
                db.collection("users").doc(uid).collection("ContactRequestsAccepted").where("date", ">", lastTimeContactNotificationOpened).orderBy("date", "desc").get()
                    .then((querySnapshot) => {
                        setRequestsAccepted(querySnapshot.docs.map(visit => visit.get("userId")));
                    })
            })
    }

    let body = requests.slice(0, 5).map((request) => <ContactRequest key={request.author.id} request={request} isCurrentUserPremium={isCurrentUserPremium}
        toggle={() => {
            setPremiumModal(true)
        }} />);
    let body2 = requestsAccepted.slice(0, 5).map((userId) =>
        <Link to="/user/invitations">
            <ContactRequest2 key={userId} userId={userId} />
        </Link>
    );

    if (!requests.length && !requestsAccepted.length) {
        return (
            <NavItem>
                <Link to="/user/contacts" className="nav-link">
                    <i className="ni ni-circle-08" style={{ fontSize: '100%' }} />
                </Link>
            </NavItem>
        );
    }

    if (!requests.length && !requestsAccepted.length) {
        body = (
            <ListGroupItem className="list-group-item-action" key={78}>
                <Row className="align-items-center justify-content-center">
                    <p className="mb-0 font-weight-500">
                        Aucune demande.
                    </p>
                </Row>
            </ListGroupItem>
        );
    }

    return (
        <Aux>
            <PremiumModal isOpen={premiumModal} toggle={() => setPremiumModal(!premiumModal)} />
            <Dropdown nav className={classes.Notifications} isOpen={isOpen} toggle={() => setOpen(!isOpen)}>
                <DropdownToggle className="nav-link cursor-pointer" color="" tag="a">
                    <span>
                        <i className="ni ni-circle-08" />
                        <Badge color="primary" className={`${classes.notify} badge-md badge-circle badge-floating border-white`}>{requests.length + requestsAccepted.length}</Badge>
                    </span>
                </DropdownToggle>
                <DropdownMenu
                    className="dropdown-menu-xl py-0 overflow-hidden"
                >
                    <div className="px-3 py-3">
                        <h6 className="text-sm text-muted m-0">Demandes de contacts</h6>
                    </div>
                    <ListGroup flush>
                        {body2}
                        {body}
                    </ListGroup>
                    <DropdownItem className="text-center text-primary font-weight-bold py-3" to="/user/contacts" tag={Link}>
                        Voir tous les contacts
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </Aux>
    );
};

export default DefaultNotificationsContacts;
