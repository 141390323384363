import React, { Component } from "react";
import { Col, Container, Row, Badge, Card, CardBody } from "reactstrap";
import Aux from "../../hoc/Aux";
import DefaultSearchBar5 from "../Default/DefaultSearchBar5";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Img from "../../assets/Pics/tribu-localiser-un-courtier.svg";
import "../../App.css";
import { withRouter } from "react-router-dom";
import CITIES from "../../views/Search/cities";

class LandingHeader5 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentCityItem: undefined,
    };
  }

  componentDidMount() {
    this.handleSetCurrentCityItem();
  }

  componentDidUpdate(prevProps) {
    const urlCity = this.props.match.params.city;
    if (urlCity !== this.state.currentCityItem?.city)
      this.handleSetCurrentCityItem();
  }

  handleSetCurrentCityItem() {
    const urlCity = this.props.match.params.city;
    const currentCity = CITIES.find((el) => el.city === urlCity);
    if (currentCity) this.setState({ currentCityItem: currentCity });
    // else if (urlCity !== undefined) this.props.history.push("/404");
  }

  isVowel(char) {
    return "aeiouyAEIOUY".includes(char);
  }

  render() {
    const { currentCityItem } = this.state;
    return (
      <Aux>
        {currentCityItem ? (
          <Helmet>
            <title>Courtiers Tribu à {currentCityItem.city}</title>
            <meta
              name="description"
              content={`Trouvez rapidement sur Tribu un courtier pour votre projet à ${currentCityItem.city}`}
            />
            <meta
              name="keywords"
              content={`Courtier ${currentCityItem.city}, Courtier credit ${currentCityItem.city}, Courtier credits ${currentCityItem.city}, Courtier en assurance
            ${currentCityItem.city}, Courtier en assurances ${currentCityItem.city}, Conseiller en gestion de patrimoine ${currentCityItem.city}, Conseiller en
            investissements financiers ${currentCityItem.city}`}
            />
          </Helmet>
        ) : (
          <Helmet>
            <title>
              Il y a forcément un sympathique courtier Tribu dans votre ville
            </title>
            <meta
              name="description"
              content="Effectuez en quelques clics une recherche de compétence par zone géographique
                        ! Découvrez sur Tribu l'ensemble de nos courtiers dans les grandes villes de France."
            />
            <meta
              name="keywords"
              content="Courtier Agen, Courtier Ajaccio, Courtier Albi, Courtier Alençon, Courtier Amiens,
Courtier Angers, Courtier Angoulême, Courtier Annecy, Courtier Arras, Courtier Auch, Courtier
Aurillac, Courtier Auxerre, Courtier Avignon, Courtier Bar-le-Duc, Courtier Basse-Terre, Courtier
Bastia, Courtier Beauvais, Courtier Belfort, Courtier Besançon, Courtier Blois, Courtier Bobigny,
Courtier Bordeaux, Courtier Bourg-en-Bresse, Courtier Bourges, Courtier Caen, Courtier Cahors,
Courtier Carcassonne, Courtier Cayenne, Courtier Châlons-en-Champagne, Courtier Chambéry,
Courtier Charleville-Mézières, Courtier Chartres, Courtier Châteauroux, Courtier Chaumont, Courtier
Clermont-Ferrand, Courtier Colmar, Courtier Créteil, Courtier Digne-les-Bains, Courtier Dijon,
Courtier Epinal, Courtier Evreux, Courtier Evry, Courtier Foix, Courtier Fort-de-France, Courtier Gap,
Courtier Grenoble, Courtier Guéret, Courtier La Rochelle, Courtier La Roche-sur-Yon, Courtier Laon,
Courtier Laval, Courtier Le Mans, Courtier Le Puy-en-Velay, Courtier Lille, Courtier Limoges, Courtier
Lons-le-Saunier, Courtier Lyon, Courtier Mâcon, Courtier Marseille, Courtier Melun, Courtier Mende,
Courtier Metz, Courtier Mont-de-Marsan, Courtier Montauban, Courtier Montpellier, Courtier
Moulins, Courtier Nancy, Courtier Nanterre, Courtier Nantes, Courtier Nevers, Courtier Nice, Courtier
Nîmes, Courtier Niort, Courtier Orléans, Courtier Paris, Courtier Pau, Courtier Périgueux, Courtier
Perpignan, Courtier Poitiers, Courtier Pontoise, Courtier Privas, Courtier Quimper, Courtier Rennes,
Courtier Rodez, Courtier Rouen, Courtier Saint-Brieuc, Courtier Saint-Denis, Courtier Saint-Étienne,
Courtier Saint-Lô, Courtier Strasbourg, Courtier Tarbes, Courtier Toulon, Courtier Toulouse, Courtier
Tours, Courtier Troyes, Courtier Tulle, Courtier Valence, Courtier Vannes, Courtier Versailles, Courtier
Vesoul"
            />
          </Helmet>
        )}

        <div className="header bg-gradient-info pb-7">
          <Container>
            <div className="header-body">
              <Row className="justify-content-center">
                <img
                  style={{ transform: "translate(0, 60px)" }}
                  className="mb-3 bg-title-image"
                  alt="je suis courtier"
                  src={Img}
                />
              </Row>
              <Row className="justify-content-center align-items-center h-100 m-0">
                <Col className="text-white text-center">
                  <h3
                    className="display-2 text-white mt-5 font-weight-light"
                    style={{ lineHeight: "1.3", paddingTop: 100 }}
                  >
                    {currentCityItem ? "Courtiers à" : "Courtiers par"}
                    <br />
                    <span className="font-weight-bold">
                      {currentCityItem ? currentCityItem.city : "villes"}
                    </span>
                  </h3>
                  <h3
                    className="display-4 text-white mt-5 font-weight-light"
                    style={{ lineHeight: "1.3" }}
                  >
                    {currentCityItem
                      ? `Travaillez en toute sérénité avec les meilleurs courtiers ${
                          this.isVowel(currentCityItem.city[0]) ? "d'" : "de "
                        }${currentCityItem.city}`
                      : "Travaillez en toute sérénité avec les meilleurs courtiers partout en France"}
                  </h3>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg="8">
                  <div>
                    <div className="mt-5">
                      <DefaultSearchBar5 searchCity={currentCityItem?.city} />
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col className="text-white text-center">
                  <h3
                    className="display-4 text-white mt-5 font-weight-light"
                    style={{ lineHeight: "1.3" }}
                  >
                    Les professionnels du courtage sont sur Tribu
                  </h3>
                </Col>
              </Row>

              <Row className="mt-5 d-flex justify-content-center">
                <Col lg={currentCityItem ? 8 : 0}>
                  {currentCityItem
                    ? currentCityItem.description && (
                        <Card style={{ backgroundColor: "var(--lighter)" }}>
                          <CardBody>
                            <span
                              style={{
                                fontSize: 28,
                                marginBlockEnd: 10,
                                display: "flex",
                                alignSelf: "center",
                              }}
                            >
                              {currentCityItem.city}
                            </span>
                            <span>{currentCityItem.description}</span>
                          </CardBody>
                        </Card>
                      )
                    : CITIES.sort((a, b) => {
                        return a.city.localeCompare(b.city);
                      }).map((obj, key) => (
                        <Link to={`/search/villes/${obj.city}`}>
                          <Badge
                            className="mr-2 mb-2"
                            color="primary"
                            key={key}
                          >
                            {obj.city}
                          </Badge>
                        </Link>
                      ))}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </Aux>
    );
  }
}

export default withRouter(LandingHeader5);
