import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import routes from './Routes';
import * as roles from './Roles';

const checkRoles = (routeRoles, userRoles) => routeRoles && routeRoles.filter((element) => userRoles.includes(element)).length > 0;

const router = (user) => {
    const redirections = [];
    const returnRoutes = routes.map((route, key) => {
        const index = `routes_${key}`;

        if (!route.roles || (user.id && checkRoles(route.roles, user.roles)) || (!user.id && checkRoles(route.roles, [roles.DISCONNECTED]))) {
            return (
                <Route
                    exact
                    path={route.path}
                    render={(props) => (route.layout ? <route.layout {...props}><route.component {...props} /></route.layout> : <route.component {...props} />)}
                    key={index}
                />
            );
        }
        return redirections.push(<Redirect key={index} exact from={route.path} to={route.redirect || '/404'} />);
    });

    returnRoutes.push(
        [...redirections],
        <Redirect from="*" to="/404" key={returnRoutes.length} />,
    );
    return returnRoutes;
};

export default router;
