import React, { Component } from 'react';
import {
    CardHeader, Col, Label, Row
} from 'reactstrap';
import flipArrow from '../../assets/Scss/components/CollpaseArrow.scss';
import CollapseButton from '../../components/Ui/CollapseButton';
import Aux from '../../hoc/Aux/index';
import './custom.css';

class SearchServicesCIF extends Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: props.opened,
        };
    }

    servicesObj = [
        {
            id: 'assurance-vie',
            text: 'Assurance-vie',
        },
        {
            id: 'contrat_de_capitalisation',
            text: 'Contrat de capitalisation',
        },
        {
            id: 'consommation',
            text: 'Consommation',
        },
        {
            id: 'consommation_et_immobilier',
            text: 'Consommation et Immobilier',
        },
        {
            id: 'credit_professionnel',
            text: 'Crédit professionnel',
        },
        {
            id: 'credit_bail_immobilier',
            text: 'Crédit bail immobilier',
        },
        {
            id: 'credit_bail_mobilier',
            text: 'Crédit bail mobilier',
        },
        {
            id: 'location_financiere',
            text: 'Location financière',
        },
        {
            id: 'location_longue_duree',
            text: 'Location longue durée',
        },
        {
            id: 'affacturage',
            text: 'Affacturage',
        },
        {
            id: 'assurance_credit',
            text: 'Assurance crédit',
        },
        {
            id: 'compte-titres_pro',
            text: 'Compte-titres pro',
        },
        {
            id: 'compte_a_terme_pro',
            text: 'Compte à terme pro',
        },
        {
            id: 'compte-titres',
            text: 'Compte-titres',
        },
        {
            id: 'compte_a_terme',
            text: 'Compte à terme',
        },
        {
            id: 'pee',
            text: 'PEE',
        },
        {
            id: 'perco',
            text: 'PERCO',
        },
        {
            id: 'article_83',
            text: 'Article 83',
        },
        {
            id: 'girardin_industriel',
            text: 'Girardin industriel',
        },
        {
            id: 'pea',
            text: 'PEA',
        },
        {
            id: 'fip',
            text: 'FIP',
        },
        {
            id: 'fcpi',
            text: 'FCPI',
        },
        {
            id: 'scpi',
            text: 'SCPI',
        },
        {
            id: 'opci',
            text: 'OPCI',
        },
        {
            id: 'garantie_des_accidents_de_la_vie',
            text: 'Garantie des accidents de la vie',
        },
        {
            id: 'protection_juridique',
            text: 'Protection juridique',
        },
        {
            id: 'deces',
            text: 'Décès',
        },
        {
            id: 'scolaire',
            text: 'Scolaire',
        },
        {
            id: 'emprunteur',
            text: 'Emprunteur',
        },
        {
            id: 'garantie_obseques',
            text: 'Garantie obsèques',
        },
        {
            id: 'animaux_de_compagnie',
            text: 'Animaux de compagnie',
        },
        {
            id: 'dependance',
            text: 'Dépendance',
        },
        {
            id: 'rapatriement',
            text: 'Rapatriement',
        },
        {
            id: 'locataire',
            text: 'Locataire',
        },
        {
            id: 'propriétaire_non-occupant',
            text: 'Propriétaire non-occupant',
        },
        {
            id: 'plaisance',
            text: 'Plaisance',
        },
        {
            id: 'voyage',
            text: 'Voyage',
        },
        {
            id: 'chasse',
            text: 'Chasse',
        },
        {
            id: 'sport',
            text: 'Sport',
        },
        {
            id: 'complementaire_sante',
            text: 'Complémentaire santé',
        },
        {
            id: 'sur-complementaire',
            text: 'Sur-complémentaire',
        },
        {
            id: 'auto',
            text: 'Auto',
        },
        {
            id: '2_roues',
            text: '2 roues',
        },
        {
            id: 'voiture_sans_permis',
            text: 'Voiture sans permis',
        },
        {
            id: 'quad',
            text: 'Quad',
        },
        {
            id: 'camping-car',
            text: 'Camping-car',
        },
        {
            id: 'malusse',
            text: 'Malussé',
        },
        {
            id: 'prevoyance',
            text: 'Prévoyance',
        },
        {
            id: 'sante',
            text: 'Santé',
        },
        {
            id: 'retraite',
            text: 'Retraite',
        },
        {
            id: 'perte_d_emploi',
            text: 'Perte d’emploi',
        },
        {
            id: 'loi_madelin',
            text: 'Loi Madelin',
        },
        {
            id: 'responsabilite_civile_des_mandataires_sociaux',
            text: 'Responsabilité civile des mandataires sociaux',
        },
        {
            id: 'homme-cle',
            text: 'Homme-clé',
        },
        {
            id: 'dommage-ouvrage',
            text: 'Dommage-ouvrage',
        },
        {
            id: 'tous_risques_chantier',
            text: 'Tous risques chantier',
        },
        {
            id: 'constructeur_non_realisateur',
            text: 'Constructeur non réalisateur',
        },
        {
            id: 'responsabilite_civile_decennale',
            text: 'Responsabilité civile décennale',
        },
        {
            id: 'garantie_financiere_d_achevement',
            text: 'Garantie financière d’achèvement',
        },
        {
            id: 'responsabilite_civile_promoteur',
            text: 'Responsabilité civile promoteur',
        },
        {
            id: 'bris_de_machine',
            text: 'Bris de machine',
        },
        {
            id: 'dommage_aux_biens',
            text: 'Dommage aux biens',
        },
        {
            id: 'locaux_professionnels',
            text: 'Locaux professionnels',
        },
        {
            id: 'responsabilite_civile_d_exploitation',
            text: 'Responsabilité civile d’exploitation',
        },
        {
            id: 'protection_juridique',
            text: 'Protection juridique',
        },
        {
            id: 'perte_d_exploitation',
            text: 'Perte d’exploitation',
        },
        {
            id: 'risques_informatiques',
            text: 'Risques informatiques',
        },
        {
            id: 'agriculteur',
            text: 'Agriculteur',
        },
        {
            id: 'artisan',
            text: 'Artisan',
        },
        {
            id: 'association',
            text: 'Association',
        },
        {
            id: 'auto-entrepreneur',
            text: 'Auto-entrepreneur',
        },
        {
            id: 'commercant',
            text: 'Commerçant',
        },
        {
            id: 'profession_liberale',
            text: 'Profession libérale',
        },
        {
            id: 'prevoyance',
            text: 'Prévoyance',
        },
        {
            id: 'epargne_salariale',
            text: 'Epargne salariale',
        },
        {
            id: 'sante',
            text: 'Santé',
        },
        {
            id: 'retraite',
            text: 'Retraite',
        },
        {
            id: 'dependance',
            text: 'Dépendance',
        },
        {
            id: 'flotte_de_vehicules',
            text: 'Flotte de véhicules',
        },
        {
            id: 'transport_de_marchandises',
            text: 'Transport de marchandises',
        },
        {
            id: 'transport_de_voyageurs',
            text: 'Transport de voyageurs',
        },
        {
            id: 'flotte_motocycle',
            text: 'Flotte motocycle',
        },
    ];

    render() {
        const {
            opened,
        } = this.state;

        const {
            selectedServices,
            handleSelectService,
        } = this.props;

        const servicesEntreprise = [['Compte-titres pro', 'Compte à terme pro'], ['PEE', 'PERCO'], ['Article 83', 'Girardin industriel']];
        const servicesParticulier = [['PEA', 'Compte-titres'], ['Compte à terme', 'FIP'], ['FCPI', 'SCPI'], ['OPCI', '']];

        return (
            <Aux>
                <CardHeader className={`align-items-center ${flipArrow.FlipArrow}`} onClick={() => this.setState({ opened: !opened })}>
                    <Row>
                        <Col>
                            <h5 className="h4 mb--4">Services CIF</h5>
                            <br/>
                            <p>Sélectionner un ou plusieurs services</p>
                        </Col>
                        <Col className="col-auto">
                            <CollapseButton collapsed={!opened} />
                        </Col>
                    </Row>
                </CardHeader>
                {
                    opened && (
                        <div className="container">
                            <CardHeader className="pb-1">
                                <h5 className="h4 title">Particuliers</h5>
                                {
                                    servicesParticulier.map((servicesArray, key) => {
                                        const index1 = `services1_${key}_c`;
                                        const index2 = `services2_${key}_c`;
                                        let id1 = "";
                                        let id2 = "";
                                        this.servicesObj.forEach((obj) =>   {
                                            if(obj.text === servicesArray[0]) {
                                                id1 = obj.id
                                            }
                                        })
                                        this.servicesObj.forEach((obj) =>   {
                                            if(obj.text === servicesArray[1]) {
                                                id2 = obj.id
                                            }
                                        })
                                        const checked1 = selectedServices.includes(id1);
                                        const checked2 = selectedServices.includes(id2);

                                        return (
                                            <div class="row">
                                                <div className="custom-control custom-checkbox mb-3 item" key={index1}>
                                                    <input
                                                        className="custom-control-input"
                                                        id={index1}
                                                        type="checkbox"
                                                        name={servicesArray[0]}
                                                        checked={checked1}
                                                        onChange={handleSelectService}
                                                    />
                                                    <Label className="custom-control-label" htmlFor={index1}>
                                                        {servicesArray[0]}
                                                    </Label>
                                                </div>
                                                {
                                                    servicesArray[1] !== '' ? (
                                                        <div className="custom-control custom-checkbox mb-3 item" key={index2}>
                                                            <input
                                                                className="custom-control-input"
                                                                id={index2}
                                                                type="checkbox"
                                                                name={servicesArray[1]}
                                                                checked={checked2}
                                                                onChange={handleSelectService}
                                                            />
                                                            <Label className="custom-control-label" htmlFor={index2}>
                                                                {servicesArray[1]}
                                                            </Label>
                                                        </div>
                                                    ) : <div></div>
                                                }
                                            </div>
                                        );
                                    })
                                }
                                <h5 className="h4 title">Professionnels</h5>
                                {
                                    servicesEntreprise.map((servicesArray, key) => {
                                        const index1 = `services3_${key}_c`;
                                        const index2 = `services4_${key}_c`;
                                        let id1 = "";
                                        let id2 = "";
                                        this.servicesObj.forEach((obj) =>   {
                                            if(obj.text === servicesArray[0]) {
                                                id1 = obj.id
                                            }
                                        })
                                        this.servicesObj.forEach((obj) =>   {
                                            if(obj.text === servicesArray[1]) {
                                                id2 = obj.id
                                            }
                                        })
                                        const checked1 = selectedServices.includes(id1);
                                        const checked2 = selectedServices.includes(id2);

                                        return (
                                            <div class="row">
                                                <div className="custom-control custom-checkbox mb-3 item" key={index1}>
                                                    <input
                                                        className="custom-control-input"
                                                        id={index1}
                                                        type="checkbox"
                                                        name={servicesArray[0]}
                                                        checked={checked1}
                                                        onChange={handleSelectService}
                                                    />
                                                    <Label className="custom-control-label" htmlFor={index1}>
                                                        {servicesArray[0]}
                                                    </Label>
                                                </div>
                                                {
                                                    servicesArray[1] !== '' ? (
                                                        <div className="custom-control custom-checkbox mb-3 item" key={index2}>
                                                            <input
                                                                className="custom-control-input"
                                                                id={index2}
                                                                type="checkbox"
                                                                name={servicesArray[1]}
                                                                checked={checked2}
                                                                onChange={handleSelectService}
                                                            />
                                                            <Label className="custom-control-label" htmlFor={index2}>
                                                                {servicesArray[1]}
                                                            </Label>
                                                        </div>
                                                    ) : <div></div>
                                                }
                                            </div>
                                        );
                                    })
                                }
                            </CardHeader>
                        </div>
                    )
                }
            </Aux>
        );
    }
}

export default SearchServicesCIF;
