// React Core
import React, { Component } from 'react';

// components
import Container from 'reactstrap/es/Container';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import BannerBlue from '../assets/Pics/Icons/banner_beta_blue.png';
import DefaultFooter from '../layouts/Default/DefaultFooter';

class Error404 extends Component {
    render() {
        return (
            <Container className="h-100vh">
                <Row className="h-100 align-items-center">
                    <Col>
                        <Row>
                            <Col className="text-center" xs={12}>
                                <Link to="/">
                                    <img src={BannerBlue} alt="Tribu" width="200px" />
                                </Link>
                            </Col>
                            <Col className="text-center mt-3" xs={12}>
                                <h1 className="font-weight-light">404 | Page non trouvée !</h1>
                            </Col>
                            <Col className="text-center mt-5" xs={12}>
                                <Link className="h2 text-primary" to="/">
                                    Retour à l&apos;accueil
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <DefaultFooter />
            </Container>
        );
    }
}

export default Error404;
