import * as actionTypes from '../actionConsts';
import { firebaseApp, db } from '../../config/firebase';

// eslint-disable-next-line no-unused-vars
const setUserFetchError = (error) => ({
    type: actionTypes.SET_USER_ERROR,
    error,
});

const setUserFetching = (isFetching) => ({
    type: actionTypes.SET_USER_FETCHING,
    isFetching,
});

const setFetched = (isFetched) => ({
    type: actionTypes.SET_USER_FETCHED,
    isFetched,
});

const setUser = (user) => ({
    type: actionTypes.SET_USER,
    user,
});

/**
 * Get the companies that the user administrate.
 * @returns {Function}
 */
export const dbFetchCompanies = () => (dispatch, getState) => {
    const user = getState().User;

    const userId = firebaseApp.auth().currentUser.uid;
    db.collection('companies')
        .where(`owners.${userId}`, '==', true)
        .get()
        .then((snapshots) => {
            const companies = snapshots.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            dispatch(setUser({ ...user, companies }));
        });
};

export const dbFetchFollowing = () => (dispatch, getState) => {
    const userId = firebaseApp.auth().currentUser.uid;
    // eslint-disable-next-line no-unused-vars
    const user = getState().User;

    db.collection('users')
        .doc(userId)
        .collection('following')
        .onSnapshot((snapshots) => {
            const followings = snapshots.docs.map((doc) => doc.id);
            dispatch(setUser({ ...user, followings }));
        });
};

/**
 * @returns {Function}
 */
export const fetchUser = () => (dispatch) => {
    dispatch(setUserFetching(true));
    dispatch(setFetched(false));

    firebaseApp.auth().onAuthStateChanged((user) => {
        if (user) {
            db.collection('users')
                .doc(user.uid)
                .onSnapshot((snapshot) => {
                    dispatch(setUser({
                        id: user.uid,
                        mail: user.email,
                        name: `${snapshot.data().firstName} ${snapshot.data().lastName}`,
                        ...snapshot.data(),
                    }));
                    dispatch(dbFetchCompanies());
                    dispatch(dbFetchFollowing());
                    dispatch(setUserFetching(false));
                    dispatch(setFetched(true));
                });
        } else {
            dispatch(setFetched(true));
            dispatch(setUserFetching(false));
        }
    });
};
