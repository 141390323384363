import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import {
  Card,
  CardBody,
  Col,
  Input,
  InputGroup,
  ListGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import CardHeader from "reactstrap/es/CardHeader";
import CardFooter from "reactstrap/es/CardFooter";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ChatThreadMenu from "./ChatThreadMenu";
import ChatInput from "./ChatInput";
import {
  dbAddfile,
  dbAddmessage,
  dbAddpicture,
  dbAddvideo,
} from "../../store/chat/actions";
import ChatReceived from "./ChatReceived";
import { firebaseApp, db } from "../../config/firebase";
import ChatSent from "./ChatSent";
import ChatDate from "./ChatDate";
import moment from "moment";

const GroupedChatThread = (props) => {
  const [chat, setChat] = useState();
  const [seeMoreIsOpened, setSeeMoreIsOpened] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [contactsSearch, setContactsSearch] = useState("");
  const [contactsModalIsOpen, setContactsModalIsOpen] = useState(false);
  const [usersToRender, setUsersToRender] = useState();

  const { match } = props;

  const { chatId } = match.params;
  const uid = firebaseApp.auth().currentUser.uid;

  const fakeChat = {
    id: 1,
    users: [
      { id: "gilles-bedel", name: "Gilles Bedel" },
      {
        id: "gilles-bedel",
        name: "Avenir Courtage",
      },
      {
        id: "gilles-bedel",
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },

      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Thomas Paul",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
      {
        name: "Gilles Bedel",
      },
      {
        name: "Avenir Courtage",
      },
      {
        name: "Paul Lemarchand",
      },
      {
        name: "Robert Dupont",
      },
    ],
    created_at: 1686010239122,
    message:
      "Bonjour,\n\nNous voulons vous informer de nos nouvelles offres pour votre assurance automobile.\n\nCordialement.Bonjour,\n\nNous voulons vous informer de nos nouvelles offres pour votre assurance automobile.\n\nCordialement.Bonjour,\n\nNous voulons vous informer de nos nouvelles offres pour votre assurance automobile.\n\nCordialement.Bonjour,\n\nNous voulons vous informer de nos nouvelles offres pour votre assurance automobile.\n\nCordialement",
    attachments: [
      {
        name: "Explication.mp4",
        type: "video",
        size: 100,
        downloadURL:
          "https://firebasestorage.googleapis.com/v0/b/tribu-app.appspot.com/o/chat%2Fu2j9W8zlZsOO1hiqMq3Aj8sOvv03pBigUxpaNtQ0cgIFCCYbxsswj672%2FMAATHENA-Appli-Livreur-CHARGEMENT%2002.mp4?alt=media&token=9cc14a57-be71-4631-89ac-c1e1d4139250",
      },
      {
        name: "Liste de.txt",
        type: "file",
        size: 100,
        downloadURL:
          "https://firebasestorage.googleapis.com/v0/b/tribu-app.appspot.com/o/chat%2Fu2j9W8zlZsOO1hiqMq3Aj8sOvv03pBigUxpaNtQ0cgIFCCYbxsswj672%2FDocument%20sans%20titre.txt?alt=media&token=a539d02a-170b-474d-b432-0345793bc89c",
      },
    ],
  };

  const fetchChat = (chatId) => {
    // setMessages([]);

    setChat(fakeChat);
    setUsersToRender(fakeChat.users);

    // db.collection("users")
    //   .doc(uid)
    //   .collection("chats")
    //   .doc(chatId)
    //   .get()
    //   .then((doc) => ({
    //     id: doc.id,
    //     ...doc.data(),
    //   }))
    //   .then((chat) => {
    //     db.collection("chats")
    //       .doc(chatId)
    //       .get()
    //       .then((snapshot) => {
    //         setChat({
    //           ...snapshot.data(),
    //           ...chat,
    //         });
    //       });
    //   })
    //   .then(() => {
    //     db.collection("chats")
    //       .doc(chatId)
    //       .collection("messages")
    //       .orderBy("date", "asc")
    //       .onSnapshot((snapshots) => {
    //         const messages = snapshots.docs.map((snapshot) => ({
    //           id: snapshot.id,
    //           ...snapshot.data(),
    //         }));
    //         setMessages(messages);
    //       });
    //   });
  };

  if (!fetched) {
    setFetched(true);
    fetchChat(chatId);
  }

  const threadRef = useRef(null);

  useEffect(() => fetchChat(chatId), [chatId]);

  const toggleContactsModal = () => {
    setContactsModalIsOpen(!contactsModalIsOpen);
  };

  const strWithoutForm = (str) => {
    return str
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  };

  const searchContacts = (str) => {
    setContactsSearch(str);
    if (/\S/.test(str)) {

      var result = chat.users.filter(function(el) {
        return strWithoutForm(el.name).indexOf(strWithoutForm(str)) >= 0;
      });
      setUsersToRender(result);
    } else setUsersToRender(chat.users);
  };

  const contactsModal = (
    <Modal
      size="sm"
      isOpen={contactsModalIsOpen}
      toggle={toggleContactsModal}
      scrollable
    >
      <ModalBody className="align-items-center">
        <Row className="justify-content-center mb-2">
          <Col>
            <InputGroup className="w-100">
              <div
                style={{ borderRadius: 5 }}
                className="bg-secondary  d-flex align-items-center w-100"
              >
                <Input
                  autoFocus
                  placeholder="Rechercher un contact..."
                  style={{ height: 40, width: "100%" }}
                  value={contactsSearch}
                  onChange={(e) => {
                    searchContacts(e.target.value);
                  }}
                />
                <i
                  onClick={() => {
                    setContactsSearch("");
                  }}
                  className="fas fa-times pr-2 cursor-pointer"
                />
              </div>
            </InputGroup>
          </Col>
        </Row>
        {usersToRender &&
          usersToRender.map((user, index) => (
            <Link to={`/user/${user.id}/about`} style={{color: 'initial'}}>
              <p className="cursor-pointer text-m">{user.name}</p>
            </Link>
          ))}
      </ModalBody>
    </Modal>
  );

  const header = (
    <CardHeader>
      <Row>
        <Col className="col-auto pr-0 d-lg-none">
          <Link className="btn btn-link btn-sm" to="/chat">
            <i className="fas fa-arrow-left" style={{ fontSize: "17px" }} />
          </Link>
        </Col>
        <Col className="d-flex  align-items-center justify-content-space-between">
          <h5 className="h2 mb-0 font-weight-normal" style={{ flex: 1 }}>
            {chat ? (
              <>
                À : {` `}
                {chat.users.map((user, index) => {
                  if (index >= 3) return;
                  return (
                    <>
                      {user.name}
                      {2 - index > 0 ? ", " : ""}
                    </>
                  );
                })}
                {chat.users.length > 3 && (
                  <>
                    {` `} et {chat.users.length - 3}{" "}
                    {chat.users.length === 4 ? "autre" : "autres"}
                    <small
                      onClick={toggleContactsModal}
                      className="m-0 text-xs font-weight-medium cursor-pointer ml-2"
                    >
                      Afficher tous
                    </small>
                  </>
                )}
              </>
            ) : (
              "Conversation"
            )}
          </h5>
          <h5 className="m-0 grouped-message-date">
            {" "}
            {moment(new Date(chat?.created_at)).format("LLL")}
          </h5>
        </Col>
      </Row>
    </CardHeader>
  );

  return (
    <Card className="shadow h-100">
      {header}
      <CardBody className="scroll-body" style={{ transition: "1s" }}>
        <Row>
          <Col>
            <p className="grouped-message-text">{chat?.message}</p>
            <div className="float-left clear" ref={threadRef} />
          </Col>
        </Row>
        <Row>
          <Col className="d-flex flex-wrap">
            {chat?.attachments.map((attachment) => {
              return (
                <a target="_blank" href={attachment.downloadURL}>
                  <div
                    className="p-4 cursor-pointer rounded bg-secondary mr-4 d-flex flex-column align-items-center"
                    style={{ maxWidth: 125, width: '100%' }}
                  >
                    <div className="mb-2"> 

                  
                    {attachment.type === "image" ? (
                      <i class="fas fa-file-image" style={{ fontSize: 40 }}></i>
                    ) : attachment.type === "video" ? (
                      <i class="fas fa-file-video" style={{ fontSize: 40 }}></i>
                    ) : (
                      <i class="fas fa-file" style={{ fontSize: 40 }}></i>
                    )}
                      </div>
                    <p
                      className="m-0"
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textAlign: "center",
                        color: "gray",
                        fontSize: 14,
                        maxWidth: '100%'
                      }}
                    >
                      {attachment.name}
                    </p>
                  </div>
                </a>
              );
            })}
          </Col>
        </Row>
      </CardBody>
      {contactsModal}
    </Card>
  );
};

export default connect(null, {
  addMessage: dbAddmessage,
  addPicture: dbAddpicture,
  addVideo: dbAddvideo,
  addFile: dbAddfile,
})(GroupedChatThread);

GroupedChatThread.propTypes = {
  addVideo: PropTypes.func,
  addPicture: PropTypes.func,
  addMessage: PropTypes.func,
  addFile: PropTypes.func,
};

GroupedChatThread.defaultProps = {
  addVideo: () => {},
  addPicture: () => {},
  addMessage: () => {},
  addFile: () => {},
};