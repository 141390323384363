import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import CardHeader from "reactstrap/es/CardHeader";
import {
  Card,
  CardBody,
  Col,
  DropdownMenu,
  Dropdown,
  DropdownToggle,
  Input,
  InputGroup,
  ListGroup,
  Row,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";
import Button from "reactstrap/es/Button";
import { connect } from "react-redux";
import ChatRecent from "./ChatRecent";
import {
  dbFetchChats,
  dbFetchGroupedChats,
  setChatSelectedTab,
} from "../../store/chatRecents/actions";
import { withRouter } from "react-router-dom";
import GroupedChatRecent from "./GroupedChatRecent";

const ChatRecents = (props) => {
  const {
    fetchChats,
    chats,
    selectedChatTab,
    chatTabs,
    setChatSelectedTabb,
    groupedChats,
    fetchGroupedChats,
  } = props;
  const chatsRef = useRef([]);
  const [openMenuMode, setOpenMenuMode] = useState(false);
  const [searchContactValue, setSearchContactValue] = useState("");
  const [searchMode, setSearchMode] = useState(false);
  const [searchInputStyle, setSearchInputStyle] = useState();
  const [chatsToRender, setChatsToRender] = useState(chats);
  const { chatId } = useParams();

  useEffect(() => {
    fetchChats();
    fetchGroupedChats();
  }, []);


  useEffect(() => {
    setSearchModee(searchMode);
  }, [chats]);

  useEffect(() => {
    chatsRef.current = chatsRef.current?.slice(0, chatsToRender?.length);
  }, [chatsToRender]);

  function setSearchModee(value) {
    if (value) {
      setSearchMode(value);
      setTimeout(() => {
        setSearchInputStyle({ height: 40, width: 250 });
      }, 1);
    } else {
      setTimeout(() => {
        setSearchMode(value);
      }, 125);
      setSearchInputStyle({ height: 40, width: 40 });
      searchContact("");
    }
  }

  useEffect(() => {
    searchContact("");
    setSearchModee(false);
  }, [window.location.pathname]);

  useEffect(() => {
    if (chatId && chatsToRender) {
      setTimeout(() => {
        const indexOfConversationToScrollTo = chatsToRender.findIndex(
          (el) => el.id === chatId
        );
        if (indexOfConversationToScrollTo > -1)
          chatsRef.current[indexOfConversationToScrollTo]?.scrollIntoView({
            behavior: "smooth",
          });
      }, 500);
    }
  }, [window.location.pathname, chatsToRender]);

  useEffect(() => {
    handleTabSelection(selectedChatTab);
  }, [selectedChatTab, window.location.pathname]);

  const handleTabSelection = (index) => {
    if (chatTabs[index] === "Messages") setChatsToRender(chats);
    else if (chatTabs[index] === "Envoyés en groupe")
      setChatsToRender(groupedChats);
    setChatSelectedTabb(index);
    setOpenMenuMode(false);
  };

  const strWithoutForm = (str) => {
    return str
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  };

  function searchContact(str) {
    setSearchContactValue(str);
    if (/\S/.test(str)) {
      var result = chats.filter(function(el) {
        return strWithoutForm(el.name).indexOf(strWithoutForm(str)) >= 0;
      });
      setChatsToRender(result);
    } else setChatsToRender(chats);
  }


  const header = (
    <CardHeader>
      <Row className="align-items-center">
        <Col className={`col-auto pr-0 `}>
          <InputGroup>
            {searchMode ? (
              <div
                style={{ borderRadius: 5 }}
                className="animated-input bg-secondary  d-flex align-items-center"
              >
                <Input
                  autoFocus
                  placeholder="Rechercher un contact..."
                  style={searchInputStyle}
                  value={searchContactValue}
                  onChange={(e) => {
                    searchContact(e.target.value);
                  }}
                />
                <i
                  onClick={() => {
                    setSearchModee(!searchMode);
                  }}
                  className="fas fa-times pr-2 cursor-pointer"
                />
              </div>
            ) : (
              <div
                onClick={() => {
                  setSearchModee(!searchMode);
                }}
                className="animated-input rounded-circle  bg-secondary cursor-pointer d-flex align-items-center justify-content-center"
                style={searchInputStyle}
              >
                <i className="fas fa-search" />
              </div>
            )}
          </InputGroup>
        </Col>

        {!searchMode && (
          <div
            className="d-flex justify-content-between w-100 "
            style={{ flex: 1 }}
          >
            <Dropdown
              isOpen={openMenuMode}
              // toggle={() => setOpenMenuMode(!openMenuMode)}
            >
              <DropdownToggle
                //add toggle and disabled w/ comments
                disabled               
                tag="div"
                type="div"
                className="form-control m-0 p-0 bg-white"
              >
                <div className="col d-flex align-items-center cursor-pointer">
                  <h4 className="h2 mb-0 pl-0 font-weight-normal pr-2">
                    {chatTabs[selectedChatTab]}
                  </h4>
                  {/* <i class="fas fa-angle-down "></i> */}
                </div>
              </DropdownToggle>
              <DropdownMenu left>
                {chatTabs.map((tab, index) => {
                  if (index === selectedChatTab) return;
                  else
                    return (
                      <DropdownItem
                        className="cursor-pointer d-flex "
                        onClick={() => {
                          handleTabSelection(index);
                        }}
                      >
                        <h4 className="h4 mb-0 pl-0 font-weight-normal pr-2">
                          {tab}
                        </h4>
                      </DropdownItem>
                    );
                })}
              </DropdownMenu>
            </Dropdown>
            <Col className="col-auto">
              <Link to="/chat/compose">
                <Button size="sm" color="primary">
                  <i className="fas fa-pen" />
                </Button>
              </Link>
            </Col>
          </div>
        )}
      </Row>
    </CardHeader>
  );

  return (
    <Card className="shadow h-100">
      {header}
      <CardBody className="p-0 scroll-body">
        <ListGroup flush>
          {chatsToRender.length > 0 ? (
            chatTabs[selectedChatTab] === "Messages" ? (
              chats
                .sort((a, b) => b.lastMessage.date - a.lastMessage.date)
                .map((chat, i) => (
                  <div ref={(el) => (chatsRef.current[i] = el)}>
                    <ChatRecent key={chat.name} chat={chat} />
                  </div>
                ))
            ) : (
              groupedChats
                .sort((a, b) => b.lastMessage.date - a.lastMessage.date)
                .map((chat, i) => (
                  <div ref={(el) => (chatsRef.current[i] = el)}>
                    <GroupedChatRecent key={chat.name} chat={chat} />
                  </div>
                ))
            )
          ) : (
            // (
            //   chatsToRender
            //     .sort((a, b) => b.lastMessage.date - a.lastMessage.date)
            //     .map((chat, i) => (
            //       <div ref={(el) => (chatsRef.current[i] = el)}>
            //         {chatTabs[selectedChatTab] === "Messages"  ?(
            //           <ChatRecent key={chat.name} chat={chat} />
            //         ) : (
            //           <GroupedChatRecent key={chat.name} chat={chat} /> )
            //
            //         }
            //       </div>
            //     ))
            // )
            <span className="mt-2 text-sm p-4 text-center">
              Aucune conversation
            </span>
          )}
        </ListGroup>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  chats: state.ChatRecents.chats,
  groupedChats: state.ChatRecents.groupedChats,
  chatTabs: state.ChatRecents.chatTabs,
  selectedChatTab: state.ChatRecents.selectedChatTab,
});

export default connect(mapStateToProps, {
  fetchChats: dbFetchChats,
  fetchGroupedChats: dbFetchGroupedChats,
  setChatSelectedTabb: setChatSelectedTab,
})(withRouter(ChatRecents));

ChatRecents.propTypes = {
  chats: PropTypes.array,
  groupedChats: PropTypes.array,
  selectedChatTab: PropTypes.number,
  chatTabs: PropTypes.array,
  fetchChats: PropTypes.func,
  setChatSelectedTabb: PropTypes.func,
};

ChatRecents.defaultProps = {
  chats: [],
  groupedChats: [],
  chatTabs: [],
  selectedChatTab: 0,
  fetchChats: () => {},
  setChatSelectedTabb: () => {},
};