import React, { Component } from 'react';
import Aux from '../../hoc/Aux';
import LandingNavbar from '../../layouts/Landing/LandingNavbar';
import LandingHeader5 from '../../layouts/Landing/LandingHeader5';
import DefaultFooter from '../../layouts/Default/DefaultFooter';

class SearchWithCities extends Component {
    render() {
        return (
            <Aux>
                <div className="main-content">
                    <LandingNavbar />
                    <LandingHeader5 />
                    <Aux>
                        <section className="section pt-6 bg-white">
                            <DefaultFooter />
                        </section>
                    </Aux>
                </div>
            </Aux>
        );
    }
}

export default SearchWithCities;