import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
    Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
} from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import NotificationAlert from 'react-notification-alert';
import config from '../../config/config.json';
import Aux from '../../hoc/Aux';

class PostShare extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isMenuOpen: false,
        };
    }

    linkCopiedNotification = () => {
        const options = {
            place: 'br',
            message: (
                <div className="alert-text">
                    <span className="" data-notify="title">
                        Le lien a été copié dans le presse papier
                    </span>
                </div>
            ),
            type: 'default',
            icon: 'far fa-clipboard',
            autoDismiss: 1,
            closeButton: false,
        };
        this.linkCopiedRef.notificationAlert(options);
    };

    render() {
        const {
            isMenuOpen,
        } = this.state;

        const {
            id,
        } = this.props;

        return (
            <Aux>
                <Dropdown isOpen={isMenuOpen} toggle={() => this.setState({ isMenuOpen: !isMenuOpen })}>
                    <DropdownToggle
                        tag="span"
                        onClick={this.toggle}
                        data-toggle="dropdown"
                        className="btn-sm pr-0"
                        style={{ cursor: 'pointer' }}
                    >
                        <i className="fas fa-share " style={{fontSize: 15}} ></i>
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem>
                            <CopyToClipboard
                                text={`${window.location.origin}/post/${id}`}
                                onCopy={() => this.linkCopiedNotification()}
                            >
                                <span>Copier le lien</span>
                            </CopyToClipboard>
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
                <div className="rna-wrapper">
                    <NotificationAlert ref={(linkCopied) => {
                        this.linkCopiedRef = linkCopied;
                    }}
                    />
                </div>
            </Aux>
        );
    }
}

export default PostShare;

PostShare.propTypes = {
    id: PropTypes.string,
};

PostShare.defaultProps = {
    id: '',
};
