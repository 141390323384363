import { Link } from "react-router-dom";
import Aux from "../../../hoc/Aux";
import PageComponent from "../PageComponent";
import "../custom.css";

function TribuPremium() {
  return (
    <Aux>
      <PageComponent>
        <h1 className="help-item-title">Tribu Premium</h1>
        <p className="help-item-text">
          Tribu Premium est une option payante car de nombreuses fonctionnalités
          nécessitent des dépenses supplémentaires à des tiers tels que les
          fournisseurs de centres de données et les fabricants de serveurs.
          Tribu Premium permet d’aider Tribu à développer de nouvelles
          fonctionnalités au bénéfice de tous les utilisateurs.
          <br />
          <br />
          Les abonnements sont gérés par Stripe qui enverra automatiquement une
          facture le jour du renouvellement de votre abonnement en fonction de
          la formule choisie.
          <br />
          <br />
          Vous pouvez à tout moment résilier votre abonnement. Veuillez noter
          que tout abonnement entamé est dû.
        </p>
      </PageComponent>
    </Aux>
  );
}

export default TribuPremium;
