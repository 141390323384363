import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Badge, Card, CardBody, Col, Row } from "reactstrap";
import CardHeader from "reactstrap/es/CardHeader";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import DefaultProfile from "../../assets/Pics/defaultProfile.jpg";
import { db, firebaseApp } from "../../config/firebase";
import PremiumModal from "../Premium/PremiumModal";
import Aux from "../../hoc/Aux";

const ChatProfile = (props) => {
  const { chat, chatId } = props;
  const [profile, setProfile] = useState();
  const [society, setSociety] = useState();
  const [companyUsername, setCompanyUsername] = useState('');
  const [premium, setPremium] = useState(false);
  const [premiumModal, setPremiumModal] = useState(false);

  useEffect(() => {
    if (chatId !== undefined && chatId !== null && chatId !== '') {
      const uid = firebaseApp.auth().currentUser.uid;
      db.collection("chats")
        .doc(chatId)
        .get()
        .then((doc) => {
          if (doc.data()) {
            const userId = doc.data().users[0].userId === uid ? doc.data().users[1].userId : doc.data().users[0].userId
            db.collection("users")
              .doc(userId)
              .get()
              .then((doc) => {
                if (doc.data()) {
                  setProfile(doc.data());

                  if (doc.data().initialCompany !== undefined && doc.data().initialCompany !== null && doc.data().initialCompany !== '') {
                    db.collection("companies")
                      .doc(doc.data().initialCompany)
                      .get()
                      .then((doc) => {
                        setCompanyUsername(doc.data().username)
                      })
                  }
                }
              });

            db.collection("usersSociety")
              .doc(userId)
              .get()
              .then((doc) => {
                if (doc.data()) {
                  const { informations } = doc.data();
                  setSociety(informations.denomination);
                }
              });
          }
        });
    }
  }, [chatId]);

  useEffect(() => {
    const userId = firebaseApp.auth().currentUser.uid;
    db.collection('users').doc(userId).get().then((doc) => {
      const premium = doc.get("premium")
      setPremium(premium)
    })
  }, []);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  const header = (
    <CardHeader>
      <Row className="align-items-center">
        <Col className="col-auto pr-0 d-lg-none">
          <Link className="btn btn-link btn-sm" to="/chat">
            <i className="fas fa-arrow-left" style={{ fontSize: "17px" }} />
          </Link>
        </Col>
        <div className="col">
          <h5 className="h2 mb-0 font-weight-normal">
            {chat.name || "Conversation"}
          </h5>
        </div>
        <Col className="col-auto"></Col>
      </Row>
    </CardHeader>
  );

  return (
    <Aux>
      <PremiumModal isOpen={premiumModal} toggle={() => setPremiumModal(!premiumModal)} />
      <Card className="shadow d-flex flex-column h-100">
        {profile && (
          <CardBody
            className="d-flex align-items-center justify-content-center "
            style={{ flex: 1 }}
          >
            <Row className="align-items-center">
              <Col className="text-center" xs={12}>
                {
                  premium ?
                    <Link target="_blank" to={`/user/${profile.username}/about`}>
                      <img
                        className="avatar rounded-circle mb-2 cursor-pointer"
                        alt=""
                        src={profile.profilePic ? profile.profilePic : DefaultProfile}
                        style={{ width: "75px", height: "75px" }}
                      />
                    </Link>
                    :
                    <img
                      className="avatar rounded-circle mb-2 cursor-pointer"
                      alt=""
                      src={profile.profilePic ? profile.profilePic : DefaultProfile}
                      onClick={() => setPremiumModal(true)}
                      style={{ width: "75px", height: "75px" }}
                    />
                }
              </Col>
              <Col className="text-center" xs={12}>
                {profile.name === undefined ? (
                  <h3 className=" overflow-wrap">
                    {`${capitalizeFirstLetter(
                      profile.firstName
                    )} ${profile.lastName.toUpperCase()}`}
                  </h3>
                ) : (
                  <h3>{`${profile.name}`}</h3>
                )}
              </Col>
            </Row>
          </CardBody>
        )}

        <div style={{ width: '100%', height: 1, backgroundColor: "var(--secondary)" }} />

        {society && (
          <CardBody
            className="d-flex align-items-center justify-content-center "
            style={{ flex: 1 }}
          >
            <Row className="align-items-center">
              <Col className="text-center" xs={12}>
                {
                  premium ?
                    <Link target="_blank" to={`/company/${companyUsername}/about`}>
                      <img
                        className="avatar rounded-circle mb-2 cursor-pointer"
                        alt=""
                        src={`https://avatars.dicebear.com/v2/initials/${society}.svg?options[backgroundColors][0]=blue`}
                        style={{ width: "75px", height: "75px" }}
                      />
                    </Link>
                    :
                    <img
                      className="avatar rounded-circle mb-2 cursor-pointer"
                      alt=""
                      src={`https://avatars.dicebear.com/v2/initials/${society}.svg?options[backgroundColors][0]=blue`}
                      onClick={() => setPremiumModal(true)}
                      style={{ width: "75px", height: "75px" }}
                    />
                }
              </Col>
              <Col className="text-center" xs={12}>
                <h3>{`${society}`}</h3>
                <p>
                  {profile.categories &&
                    profile.categories.map((category, key) => {
                      const index = `categories_${key}`;

                      return (
                        <Badge key={index} color="primary" className="mr-2">
                          {category}
                        </Badge>
                      );
                    })}
                </p>
                {profile.city && (
                  <p>
                    <span role="img" aria-label="pin">
                      📍
                    </span>{" "}
                    {capitalizeFirstLetter(profile.city.toLowerCase())}
                  </p>
                )}
              </Col>
            </Row>
          </CardBody>
        )}
      </Card>
    </Aux>
  );
};

const mapStateToProps = (state) => ({
  chat: state.Chat.chat,
});

export default connect(mapStateToProps, {})(ChatProfile);

ChatProfile.propTypes = {
  chat: PropTypes.object,
};

ChatProfile.defaultProps = {
  chat: {},
};