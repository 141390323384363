import PropTypes from "prop-types";
import React, { Component } from "react";
import classes from "../../assets/Scss/components/PublishModal.scss";

class PublishPic extends Component {
  componentDidMount() {
    this.scrollToRight();
  }

  componentDidUpdate(prevProps) {
    const { images } = this.props;

    if (images.length !== prevProps.images.length) {
      this.scrollToRight();
    }
  }

  scrollToRight = () => {
    const { scrollWidth } = this.publishAddImageRef;
    const width = this.publishAddImageRef.clientWidth;
    const maxScrollLeft = scrollWidth - width;
    this.publishAddImageRef.scrollLeft = maxScrollLeft > 0 ? maxScrollLeft : 0;
  };

  render() {
    const { images, addImage, deleteImage } = this.props;

    return (
      <div className={classes.PublishModal}>
        <div
          className={classes.images}
          ref={(publishAddImageRef) => {
            this.publishAddImageRef = publishAddImageRef;
          }}
        >
          {images.map((image, key) => {
            return (
              <div
                className={`${classes.previews} mr-2 `}
                key={image.img || image}
              >
                <div className="post-img-container ">
                  <img
                  className="post-image"
                    id="message"
                    src={image.img || image}
                    alt="chat"
                    height="200"
                  />
                </div>

                <div
                  className={classes.discard}
                  onClick={() => deleteImage(key)}
                >
                  <i className="fas fa-times" />
                </div>
              </div>
            );
          })}
          {images.length > 0 && (
            <div className={classes.addImage} onClick={addImage}>
              <div className={classes.add}>
                <i className="fas fa-plus-square" />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default PublishPic;

PublishPic.propTypes = {
  addImage: PropTypes.func,
  deleteImage: PropTypes.func,
  images: PropTypes.array,
};

PublishPic.defaultProps = {
  addImage: () => {},
  deleteImage: () => {},
  images: [],
};
