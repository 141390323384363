import * as actionTypes from "../actionConsts";
import algolia from "../../config/algolia";
import { db } from "../../config/firebase";

const setPending = (isPending) => ({
  type: actionTypes.SET_SEARCH_PENDING,
  isPending,
});

const setResults = (results) => ({
  type: actionTypes.SET_SEARCH_RESULTS,
  results,
});

export const actions =
  (index, query, categories, services, city, department) =>
  async (dispatch) => {
    dispatch(setPending(true));
    dispatch(setResults([]));

    const algoliaIndex = algolia.initIndex(index);

    const categoryFilters = categories?.length
      ? categories.split(" ").map((category) => `categories:${category}`)
      : [];
    const serviceFilters = services?.length
      ? services.split(" ").map((service) => `servicesID:${service}`)
      : [];
    const cityFilter = city?.length
      ? city.split(" ").map((x) => `city:${x}`)
      : [];
    const departmentFilter = department?.length
      ? department.split(" ").map((x) => `department:${x}`)
      : [];

    algoliaIndex
      .search(query.toString(), {
        facetFilters: categoryFilters
          .concat(serviceFilters)
          .concat(cityFilter)
          .concat(departmentFilter),
      })
      .then(({ hits }) => {
        dispatch(setResults(hits));
        dispatch(setPending(false));
      });
  };

/*export const actions2 = (query) => async (dispatch) => {
    dispatch(setPending(true));
    dispatch(setResults([]));

    const algoliaIndex = algolia.initIndex("companies");

    algoliaIndex.search(query.toString()).then(({ hits }) => {
        const owners = hits.map((hit) => hit.owners)
        const usersPromise = [];

        owners.forEach((obj) => {
            if (obj !== {}) {
                Object.keys(obj).forEach((key) => {
                    if (obj[key]) {
                        const userId = key;
                        usersPromise.push(
                            db
                                .collection('users')
                                .doc(userId)
                                .get()
                                .then((doc) => doc.data()));
                    }
                });
            }
        });

        Promise.all(usersPromise).then((users) => {
            dispatch(setResults(hits.concat(users)));
            dispatch(setPending(false));
        })
    });
};*/

export const actions2 = (query) => async (dispatch) => {
  dispatch(setPending(true));
  dispatch(setResults([]));

  const algoliaIndex = algolia.initIndex("companies");


  algoliaIndex.search(query.toString()).then(({ hits }) => {
    dispatch(setResults(hits));
    dispatch(setPending(false));
  });
};

export const actions3 = (service) => async (dispatch) => {
  dispatch(setPending(true));
  dispatch(setResults([]));


  const users = [];

  db.collection("users")
    .where("services", "array-contains", service)
    .get()
    .then((snap) => {
      snap.docs.forEach((doc) => {
        users.push({
          ...doc.data(),
          objectID: doc.id,
        });
      });

      dispatch(setResults(users));
      dispatch(setPending(false));
    });
};

export const actions4 = (category) => async (dispatch) => {
  dispatch(setPending(true));
  dispatch(setResults([]));

  const users = [];

  db.collection("users")
    .where("categories", "array-contains", category)
    .get()
    .then((snap) => {
      snap.docs.forEach((doc) => {
        users.push({
          ...doc.data(),
          objectID: doc.id,
        });
      });

      dispatch(setResults(users));
      dispatch(setPending(false));
    });
};

export const actions5 = (service) => async (dispatch) => {
  dispatch(setPending(true));
  dispatch(setResults([]));


  fetch("https://us-central1-tribu-app.cloudfunctions.net/search3", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      service,
    }),
  })
    .then((res) => res.json())
    .then((result) => {
      dispatch(setResults(result.results));
      dispatch(setPending(false));
    });
};

export const actions6 = (category) => async (dispatch) => {
  dispatch(setPending(true));
  dispatch(setResults([]));


  fetch("https://us-central1-tribu-app.cloudfunctions.net/search4", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      category,
    }),
  })
    .then((res) => res.json())
    .then((result) => {
      dispatch(setResults(result.results));
      dispatch(setPending(false));
    });
};

export const actions7 = (department) => async (dispatch) => {
  dispatch(setPending(true));
  dispatch(setResults([]));


  const users = [];

  db.collection("users")
    .where("department", "==", department)
    .get()
    .then((snap) => {
      snap.docs.forEach((doc) => {
        users.push({
          ...doc.data(),
          objectID: doc.id,
        });
      });

      dispatch(setResults(users));
      dispatch(setPending(false));
    });
};

export const actions8 = (department) => async (dispatch) => {
  dispatch(setPending(true));
  dispatch(setResults([]));


  fetch("https://us-central1-tribu-app.cloudfunctions.net/searchByDepartment", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      department,
    }),
  })
    .then((res) => res.json())
    .then((result) => {
      dispatch(setResults(result.results));
      dispatch(setPending(false));
    });
};
