import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Aux from "../../../hoc/Aux";
import PageComponent from "../PageComponent";
import "../custom.css";

function FirstStep() {
  return (
    <Aux>
      <PageComponent>
        <h1 className="help-item-title">Premiers pas sur Tribu</h1>
        <p className="help-item-text">
          Tribu regroupe la plus grande communauté de courtiers en France. Les
          courtiers de la communauté Tribu sont répartis en 6 catégories Orias :
          {` `}
          <Link to='/search?q=IOBSP'>IOBSP</Link>
          {`, `}
          <Link to='/search?q=IAS'>IAS</Link>
          {`, `}
          <Link to='/search?q=CIF'>CIF</Link>
          {`, `}
          <Link to='/search?q=ALPSI'>ALPSI</Link>
          {`, `}
          <Link to='/search?q=CIP'>CIP</Link>
          {` et `}
          <Link to='/search?q=IFP'>IFP</Link>.
          <br />
          <br />
          Si vous faîtes partie de l’une de ces catégories, bienvenue sur Tribu
          !
          <br />
          <br />
          Pour vous inscrire gratuitement, il suffit de{" "}
          <Link to={"/signup"}>cliquer ici</Link>, puis sur “INSCRIPTION”.
          <br />
          <br />
          Lors de l’inscription, nous vous demandons de renseigner votre prénom
          ainsi que votre nom, une adresse e-mail valide et un numéro de
          portable. Le numéro Orias vous permettra de valider votre compte et de
          compléter automatiquement une partie de votre profil et de votre page
          entreprise. Dans le cas où vous avez plusieurs structures
          indépendantes, vous devez créer des comptes distincts, avec adresses
          mail et numéros Orias distincts.
        </p>
      </PageComponent>
    </Aux>
  );
}

export default FirstStep;
