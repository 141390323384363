const SERVICES = [
  {
    service: "Crédit Immobilier",
    service_alias: "Crédit immobilier",
    isParent: true,
    category: "IOBSP",
    name: "credit-immobilier",
    title: "Les courtiers en crédit immobilier | Tribu",
    description:
      "Besoin d'un courtier en crédit immobilier ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier credit immobilier, courtier immobilier, courtier credit, courtier pret, courtier pret immobilier, courtier financement immobilier, credit immobilier, courtage credit immobilier, courtage immobilier, courtage credit, courtage prêt immobilier, courtage financement immobilier",
    text: `Un crédit immobilier a vocation à financer l’acquisition d’un immeuble à usage d’habitation ou à usage professionnel et d’habitation, les travaux de réparation, d’amélioration et d’entretien de l’immeuble acquis; ou encore l’acquisition d’un terrain.
    Le prêt octroyé par la banque peut servir à financer une résidence principale ou secondaire, ou à réaliser de l’investissement locatif.\n
    Le courtier en crédit immobilier est un intermédiaire entre le client, dont il recueille les besoins, et la banque, qui propose les financements. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Rachat de crédits",
    service_alias: "Rachat de crédits",
    isParent: true,
    category: "IOBSP",
    name: "rachat-de-credits",
    title: "Les courtiers en rachat de crédits | Tribu",
    description:
      "Besoin d'un courtier en rachat de prêts ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier rachat credits, courtier rachat prets, courtage rachat credits, courtage rachat rachat prets, courtier regroupement credits, courtier regroupement prets, courtage regroupement credits, courtage regroupement prets, rachat credits, regroupement prets, restructuration de dettes",
    text: `Le rachat de crédits est également connu sous le nom de regroupement de crédits ou de restructuration de dettes. Il prend la forme d’un montage financier visant à regrouper les mensualités de plusieurs crédits en un crédit unique.
    La restructuration du crédit constitue une solution intéressante pour les particuliers soucieux d’alléger leur taux d’endettement.\n
    Le courtier en rachat de crédits est un intermédiaire entre le client, dont il recueille les besoins, et la banque, qui propose les financements. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Rachat de crédits consommation",
    service_alias: "Rachat de crédits consommation",
    parent: "rachat-de-credits",
    category: "IOBSP",
    name: "rachat-de-credits-consommation",
    title: "Les courtiers en rachat de crédits à la consommation | Tribu",
    description:
      "Besoin d'un courtier en rachat de prêts à la consommation ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier rachat credits consommation, courtier rachat prets consommation, courtage rachat credits consommation, courtage rachat rachat prets consommation, credits consommation, prets consommation, courtier regroupement credits consommation, courtier regroupement prets consommation, courtage regroupement credits consommation, courtage regroupement prets consommation, courtier rachat credits conso, courtier rachat prets conso, courtage rachat credits conso, courtage rachat rachat prets conso, credits conso, prets conso, courtier regroupement credits conso, courtier regroupement prets conso, courtage regroupement credits conso, courtage regroupement prets conso, rachat credits consommation, rachat de credits conso, regroupement prets consommation, regroupement prets conso, restructuration de dettes consommation, restructuration de dettes conso",
    text: `Le rachat ou regroupement de crédits à la consommation concerne spécifiquement les crédits à la consommation, qui sont rassemblés et transformés en un seul et même contrat, sans exiger de l’emprunteur de fournir une garantie hypothécaire.\n 
    Le courtier en rachat de crédits consommation est un intermédiaire entre le client, dont il recueille les besoins, et la banque, qui propose les financements. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Rachat de crédits consommation et immobilier",
    service_alias: "Rachat de crédits consommation et immobilier",
    parent: "rachat-de-credits",
    category: "IOBSP",
    name: "rachat-de-credits-consommation-et-immobilier",
    title:
      "Les courtiers en rachat de crédits à la consommation et immobilier | Tribu",
    description:
      "Besoin d'un courtier en rachat de prêts à la consommation ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier rachat credits consommation immobilier, courtier rachat prets consommation immobilier, courtage rachat credits consommation immobilier, courtage rachat rachat prets consommation immobilier, credits consommation immobilier, prets consommation immobilier, courtier regroupement credits consommation immobilier, courtier regroupement prets consommation immobilier, courtage regroupement credits consommation immobilier, courtage regroupement prets consommation immobilier, courtier rachat credits conso immobilier, courtier rachat prets conso immobilier, courtage rachat credits conso immobilier, courtage rachat rachat prets conso immobilier, credits conso immobilier, prets conso immobilier, courtier regroupement credits conso immobilier, courtier regroupement prets conso immobilier, courtage regroupement credits conso immobilier, courtage regroupement prets conso immobilier, rachat credits consommation immobilier, rachat de credits conso immobilier, regroupement prets consommation immobilier, regroupement prets conso immobilier, restructuration de dettes consommation immobilier, restructuration de dettes conso immobilier",
    text: `Le rachat ou regroupement de crédits à la consommation et immobilier consiste à regrouper d’anciens emprunts de nature différente. L’établissement financier propose de réunir dans un emprunt unique plusieurs crédits à la consommation ainsi qu’un (ou plusieurs) prêt(s) immobilier(s).\n 
    Le courtier en rachat de crédits consommation et immobilier est un intermédiaire entre le client, dont il recueille les besoins, et la banque, qui propose les financements. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Crédit professionnel",
    service_alias: "Crédit professionnel",
    isParent: true,
    category: "IOBSP",
    name: "credit-professionnel",
    title: "Les courtiers en crédit professionnel | Tribu",
    description:
      "Besoin d'un courtier en crédit pro ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier credit professionnel, courtier credit, courtier pret, courtier financement, courtage credit professionnel, courtage credit, courtage pret, courtier financement professionnel, courtage financement professionnel, credit professionnel, courtier credit pro, courtage credit pro, courtier financement pro, courtage financement pro, credit pro",
    text: `Le crédit professionnel est destiné aux professionnels dans le cadre de leur installation ou du développement de leur entreprise. Le recours à un crédit professionnel permet de financer différents projets : achat de matériel, investissement, export, locaux commerciaux ou bureaux, trésorerie…\n
    Le courtier en crédit professionnel est un intermédiaire entre le client, dont il recueille les besoins, et la banque, qui propose les financements. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Prêt professionnel",
    service_alias: "Prêt professionnel",
    parent: "credit-professionnel",
    category: "IOBSP",
    name: "pret-professionnel",
    title: "Les courtiers en prêt professionnel | Tribu",
    description:
      "Besoin d'un courtier en prêt professionnel ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier pret professionnel, courtier credit, courtier pret, courtier financement, courtage pret professionnel, courtage credit, courtage pret, courtier financement professionnel, courtage financement professionnel, pret professionnel",
    text: `Un prêt professionnel est conçu pour financer un investissement professionnel, qui peut être matériel (machines, véhicule, locaux…) ou immatériel (besoin en fonds de roulement, fonds de commerce…). Il s’adresse aux petites et moyennes entreprises et industries, commerçants, artisans ou travailleurs indépendants.\n 
    Le courtier en prêt professionnel est un intermédiaire entre le client, dont il recueille les besoins, et la banque, qui propose les financements. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Crédit bail immobilier",
    service_alias: "Crédit bail immobilier",
    parent: "credit-professionnel",
    category: "IOBSP",
    name: "credit-bail-immobilier",
    title: "Les courtiers en crédit-bail immobilier | Tribu",
    description:
      "Besoin d'un courtier en crédit-bail immobilier ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier credit bail immobilier, courtage credit bail immobilier, credit bail immobilier, courtier credit bail, courtage credit bail, credit bail immobilier, credit bail",
    text: `Le crédit-bail immobilier nécessite l'achat, par le crédit-bailleur, de l'immeuble d'entreprise qui sera ensuite loué à l'entreprise locataire (le crédit-preneur), généralement avec option d'achat en fin de contrat.\n
    Le courtier en crédit-bail immobilier est un intermédiaire entre le client, dont il recueille les besoins, et la banque, qui propose les financements. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Crédit bail mobilier",
    service_alias: "Crédit bail mobilier",
    parent: "credit-professionnel",
    category: "IOBSP",
    name: "credit-bail-mobilier",
    title: "Les courtiers en crédit-bail mobilier | Tribu",
    description:
      "Besoin d'un courtier en crédit-bail mobilier ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier credit bail mobilier, courtage credit bail mobilier, credit bail mobilier, courtier credit bail, courtage credit bail, credit bail mobilier, credit bail",
    text: `Le crédit-bail, appelé aussi "leasing", permet de financer des équipements sans faire d'apport. La société de crédit-bail est propriétaire du matériel qu'elle a acheté directement auprès du fournisseur et qu'elle loue ensuite à l'entreprise pendant un temps précisé dans le contrat de location.\n
    Le courtier en crédit-bail mobilier est un intermédiaire entre le client, dont il recueille les besoins, et la banque, qui propose les financements. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Location financière",
    service_alias: "Location financière",
    parent: "credit-professionnel",
    category: "IOBSP",
    name: "location-financiere",
    title: "Les courtiers en location financière | Tribu",
    description:
      "Besoin d'un courtier en location financière ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier location financiere, courtage location financiere, location financiere",
    text: `La formule de la location financière permet de financer en souplesse les dépenses d'équipement de l'entreprise. Faire financer par un contrat de location un bien mobilier à usage professionnel comme du matériel d’équipement, véhicules utilitaires et industriels, matériel informatique, machine-outil, neuf ou d'occasion, etc.\n
    Le courtier en location financière est un intermédiaire entre le client, dont il recueille les besoins, et la banque, qui propose les financements. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Location longue durée",
    service_alias: "Location longue durée",
    parent: "credit-professionnel",
    category: "IOBSP",
    name: "location-longue-duree",
    title: "Les courtiers en location longue durée | Tribu",
    description:
      "Besoin d'un courtier en location longue durée ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier location longue duree, courtage location longue duree, location longue duree",
    text: `La location longue durée est une solution de financement proposée lors de l'acquisition d'un véhicule. Il s'agit juridiquement d'une location qui permet d'utiliser librement un véhicule moyennant le versement de loyers déductibles des bénéfices de l'entreprise. Aucune option d'achat n'est prévue à l'issue du contrat.\n 
    Le courtier en location longue durée est un intermédiaire entre le client, dont il recueille les besoins, et la banque, qui propose les financements. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Affacturage",
    service_alias: "Affacturage",
    parent: "credit-professionnel",
    category: "IOBSP",
    name: "affacturage",
    title: "Les courtiers en affacturage | Tribu",
    description:
      "Besoin d'un courtier en affacturage ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords: "courtier affacturage, courtage affacturage, affacturage",
    text: `L’affacturage est un moyen de financement permettant d’obtenir rapidement une avance de trésorerie par la cession de factures en attente de règlement, ce que l’on définit par "créances clients".
    L’affacturage offre également un service de gestion des encaissements : relance et recouvrement de créances non réglées.\n 
    Le courtier en affacturage est un intermédiaire entre le client, dont il recueille les besoins, et le factor, à qui sera transféré la créance commerciale. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Assurance crédit",
    service_alias: "Assurance crédit",
    parent: "credit-professionnel",
    category: "IOBSP",
    name: "assurance-credit",
    title: "Les courtiers en assurance crédit | Tribu",
    description:
      "Besoin d'un courtier en assurance crédit ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance credit, courtage assurance credit, assurance credit",
    text: `Pour l’entreprise, l’assurance-crédit permet de garantir les risques clients avec une surveillance et une évaluation des risques de paiements. Ces deux techniques sont donc complémentaires.\n 
    Le courtier en assurance-crédit est un intermédiaire entre le client, dont il recueille les besoins, et le factor, à qui sera transféré la créance commerciale. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Epargne",
    service_alias: "Épargne",
    isParent: true,
    category: "IAS",
    name: "épargne",
    title: "Les courtiers en épargne | Tribu",
    description:
      "Besoin d'un courtier en placements financiers ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords: "courtier epargne, courtage epargne, epargne",
    text: `L'épargne financière permet de faire fructifier son capital et de bénéficier ainsi de plus-values. L'épargne financière peut être constituée des sommes d'argent placées à la banque ou de produits financiers d'investissement.\n
    Un courtier en placements financiers déniche les meilleurs placements, ceux qui sont les plus intéressants et les plus adaptés au profil de son client. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu. `,
  },
  {
    service: "Assurance-vie",
    service_alias: "Assurance-vie",
    parent: "épargne",
    category: "IAS",
    name: "assurance-vie",
    title: "Les courtiers en assurance vie | Tribu",
    description:
      "Besoin d'un courtier en assurance vie ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords: "courtier assurance vie, courtage assurance vie, assurance vie",
    text: `L'assurance-vie est un contrat d'assurance qui permet à un souscripteur assuré de se constituer un capital, via des versements libres ou programmés. À ne pas confondre avec un contrat d'assurance décès, pour lequel le souscripteur verse une prime fixe mensuelle et qui assure aux bénéficiaires choisis le versement d'un capital fixé en cas de décès.\n
    Le courtier en placement d’assurance-vie est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Contrat de capitalisation",
    service_alias: "Contrat de capitalisation",
    parent: "épargne",
    category: "IAS",
    name: "contrat-de-capitalisation",
    title: "Les courtiers en contrat de capitalisation | Tribu",
    description:
      "Besoin d'un courtier en contrat de capitalisation ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier contrat capitalisation, courtage contrat capitalisation, contrat capitalisation",
    text: `Un contrat de capitalisation est un produit d'épargne financière proposée par des compagnies d'assurance. Si le contrat de capitalisation est identique au contrat d'assurance-vie au niveau de la gestion et de la fiscalité des gains, il diffère en matière de transmission.\n
    Le courtier en placement de contrat de capitalisation est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Assurance famille",
    service_alias: "Assurance famille",
    isParent: true,
    category: "IAS",
    name: "assurance-famille",
    title: "Les courtiers en assurance famille | Tribu",
    description:
      "Besoin d'un courtier en assurance famille ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance famille, courtage assurance famille, assurance famille",
    text: `Au quotidien, nous ne sommes jamais à l’abri d’un accident domestique, d’un préjudice causé par un tiers, etc. Souscrire une assurance pour protéger sa famille peut donc s’avérer très utile.\n 
    Le courtier en assurance famille est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Garantie des accidents de la vie",
    service_alias: "Garantie des accidents de la vie",
    parent: "assurance-famille",
    category: "IAS",
    name: "garantie-des-accidents-de-la-vie",
    title: "Les courtiers en GAV | Tribu",
    description:
      "Besoin d'un courtier en assurance garantie des accidents de la vie ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier GAV, courtier garantie des accidents de la vie, garantie des accidents de la vie, courtage GAV, courtage garantie des accidents de la vie",
    text: `L’assurance accidents de la vie est un contrat d'assurance prévoyance qui protège un assuré et sa famille des conséquences, notamment financières, d’un accident grave de la vie courante.
    La GAV complète efficacement les risques couverts par l’assurance responsabilité civile. Cette dernière ne couvre en effet que les dommages causés à des tiers.\n 
    Le courtier en assurance GAV est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Protection juridique",
    service_alias: "Protection juridique",
    parent: "assurance-famille",
    category: "IAS",
    name: "protection-juridique",
    title: "Les courtiers en protection juridique | Tribu",
    description:
      "Besoin d'un courtier en assurance protection juridique ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier PJ, courtier protection juridique, protection juridique, courtage PJ, courtage protection juridique",
    text: `L’assurance protection juridique permet un accompagnement et une indemnisation en cas de litige avec un tiers ou de conflit nécessitant l’appel à une instance de juridiction.
    Parfois incluse dans un contrat d’assurance, elle peut aussi être souscrite individuellement.\n
    Le courtier en assurance protection juridique est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Assurance décès",
    service_alias: "Assurance décès",
    parent: "assurance-famille",
    category: "IAS",
    name: "assurance-deces",
    title: "Les courtiers en assurance-décès | Tribu",
    description:
      "Besoin d'un courtier en assurance décès ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance deces, assurance deces, courtage assurance deces",
    text: `L’objectif principal d’une assurance décès est de garantir le versement d’un capital à des bénéficiaires en cas de décès, d’invalidité ou de perte totale et irréversible d’autonomie du souscripteur.
    La plupart des assurances décès classiques couvrent les décès accidentels et ceux causés par une maladie.\n
    Le courtier en assurance décès est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Assurance scolaire",
    service_alias: "Assurance scolaire",
    parent: "assurance-famille",
    category: "IAS",
    name: "assurance-scolaire",
    title: "Les courtiers en assurance scolaire | Tribu",
    description:
      "Besoin d'un courtier en assurance scolaire ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance scolaire, assurance scolaire, courtage assurance scolaire",
    text: `L’assurance scolaire est une couverture d’assurance conçue pour protéger l’enfant tout au long de sa scolarité, aussi bien pendant ses activités scolaires que périscolaires.
    Elle est facultative pour assister aux cours et participer aux activités obligatoires et devient obligatoire s’il veut participer aux activités dites facultatives.\n
    Le courtier en assurance scolaire est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Assurance emprunteur",
    service_alias: "Assurance emprunteur",
    parent: "assurance-famille",
    category: "IAS",
    name: "assurance-emprunteur",
    title: "Les courtiers en assurance emprunteur | Tribu",
    description:
      "Besoin d'un courtier en assurance emprunteur ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance emprunteur, assurance emprunteur, courtage assurance emprunteur, courtier assurance de prêt, courtage assurance de pret, courtier assurance credit, courtage assurance credit",
    text: `L’assurance emprunteur est exigée par tous les établissements de crédit.
    L’assurance de prêt immobilier protège l’emprunteur en cas de sinistre ou accident ainsi que les organismes prêteurs pour le remboursement des mensualités de prêt immobilier.\n
    Le courtier en assurance de prêt immobilier est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Garantie obsèques",
    service_alias: "Garantie obsèques",
    parent: "assurance-famille",
    category: "IAS",
    name: "garantie-obseques",
    title: "Les courtiers en assurance garantie obsèques | Tribu",
    description:
      "Besoin d'un courtier en assurance garantie obsèques ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance obseques, courtier garantie obseques ,assurance obseques, courtage assurance obseques, courtage garantie obseques, garantie obseques",
    text: `L’assurance obsèques, ou « convention obsèques », est un contrat de prévoyance qui permet le versement d’un capital à ses proches afin de financer ses funérailles.
    Elle permet la provision et la transmission d’un capital destiné à couvrir ses frais d’obsèques.\n
    Le courtier en assurance obsèques est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Assurance animaux",
    service_alias: "Assurance animaux",
    parent: "assurance-famille",
    category: "IAS",
    name: "assurance-animaux",
    title: "Les courtiers en assurance animaux | Tribu",
    description:
      "Besoin d'un courtier en assurance chien chat ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance animaux, assurance animaux, courtage assurance animaux, courtier assurance chien chat, courtage assurance chien chat, assurance chien chat",
    text: `L’assurance animaux s’adresse à tous les propriétaires de chiens ou de chats souhaitant réaliser des économies tout en assurant à son animal de compagnie des soins et une prise en charge en cas de pépin.
    Chaque mutuelle pour animaux propose un socle commun de garanties auxquelles peuvent être ajoutées des options.\n
    Le courtier en assurance chien chat est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Assurance dépendance",
    service_alias: "Assurance dépendance",
    parent: "assurance-famille",
    category: "IAS",
    name: "assurance-dependance",
    title: "Les courtiers en assurance dépendance | Tribu",
    description:
      "Besoin d'un courtier en assurance dépendance ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance dependance, assurance dependance, courtage assurance dependance",
    text: `Les contrats d’assurance dépendance sont des contrats qui garantissent le versement d’une rente, d’un capital ou d’une prestation en nature en cas de perte totale ou partielle de l’autonomie de l’assuré.\n
    Le courtier en assurance dépendance est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Assurance rapatriement",
    service_alias: "Assurance rapatriement",
    parent: "assurance-famille",
    category: "IAS",
    name: "assurance-rapatriement",
    title: "Les courtiers en assurance rapatriement | Tribu",
    description:
      "Besoin d'un courtier en assurance rapatriement ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance rapatriement, courtage assurance rapatriement, assurance rapatriement",
    text: `L’assurance rapatriement est une garantie qui permet de couvrir le retour anticipé d’un voyageur dans son pays en cas de maladie, d’un accident ou encore d’un décès.
    La garantie est en généralement incluse dans les contrats d’assurance voyage, que ce soit dans la formule de base ou en option.\n
    Le courtier en assurance rapatriement est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Assurance habitation",
    service_alias: "Assurance habitation",
    isParent: true,
    category: "IAS",
    name: "assurance-habitation",
    title: "Les courtiers en assurance habitation | Tribu",
    description:
      "Besoin d'un courtier en assurance habitation ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance habitation, courtage assurance habitation, assurance habitation",
    text: `La meilleure assurance habitation est l’assurance la mieux adaptée au logement et aux besoins de ses occupants, le tout à un prix avantageux.\n
    Le courtier en assurance habitation est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Assurance locataire",
    service_alias: "Assurance locataire",
    parent: "assurance-habitation",
    category: "IAS",
    name: "assurance-locataire",
    title: "Les courtiers en assurance locataire | Tribu",
    description:
      "Besoin d'un courtier en assurance locataire ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance locataire, courtage assurance locataire, assurance locataire",
    text: `L’assurance risques locatifs désigne une assurance habitation locataire avec le minimum de garanties habitation. Cette assurance permet de couvrir les dégâts causés durant la location et correspond à ce que l’on appelle la responsabilité civile locative.\n
    Le courtier en assurance locataire est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Propriétaire non-occupant",
    service_alias: "Assurance propriétaire non-occupant",
    parent: "assurance-habitation",
    category: "IAS",
    name: "proprietaire-non-occupant",
    title: "Les courtiers en propriétaire non occupant | Tribu",
    description:
      "Besoin d'un courtier en propriétaire non occupant ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier proprietaire non occupant, courtage proprietaire non occupant, proprietaire non occupant",
    text: `L‘assurance propriétaire non-occupant (PNO) est une assurance habitation spécialement dédiée aux propriétaires bailleurs n’occupant pas leur logement. Elle leur permet d’être indemnisé si un sinistre survient, que le logement soit vacant ou non.\n
    Le courtier en assurance propriétaire non-occupant est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Assurance loisirs",
    service_alias: "Assurance loisirs",
    isParent: true,
    category: "IAS",
    name: "assurance-loisirs",
    title: "Les courtiers en assurance loisirs | Tribu",
    description:
      "Besoin d'un courtier en assurance loisirs ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance loisirs, courtage assurance loisirs, assurance loisirs",
    text: `Pour vivre ses loisirs en toute sérénité, il faut être bien assuré. Chaque loisir ayant ses spécificités, il n’est pas toujours évident de s’y retrouver en tant qu’assuré.\n
    Le courtier en assurance loisirs est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Assurance plaisance",
    service_alias: "Assurance plaisance",
    parent: "assurance-loisirs",
    category: "IAS",
    name: "plaisance",
    title: "Les courtiers en assurance plaisance | Tribu",
    description:
      "Besoin d'un courtier en assurance bateau ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance plaisance, courtier assurance bateau , courtier assurance plaisance, courtage assurance bateau, assurance bateau",
    text: `L’assurance d’un bateau n’est pas obligatoire, qu’il soit motorisé ou à voile. Il y a deux   types d’assurances bateau : l’assurance bateau au tiers et l’assurance bateau multirisque qui octroie un niveau de couverture étendu.\n 
    Le courtier en assurance bateau est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Assurance voyage",
    service_alias: "Assurance voyage",
    parent: "assurance-loisirs",
    category: "IAS",
    name: "assurance-voyage",
    title: "Les courtiers en assurance voyage | Tribu",
    description:
      "Besoin d'un courtier en assurance voyage ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance voyage, courtage assurance voyage, assurance voyage",
    text: `L’assurance voyage est un contrat spécifiquement conçu pour répondre aux conséquences financières d’une annulation de voyage ou d’un sinistre intervenant durant celui-ci. Les garanties incluses interviennent avant, pendant, après le séjour en fonction des causes d’annulation comme des dommages subis.\n
    Le courtier en assurance voyage est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Assurance chasse",
    service_alias: "Assurance chasse",
    parent: "assurance-loisirs",
    category: "IAS",
    name: "assurance-chasse",
    title: "Les courtiers en assurance chasse | Tribu",
    description:
      "Besoin d'un courtier en assurance chasse ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance chasse, courtage assurance chasse, assurance chasse",
    text: `Pour valider son permis de chasser, le chasseur doit avoir souscrit à une assurance couvrant sa responsabilité pour les dommages corporels causés aux autres.
    L’assurance minimum obligatoire ne couvre pas les dommages à soi-même, les dommages matériels causés à autrui.\n
    Le courtier en assurance chasse est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Assurance sport",
    service_alias: "Assurance sport",
    parent: "assurance-loisirs",
    category: "IAS",
    name: "assurance-sport",
    title: "Les courtiers en assurance sport | Tribu",
    description:
      "Besoin d'un courtier en assurance sport ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance sport, courtage assurance sport, assurance sport",
    text: `Pour un adhérent d’un club sportif, la licence permet de bénéficier de l’assurance responsabilité civile pour les dommages causés aux autres. En cas de blessure, l’assurance du club ne prendra pas en charge ses frais de santé et il devra souscrire à une mutuelle adaptée à ses besoins.\n
    Le courtier en assurance sportive est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Assurance santé",
    service_alias: "Assurance santé",
    isParent: true,
    category: "IAS",
    name: "assurance-sante",
    title: "Les courtiers en assurance santé | Tribu",
    description:
      "Besoin d'un courtier en assurance santé ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance santé, courtage assurance santé, assurance santé",
    text: `Une assurance santé est un contrat qui a pour objet de prendre en charge tout ou partie des dépenses de santé concernant la maladie, l'accident et la maternité, non couvertes par l'assurance maladie obligatoire.\n 
    Le courtier en assurance santé est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu`,
  },
  {
    service: "Complémentaire santé",
    service_alias: "Complémentaire santé",
    parent: "assurance-sante",
    category: "IAS",
    name: "complementaire-sante",
    title: "Les courtiers en assurance complémentaire santé | Tribu",
    description:
      "Besoin d'un courtier en assurance complémentaire santé ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance complementaire sante, courtage assurance complementaire sante, assurance complementaire sante, complementaire sante",
    text: `La complémentaire santé rembourse les dépenses de santé des assurés, en supplément de la Sécurité sociale. En effet, si celle-ci bénéficie à tout le monde, elle ne rembourse qu’une partie des soins médicaux.
    Le rôle d’une mutuelle est de rembourser le ticket modérateur de manière partielle ou totale.\n
    Le courtier en assurance complémentaire santé est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Sur-complémentaire",
    service_alias: "Sur-complémentaire",
    parent: "assurance-sante",
    category: "IAS",
    name: "sur-complementaire",
    title: "Les courtiers en assurance surcomplémentaire santé | Tribu",
    description:
      "Besoin d'un courtier en assurance surcomplémentaire santé ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance surcomplementaire sante, courtage assurance surcomplementaire sante, assurance surcomplementaire sante, surcomplementaire sante, sur complementaire, sur complementaire sante",
    text: `La surcomplémentaire santé, ou surmutuelle santé, complète les remboursements de l’Assurance maladie et de la complémentaire santé. Elle propose ainsi un troisième niveau de protection et s’adresse aux personnes qui ont des dépenses élevées dans certains postes de soins.\n
    Le courtier en assurance surcomplémentaire santé est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Assurance du véhicule",
    service_alias: "Assurance du véhicule",
    isParent: true,
    category: "IAS",
    name: "assurance-du-vehicule",
    title: "Les courtiers en assurance du véhicule | Tribu",
    description:
      "Besoin d'un courtier en assurance du véhicule ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance véhicule, courtage assurance véhicule, assurance véhicule, assurance auto, auss",
    text: `Le propriétaire d'un véhicule à moteur en circulation sur la voie publique ou en stationnement, doit l'assurer avec au minimum la garantie responsabilité civile. L'assurance responsabilité civile, appelée parfois assurance au tiers, sert à indemniser les victimes des dommages causés par le véhicule.\n
    Le courtier en assurance du véhicule est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Assurance auto",
    service_alias: "Assurance auto",
    parent: "assurance-du-vehicule",
    category: "IAS",
    name: "assurance-auto",
    title: "Les courtiers en assurance auto | Tribu",
    description:
      "Besoin d'un courtier en assurance automobile ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance auto, courtage assurance auto, assurance auto, assurance automobile",
    text: `L’assurance automobile est obligatoire pour tous les véhicules à moteur. 
    L’obligation d’assurance auto concerne uniquement la garantie Responsabilité Civile qui est présente dans tous les contrats d’assurance voiture et permet de couvrir les dommages matériels et les tiers victimes d’un dommage causé par le véhicule.\n
    Le courtier en assurance auto est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Assurance 2 roues",
    service_alias: "Assurance 2 roues",
    parent: "assurance-du-vehicule",
    category: "IAS",
    name: "assurance-2-roues",
    title: "Les courtiers en assurance 2 roues | Tribu",
    description:
      "Besoin d'un courtier en assurance moto ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance moto, courtage assurance moto, assurance moto, assurance scooter",
    text: `L’assurance deux-roues vise à protéger la moto ou le scooter mais également à couvrir contre les dommages causés à autrui.\n
    Le courtier en assurance moto est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Assurance voiture sans permis",
    service_alias: "Assurance voiture sans permis",
    parent: "assurance-du-vehicule",
    category: "IAS",
    name: "assurance-voiture-sans-permis",
    title: "Les courtiers en assurance voiture sans permis | Tribu",
    description:
      "Besoin d'un courtier en assurance voiture sans permis ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance voiture sans permis, courtier assurance voiturette, courtage assurance voiturette, assurance voiture sans permis, assurance voiture",
    text: `Si le permis de conduire classique n’est pas exigé pour prendre le volant d’une voiture sans permis, l’assurance l’est. Il est indispensable de protéger la voiturette, elle comme son conducteur, en cas de sinistre.\n
    Le courtier en assurance voiture sans permis est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Assurance quad",
    service_alias: "Assurance quad",
    parent: "assurance-du-vehicule",
    category: "IAS",
    name: "assurance-quad",
    title: "Les courtiers en assurance quad | Tribu",
    description:
      "Besoin d'un courtier en assurance quad ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance quad, courtage assurance quad, assurance quad",
    text: `Utilisé pour circuler sur la voie publique ou pour des loisirs tout terrain, un quad nécessite un contrat d’assurance moto qui lui est spécifique. Cela vaut également pour les buggys et les SSV qui entrent dans la catégorie des deux-roues.\n
    Le courtier en assurance quad est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Assurance camping-car",
    service_alias: "Assurance camping-car",
    parent: "assurance-du-vehicule",
    category: "IAS",
    name: "assurance-camping-car",
    title: "Les courtiers en assurance camping-car | Tribu",
    description:
      "Besoin d'un courtier en assurance camping-car ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance camping-car, courtier assurance van, courtage assurance camping-car, courtage assurance van, assurance camping-car, assurance van",
    text: `Même si le véhicule ne roule que quelques jours par an, il est obligatoire selon le code des assurances de souscrire un contrat d’assurance auto pour un camping-car. La plupart des assureurs proposent des contrats spécifiques pour ce type de véhicule.\n
    Le courtier en assurance camping-car est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Assurance malussé",
    service_alias: "Assurance malussé",
    parent: "assurance-du-vehicule",
    category: "IAS",
    name: "assurance-malusse",
    title: "Les courtiers en assurance malussé | Tribu",
    description:
      "Besoin d'un courtier en assurance malussé ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance malussé, courtier assurance malus, courtage assurance malussé, courtage assurance malus, assurance malussé, assurance malus",
    text: `Aussi connu sous le nom de coefficient réduction majoration, le coefficient bonus-malus est attribué à chaque conducteur et joue un rôle important dans la tarification de la prime d’assurance auto.
    Dès lors que ce coefficient est supérieur à 1, l’assuré est ce qu’on appelle un profil malussé.\n
    Le courtier en assurance malussé est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Assurance du chef d'entreprise",
    service_alias: "Assurance du chef d'entreprise",
    isParent: true,
    category: "IAS",
    name: "assurance-du-chef-d-entreprise",
    title: "Les courtiers en assurance du chef d’entreprise | Tribu",
    description:
      "Besoin d'un courtier en assurance du chef d’entreprise loisirs ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance chef entreprise, courtage assurance chef entreprise, assurance chef entreprise",
    text: `L’important pour un chef d’entreprise est de bien anticiper les risques qui peuvent se produire, en souscrivant les bonnes assurances en amont. Il lui est fortement conseillé d’étudier les protections spécifiques à sa situation.\n
    Le courtier en assurance du chef d’entreprise est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Prévoyance pro",
    service_alias: "Prévoyance pro",
    parent: "assurance-du-chef-d-entreprise",
    category: "IAS",
    name: "prevoyance-pro",
    title: "Les courtiers en assurance prévoyance pro | Tribu",
    description:
      "Besoin d'un courtier en assurance prévoyance pro ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance prevoyance pro, courtier assurance prevoyance, courtage assurance prevoyance pro, courtage assurance prevoyance, prevoyance, prevoyance pro",
    text: `Tous les professionnels indépendants, libéraux, commerçants, artisans, dirigeants ou encore créateurs d’entreprise ont intérêt à se couvrir (décès, incapacité, invalidité) via un contrat de prévoyance. La couverture ne protégera pas uniquement le travailleur non salarié mais aussi sa famille.\n 
    Le courtier en assurance prévoyance TNS est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Santé pro",
    service_alias: "Santé pro",
    parent: "assurance-du-chef-d-entreprise",
    category: "IAS",
    name: "sante-pro",
    title: "Les courtiers en assurance santé pro | Tribu",
    description:
      "Besoin d'un courtier en assurance santé pro ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance sante pro, courtier assurance sante, courtage assurance sante pro, courtage assurance sante, sante pro",
    text: `La mutuelle TNS est une complémentaire santé réservée aux travailleurs non-salariés (TNS), c’est-à-dire à tous les actifs n’ayant pas le statut d’employé. Elle permet de rembourser tout ou partie des frais de santé, qui ne serait pas pris en charge par l'Assurance Maladie.\n
    Le courtier en assurance santé TNS est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Retraite pro",
    service_alias: "Retraite pro",
    parent: "assurance-du-chef-d-entreprise",
    category: "IAS",
    name: "retraite-pro",
    title: "Les courtiers en assurance retraite pro | Tribu",
    description:
      "Besoin d'un courtier en assurance retraite pro ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance retraite pro, courtier assurance retraite, courtage assurance retraite pro, courtage assurance retraite, retraite pro",
    text: `Les indépendants évoluant sous le statut de Travailleur Non Salarié (TNS) ne bénéficient pas du même régime de retraite que les salariés. Il est très important d’anticiper cette situation en épargnant le plus tôt possible pour compléter leur pension et éviter une baisse de revenus conséquente lors du départ en retraite.\n 
    Le courtier en assurance retraite TNS est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Perte d’emploi",
    service_alias: "Perte d’emploi",
    parent: "assurance-du-chef-d-entreprise",
    category: "IAS",
    name: "perte-d-emploi",
    title: "Les courtiers en perte d’emploi | Tribu",
    description:
      "Besoin d'un courtier en chômage ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance perte d’emploi, courtier assurance chomage, courtage assurance perte d’emploi, courtage assurance chomage, assurance perte d’emploi, assurance chomage",
    text: `En cas d’incapacité de travail, les conséquences peuvent être lourdes pour le travailleur non salarié (TNS) qui ne peut plus exercer d’activité professionnelle. Seule une assurance perte de revenu en cas d’arrêt de travail total ou de maladie.\n 
    Le courtier en assurance perte d’emploi est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },

  {
    service: "Responsabilité civile des mandataires sociaux",
    service_alias: "Responsabilité civile des mandataires sociaux",
    parent: "assurance-du-chef-d-entreprise",
    category: "IAS",
    name: "responsabilite-civile-des-mandataires-sociaux",
    title:
      "Les courtiers en assurance responsabilité civile des mandataires sociaux | Tribu",
    description:
      "Besoin d'un courtier en assurance responsabilité civile des mandataires sociaux ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance responsabilite civile des mandataires sociaux, courtier assurance RCMS, courtage assurance responsabilite civile des mandataires sociaux, courtage assurance RCMS, responsabilite civile des mandataires sociaux, assurance responsabilite civile des mandataires sociaux",
    text: `Le contrat d'assurance responsabilité civile des mandataires sociaux intervient quand la responsabilité personnelle du dirigeant est engagée. Il protège les dirigeants en tant que personnes physiques suite à une décision prise dans l’exercice de leurs fonctions et permet de protéger leur patrimoine personnel.\n
    Le courtier en assurance RCMS est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Assurance homme-clé",
    service_alias: "Assurance homme-clé",
    parent: "assurance-du-chef-d-entreprise",
    category: "IAS",
    name: "assurance-homme-cle",
    title: "Les courtiers en assurance homme clé | Tribu",
    description:
      "Besoin d'un courtier en assurance homme clé ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance homme cle, courtage assurance homme cle, assurance homme cle",
    text: `Une assurance homme clé permet de compenser les conséquences financières de la disparition d’une personne essentielle à l’activité d’une entreprise. Cela peut concerner son décès ou encore son invalidité. Cette disparition peut entraîner des conséquences dommageables pour la survie de l’entreprise.\n
    Le courtier en assurance homme clé est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Assurance construction",
    service_alias: "Assurance construction",
    isParent: true,
    category: "IAS",
    name: "assurance-construction",
    title: "Les courtiers en assurance construction | Tribu",
    description:
      "Besoin d'un courtier en assurance construction ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance construction, courtage assurance construction, assurance construction",
    text: `Les assurances construction souscrites par le constructeur d’un bien immobilier ou par un maître d’ouvrage ont pour principe de protéger le futur propriétaire contre les risques de défauts et de malfaçons.\n 
    Le courtier en assurance construction est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Dommage-ouvrage",
    service_alias: "Dommages-ouvrage",
    parent: "assurance-construction",
    category: "IAS",
    name: "assurance-dommages-ouvrage",
    title: "Les courtiers en assurance dommages-ouvrage | Tribu",
    description:
      "Besoin d'un courtier en assurance dommages-ouvrage ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance dommages-ouvrage, courtage assurance dommages-ouvrage, assurance dommages-ouvrage, dommages-ouvrage, assurance do, do",
    text: `L’assurance dommages-ouvrage permet de protéger, après réception des travaux, les propriétaires de bâtiments neufs contre les dommages causés aux constructions et aux ouvrages qui y sont associés et qui sont couverts par la garantie décennale des constructeurs.\n
    Le courtier en assurance dommages-ouvrage est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Tous risques chantier",
    service_alias: "Tous risques chantier",
    parent: "assurance-construction",
    category: "IAS",
    name: "tous-risques-chantier",
    title: "Les courtiers en assurance tous risques chantier | Tribu",
    description:
      "Besoin d'un courtier en assurance tous risques chantier ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance tous risques chantier, courtage assurance tous risques chantier, assurance tous risques chantier, tous risques chantier, assurance trc, trc",
    text: `Ce contrat permet d’apporter une garantie globale à concurrence du coût total du chantier. C’est un contrat sur-mesure souscrit chantier par chantier, qui garantit en tout sauf les dommages accidentels affectant le chantier au cours de sa réalisation. Le tout sauf signifie que le contrat garantit tous les événements, sauf ceux figurant aux exclusions.\n
    Le courtier en assurance tous risques chantier est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Constructeur non réalisateur",
    service_alias: "Constructeur non réalisateur",
    parent: "assurance-construction",
    category: "IAS",
    name: "assurance-constructeur-non-realisateur",
    title: "Les courtiers en assurance constructeur non réalisateur | Tribu",
    description:
      "Besoin d'un courtier en assurance constructeur non réalisateur ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance constructeur non realisateur, courtage assurance constructeur non realisateur, assurance constructeur non realisateur, constructeur non realisateur, assurance cnr, cnr",
    text: `Appelée aussi garantie CNR, l’assurance constructeur non réalisateur est indispensable pour protéger un chantier des dommages de nature décennale.
    Pour les professionnels, l’assurance CNR se présente comme une option de l’assurance décennale, les assureurs préférant lier ces couvertures.\n
    Le courtier en assurance constructeur non réalisateur est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Responsabilité civile décennale",
    service_alias: "Responsabilité civile décennale",
    parent: "assurance-construction",
    category: "IAS",
    name: "responsabilite-civile-decennale",
    title: "Les courtiers en assurance responsabilité civile décennale | Tribu",
    description:
      "Besoin d'un courtier en assurance responsabilité civile décennale ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance responsabilite civile decennale, courtage assurance responsabilite civile decennale, assurance responsabilite civile decennale, responsabilite civile decennale, assurance rcd, rcd, assurance decennale, garantie decennale, decennale",
    text: `L'assurance décennale aussi appelée garantie décennale couvre pendant 10 ans, après les travaux effectués, les frais ou dommages de réparation liés à un vice ou défaut de construction mettant en péril un ouvrage. Cette assurance est régie par la loi Spinetta qui stipule que tout constructeur d'ouvrage se déclare responsable des travaux et des dommages liés à ces derniers.\n 
    Le courtier en assurance responsabilité civile décennale est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Garantie financière d’achèvement",
    service_alias: "Garantie financière d’achèvement",
    parent: "assurance-construction",
    category: "IAS",
    name: "garantie-financiere-d-achevement",
    title:
      "Les courtiers en assurance garantie financière d’achèvement | Tribu",
    description:
      "Besoin d'un courtier en assurance garantie financière d’achèvement ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance garantie financiere achevement, courtage assurance garantie financiere achevement, assurance garantie financiere achevement, garantie financiere achevement, assurance gfa, gfa",
    text: `Protégeant les acquéreurs d’un bien en VEFA (Vente en l’état futur d’achèvement), la garantie financière d’achèvement a été rendue obligatoire en 2015 pour les projets d’habitation et les constructions mixtes à usage professionnel et d’habitation. La GFA permet au promoteur de commercialiser les lots de la VEFA dès le démarrage des travaux.\n
    Le courtier en assurance garantie financière d’achèvement est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Responsabilité civile promoteur",
    service_alias: "Responsabilité civile promoteur",
    parent: "assurance-construction",
    category: "IAS",
    name: "responsabilite-civile-promoteur",
    title: "Les courtiers en assurance responsabilité civile promoteur | Tribu",
    description:
      "Besoin d'un courtier en assurance responsabilité civile promoteur ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance responsabilite civile promoteur, courtage assurance responsabilite civile promoteur, assurance garantie responsabilite civile promoteur, responsabilite civile promoteur, assurance promoteur",
    text: `La responsabilité civile promoteur, bien que non obligatoire, garantie le promoteur immobilier contre les conséquences pécuniaires d’une erreur commise dans le cadre de ses activités et pour laquelle sa responsabilité est mise en cause.\n
    Le courtier en assurance responsabilité civile promoteur est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Multirisque professionnelle",
    service_alias: "Multirisque professionnelle",
    isParent: true,
    category: "IAS",
    name: "multirisque-professionnelle",
    title: "Les courtiers en assurance multirisque professionnelle | Tribu",
    description:
      "Besoin d'un courtier en assurance multirisque professionnelle ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance multirisque professionnelle, courtage assurance multirisque professionnelle, assurance multirisque professionnelle, multirisque professionnelle",
    text: `La multirisque professionnelle est une assurance qui couvre les biens mobiliers et immobiliers d’une entreprise, mais également sa responsabilité. Elle est conçue pour les professionnels afin de protéger leur activité.\n
    Le courtier en assurance multirisque professionnelle est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Bris de machine",
    service_alias: "Assurance bris de machine",
    parent: "multirisque-professionnelle",
    category: "IAS",
    name: "bris-de-machine",
    title: "Les courtiers en assurance bris de machine | Tribu",
    description:
      "Besoin d'un courtier en assurance bris de machine ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance bris de machine, courtage assurance bris de machine, assurance bris de machine, bris de machine",
    text: `Nacelle, tractopelle, grus… le matériel de chantier utilisé doit être assuré contre les dommages (choc, collision, renversement, vol, vandalisme…). L’assurance bris de machine couvre les engins du propriétaire ou du loueur de machine contre les bris.\n
    Le courtier en assurance bris de machine est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Dommage aux biens",
    service_alias: "Dommage aux biens",
    parent: "multirisque-professionnelle",
    category: "IAS",
    name: "dommage-aux-biens",
    title: "Les courtiers en assurance dommage aux biens | Tribu",
    description:
      "Besoin d'un courtier en assurance dommage aux biens ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance dommage aux biens, courtage assurance dommage aux biens, assurance dommage aux biens, dommage aux biens",
    text: `Au quotidien, l’entreprise fait face à de nombreux risques, de natures très variées, susceptibles d’endommager ses biens et de mettre en péril son activité. Pour protéger ses outils de travail et le patrimoine de la société face aux divers dommages accidentels, il existe différents contrats d’assurance dommages aux biens.\n
    Le courtier en assurance dommage aux biens est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Locaux professionnels",
    service_alias: "Locaux professionnels",
    parent: "multirisque-professionnelle",
    category: "IAS",
    name: "locaux-professionnels",
    title: "Les courtiers en assurance locaux professionnels | Tribu",
    description:
      "Besoin d'un courtier en assurance locaux professionnels ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance locaux professionnels, courtage assurance locaux professionnels, assurance locaux professionnels, locaux professionnels",
    text: `L’assurance des locaux professionnels fait partie des assurances indispensables à souscrire lorsqu'on lance son activité et est obligatoire dans la plupart des cas. Elle protège contre l'incendie, le vol, le vandalisme, les dégâts des eaux, les bris de glaces et d'enseigne etc.\n
    Le courtier en assurance locaux professionnels est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Responsabilité civile d’exploitation",
    service_alias: "Responsabilité civile d’exploitation",
    parent: "multirisque-professionnelle",
    category: "IAS",
    name: "responsabilite-civile-d-exploitation",
    title:
      "Les courtiers en assurance responsabilité civile d’exploitation | Tribu",
    description:
      "Besoin d'un courtier en assurance responsabilité civile d’exploitation ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance responsabilite civile exploitation, courtage assurance responsabilite civile exploitation, assurance responsabilite civile exploitation, responsabilite civile exploitation",
    text: `La responsabilité civile d’exploitation ne doit pas être confondue avec la responsabilité civile professionnelle. La RC exploitation s’applique en cas d’incident survenu pendant l’exploitation, dans le cadre des activités quotidiennes de l’entreprise alors que l’assurance responsabilité civile professionnelle concerne les dommages qui trouvent leur origine dans la réalisation même de la prestation.\n
    Le courtier en assurance responsabilité civile d’exploitation est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu`,
  },
  {
    service: "Protection juridique pro",
    service_alias: "Protection juridique pro",
    parent: "multirisque-professionnelle",
    category: "IAS",
    name: "protection-juridique-pro",
    title: "Les courtiers en assurance protection juridique pro | Tribu",
    description:
      "Besoin d'un courtier en assurance protection juridique pro ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance protection juridique pro, courtage assurance protection juridique pro, assurance protection juridique pro, protection juridique pro, courtier assurance pj pro, courtage assurance pj pro, assurance pj pro, pj pro",
    text: `La protection juridique professionnelle est un contrat d’assurance qui offre un ensemble de services et de prestations juridiques permettant au chef d’entreprise de faire valoir ses droits. Elle intervient dès l'apparition d'un litige, de façon à anticiper les risques juridiques et financiers.\n
    Le courtier en assurance protection juridique pro est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Perte d’exploitation",
    service_alias: "Assurance perte d’exploitation",
    parent: "multirisque-professionnelle",
    category: "IAS",
    name: "assurance-perte-d-exploitation",
    title: "Les courtiers en assurance perte d’exploitation | Tribu",
    description:
      "Besoin d'un courtier en assurance perte d’exploitation ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance perte exploitation, courtage assurance perte exploitation, assurance perte exploitation, perte exploitation",
    text: `Tout sinistre a généralement une incidence sur l’activité de la société : une baisse de l’activité voire une absence totale de chiffre d’affaires. L’assurance perte d’exploitation lui permet de se couvrir contre ce risque, par le biais du versement d’une indemnité à l’entreprise.\n
    Le courtier en assurance perte d’exploitation est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Risques informatiques",
    service_alias: "Assurance risques informatiques",
    parent: "multirisque-professionnelle",
    category: "IAS",
    name: "assurance-risques-informatiques",
    title: "Les courtiers en assurance risques informatiques | Tribu",
    description:
      "Besoin d'un courtier en assurance risques informatiques ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance risques informatiques, courtage assurance risques informatiques, assurance risques informatiques, risques informatiques",
    text: `L’assurance des risques informatiques protège le parc informatique d’une entreprise et de ses collaborateurs. Il s’agit donc d’une assurance qui protège le matériel informatique et peut faire l’objet de garanties complémentaires.\n
    Le courtier en assurance risques informatiques est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Responsabilité civile professionnelle",
    service_alias: "Responsabilité civile professionnelle",
    isParent: true,
    category: "IAS",
    name: "responsabilité-civile-professionnelle",
    title:
      "Les courtiers en assurance responsabilité civile professionnelle | Tribu",
    description:
      "Besoin d'un courtier en assurance responsabilité civile professionnelle ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance responsabilité civile professionnelle, courtage assurance responsabilité civile professionnelle, assurance responsabilité civile professionnelle, responsabilité civile professionnelle, courtier assurance rc pro, courtage rc pro, assurance rc pro, rc pro",
    text: `La responsabilité civile professionnelle, également appelée RC Pro, est une assurance qui vise à protéger l’entreprise contre les dommages physiques et immatériels qu’elle pourrait causer à un tiers dans le cadre de son activité.\n
    Le courtier en assurance responsabilité civile professionnelle est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "RC Pro Agriculteur",
    service_alias: "RC Pro Agriculteur",
    parent: "responsabilité-civile-professionnelle",
    category: "IAS",
    name: "rc-pro-agriculteur",
    title: "Les courtiers en assurance RC Pro agriculteur | Tribu",
    description:
      "Besoin d'un courtier en assurance RC Pro agriculteur ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance responsabilité civile professionnelle agriculteur, courtage assurance responsabilité civile professionnelle agriculteur, assurance responsabilité civile professionnelle agriculteur, responsabilité civile professionnelle agriculteur, courtier assurance rc pro agriculteur, courtage rc pro agriculteur, assurance rc pro agriculteur, rc pro agriculteur",
    text: `La RC pro agriculteur couvre l’exploitant agricole des dommages causés aux tiers à cause de son activité.
    L’assurance professionnelle agricole n’est pas obligatoire, car l’agriculteur ne fait pas partie des professions réglementées. Elle est toutefois fortement conseillée et ne doit pas être négligée.\n
    Le courtier en assurance RC pro agriculteur est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "RC Pro Artisan",
    service_alias: "RC Pro Artisan",
    parent: "responsabilité-civile-professionnelle",
    category: "IAS",
    name: "rc-pro-artisan",
    title: "Les courtiers en assurance RC Pro artisan | Tribu",
    description:
      "Besoin d'un courtier en assurance RC Pro artisan ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance responsabilité civile professionnelle artisan, courtage assurance responsabilité civile professionnelle artisan, assurance responsabilité civile professionnelle artisan, responsabilité civile professionnelle artisan, courtier assurance rc pro artisan, courtage rc pro artisan, assurance rc pro artisan, rc pro artisan",
    text: `La RC pro artisan couvre l’artisan des dommages causés aux tiers à cause de son activité.
    L’assurance professionnelle artisan n’est pas obligatoire, car l’artisan ne fait pas partie des professions réglementées. Elle est toutefois fortement conseillée et ne doit pas être négligée.\n
    Le courtier en assurance RC pro artisan est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "RC Pro Association",
    service_alias: "RC Pro Association",
    parent: "responsabilité-civile-professionnelle",
    category: "IAS",
    name: "rc-pro-association",
    title: "Les courtiers en assurance RC Pro association | Tribu",
    description:
      "Besoin d'un courtier en assurance RC Pro association ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance responsabilité civile professionnelle association, courtage assurance responsabilité civile professionnelle association, assurance responsabilité civile professionnelle association, responsabilité civile professionnelle association, courtier assurance rc pro association, courtage rc pro association, assurance rc pro association, rc pro association",
    text: `La RC pro association couvre l’association des dommages causés aux tiers à cause de son activité. L’assurance professionnelle association est obligatoire pour les associations sportives.\n
    Le courtier en assurance RC pro association est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "RC Pro Auto-entrepreneur",
    service_alias: "RC Pro Auto-entrepreneur",
    parent: "responsabilité-civile-professionnelle",
    category: "IAS",
    name: "rc-pro-auto-entrepreneur",
    title: "Les courtiers en assurance RC Pro auto-entrepreneur | Tribu",
    description:
      "Besoin d'un courtier en assurance RC Pro auto-entreprenur ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance responsabilité civile professionnelle auto entrepreneur, courtage assurance responsabilité civile professionnelle auto entrepreneur, assurance responsabilité civile professionnelle auto entrepreneur, responsabilité civile professionnelle auto entrepreneur, courtier assurance rc pro auto entrepreneur, courtage rc pro auto entrepreneur, assurance rc pro auto entrepreneur, rc pro auto entrepreneur",
    text: `La RC pro auto-entrepreneur couvre l’entrepreneur individuel des dommages causés aux tiers à cause de son activité.
    Il est fortement conseillé de souscrire une assurance professionnelle car la responsabilité d’une auto-entreprise peut être recherchée en cas de dommages corporels, matériels ou immatériels.\n
    Le courtier en assurance RC pro auto-entrepreneur est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "RC Pro Commerçant",
    service_alias: "RC Pro Commerçant",
    parent: "responsabilité-civile-professionnelle",
    category: "IAS",
    name: "rc-pro-commerçant",
    title: "Les courtiers en assurance RC Pro commerçant | Tribu",
    description:
      "Besoin d'un courtier en assurance RC Pro commerçant ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance responsabilité civile professionnelle commercant, courtage assurance responsabilité civile professionnelle commercant, assurance responsabilité civile professionnelle commercant, responsabilité civile professionnelle commercant, courtier assurance rc pro commercant, courtage rc pro commercant, assurance rc pro commercant, rc pro commercant",
    text: `La RC pro commerçant couvre l’artisan des dommages causés aux tiers à cause de son activité.
    L’assurance professionnelle artisan n’est pas obligatoire, car le commerçant ne fait pas partie des professions réglementées. Elle est toutefois fortement conseillée et ne doit pas être négligée.\n
    Le courtier en assurance RC pro commerçant est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "RC Pro Profession libérale",
    service_alias: "RC Pro Profession libérale",
    parent: "responsabilité-civile-professionnelle",
    category: "IAS",
    name: "rc-pro-profession-liberale",
    title: "Les courtiers en assurance RC Pro profession libérale | Tribu",
    description:
      "Besoin d'un courtier en assurance RC Pro profession libérale ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance responsabilité civile professionnelle profession liberale, courtage assurance responsabilité civile professionnelle profession liberale, assurance responsabilité civile professionnelle profession liberale, responsabilité civile professionnelle profession liberale, courtier assurance rc pro profession liberale, courtage rc pro profession liberale, assurance rc pro profession liberale, rc pro profession liberale",
    text: `Selon l’activité, la loi peut imposer la souscription d’une assurance RC Pro. Cette obligation ne concerne que les professions réglementées car les professionnels libéraux non réglementés n’y sont pas tenus. C’est le cas pour les professionnels du droit, de l’immobilier, de la santé, du transport, du tourisme etc.\n
    Le courtier en assurance RC Pro profession libérale est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Assurance des salariés",
    service_alias: "Assurance des salariés",
    isParent: true,
    category: "IAS",
    name: "assurance-des-salaries",
    title: "Les courtiers en assurance des salariés | Tribu",
    description:
      "Besoin d'un courtier en assurance des salariés ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance salaries, courtage assurance salaries, assurance salaries",
    text: `La prévoyance d’entreprise offre aux salariés une protection en cas d’incapacité de travail ou d’invalidité. Ils bénéficient ainsi d’un maintien de leur salaire. Contrairement à la complémentaire santé collective, la prévoyance d’entreprise n’est pas obligatoire pour tous les salariés.\n 
    Le courtier en assurance des salariés un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Prévoyance collective",
    service_alias: "Prévoyance collective",
    parent: "assurance-des-salaries",
    category: "IAS",
    name: "prevoyance-collective",
    title: "Les courtiers en assurance prévoyance collective | Tribu",
    description:
      "Besoin d'un courtier en assurance prévoyance collective ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance prevoyance collective, courtage assurance prevoyance collective, assurance prevoyance collective, prevoyance collective",
    text: `La prévoyance d’entreprise est une assurance collective proposée aux salariés, dans le but de venir compléter les indemnités versées de la Sécurité sociale. 
    Cette assurance professionnelle apporte des garanties supplémentaires à l’assuré ou à ses proches, notamment face à un accident du travail, un arrêt de travail, une invalidité ou un décès.\n
    Le courtier en assurance prévoyance collective est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Epargne salariale",
    service_alias: "Epargne salariale",
    parent: "assurance-des-salaries",
    category: "IAS",
    name: "épargne-salariale",
    title: "Les courtiers en assurance épargne salariale | Tribu",
    description:
      "Besoin d'un courtier en assurance épargne salariale ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance epargne salariale, courtage assurance epargne salariale, assurance epargne salariale, epargne salariale",
    text: `Mis en place à l’initiative de l’entreprise, le Plan d'Epargne Entreprise (PEE) offre aux salariés la possibilité de se constituer une épargne à court ou moyen terme.
    Les sources d’alimentation sont nombreuses : versements volontaires des salariés, intéressement, participation, abondement.\n
    Le courtier en assurance PEE est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Santé collective",
    service_alias: "Santé collective",
    parent: "assurance-des-salaries",
    category: "IAS",
    name: "sante-collective",
    title: "Les courtiers en assurance santé collective | Tribu",
    description:
      "Besoin d'un courtier en assurance santé collective ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance sante collective, courtage assurance sante collective, assurance sante collective, sante collective",
    text: `Une mutuelle d’entreprise est une complémentaire santé professionnelle qui vient compléter les garanties de base offertes par le régime général, en totalité ou en partie.
    Elle permet donc aux salariés d’être mieux couverts lors de soins ou d’une hospitalisation.\n
    Le courtier en assurance santé collective est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Retraite collective",
    service_alias: "Retraite collective",
    parent: "assurance-des-salaries",
    category: "IAS",
    name: "retraite-collective",
    title: "Les courtiers en assurance retraite collective | Tribu",
    description:
      "Besoin d'un courtier en assurance retraite collective ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance retraite collective, courtage assurance retraite collective, assurance retraite collective, retraite collective",
    text: `Le PER plan d’épargne pour la retraite collectif (PERCOL ou PERECO) est un dispositif d’entreprise qui permet aux salariés de se constituer une épargne en prévision de la retraite.
    Les sommes sont bloquées jusqu’à cette échéance retraite, sauf cas de déblocage exceptionnel.\n
    Le courtier en assurance retraite collective est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Assurance des véhicules",
    service_alias: "Assurance des véhicules",
    isParent: true,
    category: "IAS",
    name: "assurance-des-vehicules",
    title: "Les courtiers en assurance des véhicules | Tribu",
    description:
      "Besoin d'un courtier en assurance des véhicules ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance vehicules, courtage assurance vehicules, assurance vehicules",
    text: `L’assurance auto professionnelle s’adresse à toutes les entreprises qui utilisent un véhicule dans le cadre de leur mission. Elle peut assurer un seul véhicule ou une flotte de véhicules.\n
    Le courtier en assurance des véhicules est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Flotte automobile",
    service_alias: "Flotte automobile",
    parent: "assurance-des-vehicules",
    category: "IAS",
    name: "flotte-automobile",
    title: "Les courtiers en assurance flotte automobile | Tribu",
    description: "Les courtiers en assurance flotte automobile | Tribu",
    keywords:
      "courtier assurance flotte automobile, courtage assurance flotte automobile, assurance flotte automobile, flotte automobile",
    text: `L’assurance flotte automobile est une assurance qui a la capacité de couvrir l’ensemble du parc automobile en un seul et même contrat. Elle couvre tous types de véhicules, à savoir les voitures, les véhicules utilitaires, poids lourds, les tracteurs et engins agricoles, les engins de chantier, de manutention et de levage ainsi que leurs remorques.\n 
    Le courtier en assurance flotte automobile est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Transport de marchandises",
    service_alias: "Assurance transport de marchandises",
    parent: "assurance-des-vehicules",
    category: "IAS",
    name: "assurance-transport-de-marchandises",
    title: "Les courtiers en assurance transport de marchandises | Tribu",
    description:
      "Besoin d'un courtier en assurance transport de marchandises ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance transport marchandises, courtage assurance transport marchandises, assurance transport marchandises",
    text: `Une assurance marchandises « transports privés » (ou Transport Pour Propre Compte) couvre les marchandises appartenant à l’assuré et transportées par des moyens propres. L’assurance peut aussi garantir les marchandises et matériels transportés par des professionnels du transport.\n
    Le courtier en assurance transport de marchandises est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Transport de voyageurs",
    service_alias: "Assurance transport de voyageurs",
    parent: "assurance-des-vehicules",
    category: "IAS",
    name: "assurance-transport-de-voyageurs",
    title: "Les courtiers en assurance transport de voyageurs | Tribu",
    description:
      "Besoin d'un courtier en assurance transport de voyageurs ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance transport voyageurs, courtage assurance transport voyageurs, assurance transport voyageurs, courtier assurance tpv, courtage assurance tpv, assurance tpv",
    text: `L’assurance Transport Public de Voyageurs (TPV) indemnise les dommages causés aux personnes pendant le transport. Les propriétaires d’entreprises commerciales de transport de passagers, qu’il s’agisse de compagnies de taxis, d’autobus ou de limousines, doivent s’assurer que leurs clients sont protégés en cas d’accident ou de tout autre événement imprévu.\n
    Le courtier en assurance transport de voyageurs est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Flotte motocycle",
    service_alias: "Flotte motocycle",
    parent: "assurance-des-vehicules",
    category: "IAS",
    name: "flotte-motocycle",
    title: "Les courtiers en assurance flotte motocycle | Tribu",
    description:
      "Besoin d'un courtier en assurance flotte motocycle ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "courtier assurance flotte motocycle, courtage assurance flotte motocycle, assurance flotte motocycle, flotte motocycle",
    text: `L’assurance flotte motocyle est une assurance qui a la capacité de couvrir l’ensemble du parc automobile en un seul et même contrat. Elle couvre les deux-rvoues et les nouveaux engins de déplacement tels que les vélos à assistance électrique.\n
    Le courtier en assurance flotte motocycle est un intermédiaire entre le client, dont il recueille les besoins, et l’assureur, qui propose des offres de contrats. Gain de temps, tarifs avantageux, simplicité et tranquillité assurés avec nos courtiers Tribu.`,
  },
  {
    service: "Produits financiers",
    service_alias: "Produits financiers",
    isParent: true,
    disable: true,
    category: "CIF",
    name: "produits-financiers",
  },
  {
    service: "PEA",
    service_alias: "PEA",
    parent: 'produits-financiers',
    category: "CIF",
    name: "pea",
    title:
      "Les conseillers en investissements financiers Plan d’Epargne en Actions (PEA) | Tribu",
    description:
      "Besoin d'un conseiller en investissements financiers avec les meilleurs placements PEA ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "conseil pea, conseiller pea, conseil plan epargne actions, conseiller plan epargne actions, plan epargne actions, pea, cif pea, conseil investissements financiers pea, conseiller investissements financiers pea, conseil investissements financiers plan epargne actions, conseiller investissements financiers plan epargne actions, courtier pea, courtage pea",
    text: `Le Plan d'Épargne en Actions (PEA) permet de gérer un portefeuille d'actions et d'OPCVM d’actions européennes tout en bénéficiant d'avantages fiscaux (optimisation de la fiscalité sur les plus-values et les revenus des capitaux mobiliers). Le PEA peut faire l'objet d'une ouverture auprès d'un organisme bancaire ou tout autre intermédiaire financier susceptible de proposer des solutions d'investissement – comme les courtiers.\n
    Le statut de Conseil en Investissement Financier (CIF) permet à nos courtiers Tribu de proposer des solutions de Plan Epargne en Actions (PEA) bancaires.`,
  },
  {
    service: "Compte-titres",
    service_alias: "Compte-titres",
    parent: 'produits-financiers',
    category: "CIF",
    name: "compte-titres",
    title:
      "Les conseillers en investissements financiers Compte-titres (CTO) | Tribu",
    description:
      "Besoin d'un conseiller en investissements financiers avec les meilleurs placements comptes-titres ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "conseil cto, conseiller cto, conseil compte titres, conseiller compte titres, compte titres, cto, cif cto, conseil investissements financiers cto, conseiller investissements financiers cto, conseil investissements financiers compte titres, conseiller investissements financiers compte titres, courtier compte titres, courtage compte titres",
    text: `Le compte-titres est une enveloppe bancaire permettant à un investisseur d'acheter des valeurs mobilières françaises, européennes ou internationales. Le compte est composé d'une partie titres et d'une partie espèces.\n
    Le statut de Conseil en Investissement Financier (CIF) permet à nos courtiers Tribu de proposer des solutions de compte-titres ordinaires (CTO).`,
  },
  {
    service: "Compte à terme",
    service_alias: "Compte à terme",
    parent: 'produits-financiers',
    category: "CIF",
    name: "compte-a-terme",
    title:
      "Les conseillers en investissements financiers Compte à terme (CAT) | Tribu",
    description:
      "Besoin d'un conseiller en investissements financiers avec les meilleurs placements comptes à terme ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "conseil compte a terme, conseiller compte a terme, compte a terme, cat, cif cat, conseil investissements financiers cat, conseiller investissements financiers cat, conseil investissements financiers compte a terme, conseiller investissements financiers compte a terme, courtier compte a terme, courtage compte a terme",
    text: `Le compte à terme (aussi nommé CAT) est un produit d’épargne non réglementé. Il existe un large éventail de comptes à terme : le compte à terme à taux fixe, le compte à terme à taux variable, le compte à terme à taux progressif. \n
    Le statut de Conseil en Investissement Financier (CIF) permet à nos courtiers Tribu de proposer des solutions de compte à terme (CAT).`,
  },
  {
    service: "FIP",
    service_alias: "FIP",
    parent: 'produits-financiers',
    category: "CIF",
    name: "fip",
    title:
      "Les conseillers en investissements financiers Fonds d'Investissement de Proximité (FIP) | Tribu",
    description:
      "Besoin d'un conseiller en investissements financiers avec les meilleurs placements fonds d'investissement de proximité (FIP) ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "conseil fonds investissement proximite, conseiller fonds investissement proximite, fonds investissement proximite, fip, cif fip, conseil investissements financiers fip, conseiller investissements financiers fip, conseil investissements financiers fonds investissement proximite, conseiller investissements financiers fonds investissement proximité, courtier fip, courtage fip",
    text: `Les Fonds d'Investissement de Proximité (FIP) sont des fonds dont l'actif est composé au minimum de 70 % de PME françaises non cotées. Les FIP peuvent être investis en France métropole, en Corse ou dans les départements d’Outre-Mer, les FIP corse et FIP Outre-Mer faisant bénéficier d’une réduction d’impôt plus avantageuse, 30 % contre 25 % pour un FIP investi en France métropolitaine.\n 
    Le statut de Conseil en Investissement Financier (CIF) permet à nos courtiers Tribu de proposer des solutions de Fonds d'Investissement de Proximité (FIP)`,
  },
  {
    service: "FCPI",
    service_alias: "FCPI",
    parent: 'produits-financiers',
    category: "CIF",
    name: "fcpi",
    title:
      "Les conseillers en investissements financiers Fonds communs de placement dans l’innovation (FCPI) | Tribu",
    description:
      "Besoin d'un conseiller en investissements financiers avec les meilleurs placements fonds communs de placement dans l’innovation (FCPI) ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "conseil fonds communs placement innovation, conseiller fonds communs placement innovation, fonds communs placement innovation, fcpi, cif fcpi, conseil investissements financiers fcpi, conseiller investissements financiers fcpi, conseil investissements financiers fonds communs placement innovation, conseiller investissements financiers fonds communs placement innovation, courtier fcpi, courtage fcpi",
    text: `Les FCPI sont des Fonds communs de placement dans l’innovation qui financent des petites et moyennes entreprises innovantes (au minimum 70 %). Pour le FCPI, la réduction d’impôt maximale équivaut à 25 % des sommes investies.\n
    Le statut de Conseil en Investissement Financier (CIF) permet à nos courtiers Tribu de proposer des solutions de Fonds communs de placement dans l’innovation (FCPI).`,
  },
  {
    service: "SCPI",
    service_alias: "SCPI",
    parent: 'produits-financiers',
    category: "CIF",
    name: "scpi",
    title:
      "Les conseillers en investissements financiers Sociétés civiles de placement immobilier (SCPI). | Tribu",
    description:
      "Besoin d'un conseiller en investissements financiers avec les meilleurs placements sociétés civiles de placement immobilier (SCPI) ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "conseil société civile placement immobilier, conseiller société civile placement immobilier, société civile placement immobilier, scpi, cif scpi, conseil investissements financiers scpi, conseiller investissements financiers scpi, conseil investissements financiers société civile placement immobilier, conseiller investissements financiers société civile placement immobilier, courtier scpi, courtage scpi",
    text: `La SCPI (Société Civile de Placement Immobilier), appelé aussi « pierre papier », est un placement immobilier locatif de long terme. La société civile de placement immobilier, qui peut être diversifiée ou spécialisée, collecte des fonds auprès de plusieurs investisseurs dans le but d’acquérir et gérer un patrimoine immobilier locatif.\n
    Le statut de Conseil en Investissement Financier (CIF) permet à nos courtiers Tribu de proposer des solutions de sociétés civiles de placement immobilier (SCPI).`,
  },
  {
    service: "OPCI",
    service_alias: "OPCI",
    parent: 'produits-financiers',
    category: "CIF",
    name: "opci",
    title:
      "Les conseillers en investissements financiers Organisme de Placement Collectif Immobilier (OPCI) | Tribu",
    description:
      "Besoin d'un conseiller en investissements financiers avec les meilleurs placements Organismes de Placement Collectif Immobilier (OPCI) ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "conseil organisme placement collectif immobilier, conseiller organisme placement collectif immobilier, organisme placement collectif immobilier, opci, cif opci, conseil investissements financiers opci, conseiller investissements financiers opci, conseil investissements financiers organisme placement collectif immobilier, conseiller investissements financiers organisme placement collectif immobilier, courtier opci, courtage opci",
    text: `L’OPCI (Organisme de Placement Collectif Immobilier) est un placement immobilier non coté géré par une société de gestion de portefeuille agréée par l'Autorité des Marchés Financiers (AMF). Préconisé pour un investissement sur le long terme, l'OPCI gère un patrimoine majoritairement investi en immobilier tertiaire. \n
    Le statut de Conseil en Investissement Financier (CIF) permet à nos courtiers Tribu de proposer des solutions d’Organismes de Placement Collectif Immobilier (OPCI).`,
  },
  {
    service: "Produits financiers pro",
    service_alias: "Produits financiers pro",
    isParent: true,
    disable: true,
    category: "CIF",
    name: "produits-financiers-pro",
  },
  {
    service: "Compte-titres pro",
    service_alias: "Compte-titres pro",
    parent: "produits-financiers-pro",
    category: "CIF",
    name: "compte-titres-pro",
    title:
      "Les conseillers en investissements financiers Compte-titres pro (CTO) | Tribu",
    description:
      "Besoin d'un conseiller en investissements financiers avec les meilleurs placements comptes-titres pour entreprise ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "conseil cto pro, conseiller cto pro, conseil compte titres pro, conseiller compte titres pro, compte titres pro, cto pro, cif cto pro, conseil investissements financiers cto pro, conseiller investissements financiers cto pro, conseil investissements financiers compte titres pro, conseiller investissements financiers compte titres pro, courtier compte titres pro, courtage compte titres pro, courtier compte titres pro, courtage compte titres pro",
    text: `Le compte-titres pour entreprise permet de faire fructifier les liquidités à moyen terme en les plaçant dans des valeurs mobilières. Toutefois, l’objet social de la société doit autoriser la réalisation de telles opérations\n
    Le statut de Conseil en Investissement Financier (CIF) permet à nos courtiers Tribu de proposer des solutions de compte-titres ordinaires (CTO) pour entreprise.`,
  },

  {
    service: "Compte à terme pro",
    service_alias: "Compte à terme pro",
    parent: "produits-financiers-pro",
    category: "CIF",
    name: "compte-a-terme-pro",
    title:
      "Les conseillers en investissements financiers Compte à terme pro (CAT) | Tribu",
    description:
      "Besoin d'un conseiller en investissements financiers avec les meilleurs placements comptes à terme pour entreprise ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "conseil compte a terme pro, conseiller compte a terme pro, compte a terme pro, cat pro, cif cat pro, conseil investissements financiers cat pro, conseiller investissements financiers cat pro, conseil investissements financiers compte a terme pro, conseiller investissements financiers compte a terme pro, courtier compte a terme pro, courtage compte a terme pro",
    text: `Aussi appelé “dépôt à terme”, le compte à terme (CAT) pour entreprise est une solution d’épargne à court et moyen terme, dont les taux et la durée sont connus à l’avance. \n
    Le statut de Conseil en Investissement Financier (CIF) permet à nos courtiers Tribu de proposer des solutions de compte à terme (CAT) pour entreprise.`,
  },

  {
    service: "Girardin industriel",
    service_alias: "Girardin industriel",
    parent: "produits-financiers-pro",
    category: "CIF",
    name: "girardin-industriel",
    title:
      "Les conseillers en investissements financiers Girardin industriel | Tribu",
    description:
      "Besoin d'un conseiller en investissements financiers avec les meilleurs placements Girardin industriel ? Trouvez facilement les meilleurs profils sur Tribu !",
    keywords:
      "conseil girardin industriel, conseiller girardin industriel, conseil girardin industriel, conseiller girardin industriel, girardin industriel, cif girardin industriel, conseil investissements financiers girardin industriel, conseiller investissements financiers girardin industriel, courtier girardin industriel, courtage girardin industriel",
    text: `La loi Girardin du 21 juillet 2003 accorde une réduction d’impôt sur le revenu aux contribuables français réalisant un investissement productif en outre-mer dans le cadre d’une entreprise exerçant une activité agricole, industrielle, commerciale ou artisanale éligible à ce dispositif fiscal.\n
    Le statut de Conseil en Investissement Financier (CIF) permet à nos courtiers Tribu de proposer des solutions en Girardin industriel.`,
  },
];

export default SERVICES;
