import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
    Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row,
} from 'reactstrap';
import Form from 'reactstrap/es/Form';
import Aux from '../hoc/Aux';

const ReportModal = (props) => {
    const [text, setText] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const {
        isOpen,
        toggle,
        onSubmit,

        title,
    } = props;

    const handleSubmit = () => {
        if (!text.length) return;

        onSubmit(text);
        setSubmitted(true);
    };

    let body = (
        <Aux>
            <ModalBody>
                <Form>
                    <FormGroup className="mb-0">
                        <Label for="exampleText">Décrivez votre problème</Label>
                        <Input type="textarea" value={text} onChange={(e) => setText(e.target.value)} />
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" className="mr-2" size="sm" disabled={!text.length} onClick={handleSubmit}>Signaler</Button>
                <Button color="secondary" onClick={toggle} size="sm">Annuler</Button>
            </ModalFooter>
        </Aux>
    );

    if (submitted) {
        body = (
            <ModalBody>
                <Row className="justify-content-center">
                    <div className="icon icon-shape bg-gradient-primary text-white rounded-circle fadeIn" style={{ width: 100, height: 100 }}>
                        <i className="fas fa-check display-1" style={{ fontSize: 34 }} />
                    </div>
                </Row>
                <Row className="mt-3">
                    <Col className="text-center">
                        <h5 className="h3 mb-0">Le contenu a été signalé !</h5>
                    </Col>
                </Row>
                <Row className="justify-content-center mt-3">
                    <Col className="col-auto">
                        <Button color="primary" size="sm" onClick={toggle}>Terminer</Button>
                    </Col>
                </Row>
            </ModalBody>
        );
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>{title}</ModalHeader>
            {body}
        </Modal>
    );
};

export default ReportModal;

ReportModal.propTypes = {
    isOpen: PropTypes.bool,
    onSubmit: PropTypes.func,
    title: PropTypes.string,
    toggle: PropTypes.func,
};

ReportModal.defaultProps = {
    isOpen: false,
    onSubmit: () => {},
    title: '',
    toggle: () => {},
};
