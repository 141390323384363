import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
    Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
} from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { dbDeleteChat } from '../../store/chat/actions';

const ChatThreadMenu = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const {
        match,
        history,
        deleteChat,
        chat,
    } = props;

    const handleDelete = () => {
        const { chatId } = match.params;

        //if (chatId === chat.id) history.push('/chat/');
        deleteChat(chat.id);
        if (chatId === chat.id) history.push('/chat/')
    };

    return (
        <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
            <DropdownToggle className="cursor-pointer" tag="span" onClick={() => setIsOpen(!isOpen)}>
                <i className="fas fa-ellipsis-h" />
            </DropdownToggle>
            <DropdownMenu right>
                <DropdownItem onClick={handleDelete}>Supprimer</DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
};

export default withRouter(connect(null, {
    deleteChat: dbDeleteChat,
})(ChatThreadMenu));

ChatThreadMenu.propTypes = {
    chat: PropTypes.object,
    deleteChat: PropTypes.func,
};

ChatThreadMenu.defaultProps = {
    chat: {},
    deleteChat: () => {},
};
