import * as actionTypes from '../actionConsts';


export const setNavbarOpened = (opened) => {
    localStorage.setItem('navbar', opened);

    return {
        type: actionTypes.SET_NAVBAR_OPENED,
        opened,
    };
};
