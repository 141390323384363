import { Link } from "react-router-dom";
import Aux from "../../../hoc/Aux";
import PageComponent from "../PageComponent";
import "../custom.css";

function RemoveContact() {
  return (
    <Aux>
      <PageComponent>
        <h1 className="help-item-title">Supprimer un contact</h1>
        <p className="help-item-text">
          Dans la page Contacts, à côté du nom de chacun de vos contacts, vous
          avez une enveloppe et une icône "..." :
          <br />
          <br />
          <ul>
            <li>
              L’icône "..." permet de supprimer la personne de votre liste de
              contacts
            </li>
            <li>L'enveloppe permet d'écrire à cette personne.</li>
          </ul>
        </p>
      </PageComponent>
    </Aux>
  );
}

export default RemoveContact;
