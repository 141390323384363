import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classes from '../../assets/Scss/components/PublishModal.scss';

class PublishPic2 extends Component {
    componentDidMount() {
        this.scrollToRight();
    }

    componentDidUpdate(prevProps) {
        const { videos } = this.props;

        if (videos.length !== prevProps.videos.length) {
            this.scrollToRight();
        }
    }

    scrollToRight = () => {
        const { scrollWidth } = this.publishAddImageRef;
        const width = this.publishAddImageRef.clientWidth;
        const maxScrollLeft = scrollWidth - width;
        this.publishAddImageRef.scrollLeft = maxScrollLeft > 0 ? maxScrollLeft : 0;
    };

    render() {
        const {
            videos,
            addVideo,
            deleteVideo,
        } = this.props;
        
        return (
            <div className={classes.PublishModal}>
                <div className={classes.images} ref={(publishAddImageRef) => { this.publishAddImageRef = publishAddImageRef; }}>
                    {
                        videos.map((video, key) => {

                            return (
                                <div
                                    className={`${classes.previews}  `}
                                    key={video.img || video}
                                >
                                    <video controls width="550" className='post-video'>

                                        <source src={video.img || video} />
                                    </video>
                                    <div className={classes.discard} onClick={() => deleteVideo(key)}>
                                        <i className="fas fa-times" />
                                    </div>
                                </div>
                            );
                        })
                    }
                    {
                        videos.length > 0 && (
                            <div className={classes.addImage} onClick={addVideo}>
                                <div className={classes.add}>
                                    <i className="fas fa-plus-square" />
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default PublishPic2;

PublishPic2.propTypes = {
    addImage: PropTypes.func,
    deleteImage: PropTypes.func,
    images: PropTypes.array,
};

PublishPic2.defaultProps = {
    addImage: () => { },
    deleteImage: () => { },
    images: [],
};