import { Link } from "react-router-dom";
import Aux from "../../../hoc/Aux";
import PageComponent from "../PageComponent";
import "../custom.css";

function EditProfile() {
  return (
    <Aux>
      <PageComponent>
        <h1 className="help-item-title">Modifier son profil</h1>
        <p className="help-item-text">
          Nous vous donnons ici tous les conseils pour que vous puissiez
          facilement compléter et/ou modifier votre profil Tribu.
          <p className="help-item-subtitle">Description</p>
          La description doit comporter au moins 200 caractères. Elle est la
          clef pour vous introduire rapidement auprès des membres Tribu.
          <p className="help-item-subtitle">Entreprise</p>
          Les structures dans lesquelles exerce une responsabilité le membre
          ayant créé un compte Tribu.
          <p className="help-item-subtitle">Photo de profil</p>
          Depuis votre compte, dans le menu, cliquez sur "Mon profil" puis sur
          votre photo. Vous pouvez ensuite télécharger votre photo. Assurez-vous
          qu'elle soit droite et non inclinée.
          <p className="help-item-subtitle">Expérience</p>
          Votre statut de collaborateur au sein de votre entreprise est
          l’expérience par défaut. Vous pouvez mettre à jour cette dernière
          depuis votre profil.
          <br />
          <br />
          Vous pouvez rajouter toutes vos expériences professionnelles en lien
          ou nom avec le courtage, pour cela cliquez sur "Ajouter une
          expérience".
          <br />
          <br />
          Pour supprimer une expérience, cliquez sur "Editez" depuis votre
          profil puis "Modifier une expérience". Cliquez sur "Supprimer" en bas
          du formulaire.
          <p className="help-item-subtitle">Formation</p>
          Vous pouvez enregistrer vos capacités professionnelles depuis votre
          profil.
          <br />
          <br />
          Vous pouvez ajouter toutes vos formations académiques et
          professionnelles, pour cela cliquez sur "Editez" depuis votre profil
          puis "Ajouter une formation".
          <br />
          <br />
          Pour supprimer une formation, cliquez sur "Editez" depuis votre profil
          puis "Modifier une formation". Cliquez sur "Supprimer" en bas du
          formulaire.
        </p>
      </PageComponent>
    </Aux>
  );
}

export default EditProfile;
