import React, { Component } from "react";
import NotificationAlert from "react-notification-alert";
import { Button, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { PDD } from "../router/Routes";

class RgpdNotif extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hide: false,
    };
  }

  componentDidMount() {
    this.setState({
      hide: localStorage.getItem("hideRgpd"),
    });

    this.rgpdCookie();
  }

  acceptRgpdHandler = () => {
    localStorage.setItem("acceptRgpd", true);
    localStorage.setItem("hideRgpd", true);
    this.setState({ hide: true });
  };

  denyRgpdHandler = () => {
    localStorage.setItem("acceptRgpd", false);
    localStorage.setItem("hideRgpd", true);
    this.setState({ hide: true });
  };

  rgpdCookie = () => {
    const options = {
      place: "br",
      message: (
        <div className="alert-text">
          <Row className="mb-2">
            <Col xs={12}>
              <h4 style={{ color: "white" }}>
                Votre vie privée est importante pour nous !
              </h4>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={12}>
              Nous utilisons des cookies et d'autres technologies pour améliorer
              votre expérience de navigation, analyser le trafic de notre site
              et personnaliser le contenu. Ces données nous aident à comprendre
              comment nos visiteurs utilisent notre site afin de l'améliorer.
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="col-auto mt-3 mt-md-0 float-right">
              <Link to={PDD} className="text-white">
                En savoir plus
              </Link>
            </Col>
          </Row>
          <Row style={{ display: "flex", justifyContent: "flex-end" }}>
            <Col className="col-auto   ">
              <Button
                onClick={this.denyRgpdHandler}
                className="btn-link"
                style={{ color: "var(--warning)" }}
              >
                Refuser
              </Button>
            </Col>
            <Col className="col-auto  ">
              <Button onClick={this.acceptRgpdHandler}>Accepter</Button>
            </Col>
          </Row>
        </div>
      ),
      type: "default",
      closeButton: false,
    };
    this.rgpdRef.notificationAlert(options);
  };

  render() {
    const { hide } = this.state;

    return (
      !hide && (
        <NotificationAlert
          ref={(rgpd) => {
            this.rgpdRef = rgpd;
          }}
        />
      )
    );
  }
}

export default RgpdNotif;
