import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Brand from "../../assets/Pics/Icons/tribu-white.svg";
import Aux from "../../hoc/Aux";
import config from "../../config/config";
import { HOW_IT_WORKS, GET_IN_TOUCH, HELP_CENTER } from "../../router/Routes";

class Sitemap extends Component {
  render() {
    return (
      <Aux>
        <Helmet>
          <title>{`${config.title} - Plan du site`}</title>
        </Helmet>
        <div className="header py-9 bg-gradient-info">
          <Container>
            <div className="header-body">
              <Row className="justify-content-center">
                <Col className="col-auto">
                  <Link to="/">
                    <img
                      className="mb-3"
                      alt="Tribu"
                      src={Brand}
                      style={{ maxWidth: "75px" }}
                    />
                  </Link>
                </Col>
              </Row>
              <hr className="bg-white w-50 text-left my-2" />
              <p className="text-white text-center display-4 font-weight-300">
                Où trouver les bonnes informations ?
              </p>
            </div>
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </Container>
        </div>
        <section className="header bg-white">
          <Container className="py-6">
            <Row className="justify-content-center">
              <Col xs={12}>
                <h5 className="h1 text-center font-weight-normal">
                  Pour les grands comptes
                </h5>
              </Col>
              <Col className="mt-5" xs={12} md={10}>
                <Row className="text-center">
                  <Col>
                    <Link to="/corporate">Tribu Corporate</Link>
                  </Col>
                  <Col>
                    <Link to="/signup/corporate">
                      Créer mon profil grand compte
                    </Link>
                  </Col>
                </Row>
                <Row className="text-center mt-3">
                  <Col>
                    <Link to="/search">Tous les courtiers</Link>
                  </Col>
                  <Col>
                    <Link to="/search/services">Courtiers par services</Link>
                  </Col>
                  <Col>
                    <Link to="/search/categories">
                      Courtiers par catégories
                    </Link>
                  </Col>
                  <Col>
                    <Link to="/search/villes">Courtiers par villes</Link>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="justify-content-center mt-6">
              <Col xs={12}>
                <h5 className="h1 text-center font-weight-normal">
                  Pour les courtiers
                </h5>
              </Col>
              <Col className="mt-5" xs={12} md={10}>
                <Row className="text-center">
                  <Col>
                    <Link to="/signup">Créer mon profil courtier</Link>
                  </Col>
                  <Col>
                    <Link to="/signin">Se connecter</Link>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="justify-content-center mt-6">
              <Col xs={12}>
                <h5 className="h1 text-center font-weight-normal">
                  À propos de Tribu
                </h5>
              </Col>
              <Col className="mt-5" xs={12} md={10}>
                <Row className="text-center">
                  <Col>
                    <Link to="/about">Qui sommes-nous ?</Link>
                  </Col>
                  <Col>
                    <Link to={HOW_IT_WORKS}>Comment ça marche ?</Link>
                  </Col>
                  <Col>
                    <Link to={HELP_CENTER}>Centre d'assistance</Link>
                  </Col>
                  <Col>
                    <Link to={GET_IN_TOUCH}>Contact</Link>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </Aux>
    );
  }
}

export default Sitemap;
