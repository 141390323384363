import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import Form from "reactstrap/es/Form";
import Aux from "../../hoc/Aux";
import { connect } from "react-redux";
import { db, firebaseApp } from "../../config/firebase";
import { Link } from "react-router-dom";
import DefaultProfile from "../../assets/Pics/defaultProfile.jpg";
import Moment from "react-moment";
import { ClipLoader } from "react-spinners";

const RepostModal = (props) => {
  const { isOpen, toggle, onSubmit, post, isCurrentUserPremium, user } = props;
  const { author } = post;
  const type = author.isCompany ? "company" : "user";

  const userId = firebaseApp.auth().currentUser.uid;

  const [profilePic, setProfilePic] = useState();

  useEffect(() => {
    type === "user"
      ? db
          .collection("users")
          .doc(author.userId)
          .get()
          .then((doc) => {
            setProfilePic(doc.data().profilePic);
          })
      : db
          .collection("companies")
          .doc(author.userId)
          .get()
          .then((doc) => {
            setProfilePic(doc.data().profilePic);
          });
  }, []);

  const handleSubmit = () => {
    onSubmit();
  };

  let body = (
    <Aux>
      <ModalBody className="">
        {props.isLoading && (
          <Col className="col-12 text-center">
            <ClipLoader color="#1171ef" size={30} />
          </Col>
        )}
        <Col
          style={{
            wordWrap: "break-word",
            borderRadius: 10,
          }}
          className="p-4 shadow"
        >
          <Row className="justify-content-between pb-3">
            <Col>
              <Row className="align-items-center">
                <Col className="col-auto pr-0">
                  {isCurrentUserPremium ||
                  userId === author.userId ||
                  user.companies.findIndex(
                    (company) => company.id === author.userId
                  ) !== -1 ? (
                    <Link
                      target="_blank"
                      to={`/${type}/${author.username}/about`}
                    >
                      <img
                        className="avatar rounded-circle"
                        src={profilePic ? profilePic : DefaultProfile}
                        alt=""
                      />
                    </Link>
                  ) : (
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => props.toggle()}
                    >
                      <img
                        className="avatar rounded-circle"
                        src={profilePic ? profilePic : DefaultProfile}
                        alt=""
                      />
                    </div>
                  )}
                </Col>
                <Col className="col-auto align-items-center">
                  {props.isCurrentUserPremium ||
                  userId === author.userId ||
                  companies.findIndex(
                    (company) => company.id === author.userId
                  ) !== -1 ? (
                    <Link
                      target="_blank"
                      to={`/${type}/${author.username}/about`}
                      className="h4 text-primary d-block mb-0"
                    >
                      {author.name}
                    </Link>
                  ) : (
                    <div
                      className="h4 text-primary d-block mb-0"
                      style={{ cursor: "pointer" }}
                      onClick={() => props.toggle()}
                    >
                      {author.name}
                    </div>
                  )}
                  <small>
                    <Moment locale="fr" fromNow ago>
                      {post.createdAt}
                    </Moment>
                  </small>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className="">
              <p className="p-0 m-0  post-text">{post.text}</p>
            </Col>
          </Row>
        </Col>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          className="mr-2"
          size="sm"
          onClick={handleSubmit}
        >
          Reposter
        </Button>
        <Button color="secondary" onClick={toggle} size="sm">
          Annuler
        </Button>
      </ModalFooter>
    </Aux>
  );

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        Reposter sur votre fil d'actualité
      </ModalHeader>
      {body}
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  user: state.User.user,
});

export default connect(mapStateToProps)(RepostModal);

RepostModal.propTypes = {
  user: PropTypes.object,
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  toggle: PropTypes.func,
  isCurrentUserPremium: PropTypes.bool,
};

RepostModal.defaultProps = {
  user: {},
  isOpen: false,
  onSubmit: () => {},
  title: "",
  toggle: () => {},
  isCurrentUserPremium: false,
};
