import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
    Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
} from 'reactstrap';
import { connect } from 'react-redux';
import messageClasses from '../../assets/Scss/components/Chat.scss';
import { dbDeleteComment as actionsDeleteComment } from '../../store/posts/actions';

class CommentaryActions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menu: false,
        };
    }

    handleDeleteComment = (commentID) => {
        const { deleteComment } = this.props;
        deleteComment(commentID);
    };

    render() {
        const {
            menu,
        } = this.state;

        const {
            commentId,
        } = this.props;

        return (
            <Dropdown isOpen={menu} toggle={() => this.setState({ menu: !menu })} >
                <DropdownToggle
                    tag="span"
                    onClick={this.toggle}
                    data-toggle="dropdown"
                    className={`${messageClasses.dropdown} ml-2 btn-sm pr-0`}
                >
                    <i className="fas fa-ellipsis-h text-gray" />
                </DropdownToggle>
                <DropdownMenu right>
                    <DropdownItem onClick={() => this.handleDeleteComment(commentId)}>Supprimer</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    }
}

const mapDispatchToProps = (state) => ({
    user: state.User.user,
});

export default connect(mapDispatchToProps, {
    deleteComment: actionsDeleteComment,
})(CommentaryActions);

CommentaryActions.propTypes = {
    deleteComment: PropTypes.func,
    commentId: PropTypes.string,
};

CommentaryActions.defaultProps = {
    deleteComment: () => {},
    commentId: null,
};
