import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { Card, Col, Row, CardBody } from "reactstrap";

import PostHeader from "./PostHeader";
import PostContent from "./PostContent";
import PostEngagements from "./PostEngagements";
import Aux from "../../hoc/Aux";
import PropTypes from 'prop-types';

import classes from "../../assets/Scss/components/Post.scss";
import { firebaseApp, db } from "../../config/firebase";
import PremiumModal from "../Premium/PremiumModal";

const Posts2 = (props) => {
  const [isCurrentUserPremium, setIsCurrentUserPremium] = useState(false);
  const [premiumModal, setPremiumModal] = useState(false);
  const userId = firebaseApp.auth().currentUser.uid;
  const [posts, setPosts] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const {postMaxLength} = props


 

  useEffect(() => {
    db.collection("users")
      .doc(userId)
      .get()
      .then((doc) => {
        const premium = doc.get("premium");
        setIsCurrentUserPremium(premium);
      });
  }, []);

  const asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const contactIds = await db
        .collection("users")
        .doc(userId)
        .collection("contacts")
        .where("status", "==", 1)
        .get()
        .then((snapshots) => snapshots.docs.map((doc) => doc.id));

      const companyIds = await db
        .collection("users")
        .doc(userId)
        .collection("following")
        .where("following", "==", true)
        .get()
        .then((snapshots) => snapshots.docs.map((doc) => doc.id));

      const ownCompanyIds = await db
        .collection("companies")
        .where(`owners.${userId}`, "==", true)
        .get()
        .then((snapshots) => snapshots.docs.map((doc) => doc.id));

      const authorIds = [
        ...contactIds,
        ...companyIds,
        ...ownCompanyIds,
        userId,
      ];

      var postPromises = [];
      var newPosts = [];

      await asyncForEach(authorIds, async (contactId) => {
        postPromises.push(await fetchPostsByUserId(contactId));
      });

      await asyncForEach(postPromises, async (post) => {
        await asyncForEach(post, async (items) => {
          if (items.isRepost !== true) newPosts.push(items);
          else {
            const { post, error } = await db
              .collection("posts")
              .doc(items.originalPostId)
              .get()
              .then((doc) => {
                if (doc.exists) return { post: { ...doc.data(), id: doc.id } };
                else return { error: true };
              });
            if (post) newPosts.push({ ...items, post });
          }
        });
      });

      setPosts(sortPosts(newPosts));
      setLoading(false);
    };
    fetchData();
  }, []);

  const sortPosts = (posts) => posts.sort((a, b) => b.createdAt - a.createdAt);

  const fetchPostsByUserId = (userId) => {
    let query = db
      .collection("posts")
      .where("author.userId", "==", userId)
      .orderBy("createdAt", "desc");

    return query.get().then((snapshots) =>
      snapshots.docs.map((snapshot) => ({
        id: snapshot.id,
        comments: [],
        userLikes: snapshot.data().likes && snapshot.data().likes[userId],
        ...snapshot.data(),
      }))
    );
  };

  if (isLoading) {
    return (
      <Row className="justify-content-center mt-4">
        <ClipLoader color="#1171ef" />
      </Row>
    );
  }

  if (!posts.length) {
    return (
      <Row className="mb-3">
        <Col>
          <Card>
            <CardBody className="text-center">
              Aucune nouvelle publication.
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }

  return (
    <Aux>
      <PremiumModal
        isOpen={premiumModal}
        toggle={() => setPremiumModal(!premiumModal)}
      />
      {posts.map((post) => (
        <Row key={post.id} className={`${classes.Post} mb-3`}>
          <Col>
            <Card className="shadow mb-0">
              <PostHeader
                post={post}
                isCurrentUserPremium={isCurrentUserPremium}
                toggle={() => setPremiumModal(!premiumModal)}
              />
              <PostContent post={post} postMaxLength={postMaxLength} />
              <PostEngagements
                post={post}
                isCurrentUserPremium={isCurrentUserPremium}
              />
            </Card>
          </Col>
        </Row>
      ))}
    </Aux>
  );
};

export default Posts2;

Posts2.propTypes = {
  postMaxLength: PropTypes.number,
};

Posts2.defaultProps = {
  postMaxLength: 0,
};
