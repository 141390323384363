import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { db, firebaseApp } from '../../config/firebase';
import View from '../../components/Notifications/View';
import {
    Badge,
    DropdownItem, DropdownMenu, DropdownToggle, ListGroup, NavItem, Dropdown,
} from 'reactstrap';
import classes from '../../assets/Scss/components/Notifications.scss';
import CompanyFollower from '../../components/Notifications/CompanyFollower';
import UserFollower from '../../components/Notifications/UserFollower';
import CompanyView from '../../components/Notifications/CompanyView';
import Aux from '../../hoc/Aux';
import PremiumModal from '../../views/Premium/PremiumModal';

const DefaultNotificationsVisits = (props) => {
    const uid = firebaseApp.auth().currentUser.uid;
    const [userVisits, setUserVisits] = useState([]);
    const [companyVisits, setCompanyVisits] = useState([]);
    const [userFollowers, setUserFollowers] = useState([]);
    const [companyFollowers, setCompanyFollowers] = useState([]);
    const [isOpen, setOpen] = useState(false);
    const [hasOpened, setHasOpened] = useState(false);
    const [isCurrentUserPremium, setIsCurrentUserPremium] = useState(false);
    const [premiumModal, setPremiumModal] = useState(false);

    useEffect(() => {
        if (props.allowNotifications) fetch();
    }, []);

    useEffect(() => {
        if (isOpen) {
            if (!hasOpened) setHasOpened(true)
        }
    }, [isOpen]);

    useEffect(() => {
        if (hasOpened) {
            db.collection("users").doc(uid).update({
                lastTimeViewNotificationOpened: Date.now()
            })
        }
    }, [hasOpened]);

    useEffect(() => {
        db.collection('users').doc(uid).get().then((doc) => {
            const premium = doc.get("premium")
            setIsCurrentUserPremium(premium)
        })
    }, []);

    const occurrences1 = userVisits.reduce(function (acc, curr) {
        return acc[curr] ? ++acc[curr] : acc[curr] = 1, acc
    }, {});
    const occurrences2 = companyVisits.reduce(function (acc, curr) {
        return acc[curr] ? ++acc[curr] : acc[curr] = 1, acc
    }, {});
    let body1 = [...new Set(userVisits)].slice(0, 5).map((visit) => <View userId={visit} count={occurrences1[visit]} isCurrentUserPremium={isCurrentUserPremium}
        toggle={() => {
            setPremiumModal(true)
        }} />);
    let body2 = [...new Set(companyVisits)].slice(0, 5).map((visit) => <CompanyView userId={visit} count={occurrences2[visit]} isCurrentUserPremium={isCurrentUserPremium}
        toggle={() => {
            setPremiumModal(true)
        }} />);
    let body3 = [...new Set(companyFollowers)].slice(0, 5).map((obj) => <CompanyFollower followerId={obj.followerId} companyId={obj.companyId} isCurrentUserPremium={isCurrentUserPremium}
        toggle={() => {
            setPremiumModal(true)
        }} />);
    let body4 = [...new Set(userFollowers)].slice(0, 5).map((id) => <UserFollower userId={id} isCurrentUserPremium={isCurrentUserPremium}
        toggle={() => {
            setPremiumModal(true)
        }} />);

    const fetch = () => {
        db.collection("users").doc(uid).get()
            .then((documentSnapshot) => {
                const lastTimeViewNotificationOpened = documentSnapshot.get("lastTimeViewNotificationOpened") ?? 0
                db.collection("users").doc(uid).collection("views").where("date", ">", lastTimeViewNotificationOpened).orderBy("date", "desc").get()
                    .then((querySnapshot) => {
                        setUserVisits(querySnapshot.docs.map(visit => visit.get("userId")));
                    })
                db.collection("users").doc(uid).collection("companyViews").where("date", ">", lastTimeViewNotificationOpened).orderBy("date", "desc").get()
                    .then((querySnapshot) => {
                        setCompanyVisits(querySnapshot.docs.map(visit => visit.get("userId")));
                    })
                db.collection("users").doc(uid).collection("followers").where("date", ">", lastTimeViewNotificationOpened).orderBy("date", "desc").get()
                    .then((querySnapshot) => {
                        setUserFollowers(querySnapshot.docs.map(doc => doc.id))
                    })
                db.collection("users").doc(uid).collection("companyFollowers").where("date", ">", lastTimeViewNotificationOpened).orderBy("date", "desc").get()
                    .then((querySnapshot) => {
                        setCompanyFollowers(querySnapshot.docs.map(doc => doc.data()))
                    })
            })
    }

    if (!userVisits.length && !companyVisits.length && !userFollowers.length && !companyFollowers.length) {
        return (
            <NavItem>
                <Link to="/user/visits" className="nav-link">
                    <i className="fa fa-eye" style={{ fontSize: '100%' }} />
                </Link>
            </NavItem>
        );
    }

    /*if (!userVisits.length && !companyVisits.length && !userFollowers.length && !companyFollowers.length) {
        body = (
            <ListGroupItem className="list-group-item-action" key={78}>
                <Row className="align-items-center justify-content-center">
                    <p className="mb-0 font-weight-500">
                        Aucune demande.
                    </p>
                </Row>
            </ListGroupItem>
        );
    }*/

    return (
        <Aux>
            <PremiumModal isOpen={premiumModal} toggle={() => setPremiumModal(!premiumModal)} />
            <Dropdown nav className={classes.Notifications} isOpen={isOpen} toggle={() => setOpen(!isOpen)}>
                <DropdownToggle className="nav-link cursor-pointer" color="" tag="a">
                    <span>
                        <i className="fa fa-eye" />
                        <Badge color="primary" className={`${classes.notify} badge-md badge-circle badge-floating border-white`}>{userVisits.length + companyVisits.length + userFollowers.length + companyFollowers.length}</Badge>
                    </span>
                </DropdownToggle>
                <DropdownMenu
                    className="dropdown-menu-xl py-0 overflow-hidden"
                >
                    <div className="px-3 py-3">
                        <h6 className="text-sm text-muted m-0">Vues</h6>
                    </div>
                    <ListGroup flush>
                        {body4}
                        {body3}
                        {body2}
                        {body1}
                    </ListGroup>
                    <DropdownItem className="text-center text-primary font-weight-bold py-3" to="/user/visits" tag={Link}>
                        Voir toutes les vues
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </Aux>
    );
};

export default DefaultNotificationsVisits;
