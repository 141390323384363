// React Cores
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";

// components
import { Navbar, Container, Row, Col, Nav, Collapse } from "reactstrap";

import { connect } from "react-redux";
import Dropdown from "./Sidebar/Dropdown";
import Item from "./Sidebar/Item";
import links from "../../data/SidebarLinks.json";
import Brand from "../../assets/Pics/Icons/brand.png";
import classes from "../../assets/Scss/components/Sidebar.scss";
import UserMenu from "./DefaultUserMenu";
import { setNavbarOpened as navbarSetNavbarOpened } from "../../store/navbar/actions";
import Notifications from "./DefaultNotifications";
import DefaultSearchBar from "./DefaultSearchBar";

class DefaultSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: false,
    };
  }

  createLinks = () =>
    links.default.map((prop, key) => {
      const linkIndex = key;
      const { opened } = this.state;

      if (prop.space)
        return <div key={linkIndex} style={{ height: prop.space }} />;

      if (prop.path)
        return (
          <Item
            key={linkIndex}
            {...prop}
            setOpened={() => this.setState({ opened: !opened })}
          />
        );

      return (
        <Dropdown
          key={linkIndex}
          {...prop}
          setOpened={() => this.setState({ opened: !opened })}
        />
      );
    });

  render() {
    const { opened } = this.state;

    const { opened: navbarOpened, setNavbarOpened } = this.props;

    return (
      <div className={classes.Sidebar}>
        <Navbar
          className={`${classes.sidebar} ${
            !navbarOpened ? classes.minimized : classes.expanded
          } navbar-vertical fixed-left navbar-light bg-white py-0 py-md-4`}
          expand="md"
          id="sidenav-main"
        >
          <Container fluid>
            <button
              className="navbar-toggler"
              type="button"
              onClick={() => this.setState({ opened: !opened })}
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div>
              <Link to="/" className={`${classes.BrandIcon} mt-md--3`}>
                <img alt="Brand Icon" src={Brand} />
              </Link>
              <span className="float-right">
                <i
                  className={`fas ${
                    navbarOpened ? "fa-align-justify" : "fa-align-right"
                  } d-none d-md-inline-block text-primary`}
                  style={{ cursor: "pointer" }}
                  onClick={() => setNavbarOpened(!navbarOpened)}
                />
              </span>
            </div>
            <Nav className="align-items-center d-flex d-md-none">
              <Notifications />
              <UserMenu />
            </Nav>

            <Collapse navbar isOpen={opened}>
              <div className="navbar-collapse-header d-md-none">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <Link to="/">
                      <img alt="Brand Icon" src={Brand} />
                    </Link>
                  </Col>
                  <Col className="collapse-close" xs="6">
                    <button
                      className="navbar-toggler"
                      type="button"
                      onClick={() => this.setState({ opened: !opened })}
                    >
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>

              <div className="d-md-none">
                <DefaultSearchBar />
              </div>

              <Nav navbar className={classes.nav}>
                {this.createLinks()}
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  opened: state.Navbar.opened,
});

const mapDispatchToProps = (dispatch) => ({
  setNavbarOpened: (opened) => dispatch(navbarSetNavbarOpened(opened)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultSidebar);

DefaultSidebar.propTypes = {
  opened: PropTypes.bool,
  setNavbarOpened: PropTypes.func.isRequired,
};

DefaultSidebar.defaultProps = {
  opened: false,
};
