import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  Button,
  Col,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  Row,
  UncontrolledCollapse,
  Collapse,
} from "reactstrap";
import { Link } from "react-router-dom";
import classNames from "classnames";
import Banner from "../../assets/Pics/Icons/banner.png";
import Brand from "../../assets/Pics/Icons/brand.png";
import BrandCorporate from "../../assets/Pics/Icons/brand_orange.png";
import classes from "../../assets/Scss/components/LandingNavbar.scss";
import Aux from "../../hoc/Aux";
import SignupModal from "../../views/Login/SignupModal";
import { BECOME_BROKER, HOW_IT_WORKS } from "../../router/Routes";

class LandingNavbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pinned: false,
      loginModal: false,
      isOpen: false,
    };
  }

  componentDidMount() {
    document.addEventListener("scroll", this.trackScrolling);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.trackScrolling);
  }

  trackScrolling = () => {
    this.setState({ pinned: window.pageYOffset > 150, isOpen: false });
  };

  render() {
    const { loginModal, pinned, isOpen } = this.state;

    const { isCorporate } = this.props;

    return (
      <Aux>
        <SignupModal
          isOpen={loginModal}
          toggle={() => this.setState({ loginModal: !loginModal })}
        />
        {isOpen && (
          <div
            onClick={() => {
              this.setState({ isOpen: false });
            }}
            id="navbar-collapse"
            style={{
              opacity: 1,
              zIndex: 10,
              position: "absolute",
              top: 0,
              left: 0,
              height: "100%",
              width: "100%",
            }}
          ></div>
        )}
        <Navbar
          className={`w-100 bg-transparent position-fixed shadow ${
            pinned
              ? `${classes.show} bg-white navbar-light`
              : `${classes.show} navbar-dark`
          }`}
          style={{ zIndex: 10 }}
          expand="lg"
        >
          <Container fluid>
            <NavbarBrand
              to="/"
              onClick={() => {
                if (window.location.pathname === "/")
                  window.location.href = "/";
              }}
              tag={Link}
            >
              {pinned ? (
                <img
                  alt="Tribu"
                  src={!isCorporate ? Brand : BrandCorporate}
                  className="mt--2"
                  style={{ maxWidth: "50px", height: "auto" }}
                />
              ) : (
                <img
                  alt="Tribu"
                  src={Banner}
                  className="mt--2"
                  style={{ maxWidth: "130px", height: "auto" }}
                />
              )}
            </NavbarBrand>

            <button
              onClick={() => {
                this.setState({ isOpen: !isOpen });
              }}
              aria-controls="navbar-collapse"
              aria-expanded={false}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              data-target="#navbar-collapse"
              data-toggle="collapse"
              id="navbar-collapse"
              type="button"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <Collapse
              isOpen={isOpen}
              className="navbar-custom-collapse"
              navbar
              toggler="#navbar-collapse"
            >
              <div className="navbar-collapse-header">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <Link to="/admin/dashboard">
                      <img alt="..." src={Brand} />
                    </Link>
                  </Col>
                  <Col className="collapse-close" xs="6">
                    <button
                      aria-controls="navbar-collapse"
                      aria-expanded={false}
                      aria-label="Toggle navigation"
                      className="navbar-toggler"
                      data-target="#navbar-collapse"
                      data-toggle="collapse"
                      id="navbar-collapse"
                      type="button"
                    >
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              <Nav className="align-items-lg-center" navbar>
                <NavItem>
                  <NavLink tag={Link} to={HOW_IT_WORKS}>
                    Comment ça marche ?
                  </NavLink>
                </NavItem>
                {/*
                                    <NavItem>
                                        <NavLink tag={Link} to={BECOME_BROKER}>
                                            Devenir courtier
                                        </NavLink>
                                    </NavItem>
                                    */}
              </Nav>
              <Nav className="align-items-lg-center ml-lg-auto" navbar>
                <NavItem>
                  <NavLink tag={Link} to="/je-suis-courtier">
                    Je suis courtier
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} to="/je-suis-fournisseur">
                    Je suis fournisseur
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} to="/signin">
                    Me connecter
                  </NavLink>
                </NavItem>
                <NavItem>
                  <Button
                    className={classNames({
                      "btn-neutral": !pinned,
                      "btn-primary": pinned,
                    })}
                    onClick={() => this.setState({ loginModal: !loginModal })}
                  >
                    M&apos;INSCRIRE
                  </Button>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </Aux>
    );
  }
}

export default LandingNavbar;

LandingNavbar.propTypes = {
  isCorporate: PropTypes.bool,
};

LandingNavbar.defaultProps = {
  isCorporate: false,
};
