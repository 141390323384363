import React, { Component } from 'react';
import {
    CardHeader, Input, Row,
} from 'reactstrap';
import Aux from '../../hoc/Aux';
import PlacesAutocomplete from 'react-places-autocomplete';
import './custom.css';
import { withRouter } from 'react-router';

class SearchFiltersArea extends Component {
    constructor(props) {
        super(props);

        const { location } = props;
        const params = new URLSearchParams(location.search);

        this.state = {
            city: params.get('l') ?? "",
        };
    }

    componentWillMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            const params = new URLSearchParams(location.search);
            if (params.get('l') !== null) {
                this.setState({ city: params.get('l') });
            }
            else {
                this.setState({ city: "" });
            }
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    handleChange = city => {
        this.setState({ city });
    };

    render() {

        return (
            <PlacesAutocomplete
                value={this.state.city.replace(/, France$/, "")}
                onChange={this.handleChange}
                searchOptions={{
                    types: ['(cities)'],
                    region: 'FR',
                    componentRestrictions: { country: "fr" }
                }}
                onSelect={(city) => {
                    this.setState({ city: city.replace(/, France$/, "") });
                    this.props.handleSelectLocalisation(city.replace(/, France$/, ""))
                }}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <Aux>
                        <CardHeader>
                            <Row>
                                <div style={{ width: '100%' }}>
                                    <div>
                                        <Input
                                            {...getInputProps({
                                                placeholder: 'Lieu (ex: Bordeaux, Lyon, Nice ...)',
                                                className: 'w-100',
                                            })}
                                            // style={{
                                            //     borderTopRightRadius: 0,
                                            //     borderBottomRightRadius: 0,
                                            // }}
                                        />
                                        <div className="autocomplete-dropdown-container">
                                            {/*loading && <div>Loading...</div>*/}
                                            {suggestions.map((suggestion, index) => {
                                                    return (
                                                         
                                                        <div
                                                         className="autocomplete-item suggestion"
                                                            {...getSuggestionItemProps(suggestion)}
                                                        >
                                                            {suggestion.description.replace(/, France$/, "")}
                                                        </div>
                                               
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </Row>
                        </CardHeader>
                    </Aux>
                )}
            </PlacesAutocomplete>
        );
    }
}

export default withRouter(SearchFiltersArea);