// React Cores
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// components
import {
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Media,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import * as actionTypes from '../../store/actionConsts';
import DefaultProfile from '../../assets/Pics/defaultProfile.jpg';

class DefaultUserMenu extends Component {
    render() {
        const {
            user,
            history,
            logout,
        } = this.props;

        let companyLink = (
            <DropdownItem to="/company/add" tag={Link}>
                <i className="fas fa-briefcase" />
                <span>Page entreprise</span>
            </DropdownItem>
        );

        if (user.companies && user.companies.length) {
            companyLink = (
                <DropdownItem to={`/company/${user.companies[0].username}/about`} tag={Link}>
                    <i className="fas fa-briefcase" />
                    <span>Page entreprise</span>
                </DropdownItem>
            );
        }

        return (
            <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                    <Media className="align-items-center">
                        <img
                            className="avatar rounded-circle"
                            alt=""
                            src={user.profilePic !== '' ? user.profilePic : DefaultProfile}
                        />
                    </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem
                        onClick={() => {
                            logout();
                            history.push('/');
                        }}
                    >
                        <i className="ni ni-user-run" />
                        <span>Déconnexion</span>
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
        );
    }
}

DefaultUserMenu.propTypes = {
    logout: PropTypes.func.isRequired,
    user: PropTypes.object,
};

DefaultUserMenu.defaultProps = {
    user: {},
};

const mapStateToProps = (state) => ({
    user: state.User.user,
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch({ type: actionTypes.LOGOUT }),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DefaultUserMenu));
