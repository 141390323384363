import React, { Component } from 'react';
import {
    Badge,
    Button,
    Card,
    CardBody, CardHeader,
    Col,
    Container,
    Row,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Aux from '../../hoc/Aux';
import CorporateImage from '../../assets/Pics/landing/ill-2.svg';
import SignupScreen from '../../assets/Pics/landing/signup.png';
import BrandWhiteBg from '../../assets/Pics/Icons/brand_white_bg.png';
import LandingHeader from '../../layouts/Landing/LandingHeader';
import GetInTouchForm from '../GetInTouch/GetInTouchForm';
import Landing1 from '../../assets/Pics/landing/landing1.png';
import Landing2 from '../../assets/Pics/landing/landing2.png';
import SignupModal from '../Login/SignupModal';
import './custom.css'

class Landing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            signupModal: false,
        };
    }

    render() {
        const {
            signupModal,
        } = this.state;

        return (
            <Aux>
                <Helmet>
                    <meta
                        name="description"
                        content="Tribu est la solution globale de courtage. Réseau social, logiciel crédit, logiciel assurance, formations… Recherchez et travaillez avec les meilleurs courtiers."
                    />
                </Helmet>
                <SignupModal isOpen={signupModal} toggle={() => this.setState({ signupModal: !signupModal })} />
                <LandingHeader/>
                <section className="pt-0 pb-9 bg-default">
                    <Container fluid>
                        <Row className="justify-content-center text-center">
                            <Col md="6">
                                <p className="lead text-white font-weight-500">
                                    Courtier en crédit immobilier, Courtier en rachat de crédit, Courtier en financement
                                    professionnel, Courtier en assurance, Agent général, Gestionnaire de patrimoine,
                                    CGPI, Conseillers en investissements financiers...
                                </p>
                                <p className="lead text-white font-weight-500">
                                    Tous les courtiers et les métiers du courtage au même endroit.
                                </p>
                                <p className="lead text-white font-weight-500">
                                    Rejoindre Tribu pour vous connecter avec des courtiers mais aussi avec des grands
                                    comptes. Comment ? En misant sur votre savoir-faire et votre conformité.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="section section-lg pt-lg-0 mt--7 ">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg="12">
                                <Row>
                                    <Col lg="4">
                                        <Card className="card-lift--hover shadow border-0 mb-4">
                                            <CardHeader className="pb-0 pt-2 border-0">
                                                <Row className="justify-content-end">
                                                    <Col className="col-auto">

                                                    </Col>
                                                </Row>
                                            </CardHeader>
                                            <CardBody className="pt-0">
                                                <div
                                                    className="icon icon-shape bg-gradient-info text-white rounded-circle mb-4"
                                                >
                                                    <i className="ni ni-app" />
                                                </div>
                                                <h4 className="h3 text-primary text-uppercase">
                                                    SERVICES
                                                </h4>
                                                <p className="description mt-3">
                                                    Crédit ou Assurance ? Nos courtiers par offre de services.
                                                </p>
                                                <div>
                                                    <Badge color="primary" pill>
                                                        PARTICULIER
                                                    </Badge>
                                                    <Badge color="primary" pill>
                                                        ENTREPRISE
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <Link className="btn btn-primary mt-4" to="/search/services">
                                                        TROUVER UN COURTIER
                                                    </Link>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg="4">
                                        <Card className="card-lift--hover shadow border-0 mb-4">
                                            <CardHeader className="pb-0 pt-2 border-0">
                                                <Row className="justify-content-end">
                                                    <Col className="col-auto">

                                                    </Col>
                                                </Row>
                                            </CardHeader>
                                            <CardBody className="pt-0">
                                                <div
                                                    className="icon icon-shape bg-gradient-info text-white rounded-circle mb-4"
                                                >
                                                    <i className="fas fa-filter" />
                                                </div>
                                                <h4 className="h3 text-primary text-uppercase">
                                                    CATÉGORIES
                                                </h4>
                                                <p className="description mt-3">
                                                    Tous nos courtiers régulièrement inscrits au registre Orias.
                                                </p>
                                                <div>
                                                    <Badge color="primary" pill>
                                                        IOBSP
                                                    </Badge>
                                                    <Badge color="primary" pill>
                                                        IAS
                                                    </Badge>
                                                    <Badge color="primary" pill>
                                                        CIF
                                                    </Badge>
                                                    <Badge color="primary" pill>
                                                        ALPSI
                                                    </Badge>
                                                    <Badge color="primary" pill>
                                                        CIP
                                                    </Badge>
                                                    <Badge color="primary" pill>
                                                        IFP
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <Link className="btn btn-primary mt-4" to="/search/categories">
                                                        VÉRIFIER UN COURTIER
                                                    </Link>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col lg="4">
                                        <Card className="card-lift--hover shadow border-0 mb-4">
                                            <CardHeader className="pb-0 pt-2 border-0">
                                                <Row className="justify-content-end">
                                                    <Col className="col-auto">

                                                    </Col>
                                                </Row>
                                            </CardHeader>
                                            <CardBody className="pt-0">
                                                <div
                                                    className="icon icon-shape bg-gradient-info text-white rounded-circle mb-4"
                                                >
                                                    <i className="fas fa-city" />
                                                </div>
                                                <h4 className="h3 text-primary text-uppercase">
                                                    VILLES
                                                </h4>
                                                <p className="description mt-3">
                                                    Le plus grand nombre de courtiers pour une proximité garantie !
                                                </p>
                                                <div>
                                                    <Badge color="primary" pill>
                                                        MADE
                                                    </Badge>
                                                    <Badge color="primary" pill>
                                                        IN
                                                    </Badge>
                                                    <Badge color="primary" pill>
                                                        FRANCE
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <Link className="btn btn-primary mt-4" to="/search/villes">
                                                        LOCALISER UN COURTIER
                                                    </Link>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="pb-3 py-md-6">
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col className="order-md-2 text-center" md="6">
                                <img
                                    alt="..."
                                    className="img-fluid floating"
                                    src={Landing1}
                                    width={375}
                                />
                            </Col>
                            <Col className="order-md-1" md="6">
                                <div className="pr-md-5">
                                    <h1>
                                        De la mise en relation,
                                        <br />
                                        jusqu&apos;au partenariat win-win
                                    </h1>
                                    <ul className="list-unstyled mt-2">
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge
                                                        className="badge-circle mr-3"
                                                        color="primary"
                                                    >
                                                        <i className="far fa-flag" />
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h4 className="mb-0">
                                                        Je trouve des courtiers partout en France
                                                    </h4>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge
                                                        className="badge-circle mr-3"
                                                        color="primary"
                                                    >
                                                        <i className="ni ni-atom" />
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h4 className="mb-0">Je développe mon réseau professionnel</h4>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge
                                                        className="badge-circle mr-3"
                                                        color="primary"
                                                    >
                                                        <i className="far fa-newspaper" />
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h4 className="mb-0">
                                                        Je partage les actualités de mon entreprise
                                                    </h4>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge
                                                        className="badge-circle mr-3"
                                                        color="primary"
                                                    >
                                                        <i className="ni ni-app" />
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h4 className="mb-0">
                                                        Je propose mes services à la communauté
                                                    </h4>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="py-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    <Badge
                                                        className="badge-circle mr-3"
                                                        color="primary"
                                                    >
                                                        <i className="fas fa-columns" />
                                                    </Badge>
                                                </div>
                                                <div>
                                                    <h4 className="mb-0">
                                                        Je gère mon activité depuis une seule interface
                                                    </h4>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="pb-6">
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col md="6 text-center d-flex justify-content-center">
                                <img
                                    alt="..."
                                    className="img-fluid floating"
                                    src={Landing2}
                                    width={290}
                                    style={{maxHeight: 320, }}
                                />
                            </Col>
                            <Col md="6">
                                <div className="pr-md-5">
                                    <h1>Solution de gestion globale</h1>
                                    <p>
                                        Annuaire, messagerie instantanée, page profil, page entreprise, actualités…
                                        <br />
                                        Simplifiez-vous la vie avec Tribu depuis un seul et même outil, facile à utiliser.
                                    </p>
                                    <span
                                        className="font-weight-bold text-primary mt-5"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => this.setState({ signupModal: !signupModal })}
                                    >
                                        INSCRIPTION GRATUITE
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="py-5 section-nucleo-icons bg-white overflow-hidden">
                    <Container>
                        <div className="blur--hover">
                            <span
                                to="/signup"
                                style={{ cursor: 'pointer' }}
                                onClick={() => this.setState({ signupModal: !signupModal })}
                            >
                                <div className="icons-container blur-item">
                                    <i >
                                        <img
                                            alt="..."
                                            className="img-center my-auto"
                                            src={BrandWhiteBg}
                                            style={{ height: '70px', width: '70px' }}
                                        />
                                    </i>

                                    <i className="icon icon-sm">
                                        <i className="fas fa-search text-primary" style={{ fontSize: '1em' }} />
                                    </i>
                                    <i className="icon icon-sm">
                                        <i className="fas fa-calendar-alt text-primary" style={{ fontSize: '1em' }} />
                                    </i>
                                    <i className="icon icon-sm">
                                        <i className="ni ni-circle-08 text-primary" style={{ fontSize: '1em' }} />
                                    </i>

                                    <i className="icon">
                                        <i className="fas fa-columns text-primary" style={{ fontSize: '1.3em' }} />
                                    </i>
                                    <i className="icon">
                                        <i className="ni ni-money-coins text-primary" style={{ fontSize: '1.3em' }} />
                                    </i>
                                    <i className="icon">
                                        <i className="ni ni-app text-primary" style={{ fontSize: '1.3em' }} />
                                    </i>

                                    <i className="icon icon-sm">
                                        <i className="fas fa-thumbs-up text-primary" style={{ fontSize: '1em' }} />
                                    </i>
                                    <i className="icon icon-sm">
                                        <i className="ni ni-bell-55 text-primary" style={{ fontSize: '1em' }} />
                                    </i>
                                    <i className="icon icon-sm">
                                        <i className="ni ni-chat-round text-primary" style={{ fontSize: '1em' }} />
                                    </i>

                                    <i className="icon">
                                        <i className="ni ni-chart-bar-32 text-primary" style={{ fontSize: '1.3em' }} />
                                    </i>
                                    <i className="icon">
                                        <i className="ni ni-planet text-primary" style={{ fontSize: '1.3em' }} />
                                    </i>
                                    <i className="icon">
                                        <i className="fas fa-users text-primary" style={{ fontSize: '1.3em' }} />
                                    </i>
                                </div>
                                <span className="blur-hidden h1 text-primary">
                                    Créer mon profil
                                </span>
                            </span>
                        </div>
                    </Container>
                </section>
                <section className="section pb-5 bg-gradient-warning header">
                    <Container>
                        <Row className="row-grid align-items-center">
                            <Col className="order-lg-2 ml-lg-auto" md="6">
                                <div className="position-relative pl-md-5">
                                    <img
                                        alt="..."
                                        className="img-center img-fluid"
                                        src={CorporateImage}
                                    />
                                </div>
                            </Col>
                            <Col className="order-lg-1" lg="6">
                                <div className="d-flex px-3 flex-col-mobile">
                                    <div>
                                        <div
                                            className="icon icon-lg icon-shape bg-white shadow rounded-circle"
                                        >
                                            <i className="ni ni-building text-warning" />
                                        </div>
                                    </div>
                                    <div className="pl-4-mobile">
                                        <h5 className="h1 display-2 my-0 text-white">
                                            Tribu Corporate
                                        </h5>
                                        <hr className="bg-white w-50 text-left my-2" />
                                        <p className="text-white display-4 font-weight-300">
                                            <span className="font-weight-400">Vous êtes un grand compte ?</span>
                                            <span className="mt-1">Nous avons pensé à vous !</span>
                                            <span className="h4 font-weight-normal text-white mt-2">
                                                Banques, compagnies d’assurance, courtiers grossistes, mutuelles, institutions de prévoyance,
                                                packageurs et sociétés de gestion utilisent Tribu.
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <Card className="shadow shadow-lg--hover mt-5">
                                    <CardBody>
                                        <div className="d-flex">
                                            <div>
                                                <div
                                                    className="icon icon-shape bg-gradient-warning rounded-circle text-white"
                                                >
                                                    <i className="fas fa-filter" />
                                                </div>
                                            </div>
                                            <div className="pl-4">
                                                <h5 className="h3 text-warning">
                                                    Un processus de sélection unique
                                                </h5>
                                                <p>
                                                    Avec Tribu, vous savez avec qui vous travaillez. Tous les courtiers
                                                    sont en règle avec l’Orias.
                                                    <br />
                                                    En un coup d&apos;œil, pour chaque courtier, vous pouvez valider la
                                                    conformité, l’expérience, la formation, l’entreprise, les services
                                                    proposés... Tout est analysé et documenté.

                                                </p>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                                <Card className="shadow shadow-lg--hover mt-5">
                                    <CardBody>
                                        <div className="d-flex">
                                            <div>
                                                <div
                                                    className="icon icon-shape bg-gradient-warning rounded-circle text-white"
                                                >
                                                    <i className="fas fa-rocket" />
                                                </div>
                                            </div>
                                            <div className="pl-4">
                                                <h5 className="h3 text-warning">
                                                    Déployer Tribu dans votre organisation
                                                </h5>
                                                <p>
                                                    Trouvez et collaborez avec de nouveaux courtiers dans le respect de
                                                    vos process.
                                                    Notre équipe vous accompagne dans votre transition. C&apos;est
                                                    simple et rapide.
                                                </p>

                                                <div>                   
                                                <Link to="/signup/corporate" className="btn btn-warning mr-2">
                                                    Créer un compte
                                                </Link>
                                                </div>
                                            <Link to="/corporate" style={{display: 'inline-block'}} >
                                                <p
                                                    className=" text-warning mt-3 mb-0"
                                                    style={{fontWeight:"bold"}}
                                                    to="/corporate"
                                                >
                                                    Demander une démo
                                                </p>
                                                </Link>
                                                </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                    <div className="separator separator-bottom separator-skew zindex-100">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="fill-default"
                                points="2560 0 2560 100 0 100"
                            />
                        </svg>
                    </div>
                </section>
                <section className="py-5 pb-6 bg-default">

                    <Container fluid>
                        <Row className="justify-content-center text-center">
                            <Col md='6'>

                                <h5 className="h1 display-2 my-0 text-white">

                                    Votre talent n'attend pas
                                </h5>

                                {/* <Row className="justify-content-center m-0 p-0 mt--5">
                                  
                                </Row> */}

                            </Col>

                        </Row>
                        <Row className="justify-content-center text-center">
                            <Col md={6}>
                                <p className="lead text-white font-weight-500"

                                >
                                    Les courtiers et acteurs du courtage disposent de cinq évènements annuels pour se rencontrer :

                                    les Journées du Courtage à Paris, la Convention Patrimonia à Lyon, les Rendez-Vous du
                                    Courtage à Marseille et Lyon; et enfin le Salon du Crédit à Vincennes.
                                </p>
                                <p className="lead text-white font-weight-500"

                                >


                                    Ça c’était avant. Aujourd’hui il y a Tribu.
                                </p>



                            </Col>




                        </Row>






                    </Container>
                </section>
                <section className="py-4 py-md-2 bg-white">
                    <Container>
                        <Row className="row-grid justify-content-center">
                            <Col xs={12} md={6} className="my-auto order-2 order-md-1">
                                <h2 className="display-3">
                                    Devenez con-Tribu-teur !
                                </h2>
                                <p>
                                    Rejoignez le réseau social des courtiers pour les courtiers.
                                    <br />
                                    Augmentez votre visibilité et trouvez des solutions pour ne plus perdre de clients.
                                </p>
                                <Button
                                    className="btn-default btn-lg btn-round mt-2 text-center text-md-left"
                                    onClick={() => this.setState({ signupModal: !signupModal })}
                                >
                                    INSCRIPTION GRATUITE
                                </Button>
                            </Col>
                            <Col xs={12} md={6} className="mt--5 order-1 order-md-2">
                                <img className="w-100" src={SignupScreen} alt="Page d'inscription" />
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="section py-7 section-lg bg-gradient-info header">
                    <Container>
                        <Row className="justify-content-center">
                            <Col className="my-auto pb-4 pb-md-auto" xs={12} md={6}>
                                <h5 className="h1 display-2 my-0 text-white">
                                    Vous n’êtes pas courtier ?
                                </h5>
                                <hr className="bg-white w-50 text-left my-2" />
                                <p className="text-white display-4 font-weight-300">
                                    <span className="font-weight-400">Vous ne pouvez accéder à Tribu ?</span>
                                    <span className="mt-1">Pas de panique ! Nous avons la solution.</span>
                                </p>
                            </Col>
                            <Col lg="6">
                                <GetInTouchForm becomeBroker />
                            </Col>
                        </Row>
                    </Container>
                    <div className="separator separator-bottom separator-skew zindex-100">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="fill-white"
                                points="2560 0 2560 100 0 100"
                            />
                        </svg>
                    </div>
                </section>
            </Aux>
        );
    }
}

export default Landing;