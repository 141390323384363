import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Aux from "../../hoc/Aux";
import Brand from "../../assets/Pics/Icons/tribu-white.svg";
import "./custom.css";
import { useLocation } from "react-router-dom";
import PagesInfos from "./Pages/PagesInfos";

function Breadcrumb({ homeInfo }) {
  const location = useLocation();

  const currentPages = () => {
    var pages = [homeInfo];
    var currentUrl = location.pathname.replace(homeInfo.url, "");
    const page = PagesInfos.find((el) => el.url === currentUrl);
    if (page) pages.push(page);
    return pages;
  };

  return (
    <div div style={{display:'flex',  flexWrap:'wrap'}}>
      {currentPages().map((page, index) => (
        <>
          <Link to={index === 0 ? page.url : homeInfo.url + page.url}>{page.name}</Link>
          {index < currentPages().length - 1 && <span className="mx-2">{">"}</span>}
        </>
      ))}
    </div>
  );
}

export default Breadcrumb;
