import LandingLayout from "../layouts/Landing/LandingLayout";
import Views from "../views";
import DefaultLayout from "../layouts/Default/DefaultLayout";
// eslint-disable-next-line no-unused-vars
import { DEFAULT, CORPORATE, DISCONNECTED, ADMIN } from "./Roles";
import SystemLayout from "../layouts/System/SystemLayout";
import CorporateLayout from "../layouts/Corporate/CorporateLayout";

export const HELP_CENTER = "/centre-assistance";
export const PDD = "/protection-des-donnees";
export const CGUV = "/condition-utilisation";
export const BECOME_BROKER = "/devenir-courtier";
export const HOW_IT_WORKS = "/comment-ca-marche";
export const SITEMAP = "/plan-du-site";
export const GET_IN_TOUCH = "/nous-contacter";

const routes = [
  {
    path: "/",
    component: Views.Home,
    roles: [DEFAULT, CORPORATE],
  },
  {
    path: "/",
    layout: LandingLayout,
    component: Views.Landing,
    roles: [DISCONNECTED],
  },
  {
    path: "/dashboard",
    layout: DefaultLayout,
    component: Views.Dashboard,
    roles: [DEFAULT, CORPORATE],
  },
  {
    path: "/dashboard/todo",
    layout: DefaultLayout,
    component: Views.ToDo,
    roles: [DEFAULT, CORPORATE],
  },
  {
    path: "/dashboard/IOB/agencies",
    layout: DefaultLayout,
    component: Views.Agencies,
    roles: [DEFAULT],
  },
  {
    path: "/dashboard/IOB/agencies/view/:id",
    layout: DefaultLayout,
    component: Views.ViewAgencies,
    roles: [DEFAULT],
  },
  {
    path: "/dashboard/IOB/agencies/edit/:id",
    layout: DefaultLayout,
    component: Views.EditAgencies,
    roles: [DEFAULT],
  },
  {
    path: "/dashboard/IOB/agencies/create",
    layout: DefaultLayout,
    component: Views.EditAgencies,
    roles: [DEFAULT],
  },
  {
    path: "/dashboard/IOB/banks",
    layout: DefaultLayout,
    component: Views.Banks,
    roles: [DEFAULT],
  },
  {
    path: "/dashboard/IOB/banks/view/:id",
    layout: DefaultLayout,
    component: Views.ViewBanks,
    roles: [DEFAULT],
  },
  {
    path: "/dashboard/IOB/banks/create",
    layout: DefaultLayout,
    component: Views.EditBanks,
    roles: [DEFAULT],
  },
  {
    path: "/corporate",
    layout: CorporateLayout,
    component: Views.Corporate,
    roles: [DISCONNECTED],
  },
  {
    path: GET_IN_TOUCH,
    layout: LandingLayout,
    component: Views.GetInTouch,
    roles: [DISCONNECTED],
  },
  {
    path: GET_IN_TOUCH,
    component: Views.GetInTouch2,
    roles: [DEFAULT],
  },
  {
    path: BECOME_BROKER,
    layout: LandingLayout,
    component: Views.BecomeBroker,
    roles: false,
  },
  {
    path: "/je-suis-courtier",
    layout: LandingLayout,
    component: Views.ImBroker,
    roles: false,
  },
  {
    path: "/je-suis-fournisseur",
    layout: CorporateLayout,
    component: Views.ImCorporate,
    roles: false,
  },
  {
    path: HOW_IT_WORKS,
    layout: LandingLayout,
    component: Views.HowItWorks,
    roles: false,
  },
  {
    path: "/about",
    layout: LandingLayout,
    component: Views.About,
    roles: [DISCONNECTED],
  },
  {
    path: "/about",
    component: Views.About2,
    roles: [DEFAULT, CORPORATE],
  },
  {
    path: CGUV,
    layout: LandingLayout,
    component: Views.Cguv,
    roles: [DISCONNECTED],
  },
  {
    path: CGUV,
    component: Views.Cguv2,
    roles: [DEFAULT, CORPORATE],
  },
  {
    path: HELP_CENTER,
    layout: LandingLayout,
    component: Views.HelpCenter,
    roles: [DISCONNECTED],
  },
  {
    path: `${HELP_CENTER}/premiers-pas-sur-tribu`,
    layout: LandingLayout,
    component: Views.HelpFirstSteps,
    roles: [DISCONNECTED],
  },
  {
    path: `${HELP_CENTER}/activation-du-compte`,
    layout: LandingLayout,
    component: Views.HelpAccountActivation,
    roles: [DISCONNECTED],
  },
  {
    path: `${HELP_CENTER}/probleme-de-connexion`,
    layout: LandingLayout,
    component: Views.HelpSignInProblem,
    roles: [DISCONNECTED],
  },
  {
    path: `${HELP_CENTER}/parametres-du-compte`,
    layout: LandingLayout,
    component: Views.HelpAccountSettings,
    roles: [DISCONNECTED],
  },
  {
    path: `${HELP_CENTER}/tribu-premium`,
    layout: LandingLayout,
    component: Views.HelpTribuPremium,
    roles: [DISCONNECTED],
  },
  {
    path: `${HELP_CENTER}/modifier-son-profil`,
    layout: LandingLayout,
    component: Views.HelpEditProfile,
    roles: [DISCONNECTED],
  },
  {
    path: `${HELP_CENTER}/modifier-sa-page-entreprise`,
    layout: LandingLayout,
    component: Views.HelpEditCompany,
    roles: [DISCONNECTED],
  },
  {
    path: `${HELP_CENTER}/donnees-personnelles`,
    layout: LandingLayout,
    component: Views.HelpUserData,
    roles: [DISCONNECTED],
  },
  {
    path: `${HELP_CENTER}/tableau-de-bord`,
    layout: LandingLayout,
    component: Views.HelpDashboard,
    roles: [DISCONNECTED],
  },
  {
    path: `${HELP_CENTER}/ajouter-un-membre`,
    layout: LandingLayout,
    component: Views.HelpAddMember,
    roles: [DISCONNECTED],
  },
  {
    path: `${HELP_CENTER}/supprimer-un-contact`,
    layout: LandingLayout,
    component: Views.HelpRemoveContact,
    roles: [DISCONNECTED],
  },
  {
    path: `${HELP_CENTER}/ecrire-a-un-contact`,
    layout: LandingLayout,
    component: Views.HelpWriteToContact,
    roles: [DISCONNECTED],
  },
  {
    path: `${HELP_CENTER}/abonnes-et-abonnements`,
    layout: LandingLayout,
    component: Views.HelpFollowers,
    roles: [DISCONNECTED],
  },
  {
    path: `${HELP_CENTER}/publications`,
    layout: LandingLayout,
    component: Views.HelpPosts,
    roles: [DISCONNECTED],
  },
  {
    path: PDD,
    layout: LandingLayout,
    component: Views.Pdd,
    roles: [DISCONNECTED],
  },
  {
    path: PDD,
    component: Views.Pdd2,
    roles: [DEFAULT, CORPORATE],
  },
  {
    path: SITEMAP,
    layout: LandingLayout,
    component: Views.Sitemap,
    roles: [DISCONNECTED],
  },
  {
    path: SITEMAP,
    component: Views.Sitemap2,
    roles: [DEFAULT, CORPORATE],
  },
  {
    path: "/clients/customers",
    layout: DefaultLayout,
    component: Views.ClientsCustomers,
    roles: [DEFAULT],
  },
  {
    path: "/clients/customers/add",
    layout: DefaultLayout,
    component: Views.ClientsCustomerAdd,
    roles: [DEFAULT],
  },
  {
    path: "/clients/customers/:customer/documents",
    layout: DefaultLayout,
    component: Views.ClientsCustomer,
    roles: [DEFAULT],
  },
  {
    path: "/clients/customers/:customer",
    layout: DefaultLayout,
    component: Views.ClientsCustomer,
    roles: [DEFAULT],
  },
  {
    path: "/clients/companies",
    layout: DefaultLayout,
    component: Views.ClientsCompanies,
    roles: [DEFAULT],
  },
  {
    path: "/clients/companies/add",
    layout: DefaultLayout,
    component: Views.ClientsCompanyAdd,
    roles: [DEFAULT],
  },
  {
    path: "/clients/companies/:company/documents",
    layout: DefaultLayout,
    component: Views.ClientsCompany,
    roles: [DEFAULT],
  },
  {
    path: "/clients/companies/:company",
    layout: DefaultLayout,
    component: Views.ClientsCompany,
    roles: [DEFAULT],
  },
  {
    path: "/signin",
    component: Views.Signin,
    roles: [DISCONNECTED],
    redirect: "/",
  },
  {
    path: "/signup",
    component: Views.Signup2,
    roles: [DISCONNECTED],
  },
  {
    path: "/signup/corporate",
    component: Views.SignupCorporate2,
    roles: [DISCONNECTED],
  },
  {
    path: "/signup/corporate-with-orias",
    component: Views.SignupCorporateWithOrias2,
    roles: [DISCONNECTED],
  },
  {
    path: "/signup/corporate-without-orias",
    component: Views.SignupCorporateWithoutOrias2,
    roles: [DISCONNECTED],
  },
  {
    path: "/reset-password",
    layout: LandingLayout,
    component: Views.RecoverPass,
    roles: [DISCONNECTED],
  },
  {
    path: "/user/visits",
    component: Views.Visits,
    roles: [DEFAULT, CORPORATE],
  },
  {
    path: "/user/invitations",
    component: Views.Invitations,
    roles: [DEFAULT, CORPORATE],
  },
  {
    path: "/user/invitations/received",
    component: Views.Invitations,
    roles: [DEFAULT, CORPORATE],
  },
  {
    path: "/user/invitations/sent",
    component: Views.Invitations,
    roles: [DEFAULT, CORPORATE],
  },
  {
    path: "/user/formula",
    component: Views.Formula,
    roles: [DEFAULT, CORPORATE],
  },
  {
    path: "/user/society",
    component: Views.Society,
    roles: [DEFAULT, CORPORATE],
  },
  {
    path: "/user/society/documents",
    component: Views.Society,
    roles: [DEFAULT, CORPORATE],
  },
  {
    path: "/user/contacts/",
    component: Views.Contacts,
    roles: [DEFAULT, CORPORATE],
  },
  {
    path: "/user/:userId/contacts/",
    component: Views.ProfileContacts,
    roles: [DEFAULT, CORPORATE],
  },
  {
    path: "/user/settings/",
    component: Views.Settings,
    roles: [DEFAULT, CORPORATE],
  },
  {
    path: "/user/settings-help/",
    component: Views.SettingsHelp,
    roles: [DEFAULT, CORPORATE],
  },
  {
    path: "/user/settings-notifications/",
    component: Views.SettingsNotifications,
    roles: [DEFAULT, CORPORATE],
  },
  {
    path: "/user/settings-password/",
    component: Views.SettingsPassword,
    roles: [DEFAULT, CORPORATE],
  },
  {
    path: "/user/settings-phone/",
    component: Views.SettingsPhone,
    roles: [DEFAULT, CORPORATE],
  },
  {
    path: "/user/:user/:view",
    component: Views.Profile2,
    roles: [DEFAULT, CORPORATE],
  },
  {
    path: "/company/visits/",
    component: Views.CompanyVisits,
    roles: [DEFAULT, CORPORATE],
  },
  {
    path: "/company/add/",
    component: Views.CompanyAdd,
    roles: [DEFAULT, CORPORATE],
  },
  {
    path: "/company/create/",
    component: Views.CompanyCreate,
    roles: [DEFAULT, CORPORATE],
  },
  {
    path: "/company/:id/modify",
    component: Views.CompanyModify,
    roles: [DEFAULT, CORPORATE],
  },
  {
    path: "/company/:username/:view",
    component: Views.Company2,
    roles: [DEFAULT, CORPORATE],
  },
  {
    path: "/chat/compose/",
    component: Views.Chat,
    roles: [DEFAULT, CORPORATE],
  },
  {
    path: "/chat/grouped/:chatId",
    component: Views.Chat,
    roles: [DEFAULT, CORPORATE],
  },
  {
    path: "/chat/:chatId",
    component: Views.Chat,
    roles: [DEFAULT, CORPORATE],
  },
  {
    path: "/chat/",
    component: Views.Chat,
    roles: [DEFAULT, CORPORATE],
  },
  {
    path: "/actions/",
    component: Views.Search,
    roles: [DEFAULT, CORPORATE],
  },
  {
    path: "/feedback/",
    component: Views.SimpleForm,
    roles: false,
  },
  {
    path: "/search/",
    component: Views.Search2,
    roles: [DISCONNECTED],
  },
  {
    path: "/search/services",
    component: Views.SearchWithServices,
    roles: [DISCONNECTED],
  },
  {
    path: "/search/services/:service",
    component: Views.SearchWithServices,
    roles: [DISCONNECTED],
  },
  {
    path: "/search/categories",
    component: Views.SearchWithCategories,
    roles: [DISCONNECTED],
  },
  {
    path: "/search/categories/:category",
    component: Views.SearchWithCategories,
    roles: [DISCONNECTED],
  },
  {
    path: "/search/villes/:city",
    component: Views.SearchWithCities,
    roles: [DISCONNECTED],
  },
  {
    path: "/search/villes",
    component: Views.SearchWithCities,
    roles: [DISCONNECTED],
  },
  {
    path: "/post/:postId",
    component: Views.Post,
    roles: [DEFAULT, CORPORATE, DISCONNECTED],
  },
  {
    path: "/404",
    component: Views.Error404,
    roles: false,
  },
  {
    path: "/admin",
    component: Views.Admin,
    roles: [ADMIN],
  },
  {
    path: "/admin/users",
    component: Views.AdminUsers,
    roles: [ADMIN],
  },
  {
    path: "/admin/companies",
    component: Views.AdminCompanies,
    roles: [ADMIN],
  },
  {
    path: "/admin/societies",
    component: Views.AdminSocieties,
    roles: [ADMIN],
  },
  {
    path: "/admin/posts",
    component: Views.AdminPosts,
    roles: [ADMIN],
  },
  {
    path: "/admin/corporates",
    component: Views.AdminCorporates,
    roles: [ADMIN],
  },
  {
    path: "/admin/brokers",
    component: Views.AdminBrokers,
    roles: [ADMIN],
  },
  {
    path: "/user/premium",
    component: Views.SettingsPremium,
    roles: [DEFAULT, CORPORATE],
  },
];

export default routes;
