import { Link } from "react-router-dom";
import Aux from "../../../hoc/Aux";
import PageComponent from "../PageComponent";
import "../custom.css";

function Dashboard() {
  return (
    <Aux>
      <PageComponent>
        <h1 className="help-item-title">Tableau de bord</h1>
        <p className="help-item-text">
          Tribu est une solution complète de management de courtiers.
          <br />
          <br />
          Simple et ergonomique, votre dashboard a été conçu pour centraliser
          l’ensemble de vos activités.
          <br />
          <br />
          La liste de vos contacts, votre messagerie ou encore les notifications
          sont directement accessibles.
          <br />
          <br />
          Grâce au moteur de recherche Tribu, vous allez pourvoir parcourir les
          profils des courtiers selon vos critères de recherche. Il est possible
          de rechercher un contact avec son nom, son entreprise, son numéro
          Orias, son Siren, ou encore un mot clé. Vous pourrez par la suite
          affiner les résultats de votre recherche grâce au filtre mis à votre
          disposition sur le menu à gauche.
          <br />
          <br />
          Sont également disponibles les statistiques de votre profil, les
          statistiques de votre entreprise, les suggestions de profils ainsi que
          votre fil d’actualités.
        </p>
      </PageComponent>
    </Aux>
  );
}

export default Dashboard;
