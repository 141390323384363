import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  Alert,
  Button,
  CardBody,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import CardFooter from "reactstrap/es/CardFooter";
import { connect } from "react-redux";
import classes from "../../assets/Scss/components/Post.scss";
import PublishPic from "./PublishPic";
import Aux from "../../hoc/Aux";
import { checkFile, checkImage } from "../../utils/Checkfiles";
import {
  dbEditPost as actionsEditPost,
  setEditingPost as actionsSetEditingPost,
} from "../../store/posts/actions";
import { uploadImage } from "../../store/images/actions";
import { db } from "../../config/firebase";
import PublishPic2 from "./PublishPic2";
import { Container } from "reactstrap";
import { ClipLoader } from "react-spinners";

class PostEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      post: {},
      loading: false,
      sizeModal: false,
      sizeModalVideo: false,
    };

    this.imageInputRef = React.createRef();
    this.videoInputRef = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.loaded === false) {
      return {
        loaded: true,
        ...nextProps,
      };
    }

    return prevState;
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      post: {
        ...prevState.post,
        [name]: value,
      },
    }));
  };

  /**
   * Triggered on image upload
   * @param e
   */
  handleImageUpload = (e) => {
    const { post } = this.state;
    const errors = checkImage(e.target.files[0], 3);

    if (errors.length === 0) {
      this.setState({
        post: {
          ...post,
          images: [
            ...post.images,
            {
              file: e.target.files[0],
              img: URL.createObjectURL(e.target.files[0]),
            },
          ],
        },
      });
    } else {
      this.setState({ sizeModal: true });
    }
  };

  /**
   * Triggered on video upload
   * @param e
   */
  handleVideoUpload = (e) => {
    const { post } = this.state;
    const errors = checkFile(e.target.files[0]);
    var newVideos = post.videos ? post.videos : [];
    newVideos.push({
      file: e.target.files[0],
      img: URL.createObjectURL(e.target.files[0]),
    });

    if (errors.length === 0) {
      this.setState({
        post: {
          ...post,
          videos: newVideos,
        },
      });
    } else {
      this.setState({ sizeModalVideo: true });
    }
  };

  /**
   * Triggered on image delete
   * @param id
   */
  handleDeleteImage = (id) => {
    const { post } = this.state;
    const newImages = [...post.images];
    newImages.splice(id, 1);

    this.setState({
      post: {
        ...post,
        images: newImages,
      },
    });
  };

  /**
   * Triggered on video delete
   * @param id
   */
  handleDeleteVideo = (id) => {
    const { post } = this.state;
    const newVideos = [...post.videos];
    newVideos.splice(id, 1);

    this.setState({
      post: {
        ...post,
        videos: newVideos,
      },
    });
  };

  handleSubmit = async () => {
    const { post } = this.state;
    const { setEditingPost } = this.props;

    if (post.videos && post.videos.length > 0) {
      this.setState({ loading: true });
      const videoPromises = [];

      post.videos.forEach((video) => {
        if (video.file)
          videoPromises.push(uploadImage(`posts/${post.id}`, video.file));
      });

      post.videos = post.videos.filter((video) => video.file === undefined);

      post.videos = [
        ...post.videos,
        ...(await Promise.all(videoPromises).then((values) => values)),
      ];

      db.collection("posts")
        .doc(post.id)
        .update(
          post.text
            ? {
                text: post.text,
                videos: post.videos,
                images: [],
              }
            : {
                videos: post.videos,
                images: [],
              }
        )
        .then(() => {
          this.setState({ loading: false });
          setEditingPost("");
          window.location.reload();
        });
    } else if (post.images) {
      this.setState({ loading: true });
      const imagePromises = [];

      post.images.forEach((image) => {
        if (image.file)
          imagePromises.push(uploadImage(`posts/${post.id}`, image.file));
      });

      post.images = post.images.filter((image) => image.file === undefined);

      post.images = [
        ...post.images,
        ...(await Promise.all(imagePromises).then((values) => values)),
      ];

      db.collection("posts")
        .doc(post.id)
        .update(
          post.text
            ? {
                text: post.text,
                images: post.images,
                videos: [],
              }
            : {
                images: post.images,
                videos: [],
              }
        )
        .then(() => {
          this.setState({ loading: false });
          setEditingPost("");
          window.location.reload();
        });
    }
  };

  render() {
    const { post, error, loading, sizeModal, sizeModalVideo } = this.state;

    const { setEditingPost, postMaxLength } = this.props;

    if (loading) {
      return (
        <Container fluid>
          <Row className="justify-content-center mt-4">
            <ClipLoader color="#1171ef" />
          </Row>
        </Container>
      );
    }

    return (
      <Aux>
        <Modal
          size="lg"
          isOpen={sizeModalVideo}
          centered
          toggle={() => this.setState({ sizeModalVideo: !sizeModalVideo })}
        >
          <ModalHeader
            toggle={() => this.setState({ sizeModalVideo: !sizeModalVideo })}
          />
          <ModalBody className="pt-0 align-items-center">
            <Row className="justify-content-center">
              <h5 className="h1 mb-0 font-weight-light">
                Le fichier est trop grand !
              </h5>
            </Row>
            <Row className="justify-content-center mb-3">
              <p>7 Mo maximum</p>
            </Row>
            <Row className="justify-content-center">
              <Button
                className="btn-secondary text-primary"
                onClick={() =>
                  this.setState({ sizeModalVideo: !sizeModalVideo })
                }
              >
                OK
              </Button>
            </Row>
          </ModalBody>
        </Modal>
        <Modal
          size="lg"
          isOpen={sizeModal}
          centered
          toggle={() => this.setState({ sizeModal: !sizeModal })}
        >
          <ModalHeader
            toggle={() => this.setState({ sizeModal: !sizeModal })}
          />
          <ModalBody className="pt-0 align-items-center">
            <Row className="justify-content-center">
              <h5 className="h1 mb-0 font-weight-light">
                Le fichier est trop grand !
              </h5>
            </Row>
            <Row className="justify-content-center mb-3">
              <p>3 Mo maximum</p>
            </Row>
            <Row className="justify-content-center">
              <Button
                className="btn-secondary text-primary"
                onClick={() => this.setState({ sizeModal: !sizeModal })}
              >
                OK
              </Button>
            </Row>
          </ModalBody>
        </Modal>
        <CardBody className="py-0 mt-2 mb-2">
          {error && <Alert color="danger">{error}</Alert>}

          <input
            key={Math.random().toString(36)}
            type="file"
            ref={this.imageInputRef}
            style={{ display: "none" }}
            onChange={this.handleImageUpload}
            accept="image/png, image/gif, image/jpeg, image/jpg"
          />
          <input
            key={Math.random().toString(36)}
            type="file"
            ref={this.videoInputRef}
            style={{ display: "none" }}
            onChange={this.handleVideoUpload}
            accept="video/mp4, video/x-m4v, video/*"
          />
          <Label className="sr-only">Votre message</Label>
          <Input
            maxLength={postMaxLength}
            type="textarea"
            className={classes.input}
            value={post.text}
            name="text"
            onChange={this.handleChange}
            placeholder="Votre message..."
          />

          <Row className="mt-1">
            <Col>
              {postMaxLength - post.text.length < 10 && (
                <small style={{ color: "red" }}>
                  {postMaxLength - post.text.length} restant
                  {postMaxLength - post.text.length > 1 ? "s" : ""}
                </small>
              )}
            </Col>
          </Row>
        </CardBody>

        {post.images && post.images.length > 0 && (
          <CardFooter className="border-0 py-0">
            <PublishPic
              images={post.images}
              addImage={() => this.imageInputRef.current.click()}
              deleteImage={this.handleDeleteImage}
            />
          </CardFooter>
        )}
        {post.videos && post.videos.length > 0 && (
          <CardFooter className="border-0 py-0">
            <PublishPic2
              videos={post.videos}
              addVideo={() => this.videoInputRef.current.click()}
              deleteVideo={this.handleDeleteVideo}
            />
          </CardFooter>
        )}
        <CardFooter className="border-top-0 pt-0">
          <Row
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex" }}>
              {post.images &&
                post.images.length === 0 &&
                (post.videos === undefined || post.videos.length === 0) && (
                  <Col className="pr-0">
                    <Button
                      className="shadow-none btn-sm"
                      onClick={() => this.imageInputRef.current.click()}
                    >
                      <i className="fas fa-camera text-primary" />
                    </Button>
                  </Col>
                )}
              {(post.videos === undefined || post.videos.length === 0) &&
                post.images.length == 0 && (
                  <Col>
                    <Button
                      className="shadow-none btn-sm"
                      onClick={() => this.videoInputRef.current.click()}
                    >
                      <i className="fas fa-video text-primary" />
                    </Button>
                  </Col>
                )}
            </div>

            <Col className="col-auto">
              <Button
                className="btn-sm shadow-none"
                color="primary"
                onClick={this.handleSubmit}
                disabled={
                  ((post.text && post.text.length === 0) || !post.text) &&
                  ((post.images && post.images.length === 0) ||
                    (post.videos && post.videos.length === 0))
                }
              >
                Enregistrer
              </Button>
              <Button
                className="btn-sm shadow-none"
                onClick={() => setEditingPost("")}
              >
                Annuler
              </Button>
            </Col>
          </Row>
        </CardFooter>
      </Aux>
    );
  }
}

export default connect(null, {
  editPost: actionsEditPost,
  setEditingPost: actionsSetEditingPost,
})(PostEdit);

PostEdit.propTypes = {
  editPost: PropTypes.func,
  setEditingPost: PropTypes.func,
  postMaxLength: PropTypes.number,
};

PostEdit.defaultProps = {
  editPost: () => {},
  setEditingPost: () => {},
  postMaxLength: 0,
};
