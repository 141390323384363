import React, { Component } from "react";
import Scrollspy from "react-scrollspy";
import { Button, Col, Row, Container } from "reactstrap";
import { scroller } from "react-scroll";
import { Helmet } from "react-helmet";
import classes from "../../assets/Scss/components/BecomeBroker.scss";
import config from "../../config/config";
import { Link } from 'react-router-dom';
import Img from '../../assets/Pics/tribu-je-suis-fournisseur.svg';
import '../../App.css'

class ImCorporate extends Component {
    myRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            currentSection: "section-1",
        };
    }

    handleNewSection = (data) => {
        if (data) {
            this.setState({ currentSection: data.id });
        }
    };

    scrollToHandler = (e) => {
        e.preventDefault();
        const { currentSection } = this.state;
        const newSection = currentSection.split("-");
        newSection[1] = parseInt(newSection[1], 10) + 1;

        scroller.scrollTo(newSection.join("-"), {
            duration: 500,
            delay: 100,
            smooth: true,
        });

        this.setState({ currentSection: newSection.join("-") });
    };

    render() {
        const { currentSection } = this.state;

        return (
            <div className={classes.BecomeBroker}>
                <Helmet>
                    <title>Travaillez avec vos courtiers sur Tribu en toute confiance !</title>
                    <meta
                        name="description"
                        content="Trouvez et gérez tous les courtiers dont vous avez besoin. Fini la chasse aux informations et le temps perdu ! Tribu est votre solution pour accélérer vos projets."
                    />
                    <meta
                        name="keywords"
                        content="acpr, orias, banque, assureur, compagnie d’assurance, courtier grossiste, grossiste,
                        mutuelle, institution prevoyance, packageur credit, societe de gestion, groupement gestion
                        patrimoine, groupement cgp, reseau de franchise, reseau franchise credit, reseau franchise
                        assurance, franchiseur, franchiseur credit, franchiseur assurance, association agreee courtier,
                        association agreee ACPR, editeur logiciel credit, editeur logiciel assurance, organisme de formation
                        courtage, organisme de formation courtier, patrimonia, salon du courtage, les journees du courtage,
                        les rendez-vous du courtage, rdv du courtage, salon du credit"
                    />
                </Helmet>
                <Scrollspy
                    className="d-none"
                    items={[
                        "section-1",
                        "section-2",
                        "section-3",
                        "section-4",
                        "section-5",
                        "section-6",
                    ]}
                    onUpdate={this.handleNewSection}
                />
                <section className={`header bg-gradient-warning py-9 ${classes.About}`}>
                    <Container>
                        <Row className="justify-content-center">

                            <img
                                style={{ transform: 'translate(0, -60px)' }}
                                className="mb-3 bg-title-image"
                                alt="tribu je suis fournisseur"
                                src={Img}
                            />
                        </Row>

                        <Row className="justify-content-center" style={{ paddingBottom: 30 }}>
                            <Col xs={12}>
                                <h5 className="display-3 text-center text-white"
                                    style={{ textShadow: '0px 0px 20px rgba(0,0,0,0.4)' }}
                                >
                                    Dénichez et travaillez facilement avec les
                                    meilleurs courtiers
                                </h5>
                            </Col>

                        </Row>
                        <div className="separator separator-bottom separator-skew">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                                viewBox="0 0 2560 100"
                                x="0"
                                y="0"
                            >
                                <polygon
                                    className="fill-white"
                                    points="0 0 2560 100 0 100"
                                />
                            </svg>
                        </div>

                    </Container>
                </section>
                <section className="bg-white" style={{
                    zIndex: 2,
                    position: "relative",
                }}>
                    <Container className="py-6">
                        <Row className="justify-content-center">
                            <Col xs={12}>
                                <h5 className="display-3 text-center">
                                    Trouvez le bon profil en deux clics
                                </h5>
                            </Col>
                            <Col className="mt-3" md={6}>
                                <p className="h2 text-center font-weight-normal" >
                                    Grâce à un puissant moteur de recherche et des filtres élaborés, partez à la recherche de vos
                                    courtiers parmi nos 40 000 talents. Discutez directement avec eux en quelques clics. Nous
                                    vous garantissons une collaboration simple et sécurisée.
                                </p>
                            </Col>
                        </Row>

                    </Container>
                </section>
                <section className="header bg-gradient-warning">
                    <Container className="pt-6 pb-8">
                        <Row className="justify-content-center">
                            <Col xs={12}>
                                <h5 className="display-3 text-center text-white">
                                    100% sûr
                                </h5>
                            </Col>

                        </Row>
                        <Row className="justify-content-center mt-3">
                            <Col xs={12}>
                                <p className="h2 text-center text-white font-weight-normal" >
                                    Notre processus d&#39;inscription de courtiers garantit leur conformité,
                                    <br />
                                    grâce à la vérification de
                                    leurs numéros d&#39;immatriculation.
                                </p>
                            </Col>
                        </Row>
                        <Row className="justify-content-center mt-3">
                            <Col xs={12}>
                                <p className="h2 text-center text-white font-weight-normal" >
                                    Chez Tribu, pas de courtier radié de l’Orias ou du RCS.
                                </p>
                            </Col>
                        </Row>
                        <Row className="justify-content-center mt-3">
                            <Col xs={12}>
                                <p className="h2 text-center text-white font-weight-normal" >
                                    Tribu garantie que tous les courtiers respectent la réglementation en vigueur.
                                    <br />
                                    Vous accéderez à toutes les données actualisées des courtiers issues des bases publiques officielles.
                                </p>
                            </Col>
                        </Row>
                        <Row className="justify-content-center mt-3">
                            <Col xs={12}>
                                <p className="h2 text-center text-white font-weight-normal" >
                                    Collaborez avec un réseau national de courtiers tout en gardant l&#39;esprit tranquille.
                                </p>
                            </Col>
                        </Row>

                        <div className="separator separator-bottom separator-skew zindex-100">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                                viewBox="0 0 2560 100"
                                x="0"
                                y="0"
                            >
                                <polygon
                                    className="fill-white"
                                    points="2560 0 2560 100 0 100"
                                />
                            </svg>
                        </div>
                    </Container>
                </section>
                <section className="bg-white">
                    <Container className="py-6">
                        <Row className="justify-content-center">
                            <Col xs={12}>
                                <h5 className="display-3 text-center">
                                    Tribu, un tiers de confiance
                                    <br />
                                    pour travailler sereinement
                                    avec vos courtiers !
                                </h5>
                            </Col>
                            <Col className="mt-3" md={6}>
                                <p className="h2 text-center font-weight-normal" >
                                    Vérification de vos relations d&#39;affaires ? KYC ? Nous avons conscience qu’un certain nombre
                                    d’interrogations peuvent apparaître lorsqu&#39;il s’agit de travailler avec des courtiers. Nous avons
                                    donc simplifié et automatisé les tâches qui ont pu être un frein pour vous jusqu’à aujourd’hui.
                                </p>
                            </Col>

                        </Row>
                        <Row className="justify-content-center">
                            <Col className="mt-3" md={6}>
                                <Row className="justify-content-center mt-4">
                                    <Link to="/signup/corporate">
                                        <Button className="btn-secondary text-warning px-3 mw-100">CRÉER UN COMPTE GRATUITEMENT</Button>
                                    </Link>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>



            </div>
        );
    }
}

export default ImCorporate;