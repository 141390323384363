import FileExtensions from '../data/FileExtensions.json';

export const checkImage = (file, maxSize) => {
    const { name, size } = file;
    const errors = [];

    if (file === '') {
        errors.push('Error !');
    }

    if (size / 1024 / 1024 > (maxSize || 3)) {
        errors.push(`Le fichier est trop grand ! (${maxSize || 3} Mb)`);
    }

    if (!FileExtensions.images.includes(name.split('.')[name.split('.').length - 1])) {
        errors.push(`Le type du fichier n'est pas correct (.${FileExtensions.images.join(', .')} acceptés)`);
    }

    return errors;
};

export const checkFile = (file) => {
    const errors = [];

  

    if (file === '') {
        errors.push('Error !');
    }

    if (file.size / 1024 / 1024 > 7) {
        errors.push('Le fichier est trop grand !');
    }

    return errors;
};
