import PropTypes from "prop-types";
import React from "react";
import { ListGroupItem, Row } from "reactstrap";
import Moment from "react-moment";
import classes from "../../assets/Scss/components/Chat.scss";
import "moment/locale/fr";
import moment from "moment-timezone";

const ChatDate = (props) => {
  const { date } = props;

  return (
    <ListGroupItem
      className={`flex-column align-items-end py-2 px-4 border-0 ${classes.Chat}`}
    >
      <Row className="justify-content-center">
        <small className="text-nowrap">
          <span>{moment(date).format("ll")}</span>
        </small>
      </Row>
    </ListGroupItem>
  );
};

export default ChatDate;

ChatDate.propTypes = {
  date: PropTypes.number,
};

ChatDate.defaultProps = {
  date: 0,
};