import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { CardHeader, Col, Row } from "reactstrap";

import { connect } from "react-redux";
import PostDropdown from "./PostDropdown";
import CompanyFollowing from "../Company/CompanyFollowing";
import DefaultProfile from "../../assets/Pics/defaultProfile.jpg";
import { useState, useEffect } from "react";
import { db, firebaseApp } from "../../config/firebase";

const PostHeader = (props) => {
  const userId = firebaseApp.auth().currentUser.uid;
  const { post, user } = props;
  const [profilePic, setProfilePic] = useState();

  const author = post.isRepost ? post.post.author : post.author;
  const { companies } = user;
  const type = author.isCompany ? "company" : "user";

  useEffect(() => {
    type === "user"
      ? db
          .collection("users")
          .doc(author.userId)
          .get()
          .then((doc) => {
            setProfilePic(doc.data().profilePic);
          })
      : db
          .collection("companies")
          .doc(author.userId)
          .get()
          .then((doc) => {
            setProfilePic(doc.data().profilePic);
          });
  }, []);

  const isRepost = post.isRepost && post.post;

  return (
    <CardHeader className={`border-0 mb--3 px-3 py-3`}>
      {isRepost && (
        <Row className="mb-3">
          <Col>
            <small>
              <b>{post.author.name} a reposté</b>
            </small>
          </Col>
          {post.isRepost && (
            <Col className="col-auto">
              {author.isCompany &&
                companies.findIndex(
                  (company) => company.id === author.userId
                ) === -1 && <CompanyFollowing companyId={author.userId} />}
              <PostDropdown post={post} />
            </Col>
          )}
        </Row>
      )}

      <Row
        onClick={() => {
          if (post.isRepost) window.open(`post/${post.post.id}`);
        }}
        className={`justify-content-between  ${
          post.isRepost ? "cursor-pointer" : ""
        }`}
      >
        <Col>
          <Row className="align-items-center">
            <Col className="col-auto pr-0">
              {props.isCurrentUserPremium ||
              userId === author.userId ||
              companies.findIndex((company) => company.id === author.userId) !==
                -1 ? (
                <Link target="_blank" to={`/${type}/${author.username}/about`}>
                  <img
                    className="avatar rounded-circle"
                    src={profilePic ? profilePic : DefaultProfile}
                    alt=""
                  />
                </Link>
              ) : (
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => props.toggle()}
                >
                  <img
                    className="avatar rounded-circle"
                    src={profilePic ? profilePic : DefaultProfile}
                    alt=""
                  />
                </div>
              )}
            </Col>
            <Col className="col-auto align-items-center">
              {props.isCurrentUserPremium ||
              userId === author.userId ||
              companies.findIndex((company) => company.id === author.userId) !==
                -1 ? (
                <Link
                  target="_blank"
                  to={`/${type}/${author.username}/about`}
                  className="h4 text-primary d-block mb-0"
                >
                  {author.name}
                </Link>
              ) : (
                <div
                  className="h4 text-primary d-block mb-0"
                  style={{ cursor: "pointer" }}
                  onClick={() => props.toggle()}
                >
                  {author.name}
                </div>
              )}
              <small>
                <Moment locale="fr" fromNow ago>
                  {post.isRepost ? post.post.createdAt : post.createdAt}
                </Moment>
              </small>
            </Col>
          </Row>
        </Col>
        {post.isRepost || (
          <Col className="col-auto">
            {author.isCompany &&
              companies.findIndex((company) => company.id === author.userId) ===
                -1 && <CompanyFollowing companyId={author.userId} />}
            <PostDropdown post={post} />
          </Col>
        )}
      </Row>
    </CardHeader>
  );
};

const mapStateToProps = (state) => ({
  user: state.User.user,
});

export default connect(mapStateToProps)(PostHeader);

PostHeader.propTypes = {
  post: PropTypes.object,
  user: PropTypes.object,
};

PostHeader.defaultProps = {
  post: {},
  user: {},
};
