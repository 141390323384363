import PropTypes from "prop-types";
import React, { Component, createRef } from "react";
import {
  Col,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
import Badge from "reactstrap/es/Badge";
import "../../layouts/Default/custom.css";
import { createAutocomplete } from "@algolia/autocomplete-core";
import { getAlgoliaResults } from "@algolia/autocomplete-preset-algolia";
import algoliasearch from "algoliasearch/lite";
import "@algolia/autocomplete-theme-classic/dist/theme.css";
import { db, firebaseApp } from "../../config/firebase";
const _ = require("lodash");

const searchClient = algoliasearch(
  "DS4P4HIB8H",
  "8cced0de8b8fbfec89bfa7203116ae37"
);

class ChatComposeContact2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      autocompleteState: {},
      contacts: [],
      allContactsIsOpen: false,
      allContactsMode: false,
    };

    this.allContactsRef = createRef();
    this.searchBarRef = createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.autocomplete = createAutocomplete({
      onStateChange: this.onChange,
      getSources() {
        return [
          {
            sourceId: "hits",
            getItems({ query }) {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: "usersForChat",
                    query,
                    params: {
                      hitsPerPage: 20,
                      highlightPreTag: "<mark>",
                      highlightPostTag: "</mark>",
                    },
                  },
                ],
              });
            },
            getItemUrl({ item }) {
              return item.url;
            },
          },
        ];
      },
    });
  }

  onChange = ({ state }) => {
    this.setState({ autocompleteState: state, search: state.query });
    // if (this.state.search !== "" && state.query === "")
    //   this.setState({ allContactsIsOpen: false });
    // else if (this.state.search === "" && state.query !== "")
    //   this.setState({ allContactsIsOpen: true });
  };

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  handleEmptySearch = () => {
    this.setState({
      search: "",
    });
  };

  handleAdd = (contact) => {
    if (this.props.selectedContacts.length === 0) {
      this.props.setSelectedContacts([...this.props.selectedContacts, contact]);
    }
  };

  handleAddAllContacts = () => {
    this.props.setSelectedContacts([]);
    this.setState({ allContactsMode: true, allContactsIsOpen: false });
  };

  handleDelete = (contact) => {
    this.props.setSelectedContacts([
      ...this.props.selectedContacts.filter((item) => item !== contact),
    ]);
  };

  handleDeleteAllContacts = () => {
    this.setState({ allContactsMode: false });
  };

  componentDidMount() {
    const userId = firebaseApp.auth().currentUser.uid;
    db.collection("users")
      .doc(userId)
      .collection("contacts")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.setState((prevState) => ({
            contacts: [...prevState.contacts, doc.id],
          }));
        });
      });
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (
      this.allContactsRef &&
      !this.allContactsRef.current?.contains(event.target) &&
      this.searchBarRef &&
      !this.searchBarRef.current?.contains(event.target)
    ) {
      this.setState({
        allContactsIsOpen: false,
      });
    }
  }

  render() {
    const {
      search,
      autocompleteState,
      allContactsIsOpen,
      allContactsMode,
    } = this.state;

    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    return (
      <div className="aa-Autocomplete" {...this.autocomplete?.getRootProps({})}>
        <Form>
          <Row>
            <Col>
              <div ref={this.searchBarRef}>
                <InputGroup
                  onMouseDown={() => {
                    // this.setState({
                    //   allContactsIsOpen: true,
                    // });
                  }}
                >
                  <InputGroupAddon addonType="prepend">À:</InputGroupAddon>
                  <Input
                    {...this.autocomplete?.getInputProps({
                      placeholder: "Rechercher un contact",
                      value: search,
                    })}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <i
                        className="fas fa-times-circle cursor-pointer"
                        onClick={this.handleEmptySearch}
                      />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </div>
            </Col>
          </Row>
          <Row>
            {allContactsMode ? (
              <Col className={` py-1`}>
                <div className="pr-4">
                  <Badge
                    color="warning"
                    className="cursor-pointer mb-1"
                    onClick={() => this.handleDeleteAllContacts()}
                  >
                    {`${capitalizeFirstLetter("Tous les contacts")}`}
                    <i className="fas fa-times-circle ml-2" />
                  </Badge>
                </div>
              </Col>
            ) : (
              <Col
                className={`${
                  this.props.selectedContacts.length === 0 ? "d-none" : ""
                } py-1`}
              >
                <div className="pr-4 ">
                  {this.props.selectedContacts.map((contact) => (
                    <>
                      <Badge
                        color="primary"
                        className="cursor-pointer mb-1"
                        onClick={() => this.handleDelete(contact)}
                      >
                        {`${capitalizeFirstLetter(
                          contact.firstName.toLowerCase()
                        )} ${contact.lastName.toUpperCase()}`}
                        <i className="fas fa-times-circle ml-2" />
                      </Badge>           
                    </>
                  ))}
                </div>
              </Col>
            )}
          </Row>
        </Form>
        <div {...this.autocomplete?.getPanelProps({})}>
          {autocompleteState.isOpen && (
            <>
              {autocompleteState.collections.map((collection, index) => {
                const { source, items } = collection;

                return (
                  <div key={`source-${index}`} className="bg-white">
                    {items.map((item) => {
                      return this.state.contacts.includes(item.objectID) ? (
                        <div>
                          <div
                            className="aa-ItemWrapper suggestion"
                            {...this.autocomplete?.getItemProps({
                              item,
                              source,
                            })}
                            onClick={() => this.handleAdd(item)}
                          >
                            <div className="aa-ItemContent">
                              <div className="aa-ItemIcon aa-ItemIcon--noBorder">
                                <svg
                                  viewBox="0 0 24 24"
                                  width="18"
                                  height="18"
                                  fill="currentColor"
                                >
                                  <path d="M16.041 15.856c-0.034 0.026-0.067 0.055-0.099 0.087s-0.060 0.064-0.087 0.099c-1.258 1.213-2.969 1.958-4.855 1.958-1.933 0-3.682-0.782-4.95-2.050s-2.050-3.017-2.050-4.95 0.782-3.682 2.050-4.95 3.017-2.050 4.95-2.050 3.682 0.782 4.95 2.050 2.050 3.017 2.050 4.95c0 1.886-0.745 3.597-1.959 4.856zM21.707 20.293l-3.675-3.675c1.231-1.54 1.968-3.493 1.968-5.618 0-2.485-1.008-4.736-2.636-6.364s-3.879-2.636-6.364-2.636-4.736 1.008-6.364 2.636-2.636 3.879-2.636 6.364 1.008 4.736 2.636 6.364 3.879 2.636 6.364 2.636c2.125 0 4.078-0.737 5.618-1.968l3.675 3.675c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414z" />
                                </svg>
                              </div>
                              <div className="aa-ItemContentBody">
                                <div className="aa-ItemContentTitle">
                                  {`${this.capitalizeFirstLetter(
                                    item.firstName.toLowerCase()
                                  )} ${item.lastName.toUpperCase()}`}
                                </div>
                              </div>
                            </div>

                            <div className="aa-ItemActions">
                              <button
                                className="aa-ItemActionButton"
                                title={`Fill query with "${item.query}"`}
                                onClick={(event) => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                }}
                              >
                                <svg
                                  viewBox="0 0 24 24"
                                  width="18"
                                  height="18"
                                  fill="currentColor"
                                >
                                  <path d="M8 17v-7.586l8.293 8.293c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414l-8.293-8.293h7.586c0.552 0 1-0.448 1-1s-0.448-1-1-1h-10c-0.552 0-1 0.448-1 1v10c0 0.552 0.448 1 1 1s1-0.448 1-1z" />
                                </svg>
                              </button>
                            </div>
                          </div>
                          {/* <div className="aa-ItemWrapper">
                            <div className="aa-ItemContent aa-ItemContent--indented">
                              <div className="aa-ItemContentSubtitle aa-ItemContentSubtitle--standalone">
                                <span className="aa-ItemContentSubtitleIcon" />
                                <span>
                                  dans{" "}
                                  <span className="aa-ItemContentSubtitleCategory">
                                    {item.roles.includes("CORPORATE")
                                      ? "Fournisseur"
                                      : "Personne"}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      ) : (
                        <div></div>
                      );
                    })}
                  </div>
                );
              })}
            </>
          )}
          {allContactsIsOpen && (
            <div
              ref={this.allContactsRef}
              className="aa-ItemWrapper suggestion"
              onClick={() => this.handleAddAllContacts()}
            >
              <div className="aa-ItemContent">
                <div className="aa-ItemIcon aa-ItemIcon--noBorder">
                  <svg
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                    fill="currentColor"
                  >
                    <path d="M16.041 15.856c-0.034 0.026-0.067 0.055-0.099 0.087s-0.060 0.064-0.087 0.099c-1.258 1.213-2.969 1.958-4.855 1.958-1.933 0-3.682-0.782-4.95-2.050s-2.050-3.017-2.050-4.95 0.782-3.682 2.050-4.95 3.017-2.050 4.95-2.050 3.682 0.782 4.95 2.050 2.050 3.017 2.050 4.95c0 1.886-0.745 3.597-1.959 4.856zM21.707 20.293l-3.675-3.675c1.231-1.54 1.968-3.493 1.968-5.618 0-2.485-1.008-4.736-2.636-6.364s-3.879-2.636-6.364-2.636-4.736 1.008-6.364 2.636-2.636 3.879-2.636 6.364 1.008 4.736 2.636 6.364 3.879 2.636 6.364 2.636c2.125 0 4.078-0.737 5.618-1.968l3.675 3.675c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414z" />
                  </svg>
                </div>
                <div className="aa-ItemContentBody">
                  <div className="aa-ItemContentTitle">
                    {`${this.capitalizeFirstLetter("tous les contacts")}`}
                  </div>
                </div>
              </div>

              <div className="aa-ItemActions">
                <button
                  className="aa-ItemActionButton"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                >
                  <svg
                    viewBox="0 0 24 24"
                    width="18"
                    height="18"
                    fill="currentColor"
                  >
                    <path d="M8 17v-7.586l8.293 8.293c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414l-8.293-8.293h7.586c0.552 0 1-0.448 1-1s-0.448-1-1-1h-10c-0.552 0-1 0.448-1 1v10c0 0.552 0.448 1 1 1s1-0.448 1-1z" />
                  </svg>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ChatComposeContact2;

ChatComposeContact2.propTypes = {
  selectedContacts: PropTypes.array,
  setSelectedContacts: PropTypes.func,
};

ChatComposeContact2.defaultProps = {
  selectedContacts: [],
  setSelectedContacts: () => {},
};