import React, { Component } from 'react';
import {
    Card, Col, Container, Row,
} from 'reactstrap';
import SearchFilters from './SearchFilters';
import SearchProfiles from './SearchProfiles';
import Aux from '../../hoc/Aux';
import DefaultNavbar2 from '../../layouts/Default/DefaultNavbar2';
import DefaultFooter from '../../layouts/Default/DefaultFooter';
import { ClipLoader } from 'react-spinners';

class Search extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showLoader: true,
            width: 768,
            widthForMobile: 576,
            filterMobileMode: false
        };

        this.setFilterMobileMode = this.setFilterMobileMode.bind(this)
        this.handler = this.handler.bind(this)
    }

    handleResize = () => {
        this.setState({ width: window.innerWidth });
        if(!this.checkIfMobile()) this.setFilterMobileMode(false)
    };

    componentDidMount() {
        this.setState({ width: window.innerWidth });

        window.addEventListener("resize", this.handleResize);
        setTimeout(() => {
            this.setState({
                showLoader: false
            });
        }, 1000);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handler() {
        this.setState({
            loginModal: true
        })
    }

    checkIfMobile() {
        return this.state.width <= this.state.widthForMobile
    }

    setFilterMobileMode(value) {
        this.setState({ filterMobileMode: value })
    }

    render() {
        return (
            <div style={{ paddingBottom: '100px' }}>
                <DefaultNavbar2 />
                <Aux>
                    {
                        this.state.showLoader ? <Container fluid style={{ paddingTop: 100 }}>
                            <Row className="justify-content-center mt-4">
                                <ClipLoader color="#1171ef" />
                            </Row>
                        </Container> : <Container fluid style={{ paddingTop: 100 }}>
                            <Row>
                                <Col xl={4}>
                                    <Card className="shadow">
                                        <SearchFilters />
                                    </Card>
                                </Col>
                                <Col xl={8}>
                                    <SearchProfiles />
                                </Col>
                            </Row>
                        </Container>
                    }
                </Aux>
                <div
                    style={{
                        position: "fixed",
                        left: 0,
                        bottom: 0,
                        right: 0,
                        backgroundColor: "white"
                    }}
                >
                    <DefaultFooter />
                </div>
            </div>
        );
    }
}

export default Search;
