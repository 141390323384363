import { Link } from "react-router-dom";
import Aux from "../../../hoc/Aux";
import PageComponent from "../PageComponent";
import "../custom.css";

function AccountSettings() {
  return (
    <Aux>
      <PageComponent>
        <h1 className="help-item-title">Paramètres du compte</h1>
        <p className="help-item-text">
          Tous les paramètres de votre compte Tribu sont réunis au même endroit.
          <p className="help-item-subtitle">Modifier mon mot de passe</p>
          Pour modifier le mot de passe associé à votre compte Tribu, vous devez
          vous rendre dans les paramètres de votre compte > onglet "Mot de
          passe".
          <br />
          <br />
          Entrez l’ancien mot de passe puis le nouveau mot de passe et confirmer
          le nouveau, sans oublier de valider en cliquant sur valider.
          <br />
          <br />
          Si vous ne vous souvenez plus de votre mot de passe actuel, consultez{" "}
          <Link to={"/centre-assistance/probleme-de-connexion"}>
            la marche à suivre
          </Link>{" "}
          dans le centre d'assistance.
          <p className="help-item-subtitle">Modifier mon numéro de téléphone</p>
          Pour modifier votre numéro de téléphone, rendez-vous dans les
          paramètres et cliquez sur l'onglet "Téléphone".
          <br />
          <br />
          Entrez le nouveau numéro de téléphone, sans oublier de cliquer sur
          valider.
          <p className="help-item-subtitle">
            Comment gérer mes notifications ?
          </p>
          Pour gérer vos notifications, rendez-vous dans les paramètres et
          cliquez sur l'onglet "Notifications".
          <br />
          <br />
          Vous pourrez activer/désactiver les notifications liées à votre
          activité et votre réseau, la messagerie, les demandes de contact, les
          visites, les abonnements, etc.
          <br />
          <br />
          Gardez à l'esprit que certaines notifications sont obligatoires et ne
          peuvent pas être désactivées, comme les notifications sur les mises à
          jour de nos Conditions Générales, ou celles liées à vos échanges avec
          les équipes de support ou de modération.
          <p className="help-item-subtitle">
            Comment gérer mes notifications ?
          </p>
          Vous n'avez pas trouvé de réponse dans le centre d'assistance ?
          <br />
          <br />
          Vous avez besoin de nous joindre, vous avez une question ou une
          suggestion, vous croyez avoir trouvé un bug ? Vous pouvez soumettre un
          ticket support en vous rendant dans les paramètres > onglet "Aide".
          <br />
          <br />
          Envoyez-nous un message, sans oublier la pièce jointe.
        </p>
      </PageComponent>
    </Aux>
  );
}

export default AccountSettings;
