import PropTypes from "prop-types";
import React, { useEffect, useState, useRef } from "react";
import { Card, CardBody, Col, ListGroup, Row } from "reactstrap";
import CardHeader from "reactstrap/es/CardHeader";
import CardFooter from "reactstrap/es/CardFooter";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ChatThreadMenu from "./ChatThreadMenu";
import ChatInput from "./ChatInput";
import {
  dbAddfile,
  dbAddmessage,
  dbAddpicture,
  dbAddvideo,
} from "../../store/chat/actions";
import ChatReceived from "./ChatReceived";
import { firebaseApp, db } from "../../config/firebase";
import ChatSent from "./ChatSent";
import ChatDate from "./ChatDate";
import * as moment from 'moment';

const ChatThread = (props) => {
  const messagesRef = useRef([]);
  const [chat, setChat] = useState({});
  const [messages, setMessages] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [interlocutorSeenDate, setInterlocutorSeenDate] = useState();
  const [interlocutorUserId, setInterlocutorUserId] = useState();
  const [isWriting, setIsWritingg] = useState(false);
  const [message, setMessage] = useState('');
  var lastDateDisplayed;

  const { match, addMessage, addPicture, addVideo, addFile } = props;

  const { chatId } = match.params;
  const uid = firebaseApp.auth().currentUser.uid;

  const fetchChat = (chatId) => {
    if (chatId !== undefined) {
      db.collection('chats').doc(chatId).get()
        .then(async (doc) => {
          if (!doc.exists) {
            const uid = firebaseApp.auth().currentUser.uid;
            const userId = chatId.replace(uid, '')

            const users = []
            const { user: userFromReducer } = props.user;

            const sender = {
              userId: firebaseApp.auth().currentUser.uid,
              profilePic: userFromReducer.profilePic || null,
              name: `${userFromReducer.firstName} ${userFromReducer.lastName}`,
              username: userFromReducer.username,
            };

            users.push(sender);
            db.collection('users').doc(userId).get()
              .then(async (doc) => {
                users.push({
                  userId,
                  profilePic: doc.data().profilePic || null,
                  name: `${doc.data().firstName} ${doc.data().lastName}`,
                  username: doc.data().username,
                })

                const userIds = {};
                users.forEach((user) => { userIds[user.userId] = true; });

                const chat = {
                  date: moment.now(),
                  nbOfUsers: users.length,
                  userIds,
                  users,
                }

                await db.collection('chats')
                  .doc(chatId)
                  .set(chat)
                  .then(() => {
                    const batch = db.batch();

                    users.forEach((user, key) => {
                      chat.name = users[0].userId === user.userId ? users[1].name : users[0].name;

                      const ref = db.collection('users')
                        .doc(user.userId)
                        .collection('chats')
                        .doc(chatId);

                      batch.set(ref, {
                        profilePic: chat.profilePic || chat.users.length === 2 ? chat.users[key % 2 ? 0 : 1].profilePic : null,
                        name: chat.name,
                        userId: chat.users[user.userId === sender.userId ? 1 : 0].userId,
                        id: chatId,
                      });
                    });

                    batch.commit().then(() => {
                      setMessages([]);
                      db.collection("users")
                        .doc(uid)
                        .collection("chats")
                        .doc(chatId)
                        .get()
                        .then((doc) => ({
                          id: doc.id,
                          ...doc.data(),
                        }))
                        .then((chat) => {
                          db.collection("chats")
                            .doc(chatId)
                            .get()
                            .then((snapshot) => {
                              setChat({
                                ...snapshot.data(),
                                ...chat,
                              });
                            });
                        })
                        .then(() => {
                          db.collection("chats")
                            .doc(chatId)
                            .collection("messages")
                            .orderBy("date", "asc")
                            .onSnapshot((snapshots) => {
                              const messages = snapshots.docs.map((snapshot) => ({
                                id: snapshot.id,
                                ...snapshot.data(),
                              }));
                              setMessages(messages);
                            });
                        });
                    });
                  });
              })
          } else {
            setMessages([]);
            db.collection("users")
              .doc(uid)
              .collection("chats")
              .doc(chatId)
              .get()
              .then((doc) => ({
                id: doc.id,
                ...doc.data(),
              }))
              .then((chat) => {
                db.collection("chats")
                  .doc(chatId)
                  .get()
                  .then((snapshot) => {
                    setChat({
                      ...snapshot.data(),
                      ...chat,
                    });
                  });
              })
              .then(() => {
                db.collection("chats")
                  .doc(chatId)
                  .collection("messages")
                  .orderBy("date", "asc")
                  .onSnapshot((snapshots) => {
                    const messages = snapshots.docs.map((snapshot) => ({
                      id: snapshot.id,
                      ...snapshot.data(),
                    }));
                    setMessages(messages);
                  });
              });
          }
        })
    }
  };

  /**
   * Fetch the messages
   */
  if (!fetched) {
    setFetched(true);
    fetchChat(chatId);
  }

  const threadRef = useRef(null);

  /**
   * Autoscroll to last message when new messages arrive.
   * @returns {*}
   */
  const scrollToBottom = () => {
    threadRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => fetchChat(chatId), [chatId]);

  useEffect(() => scrollToBottom(), [messages]);

  useEffect(() => {
    db.collection("users")
      .doc(uid)
      .collection("chats")
      .doc(chatId)
      .update({ seen: true, seenDate: Date.now() });

    //const userId = chat.users[0].userId === uid ? chat.users[1].userId : chat.users[0].userId
    /*db.collection("users").doc(userId).get().then((doc) => {
            const profilePic = doc.data().profilePic
            setProfilePic(profilePic)
        })*/
  }, []);

  useEffect(() => {
    messagesRef.current = messagesRef.current?.slice(0, messages.length);
  }, [messages]);

  useEffect(() => {
    if (message !== '') {
      db.collection("chats")
        .doc(chatId)
        .get()
        .then((doc) => {
          const isWriting = doc.data().isWriting
          db.collection("chats")
            .doc(chatId)
            .update(isWriting ? {
              isWriting: {
                ...isWriting,
                [uid]: true,
              }
            } : {
              isWriting: {
                [uid]: true,
              }
            })
        })
    } else {
      db.collection("chats")
        .doc(chatId)
        .get()
        .then((doc) => {
          const isWriting = doc.data().isWriting
          db.collection("chats")
            .doc(chatId)
            .update(isWriting ? {
              isWriting: {
                ...isWriting,
                [uid]: false,
              }
            } : {
              isWriting: {
                [uid]: false,
              }
            })
        })
    }
  }, [message]);

  const setIsWriting = (value) => {
    if (value === false)
      messagesRef.current[0].scrollIntoView({
        behavior: "smooth",
      });

    setTimeout(() => {
      setIsWritingg(value);
      scrollToBottom();
    }, 50);
  };

  useEffect(() => {
    if (JSON.stringify(chat) !== JSON.stringify({})) {
      const userId =
        chat.users[0].userId === uid
          ? chat.users[1].userId
          : chat.users[0].userId;

      db.collection("chats")
        .doc(chatId)
        .onSnapshot((doc) => {
          const isWriting = doc.data().isWriting
          if (isWriting === undefined || isWriting === null || isWriting[userId] === undefined || isWriting[userId] === null || isWriting[userId] === false) {
            setIsWritingg(false)
          } else {
            setIsWriting(true)
          }
        })
    }
  }, [chatId, chat, uid])

  useEffect(() => {
    if (chat.users) {
      const userId =
        chat.users[0].userId === uid
          ? chat.users[1].userId
          : chat.users[0].userId;
      setInterlocutorUserId(userId);
      db.collection("users")
        .doc(userId)
        .collection("chats")
        .doc(chatId)
        .get()
        .then((doc) => {
          const seenDate = doc.data()?.seenDate;
          if (seenDate !== undefined && seenDate !== null) {
            setInterlocutorSeenDate(seenDate)
          }
        });
    }
  }, [chat]);

  const messagesArr = messages.map((message, key) => {
    const { sender } = message;
    if (sender.userId === uid && interlocutorSeenDate !== undefined && interlocutorSeenDate !== null && interlocutorSeenDate > message.date) {
      message.seen = true;
    }

    const components = [];

    const previousMessage =
      key > 0 && messages[key - 1] ? messages[key - 1] : null;

    function today(date) {
      return new Date(
        new Date(date).getFullYear(),
        new Date(date).getMonth(),
        new Date(date).getDate()
      ).getTime();
    }

    if (today(lastDateDisplayed) !== today(message.date)) {
      lastDateDisplayed = message.date;

      components.push(<ChatDate key={chat.date} date={message.date} />);
    }

    const messageIsMine = (message) => {
      if (message === undefined) return true
      return (
        message.sender.userId === firebaseApp.auth().currentUser.uid &&
        message.message !== ""
      );
    };

    var indefOfLastMessageSent = messages.length - 1;
    while (
      !messageIsMine(messages[indefOfLastMessageSent]) ||
      indefOfLastMessageSent === 0
    ) {
      indefOfLastMessageSent--;
    }

    if (messageIsMine(message)) {
      components.push(
        <div ref={(el) => (messagesRef.current[key] = el)}>
          <ChatSent
            key={message.id}
            message={message}
            chat={chat}
            isLast={key === indefOfLastMessageSent}
          />
        </div>
      );
    } else if (message.message !== "") {
      components.push(
        <div ref={(el) => (messagesRef.current[key] = el)}>
          <ChatReceived key={message.id} message={message} />
        </div>
      );
    }

    if (messages.length - 1 === key && isWriting && interlocutorUserId) {
      components.push(
        <div>
          <ChatReceived isWriting userId={interlocutorUserId} />
        </div>
      );
    }

    return components;
  });

  const header = (
    <CardHeader
    >
      <Row className="align-items-center">
        <Col className="col-auto pr-0 d-lg-none">
          <Link className="btn btn-link btn-sm" to="/chat">
            <i className="fas fa-arrow-left" style={{ fontSize: "17px" }} />
          </Link>
        </Col>
        <div className="col">
          <h5 className="h2 mb-0 font-weight-normal">
            {chat.name || "Conversation"}
          </h5>
        </div>
        <Col className="col-auto">
          <ChatThreadMenu chat={chat} />
        </Col>
      </Row>
    </CardHeader>
  );

  return (
    <Card className="shadow h-100">
      {header}
      <CardBody className="scroll-body" style={{ transition: "1s" }}>
        <ListGroup flush>
          <Row>
            <Col>
              {messagesArr}
              <div className="float-left clear" ref={threadRef} />
            </Col>
          </Row>
        </ListGroup>
      </CardBody>
      <CardFooter>
        <ChatInput
          chatId={chat.id}
          onSubmit={(message) => addMessage(chat, message)}
          onSubmit2={(picture) => addPicture(chat, picture)}
          onSubmit3={(video) => addVideo(chat, video)}
          onSubmit4={(file) => addFile(chat, file)}
          message={message}
          setMessage={setMessage}
        />
      </CardFooter>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  user: state.User.user,
});

export default connect(mapStateToProps, {
  addMessage: dbAddmessage,
  addPicture: dbAddpicture,
  addVideo: dbAddvideo,
  addFile: dbAddfile,
})(ChatThread);

ChatThread.propTypes = {
  addVideo: PropTypes.func,
  addPicture: PropTypes.func,
  addMessage: PropTypes.func,
  addFile: PropTypes.func,
};

ChatThread.defaultProps = {
  addVideo: () => { },
  addPicture: () => { },
  addMessage: () => { },
  addFile: () => { },
};