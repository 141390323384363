import { Link } from "react-router-dom";
import Aux from "../../../hoc/Aux";
import PageComponent from "../PageComponent";
import "../custom.css";

function WriteToContact() {
  return (
    <Aux>
      <PageComponent>
        <h1 className="help-item-title">Écrire à un contact</h1>
        <p className="help-item-text">
          Pour écrire un message à un contact, vous pouvez :
          <br />
          <br />
          <ul>
            <li>
              Aller sur le profil de ce contact et cliquer sur "Envoyer un
              message"
            </li>
            <li>
              Dans votre messagerie cliquer sur Composer un message, puis
              rechercher le contact
            </li>
            <li>
              Dans la page Contacts cliquer sur la petite enveloppe en face du
              nom de la personne à qui vous souhaitez écrire
            </li>
          </ul>
          <p className="help-item-subtitle">
            Envoyer un message à plusieurs de mes contacts
          </p>
          Envoyer un même message à plusieurs membres de Tribu est très simple
          : 
          <br />
          <br />
          <ul>
            <li>
              Aller dans votre messagerie en cliquant sur "Composer un message",
              puis rechercher un contact
            </li>
            <li>
              Commencez à remplir un nom de destinataire. Le moteur de recherche
              auto-remplit la petite barre de recherche et vous propose des
              noms. Cliquez sur le destinataire souhaité. Recommencez
              l’opération pour chacun des contacts à inviter
            </li>
            <li>Complétez votre message et cliquez sur "Envoyer un message"</li>
          </ul>
        </p>
      </PageComponent>
    </Aux>
  );
}

export default WriteToContact;
