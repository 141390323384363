import PropTypes from "prop-types";
import React, { Component } from "react";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Link } from "react-router-dom";

class SignupModal extends Component {
  render() {
    const { isOpen, toggle } = this.props;

    return (
      <Modal size="lg" isOpen={isOpen} centered toggle={toggle}>
        <ModalHeader toggle={toggle} />
        <ModalBody className="pt-0 align-items-center">
          <Row className="justify-content-center">
            <h5 className="h1 mb-0 font-weight-light">
              Créez votre compte Tribu
            </h5>
          </Row>
          <Row className="px-lg-5 my-3">
            <Col>
              <Link className="btn btn-outline-default signup-modal-button" to="/signup">
                Je suis courtier
              </Link>
            </Col>
            <Col>
              <Link className="btn btn-outline-default signup-modal-button" to="/signup/corporate">
                Je suis un grand compte
              </Link>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <span>
              Déjà un compte Tribu ?
              <Button onClick={toggle} style={{ marginLeft: 16 }}>
                <Link to="/signin">Connectez-vous</Link>
              </Button>
            </span>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

export default SignupModal;

SignupModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

SignupModal.defaultProps = {
  isOpen: false,
  toggle: () => { },
};