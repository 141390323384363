import PropTypes from 'prop-types';
import React from 'react';
import {
    Button,
} from 'reactstrap';
import { connect } from 'react-redux';
import { dbFollowCompany, dbUnfollowCompany } from '../../store/company/actions';

const CompanyFollowing = (props) => {
    const {
        follow,
        unFollow,
        followings,
        companyId,
    } = props;

    const isFollowing = followings.includes(companyId);

    if (isFollowing) {
        return (
            <Button size="sm" onClick={() => unFollow(companyId)}>
                Ne plus suivre
            </Button>
        );
    }

    return (
        <Button color="primary" size="sm" onClick={() => follow(companyId)}>
            Suivre
        </Button>
    );
};

const mapStateToProps = (state) => ({
    followings: state.User.user.followings,
});

export default connect(mapStateToProps, {
    follow: dbFollowCompany,
    unFollow: dbUnfollowCompany,
})(CompanyFollowing);

CompanyFollowing.propTypes = {
    companyId: PropTypes.string,
    follow: PropTypes.func,
    followings: PropTypes.array,
    unFollow: PropTypes.func,
};

CompanyFollowing.defaultProps = {
    companyId: null,
    follow: () => {},
    followings: [],
    unFollow: () => {},
};
