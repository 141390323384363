import React, { Component } from 'react';
import { Button, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import Banner from '../../assets/Pics/Icons/banner.png';
import SignupModal from './SignupModal';
import Aux from '../../hoc/Aux';

class SigninHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            signupModal: false,
        };

       
    }


    render() {
        const {
            signupModal,
        } = this.state;

        return (
            <Aux>
                <SignupModal isOpen={signupModal} toggle={() => this.setState({ signupModal: !signupModal })} />
                <Col className="bg-gradient-info h-100vh align-items-center d-none d-md-flex">
                    <div className="mx-auto text-center">
                        <Link to="/">
                            <img
                                className="position-absolute top-4 left-6"
                                alt="Tribu"
                                src={Banner}
                                style={{ maxWidth: '130px' }}
                            />
                        </Link>
                        <h2 className="display-4 text-white">
                            Contents de vous revoir !
                        </h2>
                        <small className="text-white mt-4">
                            Pas encore de compte ?
                        </small>
                        <br />
                        <Button className="btn-secondary mt-2 w-50 text-primary" onClick={() => this.setState({ signupModal: !signupModal })}>
                            INSCRIVEZ-VOUS
                        </Button>
                    </div>
                </Col>
            </Aux>
        );
    }
}

export default SigninHeader;
