import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
    Button, Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';
import { connect } from 'react-redux';
import { dbDeletePost as actionsDeletepost } from '../../store/posts/actions';
import { Col } from 'reactstrap';
import { ClipLoader } from 'react-spinners';

class DeleteModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        };
    }

    deletePostHandler = () => {
        this.setState({
            loading: true
        })
        const {
            deletePost, postId, userId, isCompany, authorId
        } = this.props;

        deletePost(postId, userId, isCompany, authorId).then(() => {
            window.location.reload();
        });
    };

    render() {
        const {
            loading,
        } = this.state;
        const {
            isOpen,
            toggle,
        } = this.props;

        if (loading) return <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>Supprimer le post</ModalHeader>
            <ModalBody>
                <Col className="col-12 text-center">
                    <ClipLoader color="#1171ef" size={30} />
                </Col>
            </ModalBody>
        </Modal>

        return (
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>Supprimer le post</ModalHeader>
                <ModalBody>
                    Vous allez supprimer la publication.
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.deletePostHandler} className="mr-2" size="sm">Supprimer</Button>
                    <Button color="secondary" onClick={toggle} size="sm">Annuler</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => ({
    userId: state.User.user.id,
});

export default connect(mapStateToProps, {
    deletePost: actionsDeletepost,
})(DeleteModal);

DeleteModal.propTypes = {
    deletePost: PropTypes.func,
    isOpen: PropTypes.bool,
    postId: PropTypes.string,
    toggle: PropTypes.func,
    userId: PropTypes.string,
};

DeleteModal.defaultProps = {
    deletePost: () => { },
    isOpen: false,
    postId: null,
    toggle: () => { },
    userId: '',
};
