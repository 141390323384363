import { Link } from "react-router-dom";
import Aux from "../../../hoc/Aux";
import PageComponent from "../PageComponent";
import "../custom.css";

function SignInProblem() {
  return (
    <Aux>
      <PageComponent>
        <h1 className="help-item-title">Problème de connexion</h1>
        <p className="help-item-text">
          La première chose à regarder, c'est : utilisez-vous des bloqueurs de
          pub ou un anti-virus qui pourraient entrer en conflit avec le site ? 
          <br />
          <br />
          Si ce n'est pas le cas, nous vous invitons à utiliser le formulaire "
          <Link to={"/reset-password"}>mot de passe oublié</Link>" afin de
          recevoir un e-mail pour réinitialiser votre mot de passe.
          <br />
          Vous pourrez de nouveau vous connecter à Tribu avec votre nouveau mot
          de passe.
          <br />
          <br />
          - Pensez à vérifier que la touche majuscule ne soit pas activée ou
          encore que vous n'ajoutez pas un caractère supplémentaire (comme un
          espace à la fin du mot) lorsque vous renseignez votre mot de passe ou
          votre e-mail.
          <br />
          <br />
          - Saisissez manuellement votre e-mail et votre mot de passe.
          <br />
          <br />
          Il arrive aussi parfois qu'un caractère comme un espace soit mis à la
          fin de votre e-mail lorsque celui-ci est renseigné automatiquement et
          cela peut bloquer votre identification sur le site.
          <br />
          <br />
          Si le problème persiste, essayez de vous connecter avec un autre
          navigateur comme Chrome ou Safari afin de vérifier si ce problème
          provient du navigateur. 
          <br />
          <br />
          Si vous ne recevez pas l'e-mail avec votre nouveau mot de passe, nous
          vous conseillons de vérifier dans le dossier de courrier indésirable
          (ou spam) de votre messagerie : il peut arriver que le message y ait
          été classé par erreur.
          <br />
          <br />
          Vous pouvez également ajouter contact [at] tribu [dot] com à vos
          contacts pour vous assurer de recevoir toutes nos communications.
        </p>
      </PageComponent>
    </Aux>
  );
}

export default SignInProblem;
