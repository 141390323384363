import * as moment from 'moment';
import firebase from '@firebase/app';
import { db, firebaseApp } from '../../config/firebase';
import { uploadImage } from '../images/actions';
import {
    ADD_POST,
    ADD_POST_LIST,
    DELETE_POST, RESET_POSTS,
    SET_LAST_POST_DATE,
    SET_LIKES,
    SET_POST_COMMENTS, SET_POST_EDITING_POST,
    SET_POST_LIKES_LOADING, SET_POST_LOADING,
    SET_POST_USER_LIKES,
} from './types';

const sortPosts = (posts) => posts.sort((a, b) => b.createdAt - a.createdAt);

const setIsLoading = (isLoading) => ({
    type: SET_POST_LOADING,
    isLoading,
});

const addPosts = (posts, before = false) => ({
    type: ADD_POST_LIST,
    posts,
    before,
});

const setLastPostDate = (userId, date) => ({
    type: SET_LAST_POST_DATE,
    userId,
    date,
});

const addPost = (post) => ({
    type: ADD_POST,
    post,
});

const deletePost = (postId) => ({
    type: DELETE_POST,
    postId,
});

export const resetPosts = () => ({
    type: RESET_POSTS,
});

const setLikes = (likes) => ({
    type: SET_LIKES,
    likes,
});

const setLikesLoading = (isLoading) => ({
    type: SET_POST_LIKES_LOADING,
    isLoading,
});

const setUserLikes = (postId) => ({
    type: SET_POST_USER_LIKES,
    postId,
});

const setPostComments = (postId, comments) => ({
    type: SET_POST_COMMENTS,
    postId,
    comments,
});

export const setEditingPost = (postId) => ({
    type: SET_POST_EDITING_POST,
    postId,
});

/**
 * Fetch posts of an user.
 * @param userId
 * @param lastDate
 * @param limit
 * @returns {Promise<{comments: [], id: *, userLikes: (*|likes|[])}[]>}
 */
export const fetchPostsByUserId = (userId, lastDate, limit) => {
    let query = db.collection('posts')
        .where('author.userId', '==', userId)
        .orderBy('createdAt', 'desc');

    if (lastDate) {
        query = query.startAfter(lastDate);
    }

    if (limit) {
        query = query.limit(limit);
    }

    return query.get()
        .then((snapshots) => snapshots.docs.map((snapshot) => ({
            id: snapshot.id,
            comments: [],
            userLikes: snapshot.data().likes && snapshot.data().likes[userId],
            ...snapshot.data(),
        }
        )));
};

/**
 * Create the user timeline by collecting posts of his contacts.
 * @param limit
 * @returns {Function}
 */
export const dbFetchPosts = (limit) => async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    const userId = firebaseApp.auth().currentUser.uid;

    const { lastPostDate } = getState().Posts;

    const contactIds = await db.collection('users')
        .doc(userId)
        .collection('contacts')
        .where('status', '==', 1)
        .get()
        .then((snapshots) => snapshots.docs.map((doc) => doc.id));

    const companyIds = await db.collection('users')
        .doc(userId)
        .collection('following')
        .where('following', '==', true)
        .get()
        .then((snapshots) => snapshots.docs.map((doc) => doc.id));

    const authorIds = [...contactIds, ...companyIds, userId];

    const postPromises = [];

    authorIds.forEach((contactId) => {
        postPromises.push(
            fetchPostsByUserId(contactId, lastPostDate[contactId], limit)
                .then((posts) => {
                    const lastDate = posts.length ? posts[posts.length - 1].createdAt : undefined;
                    dispatch(setLastPostDate(contactId, lastDate));
                    return posts;
                }),
        );
    });

    Promise.all(postPromises).then((posts) => {
        const newPosts = [];

        posts.forEach((items) => newPosts.push(...items));

        dispatch(addPosts(sortPosts(newPosts)));
        dispatch(setIsLoading(false));
    });
};

/**
 * Fetch only the post of an user.
 * @param userId
 * @param limit
 */
export const dbFetchPostsByUserId = (userId, limit) => (dispatch, getState) => {
    dispatch(setIsLoading(true));

    const { lastPostDate } = getState().Posts;

    fetchPostsByUserId(userId, lastPostDate[userId], limit)
        .then((posts) => {
            const lastDate = posts.length ? posts[posts.length - 1].createdAt : undefined;

            dispatch(setLastPostDate(userId, lastDate));
            dispatch(addPosts(sortPosts(posts)));
            dispatch(setIsLoading(false));
        });
};

/**
 * Add a post to firebase then dispatch it to the reducer
 * @param post
 * @param user
 * @returns {Function}
 */
export const dbAddPost = (post, author) => async (dispatch) => {

    
    post = {
        author,
        createdAt: moment.now(),
        nbOfLikes: 0,
        nbOfComments: 0,
        ...post,
    };

    const postRef = db.collection('posts').doc();

    if (post.images) {
        const imagePromises = [];

        post.images.forEach((image) => {
            imagePromises.push(uploadImage(`posts/${postRef.id}`, image.file));
        });

        post.images = await Promise.all(imagePromises).then((values) => values);
    }

    if (post.videos) {
        const videoPromises = [];

        post.videos.forEach((video) => {
            videoPromises.push(uploadImage(`posts/${postRef.id}`, video.file));
        });

        post.videos = await Promise.all(videoPromises).then((values) => values);
    }
    

    await postRef.set(post);

    let userRef = db.collection('users').doc(author.userId);


    if (author.isCompany) {
        userRef = db.collection('companies').doc(author.userId);
    }

    await userRef.update({ nbOfPosts: firebase.firestore.FieldValue.increment(1) });

    dispatch(addPost({
        id: postRef.id,
        comments: [],
        userLikes: false,
        ...post,
    }));
};

/**
 * Edit a post
 * @param post
 * @returns {function(*): Promise<void>}
 */
export const dbEditPost = (post) => async (dispatch) => {
    if (post.images) {
        const imagePromises = [];

        post.images.forEach((image) => {
            if (image.file) imagePromises.push(uploadImage(`posts/${post.id}`, image.file));
        });

        post.images = post.images.filter((image) => image.file === undefined);

        post.images = [
            ...post.images,
            ...await Promise.all(imagePromises).then((values) => values),
        ];
    }

    db.collection('posts')
        .doc(post.id)
        .update({
            text: post.text,
            images: post.images,
        }).then(() => {
            dispatch(addPosts([post], true));
        });
};
/**
 * Delete a post
 * @param postId
 * @param userId
 * @returns {Function}
 */
export const dbDeletePost = (postId, userId, isCompany, authorId) => async (dispatch) => {
    await db.collection('posts').doc(postId).delete()
    if (isCompany) {
        await db.collection('companies').doc(authorId).update({ nbOfPosts: firebase.firestore.FieldValue.increment(-1) })
    } else {
        await db.collection('users').doc(userId).update({ nbOfPosts: firebase.firestore.FieldValue.increment(-1) })
    }
    dispatch(deletePost(postId));
};

/**
 * Fetch the likes of a post
 * @returns {Function}
 */
// eslint-disable-next-line no-unused-vars
export const dbFetchPostLikes = (postId) => (dispatch, getState) => {
    dispatch(setLikesLoading(true));

    const { posts } = getState().Posts;

    const post = posts.filter((item) => item.id === postId);

    if (post.length === 1 && post[0].likes) {
        const userPromises = [];
        Object.keys(post[0].likes).forEach((like) => {
            userPromises.push(
                db.collection('users').doc(like).get()
                    .then((doc) => doc.data()),
            );
        });

        Promise.all(userPromises).then((likes) => {
            dispatch(setLikes(likes));
            dispatch(setLikesLoading(false));
        });
    }
};

/**
 * Like a post in Firestore then dispatch in reducer
 * @param postId
 * @param user
 * @returns {Function}
 */
export const dbLikePost = (postId, user) => (dispatch) => {
    const ref = db.collection('posts')
        .doc(postId);
    db.runTransaction((transaction) => transaction.get(ref)
        .then((doc) => {
            if (doc.exists) {
                const post = doc.data();
                let { likes, nbOfLikes } = post;
                if (!likes) {
                    likes = {};
                }
                if (!nbOfLikes) {
                    nbOfLikes = 0;
                }

                if (!likes[user.id]) {
                    likes[user.id] = true;
                    nbOfLikes++;
                } else {
                    delete likes[user.id];
                    nbOfLikes--;
                }

                transaction.update(ref, {
                    likes: { ...likes },
                    nbOfLikes,
                });
            }
        }));

    dispatch(setUserLikes(postId));
};

/**
 * Fetch the comments of a post
 * @param postId
 * @returns {Function}
 */
export const dbFetchComments = (postId) => (dispatch) => {
    db.collection('comments')
        .orderBy('createdAt', 'desc')
        .where('postId', '==', postId)
        .onSnapshot(((snapshot) => {
            const comments = [];

            snapshot.forEach((doc) => {
                const data = doc.data();

                const promise = db.collection('users')
                    .doc(data.userId)
                    .get()
                    .then((user) => ({
                        id: doc.id,
                        ...data,
                        author: {
                            id: user.id,
                            ...user.data(),
                        },
                    }));

                comments.push(promise);
            });

            Promise.all(comments)
                .then((data) => {
                    dispatch(setPostComments(postId, data));
                });
        }));
};

/**
 * Add a comment
 * @param postId
 * @param userId
 * @param comment
 * @returns {Function}
 */
// eslint-disable-next-line no-unused-vars
export const dbAddComment = (postId, userId, comment) => (dispatch) => {
    db.collection('comments')
        .add({
            postId,
            userId,
            createdAt: moment.now(),
            comment,
        });
};

/**
 * Delete a comment
 * @param commentId
 * @returns {Function}
 */
// eslint-disable-next-line no-unused-vars
export const dbDeleteComment = (commentId) => (dispatch) => {
    db.collection('comments')
        .doc(commentId)
        .delete();
};

// eslint-disable-next-line no-unused-vars
export const dbReportPost = (postId, text) => (dispatch) => {
    const userId = firebaseApp.auth().currentUser.uid;

    db.collection('reports')
        .doc()
        .set({
            createdAt: moment.now(),
            type: 'POSTS',
            userId,
            postId,
            text,
        });
};
