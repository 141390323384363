import React, { Component, createRef } from "react";
import {
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { withRouter } from "react-router";
// import Aux from "../../hoc/Aux";
import "./custom2.css";
import { createAutocomplete } from "@algolia/autocomplete-core";
import { getAlgoliaResults } from "@algolia/autocomplete-preset-algolia";
import algoliasearch from "algoliasearch/lite";
import PlacesAutocomplete from "react-places-autocomplete";
import "@algolia/autocomplete-theme-classic/dist/theme.css";

const searchClient = algoliasearch(
  "DS4P4HIB8H",
  "8cced0de8b8fbfec89bfa7203116ae37"
);

class DefaultSearchBar6 extends Component {
  constructor(props) {
    super(props);
    this.inputLeft = createRef();
    this.inputRight = createRef();
    const { location } = this.props;

    const params = new URLSearchParams(location.search);

    this.state = {
      search: params.get("q") ?? "",
      city: params.get("l") ?? "",
      errorSearch: false,
      errorCity: false,
      autocompleteState: {},
      modal: false,
      inputLeftWidth: 0,
      inputRightWidth: 0,
    };

    this.autocomplete = createAutocomplete({
      onStateChange: this.onChange,
      getSources() {
        return [
          {
            sourceId: "hits",
            getItems({ query }) {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: "users",
                    query,
                    params: {
                      hitsPerPage: 5,
                      highlightPreTag: "<mark>",
                      highlightPostTag: "</mark>",
                    },
                  },
                  {
                    indexName: "categories",
                    query,
                    params: {
                      hitsPerPage: 5,
                      highlightPreTag: "<mark>",
                      highlightPostTag: "</mark>",
                    },
                  },
                  {
                    indexName: "services",
                    query,
                    params: {
                      hitsPerPage: 5,
                      highlightPreTag: "<mark>",
                      highlightPostTag: "</mark>",
                    },
                  },
                  {
                    indexName: "companies",
                    query,
                    params: {
                      hitsPerPage: 5,
                      highlightPreTag: "<mark>",
                      highlightPostTag: "</mark>",
                    },
                  },
                ],
              });
            },
            getItemUrl({ item }) {
              return item.url;
            },
          },
        ];
      },
    });
  }

  componentWillMount() {
    window.addEventListener("resize", this.handleResize);
    this.unlisten = this.props.history.listen((location, action) => {
      const params = new URLSearchParams(location.search);
      if (params.get("q") !== null) {
        this.setState({ search: params.get("q") });
      }
      if (params.get("l") !== null) {
        this.setState({ city: params.get("l") });
      }
    });
  }

  componentWillUnmount() {
    this.unlisten();
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({
      inputLeftWidth: this.inputLeft.current.offsetWidth,
      inputRightWidth: this.inputRight.current.offsetWidth,
    });
  }

  onChange = ({ state }) => {
    this.setState({ autocompleteState: state, search: state.query });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleChange2 = (city) => {
    this.setState({ city });
  };

  handleSearch = (e) => {
    e.preventDefault();
    const { search, city } = this.state;
    const { history, location } = this.props;

    const params = new URLSearchParams(location.search);
    const categories = params.get("c") ?? "";
    const services = params.get("s") ?? "";
    
    if (search === "") {
      this.setState({ errorSearch: true });
      return;
    }

    if (search === "" && city !== "") {
      this.setState({ modal: true });
    } else {
      history.push(
        `/actions${search ? `?q=${search}` : ""}${
          city ? `&l=${city.replace(/, France$/, "")}` : ""
        }${categories ? `&c=${categories}` : ""}${
          services ? `&s=${services}` : ""
        }`
      );
    }
  };

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  render() {
    const { search, errorSearch, errorCity, city, autocompleteState } =
      this.state;
    const { onHeader } = this.props;

    return (
      <div
        className="aa-Autocomplete w-100"
        {...this.autocomplete?.getRootProps({})}
      >
        <Form
          className="navbar-search navbar-search-dark form-inline w-100 mx-auto d-flex"
          onSubmit={this.handleSearch}
          style={{ transform: "translateX(0)" }}
        >
          <FormGroup className={`input-left-container`} style={{}}>
            <InputGroup
              className="input-group-alternative border-right-0 w-100"
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                backgroundColor: "rgba(0, 0, 0, .2)",
              }}
            >
              <div className="w-100" ref={this.inputLeft}>
                <Input
                  style={{
                    textOverflow: "ellipsis",
                    width: "100%",
                  }}
                  {...this.autocomplete?.getInputProps({
                    placeholder:
                      "Personne, Entreprise, Service, n° Orias, Siren, Mot-clé ...",
                    value: search,
                  })}
                />
              </div>
            </InputGroup>
            {errorSearch && search === "" && (
              <span
                className="position-absolute text-danger"
                style={{
                  transform: `translate(${0}px, ${onHeader ? 32 : 50}px)`,
                  width:
                    this.state.inputLeftWidth ||
                    this.inputLeft.current.offsetWidth,
                  textShadow: "0px 0px 40px rgba(0,0,0,0.4)",
                }}
              >
                Indiquez un élément de recherche
              </span>
            )}
            {errorCity && city === "" && (
              <span
                className="position-absolute text-danger"
                style={{
                  transform: `translate(${0}px, ${onHeader ? 32 : 50}px)`,
                  marginLeft:
                    this.state.inputLeftWidth ||
                    this.inputLeft.current.offsetWidth,
                  width:
                    this.state.inputRightWidth ||
                    this.inputRight.current.offsetWidth,
                  textShadow: "0px 0px 40px rgba(0,0,0,0.4)",
                }}
              >
                Indiquez une ville
              </span>
            )}
          </FormGroup>
          <FormGroup
            className={`input-right-container ${
              onHeader ? "input-right-container-header" : ""
            }`}
            style={{ width: onHeader && 240 }}
          >
            <InputGroup
              className="input-group-alternative border-left-0 w-100"
              style={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                backgroundColor: "rgba(0, 0, 0, .2)",
                marginBlock: onHeader ? 0 : 90,
              }}
            >
              <PlacesAutocomplete
                value={this.state.city.replace(/, France$/, "")}
                onChange={this.handleChange2}
                searchOptions={{
                  types: ["(cities)"],
                  region: "FR",
                  componentRestrictions: { country: "fr" },
                }}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <>
                    <div style={{ flex: 1 }} ref={this.inputRight}>
                      <Input
                        style={{ width: "100%", textOverflow: "ellipsis" }}
                        value={this.state.city.replace(/, France$/, "")}
                        {...getInputProps({
                          placeholder: "Bordeaux, Lyon, Nice ...",
                        })}
                      />
                    </div>
                    {(loading || suggestions.length > 0) && (
                      <div className={`autocomplete-right-container cursor`}>
                        <div className="autocomplete-scroll">
                          {/*loading && <div>Loading...</div>*/}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                            return (
                              <div
                                className="autocomplete-right-item style suggestion"
                                {...getSuggestionItemProps(suggestion)}
                              >
                                {suggestion.description.replace(
                                  /, France$/,
                                  ""
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </PlacesAutocomplete>

              <InputGroupAddon addonType="prepend">
                <InputGroupText
                  style={{ cursor: "pointer" }}
                  onClick={this.handleSearch}
                >
                  <i className="fas fa-search float-right" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
          <input
            type="submit"
            style={{
              position: "absolute",
              left: "-9999px",
              width: 0,
              height: 0,
            }}
            tabIndex="-1"
          />
        </Form>
        {autocompleteState.isOpen == true && (
          <div
            {...this.autocomplete?.getPanelProps({})}
            style={{
              width: onHeader
                ? this.state.inputLeftWidth ||
                  this.inputLeft.current.offsetWidth
                : "",
            }}
            className={`autocomplete-left-container ${
              onHeader ? "autocomplete-left-container-header" : ""
            }`}
          >
            <div className="autocomplete-scroll">
              {autocompleteState.collections.map((collection, index) => {
                const { source, items } = collection;

                return (
                  <div key={`source-${index}`} className="bg-white">
                    {items.map((item) => (
                      <div>
                        <div
                          className="aa-ItemWrapper suggestion"
                          style={{ padding: 0 }}
                          {...this.autocomplete?.getItemProps({
                            item,
                            source,
                          })}
                          onClick={() => {
                            const { city } = this.state;
                            const { history, location } = this.props;

                            const params = new URLSearchParams(location.search);
                            const categories = params.get("c") ?? "";
                            const services = params.get("s") ?? "";

                            if (item.name !== undefined) {
                              history.push(
                                `/actions?e=${item.name}${
                                  city
                                    ? `&l=${city.replace(/, France$/, "")}`
                                    : ""
                                }${categories ? `&c=${categories}` : ""}${
                                  services ? `&s=${services}` : ""
                                }`
                              );
                            } else if (
                              item.label !== undefined &&
                              item.category !== undefined
                            ) {
                              history.push(
                                `/actions?q=&c=${item.category}&s=${item.label}`
                              );
                            } else if (item.label !== undefined) {
                              history.push(`/actions?q=&c=${item.label}`);
                            } else {
                              history.push(
                                `/actions?q=${item.firstName}+${item.lastName}${
                                  city
                                    ? `&l=${city.replace(/, France$/, "")}`
                                    : ""
                                }${categories ? `&c=${categories}` : ""}${
                                  services ? `&s=${services}` : ""
                                }`
                              );
                            }
                          }}
                        >
                          <div className="aa-ItemContent">
                            <div className="aa-ItemIcon aa-ItemIcon--noBorder">
                              <svg
                                viewBox="0 0 24 24"
                                width="18"
                                height="18"
                                fill="currentColor"
                              >
                                <path d="M16.041 15.856c-0.034 0.026-0.067 0.055-0.099 0.087s-0.060 0.064-0.087 0.099c-1.258 1.213-2.969 1.958-4.855 1.958-1.933 0-3.682-0.782-4.95-2.050s-2.050-3.017-2.050-4.95 0.782-3.682 2.050-4.95 3.017-2.050 4.95-2.050 3.682 0.782 4.95 2.050 2.050 3.017 2.050 4.95c0 1.886-0.745 3.597-1.959 4.856zM21.707 20.293l-3.675-3.675c1.231-1.54 1.968-3.493 1.968-5.618 0-2.485-1.008-4.736-2.636-6.364s-3.879-2.636-6.364-2.636-4.736 1.008-6.364 2.636-2.636 3.879-2.636 6.364 1.008 4.736 2.636 6.364 3.879 2.636 6.364 2.636c2.125 0 4.078-0.737 5.618-1.968l3.675 3.675c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414z" />
                              </svg>
                            </div>
                            <div className="aa-ItemContentBody">
                              <div className="aa-ItemContentTitle">
                                {item.name !== undefined
                                  ? `${item.name}`
                                  : item.label !== undefined
                                  ? `${item.label}`
                                  : `${this.capitalizeFirstLetter(
                                      item.firstName.toLowerCase()
                                    )} ${item.lastName.toUpperCase()}`}
                              </div>
                            </div>
                          </div>

                          <div className="aa-ItemActions">
                            <button
                              className="aa-ItemActionButton"
                              title={`Fill query with "${item.query}"`}
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                              }}
                            >
                              <svg
                                viewBox="0 0 24 24"
                                width="18"
                                height="18"
                                fill="currentColor"
                              >
                                <path d="M8 17v-7.586l8.293 8.293c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414l-8.293-8.293h7.586c0.552 0 1-0.448 1-1s-0.448-1-1-1h-10c-0.552 0-1 0.448-1 1v10c0 0.552 0.448 1 1 1s1-0.448 1-1z" />
                              </svg>
                            </button>
                          </div>
                        </div>
                        <div className="aa-ItemWrapper">
                          <div className="aa-ItemContent aa-ItemContent--indented">
                            <div className="aa-ItemContentSubtitle aa-ItemContentSubtitle--standalone">
                              <span className="aa-ItemContentSubtitleIcon" />
                              <span>
                                dans{" "}
                                <span className="aa-ItemContentSubtitleCategory">
                                  {item.category !== undefined &&
                                  item.sub_category !== undefined &&
                                  item.sub_sub_category !== undefined &&
                                  item.sub_sub_sub_category !== undefined
                                    ? `${item.sub_sub_sub_category} - ${item.sub_sub_category} - ${item.sub_category} - ${item.category}`
                                    : item.category !== undefined &&
                                      item.sub_category !== undefined &&
                                      item.sub_sub_category !== undefined
                                    ? `${item.sub_sub_category} - ${item.sub_category} - ${item.category}`
                                    : item.category !== undefined &&
                                      item.sub_category !== undefined
                                    ? `${item.sub_category} - ${item.category}`
                                    : item.label !== undefined
                                    ? `Catégorie`
                                    : item.name !== undefined
                                    ? `Entreprise`
                                    : `Membre`}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(DefaultSearchBar6);
