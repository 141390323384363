import React, { Component } from "react";
import { Switch, withRouter } from "react-router";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Alert, Container, Row } from "reactstrap";
import ReactGA from "react-ga4";
// import TagManager from 'react-gtm-module';
import router from "./router/Router";
import { fetchUser as userfetchUser } from "./store/user/actions";
import Loading from "./views/Loading";
import Aux from "./hoc/Aux";
import SystemLayout from "./layouts/System/SystemLayout";
import config from "./config/config.json";
import RgpdNotif from "./components/RgpdNotif";
import { ClipLoader } from "react-spinners";

class App extends Component {
  componentDidMount() {
    this.initializeReactGA();
    // TagManager.initialize(config.googleTagManager);
    const { fetchUser } = this.props;
    fetchUser();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  initializeReactGA = () => {
    const acceptRgpd = localStorage.getItem("acceptRgpd");
    if (acceptRgpd !== true) return;
    ReactGA.initialize(config.googleAnalytics.id);
    ReactGA.pageview("/app");
  };

  render() {
    const { isFetching, isFetched, error, user } = this.props;

    if (isFetching || !isFetched) {
      return (
        <Container fluid>
          <Row className="justify-content-center mt-4">
            <ClipLoader color="#1171ef" />
          </Row>
        </Container>
      );
    }

    return (
      <React.Suspense fallback={<div>Chargement...</div>}>
        <RgpdNotif />
        {!error ? (
          <Aux>
            {!isFetching && isFetched ? (
              <Switch>{router(user)}</Switch>
            ) : (
              <SystemLayout>
                <Loading color="primary" />
              </SystemLayout>
            )}
          </Aux>
        ) : (
          <SystemLayout>
            <Alert color="primary">
              Veuillez nous excuser, une erreur s&apos;est produite lors de la
              connexion au serveur.
            </Alert>
          </SystemLayout>
        )}
      </React.Suspense>
    );
  }
}

App.propTypes = {
  error: PropTypes.string,
  fetchUser: PropTypes.func.isRequired,
  isFetched: PropTypes.bool,
  isFetching: PropTypes.bool,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.User.user,
  isFetching: state.User.isFetching,
  isFetched: state.User.isFetched,
  error: state.User.error,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUser: (token) => dispatch(userfetchUser(token)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

App.defaultProps = {
  error: null,
  isFetched: false,
  isFetching: false,
};
