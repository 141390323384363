import React, { Component } from 'react';
import {
    Col, Container, Row,
} from 'reactstrap';
import Aux from '../../hoc/Aux';
import DefaultSearchBar5 from '../Default/DefaultSearchBar5';

class LandingHeader2 extends Component {
    render() {
        return (
            <Aux>
                <div className="header bg-gradient-info pb-7">
                    <Container>
                        <div className="header-body">
                            <Row className="justify-content-center pt-6">
                                <Col lg="8">
                                    <div>
                                        <div className="mt-5">
                                            <DefaultSearchBar5 isSearchPage={this.props.isSearchPage}/>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </Aux>
        );
    }
}

export default LandingHeader2;
