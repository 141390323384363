import React, { Component } from 'react';
import Aux from '../../hoc/Aux';
import LandingNavbar from '../Landing/LandingNavbar';
import DefaultFooter from '../Default/DefaultFooter';

class CorporateLayout extends Component {
    render() {
        const {
            children,
        } = this.props;

        return (
            <Aux>
                <div className="main-content bg-white" style={{ minHeight: '100vh' }}>
                    <LandingNavbar isCorporate />
                    {children}
                    <div className="py-4" />
                    <section className="section pt-6 bg-white">
                        <DefaultFooter />
                    </section>
                </div>
            </Aux>
        );
    }
}

export default CorporateLayout;
