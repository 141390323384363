import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import {
    Badge,
    Col,
    DropdownItem, DropdownMenu, DropdownToggle, ListGroup, ListGroupItem, NavItem, Row, UncontrolledDropdown,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { dbFetchChats2 } from '../../store/chatRecents/actions';
import classes from '../../assets/Scss/components/Notifications.scss';
import DefaultProfile from '../../assets/Pics/defaultProfile.jpg';

const DefaultNotificationsChats = (props) => {
    const {
        fetchChats,
        chats,
        allowNotifications,
    } = props;

    useEffect(() => {
        fetchChats();
    }, []);

    useEffect(() => {
        fetchChats();
    }, []);

    let nbOfUnseen = 0;

    if (allowNotifications) {
        chats.forEach((chat) => {
            if (!chat.seen) nbOfUnseen++;
        });
    }

    if (!nbOfUnseen) {
        return (
            <NavItem>
                <Link to="/chat" className="nav-link">
                    <i className="ni ni-chat-round" style={{ fontSize: '100%' }} />
                </Link>
            </NavItem>
        );
    }

    let body = chats.slice(0, 5).map((chat) => {
        const { lastMessage } = chat;

        return (
            <ListGroupItem key={chat.id} className="list-group-item-action" to={`/chat/${chat.id}`} tag={Link}>
                <Row className="align-items-center">
                    <Col className="col-auto">
                        <img alt='' className="avatar rounded-circle" src={chat.profilePic ? chat.profilePic : DefaultProfile} />
                    </Col>
                    <div className="col ml--2">
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <h4 className="mb-0 text-sm">{chat.name}</h4>
                            </div>
                            <div className="text-right text-muted">
                                <small><Moment locale="fr" toNow ago>{lastMessage.date}</Moment></small>
                            </div>
                        </div>
                        <p className="text-sm mb-0">
                            {lastMessage.message}
                        </p>
                    </div>
                </Row>
            </ListGroupItem>
        );
    });

    if (!body.length) {
        body = (
            <ListGroupItem className="list-group-item-action">
                <Row className="align-items-center justify-content-center">
                    <p className="mb-0 font-weight-500">
                        Aucune message.
                    </p>
                </Row>
            </ListGroupItem>
        );
    }

    return (
        <UncontrolledDropdown nav className={classes.Notifications}>
            <DropdownToggle className="nav-link cursor-pointer" color="" tag="a">
                <span>
                    <i className="ni ni-chat-round" />
                    <Badge color="primary" className={`${classes.notify} badge-md badge-circle badge-floating border-white`}>{nbOfUnseen}</Badge>
                </span>
            </DropdownToggle>
            <DropdownMenu
                className="dropdown-menu-xl py-0 overflow-hidden"
            >
                <div className="px-3 py-3">
                    <h6 className="text-sm text-muted m-0">Messages</h6>
                </div>
                <ListGroup flush>
                    {body}
                </ListGroup>
                <DropdownItem className="text-center text-primary font-weight-bold py-3" to="/chat" tag={Link}>
                    Voir tout les messages
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};

const mapStateToProps = (state) => ({
    chats: state.ChatRecents.chats2,
});

export default connect(mapStateToProps, {
    fetchChats: dbFetchChats2,
})(DefaultNotificationsChats);

DefaultNotificationsChats.propTypes = {
    chats: PropTypes.array,
    fetchChats: PropTypes.func,
};

DefaultNotificationsChats.defaultProps = {
    chats: [],
    fetchChats: () => { },
};
