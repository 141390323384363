import {
    SET_CONTACT_RELATIONSHIP, SET_CONTACTS, SET_CONTACTS_FETCHING, SET_CONTACTS_REQUESTS,
} from './types';

const initialState = {
    isFetching: false,
    contacts: [],
    requests: [],

    relationship: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CONTACTS:
            return { ...state, contacts: action.contacts };

        case SET_CONTACTS_REQUESTS:
            return { ...state, requests: action.requests };

        case SET_CONTACT_RELATIONSHIP:
            return { ...state, relationship: action.relationship };

        case SET_CONTACTS_FETCHING:
            return { ...state, isFetching: action.isFetching };

        default:
            return state;
    }
};

export default reducer;
