import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Alert,
    Button, Card,
    CardBody, CardHeader, Col, Row,
} from 'reactstrap';
import { connect } from 'react-redux';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { ClipLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router';
import { setSignupSuccess as actionsSetSignupSuccess, corporateSignup as authSignup } from '../../store/auth/actions';
import { corporateSignupWithoutOrias } from './LoginFields';
import Aux from '../../hoc/Aux';
import config from '../../config/config';
import SignupCorporateHeader from './SignupCorporateHeader';
import BannerOrange from '../../assets/Pics/Icons/banner_beta_orange.png';
import * as roles from '../../router/Roles';
import GenericForm from '../../components/Ui/GenericForm';
import { CGUV, PDD } from '../../router/Routes';

class SignupCorporateWithoutOrias extends Component {
    constructor(props) {
        super(props);

        this.state = {
            signup: {
                firstName: '',
                lastName: '',
                mail: '',
                password: '',
                phone: '',
            },
            width: 768,
        };

        this.signupRef = React.createRef();
    }

    componentDidMount() {
        this.setState({ width: window.innerWidth });

        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({ width: window.innerWidth });
    };

    formMap = (form, values) => form.map((field) => {
        const newField = { ...field };
        newField.value = values[field.name];
        newField.handleChange = this.handleChange;
        return newField;
    });

    handleChange = (e) => {
        const { name, value } = e.target;

        this.setState((prevState) => ({
            signup: {
                ...prevState.signup,
                [name]: value,
            },
        }));
    };

    handleSubmit = () => {
        const { signup: signupValues } = this.state;
        const { signup } = this.props;

        signup(signupValues.mail, signupValues.password, signupValues.firstName, signupValues.lastName, signupValues.phone, null, [roles.CORPORATE]);
    };

    render() {
        const {
            signup,
            width,
        } = this.state;

        const {
            history,
            isSignupPending,
            isSignupSuccess,
            signupError,
            isSignupAlready,
            setSignupSuccess,
        } = this.props;

        return (
            <Row className={`bg-secondary h-100vh ${width < 768 ? 'bg-gradient-warning' : 'bg-white'} m-0`}>
                <SignupCorporateHeader />
                <Col xs={12} sm={10} md={6} lg={7} className="my-auto mx-auto">
                    <Card className={`shadow-none border-0 col-xs-12 col-md-8 col-xl-6 mx-auto ${width < 768 ? 'bg-white' : 'bg-transparent'}`}>
                        <CardHeader className="bg-transparent border-0">
                            <Row className="mb-4 d-md-none justify-content-center">
                                <Col className="text-center" xs={6} sm={4}>
                                    <Link to="/">
                                        <img src={BannerOrange} alt="Tribu" className="w-100" />
                                    </Link>
                                </Col>
                            </Row>
                        </CardHeader>
                        <Helmet>
                            <title>{`${config.title} - Inscription Corporate`}</title>
                        </Helmet>
                        {
                            isSignupAlready && (
                                <ReactBSAlert
                                    warning
                                    style={{ display: 'block' }}
                                    title="Votre compte n'est pas encore activé !"
                                    onConfirm={() => {
                                        setSignupSuccess(false);
                                        history.push('/');
                                    }}
                                    confirmBtnBsStyle="primary"
                                    confirmBtnText="Retourner à l'accueil"
                                    btnSize=""
                                >
                                    Tribu y travaille.
                                </ReactBSAlert>
                            )
                        }

                        {
                            isSignupSuccess && (
                                <ReactBSAlert
                                    success
                                    style={{ display: 'block' }}
                                    title={`${signup.firstName}, vous venez de créer votre compte !`}
                                    onConfirm={() => {
                                        setSignupSuccess(false);
                                        history.push('/');
                                    }}
                                    confirmBtnBsStyle="primary"
                                    confirmBtnText="Retourner à l'accueil"
                                    btnSize=""
                                >
                                    Tribu vérifie vos informations et revient vers vous rapidement.
                                </ReactBSAlert>
                            )
                        }

                        <CardBody className="px-0">
                            {
                                !isSignupPending ? (
                                    <Aux>
                                        {
                                            signupError && (
                                                <Row>
                                                    <Col>
                                                        <Alert color="danger" className="w-100">
                                                            {signupError}
                                                        </Alert>
                                                    </Col>
                                                </Row>
                                            )
                                        }

                                        <GenericForm
                                            ref={this.signupRef}
                                            onSubmit={this.handleSubmit}
                                            fields={this.formMap(corporateSignupWithoutOrias, signup)}
                                        />

                                        <Row className="d-block text-left">
                                            <Col>
                                                <p style={{ fontSize: '9px' }}>
                                                    Tribu s&apos;engage à respecter vos données. En continuant votre inscription, vous acceptez
                                                    nos
                                                    {' '}
                                                    <Link to={CGUV} className="text-warning"> Conditions Générales</Link>
                                                    .
                                                </p>
                                                <p style={{ fontSize: '9px' }}>
                                                    Tribu peut utiliser ces informations pour une communication personnalisée relative à des
                                                    évènements à venir, des webinars et la plateforme Tribu. Vous pouvez vous désinscrire à tout
                                                    moment.
                                                    <br />
                                                    En savoir plus sur
                                                    <Link to={PDD} className="text-warning"> notre politique de protection des données.</Link>
                                                </p>
                                            </Col>
                                        </Row>

                                        <Row className="justify-content-center mt-3">
                                            <Col>
                                                <Button className="w-100" color="warning" onClick={() => this.signupRef.current.handleSubmit()}>
                                                    INSCRIPTION
                                                </Button>
                                            </Col>
                                        </Row>

                                        <Row className="justify-content-center mt-3">
                                            <span className="d-md-none">
                                            Déjà un compte ?
                                                <Link to="/signin" className="text-warning"> Connectez-vous !</Link>
                                            </span>
                                        </Row>
                                    </Aux>
                                ) : (
                                    <Row className="justify-content-center">
                                        <ClipLoader color="#fb6340" size={100} />
                                    </Row>
                                )
                            }
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );
    }
}

SignupCorporateWithoutOrias.propTypes = {
    isSignupAlready: PropTypes.bool,
    isSignupPending: PropTypes.bool,
    isSignupSuccess: PropTypes.bool,
    setSignupSuccess: PropTypes.func,
    signup: PropTypes.func.isRequired,
    signupError: PropTypes.string,
};

SignupCorporateWithoutOrias.defaultProps = {
    isSignupAlready: false,
    isSignupPending: false,
    isSignupSuccess: false,
    setSignupSuccess: () => {},
    signupError: '',
};

const mapStateToProps = (state) => ({
    isSignupPending: state.Auth.isSignupPending,
    isSignupSuccess: state.Auth.isSignupSuccess,
    isSignupAlready: state.Auth.isSignupAlready,
    signupError: state.Auth.signupError,
});

export default withRouter(connect(mapStateToProps, {
    setSignupSuccess: actionsSetSignupSuccess,
    signup: authSignup,
})(SignupCorporateWithoutOrias));