import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { Card, Col, Row, CardBody, Container } from "reactstrap";
import { useParams } from "react-router-dom";
import PostHeader from "./PostHeader";
import PostContent from "./PostContent";
import PostEngagements from "./PostEngagements";
import Aux from "../../hoc/Aux";
import PropTypes from "prop-types";

import classes from "../../assets/Scss/components/Post.scss";
import { firebaseApp, db } from "../../config/firebase";
import PremiumModal from "../Premium/PremiumModal";
import DefaultNavbar2 from "../../layouts/Default/DefaultNavbar2";
import DashboardHeader from "../../layouts/Default/DashboardHeader";

const Post = (props) => {
  const [isCurrentUserPremium, setIsCurrentUserPremium] = useState(false);
  const [premiumModal, setPremiumModal] = useState(false);
  const userId = firebaseApp.auth().currentUser?.uid;
  const [post, setPost] = useState();
  const [isLoading, setLoading] = useState(true);
  const { postId } = useParams();
  const { postMaxLength } = props;

  const asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  };

  useEffect(() => {
    db.collection("users")
      .doc(userId)
      .get()
      .then((doc) => {
        const premium = doc.get("premium");
        setIsCurrentUserPremium(premium);
      });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await db
        .collection("posts")
        .doc(postId, "==", userId)
        .get()
        .then(async (doc) => {
          if (doc.exists) {
            var data = {
              id: doc.id,
              comments: [],
              userLikes: doc.data().likes && doc.data().likes[userId],
              ...doc.data(),
            };
            if (data.isRepost !== true) setPost(data);
            else {
              const { post, error } = await db
                .collection("posts")
                .doc(data.originalPostId)
                .get()
                .then((doc) => {
                  if (doc.exists)
                    return { post: { ...doc.data(), id: doc.id } };
                  else return { error: true };
                });
              if (post) setPost({ ...data, post });
            }
          }
        });
      setLoading(false);
    };
    fetchData();
  }, []);

  if (isLoading) {
    return (
      <Row className="justify-content-center mt-4">
        <ClipLoader color="#1171ef" />
      </Row>
    );
  }

  if (post === undefined) {
    return (
      <Row className="mb-3">
        <Col>
          <Card>
            <CardBody className="text-center">Aucune publication.</CardBody>
          </Card>
        </Col>
      </Row>
    );
  }

  return (
    <Aux>
      <PremiumModal
        isOpen={premiumModal}
        toggle={() => setPremiumModal(!premiumModal)}
      />
      {userId && (
        <>
          <DefaultNavbar2 allowNotifications={true} fetched={true} />
          <DashboardHeader breadcrumb={false} />
        </>
      )}
      <Container fluid className="posts-container">
        <Row>
          <Col xs={12} lg={3} className="d-none d-lg-block position-fixed">
            <Row>
              <Col xs={12}></Col>
            </Row>
          </Col>
          <Col xs={12} lg={6} className="offset-lg-3">
            <Col>
              <Row className={`${classes.Post} mb-3`}>
                <Col>
                  <Card className="shadow mb-0">
                    <PostHeader
                      post={post}
                      isCurrentUserPremium={isCurrentUserPremium}
                      toggle={() => setPremiumModal(!premiumModal)}
                    />
                    <PostContent post={post} postMaxLength={postMaxLength} />
                    <PostEngagements
                      post={post}
                      isCurrentUserPremium={isCurrentUserPremium}
                    />
                  </Card>
                </Col>
              </Row>
            </Col>
          </Col>
        </Row>
      </Container>
    </Aux>
  );
};

export default Post;

Post.propTypes = {};

Post.defaultProps = {};
