import PropTypes from "prop-types";
import React from "react";
import { ListGroupItem } from "reactstrap";
import Moment from "react-moment";
import { NavLink } from "react-router-dom";
import ChatThreadMenu from "./ChatThreadMenu";
import classes from "../../assets/Scss/components/Chat.scss";
import DefaultProfile from "../../assets/Pics/defaultProfile.jpg";
import { useState, useEffect } from "react";
import { db } from "../../config/firebase";

const ChatRecent = (props) => {
  const { chat } = props;
  const { lastMessage } = chat;
  const { userId } = chat;

  const [profilePic, setProfilePic] = useState();

  useEffect(() => {
    if (userId) {
      db.collection("users")
        .doc(userId)
        .get()
        .then((doc) => {
          if (doc.data()) setProfilePic(doc.data().profilePic);
        });
    }
  }, []);

  return (
    <ListGroupItem
      className={`list-group-item-action flex-column align-items-start p-3 cursor-pointer ${classes.Recent} w-100`}
      style={{ minHeight: 80 }}
      tag={NavLink}
      to={`/chat/${chat.id}`}
      activeClassName="active-conversation-bg"
    >
      <div className="d-flex w-100 justify-content-between align-items-start">
        <img
          alt={chat.name}
          className=" mr-3 rounded-circle"
          style={{ aspectRatio: 1, width: 35 }}
          src={profilePic ? profilePic : DefaultProfile}
        />
        <div className="d-flex justify-content-between align-top w-100">
          <div>
            <h5 className="m-0 text-sm font-weight-medium ">{chat.name}</h5>

            {lastMessage ? (
              <p
                className="text-sm mb-0 mt-1 last-message"
                style={{ fontWeight: chat.seen ? "initial" : "bold" }}
              >
                {lastMessage.message}
              </p>
            ) : (
              <div></div>
            )}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
              justifyContent: "space-between",
            }}
          >
            <small className="d-inline-block">
              {lastMessage ? (
                <Moment locale="fr" toNow ago>
                  {lastMessage.date}
                </Moment>
              ) : (
                <div></div>
              )}
            </small>
            {!chat.seen && (
              <div
                style={{
                  borderRadius: 1000,
                  backgroundColor: "var(--blue)",
                  width: 15,
                  height: 15,
                }}
              />
            )}
          </div>
          <span className={`d-none ml-2 ${classes.dropdown}`}>
            <ChatThreadMenu chat={chat} />
          </span>
        </div>
      </div>
    </ListGroupItem>
  );
};

export default ChatRecent;

ChatRecent.propTypes = {
  chat: PropTypes.object,
};

ChatRecent.defaultProps = {
  chat: {},
};