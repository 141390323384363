import PropTypes from "prop-types";
import React, { useState } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { ClipLoader } from "react-spinners";
import Alert from "reactstrap/es/Alert";
import GenericForm from "../../components/Ui/GenericForm";
import ContactFields from "../Landing/ContactFields";
import Aux from "../../hoc/Aux";
import axios from "axios";
import { PDD } from '../../router/Routes';

const GetInTouchForm = (props) => {
  const [isPending, setIsPending] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [contact, setContact] = useState({
    name: "",
    mail: "",
    phone: "",
    message: "",
  });

  const contactRef = React.createRef();

  /*const sgMail = require("@sendgrid/mail");
  sgMail.setApiKey(
    "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"
  );*/

  const handleChange = (e) => {
    const { name, value } = e.target;

    setContact({
      ...contact,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    const { becomeBroker } = props;
    setIsPending(true);

    /*const message = becomeBroker
      ? {
          to: contact.mail,
          from: "admin@tribu.app",
          bcc: ["admin@tribu.app"],
          templateId: "d-ca95e7f70bdb49d39dbd92960522bed1",
          dynamic_template_data: {
            mail: contact.mail,
            name: contact.name,
            message: contact.message,
            phone: contact.phone,
          },
        }
      : {
          to: contact.mail,
          from: "admin@tribu.app",
          bcc: ["admin@tribu.app"],
          templateId: "d-fddc72f67dd841cd82eb4d900bf9b798",
          dynamic_template_data: {
            mail: contact.mail,
            firstName: contact.firstName,
            lastName: contact.lastName,
            message: contact.message,
            phone: contact.phone,
            company: contact.company,
          },
        };
        const message = {
            to: 'admin@tribu.app',
            from: 'admin@tribu.app',
            subject: 'Sending with SendGrid is Fun',
            text: 'and easy to do anywhere, even with Node.js',
            html: '<strong>and easy to do anywhere, even with Node.js</strong>',
          };
    sgMail
      .send(message)
      .then((response) => {
        alert(response[0].statusCode);
        /*setIsPending(false);
        if (data && data.success) {
          setIsSuccess(true);
        } else if (data && data.error) {
          setError(data.error);
        } else {
          setError("Une erreur s'est produite");
        }
      })
      .catch((error) => {
        alert(error);
      });*/

    const data = {
      mail: contact.mail,
      name: contact.name,
      text: contact.message,
      phone: contact.phone,
    };

    becomeBroker
      ? axios
          .post(
            "https://us-central1-tribu-app.cloudfunctions.net/httpMailBecomeBroker",
            data,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization:
                  "key=AAAAh3iPUF4:APA91bFfpDPkwEXRs7VZVOct9eQ0fmaDNnjQoHBKxYpcCwmxPL9koev3SggjQ5d7mWSTlzpgOs1tTlalSKebQ0_fFy0JPOkFGv4oLe9eAeSkCsGaldYlPqM89vvMT3wbI2du1BdjbZ4D",
              },
            }
          )
          .then((res) => {
            // here will be code
            setIsPending(false);

            if (res.status === 200) {
              setIsSuccess(true);
            } else {
              setError("Une erreur s'est produite");
            }
          })
          .catch()
      : axios
          .post(
            "https://us-central1-tribu-app.cloudfunctions.net/httpMailGetInTouch",
            data,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization:
                  "key=AAAAh3iPUF4:APA91bFfpDPkwEXRs7VZVOct9eQ0fmaDNnjQoHBKxYpcCwmxPL9koev3SggjQ5d7mWSTlzpgOs1tTlalSKebQ0_fFy0JPOkFGv4oLe9eAeSkCsGaldYlPqM89vvMT3wbI2du1BdjbZ4D",
              },
            }
          )
          .then((res) => {
            // here will be code
            setIsPending(false);

            if (res.status === 200) {
              setIsSuccess(true);
            } else {
              setError("Une erreur s'est produite");
            }
          })
          .catch();

    /*const getInTouchMail = firebaseApp
      .functions()
      .httpsCallable(becomeBroker ? "mail_BecomeBroker" : "mail_GetInTouch");
    getInTouchMail({
      mail: contact.mail,
      phone: contact.phone,
      name: contact.name,
      message: contact.message,
    })
      .then((result) => {
        setIsPending(false);
        if (result.data && result.data.success) {
          setIsSuccess(true);
        } else if (result.data && result.data.error) {
          setError(result.data.error);
        } else {
          setError("Une erreur s'est produite");
        }
      })
      .catch((error) => {
        alert(error);
      });*/
  };

  const formMap = (form, values) =>
    form.map((field) => {
      const newField = { ...field };
      newField.value = values[field.name] || "";
      newField.handleChange = handleChange;
      return newField;
    });

  return (
    <Aux>
      {isSuccess && (
        <ReactBSAlert
          success
          title=""
          style={{ display: "block" }}
          onConfirm={() => setIsSuccess(false)}
          confirmBtnBsStyle="success"
          confirmBtnText="OK"
          btnSize=""
        >
          Nous avons bien reçu votre message !
        </ReactBSAlert>
      )}
      <Card className="shadow">
        <CardBody className="p-lg-5">
          {error && <Alert color="danger">{error}</Alert>}
          {!isPending ? (
            <Aux>
              <Row className="d-block">
                <Col>
                  <GenericForm
                    ref={contactRef}
                    fields={formMap(ContactFields, contact)}
                    onSubmit={handleSubmit}
                  />
                  <small>
                    Tribu peut utiliser ces informations pour une communication
                    personnalisée relative à des évènements à venir, des
                    webinars et la plateforme Tribu. Vous pouvez vous
                    désinscrire à tout moment.
                    <br />
                    En savoir plus sur
                    <Link to={PDD} className="text-primary">
                      {" "}
                      notre politique de protection des données.
                    </Link>
                  </small>
                </Col>
              </Row>
              <Row className="mt-3 justify-content-end">
                <Col className="col-auto">
                  <Button
                    color="primary"
                    onClick={() => contactRef.current.handleSubmit()}
                  >
                    ENVOYER
                  </Button>
                </Col>
              </Row>
            </Aux>
          ) : (
            <Row className="py-8 justify-content-center">
              <ClipLoader size={100} color="#5e72e4" />
            </Row>
          )}
        </CardBody>
      </Card>
    </Aux>
  );
};

export default GetInTouchForm;

GetInTouchForm.propTypes = {
  becomeBroker: PropTypes.bool,
};

GetInTouchForm.defaultProps = {
  becomeBroker: false,
};