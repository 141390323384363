// React Core
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// components
import { ClipLoader } from 'react-spinners';

class Loading extends Component {
    render() {
        const {
            size,
            color,
        } = this.props;

        return (
            <ClipLoader size={size} color={color} />
        );
    }
}

export default Loading;

Loading.propTypes = {
    color: PropTypes.string,
    size: PropTypes.number,
};

Loading.defaultProps = {
    color: 'primary',
    size: 100,
};
