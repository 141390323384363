import React, { Component } from "react";
import { Col, Container, Nav, NavItem, NavLink, Row } from "reactstrap";
import { Link } from "react-router-dom";
import classes from "../../assets/Scss/components/Footer.scss";
import {
  CGUV,
  GET_IN_TOUCH,
  HELP_CENTER,
  PDD,
  SITEMAP,
} from "../../router/Routes";
import { firebaseApp, db } from "../../config/firebase";

class DefaultFooter extends Component {
  render() {
    const isLogged = firebaseApp.auth().currentUser?.uid;

    return (
      <footer className={`footer py-3 bg-transparent ${classes.Footer}`}>
        <Container fluid>
          <Row className="align-items-center justify-content-lg-between">
            <Col className="col-lg-auto text-center text-lg-left" xs={12}>
              <div className="copyright text-center text-lg-left text-muted">
                © {new Date().getFullYear()}{" "}
                <Link className="font-weight-bold ml-1 mr-2" to="/">
                  Tribu
                </Link>
                Fabriqué avec ❤️
              </div>
            </Col>
            <Col className="col-lg-auto text-center text-lg-right" xs={12}>
              <Nav className="nav-footer justify-content-center justify-content-lg-end">
                {!isLogged && (
                  <>
                    <NavItem>
                      <NavLink to="/about" tag={Link}>
                        À Propos
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to={HELP_CENTER} tag={Link}>
                        Centre d'assistance
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/devenir-courtier" tag={Link}>
                        Devenir courtier
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to={GET_IN_TOUCH} tag={Link}>
                        Contact
                      </NavLink>
                    </NavItem>
                  </>
                )}
                <NavItem>
                  <NavLink to={CGUV} tag={Link}>
                    CGUV
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to={PDD} tag={Link}>
                    Protection des données
                  </NavLink>
                </NavItem>
                {!isLogged && (
                  <>
                    <NavItem>
                      <NavLink to={SITEMAP} tag={Link}>
                        Plan du site
                      </NavLink>
                    </NavItem>
                  </>
                )}
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}

export default DefaultFooter;
