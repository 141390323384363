import { firebaseApp } from '../../config/firebase';
import * as actionTypes from '../actionConsts';

const initialState = {
    isSigninPending: false,
    isSigninSuccess: false,
    signinError: null,

    isSignupPending: false,
    isSignupSuccess: false,
    isSignupAlready: false,
    signupError: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SIGNIN_PENDING:
            return { ...state, isSigninPending: action.isSigninPending };

        case actionTypes.SET_SIGNIN_SUCCESS:
            return { ...state, isSigninSuccess: action.isSigninSuccess };

        case actionTypes.SET_SIGNIN_ERROR:
            return { ...state, signinError: action.signinError };

        case actionTypes.SET_SIGNUP_PENDING:
            return { ...state, isSignupPending: action.isSignupPending };

        case actionTypes.SET_SIGNUP_SUCCESS:
            return { ...state, isSignupSuccess: action.isSignupSuccess };

        case actionTypes.SET_SIGNUP_ALREADY:
            return { ...state, isSignupAlready: action.isSignupAlready };

        case actionTypes.SET_SIGNUP_ERROR:
            return { ...state, signupError: action.signupError };

        case actionTypes.LOGOUT:
            firebaseApp.auth().signOut();
            return {
                ...state,
                isSigninPending: false,
                isSigninSuccess: false,
                signinError: null,
            };

        default:
            return state;
    }
};

export default reducer;
