import React, { Component } from 'react';
import {
    Button, CardHeader, Col, Row,
} from 'reactstrap';
import { withRouter } from 'react-router';
import Aux from '../../hoc/Aux';
import SearchFiltersCategories from './SearchFiltersCategories';
import SearchFiltersArea from './SearchFiltersArea';
import SearchServicesCIF from './SearchServicesCIF';
import SearchServicesIOBSP from './SearchServicesIOBSP';
import SearchServicesIAS from './SearchServicesIAS';

class SearchFilters2 extends Component {
    constructor(props) {
        super(props);

        const { location } = props;
        const params = new URLSearchParams(location.search);

        this.state = {
            selectedCategories: params.get('c') ? params.get('c').split(' ') : [],
            selectedServices: params.get('s') ? params.get('s').split(' ') : [],
        };
    }

    map = {
        IOBSP: ['Crédit Immobilier', "Rachat de crédits", "Rachat de crédits consommation", "Rachat de crédits consommation et immobilier", "Crédit professionnel", "Prêt professionnel", "Crédit bail immobilier", "Crédit bail mobilier", "Location financière", "Location longue durée", "Affacturage", "Assurance crédit"],
        IAS: ["Epargne", "Assurance-vie", "Contrat de capitalisation", "Assurance famille", 'Garantie des accidents de la vie', 'Protection juridique', 'Assurance décès', 'Assurance scolaire', 'Assurance emprunteur', 'Garantie obsèques', 'Assurance animaux', 'Assurance dépendance', 'Assurance rapatriement', 'Assurance habitation', 'Assurance locataire', 'Propriétaire non-occupant', 'Assurance loisirs', 'Assurance plaisance', 'Assurance voyage', 'Assurance chasse', 'Assurance sport', 'Assurance santé', 'Complémentaire santé', 'Sur-complémentaire', 'Assurance auto', 'Assurance 2 roues', 'Assurance voiture sans permis', 'Assurance quad', 'Assurance camping-car', 'Assurance malussé', "Assurance du chef d'entreprise", 'Prévoyance pro', 'Santé pro', 'Retraite pro', 'Perte d’emploi', 'Loi Madelin', 'Responsabilité civile des mandataires sociaux', 'Assurance homme-clé', 'Assurance construction', 'Dommage-ouvrage', 'Tous risques chantier', 'Constructeur non réalisateur', 'Responsabilité civile décennale', 'Garantie financière d’achèvement', 'Responsabilité civile promoteur', 'Multirisque professionnelle', 'Bris de machine', 'Dommage aux biens', 'Locaux professionnels', 'Responsabilité civile d’exploitation', 'Protection juridique pro', 'Perte d’exploitation', 'Risques informatiques', 'Responsabilité civile professionnelle', 'RC Pro Agriculteur', 'RC Pro Artisan', 'RC Pro Association', 'RC Pro Auto-entrepreneur', 'RC Pro Commerçant', 'RC Pro Profession libérale', 'Assurance des salariés', 'Prévoyance collective', 'Epargne salariale', 'Santé collective', 'Retraite collective', 'Assurance des véhicules', 'Flotte automobile', 'Transport de marchandises', 'Transport de voyageurs', 'Flotte motocycle', 'Assurance du véhicule'],
        CIF: ['Compte-titres pro', 'Compte à terme pro', 'PEE', 'PERCO', 'Article 83', 'Girardin industriel', 'PEA', 'Compte-titres', 'Compte à terme', 'FIP', 'FCPI', 'SCPI', 'OPCI']
    }

    componentDidMount() {
        const { location } = this.props;
        const params = new URLSearchParams(location.search);
        const search = params.get('q') || '';

        if (search.toLowerCase() === 'iobsp') this.setState({ selectedCategories: ['IOBSP'] })
        if (search.toLowerCase() === 'ias') this.setState({ selectedCategories: ['IAS'] })
        if (search.toLowerCase() === 'cif') this.setState({ selectedCategories: ['CIF'] })

        let serviceID = ""
        this.servicesObj.forEach((obj) => {
            if (obj.text === search) {
                serviceID = obj.id
            }
        })
        if (serviceID === "rachat_de_credits") {
            this.setState({ selectedServices: [serviceID, "rachat_de_credits_consommation", "rachat_de_credits_consommation_et_immobilier"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "rachat_de_credits_consommation") {
            this.setState({ selectedServices: [serviceID, "rachat_de_credits"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "rachat_de_credits_consommation_et_immobilier") {
            this.setState({ selectedServices: [serviceID, "rachat_de_credits"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "credit_professionnel") {
            this.setState({ selectedServices: [serviceID, "pret_professionnel", "credit_bail_immobilier", "credit_bail_mobilier", "location_financiere", "location_longue_duree", "affacturage", "assurance_credit"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "pret_professionnel") {
            this.setState({ selectedServices: [serviceID, "credit_professionnel"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "credit_bail_immobilier") {
            this.setState({ selectedServices: [serviceID, "credit_professionnel"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "credit_bail_mobilier") {
            this.setState({ selectedServices: [serviceID, "credit_professionnel"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "location_financiere") {
            this.setState({ selectedServices: [serviceID, "credit_professionnel"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "location_longue_duree") {
            this.setState({ selectedServices: [serviceID, "credit_professionnel"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "affacturage") {
            this.setState({ selectedServices: [serviceID, "credit_professionnel"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "assurance_credit") {
            this.setState({ selectedServices: [serviceID, "credit_professionnel"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "epargne") {
            this.setState({ selectedServices: [serviceID, "assurance-vie", "contrat_de_capitalisation"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "assurance-vie") {
            this.setState({ selectedServices: [serviceID, "epargne"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "contrat_de_capitalisation") {
            this.setState({ selectedServices: [serviceID, "epargne"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "assurance_famille") {
            this.setState({ selectedServices: [serviceID, "garantie_des_accidents_de_la_vie", "protection_juridique", "assurance_deces", "assurance_scolaire", "assurance_emprunteur", "garantie_obseques", "assurance_animaux", "assurance_dependance", "assurance_rapatriement"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "garantie_des_accidents_de_la_vie") {
            this.setState({ selectedServices: [serviceID, "assurance_famille"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "protection_juridique") {
            this.setState({ selectedServices: [serviceID, "assurance_famille"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "assurance_deces") {
            this.setState({ selectedServices: [serviceID, "assurance_famille"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "assurance_scolaire") {
            this.setState({ selectedServices: [serviceID, "assurance_famille"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "assurance_emprunteur") {
            this.setState({ selectedServices: [serviceID, "assurance_famille"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "garantie_obseques") {
            this.setState({ selectedServices: [serviceID, "assurance_famille"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "assurance_animaux") {
            this.setState({ selectedServices: [serviceID, "assurance_famille"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "assurance_dependance") {
            this.setState({ selectedServices: [serviceID, "assurance_famille"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "assurance_rapatriement") {
            this.setState({ selectedServices: [serviceID, "assurance_famille"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "assurance_habitation") {
            this.setState({ selectedServices: [serviceID, "assurance_locataire", "propriétaire_non-occupant"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "assurance_locataire") {
            this.setState({ selectedServices: [serviceID, "assurance_habitation"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "propriétaire_non-occupant") {
            this.setState({ selectedServices: [serviceID, "assurance_habitation"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "assurance_loisirs") {
            this.setState({ selectedServices: [serviceID, "assurance_plaisance", "assurance_voyage", "assurance_chasse", "assurance_sport"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "assurance_plaisance") {
            this.setState({ selectedServices: [serviceID, "assurance_loisirs"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "assurance_voyage") {
            this.setState({ selectedServices: [serviceID, "assurance_loisirs"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "assurance_chasse") {
            this.setState({ selectedServices: [serviceID, "assurance_loisirs"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "assurance_sport") {
            this.setState({ selectedServices: [serviceID, "assurance_loisirs"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "assurance_sante") {
            this.setState({ selectedServices: [serviceID, "complementaire_sante", "sur-complementaire"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "complementaire_sante") {
            this.setState({ selectedServices: [serviceID, "assurance_sante"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "sur-complementaire") {
            this.setState({ selectedServices: [serviceID, "assurance_sante"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "assurance_du_vehicule") {
            this.setState({ selectedServices: [serviceID, "assurance_auto", "assurance_2_roues", "assurance_voiture_sans_permis", "assurance_quad", "assurance_camping-car", "assurance_malusse"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "assurance_auto") {
            this.setState({ selectedServices: [serviceID, "assurance_du_vehicule"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "assurance_2_roues") {
            this.setState({ selectedServices: [serviceID, "assurance_du_vehicule"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "assurance_voiture_sans_permis") {
            this.setState({ selectedServices: [serviceID, "assurance_du_vehicule"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "assurance_quad") {
            this.setState({ selectedServices: [serviceID, "assurance_du_vehicule"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "assurance_camping-car") {
            this.setState({ selectedServices: [serviceID, "assurance_du_vehicule"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "assurance_malusse") {
            this.setState({ selectedServices: [serviceID, "assurance_du_vehicule"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "assurance_du_chef_d_entreprise") {
            this.setState({ selectedServices: [serviceID, "prevoyance_pro", "sante_pro", "retraite_pro", "perte_d_emploi", "loi_madelin", "responsabilite_civile_des_mandataires_sociaux", "assurance_homme-cle"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "prevoyance_pro") {
            this.setState({ selectedServices: [serviceID, "assurance_du_chef_d_entreprise"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "sante_pro") {
            this.setState({ selectedServices: [serviceID, "assurance_du_chef_d_entreprise"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "retraite_pro") {
            this.setState({ selectedServices: [serviceID, "assurance_du_chef_d_entreprise"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "perte_d_emploi") {
            this.setState({ selectedServices: [serviceID, "assurance_du_chef_d_entreprise"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "loi_madelin") {
            this.setState({ selectedServices: [serviceID, "assurance_du_chef_d_entreprise"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "responsabilite_civile_des_mandataires_sociaux") {
            this.setState({ selectedServices: [serviceID, "assurance_du_chef_d_entreprise"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "assurance_homme-cle") {
            this.setState({ selectedServices: [serviceID, "assurance_du_chef_d_entreprise"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "assurance_construction") {
            this.setState({ selectedServices: [serviceID, "dommage-ouvrage", "tous_risques_chantier", "constructeur_non_realisateur", "responsabilite_civile_decennale", "garantie_financiere_d_achevement", "responsabilite_civile_promoteur"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "dommage-ouvrage") {
            this.setState({ selectedServices: [serviceID, "assurance_construction"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "tous_risques_chantier") {
            this.setState({ selectedServices: [serviceID, "assurance_construction"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "constructeur_non_realisateur") {
            this.setState({ selectedServices: [serviceID, "assurance_construction"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "responsabilite_civile_decennale") {
            this.setState({ selectedServices: [serviceID, "assurance_construction"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "garantie_financiere_d_achevement") {
            this.setState({ selectedServices: [serviceID, "assurance_construction"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "responsabilite_civile_promoteur") {
            this.setState({ selectedServices: [serviceID, "assurance_construction"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "multirisque_professionnelle") {
            this.setState({ selectedServices: [serviceID, "bris_de_machine", "dommage_aux_biens", "locaux_professionnels", "responsabilite_civile_d_exploitation", "protection_juridique_pro", "perte_d_exploitation", "risques_informatiques"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "bris_de_machine") {
            this.setState({ selectedServices: [serviceID, "multirisque_professionnelle"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "dommage_aux_biens") {
            this.setState({ selectedServices: [serviceID, "multirisque_professionnelle"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "locaux_professionnels") {
            this.setState({ selectedServices: [serviceID, "multirisque_professionnelle"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "responsabilite_civile_d_exploitation") {
            this.setState({ selectedServices: [serviceID, "multirisque_professionnelle"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "protection_juridique_pro") {
            this.setState({ selectedServices: [serviceID, "multirisque_professionnelle"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "perte_d_exploitation") {
            this.setState({ selectedServices: [serviceID, "multirisque_professionnelle"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "risques_informatiques") {
            this.setState({ selectedServices: [serviceID, "multirisque_professionnelle"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "responsabilite_civile_professionnelle") {
            this.setState({ selectedServices: [serviceID, "rc_pro_agriculteur", "rc_pro_artisan", "rc_pro_association", "rc_pro_auto-entrepreneur", "rc_pro_commercant", "rc_pro_profession_liberale"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "rc_pro_agriculteur") {
            this.setState({ selectedServices: [serviceID, "responsabilite_civile_professionnelle"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "rc_pro_artisan") {
            this.setState({ selectedServices: [serviceID, "responsabilite_civile_professionnelle"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "rc_pro_association") {
            this.setState({ selectedServices: [serviceID, "responsabilite_civile_professionnelle"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "rc_pro_auto-entrepreneur") {
            this.setState({ selectedServices: [serviceID, "responsabilite_civile_professionnelle"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "rc_pro_commercant") {
            this.setState({ selectedServices: [serviceID, "responsabilite_civile_professionnelle"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "rc_pro_profession_liberale") {
            this.setState({ selectedServices: [serviceID, "responsabilite_civile_professionnelle"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "assurance_des_salaries") {
            this.setState({ selectedServices: [serviceID, "prevoyance_collective", "retraite_collective", "sante_collective", "epargne_salariale"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "prevoyance_collective") {
            this.setState({ selectedServices: [serviceID, "assurance_des_salaries"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "retraite_collective") {
            this.setState({ selectedServices: [serviceID, "assurance_des_salaries"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "sante_collective") {
            this.setState({ selectedServices: [serviceID, "assurance_des_salaries"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "epargne_salariale") {
            this.setState({ selectedServices: [serviceID, "assurance_des_salaries"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "assurance_des_vehicules") {
            this.setState({ selectedServices: [serviceID, "flotte_automobile", "transport_de_marchandises", "transport_de_voyageurs", "flotte_motocycle"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "flotte_automobile") {
            this.setState({ selectedServices: [serviceID, "assurance_des_vehicules"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "transport_de_marchandises") {
            this.setState({ selectedServices: [serviceID, "assurance_des_vehicules"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "transport_de_voyageurs") {
            this.setState({ selectedServices: [serviceID, "assurance_des_vehicules"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID === "flotte_motocycle") {
            this.setState({ selectedServices: [serviceID, "assurance_des_vehicules"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
        else if (serviceID !== "") {
            this.setState({ selectedServices: [serviceID], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
        }
    }

    componentDidUpdate(prevProps) {
        const { location: prevLocation } = prevProps;
        const { location } = this.props;

        if (prevLocation.search !== location.search) {
            const params = new URLSearchParams(location.search);
            const search = params.get('q') || '';

            if (search.toLowerCase() === 'iobsp') this.setState({ selectedCategories: ['IOBSP'] })
            if (search.toLowerCase() === 'ias') this.setState({ selectedCategories: ['IAS'] })
            if (search.toLowerCase() === 'cif') this.setState({ selectedCategories: ['CIF'] })

            let serviceID = ""
            this.servicesObj.forEach((obj) => {
                if (obj.text === search) {
                    serviceID = obj.id
                }
            })
            if (serviceID === "rachat_de_credits") {
                this.setState({ selectedServices: [serviceID, "rachat_de_credits_consommation", "rachat_de_credits_consommation_et_immobilier"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "rachat_de_credits_consommation") {
                this.setState({ selectedServices: [serviceID, "rachat_de_credits"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "rachat_de_credits_consommation_et_immobilier") {
                this.setState({ selectedServices: [serviceID, "rachat_de_credits"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "credit_professionnel") {
                this.setState({ selectedServices: [serviceID, "pret_professionnel", "credit_bail_immobilier", "credit_bail_mobilier", "location_financiere", "location_longue_duree", "affacturage", "assurance_credit"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "pret_professionnel") {
                this.setState({ selectedServices: [serviceID, "credit_professionnel"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "credit_bail_immobilier") {
                this.setState({ selectedServices: [serviceID, "credit_professionnel"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "credit_bail_mobilier") {
                this.setState({ selectedServices: [serviceID, "credit_professionnel"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "location_financiere") {
                this.setState({ selectedServices: [serviceID, "credit_professionnel"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "location_longue_duree") {
                this.setState({ selectedServices: [serviceID, "credit_professionnel"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "affacturage") {
                this.setState({ selectedServices: [serviceID, "credit_professionnel"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "assurance_credit") {
                this.setState({ selectedServices: [serviceID, "credit_professionnel"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "epargne") {
                this.setState({ selectedServices: [serviceID, "assurance-vie", "contrat_de_capitalisation"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "assurance-vie") {
                this.setState({ selectedServices: [serviceID, "epargne"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "contrat_de_capitalisation") {
                this.setState({ selectedServices: [serviceID, "epargne"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "assurance_famille") {
                this.setState({ selectedServices: [serviceID, "garantie_des_accidents_de_la_vie", "protection_juridique", "assurance_deces", "assurance_scolaire", "assurance_emprunteur", "garantie_obseques", "assurance_animaux", "assurance_dependance", "assurance_rapatriement"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "garantie_des_accidents_de_la_vie") {
                this.setState({ selectedServices: [serviceID, "assurance_famille"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "protection_juridique") {
                this.setState({ selectedServices: [serviceID, "assurance_famille"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "assurance_deces") {
                this.setState({ selectedServices: [serviceID, "assurance_famille"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "assurance_scolaire") {
                this.setState({ selectedServices: [serviceID, "assurance_famille"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "assurance_emprunteur") {
                this.setState({ selectedServices: [serviceID, "assurance_famille"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "garantie_obseques") {
                this.setState({ selectedServices: [serviceID, "assurance_famille"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "assurance_animaux") {
                this.setState({ selectedServices: [serviceID, "assurance_famille"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "assurance_dependance") {
                this.setState({ selectedServices: [serviceID, "assurance_famille"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "assurance_rapatriement") {
                this.setState({ selectedServices: [serviceID, "assurance_famille"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "assurance_habitation") {
                this.setState({ selectedServices: [serviceID, "assurance_locataire", "propriétaire_non-occupant"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "assurance_locataire") {
                this.setState({ selectedServices: [serviceID, "assurance_habitation"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "propriétaire_non-occupant") {
                this.setState({ selectedServices: [serviceID, "assurance_habitation"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "assurance_loisirs") {
                this.setState({ selectedServices: [serviceID, "assurance_plaisance", "assurance_voyage", "assurance_chasse", "assurance_sport"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "assurance_plaisance") {
                this.setState({ selectedServices: [serviceID, "assurance_loisirs"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "assurance_voyage") {
                this.setState({ selectedServices: [serviceID, "assurance_loisirs"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "assurance_chasse") {
                this.setState({ selectedServices: [serviceID, "assurance_loisirs"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "assurance_sport") {
                this.setState({ selectedServices: [serviceID, "assurance_loisirs"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "assurance_sante") {
                this.setState({ selectedServices: [serviceID, "complementaire_sante", "sur-complementaire"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "complementaire_sante") {
                this.setState({ selectedServices: [serviceID, "assurance_sante"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "sur-complementaire") {
                this.setState({ selectedServices: [serviceID, "assurance_sante"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "assurance_du_vehicule") {
                this.setState({ selectedServices: [serviceID, "assurance_auto", "assurance_2_roues", "assurance_voiture_sans_permis", "assurance_quad", "assurance_camping-car", "assurance_malusse"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "assurance_auto") {
                this.setState({ selectedServices: [serviceID, "assurance_du_vehicule"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "assurance_2_roues") {
                this.setState({ selectedServices: [serviceID, "assurance_du_vehicule"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "assurance_voiture_sans_permis") {
                this.setState({ selectedServices: [serviceID, "assurance_du_vehicule"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "assurance_quad") {
                this.setState({ selectedServices: [serviceID, "assurance_du_vehicule"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "assurance_camping-car") {
                this.setState({ selectedServices: [serviceID, "assurance_du_vehicule"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "assurance_malusse") {
                this.setState({ selectedServices: [serviceID, "assurance_du_vehicule"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "assurance_du_chef_d_entreprise") {
                this.setState({ selectedServices: [serviceID, "prevoyance_pro", "sante_pro", "retraite_pro", "perte_d_emploi", "loi_madelin", "responsabilite_civile_des_mandataires_sociaux", "assurance_homme-cle"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "prevoyance_pro") {
                this.setState({ selectedServices: [serviceID, "assurance_du_chef_d_entreprise"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "sante_pro") {
                this.setState({ selectedServices: [serviceID, "assurance_du_chef_d_entreprise"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "retraite_pro") {
                this.setState({ selectedServices: [serviceID, "assurance_du_chef_d_entreprise"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "perte_d_emploi") {
                this.setState({ selectedServices: [serviceID, "assurance_du_chef_d_entreprise"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "loi_madelin") {
                this.setState({ selectedServices: [serviceID, "assurance_du_chef_d_entreprise"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "responsabilite_civile_des_mandataires_sociaux") {
                this.setState({ selectedServices: [serviceID, "assurance_du_chef_d_entreprise"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "assurance_homme-cle") {
                this.setState({ selectedServices: [serviceID, "assurance_du_chef_d_entreprise"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "assurance_construction") {
                this.setState({ selectedServices: [serviceID, "dommage-ouvrage", "tous_risques_chantier", "constructeur_non_realisateur", "responsabilite_civile_decennale", "garantie_financiere_d_achevement", "responsabilite_civile_promoteur"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "dommage-ouvrage") {
                this.setState({ selectedServices: [serviceID, "assurance_construction"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "tous_risques_chantier") {
                this.setState({ selectedServices: [serviceID, "assurance_construction"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "constructeur_non_realisateur") {
                this.setState({ selectedServices: [serviceID, "assurance_construction"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "responsabilite_civile_decennale") {
                this.setState({ selectedServices: [serviceID, "assurance_construction"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "garantie_financiere_d_achevement") {
                this.setState({ selectedServices: [serviceID, "assurance_construction"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "responsabilite_civile_promoteur") {
                this.setState({ selectedServices: [serviceID, "assurance_construction"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "multirisque_professionnelle") {
                this.setState({ selectedServices: [serviceID, "bris_de_machine", "dommage_aux_biens", "locaux_professionnels", "responsabilite_civile_d_exploitation", "protection_juridique_pro", "perte_d_exploitation", "risques_informatiques"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "bris_de_machine") {
                this.setState({ selectedServices: [serviceID, "multirisque_professionnelle"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "dommage_aux_biens") {
                this.setState({ selectedServices: [serviceID, "multirisque_professionnelle"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "locaux_professionnels") {
                this.setState({ selectedServices: [serviceID, "multirisque_professionnelle"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "responsabilite_civile_d_exploitation") {
                this.setState({ selectedServices: [serviceID, "multirisque_professionnelle"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "protection_juridique_pro") {
                this.setState({ selectedServices: [serviceID, "multirisque_professionnelle"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "perte_d_exploitation") {
                this.setState({ selectedServices: [serviceID, "multirisque_professionnelle"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "risques_informatiques") {
                this.setState({ selectedServices: [serviceID, "multirisque_professionnelle"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "responsabilite_civile_professionnelle") {
                this.setState({ selectedServices: [serviceID, "rc_pro_agriculteur", "rc_pro_artisan", "rc_pro_association", "rc_pro_auto-entrepreneur", "rc_pro_commercant", "rc_pro_profession_liberale"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "rc_pro_agriculteur") {
                this.setState({ selectedServices: [serviceID, "responsabilite_civile_professionnelle"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "rc_pro_artisan") {
                this.setState({ selectedServices: [serviceID, "responsabilite_civile_professionnelle"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "rc_pro_association") {
                this.setState({ selectedServices: [serviceID, "responsabilite_civile_professionnelle"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "rc_pro_auto-entrepreneur") {
                this.setState({ selectedServices: [serviceID, "responsabilite_civile_professionnelle"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "rc_pro_commercant") {
                this.setState({ selectedServices: [serviceID, "responsabilite_civile_professionnelle"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "rc_pro_profession_liberale") {
                this.setState({ selectedServices: [serviceID, "responsabilite_civile_professionnelle"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "assurance_des_salaries") {
                this.setState({ selectedServices: [serviceID, "prevoyance_collective", "retraite_collective", "sante_collective", "epargne_salariale"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "prevoyance_collective") {
                this.setState({ selectedServices: [serviceID, "assurance_des_salaries"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "retraite_collective") {
                this.setState({ selectedServices: [serviceID, "assurance_des_salaries"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "sante_collective") {
                this.setState({ selectedServices: [serviceID, "assurance_des_salaries"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "epargne_salariale") {
                this.setState({ selectedServices: [serviceID, "assurance_des_salaries"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "assurance_des_vehicules") {
                this.setState({ selectedServices: [serviceID, "flotte_automobile", "transport_de_marchandises", "transport_de_voyageurs", "flotte_motocycle"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "flotte_automobile") {
                this.setState({ selectedServices: [serviceID, "assurance_des_vehicules"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "transport_de_marchandises") {
                this.setState({ selectedServices: [serviceID, "assurance_des_vehicules"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "transport_de_voyageurs") {
                this.setState({ selectedServices: [serviceID, "assurance_des_vehicules"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID === "flotte_motocycle") {
                this.setState({ selectedServices: [serviceID, "assurance_des_vehicules"], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
            else if (serviceID !== "") {
                this.setState({ selectedServices: [serviceID], selectedCategories: [Object.keys(this.map).find(key => this.map[key].includes(search))] })
            }
        }
    }

    servicesObj = [
        {
            id: 'assurance-vie',
            text: 'Assurance-vie',
        },
        {
            id: 'contrat_de_capitalisation',
            text: 'Contrat de capitalisation',
        },
        {
            id: 'rachat_de_credits_consommation',
            text: 'Rachat de crédits consommation',
        },
        {
            id: 'rachat_de_credits_consommation_et_immobilier',
            text: 'Rachat de crédits consommation et immobilier',
        },
        {
            id: 'credit_professionnel',
            text: 'Crédit professionnel',
        },
        {
            id: 'credit_bail_immobilier',
            text: 'Crédit bail immobilier',
        },
        {
            id: 'credit_bail_mobilier',
            text: 'Crédit bail mobilier',
        },
        {
            id: 'location_financiere',
            text: 'Location financière',
        },
        {
            id: 'location_longue_duree',
            text: 'Location longue durée',
        },
        {
            id: 'affacturage',
            text: 'Affacturage',
        },
        {
            id: 'assurance_credit',
            text: 'Assurance crédit',
        },
        {
            id: 'compte-titres',
            text: 'Compte-titres',
        },
        {
            id: 'compte_a_terme',
            text: 'Compte à terme',
        },
        {
            id: 'compte-titres_pro',
            text: 'Compte-titres pro',
        },
        {
            id: 'compte_a_terme_pro',
            text: 'Compte à terme pro',
        },
        {
            id: 'pee',
            text: 'PEE',
        },
        {
            id: 'perco',
            text: 'PERCO',
        },
        {
            id: 'article_83',
            text: 'Article 83',
        },
        {
            id: 'girardin_industriel',
            text: 'Girardin industriel',
        },
        {
            id: 'pea',
            text: 'PEA',
        },
        {
            id: 'fip',
            text: 'FIP',
        },
        {
            id: 'fcpi',
            text: 'FCPI',
        },
        {
            id: 'scpi',
            text: 'SCPI',
        },
        {
            id: 'opci',
            text: 'OPCI',
        },
        {
            id: 'garantie_des_accidents_de_la_vie',
            text: 'Garantie des accidents de la vie',
        },
        {
            id: 'protection_juridique',
            text: 'Protection juridique',
        },
        {
            id: 'garantie_obseques',
            text: 'Garantie obsèques',
        },
        {
            id: 'assurance_animaux',
            text: 'Assurance animaux',
        },
        {
            id: 'assurance_dependance',
            text: 'Assurance dépendance',
        },
        {
            id: 'sante_collective',
            text: 'Santé collective',
        },
        {
            id: 'prevoyance_collective',
            text: 'Prévoyance collective',
        },
        {
            id: 'retraite_collective',
            text: 'Retraite collective',
        },
        {
            id: 'assurance_rapatriement',
            text: 'Assurance rapatriement',
        },
        {
            id: 'assurance_locataire',
            text: 'Assurance locataire',
        },
        {
            id: 'propriétaire_non-occupant',
            text: 'Propriétaire non-occupant',
        },
        {
            id: 'assurance_plaisance',
            text: 'Assurance plaisance',
        },
        {
            id: 'assurance_voyage',
            text: 'Assurance voyage',
        },
        {
            id: 'assurance_chasse',
            text: 'Assurance chasse',
        },
        {
            id: 'assurance_sport',
            text: 'Assurance sport',
        },
        {
            id: 'complementaire_sante',
            text: 'Complémentaire santé',
        },
        {
            id: 'sur-complementaire',
            text: 'Sur-complémentaire',
        },
        {
            id: 'assurance_auto',
            text: 'Assurance auto',
        },
        {
            id: 'assurance_2_roues',
            text: 'Assurance 2 roues',
        },
        {
            id: 'assurance_voiture_sans_permis',
            text: 'Assurance voiture sans permis',
        },
        {
            id: 'assurance_quad',
            text: 'Assurance quad',
        },
        {
            id: 'assurance_camping-car',
            text: 'Assurance camping-car',
        },
        {
            id: 'assurance_malusse',
            text: 'Assurance malussé',
        },
        {
            id: 'prevoyance_pro',
            text: 'Prévoyance pro',
        },
        {
            id: 'sante_pro',
            text: 'Santé pro',
        },
        {
            id: 'retraite_pro',
            text: 'Retraite pro',
        },
        {
            id: 'perte_d_emploi',
            text: 'Perte d’emploi',
        },
        {
            id: 'loi_madelin',
            text: 'Loi Madelin',
        },
        {
            id: 'responsabilite_civile_des_mandataires_sociaux',
            text: 'Responsabilité civile des mandataires sociaux',
        },
        {
            id: 'assurance_homme-cle',
            text: 'Assurance homme-clé',
        },
        {
            id: 'dommage-ouvrage',
            text: 'Dommage-ouvrage',
        },
        {
            id: 'tous_risques_chantier',
            text: 'Tous risques chantier',
        },
        {
            id: 'constructeur_non_realisateur',
            text: 'Constructeur non réalisateur',
        },
        {
            id: 'responsabilite_civile_decennale',
            text: 'Responsabilité civile décennale',
        },
        {
            id: 'garantie_financiere_d_achevement',
            text: 'Garantie financière d’achèvement',
        },
        {
            id: 'responsabilite_civile_promoteur',
            text: 'Responsabilité civile promoteur',
        },
        {
            id: 'bris_de_machine',
            text: 'Bris de machine',
        },
        {
            id: 'dommage_aux_biens',
            text: 'Dommage aux biens',
        },
        {
            id: 'locaux_professionnels',
            text: 'Locaux professionnels',
        },
        {
            id: 'responsabilite_civile_d_exploitation',
            text: 'Responsabilité civile d’exploitation',
        },
        {
            id: 'protection_juridique_pro',
            text: 'Protection juridique pro',
        },
        {
            id: 'perte_d_exploitation',
            text: 'Perte d’exploitation',
        },
        {
            id: 'risques_informatiques',
            text: 'Risques informatiques',
        },
        {
            id: 'rc_pro_agriculteur',
            text: 'RC Pro Agriculteur',
        },
        {
            id: 'rc_pro_artisan',
            text: 'RC Pro Artisan',
        },
        {
            id: 'rc_pro_association',
            text: 'RC Pro Association',
        },
        {
            id: 'rc_pro_auto-entrepreneur',
            text: 'RC Pro Auto-entrepreneur',
        },
        {
            id: 'rc_pro_commercant',
            text: 'RC Pro Commerçant',
        },
        {
            id: 'rc_pro_profession_liberale',
            text: 'RC Pro Profession libérale',
        },
        {
            id: 'prevoyance',
            text: 'Prévoyance',
        },
        {
            id: 'epargne_salariale',
            text: 'Epargne salariale',
        },
        {
            id: 'sante',
            text: 'Santé',
        },
        {
            id: 'retraite',
            text: 'Retraite',
        },
        {
            id: 'flotte_automobile',
            text: 'Flotte automobile',
        },
        {
            id: 'transport_de_marchandises',
            text: 'Transport de marchandises',
        },
        {
            id: 'transport_de_voyageurs',
            text: 'Transport de voyageurs',
        },
        {
            id: 'flotte_motocycle',
            text: 'Flotte motocycle',
        },
        {
            id: 'credit_immobilier',
            text: 'Crédit Immobilier',
        },
        {
            id: 'rachat_de_credits',
            text: 'Rachat de crédits',
        },
        {
            id: 'credit_professionnel',
            text: 'Crédit professionnel',
        },
        {
            id: 'epargne',
            text: 'Epargne',
        },
        {
            id: 'assurance_famille',
            text: 'Assurance famille',
        },
        {
            id: 'assurance_deces',
            text: 'Assurance décès',
        },
        {
            id: 'assurance_scolaire',
            text: 'Assurance scolaire',
        },
        {
            id: 'assurance_emprunteur',
            text: 'Assurance emprunteur',
        },
        {
            id: 'assurance_habitation',
            text: 'Assurance habitation',
        },
        {
            id: 'assurance_loisirs',
            text: 'Assurance loisirs',
        },
        {
            id: 'assurance_sante',
            text: 'Assurance santé',
        },
        {
            id: 'assurance_des_vehicules',
            text: 'Assurance des véhicules',
        },
        {
            id: 'assurance_du_chef_d_entreprise',
            text: "Assurance du chef d'entreprise",
        },
        {
            id: 'assurance_construction',
            text: "Assurance construction",
        },
        {
            id: 'multirisque_professionnelle',
            text: "Multirisque professionnelle",
        },
        {
            id: 'responsabilite_civile_professionnelle',
            text: "Responsabilité civile professionnelle",
        },
        {
            id: 'assurance_des_salaries',
            text: "Assurance des salariés",
        },
        {
            id: 'assurance_du_vehicule',
            text: "Assurance du véhicule",
        },
        {
            id: 'pret_professionnel',
            text: "Prêt professionnel",
        },
    ];

    componentWillMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            const params = new URLSearchParams(location.search);
            if (params.get('c') !== null) {
                this.setState(prevState => ({
                    selectedCategories: params.get('c').split("+")
                }))
            }
            if (params.get('s') !== null) {
                this.setState(prevState => ({
                    selectedServices: params.get('s').split("+")
                }))
            }
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    handleResetFilters = () => {
        this.setState({ selectedCategories: [], selectedServices: [] });
        const { history, location } = this.props;
        const params = new URLSearchParams(location.search);
        params.delete('c');
        params.delete('s');
        params.delete('l');
        history.push({
            pathname: '/search',
            search: params.toString(),
        });
    };

    handleSelectLocalisation = (localisation) => {
        const { history, location } = this.props;

        const params = new URLSearchParams(location.search);
        params.delete('l');
        params.append('l', localisation);

        history.push({
            pathname: '/search',
            search: params.toString(),
        });
    };

    handleSelectCategory = (e) => {
        const { selectedCategories } = this.state;
        const { history, location } = this.props;
        const { checked, name } = e.target;
        const newSelection = [...selectedCategories];

        const params = new URLSearchParams(location.search);
        params.delete('c');
        const index = newSelection.indexOf(name);

        if (index === -1 && checked) {
            newSelection.push(name);
            params.append('c', newSelection.join(' '));
        }

        if (index > -1 && !checked) {
            newSelection.splice(index, 1);
            if (newSelection.length) {
                params.append('c', newSelection.join(' '));
            }
        }

        history.push({
            pathname: '/search',
            search: params.toString(),
        });

        this.setState({ selectedCategories: newSelection });
    };

    handleSelectService = (e) => {
        const { selectedServices } = this.state;
        const { history, location } = this.props;
        const { checked, name } = e.target;
        const newSelection = [...selectedServices];

        const params = new URLSearchParams(location.search);
        params.delete('s');

        let serviceID = ""
        this.servicesObj.forEach((obj) => {
            if (obj.text === name) {
                serviceID = obj.id
            }
        })

        const index = newSelection.indexOf(serviceID);

        if (index === -1 && checked) {
            // newSelection.push(name);
            newSelection.push(serviceID);
            params.append('s', newSelection.join(' '));
        }

        if (index > -1 && !checked) {
            newSelection.splice(index, 1);
            if (newSelection.length) {
                params.append('s', newSelection.join(' '));
            }
        }

        history.push({
            pathname: '/search',
            search: params.toString(),
        });

        this.setState({ selectedServices: newSelection });
    };

    handleSelectService2 = (names, checked) => {
        const { selectedServices } = this.state;
        const { history, location } = this.props;
        const newSelection = [...selectedServices];

        const params = new URLSearchParams(location.search);
        params.delete('s');

        for (let i = 0; i < names.length; i++) {
            const name = names[i];
            let serviceID = ""
            this.servicesObj.forEach((obj) => {
                if (obj.text === name) {
                    serviceID = obj.id
                }
            })
            const index = newSelection.indexOf(serviceID);

            if (index === -1 && checked) {
                // newSelection.push(name);
                newSelection.push(serviceID);
            }

            if (index > -1 && !checked) {
                newSelection.splice(index, 1);
            }
        }

        if (newSelection.length) {
            params.append('s', newSelection.join(' '));
        }

        history.push({
            pathname: '/search',
            search: params.toString(),
        });

        this.setState({ selectedServices: newSelection });
    };

    render() {
        const {
            selectedCategories,
            selectedServices,
        } = this.state;

        return (
            <Aux>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <h5 className="h3 mb-0">Filtres</h5>
                        </Col>
                        <Col className="col-auto">
                            <Button className="btn-sm" onClick={this.handleResetFilters}>
                                <i className="fas fa-redo-alt mr-2" />
                                Réinitialiser
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <SearchFiltersArea handleSelectLocalisation={this.handleSelectLocalisation} />
                <SearchFiltersCategories selectedCategories={selectedCategories} handleSelectCategory={this.handleSelectCategory} />
                {
                    selectedCategories.includes('IOBSP') && (
                        <SearchServicesIOBSP selectedServices={selectedServices} handleSelectService={this.handleSelectService} handleSelectService2={this.handleSelectService2} opened={true} />
                    )
                }
                {
                    selectedCategories.includes('IAS') && (
                        <SearchServicesIAS selectedServices={selectedServices} handleSelectService={this.handleSelectService} handleSelectService2={this.handleSelectService2} opened={true} />
                    )
                }
                {
                    selectedCategories.includes('CIF') && (
                        <SearchServicesCIF selectedServices={selectedServices} handleSelectService={this.handleSelectService} opened={true} />
                    )
                }
            </Aux>
        );
    }
}

export default withRouter(SearchFilters2);
