import React from 'react';
import { Col, DropdownItem, Row } from 'reactstrap';
import { useState, useEffect } from 'react';
import { db } from '../../config/firebase';
import { Link } from 'react-router-dom';
import DefaultProfile from '../../assets/Pics/defaultProfile.jpg';

const UserFollower = (props) => {
    const { userId } = props;

    const [userName, setUserName] = useState("")
    const [userProfilePic, setUserProfilePic] = useState("")

    useEffect(() => {
        db.collection("users").doc(userId).get().then((documentSnapshot) => {
            setUserName(documentSnapshot.get("username"))
            setUserProfilePic(documentSnapshot.get("profilePic"))
        })
    }, [])

    return props.isCurrentUserPremium ? (
        <Link target="_blank" to={`/user/${userName}/about`}>
            <DropdownItem className="border-top">
                <Row className="align-items-center">
                    <Col className="col-auto pr-0">
                        <img alt='' className="avatar rounded-circle" src={userProfilePic ? userProfilePic : DefaultProfile} style={{ maxWidth: '40px', maxHeight: '40px' }} />
                    </Col>
                    <div className="col pl-0">
                        <div className="d-flex justify-content-between align-items-center">
                            <h4 className="mb-0 text-sm">{userName} s'est abonné à votre profil</h4>
                        </div>
                    </div>
                </Row>
            </DropdownItem>
        </Link>
    ) : (
        <DropdownItem className="border-top" style={{ cursor: "pointer" }} onClick={!props.isCurrentUserPremium ? () => props.toggle() : () => { }}>
            <Row className="align-items-center">
                <Col className="col-auto pr-0">
                    <img alt='' className="avatar rounded-circle" src={userProfilePic ? userProfilePic : DefaultProfile} style={{ maxWidth: '40px', maxHeight: '40px' }} />
                </Col>
                <div className="col pl-0">
                    <div className="d-flex justify-content-between align-items-center">
                        <h4 className="mb-0 text-sm">{userName} s'est abonné à votre profil</h4>
                    </div>
                </div>
            </Row>
        </DropdownItem>
    )
};

export default UserFollower;