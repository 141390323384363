import React, { Component } from 'react';
import {
    Button,
    Col, Container, Row,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Aux from '../../hoc/Aux';
import Brand from '../../assets/Pics/Icons/tribu-white.svg';
import classes from '../../assets/Scss/components/About.scss';
import SignupModal from '../Login/SignupModal';

class About extends Component {
    constructor(props) {
        super(props);

        this.state = {
            signupModal: false,
        };
    }

    render() {
        const {
            signupModal,
        } = this.state;

        return (
            <Aux>
                <SignupModal isOpen={signupModal} toggle={() => this.setState({ signupModal: !signupModal })} />
                <Helmet>
                    <title>A propos de Tribu</title>
                    <meta
                        name="description"
                        content="Tribu met en relation des courtiers avec des courtiers, des fournisseurs avec des
                        courtiers, et crée les meilleures conditions pour le développement des affaires."
                    />
                </Helmet>
                <div className={`header bg-gradient-info py-9 ${classes.About}`}>
                    <Container>
                        <div className="header-body">
                            <Row className="align-items-center">
                                <Col className="my-auto pb-4 pb-md-auto" xs={12} md={4}>
                                    <Row className="justify-content-center">
                                        <Link to="/">
                                            <img
                                                className="mb-3"
                                                alt="Tribu"
                                                src={Brand}
                                                style={{ maxWidth: '75px' }}
                                            />
                                        </Link>
                                    </Row>
                                    <hr className="bg-white w-50 text-left my-2" />
                                    <p className="text-white text-center display-4 font-weight-300">
                                        À propos de nous
                                    </p>
                                </Col>
                                <Col xs={12} md={8} className={`${classes.texts} p-3`}>
                                    <h5 className="h1 font-weight-normal text-white">
                                        Nous sommes convaincus que
                                        <span className="font-weight-bold"> le courtage ne peut être qu’humain.</span>
                                    </h5>
                                    <p className="h2 font-weight-normal text-white mt-5">
                                        Nous sommes persuadés que la créativité naît des rencontres et que
                                        <span className="font-weight-bold"> la proximité est une des clés du succès</span>
                                        . Les plus belles aventures naissent toujours du
                                        <span className="font-weight-bold"> brassage </span>
                                        de compétences.
                                    </p>
                                    <p className="h2 font-weight-normal text-white">
                                        Nous mettons en relation des courtiers avec des courtiers,
                                        <span className="font-weight-bold"> mais nous allons plus loin</span>
                                        .
                                        <br />
                                        Nous créons les conditions pour que leurs échanges soient fluides et leurs projets ambitieux.
                                    </p>
                                    <p className="h2 font-weight-normal text-white">
                                        Nous avons à cœur de permettre à chaque membre de notre communauté de s’épanouir dans son travail, d’être autonome et de faire ses choix
                                        <span className="font-weight-bold"> au plus près de ce qu’il est vraiment</span>
                                        .
                                    </p>
                                </Col>
                            </Row>
                        </div>
                        <div className="separator separator-bottom separator-skew zindex-100">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                preserveAspectRatio="none"
                                viewBox="0 0 2560 100"
                                x="0"
                                y="0"
                            >
                                <polygon
                                    className="fill-white"
                                    points="2560 0 2560 100 0 100"
                                />
                            </svg>
                        </div>
                    </Container>
                </div>
                <section className="header bg-white">
                <Container className="py-6">
                    <Row className="justify-content-center">
                        <Col xs={12}>
                            <h5 className="display-3 text-center">
                                Nous sommes Tribu
                            </h5>
                        </Col>
                        <Col className="mt-3" md={6}>
                            <p className="text-center" style={{ fontSize: '17px' }}>
                                Tribu rapproche les meilleurs courtiers, ville par ville.
                                <br />
                                Notre plateforme permet de collaborer en toute sécurité, de la première prise de contact jusqu&#39;au
                                partenariat.
                            </p>
                        </Col>
                    </Row>
                </Container>
                </section>
                <div className="header bg-gradient-info">
                    <Container className="pt-6 pb-8">
                        <h5 className="display-3 font-weight-normal text-center text-white">
                            Prenez part à l&apos;aventure,
                            <span className="font-weight-bold d-inline"> rejoignez la team Tribu !</span>
                        </h5>
                        <Row className="justify-content-center mt-4">
                            <Button className="btn-secondary text-primary px-5" onClick={() => this.setState({ signupModal: !signupModal })}>INSCRIVEZ-VOUS</Button>
                        </Row>
                    </Container>
                    <div className="separator separator-bottom separator-skew">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="fill-white"
                                points="0 0 2560 100 0 100"
                            />
                        </svg>
                    </div>
                </div>
            </Aux>
        );
    }
}

export default About;