import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Aux from "../../hoc/Aux";
import Brand from "../../assets/Pics/Icons/tribu-white.svg";
import "./custom.css";
import Breadcrumb from "./BreadCrumb";
import PagesInfos from "./Pages/PagesInfos";

const HOME_INFO = {
  name: "Centre d'assistance",
  url: "/centre-assistance",
};

function PageComponent({ children, breadcrumbDisable }) {
  const currentPage = () => {
    var currentUrl = location.pathname.replace(HOME_INFO.url, "");
    const page = PagesInfos.find((el) => el.url === currentUrl);
    return page;
  };

  return (
    <Aux>
      <Helmet>
        {currentPage() && (
          <title>{currentPage().name} | Tribu Help Center</title>
        )}
      </Helmet>
      <div className="bg-white">
        <div className="header bg-gradient-info py-9">
          <Container>
            <div className="header-body">
              <Row className="justify-content-center">
                <Col className="col-auto">
                  <Link to="/">
                    <img
                      className="mb-3"
                      alt="Tribu"
                      src={Brand}
                      style={{ maxWidth: "75px" }}
                    />
                  </Link>
                </Col>
              </Row>
              <hr className="bg-white w-50 text-left my-2" />
              <p className="text-white text-center display-4 font-weight-300">
                Centre d'assistance
              </p>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </div>
        <Container className="pt-5 pb-4 col-md-7">
          {breadcrumbDisable !== true && (
            <div className="mb-4">
              <Breadcrumb homeInfo={HOME_INFO} />
            </div>
          )}
          {children}
        </Container>
      </div>
    </Aux>
  );
}

export default PageComponent;
