import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Form,
  FormGroup,
  Input,
  Badge,
} from "reactstrap";
import { ClipLoader } from "react-spinners";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { signin as authSignin } from "../../store/auth/actions";
import Aux from "../../hoc/Aux";
import { signin as signinFields } from "./LoginFields";
import config from "../../config/config";
import SignupModal from "./SignupModal";
import BannerBlue from "../../assets/Pics/Icons/banner_beta_blue.png";
import SigninHeader from "./SigninHeader";
import GenericForm from "../../components/Ui/GenericForm";
import ReactBSAlert from "react-bootstrap-sweetalert";
import * as REGEX from "../../data/Regex";

class Signin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      signupModal: false,
      signin: {
        id: "",
        password: "",
      },
      notVerifiedModal: false,
      showError: false,
      showSiretError: false,
      newSiret: "",
      invalidSiret: false,
      isLoading: false,
      loading0: false,
      width: 768,
      widthForLogin: 576,
    };

    this.signinRef = React.createRef();
  }

  componentDidMount() {
    this.setState({ width: window.innerWidth });

    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setState({ width: window.innerWidth });
  };

  formMap = (form, values) =>
    form.map((field) => {
      const newField = { ...field };
      newField.value = values[field.name];
      newField.handleChange = this.handleChange;
      return newField;
    });

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState((prevState) => ({
      signin: {
        ...prevState.signin,
        [name]: value,
      },
    }));
  };

  /**
   * Triggered on submit button click
   */
  handleSubmit = () => {
    this.setState({ loading0: true });
    const { signin: signinValues } = this.state;
    const { signin } = this.props;

    fetch("https://us-central1-tribu-app.cloudfunctions.net/checkUser", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mail: signinValues.id,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({ loading0: false });
        if (result.showError) {
          this.setState({ showError: true });
        } else if (result.success) {
          signin(signinValues.id, signinValues.password);
        } else if (result.notVerifiedModal) {
          this.setState({ notVerifiedModal: true });
        } else if (result.showSiretError) {
          this.setState({ showSiretError: true });
        }
      });
  };

  handleSiretSubmit() {
    const { signin: signinValues } = this.state;
    const { signin } = this.props;

    if (!REGEX.SIRET.test(this.state.newSiret)) {
      this.setState({ invalidSiret: true });
    } else {
      this.setState({ isLoading: true });
      fetch(
        "https://us-central1-tribu-app.cloudfunctions.net/updateUserSiret",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            newSiret: this.state.newSiret,
            mail: signinValues.id,
          }),
        }
      )
        .then((res) => res.json())
        .then((result) => {
          if (result.error) {
            this.setState({
              invalidSiret: false,
              showSiretError: false,
              newSiret: "",
              isLoading: false,
            });
          } else if (result.success) {
            signin(signinValues.id, signinValues.password);
          }
        });
    }
  }

  render() {
    const {
      signin,
      signupModal,
      width,
      newSiret,
      invalidSiret,
      isLoading,
      widthForLogin,
    } = this.state;

    const { history, isSigninPending, signinError } = this.props;

    return (
      <Row
        className={`bg-secondary h-100vh ${
          width < 768 ? "bg-gradient-info" : "bg-white"
        } m-0`}
      >
        <Helmet>
          <title>{`${config.title} - Connexion`}</title>
        </Helmet>
        <SigninHeader />
        <Col
          xs={12}
          sm={10}
          md={6}
          lg={7}
          className="my-auto mx-auto bg-transparent"
        >
          <Card
            className={`shadow-none border-0 col-xs-12 col-md-8 col-xl-6 mx-auto ${
              width < 768 ? "bg-white" : "bg-transparent"
            }`}
          >
            <SignupModal
              isOpen={signupModal}
              toggle={() => this.setState({ signupModal: !signupModal })}
            />
            {width > widthForLogin ? (
              <>
                <CardHeader
                  className={`bg-transparent border-0 ${
                    width <= widthForLogin ? "pb-0" : ""
                  }`}
                >
                  <Row
                    className={`d-md-none justify-content-center ${
                      width > widthForLogin ? "mb-4" : ""
                    }`}
                  >
                    <Col className="text-center" xs={6} sm={4}>
                      <Link to="/">
                        <img src={BannerBlue} alt="Tribu" className="w-100" />
                      </Link>
                    </Col>
                  </Row>
                </CardHeader>

                {this.state.notVerifiedModal && (
                  <ReactBSAlert
                    warning
                    style={{ display: "block" }}
                    title="Votre compte n'est pas encore activé !"
                    onConfirm={() => {
                      this.setState({ notVerifiedModal: false });
                      history.push("/");
                    }}
                    confirmBtnBsStyle="primary"
                    confirmBtnText="Retourner à l'accueil"
                    btnSize=""
                  >
                    Tribu y travaille.
                  </ReactBSAlert>
                )}
                {this.state.showError && (
                  <ReactBSAlert
                    danger
                    title="Erreur..."
                    style={{ display: "block" }}
                    onConfirm={() => this.setState({ showError: false })}
                    confirmBtnBsStyle="danger"
                    confirmBtnText="OK"
                    btnSize=""
                  >
                    Adresse e-mail ou mot de passe incorrect.
                  </ReactBSAlert>
                )}
                {this.state.showSiretError && (
                  <ReactBSAlert
                    danger
                    title="Erreur..."
                    style={{ display: "block" }}
                    confirmBtnBsStyle="danger"
                    confirmBtnText="OK"
                    btnSize=""
                    allowEscape={true}
                    closeOnClickOutside={true}
                    onCancel={() => this.setState({ showSiretError: false })}
                  >
                    Le numéro Siret ne correspond pas à celui de l'entreprise.
                    <Form onSubmit={this.handleSiretSubmit}>
                      <FormGroup>
                        {isLoading && (
                          <Row className="justify-content-center mt-4">
                            <ClipLoader color="#1171ef" />
                          </Row>
                        )}
                        {invalidSiret && (
                          <Badge
                            color="danger"
                            className="float-right mb-1 badge-error"
                          >
                            Numéro Siret invalide
                          </Badge>
                        )}
                        <Input
                          placeholder="Saisir un nouveau Siret"
                          value={newSiret}
                          onChange={(e) =>
                            this.setState({ newSiret: e.target.value })
                          }
                        />
                      </FormGroup>
                    </Form>
                  </ReactBSAlert>
                )}

                <CardBody className="px-0">
                  {!isSigninPending && !this.state.loading0 ? (
                    <Aux>
                      {signinError && (
                        <Row>
                          <Col>
                            <Alert color="danger" className="w-100">
                              {signinError}
                            </Alert>
                          </Col>
                        </Row>
                      )}

                      <GenericForm
                        ref={this.signinRef}
                        onSubmit={this.handleSubmit}
                        fields={this.formMap(signinFields, signin)}
                      />

                      <Row className="justify-content-center mt-2">
                        <Col>
                          <Button
                            className="w-100"
                            color="primary"
                            onClick={() =>
                              this.signinRef.current.handleSubmit()
                            }
                          >
                            CONNEXION
                          </Button>
                        </Col>
                      </Row>

                      <Row className="justify-content-center">
                        <span className="mt-3">
                          <Link to="/reset-password"> Mot de passe oublié ?</Link>
                        </span>
                      </Row>

                      <Row className="justify-content-center">
                        <span className="d-md-none mt-3">
                          Pas encore de compte ?
                          <span
                            className="text-primary"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.setState({ signupModal: !signupModal })
                            }
                          >
                            {" "}
                            Inscrivez-vous !
                          </span>
                        </span>
                      </Row>
                    </Aux>
                  ) : (
                    <Row className="justify-content-center">
                      <ClipLoader color="#1171ef" size={100} />
                    </Row>
                  )}
                </CardBody>
              </>
            ) : (
              <>
                <CardHeader className={`bg-transparent border-0 pb-0 `}>
                  <Link to="/" className="d-flex justify-content-center ">
                    <img src={BannerBlue} alt="Tribu" style={{ width: 180 }} />
                  </Link>
                </CardHeader>
                <CardBody className="p-3">
                  <span className="d-md-none h3 font-weight-500 pl-0 ml-0 ">
                    On aurait beaucoup aimé vous offrir cette expérience...
                    Tribu est pour le moment disponible depuis un ordinateur.
                  </span>
                  <Row className="justify-content-center ml-0 mr-0">
                    <span className="d-md-none mt-3">
                      Pas encore de compte ?
                      <span
                        className="text-primary"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          this.setState({ signupModal: !signupModal })
                        }
                      >
                        {" "}
                        Inscrivez-vous !
                      </span>
                    </span>
                  </Row>
                </CardBody>
              </>
            )}
          </Card>
        </Col>
      </Row>
    );
  }
}

Signin.propTypes = {
  isSigninPending: PropTypes.bool,
  signin: PropTypes.func.isRequired,
  signinError: PropTypes.string,
};

Signin.defaultProps = {
  isSigninPending: false,
  signinError: null,
};

const mapStateToProps = (state) => ({
  isSigninPending: state.Auth.isSigninPending,
  isSigninSuccess: state.Auth.isSigninSuccess,
  signinError: state.Auth.signinError,
});

export default connect(mapStateToProps, {
  signin: authSignin,
})(Signin);
