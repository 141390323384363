import firebase from '@firebase/app';
import '@firebase/firestore';
import '@firebase/storage';
import '@firebase/functions';
import '@firebase/auth';
import config from './config.json';

var firebaseConfig = {
    apiKey: "AIzaSyAg1KNH-nC9i3ZTiXIa9S9ds8NEcJV-Hlk",
    authDomain: "tribu-app.firebaseapp.com",
    databaseURL: "https://tribu-app.firebaseio.com",
    projectId: "tribu-app",
    storageBucket: "tribu-app.appspot.com",
    messagingSenderId: "581843243102",
    appId: "1:581843243102:web:ce9f86f89c8e4b7e",
    measurementId: "G-1PJFNKR8W0"
};


const firebaseApp = firebase.initializeApp(firebaseConfig);
const authApp = firebase.initializeApp(config.firebase, 'auth');
firebaseApp.functions().useFunctionsEmulator('http://localhost:5001');
const db = firebaseApp.firestore();
const authDb = authApp.firestore();

const storage = firebaseApp.storage().ref();

export {
    firebaseApp,
    authApp,
    db,
    authDb,
    storage,
};
