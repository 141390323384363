import {
    ADD_POST,
    ADD_POST_LIST,
    DELETE_POST, RESET_POSTS,
    SET_LAST_POST_DATE, SET_LIKES, SET_POST_COMMENTS,
    SET_POST_EDITING_POST,
    SET_POST_LIKES_LOADING, SET_POST_LOADING, SET_POST_USER_LIKES,
} from './types';

const initialState = {
    // posts
    isLoading: false,
    error: null,
    posts: [],
    editingPostId: '',

    // Last date of posts
    lastPostDate: {},

    // Load likes
    likesLoading: false,
    likes: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_POST_LOADING:
            return { ...state, isLoading: action.isLoading };

        case ADD_POST_LIST:
            let newPostsList = [...state.posts];

            action.posts.forEach((post) => {
                newPostsList = !action.before ? [...newPostsList.filter((item) => item.id !== post.id), post] : [post, ...newPostsList.filter((item) => item.id !== post.id)];
            });

            return {
                ...state,
                posts: newPostsList,
            };

        case ADD_POST:
            const addPostsLists = [...state.posts];
            addPostsLists.unshift(action.post);

            return {
                ...state,
                posts: addPostsLists,
            };

        case DELETE_POST:
            const deletePostsLists = [...state.posts];

            return {
                ...state,
                posts: deletePostsLists.filter((item) => item.id !== action.postId),
            };

        case RESET_POSTS:
            return { ...initialState };

        case SET_POST_EDITING_POST:
            return {
                ...state,
                editingPostId: action.postId,
            };

        case SET_LAST_POST_DATE:
            return {
                ...state,
                lastPostDate: {
                    ...state.lastPostDate,
                    [action.userId]: action.date,
                },
            };

        case SET_POST_LIKES_LOADING:
            return {
                ...state,
                likesLoading: action.isLoading,
            };

        case SET_LIKES:
            return {
                ...state,
                likes: action.likes,
            };

        case SET_POST_USER_LIKES:
            const userLikesPosts = [...state.posts];
            const userLikesPostIndex = userLikesPosts.findIndex((post) => post.id === action.postId);

            if (userLikesPostIndex > -1) {
                if (userLikesPosts[userLikesPostIndex].userLikes) {
                    userLikesPosts[userLikesPostIndex] = {
                        ...userLikesPosts[userLikesPostIndex],
                        userLikes: false,
                        nbOfLikes: userLikesPosts[userLikesPostIndex].nbOfLikes - 1,
                    };
                } else {
                    userLikesPosts[userLikesPostIndex] = {
                        ...userLikesPosts[userLikesPostIndex],
                        userLikes: true,
                        nbOfLikes: userLikesPosts[userLikesPostIndex].nbOfLikes + 1,
                    };
                }
            }

            return {
                ...state,
                posts: [...userLikesPosts],
            };

        case SET_POST_COMMENTS:
            const commentsPosts = [...state.posts];
            const index = commentsPosts.findIndex((post) => post.id === action.postId);

            if (index > -1) {
                commentsPosts[index] = {
                    ...commentsPosts[index],
                    comments: action.comments,
                };
            }

            return {
                ...state,
                posts: [...commentsPosts],
            };


        default:
            return state;
    }
};

export default reducer;
